import { useCallback, useEffect, useState } from "react";
import { getTaskData } from "../../api/services";

/**
 *
 * @param {{taskUUID: string, clientUUID: string}} param0 - The task and client UUIDs.
 * @returns {{taskData: Object, isLoading: boolean, getTaskDataQuery: Function}}
 */
export function useGetTaskData({ taskUUID, clientUUID }) {
  const [task, setTask] = useState({
    data: null,
    isLoading: true,
  });

  const getTaskDataQuery = useCallback(async () => {
    if (!taskUUID || !clientUUID) return;
    try {
      const data = await getTaskData({ taskUUID, clientUUID });
      setTask({ data, isLoading: false });
    } catch (error) {
      console.error(error);
    }
  }, [clientUUID, taskUUID]);

  useEffect(() => {
    if (task?.data) return;

    getTaskDataQuery();
  }, [clientUUID, getTaskDataQuery, task, taskUUID]);

  return {
    taskData: task?.data,
    isLoading: task?.isLoading,
    getTaskDataQuery,
  };
}
