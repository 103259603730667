import { React, useEffect, useState } from "react";

import partner from "../../api/partner";
import authHeader from "../../api/authHeader";

import Lottie from "react-lottie";
import loadingLottie from "../../components/lotties/loading-dots.json";

import HandleError from "../../components/functions/HandleError";
import HandleToast from "../../components/functions/HandleToast";

import moment from "moment";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import parse from "html-react-parser";
import CurrencyTextField from "@puhl/mui-currency-textfield";
import getSymbolFromCurrency from "currency-symbol-map";

import {
  Box,
  Alert,
  Button,
  Divider,
  Typography,
  Grid,
  Paper,
  TextField,
  Modal,
  ToggleButtonGroup,
  ToggleButton,
  Chip,
  IconButton,
  Drawer,
  List,
  ListItem,
  ListItemText,
} from "@mui/material";
import {
  Close,
  Contactless,
  DataSaverOff,
  Delete,
  Edit,
  EditLocationAlt,
  Language,
  Note,
  Pending,
  Send,
  TaskAlt,
} from "@mui/icons-material";
import AccountUsage from "./components/banking/application/AccountUsage";
import Corporation from "./components/banking/application/Corporation";
import Ownership from "./components/banking/application/Ownership";
import Control from "./components/banking/application/Control";
import AccountCard from "./components/banking/overview/AccountCard";
import Submit from "./components/banking/application/Submit";

import NoResults from "../../components/functions/NoResults";
import { LoadingButton } from "@mui/lab";
import { act } from "react";
import VideoCapture from "./components/banking/facial/VideoCapture";

const Banking = ({ partnerData, partnerUser, clientData, entityData }) => {
  const useAuthHeader = authHeader();
  const handleError = HandleError();
  const displayToast = HandleToast();

  const [isLoaded, setIsLoaded] = useState(false);
  const [processing, setProcessing] = useState(false);

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: loadingLottie,
  };

  const [accounts, setAccounts] = useState([]);
  const [openAccount, setOpenAccount] = useState(false);

  const [applicationSection, setApplicationSection] = useState(0);
  const [applicationData, setApplicationData] = useState({});

  const [applicationUUID, setApplicationUUID] = useState("");

  const [manageAccount, setManageAccount] = useState(false);
  const [accountData, setAccountData] = useState({});
  const [transactionsLoaded, setTransactionsLoaded] = useState(false);
  const [accountTransactions, setAccountTransactions] = useState([]);
  const [pendingTransactions, setPendingTransactions] = useState([]);

  const [createPayment, setCreatePayment] = useState(false);

  const getAccounts = async () => {
    try {
      const response = await partner.post(
        `/v1/client/banking/data/accounts`,
        JSON.stringify({
          client_uuid: clientData.value.client_uuid,
        }),
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Basic " + useAuthHeader.base64encodedData,
            service: "partners",
          },

          // withCredentials: true,
        }
      );

      console.log(response.data);

      setApplicationData(response.data.application);
      setApplicationUUID(response.data.application.application_uuid);

      if (response.data.results > 0) {
        setAccounts(response.data.entries);
      } else {
        setAccounts([]);
        setOpenAccount(true);
      }
      setIsLoaded(true);
    } catch (err) {
      console.log(err);
      handleError(err);
      setProcessing(false);
    }
  };

  const getAccountData = async (accountUUID) => {
    try {
      const response = await partner.post(
        `/v1/client/banking/data/account`,
        JSON.stringify({
          client_uuid: clientData.value.client_uuid,
          account_uuid: accountUUID,
        }),
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Basic " + useAuthHeader.base64encodedData,
            service: "partners",
          },

          // withCredentials: true,
        }
      );

      console.log(response.data);
      setAccountData(response.data);
      getAccountTransactions(accountUUID);
      setManageAccount(true);
    } catch (err) {
      console.log(err);
      handleError(err);
      setProcessing(false);
    }
  };

  const getAccountTransactions = async (accountUUID) => {
    try {
      const response = await partner.post(
        `/v1/client/banking/data/account/transactions`,
        JSON.stringify({
          client_uuid: clientData.value.client_uuid,
          account_uuid: accountUUID,
        }),
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Basic " + useAuthHeader.base64encodedData,
            service: "partners",
          },

          // withCredentials: true,
        }
      );

      console.log(response.data);

      if (response.data.pending_results > 0) {
        setPendingTransactions(response.data.pending_entries);
      } else {
        setPendingTransactions([]);
      }

      if (response.data.results > 0) {
        setAccountTransactions(response.data.entries);
      } else {
        setAccountTransactions([]);
      }
      setTransactionsLoaded(true);
    } catch (err) {
      console.log(err);
      handleError(err);
      setProcessing(false);
    }
  };

  const [amount, setAmount] = useState("");
  const [reference, setReference] = useState("");
  const [accountNumber, setAccountNumber] = useState("");
  const [sortCode, setSortCode] = useState("");
  const [beneficiaryName, setBeneficiaryName] = useState("");
  const [isGlobal, setIsGlobal] = useState(false);
  const [beneficiaryNote, setBeneficiaryNote] = useState("");
  const [beneficiaryAccountType, setBeneficiaryAccountType] = useState(1); // 1 - personal | 2 - business

  const [currencySymbol, setCurrencySymbol] = useState("");

  useEffect(() => {
    if (clientData.value.status == 0) {
      setIsLoaded(true);
    } else {
      getAccounts();
    }
  }, []);

  function formatCurrency(amount, currencyCode, locale = "en-GB") {
    return new Intl.NumberFormat(locale, {
      style: "currency",
      currency: currencyCode,
    }).format(amount);
  }

  const [beneficiariesLoaded, setBeneficiariesLoaded] = useState(false);
  const [beneficiaries, setBeneficiaries] = useState([]);
  const [manageBeneficiaries, setManageBeneficiaries] = useState(false);

  const [createBeneficiary, setCreateBeneficiary] = useState(false);
  const [manageBeneficiary, setManageBeneficiary] = useState(false);

  const [beneficiaryAuthentication, setBeneficiaryAuthentication] = useState(false);
  const [paymentAuthentication, setPaymentAuthentication] = useState(false);

  const [videoPaymentCapture, setVideoPaymentCapture] = useState(true);
  const [videoBeneficiaryCapture, setVideoBeneficiaryCapture] = useState(true);

  const [code1, setCode1] = useState("");
  const [code2, setCode2] = useState("");
  const [code3, setCode3] = useState("");
  const [code4, setCode4] = useState("");
  const [code5, setCode5] = useState("");
  const [code6, setCode6] = useState("");

  const [viewBeneficiary, setViewBeneficiary] = useState(false);
  const [activeBeneficiary, setActiveBeneficiary] = useState(null);
  const [isBeneficiaryApproval, setIsBeneficiaryApproval] = useState(false);

  const getBeneficiaryData = async (beneficiaryUUID) => {
    try {
      const response = await partner.post(
        `/v1/client/banking/data/account/beneficiary`,
        JSON.stringify({
          client_uuid: clientData.value.client_uuid,
          beneficiary_uuid: beneficiaryUUID,
        }),
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Basic " + useAuthHeader.base64encodedData,
            service: "partners",
          },

          // withCredentials: true,
        }
      );

      console.log(response.data);
      setActiveBeneficiary(response.data);
      setViewBeneficiary(true);
    } catch (err) {
      console.log(err);
      handleError(err);
      setProcessing(false);
    }
  };

  const getAllBeneficiaries = async (accountUUID) => {
    try {
      const response = await partner.post(
        `/v1/client/banking/data/account/beneficiaries`,
        JSON.stringify({
          client_uuid: clientData.value.client_uuid,
          account_uuid: accountUUID,
        }),
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Basic " + useAuthHeader.base64encodedData,
            service: "partners",
          },

          // withCredentials: true,
        }
      );

      console.log(response.data);
      if (response.data.results > 0) {
        setBeneficiaries(response.data.entries);
      } else {
        setBeneficiaries([]);
      }
      setBeneficiariesLoaded(true);
    } catch (err) {
      console.log(err);
      handleError(err);
      setProcessing(false);
    }
  };

  const [videoContent, setVideoContent] = useState([]);
  const [faceImage, setFaceImage] = useState("");

  const handleCreateBeneficiary = async (e) => {
    e.preventDefault();
    setProcessing(true);

    try {
      const response = await partner.post(
        `/v1/client/banking/beneficiaries/create`,
        JSON.stringify({
          client_uuid: clientData.value.client_uuid,
          account_uuid: accountData.uuid,
          beneficiary_name: beneficiaryName,
          sortcode: sortCode,
          account_number: accountNumber,
          is_global: isGlobal,
          reference: reference,
          content: beneficiaryNote,
          authentication_code: code1 + code2 + code3 + code4 + code5 + code6,
          account_type: beneficiaryAccountType,
        }),
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Basic " + useAuthHeader.base64encodedData,
            service: "partners",
          },

          // withCredentials: true,
        }
      );

      processBeneficiaryFacial(response.data.beneficiary_uuid, 1);
    } catch (err) {
      console.log(err);
      setCode1("");
      setCode2("");
      setCode3("");
      setCode4("");
      setCode5("");
      setCode6("");
      setVideoBeneficiaryCapture(true);
      setBeneficiaryAuthentication(false);
      handleError(err);
      setProcessing(false);
    }
  };

  const initiateBeneficiaryApproval = (beneficiaryUUID) => {
    setIsBeneficiaryApproval(true);
    setBeneficiaryAuthentication(true);
  };

  const handleApproveBeneficiary = async (e) => {
    e.preventDefault();
    setProcessing(true);

    try {
      const response = await partner.post(
        `/v1/client/banking/beneficiaries/approve`,
        JSON.stringify({
          client_uuid: clientData.value.client_uuid,
          account_uuid: accountData.uuid,
          beneficiary_uuid: activeBeneficiary?.uuid,
          authentication_code: code1 + code2 + code3 + code4 + code5 + code6,
        }),
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Basic " + useAuthHeader.base64encodedData,
            service: "partners",
          },

          // withCredentials: true,
        }
      );

      processBeneficiaryFacial(response.data.beneficiary_uuid, 2);
    } catch (err) {
      console.log(err);
      setCode1("");
      setCode2("");
      setCode3("");
      setCode4("");
      setCode5("");
      setCode6("");
      setVideoBeneficiaryCapture(true);
      setBeneficiaryAuthentication(false);
      handleError(err);
      setProcessing(false);
    }
  };

  const handleDeleteBeneficiary = async (e) => {
    e.preventDefault();
    setProcessing(true);

    try {
      const response = await partner.post(
        `/v1/client/banking/beneficiaries/delete`,
        JSON.stringify({
          client_uuid: clientData.value.client_uuid,
          account_uuid: accountData.uuid,
          beneficiary_uuid: activeBeneficiary?.uuid,
        }),
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Basic " + useAuthHeader.base64encodedData,
            service: "partners",
          },

          // withCredentials: true,
        }
      );

      getAllBeneficiaries(accountData.uuid);
      setViewBeneficiary(false);

      displayToast("Beneficiary deleted", () => {
        setProcessing(false);
      });
    } catch (err) {
      console.log(err);
      setVideoBeneficiaryCapture(true);
      setBeneficiaryAuthentication(false);
      handleError(err);
      setProcessing(false);
    }
  };

  const processBeneficiaryFacial = async (beneficiaryUUID, uploadType) => {
    if (videoContent.length > 0) {
      const blob = new Blob(videoContent, {
        type: "video/webm",
      });

      const formData = new FormData();
      formData.append("video_file", blob);
      formData.append("beneficiary_uuid", beneficiaryUUID);
      formData.append("snapshot", faceImage);
      formData.append("upload_type", uploadType);

      try {
        const response = await partner.post(
          "/v1/client/banking/beneficiaries/auth-files",
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
              Authorization: "Basic " + useAuthHeader.base64encodedData,
              service: "partners",
            },

            //withCredentials: true,
          }
        );

        setBeneficiaryAuthentication(false);
        setVideoBeneficiaryCapture(true);
        getAllBeneficiaries(accountData.uuid);
        setIsBeneficiaryApproval(false);

        if (!isBeneficiaryApproval) {
          displayToast("Beneficiary creation initiated", () => {
            setProcessing(false);
            setCreateBeneficiary(false);
            setBeneficiaryName("");
            setSortCode("");
            setAccountNumber("");
            setReference("");
            setIsGlobal(false);
            setCode1("");
            setCode2("");
            setCode3("");
            setCode4("");
            setCode5("");
            setCode6("");
            setVideoContent([]);
            setFaceImage("");
          });
        } else {
          displayToast("Beneficiary approved", () => {
            setProcessing(false);
            setCreateBeneficiary(false);
            setBeneficiaryName("");
            setSortCode("");
            setAccountNumber("");
            setReference("");
            setIsGlobal(false);
            setCode1("");
            setCode2("");
            setCode3("");
            setCode4("");
            setCode5("");
            setCode6("");
            setVideoContent([]);
            setFaceImage("");
            setIsBeneficiaryApproval(false);
          });
        }
      } catch (err) {
        setBeneficiaryAuthentication(false);
        setVideoBeneficiaryCapture(true);
        setCode1("");
        setCode2("");
        setCode3("");
        setCode4("");
        setCode5("");
        setCode6("");
        setVideoContent([]);
        setFaceImage("");
        setProcessing(false);
        console.log(err);
        handleError(err);
      }
    }
  };

  const handleCreatePaymentRequest = async (e) => {
    e.preventDefault();
    setProcessing(true);

    try {
      const response = await partner.post(
        `/v1/client/banking/payment/init`,
        JSON.stringify({
          client_uuid: clientData.value.client_uuid,
          account_uuid: accountData.uuid,
          beneficiary_uuid: activeBeneficiary?.uuid,
          beneficiary_name: beneficiaryName,
          amount: parseFloat(amount),
          reference: reference,
          authentication_code: code1 + code2 + code3 + code4 + code5 + code6,
        }),
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Basic " + useAuthHeader.base64encodedData,
            service: "partners",
          },

          // withCredentials: true,
        }
      );

      processPaymentFacial(response.data.request_uuid, "", 1);
    } catch (err) {
      console.log(err);
      setCode1("");
      setCode2("");
      setCode3("");
      setCode4("");
      setCode5("");
      setCode6("");
      setVideoPaymentCapture(true);
      setPaymentAuthentication(false);
      handleError(err);
      setProcessing(false);
    }
  };

  const [isPaymentApproval, setIsPaymentApproval] = useState(false);

  const initiatePaymentApproval = (requestUUID) => {
    setIsPaymentApproval(true);
    setPaymentAuthentication(true);
  };

  const handleApprovePayment = async (e) => {
    e.preventDefault();
    setProcessing(true);

    try {
      const response = await partner.post(
        `/v1/client/banking/payment/approve`,
        JSON.stringify({
          client_uuid: clientData.value.client_uuid,
          account_uuid: accountData.uuid,
          request_uuid: activePaymentRequest?.request_uuid,
          authentication_code: code1 + code2 + code3 + code4 + code5 + code6,
        }),
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Basic " + useAuthHeader.base64encodedData,
            service: "partners",
          },

          // withCredentials: true,
        }
      );

      processPaymentFacial(activePaymentRequest?.request_uuid, response.data.approval_uuid, 2);
    } catch (err) {
      console.log(err);
      setCode1("");
      setCode2("");
      setCode3("");
      setCode4("");
      setCode5("");
      setCode6("");
      setVideoPaymentCapture(true);
      setPaymentAuthentication(false);
      handleError(err);
      setProcessing(false);
    }
  };

  const handlePaymentCancellation = async (e) => {
    e.preventDefault();
    setProcessing(true);

    try {
      const response = await partner.post(
        `/v1/client/banking/payment/cancel`,
        JSON.stringify({
          client_uuid: clientData.value.client_uuid,
          account_uuid: accountData.uuid,
          request_uuid: activePaymentRequest?.request_uuid,
        }),
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Basic " + useAuthHeader.base64encodedData,
            service: "partners",
          },

          // withCredentials: true,
        }
      );

      getAccountTransactions(accountData.uuid);
      getAccountData(accountData.uuid);
      setActivePaymentRequest(null);
      setViewPaymentRequest(false);

      displayToast("Payment request cancelled", () => {
        setProcessing(false);
      });
    } catch (err) {
      console.log(err);

      setVideoPaymentCapture(true);
      setPaymentAuthentication(false);
      handleError(err);
      setProcessing(false);
    }
  };

  const processPaymentFacial = async (requestUUID, approvalUUID, uploadType) => {
    if (videoContent.length > 0) {
      const blob = new Blob(videoContent, {
        type: "video/webm",
      });

      const formData = new FormData();
      formData.append("video_file", blob);
      formData.append("request_uuid", requestUUID);
      formData.append("snapshot", faceImage);
      formData.append("upload_type", uploadType);
      formData.append("approval_uuid", approvalUUID ? approvalUUID : "");

      try {
        const response = await partner.post("/v1/client/banking/payment/auth-files", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: "Basic " + useAuthHeader.base64encodedData,
            service: "partners",
          },

          //withCredentials: true,
        });

        setPaymentAuthentication(false);
        setVideoPaymentCapture(true);
        getAccountTransactions(accountData.uuid);
        setActivePaymentRequest(null);
        setViewPaymentRequest(false);
        getAccountData(accountData.uuid);

        setIsPaymentApproval(false);

        setManageBeneficiaries(false);
        setManageBeneficiary(false);
        setViewBeneficiary(false);
        getAccountTransactions(accountData.uuid);

        if (uploadType == 1) {
          displayToast("Payment request created", () => {
            setProcessing(false);
            setCreatePayment(false);
            setBeneficiaryName("");
            setSortCode("");
            setAccountNumber("");
            setReference("");
            setCode1("");
            setCode2("");
            setCode3("");
            setCode4("");
            setCode5("");
            setCode6("");
            setVideoContent([]);
            setFaceImage("");
          });
        } else {
          displayToast("Payment request approved", () => {
            setProcessing(false);
            setCreatePayment(false);
            setBeneficiaryName("");
            setSortCode("");
            setAccountNumber("");
            setReference("");
            setCode1("");
            setCode2("");
            setCode3("");
            setCode4("");
            setCode5("");
            setCode6("");
            setVideoContent([]);
            setFaceImage("");
          });
        }
      } catch (err) {
        setPaymentAuthentication(false);
        setVideoPaymentCapture(true);
        setCode1("");
        setCode2("");
        setCode3("");
        setCode4("");
        setCode5("");
        setCode6("");
        setVideoContent([]);
        setFaceImage("");
        setProcessing(false);
        console.log(err);
        handleError(err);
      }
    }
  };

  const [viewPaymentRequest, setViewPaymentRequest] = useState(false);
  const [activePaymentRequest, setActivePaymentRequest] = useState(null);

  const getPaymentRequest = async (requestUUID) => {
    try {
      const response = await partner.post(
        `/v1/client/banking/data/account/payment-request`,
        JSON.stringify({
          client_uuid: clientData.value.client_uuid,
          account_uuid: accountData.uuid,
          request_uuid: requestUUID,
        }),
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Basic " + useAuthHeader.base64encodedData,
            service: "partners",
          },

          // withCredentials: true,
        }
      );

      console.log(response.data);
      setActivePaymentRequest(response.data);
      setViewPaymentRequest(true);
    } catch (err) {
      console.log(err);
      handleError(err);
      setProcessing(false);
    }
  };

  const [viewTransaction, setViewTransaction] = useState(false);
  const [activeTransaction, setActiveTransaction] = useState(null);

  const getTransaction = async (transactionUUID) => {
    try {
      const response = await partner.post(
        `/v1/client/banking/data/account/transaction`,
        JSON.stringify({
          client_uuid: clientData.value.client_uuid,
          account_uuid: accountData.uuid,
          transaction_uuid: transactionUUID,
        }),
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Basic " + useAuthHeader.base64encodedData,
            service: "partners",
          },

          // withCredentials: true,
        }
      );

      console.log(response.data);
      setActiveTransaction(response.data);
      setViewTransaction(true);
    } catch (err) {
      console.log(err);
      handleError(err);
      setProcessing(false);
    }
  };

  useEffect(() => {
    if (manageBeneficiaries) {
      getAllBeneficiaries(accountData.uuid);
    }
  }, [manageBeneficiaries]);

  useEffect(() => {
    if (accountData?.currency) {
      setCurrencySymbol(getSymbolFromCurrency(accountData?.currency));
    } else {
      setCurrencySymbol("");
      setAmount("");
    }
  }, [accountData?.currency]);

  return isLoaded ? (
    clientData.value.status == 0 ? (
      <>
        <Alert severity="info">This client is still onboarding</Alert>
      </>
    ) : (
      <>
        {manageAccount ? (
          <>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                p: 2,
                backgroundColor: "#E5E4E2",
              }}
            >
              <Box sx={{ display: "flex", gap: 2 }}>
                <Box>
                  <Typography variant="h6">{accountData?.label}</Typography>
                  <Typography variant="subtitle2" sx={{ color: "secondary.dark" }}>
                    {accountData.sortcode} {accountData?.account_number}
                  </Typography>
                </Box>
              </Box>
              <Box sx={{ display: "flex", gap: 2 }}>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    textAlign: "center",
                  }}
                >
                  <Typography
                    variant="subtitle2"
                    sx={{ color: "secondary.dark", textTransform: "uppercase" }}
                  >
                    Available balance
                  </Typography>
                  <Typography variant="h6" sx={{}}>
                    {formatCurrency(accountData?.available_balance, accountData?.currency)}
                  </Typography>
                </Box>

                {!manageBeneficiaries && !createPayment && (
                  <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
                    <Button
                      variant="outlined"
                      color="primary"
                      onClick={() => {
                        setManageBeneficiaries(true);
                      }}
                    >
                      Beneficiaries
                    </Button>
                  </Box>
                )}

                {manageBeneficiaries && !createBeneficiary && !createPayment && (
                  <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
                    <Button
                      variant="outlined"
                      color="primary"
                      onClick={() => {
                        setActiveBeneficiary(null);
                        setReference("");
                        setAmount("");
                        setSortCode("");
                        setAccountNumber("");
                        setBeneficiaryNote("");
                        setIsBeneficiaryApproval(false);
                        setCreateBeneficiary(true);
                      }}
                    >
                      New Beneficiary
                    </Button>
                  </Box>
                )}

                <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => {
                      if (createPayment) {
                        setCreatePayment(false);
                        getAccountData(accountData.uuid);
                        getAccounts();
                      } else if (createBeneficiary) {
                        setCreateBeneficiary(false);
                        getAccountData(accountData.uuid);
                        getAccounts();
                        getAllBeneficiaries(accountData.uuid);
                      } else if (manageBeneficiaries) {
                        setManageBeneficiaries(false);
                        getAccountData(accountData.uuid);
                        getAccounts();
                      } else {
                        getAccounts();
                        setManageAccount(false);
                        setTransactionsLoaded(false);
                        setAccountData({});
                        setCreatePayment(false);
                      }
                    }}
                  >
                    Back
                  </Button>
                </Box>
              </Box>
            </Box>
            <Divider />

            {createPayment ? (
              <Box>
                <Box sx={{ p: 2 }}>
                  <Typography variant="h6">Creating a new payment request</Typography>
                </Box>

                <Divider />

                <Box sx={{ width: "100%", p: 2, display: "flex", flexDirection: "column", gap: 2 }}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} lg={activeBeneficiary?.content ? 6 : 12}>
                      <Typography variant="body1" sx={{ fontWeight: 600 }}>
                        Beneficiary details
                      </Typography>
                      <Box sx={{ border: 1, borderColor: "divider", mt: 2 }}>
                        <Box sx={{ display: "flex" }}>
                          <Box
                            sx={{
                              borderRight: 1,
                              borderColor: "divider",
                              display: "flex",
                              flexDirection: "column",
                              justifyContent: "center",
                              p: 2,
                              width: "50%",
                              textTransform: "uppercase",
                            }}
                          >
                            <Typography variant="subtitle2">Beneficiary name</Typography>
                          </Box>
                          <Box
                            sx={{
                              textAlign: "right",
                              display: "flex",
                              flexDirection: "column",
                              justifyContent: "center",
                              width: "50%",
                              p: 2,
                            }}
                          >
                            <Typography variant="subtitle1">
                              {activeBeneficiary?.beneficiary_name}
                            </Typography>
                          </Box>
                        </Box>

                        <Box sx={{ display: "flex", borderTop: 1, borderColor: "divider" }}>
                          <Box
                            sx={{
                              borderRight: 1,
                              borderColor: "divider",
                              display: "flex",
                              flexDirection: "column",
                              justifyContent: "center",
                              p: 2,
                              width: "50%",
                              textTransform: "uppercase",
                            }}
                          >
                            <Typography variant="subtitle2">Sortcode</Typography>
                          </Box>
                          <Box
                            sx={{
                              textAlign: "right",
                              display: "flex",
                              flexDirection: "column",
                              justifyContent: "center",
                              width: "50%",
                              p: 2,
                            }}
                          >
                            <Typography variant="subtitle1">
                              {activeBeneficiary?.sortcode_formatted}
                            </Typography>
                          </Box>
                        </Box>
                        <Box sx={{ display: "flex", borderTop: 1, borderColor: "divider" }}>
                          <Box
                            sx={{
                              borderRight: 1,
                              borderColor: "divider",
                              display: "flex",
                              flexDirection: "column",
                              justifyContent: "center",
                              p: 2,
                              width: "50%",
                              textTransform: "uppercase",
                            }}
                          >
                            <Typography variant="subtitle2">Account number</Typography>
                          </Box>
                          <Box
                            sx={{
                              textAlign: "right",
                              display: "flex",
                              flexDirection: "column",
                              justifyContent: "center",
                              width: "50%",
                              p: 2,
                            }}
                          >
                            <Typography variant="subtitle1">
                              {activeBeneficiary?.account_number}
                            </Typography>
                          </Box>
                        </Box>
                        {activeBeneficiary?.bic && (
                          <Box sx={{ display: "flex", borderTop: 1, borderColor: "divider" }}>
                            <Box
                              sx={{
                                borderRight: 1,
                                borderColor: "divider",
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "center",
                                p: 2,
                                width: "50%",
                                textTransform: "uppercase",
                              }}
                            >
                              <Typography variant="subtitle2">BIC</Typography>
                            </Box>
                            <Box
                              sx={{
                                textAlign: "right",
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "center",
                                width: "50%",
                                p: 2,
                              }}
                            >
                              <Typography variant="subtitle1">{activeBeneficiary?.bic}</Typography>
                            </Box>
                          </Box>
                        )}
                        {activeBeneficiary?.iban && (
                          <Box sx={{ display: "flex", borderTop: 1, borderColor: "divider" }}>
                            <Box
                              sx={{
                                borderRight: 1,
                                borderColor: "divider",
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "center",
                                p: 2,
                                width: "50%",
                                textTransform: "uppercase",
                              }}
                            >
                              <Typography variant="subtitle2">IBAN</Typography>
                            </Box>
                            <Box
                              sx={{
                                textAlign: "right",
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "center",
                                width: "50%",
                                p: 2,
                              }}
                            >
                              <Typography variant="subtitle1">{activeBeneficiary?.iban}</Typography>
                            </Box>
                          </Box>
                        )}
                      </Box>
                    </Grid>

                    {activeBeneficiary?.content && (
                      <Grid item xs={12} lg={6}>
                        {activeBeneficiary?.content && (
                          <Box sx={{}}>
                            <Typography variant="body1" sx={{ fontWeight: 600 }}>
                              Additional information
                            </Typography>

                            <Box sx={{ border: 1, borderColor: "divider", mt: 2, pl: 2, pr: 2 }}>
                              {parse(activeBeneficiary?.content)}
                            </Box>
                          </Box>
                        )}
                      </Grid>
                    )}
                  </Grid>

                  <Typography variant="body1" sx={{ fontWeight: 600 }}>
                    Payment information
                  </Typography>

                  <CurrencyTextField
                    fullWidth
                    label="Payment amount"
                    variant="outlined"
                    value={amount}
                    currencySymbol={currencySymbol}
                    outputFormat="number"
                    decimalCharacter="."
                    digitGroupSeparator=","
                    onChange={(event, value) => setAmount(value)}
                  />

                  <TextField
                    label="Payment reference"
                    variant="outlined"
                    value={reference}
                    onChange={(e) => setReference(e.target.value)}
                  />
                  <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                    <LoadingButton
                      disableElevation
                      variant="contained"
                      color="success"
                      loading={processing}
                      onClick={(e) => {
                        setPaymentAuthentication(true);
                      }}
                    >
                      Process payment
                    </LoadingButton>
                  </Box>
                </Box>
              </Box>
            ) : manageBeneficiaries ? (
              <Box>
                {createBeneficiary ? (
                  <Box>
                    <Box sx={{ p: 2 }}>
                      <Typography variant="body1">Creating a new beneficiary</Typography>
                    </Box>

                    <Divider />

                    <Grid container spacing={2} sx={{}}>
                      <Grid item xs={12} md={6}>
                        <Box sx={{ display: "flex", justifyContent: "space-between", p: 2 }}>
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              justifyContent: "center",
                            }}
                          >
                            <Typography variant="subtitle2">Account type</Typography>
                          </Box>

                          <Box>
                            <ToggleButtonGroup
                              color="primary"
                              value={beneficiaryAccountType}
                              exclusive
                              onChange={(e, value) => {
                                if (value !== null) setBeneficiaryAccountType(value);
                              }}
                            >
                              <ToggleButton value={1}>Personal</ToggleButton>
                              <ToggleButton value={2}>Business</ToggleButton>
                            </ToggleButtonGroup>
                          </Box>
                        </Box>

                        <Box
                          sx={{
                            width: "100%",
                            p: 2,
                            display: "flex",
                            flexDirection: "column",
                            gap: 2,
                          }}
                        >
                          <TextField
                            label="Beneficiary name"
                            variant="outlined"
                            value={beneficiaryName}
                            onChange={(e) => setBeneficiaryName(e.target.value)}
                          />

                          <TextField
                            label="Sortcode"
                            variant="outlined"
                            value={sortCode}
                            onChange={(e) => setSortCode(e.target.value)}
                          />

                          <TextField
                            label="Account number"
                            variant="outlined"
                            value={accountNumber}
                            onChange={(e) => setAccountNumber(e.target.value)}
                          />

                          <TextField
                            label="Reference (optional)"
                            variant="outlined"
                            value={reference}
                            onChange={(e) => setReference(e.target.value)}
                          />
                        </Box>
                      </Grid>

                      <Grid item xs={12} md={6}>
                        <Box
                          sx={{
                            borderLeft: { xs: 0, md: 1 },
                            borderTop: { xs: 1, md: 0 },
                            borderBottom: { xs: 1, md: 1 },
                            borderColor: { xs: "divider", md: "divider" },
                          }}
                        >
                          <Box sx={{ p: 2 }}>
                            <Typography variant="body1">Beneficiary note</Typography>
                          </Box>

                          <Divider />
                          <ReactQuill
                            theme="snow"
                            value={beneficiaryNote}
                            onChange={setBeneficiaryNote}
                            style={{ minHeight: "300px" }}
                          />
                        </Box>
                      </Grid>
                    </Grid>
                    <Box sx={{ display: "flex", justifyContent: "flex-end", p: 2 }}>
                      <LoadingButton
                        disableElevation
                        variant="contained"
                        color="success"
                        loading={processing}
                        onClick={(e) => {
                          setVideoBeneficiaryCapture(true);
                          setVideoContent([]);
                          setFaceImage("");
                          setBeneficiaryAuthentication(true);
                        }}
                      >
                        Continue
                      </LoadingButton>
                    </Box>
                  </Box>
                ) : beneficiariesLoaded ? (
                  beneficiaries?.length > 0 ? (
                    <Box>
                      {beneficiaries.map((beneficiary, index) => (
                        <Box
                          key={index}
                          sx={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-between",
                            borderBottom: index + 1 === beneficiaries.length ? 0 : 1,
                            borderColor: "divider",
                            cursor: "pointer",
                          }}
                          onClick={() => {
                            getBeneficiaryData(beneficiary.uuid);
                          }}
                        >
                          <Box sx={{ display: "flex", flexDirection: "column", p: 2 }}>
                            <Box sx={{ display: "flex", flexDirection: "row", gap: 2 }}>
                              <Box
                                sx={{
                                  display: "flex",
                                  flexDirection: "column",
                                  justifyContent: "center",
                                }}
                              >
                                <Typography variant="subtitle1" sx={{ fontWeight: 600 }}>
                                  {beneficiary.beneficiary_name}
                                </Typography>
                              </Box>
                            </Box>
                            <Typography variant="subtitle2" sx={{ color: "secondary.dark" }}>
                              {beneficiary.sortcode_formatted} {beneficiary.account_number}
                            </Typography>
                          </Box>
                          <Box sx={{ display: "flex", flexDirection: "row", gap: 2 }}>
                            {console.log(beneficiary)}
                            {beneficiary.requires_approval && (
                              <Chip
                                className="square-chip"
                                label="** Requires Approval **"
                                sx={{ textTransform: "uppercase" }}
                                color="secondary"
                              />
                            )}

                            {beneficiary.content && (
                              <Box
                                title="More information"
                                sx={{
                                  display: "flex",
                                  flexDirection: "column",
                                  justifyContent: "center",
                                  color: "info.main",
                                }}
                              >
                                <Note />
                              </Box>
                            )}
                            {beneficiary.is_global && (
                              <Box
                                title="Global beneficiary"
                                sx={{
                                  display: "flex",
                                  flexDirection: "column",
                                  justifyContent: "center",
                                  color: "secondary.dark",
                                }}
                              >
                                <Language />
                              </Box>
                            )}
                            <Box
                              sx={{
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "center",
                                p: 2,
                              }}
                            ></Box>
                          </Box>
                        </Box>
                      ))}

                      <Drawer
                        anchor="right"
                        open={viewBeneficiary}
                        onClose={() => setViewBeneficiary(false)}
                      >
                        <Box sx={{ minWidth: "50vw" }}>
                          <Box
                            sx={{
                              p: 2,
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "space-between",
                            }}
                          >
                            <Box
                              sx={{
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "center",
                              }}
                            >
                              <Box sx={{ display: "flex", gap: 2 }}>
                                <Box
                                  sx={{
                                    display: "flex",
                                    flexDirection: "column",
                                    justifyContent: "center",
                                  }}
                                >
                                  <Typography variant="h6">
                                    {activeBeneficiary?.beneficiary_name}
                                  </Typography>
                                </Box>
                              </Box>
                            </Box>

                            <Box sx={{ display: "flex", gap: 2 }}>
                              <IconButton
                                onClick={() => {
                                  setViewBeneficiary(false);
                                }}
                              >
                                <Close />
                              </IconButton>
                            </Box>
                          </Box>

                          <Divider />

                          <Box sx={{ p: 2 }}>
                            <Typography variant="body1" sx={{ fontWeight: 600 }}>
                              Beneficiary details
                            </Typography>
                            <Box sx={{ border: 1, borderColor: "divider", mt: 2 }}>
                              <Box sx={{ display: "flex" }}>
                                <Box
                                  sx={{
                                    borderRight: 1,
                                    borderColor: "divider",
                                    display: "flex",
                                    flexDirection: "column",
                                    justifyContent: "center",
                                    p: 2,
                                    width: "50%",
                                    textTransform: "uppercase",
                                  }}
                                >
                                  <Typography variant="subtitle2">Sortcode</Typography>
                                </Box>
                                <Box
                                  sx={{
                                    textAlign: "right",
                                    display: "flex",
                                    flexDirection: "column",
                                    justifyContent: "center",
                                    width: "50%",
                                    p: 2,
                                  }}
                                >
                                  <Typography variant="subtitle1">
                                    {activeBeneficiary?.sortcode_formatted}
                                  </Typography>
                                </Box>
                              </Box>
                              <Box sx={{ display: "flex", borderTop: 1, borderColor: "divider" }}>
                                <Box
                                  sx={{
                                    borderRight: 1,
                                    borderColor: "divider",
                                    display: "flex",
                                    flexDirection: "column",
                                    justifyContent: "center",
                                    p: 2,
                                    width: "50%",
                                    textTransform: "uppercase",
                                  }}
                                >
                                  <Typography variant="subtitle2">Account number</Typography>
                                </Box>
                                <Box
                                  sx={{
                                    textAlign: "right",
                                    display: "flex",
                                    flexDirection: "column",
                                    justifyContent: "center",
                                    width: "50%",
                                    p: 2,
                                  }}
                                >
                                  <Typography variant="subtitle1">
                                    {activeBeneficiary?.account_number}
                                  </Typography>
                                </Box>
                              </Box>
                              {activeBeneficiary?.bic && (
                                <Box sx={{ display: "flex", borderTop: 1, borderColor: "divider" }}>
                                  <Box
                                    sx={{
                                      borderRight: 1,
                                      borderColor: "divider",
                                      display: "flex",
                                      flexDirection: "column",
                                      justifyContent: "center",
                                      p: 2,
                                      width: "50%",
                                      textTransform: "uppercase",
                                    }}
                                  >
                                    <Typography variant="subtitle2">BIC</Typography>
                                  </Box>
                                  <Box
                                    sx={{
                                      textAlign: "right",
                                      display: "flex",
                                      flexDirection: "column",
                                      justifyContent: "center",
                                      width: "50%",
                                      p: 2,
                                    }}
                                  >
                                    <Typography variant="subtitle1">
                                      {activeBeneficiary?.bic}
                                    </Typography>
                                  </Box>
                                </Box>
                              )}
                              {activeBeneficiary?.iban && (
                                <Box sx={{ display: "flex", borderTop: 1, borderColor: "divider" }}>
                                  <Box
                                    sx={{
                                      borderRight: 1,
                                      borderColor: "divider",
                                      display: "flex",
                                      flexDirection: "column",
                                      justifyContent: "center",
                                      p: 2,
                                      width: "50%",
                                      textTransform: "uppercase",
                                    }}
                                  >
                                    <Typography variant="subtitle2">IBAN</Typography>
                                  </Box>
                                  <Box
                                    sx={{
                                      textAlign: "right",
                                      display: "flex",
                                      flexDirection: "column",
                                      justifyContent: "center",
                                      width: "50%",
                                      p: 2,
                                    }}
                                  >
                                    <Typography variant="subtitle1">
                                      {activeBeneficiary?.iban}
                                    </Typography>
                                  </Box>
                                </Box>
                              )}
                              {activeBeneficiary?.reference && (
                                <Box sx={{ display: "flex", borderTop: 1, borderColor: "divider" }}>
                                  <Box
                                    sx={{
                                      borderRight: 1,
                                      borderColor: "divider",
                                      display: "flex",
                                      flexDirection: "column",
                                      justifyContent: "center",
                                      p: 2,
                                      width: "50%",
                                      textTransform: "uppercase",
                                    }}
                                  >
                                    <Typography variant="subtitle1">Reference</Typography>
                                  </Box>
                                  <Box
                                    sx={{
                                      textAlign: "right",
                                      display: "flex",
                                      flexDirection: "column",
                                      justifyContent: "center",
                                      width: "50%",
                                      p: 2,
                                    }}
                                  >
                                    <Typography variant="subtitle1">
                                      {activeBeneficiary?.reference}
                                    </Typography>
                                  </Box>
                                </Box>
                              )}
                            </Box>

                            {activeBeneficiary?.content && (
                              <Box sx={{ mt: 2 }}>
                                <Typography variant="body1" sx={{ fontWeight: 600 }}>
                                  Additional information
                                </Typography>

                                <Box
                                  sx={{ border: 1, borderColor: "divider", mt: 2, pl: 2, pr: 2 }}
                                >
                                  {parse(activeBeneficiary?.content)}
                                </Box>
                              </Box>
                            )}

                            {activeBeneficiary?.history && (
                              <Box sx={{ mt: 2 }}>
                                <Typography variant="body1" sx={{ fontWeight: 600 }}>
                                  Recent Transactions
                                </Typography>

                                <Box sx={{ border: 1, borderColor: "divider", mt: 2 }}></Box>
                              </Box>
                            )}
                          </Box>
                        </Box>

                        <List style={{ marginTop: `auto`, width: "100%", border: 1 }}>
                          <ListItem sx={{ width: "100%" }}>
                            <Box
                              sx={{
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "space-between",

                                width: "100%",
                              }}
                            >
                              <Box sx={{ width: "50%" }}>
                                <Box sx={{ display: "flex", gap: 1, color: "secondary.dark" }}>
                                  <Typography
                                    variant="subtitle2"
                                    sx={{ textTransform: "uppercase" }}
                                  >
                                    Added:
                                  </Typography>
                                  <Typography variant="subtitle2">
                                    {moment.unix(activeBeneficiary?.added).format("DD/MM/YYYY")}
                                  </Typography>
                                </Box>
                                <Box sx={{ display: "flex", gap: 1, color: "secondary.dark" }}>
                                  <Typography
                                    variant="subtitle2"
                                    sx={{ textTransform: "uppercase" }}
                                  >
                                    Added by:
                                  </Typography>
                                  <Typography variant="subtitle2">
                                    {activeBeneficiary?.added_by}
                                  </Typography>
                                </Box>
                                {activeBeneficiary?.approved_by && (
                                  <Box sx={{ display: "flex", gap: 1, color: "secondary.dark" }}>
                                    <Typography
                                      variant="subtitle2"
                                      sx={{ textTransform: "uppercase" }}
                                    >
                                      Approved by:
                                    </Typography>
                                    <Typography variant="subtitle2">
                                      {activeBeneficiary?.approved_by}
                                    </Typography>
                                  </Box>
                                )}
                              </Box>
                              <Box
                                sx={{
                                  width: "50%",
                                  display: "flex",
                                  gap: 2,
                                  justifyContent: "flex-end",
                                }}
                              >
                                {activeBeneficiary?.requires_approval ? (
                                  <Box sx={{ display: "flex", gap: 2 }}>
                                    <Box
                                      sx={{
                                        display: "flex",
                                        flexDirection: "column",
                                        justifyContent: "center",
                                      }}
                                    >
                                      <IconButton
                                        variant="contained"
                                        color="danger"
                                        onClick={(e) => {
                                          handleDeleteBeneficiary(e);
                                        }}
                                      >
                                        <Delete />
                                      </IconButton>
                                    </Box>
                                    <Button
                                      variant="contained"
                                      color="success"
                                      onClick={() => {
                                        initiateBeneficiaryApproval(activeBeneficiary.uuid);
                                      }}
                                    >
                                      Approve
                                    </Button>
                                  </Box>
                                ) : (
                                  <Box
                                    sx={{
                                      display: "flex",
                                      flexDirection: "row",
                                      gap: 2,
                                    }}
                                  >
                                    <Box
                                      sx={{
                                        display: "flex",
                                        flexDirection: "column",
                                        justifyContent: "center",
                                      }}
                                    >
                                      <IconButton
                                        variant="contained"
                                        color="danger"
                                        onClick={(e) => {
                                          handleDeleteBeneficiary(e);
                                        }}
                                      >
                                        <Delete />
                                      </IconButton>
                                    </Box>
                                    <Box
                                      sx={{
                                        display: "flex",
                                        flexDirection: "column",
                                        justifyContent: "center",
                                      }}
                                    >
                                      <IconButton
                                        variant="contained"
                                        color="primary"
                                        onClick={() => {}}
                                      >
                                        <Edit />
                                      </IconButton>
                                    </Box>
                                    <Box
                                      sx={{
                                        display: "flex",
                                        flexDirection: "column",
                                        justifyContent: "center",
                                      }}
                                    >
                                      <Button
                                        disableElevation
                                        variant="contained"
                                        color="success"
                                        onClick={() => {
                                          setAmount(0);
                                          setReference(
                                            activeBeneficiary?.reference
                                              ? activeBeneficiary?.reference
                                              : ""
                                          );
                                          setCreatePayment(true);
                                        }}
                                      >
                                        Send payment
                                      </Button>
                                    </Box>
                                  </Box>
                                )}
                              </Box>
                            </Box>
                          </ListItem>
                        </List>
                      </Drawer>
                    </Box>
                  ) : (
                    <Box>
                      <NoResults message={`Create the first beneficiary to send a payment`} />
                    </Box>
                  )
                ) : (
                  <Box>
                    <Lottie speed={2.0} options={defaultOptions} height={200} width={200} />
                  </Box>
                )}
              </Box>
            ) : (
              <Box>
                {transactionsLoaded ? (
                  <Box>
                    <Box
                      sx={{
                        display: "flex",
                        gap: 2,
                        p: 2,
                        borderBottom: 1,
                        borderColor: "divider",
                        justifyContent: "space-between",
                        backgroundColor: "#0b0b0b",
                        color: "#ffffff",
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          gap: 2,
                        }}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            minWidth: 200,
                          }}
                        >
                          <Typography variant="subtitle2">Transaction time</Typography>
                        </Box>
                        <Box>
                          <Box sx={{ display: "flex", flexDirection: "column" }}>
                            <Typography variant="subtitle2">Transaction counterparty</Typography>
                            <Typography variant="subtitle2">Transaction reference</Typography>
                          </Box>
                        </Box>
                      </Box>

                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                        }}
                      >
                        <Typography variant="subtitle2">Amount</Typography>
                      </Box>
                    </Box>

                    {pendingTransactions?.length > 0 &&
                      pendingTransactions.map((transaction, index) => (
                        <Box
                          key={index}
                          sx={{
                            display: "flex",
                            gap: 2,

                            borderTop: 1,
                            borderColor: "divider",
                            justifyContent: "space-between",
                            cursor: "pointer",
                          }}
                          onClick={() => {
                            getPaymentRequest(transaction.uuid);
                          }}
                        >
                          <Box sx={{ display: "flex", gap: 2 }}>
                            <Box
                              sx={{
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "center",
                                minWidth: 200,
                                borderRight: 1,
                                borderColor: "divider",
                                p: 2,
                              }}
                            >
                              <Typography variant="subtitle2">
                                {moment.unix(transaction.timestamp).format("DD/MM/YYYY HH:mm A")}
                              </Typography>
                            </Box>
                            <Box>
                              <Box sx={{ display: "flex", flexDirection: "column", p: 2 }}>
                                <Typography variant="subtitle2">
                                  {transaction.counterparty_name}
                                </Typography>
                                <Typography variant="subtitle2">{transaction.reference}</Typography>
                              </Box>
                            </Box>
                          </Box>

                          <Box sx={{ display: "flex", flexDirection: "row", gap: 4 }}>
                            {transaction.requires_approval ? (
                              <Chip
                                className="square-chip"
                                label="** Requires approval **"
                                sx={{ textTransform: "uppercase" }}
                                color="secondary"
                              />
                            ) : (
                              <Chip
                                className="square-chip"
                                label="Processing"
                                sx={{ textTransform: "uppercase" }}
                                color="info"
                              />
                            )}
                            <Box
                              sx={{
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "center",
                                p: 2,
                                minWidth: 175,
                                textAlign: "right",
                              }}
                            >
                              <Typography variant="subtitle2">
                                {formatCurrency(transaction.amount, transaction.currency)}{" "}
                                {transaction.credit ? "Cr" : "Dr"}
                              </Typography>
                            </Box>
                          </Box>
                        </Box>
                      ))}

                    {accountTransactions?.length > 0 && (
                      <Box>
                        {accountTransactions.map((transaction, index) => (
                          <Box
                            key={index}
                            sx={{
                              display: "flex",
                              gap: 2,
                              borderTop: 1,
                              borderColor: "divider",
                              justifyContent: "space-between",
                              cursor: "pointer",
                            }}
                            onClick={() => {
                              getTransaction(transaction.uuid);
                            }}
                          >
                            <Box sx={{ display: "flex", gap: 2 }}>
                              <Box
                                sx={{
                                  display: "flex",
                                  flexDirection: "column",
                                  justifyContent: "center",
                                  minWidth: 200,
                                  borderRight: 1,
                                  borderColor: "divider",
                                  p: 2,
                                }}
                              >
                                <Typography variant="subtitle2">
                                  {moment.unix(transaction.timestamp).format("DD/MM/YYYY HH:mm A")}
                                </Typography>
                              </Box>
                              <Box>
                                <Box sx={{ display: "flex", flexDirection: "column", p: 2 }}>
                                  <Typography variant="subtitle2">
                                    {transaction.counterparty_name}
                                  </Typography>
                                  <Typography variant="subtitle2">
                                    {transaction.reference}
                                  </Typography>
                                </Box>
                              </Box>
                            </Box>

                            <Box
                              sx={{
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "center",
                                p: 2,
                                backgroundColor: transaction.credit ? "success.main" : "#ffffff",
                                width: 200,
                                textAlign: "right",
                              }}
                            >
                              <Typography variant="subtitle2">
                                {formatCurrency(transaction.amount, transaction.currency)}{" "}
                                {transaction.credit ? "Cr" : "Dr"}
                              </Typography>
                            </Box>
                          </Box>
                        ))}
                      </Box>
                    )}

                    {pendingTransactions?.length === 0 && accountTransactions?.length === 0 && (
                      <Box>
                        <NoResults message={`Waiting for the first transaction`} />
                      </Box>
                    )}
                  </Box>
                ) : (
                  <Box>
                    <Lottie speed={2.0} options={defaultOptions} height={200} width={200} />
                  </Box>
                )}

                <Drawer
                  anchor="right"
                  open={viewPaymentRequest}
                  onClose={() => setViewPaymentRequest(false)}
                >
                  <Box sx={{ minWidth: "50vw" }}>
                    <Box
                      sx={{
                        p: 2,
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "space-between",
                      }}
                    >
                      <Box
                        sx={{ display: "flex", flexDirection: "column", justifyContent: "center" }}
                      >
                        <Typography variant="h6">
                          Payment request for {activePaymentRequest?.counterparty_name}
                        </Typography>
                      </Box>
                      <Box sx={{ display: "flex", gap: 2 }}>
                        <IconButton
                          onClick={() => {
                            setActivePaymentRequest({});
                            setViewPaymentRequest(false);
                          }}
                        >
                          <Close />
                        </IconButton>
                      </Box>
                    </Box>

                    <Divider />

                    <Box sx={{ p: 2 }}>
                      <Typography variant="body1" sx={{ fontWeight: 600 }}>
                        Beneficiary details
                      </Typography>
                      <Box sx={{ border: 1, borderColor: "divider", mt: 2 }}>
                        <Box sx={{ display: "flex" }}>
                          <Box
                            sx={{
                              borderRight: 1,
                              borderColor: "divider",
                              display: "flex",
                              flexDirection: "column",
                              justifyContent: "center",
                              p: 2,
                              width: "50%",
                              textTransform: "uppercase",
                            }}
                          >
                            <Typography variant="subtitle2">Beneficiary name</Typography>
                          </Box>
                          <Box
                            sx={{
                              textAlign: "right",
                              display: "flex",
                              flexDirection: "column",
                              justifyContent: "center",
                              width: "50%",
                              p: 2,
                            }}
                          >
                            <Typography variant="subtitle1">
                              {activePaymentRequest?.counterparty_name}
                            </Typography>
                          </Box>
                        </Box>

                        <Box sx={{ display: "flex", borderTop: 1, borderColor: "divider" }}>
                          <Box
                            sx={{
                              borderRight: 1,
                              borderColor: "divider",
                              display: "flex",
                              flexDirection: "column",
                              justifyContent: "center",
                              p: 2,
                              width: "50%",
                              textTransform: "uppercase",
                            }}
                          >
                            <Typography variant="subtitle2">Sortcode</Typography>
                          </Box>
                          <Box
                            sx={{
                              textAlign: "right",
                              display: "flex",
                              flexDirection: "column",
                              justifyContent: "center",
                              width: "50%",
                              p: 2,
                            }}
                          >
                            <Typography variant="subtitle1">
                              {activePaymentRequest?.sortcode}
                            </Typography>
                          </Box>
                        </Box>
                        <Box sx={{ display: "flex", borderTop: 1, borderColor: "divider" }}>
                          <Box
                            sx={{
                              borderRight: 1,
                              borderColor: "divider",
                              display: "flex",
                              flexDirection: "column",
                              justifyContent: "center",
                              p: 2,
                              width: "50%",
                              textTransform: "uppercase",
                            }}
                          >
                            <Typography variant="subtitle2">Account number</Typography>
                          </Box>
                          <Box
                            sx={{
                              textAlign: "right",
                              display: "flex",
                              flexDirection: "column",
                              justifyContent: "center",
                              width: "50%",
                              p: 2,
                            }}
                          >
                            <Typography variant="subtitle1">
                              {activePaymentRequest?.account_number}
                            </Typography>
                          </Box>
                        </Box>
                        {activePaymentRequest?.bic && (
                          <Box sx={{ display: "flex", borderTop: 1, borderColor: "divider" }}>
                            <Box
                              sx={{
                                borderRight: 1,
                                borderColor: "divider",
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "center",
                                p: 2,
                                width: "50%",
                                textTransform: "uppercase",
                              }}
                            >
                              <Typography variant="subtitle2">BIC</Typography>
                            </Box>
                            <Box
                              sx={{
                                textAlign: "right",
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "center",
                                width: "50%",
                                p: 2,
                              }}
                            >
                              <Typography variant="subtitle1">
                                {activePaymentRequest?.bic}
                              </Typography>
                            </Box>
                          </Box>
                        )}
                        {activePaymentRequest?.iban && (
                          <Box sx={{ display: "flex", borderTop: 1, borderColor: "divider" }}>
                            <Box
                              sx={{
                                borderRight: 1,
                                borderColor: "divider",
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "center",
                                p: 2,
                                width: "50%",
                                textTransform: "uppercase",
                              }}
                            >
                              <Typography variant="subtitle2">IBAN</Typography>
                            </Box>
                            <Box
                              sx={{
                                textAlign: "right",
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "center",
                                width: "50%",
                                p: 2,
                              }}
                            >
                              <Typography variant="subtitle1">
                                {activePaymentRequest?.iban}
                              </Typography>
                            </Box>
                          </Box>
                        )}
                      </Box>
                    </Box>

                    <Box sx={{ p: 2 }}>
                      <Typography variant="body1" sx={{ fontWeight: 600 }}>
                        Payment information
                      </Typography>

                      <Box sx={{ border: 1, borderColor: "divider", mt: 2 }}>
                        <Box sx={{ display: "flex", borderTop: 0, borderColor: "divider" }}>
                          <Box
                            sx={{
                              borderRight: 1,
                              borderColor: "divider",
                              display: "flex",
                              flexDirection: "column",
                              justifyContent: "center",
                              p: 2,
                              width: "50%",
                              textTransform: "uppercase",
                            }}
                          >
                            <Typography variant="subtitle2">Payment amount</Typography>
                          </Box>
                          <Box
                            sx={{
                              textAlign: "right",
                              display: "flex",
                              flexDirection: "column",
                              justifyContent: "center",
                              width: "50%",
                              p: 2,
                            }}
                          >
                            {activePaymentRequest?.currency && (
                              <Typography variant="subtitle1">
                                {formatCurrency(
                                  activePaymentRequest?.amount,
                                  activePaymentRequest?.currency
                                )}
                              </Typography>
                            )}
                          </Box>
                        </Box>

                        <Box sx={{ display: "flex", borderTop: 1, borderColor: "divider" }}>
                          <Box
                            sx={{
                              borderRight: 1,
                              borderColor: "divider",
                              display: "flex",
                              flexDirection: "column",
                              justifyContent: "center",
                              p: 2,
                              width: "50%",
                              textTransform: "uppercase",
                            }}
                          >
                            <Typography variant="subtitle2">Payment reference</Typography>
                          </Box>
                          <Box
                            sx={{
                              textAlign: "right",
                              display: "flex",
                              flexDirection: "column",
                              justifyContent: "center",
                              width: "50%",
                              p: 2,
                            }}
                          >
                            <Typography variant="subtitle1">
                              {activePaymentRequest?.reference}
                            </Typography>
                          </Box>
                        </Box>
                      </Box>
                    </Box>

                    <Box sx={{ p: 2 }}>
                      <Typography variant="body1" sx={{ fontWeight: 600 }}>
                        Approvals ({activePaymentRequest?.approvals_count} /{" "}
                        {activePaymentRequest?.approvals_required})
                      </Typography>
                      <Box sx={{ border: 1, borderColor: "divider", mt: 2 }}>
                        {activePaymentRequest?.approvals_count > 0 ? (
                          activePaymentRequest?.approvals.map((apd, index) => (
                            <Box key={index} sx={{ display: "flex" }}>
                              <Box
                                sx={{
                                  borderRight: 1,
                                  borderColor: "divider",
                                  display: "flex",
                                  flexDirection: "column",
                                  justifyContent: "center",
                                  p: 2,
                                  width: "50%",
                                  textTransform: "uppercase",
                                }}
                              >
                                <Typography variant="subtitle2">{apd.name}</Typography>
                              </Box>

                              <Box
                                sx={{
                                  textAlign: "right",
                                  display: "flex",
                                  flexDirection: "column",
                                  justifyContent: "center",
                                  width: "50%",
                                  p: 2,
                                }}
                              >
                                <Typography variant="subtitle1">
                                  {moment.unix(apd.timestamp).format("DD/MM/YYYY HH:mm A")}
                                </Typography>
                              </Box>
                            </Box>
                          ))
                        ) : (
                          <Box>
                            <NoResults message={`Waiting for the first approval`} />
                          </Box>
                        )}
                      </Box>
                    </Box>
                  </Box>

                  <List style={{ marginTop: `auto`, width: "100%", border: 1 }}>
                    <ListItem sx={{ width: "100%" }}>
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-between",

                          width: "100%",
                        }}
                      >
                        <Box sx={{ width: "50%" }}>
                          <Box sx={{ display: "flex", gap: 1, color: "secondary.dark" }}>
                            <Typography variant="subtitle2" sx={{ textTransform: "uppercase" }}>
                              Request created:
                            </Typography>
                            <Typography variant="subtitle2">
                              {moment.unix(activePaymentRequest?.created).format("DD/MM/YYYY")}
                            </Typography>
                          </Box>
                          <Box sx={{ display: "flex", gap: 1, color: "secondary.dark" }}>
                            <Typography variant="subtitle2" sx={{ textTransform: "uppercase" }}>
                              Created by:
                            </Typography>
                            <Typography variant="subtitle2">
                              {activePaymentRequest?.created_by}
                            </Typography>
                          </Box>
                          {activePaymentRequest?.executed_timestamp > 0 && (
                            <Box sx={{ display: "flex", gap: 1, color: "secondary.dark" }}>
                              <Typography variant="subtitle2" sx={{ textTransform: "uppercase" }}>
                                Approved:
                              </Typography>
                              <Typography variant="subtitle2">
                                {moment
                                  .unix(activePaymentRequest?.executed_timestamp)
                                  .format("DD/MM/YYYY HH:mm A")}
                              </Typography>
                            </Box>
                          )}
                        </Box>
                        <Box
                          sx={{
                            width: "50%",
                            display: "flex",
                            gap: 2,
                            justifyContent: "flex-end",
                          }}
                        >
                          {activePaymentRequest?.approvals_required >
                            activePaymentRequest?.approvals_count && (
                            <Box
                              sx={{
                                display: "flex",
                                flexDirection: "row",
                                gap: 2,
                              }}
                            >
                              <Box
                                sx={{
                                  display: "flex",
                                  flexDirection: "column",
                                  justifyContent: "center",
                                }}
                              >
                                <IconButton
                                  variant="contained"
                                  color="danger"
                                  onClick={(e) => {
                                    handlePaymentCancellation(e);
                                  }}
                                >
                                  <Delete />
                                </IconButton>
                              </Box>
                              <Box
                                sx={{
                                  display: "flex",
                                  flexDirection: "column",
                                  justifyContent: "center",
                                }}
                              >
                                <Button
                                  variant="contained"
                                  color="success"
                                  onClick={() => {
                                    initiatePaymentApproval(activePaymentRequest.request_uuid);
                                  }}
                                >
                                  Approve payment
                                </Button>
                              </Box>
                            </Box>
                          )}
                        </Box>
                      </Box>
                    </ListItem>
                  </List>
                </Drawer>

                <Drawer
                  anchor="right"
                  open={viewTransaction}
                  onClose={() => setViewTransaction(false)}
                >
                  <Box sx={{ minWidth: "50vw" }}>
                    <Box
                      sx={{
                        p: 2,
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "space-between",
                      }}
                    >
                      <Box
                        sx={{ display: "flex", flexDirection: "column", justifyContent: "center" }}
                      >
                        <Typography variant="h6">
                          {activeTransaction?.credit
                            ? "Payment receipt"
                            : `Payment sent to ${activeTransaction?.counterparty_name}`}
                        </Typography>
                      </Box>
                      <Box sx={{ display: "flex", gap: 2 }}>
                        <IconButton
                          onClick={() => {
                            setActiveTransaction({});
                            setViewTransaction(false);
                          }}
                        >
                          <Close />
                        </IconButton>
                      </Box>
                    </Box>

                    <Divider />

                    <Box sx={{ p: 2 }}>
                      <Box sx={{ border: 1, borderColor: "divider", mt: 2 }}>
                        <Box sx={{ display: "flex", borderTop: 0, borderColor: "divider" }}>
                          <Box
                            sx={{
                              borderRight: 1,
                              borderColor: "divider",
                              display: "flex",
                              flexDirection: "column",
                              justifyContent: "center",
                              p: 2,
                              width: "50%",
                              textTransform: "uppercase",
                            }}
                          >
                            <Typography variant="subtitle2">Transaction time</Typography>
                          </Box>
                          <Box
                            sx={{
                              textAlign: "right",
                              display: "flex",
                              flexDirection: "column",
                              justifyContent: "center",
                              width: "50%",
                              p: 2,
                            }}
                          >
                            {activeTransaction?.currency && (
                              <Typography variant="subtitle1">
                                {moment
                                  .unix(activeTransaction?.timestamp)
                                  .format("DD/MM/YYYY HH:mm A")}
                              </Typography>
                            )}
                          </Box>
                        </Box>
                      </Box>
                    </Box>

                    <Box sx={{ p: 2 }}>
                      <Typography variant="body1" sx={{ fontWeight: 600 }}>
                        {activeTransaction?.credit
                          ? "Sender information"
                          : "Beneficiary information"}
                      </Typography>
                      <Box sx={{ border: 1, borderColor: "divider", mt: 2 }}>
                        <Box sx={{ display: "flex" }}>
                          <Box
                            sx={{
                              borderRight: 1,
                              borderColor: "divider",
                              display: "flex",
                              flexDirection: "column",
                              justifyContent: "center",
                              p: 2,
                              width: "50%",
                              textTransform: "uppercase",
                            }}
                          >
                            <Typography variant="subtitle2">
                              {activeTransaction?.credit ? "Sender" : "Beneficiary"} name
                            </Typography>
                          </Box>
                          <Box
                            sx={{
                              textAlign: "right",
                              display: "flex",
                              flexDirection: "column",
                              justifyContent: "center",
                              width: "50%",
                              p: 2,
                            }}
                          >
                            <Typography variant="subtitle1">
                              {activeTransaction?.counterparty_name}
                            </Typography>
                          </Box>
                        </Box>

                        <Box sx={{ display: "flex", borderTop: 1, borderColor: "divider" }}>
                          <Box
                            sx={{
                              borderRight: 1,
                              borderColor: "divider",
                              display: "flex",
                              flexDirection: "column",
                              justifyContent: "center",
                              p: 2,
                              width: "50%",
                              textTransform: "uppercase",
                            }}
                          >
                            <Typography variant="subtitle2">Sortcode</Typography>
                          </Box>
                          <Box
                            sx={{
                              textAlign: "right",
                              display: "flex",
                              flexDirection: "column",
                              justifyContent: "center",
                              width: "50%",
                              p: 2,
                            }}
                          >
                            <Typography variant="subtitle1">
                              {activeTransaction?.counterparty_sortcode}
                            </Typography>
                          </Box>
                        </Box>
                        <Box sx={{ display: "flex", borderTop: 1, borderColor: "divider" }}>
                          <Box
                            sx={{
                              borderRight: 1,
                              borderColor: "divider",
                              display: "flex",
                              flexDirection: "column",
                              justifyContent: "center",
                              p: 2,
                              width: "50%",
                              textTransform: "uppercase",
                            }}
                          >
                            <Typography variant="subtitle2">Account number</Typography>
                          </Box>
                          <Box
                            sx={{
                              textAlign: "right",
                              display: "flex",
                              flexDirection: "column",
                              justifyContent: "center",
                              width: "50%",
                              p: 2,
                            }}
                          >
                            <Typography variant="subtitle1">
                              {activeTransaction?.counterparty_account_number}
                            </Typography>
                          </Box>
                        </Box>
                        {activeTransaction?.counterparty_bic && (
                          <Box sx={{ display: "flex", borderTop: 1, borderColor: "divider" }}>
                            <Box
                              sx={{
                                borderRight: 1,
                                borderColor: "divider",
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "center",
                                p: 2,
                                width: "50%",
                                textTransform: "uppercase",
                              }}
                            >
                              <Typography variant="subtitle2">BIC</Typography>
                            </Box>
                            <Box
                              sx={{
                                textAlign: "right",
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "center",
                                width: "50%",
                                p: 2,
                              }}
                            >
                              <Typography variant="subtitle1">
                                {activeTransaction?.counterparty_bic}
                              </Typography>
                            </Box>
                          </Box>
                        )}
                        {activeTransaction?.counterparty_iban && (
                          <Box sx={{ display: "flex", borderTop: 1, borderColor: "divider" }}>
                            <Box
                              sx={{
                                borderRight: 1,
                                borderColor: "divider",
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "center",
                                p: 2,
                                width: "50%",
                                textTransform: "uppercase",
                              }}
                            >
                              <Typography variant="subtitle2">IBAN</Typography>
                            </Box>
                            <Box
                              sx={{
                                textAlign: "right",
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "center",
                                width: "50%",
                                p: 2,
                              }}
                            >
                              <Typography variant="subtitle1">
                                {activeTransaction?.counterparty_iban}
                              </Typography>
                            </Box>
                          </Box>
                        )}
                      </Box>
                    </Box>

                    <Box sx={{ p: 2 }}>
                      <Typography variant="body1" sx={{ fontWeight: 600 }}>
                        {activeTransaction?.credit ? "Credit" : "Payment"} information
                      </Typography>

                      <Box sx={{ border: 1, borderColor: "divider", mt: 2 }}>
                        <Box sx={{ display: "flex", borderTop: 0, borderColor: "divider" }}>
                          <Box
                            sx={{
                              borderRight: 1,
                              borderColor: "divider",
                              display: "flex",
                              flexDirection: "column",
                              justifyContent: "center",
                              p: 2,
                              width: "50%",
                              textTransform: "uppercase",
                            }}
                          >
                            <Typography variant="subtitle2">
                              {activeTransaction?.credit ? "Credit" : "Payment"} amount
                            </Typography>
                          </Box>
                          <Box
                            sx={{
                              textAlign: "right",
                              display: "flex",
                              flexDirection: "column",
                              justifyContent: "center",
                              width: "50%",
                              p: 2,
                            }}
                          >
                            {activeTransaction?.currency && (
                              <Typography variant="subtitle1">
                                {formatCurrency(
                                  activeTransaction?.amount,
                                  activeTransaction?.currency
                                )}
                              </Typography>
                            )}
                          </Box>
                        </Box>

                        <Box sx={{ display: "flex", borderTop: 1, borderColor: "divider" }}>
                          <Box
                            sx={{
                              borderRight: 1,
                              borderColor: "divider",
                              display: "flex",
                              flexDirection: "column",
                              justifyContent: "center",
                              p: 2,
                              width: "50%",
                              textTransform: "uppercase",
                            }}
                          >
                            <Typography variant="subtitle2">Payment reference</Typography>
                          </Box>
                          <Box
                            sx={{
                              textAlign: "right",
                              display: "flex",
                              flexDirection: "column",
                              justifyContent: "center",
                              width: "50%",
                              p: 2,
                            }}
                          >
                            <Typography variant="subtitle1">
                              {activeTransaction?.reference}
                            </Typography>
                          </Box>
                        </Box>
                      </Box>
                    </Box>

                    {!activeTransaction?.credit &&
                      !activeTransaction?.is_fee &&
                      activeTransaction?.approvals_required > 0 && (
                        <Box sx={{ p: 2 }}>
                          <Typography variant="body1" sx={{ fontWeight: 600 }}>
                            Approvals ({activeTransaction?.approvals_count} /{" "}
                            {activeTransaction?.approvals_required})
                          </Typography>
                          <Box sx={{ border: 1, borderColor: "divider", mt: 2 }}>
                            {activeTransaction?.approvals_count > 0 ? (
                              activeTransaction?.approvals.map((apd, index) => (
                                <Box key={index} sx={{ display: "flex" }}>
                                  <Box
                                    sx={{
                                      borderRight: 1,
                                      borderColor: "divider",
                                      display: "flex",
                                      flexDirection: "column",
                                      justifyContent: "center",
                                      p: 2,
                                      width: "50%",
                                      textTransform: "uppercase",
                                    }}
                                  >
                                    <Typography variant="subtitle2">{apd.name}</Typography>
                                  </Box>

                                  <Box
                                    sx={{
                                      textAlign: "right",
                                      display: "flex",
                                      flexDirection: "column",
                                      justifyContent: "center",
                                      width: "50%",
                                      p: 2,
                                    }}
                                  >
                                    <Typography variant="subtitle1">
                                      {moment.unix(apd.timestamp).format("DD/MM/YYYY HH:mm A")}
                                    </Typography>
                                  </Box>
                                </Box>
                              ))
                            ) : (
                              <Box>
                                <NoResults message={`Waiting for the first approval`} />
                              </Box>
                            )}
                          </Box>
                        </Box>
                      )}
                  </Box>

                  <List style={{ marginTop: `auto`, width: "100%", border: 1 }}>
                    <ListItem sx={{ width: "100%" }}>
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-between",

                          width: "100%",
                        }}
                      >
                        <Box sx={{ width: "50%" }}>
                          {!activeTransaction?.credit && !activeTransaction?.is_fee && (
                            <>
                              <Box sx={{ display: "flex", gap: 1, color: "secondary.dark" }}>
                                <Typography variant="subtitle2" sx={{ textTransform: "uppercase" }}>
                                  Request created:
                                </Typography>
                                <Typography variant="subtitle2">
                                  {moment.unix(activeTransaction?.created).format("DD/MM/YYYY")}
                                </Typography>
                              </Box>
                              <Box sx={{ display: "flex", gap: 1, color: "secondary.dark" }}>
                                <Typography variant="subtitle2" sx={{ textTransform: "uppercase" }}>
                                  Created by:
                                </Typography>
                                <Typography variant="subtitle2">
                                  {activeTransaction?.created_by}
                                </Typography>
                              </Box>
                            </>
                          )}

                          {activeTransaction?.executed_timestamp > 0 && (
                            <Box sx={{ display: "flex", gap: 1, color: "secondary.dark" }}>
                              <Typography variant="subtitle2" sx={{ textTransform: "uppercase" }}>
                                Approved:
                              </Typography>
                              <Typography variant="subtitle2">
                                {moment
                                  .unix(activeTransaction?.executed_timestamp)
                                  .format("DD/MM/YYYY HH:mm A")}
                              </Typography>
                            </Box>
                          )}
                        </Box>
                        {/*  <Box
                          sx={{
                            width: "50%",
                            display: "flex",
                            gap: 2,
                            justifyContent: "flex-end",
                          }}
                        >
                          <Button variant="contained" color="primary" onClick={() => {}}>
                            Add note
                          </Button>
                          <Button variant="contained" color="primary" onClick={() => {}}>
                            Add file
                          </Button>
                        </Box> */}
                      </Box>
                    </ListItem>
                  </List>
                </Drawer>
              </Box>
            )}

            <Modal
              open={paymentAuthentication}
              onClose={() => setPaymentAuthentication(false)}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box
                sx={{
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                  width: "30%",
                  minWidth: 800,
                  bgcolor: "background.paper",
                  border: 2,
                  borderColor: "divider",
                  boxShadow: 24,
                  p: 4,
                }}
              >
                <Box sx={{ width: "100%", border: 1, borderColor: "divider" }}>
                  <Box
                    variant="div"
                    align="center"
                    alignContent={"center"}
                    sx={{ marginLeft: "auto", marginRight: "auto", width: "fit-content" }}
                  >
                    {/* <Lottie options={defaultOptions} height={200} width={250} isStopped={false} isPaused={false} /> */}
                  </Box>

                  <Box
                    sx={{
                      p: 2,
                      borderBottom: 1,
                      borderColor: "divider",
                      backgroundColor: "#0b0b0b",
                      color: "#ffffff",
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <Box
                      sx={{ display: "flex", flexDirection: "column", justifyContent: "center" }}
                    >
                      <Typography variant="subtitle2">Verifying your request</Typography>
                    </Box>
                    <Box></Box>
                  </Box>

                  {videoPaymentCapture ? (
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                      }}
                    >
                      <VideoCapture
                        setVideoCapture={setVideoPaymentCapture}
                        setVideoContent={setVideoContent}
                        setFaceImage={setFaceImage}
                      />
                    </Box>
                  ) : (
                    <Box sx={{ p: 2, pb: 0 }}>
                      <Typography
                        sx={{ textTransform: "uppercase" }}
                        align="center"
                        variant="h6"
                        gutterBottom
                      >
                        <strong>Two factor authentication</strong>
                      </Typography>
                      <Typography
                        sx={{ mb: 2, textTransform: "uppercase" }}
                        align="center"
                        variant="h6"
                        gutterBottom
                      >
                        <strong>Enter the code from your authenticator app</strong>
                      </Typography>

                      <Grid container spacing={2} sx={{ mt: -2 }}>
                        <Grid item xs={2}>
                          <TextField
                            id="input1"
                            type="tel"
                            inputProps={{
                              min: 0,
                              maxLength: 1,
                              pattern: "[0-9]*",
                              style: { textAlign: "center", fontSize: 32 },
                            }}
                            fullWidth
                            autoFocus
                            variant="outlined"
                            value={code1}
                            onChange={(e) => setCode1(e.target.value)}
                            onKeyUp={(e) => {
                              if (e.target.value.length === 1) {
                                document.getElementById("input2").focus();
                              }
                            }}
                          />
                        </Grid>
                        <Grid item xs={2}>
                          <TextField
                            id="input2"
                            inputProps={{
                              min: 0,
                              maxLength: 1,
                              pattern: "[0-9]*",
                              style: { textAlign: "center", fontSize: 32 },
                            }}
                            fullWidth
                            variant="outlined"
                            value={code2}
                            onChange={(e) => setCode2(e.target.value)}
                            onKeyUp={(e) => {
                              if (e.target.value.length === 1) {
                                document.getElementById("input3").focus();
                              } else if (e.key === "Backspace") {
                                document.getElementById("input1").focus();
                              }
                            }}
                          />
                        </Grid>
                        <Grid item xs={2}>
                          <TextField
                            id="input3"
                            inputProps={{
                              min: 0,
                              maxLength: 1,
                              pattern: "[0-9]*",
                              style: { textAlign: "center", fontSize: 32 },
                            }}
                            fullWidth
                            variant="outlined"
                            value={code3}
                            onChange={(e) => setCode3(e.target.value)}
                            onKeyUp={(e) => {
                              if (e.target.value.length === 1) {
                                document.getElementById("input4").focus();
                              } else if (e.key === "Backspace") {
                                document.getElementById("input2").focus();
                              }
                            }}
                          />
                        </Grid>
                        <Grid item xs={2}>
                          <TextField
                            id="input4"
                            inputProps={{
                              min: 0,
                              maxLength: 1,
                              pattern: "[0-9]*",
                              style: { textAlign: "center", fontSize: 32 },
                            }}
                            fullWidth
                            variant="outlined"
                            value={code4}
                            onChange={(e) => setCode4(e.target.value)}
                            onKeyUp={(e) => {
                              if (e.target.value.length === 1) {
                                document.getElementById("input5").focus();
                              } else if (e.key === "Backspace") {
                                document.getElementById("input3").focus();
                              }
                            }}
                          />
                        </Grid>
                        <Grid item xs={2}>
                          <TextField
                            id="input5"
                            inputProps={{
                              min: 0,
                              maxLength: 1,
                              pattern: "[0-9]*",
                              style: { textAlign: "center", fontSize: 32 },
                            }}
                            fullWidth
                            variant="outlined"
                            value={code5}
                            onChange={(e) => setCode5(e.target.value)}
                            onKeyUp={(e) => {
                              if (e.target.value.length === 1) {
                                document.getElementById("input6").focus();
                              } else if (e.key === "Backspace") {
                                document.getElementById("input4").focus();
                              }
                            }}
                          />
                        </Grid>
                        <Grid item xs={2}>
                          <TextField
                            id="input6"
                            inputProps={{
                              min: 0,
                              maxLength: 1,
                              pattern: "[0-9]*",
                              style: { textAlign: "center", fontSize: 32 },
                            }}
                            fullWidth
                            variant="outlined"
                            value={code6}
                            onChange={(e) => setCode6(e.target.value)}
                            onKeyUp={(e) => {
                              if (e.key === "Backspace") {
                                document.getElementById("input5").focus();
                              } else if (e.target.value !== "") {
                                // handleAuthentication(e);
                              }
                            }}
                          />
                        </Grid>
                      </Grid>

                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "flex-end",
                          mb: 0,
                        }}
                      >
                        {isPaymentApproval ? (
                          <LoadingButton
                            disableElevation
                            loading={processing}
                            onClick={(e) => {
                              handleApprovePayment(e);
                            }}
                            type="button"
                            variant="contained"
                            color="success"
                            sx={{ mt: 2, mb: 2 }}
                          >
                            Approve Payment
                          </LoadingButton>
                        ) : (
                          <LoadingButton
                            disableElevation
                            loading={processing}
                            onClick={(e) => {
                              handleCreatePaymentRequest(e);
                            }}
                            type="button"
                            variant="contained"
                            color="success"
                            sx={{ mt: 2, mb: 2 }}
                          >
                            Create Payment
                          </LoadingButton>
                        )}
                      </Box>
                    </Box>
                  )}
                </Box>
              </Box>
            </Modal>

            <Modal
              open={beneficiaryAuthentication}
              onClose={() => setBeneficiaryAuthentication(false)}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box
                sx={{
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                  width: "30%",
                  minWidth: 800,
                  bgcolor: "background.paper",
                  border: 2,
                  borderColor: "divider",
                  boxShadow: 24,
                  p: 4,
                }}
              >
                <Box sx={{ width: "100%", border: 1, borderColor: "divider" }}>
                  <Box
                    variant="div"
                    align="center"
                    alignContent={"center"}
                    sx={{ marginLeft: "auto", marginRight: "auto", width: "fit-content" }}
                  >
                    {/* <Lottie options={defaultOptions} height={200} width={250} isStopped={false} isPaused={false} /> */}
                  </Box>

                  <Box
                    sx={{
                      p: 2,
                      borderBottom: 1,
                      borderColor: "divider",
                      backgroundColor: "#0b0b0b",
                      color: "#ffffff",
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <Box
                      sx={{ display: "flex", flexDirection: "column", justifyContent: "center" }}
                    >
                      <Typography variant="subtitle2">Verifying your request</Typography>
                    </Box>
                    <Box></Box>
                  </Box>

                  {videoBeneficiaryCapture ? (
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                      }}
                    >
                      <VideoCapture
                        setVideoCapture={setVideoBeneficiaryCapture}
                        setVideoContent={setVideoContent}
                        setFaceImage={setFaceImage}
                      />
                    </Box>
                  ) : (
                    <Box sx={{ p: 2, pb: 0 }}>
                      <Typography
                        sx={{ textTransform: "uppercase" }}
                        align="center"
                        variant="h6"
                        gutterBottom
                      >
                        <strong>Two factor authentication</strong>
                      </Typography>
                      <Typography
                        sx={{ mb: 2, textTransform: "uppercase" }}
                        align="center"
                        variant="h6"
                        gutterBottom
                      >
                        <strong>Enter the code from your authenticator app</strong>
                      </Typography>

                      <Grid container spacing={2} sx={{ mt: -2 }}>
                        <Grid item xs={2}>
                          <TextField
                            id="input1"
                            type="tel"
                            inputProps={{
                              min: 0,
                              maxLength: 1,
                              pattern: "[0-9]*",
                              style: { textAlign: "center", fontSize: 32 },
                            }}
                            fullWidth
                            autoFocus
                            variant="outlined"
                            value={code1}
                            onChange={(e) => setCode1(e.target.value)}
                            onKeyUp={(e) => {
                              if (e.target.value.length === 1) {
                                document.getElementById("input2").focus();
                              }
                            }}
                          />
                        </Grid>
                        <Grid item xs={2}>
                          <TextField
                            id="input2"
                            inputProps={{
                              min: 0,
                              maxLength: 1,
                              pattern: "[0-9]*",
                              style: { textAlign: "center", fontSize: 32 },
                            }}
                            fullWidth
                            variant="outlined"
                            value={code2}
                            onChange={(e) => setCode2(e.target.value)}
                            onKeyUp={(e) => {
                              if (e.target.value.length === 1) {
                                document.getElementById("input3").focus();
                              } else if (e.key === "Backspace") {
                                document.getElementById("input1").focus();
                              }
                            }}
                          />
                        </Grid>
                        <Grid item xs={2}>
                          <TextField
                            id="input3"
                            inputProps={{
                              min: 0,
                              maxLength: 1,
                              pattern: "[0-9]*",
                              style: { textAlign: "center", fontSize: 32 },
                            }}
                            fullWidth
                            variant="outlined"
                            value={code3}
                            onChange={(e) => setCode3(e.target.value)}
                            onKeyUp={(e) => {
                              if (e.target.value.length === 1) {
                                document.getElementById("input4").focus();
                              } else if (e.key === "Backspace") {
                                document.getElementById("input2").focus();
                              }
                            }}
                          />
                        </Grid>
                        <Grid item xs={2}>
                          <TextField
                            id="input4"
                            inputProps={{
                              min: 0,
                              maxLength: 1,
                              pattern: "[0-9]*",
                              style: { textAlign: "center", fontSize: 32 },
                            }}
                            fullWidth
                            variant="outlined"
                            value={code4}
                            onChange={(e) => setCode4(e.target.value)}
                            onKeyUp={(e) => {
                              if (e.target.value.length === 1) {
                                document.getElementById("input5").focus();
                              } else if (e.key === "Backspace") {
                                document.getElementById("input3").focus();
                              }
                            }}
                          />
                        </Grid>
                        <Grid item xs={2}>
                          <TextField
                            id="input5"
                            inputProps={{
                              min: 0,
                              maxLength: 1,
                              pattern: "[0-9]*",
                              style: { textAlign: "center", fontSize: 32 },
                            }}
                            fullWidth
                            variant="outlined"
                            value={code5}
                            onChange={(e) => setCode5(e.target.value)}
                            onKeyUp={(e) => {
                              if (e.target.value.length === 1) {
                                document.getElementById("input6").focus();
                              } else if (e.key === "Backspace") {
                                document.getElementById("input4").focus();
                              }
                            }}
                          />
                        </Grid>
                        <Grid item xs={2}>
                          <TextField
                            id="input6"
                            inputProps={{
                              min: 0,
                              maxLength: 1,
                              pattern: "[0-9]*",
                              style: { textAlign: "center", fontSize: 32 },
                            }}
                            fullWidth
                            variant="outlined"
                            value={code6}
                            onChange={(e) => setCode6(e.target.value)}
                            onKeyUp={(e) => {
                              if (e.key === "Backspace") {
                                document.getElementById("input5").focus();
                              } else if (e.target.value !== "") {
                                // handleAuthentication(e);
                              }
                            }}
                          />
                        </Grid>
                      </Grid>

                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "flex-end",
                          mb: 0,
                        }}
                      >
                        {isBeneficiaryApproval ? (
                          <LoadingButton
                            disableElevation
                            loading={processing}
                            onClick={(e) => {
                              handleApproveBeneficiary(e);
                            }}
                            type="button"
                            variant="contained"
                            color="success"
                            sx={{ mt: 2, mb: 2 }}
                          >
                            Create Beneficiary
                          </LoadingButton>
                        ) : (
                          <LoadingButton
                            disableElevation
                            loading={processing}
                            onClick={(e) => {
                              handleCreateBeneficiary(e);
                            }}
                            type="button"
                            variant="contained"
                            color="success"
                            sx={{ mt: 2, mb: 2 }}
                          >
                            Create Beneficiary
                          </LoadingButton>
                        )}
                      </Box>
                    </Box>
                  )}
                </Box>
              </Box>
            </Modal>
          </>
        ) : (
          <>
            <Box sx={{ display: "flex", justifyContent: "space-between", p: 2 }}>
              <Box>{openAccount && <Typography variant="h6">Account application</Typography>}</Box>
              <Box>
                {accounts?.length > 0 && (
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => {
                      setOpenAccount(!openAccount);
                    }}
                  >
                    {openAccount ? "Close" : "Open Account"}
                  </Button>
                )}
              </Box>
            </Box>
            <Divider />
            <Box>
              {openAccount ? (
                <Box sx={{ display: "flex" }}>
                  <Box sx={{ width: "30%" }}>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        borderRight: 1,
                        borderColor: "divider",
                      }}
                    >
                      <Box
                        className={`application-menu ${applicationSection == 0 ? "active" : ""}`}
                        onClick={() => setApplicationSection(0)}
                        sx={{
                          p: 2,
                          borderBottom: 1,
                          borderColor: "divider",
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                          }}
                        >
                          <Typography variant="button">Account usage</Typography>
                        </Box>
                        <Box
                          className={`application-menu-icon ${
                            applicationSection == 0 ? "active" : ""
                          }`}
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                          }}
                        >
                          <Edit />
                        </Box>
                      </Box>
                      <Box
                        className={`application-menu ${applicationSection == 1 ? "active" : ""}`}
                        onClick={() => setApplicationSection(1)}
                        sx={{
                          p: 2,
                          borderBottom: 1,
                          borderColor: "divider",
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                          }}
                        >
                          <Typography variant="button">Corporation</Typography>
                        </Box>
                        <Box
                          className={`application-menu-icon complete ${
                            applicationSection == 1 ? "active" : ""
                          }`}
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                          }}
                        >
                          <TaskAlt />
                        </Box>
                      </Box>
                      <Box
                        className={`application-menu ${applicationSection == 2 ? "active" : ""}`}
                        onClick={() => setApplicationSection(2)}
                        sx={{
                          p: 2,
                          borderBottom: 1,
                          borderColor: "divider",
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                          }}
                        >
                          <Typography variant="button">Ownership</Typography>
                        </Box>
                        <Box
                          className={`application-menu-icon complete ${
                            applicationSection == 2 ? "active" : ""
                          }`}
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                          }}
                        >
                          <TaskAlt />
                        </Box>
                      </Box>
                      <Box
                        className={`application-menu ${applicationSection == 3 ? "active" : ""}`}
                        onClick={() => setApplicationSection(3)}
                        sx={{
                          p: 2,
                          borderBottom: 1,
                          borderColor: "divider",
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                          }}
                        >
                          <Typography variant="button">Control</Typography>
                        </Box>
                        <Box
                          className={`application-menu-icon complete ${
                            applicationSection == 3 ? "active" : ""
                          }`}
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                          }}
                        >
                          <TaskAlt />
                        </Box>
                      </Box>
                      <Box
                        className={`application-menu ${applicationSection == 4 ? "active" : ""}`}
                        onClick={() => setApplicationSection(4)}
                        sx={{
                          p: 2,
                          borderBottom: 1,
                          borderColor: "divider",
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                          }}
                        >
                          <Typography variant="button">Submit Application</Typography>
                        </Box>
                        <Box></Box>
                      </Box>

                      <Typography
                        variant="caption"
                        sx={{ m: 2, fontWeight: 600, textTransform: "uppercase" }}
                      >
                        Accounts and payments powered by our EMI partner
                      </Typography>
                      <Box
                        component="img"
                        sx={{
                          mb: 2,
                          ml: "auto",
                          mr: "auto",
                          width: "100%",
                          maxHeight: { xs: 233, md: 167 },
                          maxWidth: { xs: 300, md: 100 },
                        }}
                        alt="The house from the offer."
                        src="/payfirm-logo.png"
                      />
                      <Divider />
                    </Box>
                  </Box>

                  <Box sx={{ p: 2, minHeight: 400, width: "85%" }}>
                    {applicationSection == 0 && (
                      <AccountUsage
                        clientData={clientData}
                        entityData={entityData}
                        applicationUUID={applicationUUID}
                        setApplicationUUID={setApplicationUUID}
                        applicationData={applicationData}
                        getAccounts={() => getAccounts()}
                      />
                    )}
                    {applicationSection == 1 && (
                      <Corporation clientData={clientData} entityData={entityData} />
                    )}
                    {applicationSection == 2 && (
                      <Ownership clientData={clientData} entityData={entityData} />
                    )}
                    {applicationSection == 3 && (
                      <Control clientData={clientData} entityData={entityData} />
                    )}
                    {applicationSection == 4 && (
                      <Submit
                        clientData={clientData}
                        entityData={entityData}
                        applicationUUID={applicationUUID}
                        applicationData={applicationData}
                        setOpenAccount={setOpenAccount}
                        getAccounts={() => getAccounts()}
                      />
                    )}
                  </Box>
                </Box>
              ) : (
                <Box sx={{ p: 2 }}>
                  {accounts?.length > 0 ? (
                    <Grid container spacing={2}>
                      {accounts.map((account, index) => (
                        <AccountCard
                          key={index}
                          accountData={account}
                          getAccountData={getAccountData}
                        />
                      ))}
                    </Grid>
                  ) : (
                    <Box>Apply for an account now</Box>
                  )}
                </Box>
              )}
            </Box>
          </>
        )}
      </>
    )
  ) : (
    <Box>
      <Lottie speed={2.0} options={defaultOptions} height={200} width={200} />
    </Box>
  );
};

export default Banking;
