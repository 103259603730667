import { React, useEffect, useState } from "react";

import partner from "../../../../../api/partner";
import central from "../../../../../api/central";
import authHeader from "../../../../../api/authHeader";

import HandleError from "../../../../../components/functions/HandleError";
import HandleToast from "../../../../../components/functions/HandleToast";

import Lottie from "react-lottie";
import loadingLottie from "../../../../../components/lotties/loading-dots.json";

import DataTable from "react-data-table-component";

import {
  Typography,
  Box,
  Button,
  Divider,
  Card,
  Avatar,
  CardHeader,
  CardContent,
  IconButton,
  Alert,
  Fade,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import { Grid, TextField, FormControl, InputLabel, MenuItem, Select, ToggleButton, ToggleButtonGroup, Chip } from "@mui/material";
import { LoadingButton } from "@mui/lab";

import { Add, Close, Delete, Edit } from "@mui/icons-material";

const DataSources = ({ partner, partnerUser, partnerData }) => {
  const useAuthHeader = authHeader();

  const handleError = HandleError();
  const displayToast = HandleToast();

  const [isLoaded, setIsLoaded] = useState(false);
  const [processing, setProcessing] = useState(false);
  const [data, setData] = useState([]);
  const [optionsData, setOptionsData] = useState([]);

  const [sourceType, setSourceType] = useState(1); // 1 sanctions | 2 peps | 3 warnings
  const [manageSources, setManageSources] = useState(false);

  const [removeSourceDialog, setRemoveSourceDialog] = useState(false);
  const [removeSource, setRemoveSource] = useState("");

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: loadingLottie,
  };

  const customStyles = {
    headCells: {
      style: {
        paddingLeft: "8px", // override the cell padding for head cells
        paddingRight: "8px",
        fontWeight: "bold",
      },
    },
    cells: {
      style: {
        paddingLeft: "8px", // override the cell padding for data cells
        paddingRight: "8px",
      },
    },
  };

  const columns = [
    {
      name: "List name",
      sortable: true,
      width: "75%",
      selector: (row) => row.name,
    },
    {
      name: "Country",
      sortable: true,
      right: "true",
      width: "25%",
      selector: (row) => row.country.name,
    },
  ];

  const manageColumns = [
    {
      name: "List name",
      sortable: true,
      width: "65%",
      selector: (row) => row.name,
    },
    {
      name: "Country",
      sortable: true,
      right: "true",
      width: "25%",
      selector: (row) => row.country.name,
    },
    {
      name: "Options",
      sortable: true,
      right: "true",
      width: "10%",
      selector: (row) => row.active,
      cell: (row) => (
        <Box>
          {row.active ? (
            <IconButton color="danger" onClick={() => removeDialog(row.uuid)}>
              <Delete />
            </IconButton>
          ) : (
            <IconButton color="success" onClick={() => handleAddSource(row.source_uuid)}>
              <Add />
            </IconButton>
          )}
        </Box>
      ),
    },
  ];

  const getPartnerSources = async () => {
    try {
      const response = await partner.post(
        `/v1/screening/data-sources/list`,
        JSON.stringify({
          partner_uuid: partnerUser.value.partner_uuid,
          source_type: sourceType,
        }),
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Basic " + useAuthHeader.base64encodedData,
            service: "partners",
          },

          //withCredentials: true,
        }
      );

      if (response.data.results > 0) {
        setData(response.data.entries);
      } else {
        setData([]);
      }

      setIsLoaded(true);
    } catch (err) {
      handleError(err);
    }
  };

  const getSourceOptions = async () => {
    try {
      const response = await central.post(
        `/v1/service/screening/sources/type-options/${sourceType == 1 ? "sanctions" : sourceType == 2 ? "peps" : "warnings"}`,
        JSON.stringify({
          partner_uuid: partnerUser.value.partner_uuid,
        }),
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Basic " + useAuthHeader.base64encodedData,
            service: "partners",
            partner_uuid: partnerUser.value.partner_uuid,
          },

          //withCredentials: true,
        }
      );

      if (response.data.results > 0) {
        var sourceOptions = [];

        response.data.entries.map((entry) => {
          var active = false;
          var uuid = "";

          if (data?.length > 0) {
            data.map((source) => {
              if (source.source_uuid == entry.source_uuid) {
                active = true;
                uuid = source.uuid;
              }
              if (active) {
                return;
              }
            });
          }

          sourceOptions = [
            ...sourceOptions,
            { name: entry.name, country: entry.country, source_uuid: entry.source_uuid, active: active, uuid: uuid },
          ];
        });

        var sortedOptions = sourceOptions.sort((a, b) => {
          if (a.active > b.active) {
            return -1;
          }
          if (a.active < b.active) {
            return 1;
          }

          return 0;
        });

        setOptionsData(sortedOptions);
      } else {
        setOptionsData([]);
      }
    } catch (err) {
      handleError(err);
    }
  };

  const handleAddSource = async (sourceUUID) => {
    try {
      const response = await partner.post(
        `/v1/screening/data-sources/add`,
        JSON.stringify({
          source_uuid: sourceUUID,
        }),
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Basic " + useAuthHeader.base64encodedData,
            service: "partners",
          },

          //withCredentials: true,
        }
      );

      getPartnerSources();

      displayToast("Source added", () => {
        setManageSources(false);
      });
    } catch (err) {
      handleError(err);
    }
  };

  const handleRemoveSource = async () => {
    setProcessing(true);
    try {
      const response = await partner.post(
        `/v1/screening/data-sources/remove`,
        JSON.stringify({
          source_uuid: removeSource,
        }),
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Basic " + useAuthHeader.base64encodedData,
            service: "partners",
          },

          //withCredentials: true,
        }
      );

      getPartnerSources();
      setRemoveSourceDialog(false);
      setRemoveSource("");
      displayToast("Source removed", () => {
        setManageSources(false);
        setProcessing(false);
      });
    } catch (err) {
      handleError(err);
      setProcessing(false);
    }
  };

  const removeDialog = (uuid) => {
    setRemoveSourceDialog(true);
    setRemoveSource(uuid);
  };

  useEffect(() => {
    getPartnerSources();
  }, [sourceType]);

  return (
    <>
      {isLoaded ? (
        <>
          <Box sx={{ borderTop: 1, borderColor: "divider" }}>
            <Box sx={{ p: 2, display: "flex", justifyContent: "space-between" }}>
              <Box sx={{ display: "flex", gap: 4 }}>
                <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
                  <Typography variant="h6">
                    {sourceType == 1 ? "Monitored sanction lists" : sourceType == 2 ? "Monitored PEP sources" : "Monitored warning lists"}
                  </Typography>
                </Box>

                <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center", width: "fit-content" }}>
                  <Button
                    onClick={() => {
                      getSourceOptions();
                      setManageSources(!manageSources);
                    }}
                    size="small"
                    variant="contained"
                    color="primary"
                    startIcon={manageSources ? <Close /> : <Edit />}
                  >
                    {manageSources ? "Close" : "Edit sources"}
                  </Button>
                </Box>
              </Box>

              {!manageSources && (
                <Box sx={{ p: 2 }}>
                  <ToggleButtonGroup
                    color="primary"
                    value={sourceType}
                    exclusive
                    onChange={(e, newValue) => {
                      if (newValue !== null) setSourceType(newValue);
                    }}
                    aria-label="Case type"
                  >
                    <ToggleButton value={1}>Sanctions</ToggleButton>

                    <ToggleButton value={3}>Warnings</ToggleButton>
                  </ToggleButtonGroup>
                </Box>
              )}
            </Box>
            <Divider />
            {manageSources ? (
              <DataTable columns={manageColumns} data={optionsData} customStyles={customStyles} pagination striped />
            ) : (
              <DataTable columns={columns} data={data} customStyles={customStyles} pagination striped />
            )}
          </Box>

          <Dialog
            open={removeSourceDialog}
            onClose={() => setRemoveSourceDialog(false)}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">Are you sure?</DialogTitle>
            <DialogContent>
              <Box>
                <DialogContentText sx={{ mb: 1 }} id="alert-dialog-description">
                  Are you sure you want to remove the source?
                </DialogContentText>
              </Box>
            </DialogContent>
            <DialogActions>
              <LoadingButton disableElevation loading={processing} onClick={() => setRemoveSourceDialog(false)} color="primary">
                Cancel
              </LoadingButton>

              <LoadingButton
                disableElevation
                sx={{ color: "#ffffff" }}
                loading={processing}
                onClick={(e) => {
                  handleRemoveSource();
                }}
                variant="contained"
                color="danger"
                autoFocus
              >
                Confirm
              </LoadingButton>
            </DialogActions>
          </Dialog>
        </>
      ) : (
        <Box>
          <Lottie speed={2.0} options={defaultOptions} height={200} width={200} />
        </Box>
      )}
    </>
  );
};

export default DataSources;
