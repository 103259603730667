import {
  Box,
  Button,
  Divider,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  Typography,
} from "@mui/material";
import { Modal } from "../../../../../components/modal";
import HandleToast from "../../../../../components/functions/HandleToast";
import {
  completeTheWorkflowSteps,
  undoCompleteTheWorkflowSteps,
} from "../../../../../api/services";
import DoneIcon from "@mui/icons-material/Done";
import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { RenderWhen } from "../../../../../components/render-when";
import { useGetTaskFiles } from "../../../../../hooks/use-get-task-files";

export function ConfirmationModal({ getTaskDataQuery, taskData, step, taskUUID }) {
  console.log(step);
  const [selectedFiles, setSelectedFiles] = useState([]);

  const [confirmModalOpen, setConfirmModalOpen] = useState({
    open: false,
    confirmationStatus: "", // confirm or undo
  });
  // const [stepConfirmationDescription, setStepConfirmationDescription] = useState("");
  const { taskFilesList } = useGetTaskFiles(taskUUID);

  const displayToast = HandleToast();
  const handleOpenConfirmationModal = (confirmationStatus) => {
    setConfirmModalOpen({ open: true, confirmationStatus });
  };

  const undoCompleteTask = async (stepUUID) => {
    try {
      await undoCompleteTheWorkflowSteps({
        taskUUID: taskData?.task_uuid,
        stepUUID,
      });
      await getTaskDataQuery();
      displayToast("Undo step completion");
    } catch (error) {
      displayToast("Error undoing step completion", undefined, "error");
      console.error(error);
    }
  };

  const completeTask = async (stepUUID) => {
    const body = {
      taskUUID: taskData?.task_uuid,
      stepUUID,
    };

    if (step?.file_required) {
      if (selectedFiles.length === 0) {
        displayToast("Please select files", undefined, "error");
        return;
      }
      body.files = selectedFiles;
    }

    try {
      await completeTheWorkflowSteps(body);
      await getTaskDataQuery();
      displayToast("Step completed");
    } catch (error) {
      displayToast("Error completing step", undefined, "error");
      console.error(error);
    }
  };

  const handleSubmitConfirmationModal = () => {
    if (confirmModalOpen.confirmationStatus === "confirm") {
      completeTask(step?.step_uuid);
    } else {
      undoCompleteTask(step?.step_uuid);
    }
    setConfirmModalOpen({ open: false });
    setSelectedFiles([]);
  };

  const handleCloseConfirmationModal = () => {
    setConfirmModalOpen({ open: false });
    setSelectedFiles([]);
  };

  useEffect(() => {
    const linkedFiles = step?.linked_files;
    if (linkedFiles && linkedFiles.length > 0) {
      setSelectedFiles(linkedFiles.map((file) => file.file_uuid));
      return;
    }
    setSelectedFiles([]);
  }, [step]);

  return (
    <>
      <Box
        sx={{
          p: 1,
        }}
      >
        <IconButton
          onClick={async () => {
            if (step?.complete) {
              if (!step?.requires_confirmation && !step?.file_required) {
                await undoCompleteTask(step?.step_uuid);
              } else {
                handleOpenConfirmationModal("undo");
              }
            } else if (!step?.requires_confirmation && !step?.file_required) {
              await completeTask(step?.step_uuid);
            } else {
              handleOpenConfirmationModal("confirm");
            }
          }}
          sx={{
            bgcolor: step?.complete ? "success.main" : "divider",
          }}
        >
          <DoneIcon />
        </IconButton>
      </Box>
      <Modal open={confirmModalOpen.open} onClose={handleCloseConfirmationModal}>
        <Stack direction="column">
          <Typography variant="h6" sx={{ p: 2 }}>
            {confirmModalOpen.confirmationStatus === "confirm" ? "Confirm" : "Undo"} Completion
          </Typography>
          <Typography variant="body1" sx={{ p: 2 }}>
            Please confirm the{" "}
            {confirmModalOpen.confirmationStatus === "confirm" ? "completion" : "undo completion"}{" "}
            of this step
          </Typography>

          <Divider />
          <RenderWhen when={step?.file_required && confirmModalOpen.confirmationStatus === "undo"}>
            <Stack
              direction="row"
              sx={{ p: 2 }}
              justifyContent={"space-between"}
              alignItems="center"
            >
              Linked Files
              <Typography variant="body1" maxWidth="50%">
                {step?.linked_files?.map((file) => file.filename).join(", ")}
              </Typography>
            </Stack>
          </RenderWhen>
          <RenderWhen
            when={step?.file_required && confirmModalOpen.confirmationStatus === "confirm"}
          >
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              sx={{
                p: 1,
                borderBottom: 1,
                borderTop: 1,
                borderColor: "divider",
              }}
            >
              <Typography variant="body1" fontWeight={600}>
                File Required:
              </Typography>
              <RenderWhen when={Boolean(taskFilesList?.length > 0)}>
                <FormControl size="small" sx={{ width: 300 }}>
                  <InputLabel>Select Files</InputLabel>
                  <Select
                    value={selectedFiles}
                    label="Select Files"
                    multiple
                    onChange={(e) => {
                      setSelectedFiles(e.target.value);
                    }}
                  >
                    {taskFilesList?.map((file, index) => (
                      <MenuItem key={index} value={file?.file_uuid}>
                        {file?.filename}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </RenderWhen>
              <RenderWhen when={Boolean(!(taskFilesList?.length > 0))}>
                <Typography variant="body1">No files found for this task</Typography>
              </RenderWhen>
            </Stack>
          </RenderWhen>
          <Divider />
          <Stack direction="row" justifyContent="flex-end" sx={{ p: 2 }} spacing={2}>
            <Button variant="outlined" onClick={handleCloseConfirmationModal}>
              Cancel
            </Button>
            <Button
              disabled={step?.file_required && selectedFiles.length === 0}
              variant="contained"
              onClick={handleSubmitConfirmationModal}
            >
              Confirm
            </Button>
          </Stack>
        </Stack>
      </Modal>
    </>
  );
}

ConfirmationModal.propTypes = {
  getTaskDataQuery: PropTypes.func,
  taskData: PropTypes.object,
  step: PropTypes.object,
  taskUUID: PropTypes.string,
};
