import { React, useEffect, useState } from "react";

import partner from "../../api/partner";
import authHeader from "../../api/authHeader";

import HandleError from "../../components/functions/HandleError";
import HandleToast from "../../components/functions/HandleToast";
import EmptyReport from "../../components/functions/EmptyReport";

import { signal } from "@preact/signals-react";
import Lottie from "react-lottie";
import loadingLottie from "../../components/lotties/loading-dots.json";

import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import parse from "html-react-parser";
import moment from "moment";

import {
  Drawer,
  Box,
  Grid,
  Typography,
  IconButton,
  Divider,
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Button,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControlLabel,
  Switch,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { Add, Close, Delete, Edit, History, Reply } from "@mui/icons-material";

const Notes = ({ partnerUser, notesOpen, setNotesOpen, clientData }) => {
  const useAuthHeader = authHeader();

  const handleError = HandleError();
  const displayToast = HandleToast();

  const [isLoaded, setIsLoaded] = useState(false);
  const [processing, setProcessing] = useState(false);

  const [notes, setNotes] = useState();

  const [showReplies, setShowReplies] = useState(true);

  const [activeNote, setActiveNote] = useState({});

  const [newNote, setNewNote] = useState(false);
  const [manageNote, setManageNote] = useState(false);
  const [replyNote, setReplyNote] = useState(false);
  const [manageReply, setManageReply] = useState(false);

  const [replyContent, setReplyContent] = useState("");

  const [noteTitle, setNoteTitle] = useState("");
  const [noteContent, setNoteContent] = useState("");

  const [deleteNoteDialog, setDeleteNoteDialog] = useState(false);

  const [versionHistory, setVersionHistory] = useState(false);
  const [versions, setVersions] = useState();

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: loadingLottie,
  };

  const getNotes = async () => {
    try {
      const response = await partner.post(
        `/v1/client/notes/data/list`,
        JSON.stringify({
          target_uuid: clientData.value.entity_uuid,
          target_type: clientData.value.entity_type,
        }),
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Basic " + useAuthHeader.base64encodedData,
            service: "partners",
          },

          //withCredentials: true,
        }
      );

      if (response.data.results > 0) {
        setNotes(response.data.entries);
      } else {
        setNotes([]);
      }

      setIsLoaded(true);
    } catch (err) {
      console.log(err);
      handleError(err);
    }
  };

  const getNoteData = async (noteUUID) => {
    try {
      const response = await partner.post(
        `/v1/client/notes/data`,
        JSON.stringify({
          target_uuid: clientData.value.entity_uuid,
          target_type: clientData.value.entity_type,
          note_uuid: noteUUID,
        }),
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Basic " + useAuthHeader.base64encodedData,
            service: "partners",
          },

          //withCredentials: true,
        }
      );

      setActiveNote(response.data);

      setNoteTitle(response.data.title);
      setNoteContent(response.data.content);
      setManageNote(true);
    } catch (err) {
      console.log(err);
      handleError(err);
    }
  };

  const getReplyData = async (noteUUID) => {
    try {
      const response = await partner.post(
        `/v1/client/notes/data`,
        JSON.stringify({
          target_uuid: clientData.value.entity_uuid,
          target_type: clientData.value.entity_type,
          note_uuid: noteUUID,
        }),
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Basic " + useAuthHeader.base64encodedData,
            service: "partners",
          },

          //withCredentials: true,
        }
      );

      setActiveNote(response.data);
      setReplyContent(response.data.content);
      setManageReply(true);
    } catch (err) {
      console.log(err);
      handleError(err);
    }
  };

  const handleCreateNote = async (e) => {
    e.preventDefault();
    setProcessing(true);
    try {
      const response = await partner.post(
        `/v1/client/notes/create`,
        JSON.stringify({
          target_uuid: clientData.value.entity_uuid,
          target_type: clientData.value.entity_type,
          title: noteTitle,
          content: noteContent,
        }),
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Basic " + useAuthHeader.base64encodedData,
            service: "partners",
          },

          //withCredentials: true,
        }
      );

      getNotes();

      setNoteContent("");
      setNoteTitle("");

      displayToast("Note created sucessfully", () => {
        setNewNote(false);
        setProcessing(false);
      });
    } catch (err) {
      console.log(err);
      handleError(err);
      setProcessing(false);
    }
  };

  const handleUpdateNote = async (e) => {
    e.preventDefault();
    setProcessing(true);
    try {
      const response = await partner.post(
        `/v1/client/notes/update`,
        JSON.stringify({
          target_uuid: clientData.value.entity_uuid,
          target_type: clientData.value.entity_type,
          title: noteTitle,
          content: noteContent,
          note_uuid: activeNote.uuid,
        }),
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Basic " + useAuthHeader.base64encodedData,
            service: "partners",
          },

          //withCredentials: true,
        }
      );

      getNotes();

      setNoteContent("");
      setNoteTitle("");

      displayToast("Note updated sucessfully", () => {
        setManageNote(false);
        setProcessing(false);
      });
    } catch (err) {
      console.log(err);
      handleError(err);
      setProcessing(false);
    }
  };

  const handleUpdateReply = async (e) => {
    e.preventDefault();
    setProcessing(true);
    try {
      const response = await partner.post(
        `/v1/client/notes/update-reply`,
        JSON.stringify({
          target_uuid: clientData.value.entity_uuid,
          target_type: clientData.value.entity_type,
          content: replyContent,
          note_uuid: activeNote.uuid,
        }),
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Basic " + useAuthHeader.base64encodedData,
            service: "partners",
          },

          //withCredentials: true,
        }
      );

      getNotes();
      setReplyContent("");
      displayToast("Reply updated sucessfully", () => {
        setManageReply(false);
        setProcessing(false);
      });
    } catch (err) {
      console.log(err);
      handleError(err);
      setProcessing(false);
    }
  };

  const handleSaveReply = async (e) => {
    e.preventDefault();
    setProcessing(true);
    try {
      const response = await partner.post(
        `/v1/client/notes/reply`,
        JSON.stringify({
          target_uuid: clientData.value.entity_uuid,
          target_type: clientData.value.entity_type,
          content: replyContent,
          note_uuid: activeNote.uuid,
        }),
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Basic " + useAuthHeader.base64encodedData,
            service: "partners",
          },

          //withCredentials: true,
        }
      );

      getNotes();

      setReplyContent("");

      displayToast("Reply saved sucessfully", () => {
        setReplyNote(false);
        setProcessing(false);
      });
    } catch (err) {
      console.log(err);
      handleError(err);
      setProcessing(false);
    }
  };

  const handleDeleteNote = async (e) => {
    e.preventDefault();
    setProcessing(true);
    try {
      const response = await partner.post(
        `/v1/client/notes/remove`,
        JSON.stringify({
          target_uuid: clientData.value.entity_uuid,
          target_type: clientData.value.entity_type,
          note_uuid: activeNote.uuid,
        }),
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Basic " + useAuthHeader.base64encodedData,
            service: "partners",
          },

          //withCredentials: true,
        }
      );

      getNotes();

      setNoteContent("");
      setNoteTitle("");
      setDeleteNoteDialog(false);

      displayToast("Note deleted sucessfully", () => {
        setManageNote(false);
        setProcessing(false);
      });
    } catch (err) {
      console.log(err);
      handleError(err);
      setProcessing(false);
    }
  };

  const getVersionHistory = async (noteUUID) => {
    try {
      const response = await partner.post(
        `/v1/client/notes/data/version-history`,
        JSON.stringify({
          target_uuid: clientData.value.entity_uuid,
          target_type: clientData.value.entity_type,
          note_uuid: noteUUID,
        }),
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Basic " + useAuthHeader.base64encodedData,
            service: "partners",
          },

          //withCredentials: true,
        }
      );

      if (response.data.results > 0) {
        setVersions(response.data.entries);
      } else {
        setVersions([]);
      }

      setVersionHistory(true);
    } catch (err) {
      console.log(err);
      handleError(err);
    }
  };

  useEffect(() => {
    if (notesOpen) {
      getNotes();
    }
  }, [notesOpen]);

  return (
    <Drawer anchor="right" open={notesOpen}>
      <Box sx={{ minWidth: "50vw" }}>
        <Box sx={{ p: 2, display: "flex", justifyContent: "space-between", alignItems: "space-between" }}>
          <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
            <Box sx={{ display: "flex", gap: 2 }}>
              <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
                <Typography variant="h6">Notes</Typography>
              </Box>
            </Box>
          </Box>

          <Box sx={{ display: "flex", gap: 2 }}>
            {!newNote && !manageNote && !versionHistory && !replyNote && !manageReply && (
              <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
                <FormControlLabel control={<Switch checked={showReplies} onClick={() => setShowReplies(!showReplies)} />} label={`Show replies`} />
              </Box>
            )}

            {!newNote && !manageNote && !versionHistory && !replyNote && !manageReply && (
              <Button variant="contained" startIcon={<Add />} onClick={() => setNewNote(true)}>
                Add note
              </Button>
            )}

            <IconButton
              onClick={() => {
                newNote
                  ? setNewNote(false)
                  : manageNote
                  ? setManageNote(false)
                  : versionHistory
                  ? setVersionHistory(false)
                  : replyNote
                  ? setReplyNote(false)
                  : manageReply
                  ? setManageReply(false)
                  : setNotesOpen(false);
              }}
            >
              <Close />
            </IconButton>
          </Box>
        </Box>

        <Divider />

        <Box sx={{ p: 2, display: "flex", flexDirection: "column", gap: 2 }}>
          {isLoaded ? (
            newNote ? (
              <Box sx={{ display: "flex", flexDirection: "column", border: 1, borderColor: "divider" }}>
                <Box sx={{ p: 2 }}>
                  <Typography variant="subtitle2">Create a new note</Typography>
                </Box>
                <Divider />
                <Box sx={{ p: 2 }}>
                  <TextField
                    fullWidth
                    id="outlined-basic"
                    label="Note title (optional)"
                    variant="outlined"
                    value={noteTitle}
                    onChange={(e) => setNoteTitle(e.target.value)}
                  />
                </Box>

                <Divider />

                <Box>
                  <ReactQuill theme="snow" value={noteContent} onChange={setNoteContent} style={{ minHeight: "300px" }} />
                </Box>

                <Divider />

                <Box sx={{ p: 2, display: "flex", justifyContent: "flex-end", gap: 2 }}>
                  <LoadingButton
                    variant="contained"
                    color="success"
                    loading={processing}
                    onClick={(e) => {
                      handleCreateNote(e);
                    }}
                  >
                    Create Note
                  </LoadingButton>
                </Box>
              </Box>
            ) : manageNote ? (
              <Box sx={{ display: "flex", flexDirection: "column", border: 1, borderColor: "divider" }}>
                <Box sx={{ p: 2 }}>
                  <Typography variant="subtitle2">Edit the note</Typography>
                </Box>
                <Divider />
                <Box sx={{ p: 2 }}>
                  <TextField
                    fullWidth
                    id="outlined-basic"
                    label="Note title (optional)"
                    variant="outlined"
                    value={noteTitle}
                    onChange={(e) => setNoteTitle(e.target.value)}
                  />
                </Box>

                <Divider />

                <Box>
                  <ReactQuill theme="snow" value={noteContent} onChange={setNoteContent} style={{ minHeight: "300px" }} />
                </Box>

                <Divider />

                <Box sx={{ p: 2, display: "flex", justifyContent: "space-between", gap: 2 }}>
                  <Button color="danger" variant="outlined" onClick={() => setDeleteNoteDialog(true)} startIcon={<Delete />}>
                    Delete
                  </Button>
                  <LoadingButton
                    variant="contained"
                    color="success"
                    loading={processing}
                    onClick={(e) => {
                      handleUpdateNote(e);
                    }}
                  >
                    Save changes
                  </LoadingButton>
                </Box>
              </Box>
            ) : manageReply ? (
              <Box sx={{ display: "flex", flexDirection: "column", border: 1, borderColor: "divider" }}>
                <Box sx={{ p: 2 }}>
                  <Typography variant="subtitle2">Edit reply</Typography>
                </Box>
                <Divider />

                <Box>
                  <ReactQuill theme="snow" value={replyContent} onChange={setReplyContent} style={{ minHeight: "300px" }} />
                </Box>

                <Divider />

                <Box sx={{ p: 2, display: "flex", justifyContent: "space-between", gap: 2 }}>
                  <Button color="danger" variant="outlined" onClick={() => setDeleteNoteDialog(true)} startIcon={<Delete />}>
                    Delete
                  </Button>
                  <LoadingButton
                    variant="contained"
                    color="success"
                    loading={processing}
                    onClick={(e) => {
                      handleUpdateReply(e);
                    }}
                  >
                    Save changes
                  </LoadingButton>
                </Box>
              </Box>
            ) : versionHistory ? (
              versions?.length > 0 ? (
                versions.map((version, index) => (
                  <Card key={index} elevation={0} variant="outlined">
                    <CardContent>
                      <Typography variant="subtitle2">{version.title}</Typography>
                      <Box>{parse(version.content)}</Box>
                    </CardContent>

                    <CardActions
                      disableSpacing
                      sx={{
                        alignSelf: "stretch",
                        display: "flex",
                        justifyContent: "flex-end",
                        alignItems: "flex-start",
                        // 👇 Edit padding to further adjust position
                        p: 2,
                      }}
                    >
                      <Box sx={{ display: "flex", flexDirection: "column", gap: 0 }}>
                        <Typography variant="subtitle" sx={{ textTransform: "uppercase", textAlign: "end", fontSize: "0.85rem" }}>
                          Created on: {moment.unix(version.updated).format("Do MMM YYYY - hh:mm a")}
                        </Typography>
                        <Typography variant="subtitle" sx={{ textTransform: "uppercase", textAlign: "end", fontSize: "0.85rem" }}>
                          {version.replaced > 0 ? `Replaced on: ${moment.unix(version.replaced).format("Do MMM YYYY - hh:mm a")}` : "Current version"}
                        </Typography>
                        <Typography variant="subtitle2" sx={{ fontWeight: "bold", textAlign: "end" }}>
                          Version: {version.version}
                        </Typography>
                      </Box>
                    </CardActions>
                  </Card>
                ))
              ) : (
                <EmptyReport message="There are no other versions of this note" />
              )
            ) : notes?.length > 0 ? (
              notes.map((note, index) => (
                <Card key={index} elevation={0} variant="outlined">
                  <Box sx={{ borderBottom: 1, borderColor: "divider", backgroundColor: "#faf8f7" }}>
                    <Box sx={{ py: 1, px: 2, display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                      <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
                        <Typography variant="h6">{note.title}</Typography>
                      </Box>
                      <Box>
                        <Typography sx={{ textAlign: "end" }} variant="subtitle2">
                          {note.owner_name}
                        </Typography>
                        <Box sx={{ textAlign: "end", fontSize: "0.85rem", display: "flex", justifyContent: "flex-end", gap: 2 }}>
                          <Box>{moment.unix(note.created).format("Do MMM YYYY - hh:mm a")}</Box>
                          {note.version > 1 && <Box sx={{ textAlign: "end", fontSize: "0.85rem" }}>Edited</Box>}
                        </Box>
                      </Box>
                    </Box>
                  </Box>

                  <Box className="note-content" sx={{ p: 2, backgroundColor: "#ffffff", borderBottom: 1, borderColor: "divider" }}>
                    {parse(note.content)}
                  </Box>

                  {!replyNote && (
                    <CardActions
                      sx={{
                        alignSelf: "stretch",
                        display: "flex",
                        justifyContent: "space-between",
                        py: 0,
                        px: 0,
                        width: "100%",
                      }}
                    >
                      <Box sx={{ px: 2 }}>
                        {!showReplies && (
                          <Typography variant="subtitle2" sx={{ fontWeight: "bold" }}>
                            Replies: {note.replies ? note.replies?.length : 0}
                          </Typography>
                        )}
                      </Box>

                      <Box>
                        <IconButton
                          title="Reply"
                          aria-label="Reply"
                          onClick={() => {
                            setActiveNote({ uuid: note.uuid });
                            setReplyNote(true);
                          }}
                        >
                          <Reply />
                        </IconButton>

                        {note.version > 1 && !replyNote && (
                          <IconButton title="Version history" aria-label="Version history" onClick={() => getVersionHistory(note.uuid)}>
                            <History />
                          </IconButton>
                        )}

                        {note.owner_uuid == partnerUser.value.uuid && !replyNote && (
                          <IconButton title="Edit note" aria-label="Edit note" onClick={() => getNoteData(note.uuid)}>
                            <Edit />
                          </IconButton>
                        )}
                      </Box>
                    </CardActions>
                  )}

                  {showReplies && note.replies && note.replies?.length > 0 && (
                    <Box sx={{ display: "flex", flexDirection: "column" }}>
                      <Divider />
                      <Box sx={{ p: 2, backgroundColor: "#0b0b0b", color: "#ffffff" }}>
                        <Typography variant="subtitle2">Replies</Typography>
                      </Box>
                      <Divider />
                      <Box sx={{ display: "flex", flexDirection: "column" }}>
                        {note.replies.map((reply, index) => (
                          <Card key={index} elevation={0} variant="" sx={{}}>
                            <Box sx={{ borderBottom: 1, borderTop: 1, borderColor: "divider", backgroundColor: "#faf8f7" }}>
                              <Box sx={{ py: 1, px: 2 }}>
                                <Box sx={{ textAlign: "end", fontSize: "0.875rem", fontWeight: 500 }}>{reply.owner_name}</Box>
                                <Box sx={{ textAlign: "end", fontSize: "0.85rem", display: "flex", justifyContent: "flex-end", gap: 2 }}>
                                  <Box>{moment.unix(reply.created).format("Do MMM YYYY - hh:mm a")}</Box>
                                  {reply.version > 1 && <Box sx={{ textAlign: "end", fontSize: "0.85rem" }}>Edited</Box>}
                                </Box>
                              </Box>
                            </Box>

                            <Box className="note-content" sx={{ p: 2, backgroundColor: "#ffffff", borderBottom: 1, borderColor: "divider" }}>
                              {parse(reply.content)}
                            </Box>

                            {!replyNote && (
                              <Box
                                sx={{
                                  alignSelf: "stretch",
                                  display: "flex",
                                  justifyContent: "flex-end",
                                  py: 0,
                                  px: 0,
                                  width: "100%",
                                }}
                              >
                                <Box>
                                  {reply.version > 1 && !replyNote && (
                                    <IconButton title="Version history" aria-label="Version history" onClick={() => getVersionHistory(reply.uuid)}>
                                      <History />
                                    </IconButton>
                                  )}

                                  {reply.owner_uuid == partnerUser.value.uuid && !replyNote && (
                                    <IconButton title="Edit note" aria-label="Edit reply" onClick={() => getReplyData(reply.uuid)}>
                                      <Edit />
                                    </IconButton>
                                  )}
                                </Box>
                              </Box>
                            )}
                          </Card>
                        ))}
                      </Box>
                    </Box>
                  )}

                  {replyNote && activeNote.uuid == note.uuid && (
                    <Box sx={{ display: "flex", flexDirection: "column" }}>
                      <Divider />
                      <Box sx={{ p: 2 }}>
                        <Typography variant="subtitle2">Compose a reply</Typography>
                      </Box>
                      <Divider />

                      <Box>
                        <ReactQuill theme="snow" value={replyContent} onChange={setReplyContent} style={{ minHeight: "300px" }} />
                      </Box>

                      <Divider />

                      <Box sx={{ p: 2, display: "flex", justifyContent: "space-between", gap: 2 }}>
                        <Button
                          variant="outlined"
                          onClick={() => {
                            setActiveNote({});
                            setReplyNote(false);
                          }}
                        >
                          Cancel
                        </Button>
                        <LoadingButton
                          variant="contained"
                          color="success"
                          loading={processing}
                          onClick={(e) => {
                            handleSaveReply(e);
                          }}
                        >
                          Save reply
                        </LoadingButton>
                      </Box>
                    </Box>
                  )}
                </Card>
              ))
            ) : (
              <EmptyReport message="This client has no notes." />
            )
          ) : (
            <Box>
              <Lottie speed={2.0} options={defaultOptions} height={200} width={200} />
            </Box>
          )}
        </Box>

        <Dialog
          open={deleteNoteDialog}
          onClose={() => setDeleteNoteDialog(false)}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">Are you sure?</DialogTitle>
          <DialogContent>
            <Box>
              <DialogContentText sx={{ mb: 1 }} id="alert-dialog-description">
                Are you sure you want to delete this note?
              </DialogContentText>
            </Box>
          </DialogContent>
          <DialogActions>
            <LoadingButton loading={processing} onClick={() => setDeleteNoteDialog(false)} color="primary">
              Cancel
            </LoadingButton>

            <LoadingButton
              sx={{ color: "#ffffff" }}
              loading={processing}
              onClick={(e) => {
                handleDeleteNote(e);
              }}
              variant="contained"
              color="danger"
              disableElevation
              autoFocus
            >
              Confirm
            </LoadingButton>
          </DialogActions>
        </Dialog>
      </Box>
    </Drawer>
  );
};

export default Notes;
