import { React, useEffect, useState, useRef, useCallback } from "react";

import partner from "../../../../../api/partner";
import authHeader from "../../../../../api/authHeader";

import HandleToast from "../../../../../components/functions/HandleToast";
import ErrorHandling from "../../../../../components/functions/HandleError";

import Lottie from "react-lottie";
import successLottie from "../../../../../components/lotties/success.json";
import loadingLottie from "../../../../../components/lotties/loading-dots.json";

import { signal } from "@preact/signals-react";

import {
  Container,
  Typography,
  Grid,
  TextField,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Box,
  Fade,
} from "@mui/material";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";

import WebcamDisplay from "./WebcamDisplay";

const capturing = signal(false);

const CaptureImage = ({ partnerUser, getFacialData, setEditMode }) => {
  const useAuthHeader = authHeader();
  const handleError = ErrorHandling();
  const displayToast = HandleToast();

  const videoElement = useRef(null);
  const mediaRecorderRef = useRef(null);
  const snapshotInterval = useRef(null);
  const canvasRef = useRef();

  const [processing, setProcessing] = useState(false);

  const [permissionsError, setPermissionsError] = useState(false);

  const [devices, setDevices] = useState([]);
  const [selectedDevice, setSelectedDevice] = useState({ deviceId: "", label: "" });

  const [snapshotImage, setSnapshotImage] = useState("");
  const [baseInstructions, setBaseInstructions] = useState("Please allow access to your camera");

  const defaultOptions = {
    loop: false,
    autoplay: true,
    animationData: successLottie,
  };

  const [videoConstraints, setVideoConstraints] = useState({
    width: 640,
    height: 400,
    facingMode: "user",
    deviceId: selectedDevice.deviceId ? { exact: selectedDevice.deviceId } : undefined,
  });

  function GetDevice(id) {
    console.log("Getting device");
    navigator.mediaDevices
      .enumerateDevices()
      .then(function (devices) {
        devices.forEach(function (device) {
          if (device.deviceId == id) {
            setSelectedDevice({
              deviceId: device.deviceId === "default" ? "" : device.deviceId,
              label: device.label,
            });
            //console.log(device.kind + ": " + device.label != undefined ? device.label : "Default");
          }
        });
      })
      .catch(function (err) {
        console.log(err.name + ": " + err.message);
      });
  }

  const handleDevices = useCallback(
    (mediaDevices) => setDevices(mediaDevices.filter(({ kind }) => kind === "videoinput")),
    [setDevices]
  );

  useEffect(() => {
    //navigator.mediaDevices.enumerateDevices().then(handleDevices);
  }, [handleDevices]);

  const [modelsLoaded, setModelsLoaded] = useState(true);
  const [faceDetected, setFaceDetected] = useState(true);

  const captureSnapshot = useCallback(() => {
    if (videoElement.current == null) {
      return;
    }
    setBaseInstructions("If you're happy with the image click confirm");
    setSnapshotImage(videoElement.current.getScreenshot());
  }, [videoElement]);

  const handleSaveFacialImage = async (e) => {
    e.preventDefault();
    setProcessing(true);

    try {
      const response = await partner.post(
        `/v1/users/self/settings/face-image`,
        JSON.stringify({
          partner_user_uuid: partnerUser.value.uuid,
          image: snapshotImage ? snapshotImage : "",
        }),
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Basic " + useAuthHeader.base64encodedData,
            service: "partners",
          },

          //withCredentials: true,
        }
      );

      getFacialData();

      setTimeout(() => {
        displayToast(`Facial recognition image updated successfully`, () => {
          setProcessing(false);
          setEditMode(false);
        });
      }, 2000);
    } catch (err) {
      handleError(err);
      setProcessing(false);
    }
  };

  return (
    <Box>
      <Typography variant="body1" gutterBottom>
        To update your facial recognition image, capture a clear image of yourself. This will be
        used to verify your identity when logging in and approving payments or beneficiaries.
      </Typography>

      <Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
          }}
        >
          <Box sx={{ display: "flex", flexDirection: "column", width: "100%" }}>
            <Box
              sx={{
                minWidth: { xs: 400, lg: 640 },
                minHeight: 400,
                border: permissionsError ? 1 : 0,
                borderColor: "divider",
              }}
            >
              {permissionsError ? (
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    justifyItems: "center",
                    textAlign: "center",
                    textTransform: "uppercase",
                  }}
                >
                  <Typography variant="subtitle2">
                    Please allow access to your camera and microphone to proceed
                  </Typography>
                </Box>
              ) : snapshotImage ? (
                <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "center" }}>
                  <img src={snapshotImage} alt="webcam" />
                </Box>
              ) : (
                <Box sx={{ position: "relative" }}>
                  <WebcamDisplay
                    videoElement={videoElement}
                    videoConstraints={videoConstraints}
                    setPermissionsError={setPermissionsError}
                    setBaseInstructions={setBaseInstructions}
                    getDevice={GetDevice}
                  />
                  <div className="overlay-container">
                    <canvas className="w-full h-fit" ref={canvasRef} />
                  </div>
                </Box>
              )}
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                width: "100%",
              }}
            >
              <Box
                sx={{
                  py: 1,
                  backgroundColor: "#0b0b0b",
                  color: "#ffffff",
                  width: "100%",
                  textAlign: "center",
                }}
              >
                <Typography
                  sx={{
                    fontWeight: 600,
                    textTransform: "uppercase",
                    fontSize: { xs: "1rem", md: "1.25rem" },
                  }}
                  variant="h6"
                >
                  {baseInstructions}
                </Typography>
              </Box>

              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  pt: 2,
                  gap: 2,
                  width: "100%",
                }}
              >
                {!capturing.value && devices?.length > 0 && !snapshotImage && (
                  <Box sx={{ width: "50%" }}>
                    <FormControl fullWidth>
                      <InputLabel id="recording-device-label">Recording device</InputLabel>
                      <Select
                        labelId="recording-device-label"
                        id="recording-device"
                        label="Select recording device"
                        value={selectedDevice.deviceId}
                        onChange={(e) => {
                          setSelectedDevice({
                            deviceId: e.target.value,
                            label: devices.find((device) => device.deviceId === e.target.value)
                              .label,
                          });
                          setVideoConstraints({
                            width: 640,
                            height: 400,
                            facingMode: "user",
                            deviceId: e.target.value ? { exact: e.target.value } : undefined,
                          });
                        }}
                      >
                        <MenuItem disabled={true} value="">
                          Select a device
                        </MenuItem>
                        {devices.map((option, index) => (
                          <MenuItem key={index} value={option.deviceId}>
                            {option.label}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Box>
                )}

                {!permissionsError && !snapshotImage && (
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "flex-end",
                      width: devices?.length > 0 ? "50%" : "100%",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                      }}
                    >
                      <Button
                        variant="contained"
                        disableElevation
                        color="success"
                        onClick={() => {
                          captureSnapshot();
                        }}
                      >
                        Capture Image
                      </Button>
                    </Box>
                  </Box>
                )}

                {!permissionsError && snapshotImage && (
                  <Box
                    sx={{
                      width: "100%",
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                      }}
                    >
                      <Button
                        variant="outlined"
                        disableElevation
                        color="primary"
                        onClick={() => {
                          setSnapshotImage("");
                        }}
                      >
                        Retake Image
                      </Button>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                      }}
                    >
                      <LoadingButton
                        variant="contained"
                        loading={processing}
                        disableElevation
                        color="success"
                        onClick={(e) => {
                          handleSaveFacialImage(e);
                        }}
                      >
                        Confirm & Upload
                      </LoadingButton>
                    </Box>
                  </Box>
                )}
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default CaptureImage;
