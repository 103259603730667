import React from "react";
import { Box, Typography, List, ListItem } from "@mui/material";

const Attestation = ({ partnerUser, entityData }) => {
  return (
    <Box sx={{ border: 1, borderColor: "divider" }}>
      <Box sx={{ p: 2, borderBottom: 1, borderColor: "divider", backgroundColor: "#0b0b0b", color: "#ffffff" }}>
        <Typography variant="subtitle2">Attestation</Typography>
      </Box>
      <Box sx={{ p: 2 }}>
        <Typography sx={{}} variant="body2">
          An attestation is a formal declaration that you have witnessed or verified specific facts or conditions. In the context of identity
          verification and proof of life, an attestation involves confirming that you have personally seen and verified the identity and well-being of
          another person.
        </Typography>
        <Typography sx={{ mt: 1 }} variant="body2">
          This process helps ensure the accuracy and reliability of the information being verified.
        </Typography>

        <Typography sx={{ mt: 2 }} variant="body2">
          By providing an attestation, you are formally asserting that:
        </Typography>

        <List sx={{ listStyleType: "disc", pl: 4 }}>
          <ListItem sx={{ display: "list-item" }}>
            <Typography variant="body2">
              <b>Observation:</b> You have personally seen the individual in question.
            </Typography>
          </ListItem>
          <ListItem sx={{ display: "list-item" }}>
            <Typography variant="body2">
              <b>Verification:</b> You have confirmed that the individual matches the identity they claim.
            </Typography>
          </ListItem>
          <ListItem sx={{ display: "list-item" }}>
            <Typography variant="body2">
              <b>Condition:</b> You have verified that the individual is alive and well.
            </Typography>
          </ListItem>
        </List>
      </Box>
    </Box>
  );
};

export default Attestation;
