import { React, useEffect, useState } from "react";

import partner from "../../api/partner";
import authHeader from "../../api/authHeader";

import Lottie from "react-lottie";
import loadingLottie from "../../components/lotties/loading-dots.json";

import HandleError from "../../components/functions/HandleError";
import HandleToast from "../../components/functions/HandleToast";

import { Box, Divider, Typography } from "@mui/material";

const Support = ({ partnerData, partnerUser, caseData, entityData }) => {
  const useAuthHeader = authHeader();
  const handleError = HandleError();
  const displayToast = HandleToast();

  const [isLoaded, setIsLoaded] = useState(false);
  const [processing, setProcessing] = useState(false);

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: loadingLottie,
  };

  useEffect(() => {
    setIsLoaded(true);
  }, []);

  return isLoaded ? (
    <Box sx={{ border: 1, borderColor: "divider" }}>
      <Box sx={{ p: 2 }}>
        <Typography variant="h6">Sandbox Support</Typography>
      </Box>
      <Divider />

      <Box sx={{ p: 2 }}>
        <Typography variant="body2">
          In app support is limited to email or telephone support by your account manager during the sandbox period.
        </Typography>
      </Box>
    </Box>
  ) : (
    <Box>
      <Lottie speed={2.0} options={defaultOptions} height={200} width={200} />
    </Box>
  );
};

export default Support;
