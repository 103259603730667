import { React, useState } from "react";

import PropTypes from "prop-types";
import { Tabs, Tab, Box } from "@mui/material";

import Personal from "./Personal";
import Contact from "./Contact";
import Authentication from "./Authentication";
import Sessions from "./Sessions";
import Facial from "./Facial";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 0 }}>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
}

const MyAccount = ({ partner, partnerData, partnerUser }) => {
  const [navValue, setNavValue] = useState(0);

  const handleNav = (event, newValue) => {
    setNavValue(newValue);
  };

  return (
    <>
      <Box sx={{ width: "100%" }}>
        <Tabs
          onChange={handleNav}
          value={navValue}
          aria-label="Tabs where selection follows focus"
          selectionFollowsFocus
        >
          <Tab label="Personal information" {...a11yProps(0)} />
          <Tab label="Contact" {...a11yProps(1)} />
          <Tab label="Authentication" {...a11yProps(2)} />
          <Tab label="Facial Recognition" {...a11yProps(3)} />
          <Tab label="Sessions" {...a11yProps(4)} />
        </Tabs>
      </Box>

      <TabPanel value={navValue} index={0}>
        <Personal partner={partner} partnerUser={partnerUser} partnerData={partnerData} />
      </TabPanel>

      <TabPanel value={navValue} index={1}>
        <Contact partner={partner} partnerUser={partnerUser} partnerData={partnerData} />
      </TabPanel>

      <TabPanel value={navValue} index={2}>
        <Authentication partner={partner} partnerUser={partnerUser} partnerData={partnerData} />
      </TabPanel>

      <TabPanel value={navValue} index={3}>
        <Facial partner={partner} partnerUser={partnerUser} partnerData={partnerData} />
      </TabPanel>

      <TabPanel value={navValue} index={4}>
        <Sessions partner={partner} partnerUser={partnerUser} partnerData={partnerData} />
      </TabPanel>
    </>
  );
};

export default MyAccount;
