import PropTypes from "prop-types";

export function AsyncRenderWhen({ children, isLoading, isError, loading, error }) {
  if (isLoading) {
    return loading ? loading : <div>Loading...</div>;
  }
  if (isError) {
    return error ? error : <div>Error...</div>;
  }
  return children;
}

AsyncRenderWhen.propTypes = {
  children: PropTypes.node,
  isLoading: PropTypes.bool,
  isError: PropTypes.bool,
  loading: PropTypes.node,
  error: PropTypes.node,
};
