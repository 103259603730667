import { Modal } from "../modal";
import PropTypes from "prop-types";
import { Stack, Typography } from "@mui/material";
import parse from "html-react-parser";
import { useGetTaskNoteHistory } from "../../hooks/use-get-task-note-history";
import { dateTime } from "../../utils/date";
import { AsyncRenderWhen } from "../async-render-when";

export function NoteHistoryModal({ noteUUID, onClose, open }) {
  const { taskHistoryData, loading } = useGetTaskNoteHistory(noteUUID);
  return (
    <Modal
      open={open}
      onClose={onClose}
      customStyle={{
        maxHeight: "50%",
      }}
    >
      <AsyncRenderWhen isLoading={loading}>
        <Stack direction="column" spacing={2} sx={{ p: 2 }}>
          {taskHistoryData?.map((note) => {
            return (
              <Stack
                key={note?.uuid}
                direction="column"
                sx={{
                  borderRadius: 1,
                  borderWidth: 1,
                  borderColor: "divider",
                  borderStyle: "solid",
                  p: 2,
                }}
              >
                <Typography variant="h6">{note?.title}</Typography>
                <Typography variant="body1">{parse(note?.content)}</Typography>
                <Typography variant="body1" align="right">
                  Updated By: {note?.owner_name}
                </Typography>
                <Typography variant="body1" align="right">
                  CREATED ON: {dateTime(note?.created)}
                </Typography>
                {note?.replaced === 0 ? (
                  <Typography variant="body1" align="right">
                    CURRENT VERSION
                  </Typography>
                ) : (
                  <Typography variant="body1" align="right">
                    REPLACED ON: {dateTime(note?.replaced)}
                  </Typography>
                )}
                <Typography variant="body1" fontWeight={600} align="right">
                  VERSION: {note?.version}
                </Typography>
              </Stack>
            );
          })}
        </Stack>
      </AsyncRenderWhen>
    </Modal>
  );
}

NoteHistoryModal.propTypes = {
  noteHistory: PropTypes.arrayOf(
    PropTypes.shape({
      uuid: PropTypes.string,
      title: PropTypes.string,
      content: PropTypes.string,
      created: PropTypes.number,
      updated: PropTypes.number,
      replaced: PropTypes.number,
      version: PropTypes.number,
    })
  ),
  noteUUID: PropTypes.string,
  onClose: PropTypes.func,
  open: PropTypes.bool,
};
