import React from "react";

import Lottie from "react-lottie";
import noResultsLottie from "../lotties/no-results.json";

import { Box, Typography } from "@mui/material";

const NoResults = ({ message }) => {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: noResultsLottie,
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "flex-start",
        gap: 2,
        border: 1,
        borderColor: "#2274A5",
        backgroundColor: "#E5F6FA",
        width: "100%",
      }}
    >
      <Box sx={{ px: 2 }}>
        <Lottie options={defaultOptions} height={150} width={150} />
      </Box>

      <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
        <Typography variant="h6" sx={{ color: "#2274A5", textTransform: "uppercase" }}>
          {message ? message : "There are no records to display"}
        </Typography>
      </Box>
    </Box>
  );
};

export default NoResults;
