import { React, useEffect, useState } from "react";

import Lottie from "react-lottie";
import loadingLottie from "../../components/lotties/loading-dots.json";

import { Card, CardContent, Typography, Divider, Box, Grid, Tabs, Tab, Alert } from "@mui/material";

const Overview = ({ partnerData, partnerUser }) => {
  const [isLoaded, setIsLoaded] = useState(false);
  const [filterOption, setFilterOption] = useState(0);
  const [workflowData, setWorkflowData] = useState([]);

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: loadingLottie,
  };

  useEffect(() => {
    setIsLoaded(true);
  }, []);

  return (
    <>
      {isLoaded ? (
        <Box>
          <Grid item sx={{ mx: "auto" }} container spacing={2} sm={12} md={12} lg={9} xl={6}>
            <Card
              variant="outlined"
              sx={{
                p: 0,
                width: "100%",
                borderRadius: "0",
              }}
            >
              <Box p={2} display="flex" alignItems="center" justifyContent="space-between">
                <Box>
                  <Typography variant="h5">Overview</Typography>
                </Box>
                <Box>
                  <Tabs value={filterOption} onChange={() => {}} aria-label="Filter options">
                    <Tab label="My Tasks" onClick={() => setFilterOption(0)} />
                    <Tab label="Unassigned" onClick={() => setFilterOption(1)} />
                  </Tabs>
                </Box>
              </Box>
              <Divider />

              <CardContent sx={{ p: 0, display: "flex", flexDirection: "column", gap: 2 }}>
                <Box>
                  {workflowData.length == 0 ? (
                    <Box sx={{ px: 2, pt: 2 }}>
                      <Alert variant="outlined" severity="info">
                        {filterOption == 0 ? "You have no outstanding tasks assigned to you" : "There are no unassigned tasks available"}
                      </Alert>
                    </Box>
                  ) : (
                    <Typography variant="body1">Tasks available</Typography>
                  )}
                </Box>
              </CardContent>
            </Card>
          </Grid>
        </Box>
      ) : (
        <Box>
          <Lottie speed={2.0} options={defaultOptions} height={200} width={200} />
        </Box>
      )}
    </>
  );
};

export default Overview;
