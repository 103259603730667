import authHeader from "./authHeader";
import partner from "./partner";

const useAuthHeader = authHeader();

/**
 *
 * @param {string} clientUUID
 * @returns {Promise<{entries:{tasks}[], results: number}>}
 */
export async function getClientTasks(clientUUID) {
  const res = await partner.post(
    "/v1/client/tasks/list",
    { client_uuid: clientUUID },
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Basic " + useAuthHeader.base64encodedData,
        service: "partners",
      },
      mode: "no-cors",
    }
  );

  return res.data;
}

/**
 *
 * @param {{clientUUID: string, filterType: number, targetUserUUID: string}} param0
 * @returns {Promise<{entries:{tasks}[], results: number}>}
 */
export async function getClientTasksFilter({ clientUUID, filterType, targetUserUUID }) {
  const res = await partner.post(
    "/v1/client/tasks/list/filter",
    {
      client_uuid: clientUUID,
      filter_type: filterType, // 0 - unassigned | 1 - future | 2 - historic | 3 - overdue | 4 - assigned user (with target_user_uuid)
      target_user_uuid: targetUserUUID, // required if filter_type is '4'
    },
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Basic " + useAuthHeader.base64encodedData,
        service: "partners",
      },
      mode: "no-cors",
    }
  );

  return res.data;
}

/**
 * @returns {Promise<{entries:{name, value:uuid}[], results: number}>}
 */
export async function getClientTasksTemplatesOptions() {
  const res = await partner.post(`/v1/client/tasks/templates/list/options`, JSON.stringify({}), {
    headers: {
      "Content-Type": "application/json",
      Authorization: "Basic " + useAuthHeader.base64encodedData,
      service: "partners",
    },
  });

  return res.data;
}

/**
 *
 * @param {string} uuid
 * @returns
 */
export async function getClientTasksTemplate(uuid) {
  const res = await partner.post(
    `/v1/client/tasks/templates`,
    JSON.stringify({
      uuid,
    }),
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Basic " + useAuthHeader.base64encodedData,
        service: "partners",
      },
      mode: "no-cors",
    }
  );

  return res.data;
}

/**
 * @param {object} taskData
 *  {
      client_uuid: string,
      template_uuid: "", // optional - if empty requires manual task data 
      assigned_user_uuid: "", // optional 
      due_date: "", // optional - YYYY-MM-DD
      with_due_time: false, // 
      due_time: "", // HH:mm if due date is set
      task_name: "", // required if no template is set
      description: "", // required 
      task_type: 1, // required if no template is set (1 - freeform | 2 - workflow)
      steps: [{
          step_name: "",
          description: "",
          attestation: false,
          file_required: false,
          file_information: "", // required if file_required is true
      }], // required if task_type is '2' (workflow) 
      save_as_template: false,
    }
 */
export async function postCreateTask(taskData) {
  const res = await partner.post("/v1/client/tasks/create", taskData, {
    headers: {
      "Content-Type": "application/json",
      Authorization: "Basic " + useAuthHeader.base64encodedData,
      service: "partners",
    },
    mode: "no-cors",
  });

  return res.data;
}

export async function getPartnerUsers() {
  const res = await partner.get("/v1/users/data/users/active", {
    headers: {
      "Content-Type": "application/json",
      Authorization: "Basic " + useAuthHeader.base64encodedData,
      service: "partners",
    },
    mode: "no-cors",
  });

  return res.data;
}

/**
 * Fetches task data.
 * @param {{taskUUID: string, clientUUID: string}} param0 - The task and client UUIDs.
 * @returns {Promise<TaskData>} The task data.
 */
export async function getTaskData({ taskUUID, clientUUID }) {
  const res = await partner.post(
    "/v1/client/tasks/data",
    { task_uuid: taskUUID, client_uuid: clientUUID },
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Basic " + useAuthHeader.base64encodedData,
        service: "partners",
      },
      mode: "no-cors",
    }
  );

  return res.data;
}

/**
 *
 * @param {{taskUUID: string, targetUUID: string}} param0
 * @returns {Promise<null>}
 */
export async function postChangeTaskAssignee({ taskUUID, targetUUID }) {
  const res = await partner.post(
    "/v1/client/tasks/ownership/assign",
    {
      task_uuid: taskUUID,
      target_uuid: targetUUID,
    },
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Basic " + useAuthHeader.base64encodedData,
        service: "partners",
      },
      mode: "no-cors",
    }
  );
  return res.data;
}

/**
 *
 * @param {{taskUUID: string, targetUUID: string}} param0
 * @returns {Promise<null>}
 */
export async function postUnassignTask({ taskUUID }) {
  const res = await partner.post(
    "/v1/client/tasks/ownership/unassign",
    {
      task_uuid: taskUUID,
    },
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Basic " + useAuthHeader.base64encodedData,
        service: "partners",
      },
      mode: "no-cors",
    }
  );
  return res.data;
}

/**
 *  Change task status
 * @param {{taskUUID: string, newStatus: 0 |1 | 2 | 3 | 4| 5 | 6}} param0
 * returns {Promise<null>}
 */
export async function postChangeTaskStatus({ taskUUID, newStatus }) {
  const res = await partner.post(
    "/v1/client/tasks/update-status",
    {
      task_uuid: taskUUID,
      new_status: newStatus, // 0 - unassigned | 1 - pending | 2 - in progress | 3 - on hold | 4 - waiting | 5 - complete | 6 - cancelled
    },
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Basic " + useAuthHeader.base64encodedData,
        service: "partners",
      },
      mode: "no-cors",
    }
  );
  return res.data;
}

/**
 *  Change task due date
 * @param {{taskUUID: string, dueDate:string, withDueDateTime: boolean, dueTime: string | undefined}} param0
 * returns {Promise<null>}
 */
export async function postChangeTaskDueDate({
  taskUUID,
  dueDate,
  withDueDateTime = false,
  dueTime = undefined,
}) {
  const res = await partner.post(
    "/v1/client/tasks/deadline",
    {
      task_uuid: taskUUID,
      due_date: dueDate, // optional - YYYY-MM-DD
      with_due_time: withDueDateTime, //
      due_time: dueTime, // HH:mm if due date is sat
    },
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Basic " + useAuthHeader.base64encodedData,
        service: "partners",
      },
      mode: "no-cors",
    }
  );
  return res.data;
}

/**
 *
 * @param {{TaskUUID: string}} param0
 * @returns Promise<any>
 */
export async function getTaskNotes({ taskUUID }) {
  const res = await partner.post(
    "/v1/client/tasks/data/notes",
    {
      task_uuid: taskUUID,
    },
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Basic " + useAuthHeader.base64encodedData,
        service: "partners",
      },
      mode: "no-cors",
    }
  );
  return res.data;
}

/**
 *  Change task due date
 * @param {{taskUUID: string, note:string}} param0
 * @returns {Promise<null>}
 */
export async function postNewTaskNote({ taskUUID, note }) {
  const res = await partner.post(
    "/v1/client/tasks/notes/add",
    {
      task_uuid: taskUUID,
      content: note,
    },
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Basic " + useAuthHeader.base64encodedData,
        service: "partners",
      },
      mode: "no-cors",
    }
  );
  return res.data;
}

export async function postUpdateTaskNote({ taskUUID, noteUUID, note }) {
  const res = await partner.post(
    "/v1/client/tasks/notes/update",
    {
      task_uuid: taskUUID,
      note_uuid: noteUUID,
      content: note,
    },
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Basic " + useAuthHeader.base64encodedData,
        service: "partners",
      },
      mode: "no-cors",
    }
  );
  return res.data;
}

/**
 *
 * @param {{taskUUID: string, noteUUID: string, note: string}} param0
 * @returns {Promise<null>}
 */

export async function addReplyToNote({ taskUUID, noteUUID, note }) {
  const res = await partner.post(
    "/v1/client/tasks/notes/reply",
    {
      task_uuid: taskUUID,
      note_uuid: noteUUID, // noteUUID of the note in which the reply is being made
      content: note,
    },
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Basic " + useAuthHeader.base64encodedData,
        service: "partners",
      },
      mode: "no-cors",
    }
  );

  return res.data;
}

export async function postUpdateReply({ taskUUID, replyUUID, note }) {
  const res = await partner.post(
    "/v1/client/tasks/notes/update-reply",
    {
      task_uuid: taskUUID,
      note_uuid: replyUUID, // replyUUID
      content: note,
    },
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Basic " + useAuthHeader.base64encodedData,
        service: "partners",
      },
    }
  );

  return res.data;
}

export async function postDeleteNote({ noteUUID }) {
  const res = await partner.post(
    "/v1/client/tasks/notes/delete",
    {
      note_uuid: noteUUID,
    },
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Basic " + useAuthHeader.base64encodedData,
        service: "partners",
      },
    }
  );

  return res.data;
}

/**
 *
 * @param {file,task_uuid,file_description,file_name} file
 * @returns {Promise<any>}
 */
export async function uploadTaskFile(file) {
  const res = await partner.post("/v1/client/tasks/files/upload", file, {
    headers: {
      "Content-Type": "multipart/form-data",
      Authorization: "Basic " + useAuthHeader.base64encodedData,
      service: "partners",
    },
  });

  return res.data;
}

export async function getTaskFiles({ taskUUID }) {
  const res = await partner.post(
    "/v1/client/tasks/data/files",
    {
      task_uuid: taskUUID,
    },
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Basic " + useAuthHeader.base64encodedData,
        service: "partners",
      },
    }
  );

  return res.data;
}

/**
 *
 * @param {{fileUUID: string, taskUUID: string}} param0
 * @returns
 */
export async function postDeleteTaskFile({ fileUUID, taskUUID }) {
  const res = await partner.post(
    "/v1/client/tasks/files/remove",
    {
      file_uuid: fileUUID,
      task_uuid: taskUUID,
    },
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Basic " + useAuthHeader.base64encodedData,
        service: "partners",
      },
    }
  );

  return res.data;
}

/**
 *
 * @param {{taskUUID: string, stepUUID: string, files: string[], content: string}} param0
 * @returns
 */
export async function completeTheWorkflowSteps({ taskUUID, stepUUID, files, content }) {
  const res = await partner.post(
    "/v1/client/tasks/step/complete",
    {
      task_uuid: taskUUID,
      step_uuid: stepUUID,
      files, // array of file uuids that have been uploaded as part of the step (required if file upload is required on the step)["uuid", "uuid"]
      content, // note content to add on the step being complete
    },
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Basic " + useAuthHeader.base64encodedData,
        service: "partners",
      },
    }
  );

  return res.data;
}

export async function undoCompleteTheWorkflowSteps({ taskUUID, stepUUID }) {
  const res = await partner.post(
    "/v1/client/tasks/step/reverse",
    {
      task_uuid: taskUUID,
      step_uuid: stepUUID,
    },
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Basic " + useAuthHeader.base64encodedData,
        service: "partners",
      },
    }
  );
  return res.data;
}

/**
 *
 * @param {{taskUUID: string}} param0
 * @returns {Promise<any>}
 */
export async function getTaskAssignmentHistory({ taskUUID }) {
  const res = await partner.post(
    "/v1/client/tasks/data/assignment-history",
    {
      task_uuid: taskUUID,
    },
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Basic " + useAuthHeader.base64encodedData,
        service: "partners",
      },
    }
  );
  return res.data;
}

/**
 *
 * @param {{fileUUID: string}} param0
 * @returns {Promise<any>}
 */
export async function getTaskFile({ fileUUID }) {
  const res = await partner.post(
    "/v1/client/tasks/files/download",
    {
      file_uuid: fileUUID,
    },
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Basic " + useAuthHeader.base64encodedData,
        service: "partners",
      },
    }
  );
  return res.data;
}

/**
 *
 * @param {{noteUUID: string}} param0
 * @returns {Promise<any>}
 */
export async function getTaskNotesHistory({ noteUUID }) {
  const res = await partner.post(
    "/v1/client/tasks/data/note/history",
    {
      note_uuid: noteUUID,
    },
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Basic " + useAuthHeader.base64encodedData,
        service: "partners",
      },
    }
  );
  return res.data;
}
