import { React, useEffect, useState, useCallback } from "react";

import authHeader from "../../api/authHeader";

import Lottie from "react-lottie";
import loadingLottie from "../../components/lotties/loading-dots.json";

import { useDropzone } from "react-dropzone";

import HandleError from "../../components/functions/HandleError";
import HandleToast from "../../components/functions/HandleToast";

import { Box, IconButton, Typography, Grid, Card, CardContent, Divider, Alert } from "@mui/material";
import { ContentCopy, CopyAll, Upload, PermMedia, RestartAlt, Close } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";

const Documents = ({ partner, partnerData, partnerUser, caseData, entityData }) => {
  const useAuthHeader = authHeader();
  const handleError = HandleError();
  const displayToast = HandleToast();

  const [isLoaded, setIsLoaded] = useState(false);
  const [processing, setProcessing] = useState(false);
  const [uploadForm, setUploadForm] = useState(false);
  const [file, setFile] = useState();

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: loadingLottie,
  };

  const onDrop = useCallback((acceptedFiles) => {
    acceptedFiles.forEach((file) => {
      setFile(file);
    });
  }, []);

  const { acceptedFiles, getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: {
      "image/png": [".png"],
      "image/jpeg": [".jpg", ".jpeg"],
      "image/gif": [".gif"],
      "image/svg+xml": [".svg"],
      "image/webp": [".webp"],
      "image/bmp": [".bmp"],
      "image/tiff": [".tiff"],
      "image/x-icon": [".ico"],
      "image/vnd.microsoft.icon": [".ico"],

      "video/mp4": [".mp4"],
      "video/mpeg": [".mpeg"],
      "video/ogg": [".ogg"],
      "video/webm": [".webm"],
      "video/3gpp": [".3gp"],
      "video/3gpp2": [".3g2"],
      "video/quicktime": [".mov"],
      "video/x-msvideo": [".avi"],
      "video/x-flv": [".flv"],
      "video/x-ms-wmv": [".wmv"],
      "video/x-matroska": [".mkv"],
      "video/x-ms-asf": [".asf"],
      "video/x-m4v": [".m4v"],
      "video/x-ms-vob": [".vob"],
      "video/x-ms-mpeg": [".mpg"],
      "video/x-ms-mpeg2": [".mpg"],
      "video/x-ms-mpeg4": [".mp4"],

      "application/pdf": [".pdf"],

      "application/msword": [".doc"],
      "application/msword": [".dot"],

      "application/vnd.openxmlformats-officedocument.wordprocessingml.document": [".docx"],
      "application/vnd.openxmlformats-officedocument.wordprocessingml.template": [".dotx"],
      "application/vnd.ms-word.document.macroEnabled.12": [".docm"],
      "application/vnd.ms-word.template.macroEnabled.12": [".dotm"],

      "application/vnd.ms-excel": [".xls"],
      "application/vnd.ms-excel": [".xlt"],
      "application/vnd.ms-excel": [".xla"],
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": [".xlsx"],
      "application/vnd.openxmlformats-officedocument.spreadsheetml.template": [".xltx"],
      "application/vnd.ms-excel.sheet.macroEnabled.12": [".xlsm"],
      "application/vnd.ms-excel.template.macroEnabled.12": [".xltm"],
      "application/vnd.ms-excel.addin.macroEnabled.12": [".xlam"],
      "application/vnd.ms-excel.sheet.binary.macroEnabled.12": [".xlsb"],

      "application/vnd.ms-powerpoint": [".ppt"],
      "application/vnd.ms-powerpoint": [".pot"],
      "application/vnd.ms-powerpoint": [".pps"],
      "application/vnd.ms-powerpoint": [".ppa"],

      "application/vnd.openxmlformats-officedocument.presentationml.presentation": [".pptx"],
      "application/vnd.openxmlformats-officedocument.presentationml.template": [".potx"],
      "application/vnd.openxmlformats-officedocument.presentationml.slideshow": [".ppsx"],
      "application/vnd.ms-powerpoint.addin.macroEnabled.12": [".ppam"],
      "application/vnd.ms-powerpoint.presentation.macroEnabled.12": [".pptm"],
      "application/vnd.ms-powerpoint.template.macroEnabled.12": [".potm"],
      "application/vnd.ms-powerpoint.slideshow.macroEnabled.12": [".ppsm"],

      "application/vnd.ms-access": [".mdb"],

      "text/csv": [".csv"],
      "text/plain": [".txt"],
      "application/json": [".json"],
      "application/xml": [".xml"],
      "application/zip": [".zip"],
      "application/x-rar-compressed": [".rar"],
      "application/x-7z-compressed": [".7z"],
      "application/x-tar": [".tar"],
      "application/x-gzip": [".gz"],
      "application/x-bzip2": [".bz2"],
    },
    maxFiles: 20,
  });

  const files = acceptedFiles.map((file) => <Typography key={file.path}>{file.path}</Typography>);

  const remove = (file) => {
    const newFiles = [...files]; // make a var for the new array
    acceptedFiles.splice(file, 1); // remove the file from the array
  };

  useEffect(() => {
    setIsLoaded(true);
  }, []);

  return (
    <>
      <Card
        variant="outlined"
        sx={{
          p: 0,
          width: "100%",
          borderRadius: "0",
        }}
      >
        <Box p={2} display="flex" alignItems="center">
          <Box>
            <Typography variant="h5">Document processing</Typography>
          </Box>
        </Box>
        <Divider />
        <CardContent sx={{ p: 0 }}>
          {isLoaded ? (
            <>
              {!uploadForm ? (
                <Grid container spacing={2}>
                  <Grid item xs={12} md={12} lg={6} xl={4}>
                    <Box sx={{ p: 2 }}>
                      <Box sx={{ border: 1, borderColor: "divider" }}>
                        <Box sx={{ borderBottom: 1, borderColor: "divider", px: 2, py: 1, display: "flex", justifyContent: "space-between" }}>
                          <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
                            <Typography variant="h6">Scan to email</Typography>
                          </Box>
                          <Box>
                            <IconButton title="Upload document" onClick={() => setUploadForm(true)}>
                              <Upload />
                            </IconButton>
                          </Box>
                        </Box>

                        <Box sx={{ p: 2 }}>
                          <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                            <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
                              <Typography variant="subtitle2">documents+a123456@cleverli.com</Typography>
                            </Box>
                            <IconButton
                              onClick={() => {
                                navigator.clipboard.writeText("documents+a123456@cleverli.com");
                              }}
                              title="Copy email address"
                            >
                              <ContentCopy />
                            </IconButton>
                          </Box>
                        </Box>
                      </Box>

                      <Box sx={{ border: 1, borderColor: "divider", mt: 2 }}>
                        <Box sx={{ borderBottom: 1, borderColor: "divider", px: 2, py: 1, display: "flex", justifyContent: "space-between" }}>
                          <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
                            <Typography variant="h6">Pending filing</Typography>
                          </Box>
                          <Box></Box>
                        </Box>

                        <Box sx={{ p: 2 }}>
                          <Alert variant="outlined" severity="info">
                            There are no documents pending filing
                          </Alert>
                        </Box>
                      </Box>
                    </Box>
                  </Grid>
                </Grid>
              ) : (
                <Box sx={{ p: 2 }}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={12} lg={6} xl={4}>
                      <Box sx={{ border: 1, borderColor: "divider" }}>
                        <Box sx={{ borderBottom: 1, borderColor: "divider", px: 2, py: 1, display: "flex", justifyContent: "space-between" }}>
                          <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
                            <Typography variant="h6">Upload document</Typography>
                          </Box>
                          <Box>
                            <IconButton title="Upload document" onClick={() => setUploadForm(false)}>
                              <Close />
                            </IconButton>
                          </Box>
                        </Box>

                        <Box sx={{ p: 2 }}>
                          <Typography variant="subtitle2">Select the files you wish to upload</Typography>

                          <Box
                            className="file-drop"
                            sx={{
                              border: 2,
                              borderColor: "divider",
                              borderStyle: "dashed",
                              cursor: "pointer",
                              p: 5,
                              my: 2,

                              display: "flex",
                              flexDirection: "column",
                              justifyContent: "center",
                            }}
                            {...getRootProps()}
                          >
                            <input {...getInputProps()} />
                            {isDragActive ? (
                              <Typography align="center">Drop the file here ...</Typography>
                            ) : (
                              <Typography align="center">Drop a file here, or click to select files</Typography>
                            )}
                          </Box>

                          {files?.length > 0 && (
                            <Box sx={{ display: "flex", flexDirection: "row", gap: 2, justifyItems: "center" }}>
                              <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
                                <PermMedia />
                              </Box>
                              <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>{files}</Box>
                            </Box>
                          )}

                          <Box sx={{ display: "flex", justifyContent: "flex-end", mt: 2 }}>
                            <LoadingButton disableElevation onClick={() => {}} loading={processing} variant="contained" color="success">
                              Upload
                            </LoadingButton>
                          </Box>
                        </Box>
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
              )}
            </>
          ) : (
            <Box>
              <Lottie speed={2.0} options={defaultOptions} height={200} width={200} />
            </Box>
          )}
        </CardContent>
      </Card>
    </>
  );
};

export default Documents;
