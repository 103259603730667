import { React, useEffect, useState } from "react";

import authHeader from "../../api/authHeader";

import HandleError from "../../components/functions/HandleError";
import NoResults from "../../components/functions/NoResults";

import Lottie from "react-lottie";
import loadingLottie from "../../components/lotties/loading-dots.json";

import moment from "moment";
import DataTable from "react-data-table-component";

import { Typography, Divider, Box, Button, Chip, Link, Fade } from "@mui/material";

import NewUser from "./components/users/NewUser";

const Users = ({ partner, partnerUser, partnerData }) => {
  const useAuthHeader = authHeader();
  const handleError = HandleError();

  const [isLoaded, setIsLoaded] = useState(false);
  const [data, setData] = useState([]);

  const [newUser, setNewUser] = useState(false);

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: loadingLottie,
  };

  const customStyles = {
    headCells: {
      style: {
        paddingLeft: "8px", // override the cell padding for head cells
        paddingRight: "8px",
        fontWeight: "bold",
      },
    },
    cells: {
      style: {
        paddingLeft: "8px", // override the cell padding for data cells
        paddingRight: "8px",
      },
    },
  };

  const columns = [
    {
      name: "Name",
      sortable: true,
      width: "30%",
      selector: (row) => row.name,
      cell: (row) => (
        <Link className="link" href={`/user/${row.uuid}`} underline="none" sx={{ fontSize: "13px" }}>
          {row.name}
        </Link>
      ),
    },
    {
      name: "Email address",
      sortable: true,
      selector: (row) => row.email_address,
      cell: (row) => (
        <Link className="link" href={`/user/${row.uuid}`} underline="none" sx={{ fontSize: "13px" }}>
          {row.email_address}
        </Link>
      ),
    },
    {
      name: "Last action",
      center: "true",
      sortable: true,
      selector: (row) => (row.last_action > 0 ? moment.unix(row.last_action).fromNow() : "-"),
    },
    {
      name: "Status",
      sortable: true,
      right: "true",
      selector: (row) => row.active,
      cell: (row) => (
        <Box>
          {row.active ? (
            <Chip className="square-chip" label="Active" color="success" />
          ) : (
            <Chip className="square-chip" label="Inactive" color="error" />
          )}
        </Box>
      ),
    },
  ];

  const getUsers = async () => {
    try {
      const response = await partner.post(`/v1/users/data/users`, JSON.stringify({}), {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Basic " + useAuthHeader.base64encodedData,
          service: "partners",
        },

        //withCredentials: true,
      });

      if (response.data.results > 0) {
        setData(response.data.entries);
      } else {
        setData([]);
      }
      setIsLoaded(true);
    } catch (err) {
      handleError(err);
    }
  };

  useEffect(() => {
    getUsers();
  }, []);

  return (
    <>
      {isLoaded ? (
        <>
          <Box sx={{ border: 1, borderColor: "divider" }}>
            <Box sx={{ p: 2, display: "flex", justifyContent: "space-between" }}>
              <Typography variant="h6">{newUser ? "Create a new user" : "User management"}</Typography>
              <Box sx={{ display: "flex", gap: 1 }}>
                <Button
                  onClick={() => {
                    setNewUser(!newUser);
                  }}
                  variant="contained"
                  color="primary"
                >
                  {newUser ? "Close" : "Create user"}
                </Button>
              </Box>
            </Box>
            <Divider />

            {newUser ? (
              <Box>
                <NewUser partner={partner} partnerUser={partnerUser} partnerData={partnerData} getUsers={getUsers} />
              </Box>
            ) : (
              <DataTable
                columns={columns}
                data={data}
                customStyles={customStyles}
                pagination
                striped
                noDataComponent={<NoResults message={`There are no users on your account`} />}
              />
            )}
          </Box>
        </>
      ) : (
        <Box>
          <Lottie speed={2.0} options={defaultOptions} height={200} width={200} />
        </Box>
      )}
    </>
  );
};

export default Users;
