import { useCallback, useEffect, useState } from "react";
import { getTaskNotes } from "../../api/services";

export function useGetTaskNotes(taskUUID) {
  const [notesData, setNotesData] = useState({
    data: null,
    isLoading: true,
  });

  const getTaskNotesQuery = useCallback(async () => {
    try {
      const taskNotes = await getTaskNotes({ taskUUID });
      setNotesData({
        data: taskNotes?.entries || [],
        isLoading: false,
      });
    } catch (error) {
      console.log(error);
    }
  }, [taskUUID]);

  useEffect(() => {
    if (notesData?.data || !taskUUID) return;
    getTaskNotesQuery(taskUUID);
  }, [getTaskNotesQuery, notesData?.data, taskUUID]);

  return {
    taskNotesList: notesData.data,
    isLoading: notesData.isLoading,
    getTaskNotesQuery,
  };
}
