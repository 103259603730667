import React from "react";
import { Routes, Route } from "react-router-dom";
import "./App.css";

import Login from "./pages/access/Login";
import Magic from "./pages/access/Magic";
import NoPage from "./pages/NoPage";

import Dashboard from "./pages/dashboard/Dashboard";
import ManageClient from "./pages/client/ManageClient";
import KycCase from "./pages/kyc-case/KycCase";
import KybCase from "./pages/kyb-case/KybCase";
import ManageUser from "./pages/user/ManageUser";
import VerifyEmail from "./pages/hosted/VerifyEmail";
import VerifyTelephone from "./pages/hosted/VerifyTelephone";
import HostedAuthentication from "./pages/hosted/HostedAuthentication";
import Hosted from "./pages/hosted/Hosted";
import SessionComplete from "./pages/hosted/SessionComplete";
import EddHosted from "./pages/hosted/EddHosted";

function App() {
  return (
    <Routes>
      <Route path="/" element={<Login />} />

      <Route path="/client">
        <Route path=":t" element={<ManageClient />} />
      </Route>

      <Route path="/magic">
        <Route path=":t" element={<Magic />} />
      </Route>

      <Route path="/verify-email">
        <Route path=":t" element={<VerifyEmail />} />
      </Route>

      <Route path="/verify-telephone">
        <Route path=":t" element={<VerifyTelephone />} />
      </Route>

      <Route path="/verify">
        <Route path=":t" element={<HostedAuthentication />} />
      </Route>

      <Route path="/hosted">
        <Route path=":t" element={<Hosted />} />
      </Route>

      <Route path="/e-hosted">
        <Route path=":t" element={<EddHosted />} />
      </Route>

      <Route path="/session-complete" element={<SessionComplete />} />

      <Route path="/dashboard" element={<Dashboard />} />

      <Route path="/case/kyc">
        <Route path=":t" element={<KycCase />} />
      </Route>

      <Route path="/case/kyb">
        <Route path=":t" element={<KybCase />} />
      </Route>

      <Route path="/user">
        <Route path=":t" element={<ManageUser />} />
      </Route>

      <Route path="*" element={<NoPage />} />
    </Routes>
  );
}

export default App;
