import { React, useEffect, useState, useCallback } from "react";

import authHeader from "../../../../../api/authHeader";

import HandleError from "../../../../../components/functions/HandleError";
import HandleToast from "../../../../../components/functions/HandleToast";

import { useDropzone } from "react-dropzone";
import Lottie from "react-lottie";
import loadingLottie from "../../../../../components/lotties/loading-dots.json";

import { Typography, Divider, Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";
import { PermMedia, RestartAlt } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";

const Branding = ({ partner, partnerUser, partnerData }) => {
  const useAuthHeader = authHeader();

  const handleError = HandleError();
  const displayToast = HandleToast();

  const [isLoaded, setIsLoaded] = useState(false);
  const [processing, setProcessing] = useState(false);

  const [isEditingLogo, setIsEditingLogo] = useState(false);
  const [logoSrc, setLogoSrc] = useState("https://placehold.co/600x200");
  const [file, setFile] = useState();

  const [confirmDialog, setConfirmDialog] = useState(false);

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: loadingLottie,
  };

  const onDrop = useCallback((acceptedFiles) => {
    acceptedFiles.forEach((file) => {
      setFile(file);
    });
  }, []);

  const { acceptedFiles, getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: {
      "image/png": [".png"],
      "image/jpeg": [".jpg", ".jpeg"],
    },
    maxFiles: 1,
  });

  const files = acceptedFiles.map((file) => <Typography key={file.path}>{file.path}</Typography>);

  const remove = (file) => {
    const newFiles = [...files]; // make a var for the new array
    acceptedFiles.splice(file, 1); // remove the file from the array
  };

  const getBrandData = async () => {
    try {
      const response = await partner.post(
        `/v1/partner/data/branding`,
        JSON.stringify({
          partner_uuid: partnerUser.value.partner_uuid,
        }),
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Basic " + useAuthHeader.base64encodedData,
            service: "partners",
          },

          //withCredentials: true,
        }
      );

      if (response.data.logo === "") {
        response.data.logo = "https://placehold.co/600x200";
      }

      setLogoSrc(response.data.logo);
      setIsLoaded(true);
    } catch (err) {
      handleError(err);
    }
  };

  const processLogoUpload = async (e) => {
    e.preventDefault();
    setProcessing(true);

    const formData = new FormData();
    formData.append("file", file);
    formData.append("fileName", file.name);
    formData.append("partner_uuid", partnerData.value.partner_uuid);

    try {
      const response = await partner.post("/v1/partner/update/logo", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: "Basic " + useAuthHeader.base64encodedData,
          service: "partners",
        },
        onUploadProgress: function (progressEvent) {},

        //withCredentials: true,
      });

      getBrandData();
      remove(0);
      displayToast("Logo uploaded successfully", () => {
        setProcessing(false);
        setIsEditingLogo(false);
      });
    } catch (err) {
      handleError(err);
      setProcessing(false);
    }
  };

  const handleReset = async (e) => {
    e.preventDefault();
    setProcessing(true);

    try {
      const response = await partner.post(`/v1/partner/update/logo/reset`, JSON.stringify({}), {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Basic " + useAuthHeader.base64encodedData,
          service: "partners",
        },

        //withCredentials: true,
      });

      setConfirmDialog(false);
      getBrandData();

      if (files?.length > 0) {
        remove(0);
      }

      displayToast(`Logo reset successfully`, () => {
        setIsEditingLogo(false);
        setProcessing(false);
      });
    } catch (err) {
      console.log(err);
      handleError(err);
      setProcessing(false);
    }
  };

  useEffect(() => {
    getBrandData();
  }, []);

  return (
    <>
      {isLoaded ? (
        <>
          <Box sx={{ width: "fit-content", minWidth: "600px", borderRight: 1, borderColor: "divider" }}>
            <Box sx={{ p: 2, display: "flex", justifyContent: "space-between" }}>
              <Typography variant="h6">Logo settings</Typography>
              <Box sx={{ display: "flex", gap: 1 }}>
                {isEditingLogo && logoSrc != "https://placehold.co/600x200" && (
                  <Button
                    onClick={() => {
                      setConfirmDialog(true);
                    }}
                    variant="outlined"
                    color="danger"
                    startIcon={<RestartAlt />}
                  >
                    Reset
                  </Button>
                )}

                <Button
                  onClick={() => {
                    setIsEditingLogo(!isEditingLogo);
                  }}
                  variant="contained"
                  color="primary"
                >
                  {isEditingLogo ? "Cancel" : "Edit"}
                </Button>
              </Box>
            </Box>
            <Divider />

            {isEditingLogo ? (
              <Box sx={{ p: 2 }}>
                <Typography variant="subtitle2">The recommended dimensions for the logo image are 600px x 200px</Typography>

                <Box
                  className="file-drop"
                  sx={{
                    border: 2,
                    borderColor: "divider",
                    borderStyle: "dashed",
                    cursor: "pointer",
                    p: 5,
                    my: 2,

                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                  }}
                  {...getRootProps()}
                >
                  <input {...getInputProps()} />
                  {isDragActive ? (
                    <Typography align="center">Drop the file here ...</Typography>
                  ) : (
                    <Typography align="center">Drop a file here, or click to select files</Typography>
                  )}
                  <Typography align="center" variant="caption">
                    (Only jpeg or png, files will be accepted)
                  </Typography>
                </Box>

                {files?.length > 0 && (
                  <Box sx={{ display: "flex", flexDirection: "row", gap: 2, justifyItems: "center" }}>
                    <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
                      <PermMedia />
                    </Box>
                    <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>{files}</Box>
                  </Box>
                )}

                <Box sx={{ display: "flex", justifyContent: "flex-end", mt: 2 }}>
                  <LoadingButton disableElevation onClick={processLogoUpload} loading={processing} variant="contained" color="success">
                    Upload
                  </LoadingButton>
                </Box>
              </Box>
            ) : (
              <Box sx={{ display: "flex", justifyContent: "center", p: 2 }}>
                <img style={{ maxWidth: "600px", maxHeight: "300px" }} src={logoSrc} alt="logo" />
              </Box>
            )}
          </Box>

          <Dialog
            open={confirmDialog}
            onClose={() => setConfirmDialog(false)}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">Are you sure?</DialogTitle>
            <DialogContent>
              <Box>
                <DialogContentText sx={{ mb: 1 }} id="alert-dialog-description">
                  Are you sure you want to reset the logo settings?
                </DialogContentText>
              </Box>
            </DialogContent>
            <DialogActions>
              <LoadingButton disableElevation loading={processing} onClick={() => setConfirmDialog(false)} color="primary">
                Cancel
              </LoadingButton>

              <LoadingButton
                disableElevation
                sx={{ color: "#ffffff" }}
                loading={processing}
                onClick={(e) => {
                  handleReset(e);
                }}
                variant="contained"
                color="success"
                autoFocus
              >
                Confirm
              </LoadingButton>
            </DialogActions>
          </Dialog>
        </>
      ) : (
        <Box>
          <Lottie speed={2.0} options={defaultOptions} height={200} width={200} />
        </Box>
      )}
    </>
  );
};

export default Branding;
