import { React, useState, useEffect } from "react";

import getSymbolFromCurrency from "currency-symbol-map";

import { Box, Collapse, TextField } from "@mui/material";
import CurrencyTextField from "@puhl/mui-currency-textfield";

import CurrencySelect from "../../../../components/form-elements/CurrencySelect";

const Money = ({ questionUUID, answersData, setAnswersData }) => {
  const [currencySymbol, setCurrencySymbol] = useState("");

  const [amountValue, setAmountValue] = useState("");
  const [currencyValue, setCurrencyValue] = useState(null);

  useEffect(() => {
    setAnswersData({
      ...answersData,
      [questionUUID]: {
        amount: amountValue,
        currency: currencyValue,
      },
    });
  }, [amountValue, currencyValue]);

  useEffect(() => {
    if (currencyValue) {
      setCurrencySymbol(getSymbolFromCurrency(currencyValue.value));
    } else {
      setCurrencySymbol("");
      setAmountValue("");
    }
  }, [currencyValue]);

  useEffect(() => {
    if (answersData[questionUUID]) {
      const { amount, currency } = answersData[questionUUID];
      setAmountValue(amount);
      setCurrencyValue(currency);
    }
  }, []);

  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
      <CurrencySelect value={currencyValue} setValue={setCurrencyValue} />

      <Collapse in={currencySymbol != ""} exit={currencySymbol == ""}>
        <Box sx={{ mb: 2 }}>
          <CurrencyTextField
            fullWidth
            label="Amount"
            variant="outlined"
            value={amountValue}
            currencySymbol={currencySymbol}
            outputFormat="number"
            decimalCharacter="."
            digitGroupSeparator=","
            onChange={(event, value) => setAmountValue(value)}
          />
        </Box>
      </Collapse>
    </Box>
  );
};

export default Money;
