import { React, useState } from "react";

import authHeader from "../../../../../../../api/authHeader";

import HandleError from "../../../../../../../components/functions/HandleError";
import HandleToast from "../../../../../../../components/functions/HandleToast";

import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

import { Box, Divider, Grid, TextField, Typography, ToggleButtonGroup, ToggleButton } from "@mui/material";
import { LoadingButton } from "@mui/lab";

const NewTemplate = ({ partner, partnerUser, partnerData, setNewTemplate, getTemplates, setActiveTemplate, setManageTemplate }) => {
  const useAuthHeader = authHeader();

  const handleError = HandleError();
  const displayToast = HandleToast();

  const [templateName, setTemplateName] = useState("");
  const [templateDescription, setTemplateDescription] = useState("");
  const [templateType, setTemplateType] = useState(1);

  const [processing, setProcessing] = useState(false);

  const handleSaveTemplate = async (e) => {
    e.preventDefault();
    setProcessing(true);

    try {
      const response = await partner.post(
        `/v1/client/tasks/templates/create`,
        JSON.stringify({
          name: templateName,
          template_type: parseInt(templateType),
          description: templateDescription,
        }),
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Basic " + useAuthHeader.base64encodedData,
            service: "partners",
          },

          //withCredentials: true,
        }
      );

      getTemplates();

      displayToast("Template created", () => {
        setTemplateName("");
        setTemplateDescription("");
        setProcessing(false);
        setNewTemplate(false);
      });
    } catch (err) {
      handleError(err);
      setProcessing(false);
    }
  };

  return (
    <Box>
      <Box sx={{ p: 2, display: "flex", flexDirection: "column", gap: 2 }}>
        <Box sx={{ display: "flex", justifyContent: "space-between", border: 1, borderColor: "divider" }}>
          <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center", p: 2 }}>
            <Typography variant="subtitle2">Template type</Typography>
          </Box>
          <Box sx={{ p: 2 }}>
            <ToggleButtonGroup
              color="primary"
              value={templateType}
              exclusive
              onChange={(e, newValue) => {
                if (newValue !== null) setTemplateType(newValue);
              }}
              aria-label="Template type"
            >
              <ToggleButton value={1}>Freeform</ToggleButton>
              <ToggleButton value={2}>Workflow</ToggleButton>
            </ToggleButtonGroup>
          </Box>
        </Box>

        <Box sx={{ border: 1, borderColor: "divider" }}>
          <Box sx={{ p: 2 }}>
            <Typography variant="subtitle2">What is the name of the template?</Typography>
          </Box>
          <Divider />

          <Box sx={{ p: 2, display: "flex", flexDirection: "column", gap: 2 }}>
            <TextField
              fullWidth
              id="outlined-basic"
              label="Template name"
              variant="outlined"
              value={templateName}
              onChange={(e) => setTemplateName(e.target.value)}
            />
          </Box>
        </Box>

        <Box sx={{ border: 1, borderColor: "divider" }}>
          <Box sx={{ p: 2 }}>
            <Typography variant="subtitle2">Provide a description and instructions for the task</Typography>
          </Box>

          <Divider />
          <ReactQuill theme="snow" value={templateDescription} onChange={setTemplateDescription} style={{ minHeight: "300px" }} />
        </Box>

        <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
          <LoadingButton
            disableElevation
            variant="contained"
            color="success"
            loading={processing}
            onClick={(e) => {
              handleSaveTemplate(e);
            }}
          >
            Create template
          </LoadingButton>
        </Box>
      </Box>
    </Box>
  );
};

export default NewTemplate;
