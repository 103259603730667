import { React, useEffect, useState } from "react";

import authHeader from "../../../api/hostedAuthHeader";

import HandleError from "../../../components/functions/HostedHandleError";
import HandleToast from "../../../components/functions/HandleToast";

import Lottie from "react-lottie";
import loadingLottie from "../../../components/lotties/loading-dots.json";

import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import moment from "moment";
import parse from "html-react-parser";

import { Close, Delete, Edit, Add, PermMedia } from "@mui/icons-material";
import {
  Box,
  Divider,
  IconButton,
  Typography,
  Button,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  DialogContentText,
  Collapse,
  ToggleButtonGroup,
  ToggleButton,
  Chip,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";

import CountrySelect from "../../../components/form-elements/CountrySelect";
import NoResults from "../../../components/functions/NoResults";

const PepDeclaration = ({ partner, caseUUID, entityData, caseData, activeView, getPerson, requestData, getRequestData, getCase }) => {
  const useAuthHeader = authHeader();
  const handleError = HandleError();
  const displayToast = HandleToast();

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: loadingLottie,
  };

  const pepPositions = [
    { value: "1", label: "Head of State" },
    { value: "2", label: "Government ministers, cabinet members or equivalent politicians" },
    { value: "3", label: "Senior government executives" },
    { value: "4", label: "Diplomatic officials" },
    { value: "5", label: "Leader or senior officials of a political party" },
    { value: "6", label: "Judicial Officials" },
    { value: "7", label: "Military Officials" },
    { value: "8", label: "Central bank governors" },
    {
      value: "9",
      label: "Board members or senior executives of an intergovernmental organization or stated-owned corporation",
    },
  ];

  const relationshipTypes = [
    { value: "1", label: "Spouses, partners, parents of children" },
    { value: "2", label: "Parents" },
    { value: "3", label: "Children" },
    { value: "4", label: "Siblings" },
    { value: "5", label: "Uncles, aunts, cousins" },
    { value: "6", label: "Close friends" },
    { value: "7", label: "Legal advisors" },
    { value: "8", label: "Individuals with legal arrangements or close business or political relationships with a PEP" },
    { value: "9", label: "Other" },
  ];

  const titleOptions = [
    { value: "Mr", label: "Mr" },
    { value: "Mrs", label: "Mrs" },
    { value: "Miss", label: "Miss" },
    { value: "Ms", label: "Ms" },
    { value: "Dr", label: "Dr" },
    { value: "Prof", label: "Prof" },
    { value: "Rev", label: "Rev" },
    { value: "Sir", label: "Sir" },
    { value: "Madam", label: "Madam" },
    { value: "Lord", label: "Lord" },
    { value: "Lady", label: "Lady" },
    { value: "Fr", label: "Father" },
    { value: "Sr", label: "Sister" },
    { value: "Imam", label: "Imam" },
    { value: "Sheikh", label: "Sheikh" },
    { value: "Rabbi", label: "Rabbi" },
    { value: "Duke", label: "Duke" },
    { value: "Duchess", label: "Duchess" },
    { value: "Earl", label: "Earl" },
    { value: "Count", label: "Count" },
    { value: "Countess", label: "Countess" },
    { value: "Baron", label: "Baron" },
    { value: "Baroness", label: "Baroness" },
    { value: "Capt", label: "Captain" },
    { value: "Lt", label: "Lieutenant" },
    { value: "Col", label: "Colonel" },
    { value: "Gen", label: "General" },
  ];

  const [isLoaded, setIsLoaded] = useState(false);
  const [processing, setProcessing] = useState(false);

  const [editMode, setEditMode] = useState(false);

  const [editType, setEditType] = useState(0);

  const [declarationData, setDeclarationData] = useState(null);

  const [isPEP, setIsPEP] = useState(declarationData?.is_pep ? declarationData.is_pep : false);
  const [hasAssociates, setHasAssociates] = useState(declarationData?.has_associates ? declarationData.has_associates : false);

  const [positionType, setPositionType] = useState("0");
  const [pepCountry, setPepCountry] = useState(
    declarationData?.country ? { value: declarationData.country?.code, label: declarationData.country?.name } : null
  );
  const [pepContent, setPepContent] = useState(declarationData?.content ? declarationData.content : "");

  const [associateTitle, setAssociateTitle] = useState("");
  const [associateFirstName, setAssociateFirstName] = useState("");
  const [associateMiddleNames, setAssociateMiddleNames] = useState("");
  const [associateLastName, setAssociateLastName] = useState("");
  const [associateRelationshipType, setAssociateRelationshipType] = useState("0");
  const [associateRelationshipOther, setAssociateRelationshipOther] = useState("");
  const [associatePositionType, setAssociatePositionType] = useState("0");
  const [associateCountry, setAssociateCountry] = useState(null);
  const [associateContent, setAssociateContent] = useState("");

  const [activeAssociate, setActiveAssociate] = useState("");
  const [deleteAssociateDialog, setDeleteAssociateDialog] = useState(false);

  const getPepDeclaration = async () => {
    try {
      const response = await partner.post(
        `/v1/entity/people/person/data/pep-declaration`,
        JSON.stringify({
          person_uuid: entityData.value.person_uuid,
        }),
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Basic " + useAuthHeader.base64encodedData,
            service: "hosted",
          },

          //withCredentials: true,
        }
      );

      if (!response.data.complete) {
        setEditMode(true);
      }

      if (response.data.complete) {
        setIsPEP(response.data.is_pep);
        setHasAssociates(response.data.has_associates);
        if (response.data.is_pep) {
          setPepCountry({ value: response.data.country?.code, name: response.data.country?.name });
          setPositionType(response.data.position_type.toString());
          setPepContent(response.data.content);
        }
      }
      setDeclarationData(response.data);
      setIsLoaded(true);
    } catch (err) {
      console.log(err);
      handleError(err);
    }
  };

  const handleSaveDeclaration = async (e) => {
    e.preventDefault();
    setProcessing(true);

    try {
      const response = await partner.post(
        `/v1/entity/people/person/pep-declaration/update`,
        JSON.stringify({
          person_uuid: entityData.value.person_uuid,
          is_pep: isPEP,
          position: parseInt(positionType),
          country: pepCountry ? pepCountry.value : "",
          has_associates: hasAssociates,
          content: pepContent,
        }),
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Basic " + useAuthHeader.base64encodedData,
            service: "hosted",
          },

          //withCredentials: true,
        }
      );

      getPepDeclaration();
      getRequestData();
      getCase();

      displayToast("Declaration saved successfully", () => {
        setProcessing(false);
        setEditMode(false);
      });
    } catch (err) {
      console.log(err);
      handleError(err);
      setProcessing(false);
    }
  };

  const handleAddAssociate = async (e) => {
    e.preventDefault();
    setProcessing(true);

    try {
      const response = await partner.post(
        `/v1/entity/people/person/pep-declaration/associates/add`,
        JSON.stringify({
          person_uuid: entityData.value.person_uuid,
          title: associateTitle,
          first_name: associateFirstName,
          middle_names: associateMiddleNames,
          last_name: associateLastName,

          relationship_type: parseInt(associateRelationshipType),
          other_relationship: associateRelationshipOther ? associateRelationshipOther : "",
          position: parseInt(associatePositionType),
          country: associateCountry ? associateCountry.value : "",
          content: associateContent,
        }),
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Basic " + useAuthHeader.base64encodedData,
            service: "hosted",
          },

          //withCredentials: true,
        }
      );

      getPepDeclaration();
      getRequestData();
      getCase();

      displayToast("Associate added successfully", () => {
        setProcessing(false);
        setEditMode(0);
        setEditMode(false);
      });
    } catch (err) {
      console.log(err);
      handleError(err);
      setProcessing(false);
    }
  };

  const handleDeleteAssociate = async (e) => {
    e.preventDefault();
    setProcessing(true);

    try {
      const response = await partner.post(
        `/v1/entity/people/person/pep-declaration/associates/delete`,
        JSON.stringify({
          person_uuid: entityData.value.person_uuid,
          associate_uuid: activeAssociate,
        }),
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Basic " + useAuthHeader.base64encodedData,
            service: "hosted",
          },

          //withCredentials: true,
        }
      );

      getPepDeclaration();
      getRequestData();
      getCase();

      displayToast("Associate deleted successfully", () => {
        setProcessing(false);
        setEditMode(0);
        setEditMode(false);
      });
    } catch (err) {
      console.log(err);
      handleError(err);
      setProcessing(false);
    }
  };

  const getAssociateData = async (uuid) => {
    try {
      const response = await partner.post(
        `/v1/entity/people/person/data/pep-declaration/associate`,
        JSON.stringify({
          person_uuid: entityData.value.person_uuid,
          associate_uuid: uuid,
        }),
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Basic " + useAuthHeader.base64encodedData,
            service: "hosted",
          },

          //withCredentials: true,
        }
      );

      setAssociateTitle(response.data.title);
      setAssociateFirstName(response.data.first_name);
      setAssociateMiddleNames(response.data.middle_names);
      setAssociateLastName(response.data.last_name);
      setAssociateRelationshipType(response.data.relationship_type.toString());
      setAssociateRelationshipOther(response.data.other_relationship);
      setAssociateCountry({ value: response.data.country?.code, name: response.data.country?.name });
      setAssociatePositionType(response.data.position_type.toString());
      setAssociateContent(response.data.content);

      setActiveAssociate(response.data.uuid);
      setEditType(1);
      setEditMode(true);
    } catch (err) {
      console.log(err);
      handleError(err);
    }
  };

  useEffect(() => {
    getPepDeclaration();
  }, []);

  return isLoaded ? (
    <Box>
      <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "space-between", borderBottom: 1, borderColor: "divider" }}>
        <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center", p: 2 }}>
          <Typography variant="subtitle2">PEP Declaration</Typography>
        </Box>
        <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center", p: 2 }}>
          <IconButton
            title="Edit"
            onClick={() => {
              setEditMode(!editMode);
            }}
          >
            {editMode ? <Close /> : <Edit />}
          </IconButton>
        </Box>
      </Box>

      {!editMode ? (
        <Box>
          <Box sx={{ display: "flex", flexDirection: "column" }}>
            <Box sx={{ borderTop: 0, borderColor: "divider", display: "flex", justifyContent: "space-between" }}>
              <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
                <Typography variant="subtitle" sx={{ fontSize: "13px", p: 2 }}>
                  Are you a politically exposed person (PEP)?
                </Typography>
              </Box>
              <Box sx={{ px: 2, display: "flex", flexDirection: "column", justifyContent: "center" }}>
                {declarationData.complete ? (
                  declarationData?.is_pep ? (
                    <Chip className="square-chip" label="YES" sx={{ backgroundColor: "#C83E4D", color: "#ffffff" }} />
                  ) : (
                    <Chip className="square-chip" label="No" sx={{ backgroundColor: "#0b0b0b", color: "#ffffff" }} />
                  )
                ) : (
                  <Typography variant="subtitle2" sx={{ fontSize: "13px" }}>
                    -
                  </Typography>
                )}
              </Box>
            </Box>

            {declarationData.complete && declarationData?.is_pep && (
              <Box>
                <Box sx={{ borderTop: 1, borderColor: "divider", display: "flex", justifyContent: "space-between", p: 2 }}>
                  <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
                    <Typography variant="subtitle" sx={{ fontSize: "13px" }}>
                      What position do you hold?
                    </Typography>
                  </Box>
                  <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
                    <Typography variant="subtitle2" sx={{ fontSize: "13px" }}>
                      {declarationData.position_type_label}
                    </Typography>
                  </Box>
                </Box>
                <Box sx={{ borderTop: 1, borderColor: "divider", display: "flex", justifyContent: "space-between", p: 2 }}>
                  <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
                    <Typography variant="subtitle" sx={{ fontSize: "13px" }}>
                      In what country are you a PEP?
                    </Typography>
                  </Box>
                  <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
                    <Typography variant="subtitle2" sx={{ fontSize: "13px" }}>
                      {declarationData?.country?.name}
                    </Typography>
                  </Box>
                </Box>

                {declarationData.content && (
                  <Box sx={{ borderTop: 1, borderColor: "divider", display: "flex", justifyContent: "space-between", p: 2 }}>
                    <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
                      <Typography variant="subtitle" sx={{ fontSize: "13px" }}>
                        Information
                      </Typography>
                    </Box>
                    <Box sx={{ width: "75%", textAlign: "end" }}>{parse(declarationData.content)}</Box>
                  </Box>
                )}

                <Box sx={{ borderTop: 1, borderColor: "divider", display: "flex", justifyContent: "space-between", p: 2 }}>
                  <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
                    <Typography variant="subtitle" sx={{ fontSize: "13px" }}>
                      Are you closely related to or associated with politcally exposed people?
                    </Typography>
                  </Box>
                  <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
                    {declarationData ? (
                      declarationData?.has_associates ? (
                        <Chip className="square-chip" label="YES" sx={{ backgroundColor: "#C83E4D", color: "#ffffff", textTransform: "uppercase" }} />
                      ) : (
                        <Chip className="square-chip" label="No" sx={{ backgroundColor: "#0b0b0b", color: "#ffffff", textTransform: "uppercase" }} />
                      )
                    ) : (
                      <Typography variant="subtitle2" sx={{ fontSize: "13px", p: 2 }}>
                        -
                      </Typography>
                    )}
                  </Box>
                </Box>
              </Box>
            )}

            {declarationData.complete && declarationData?.has_associates && (
              <Box>
                <Box
                  sx={{
                    borderTop: 1,
                    borderColor: "divider",
                    display: "flex",
                    justifyContent: "space-between",
                    backgroundColor: "#0b0b0b",
                    color: "#ffffff",
                  }}
                >
                  <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
                    <Typography variant="body2" sx={{ px: 2, py: 1, fontWeight: 600 }}>
                      Politically exposed relatives or associates
                    </Typography>
                  </Box>
                  <Box sx={{ px: 2, py: 1 }}>
                    <IconButton
                      sx={{ color: "#ffffff" }}
                      onClick={() => {
                        setAssociateTitle("");
                        setAssociateFirstName("");
                        setAssociateMiddleNames("");
                        setAssociateLastName("");
                        setAssociateRelationshipType("0");
                        setAssociateRelationshipOther("");
                        setAssociatePositionType("0");
                        setAssociateCountry(null);
                        setAssociateContent("");

                        setEditType(1);
                        setEditMode(!editMode);
                      }}
                    >
                      {editMode ? <Close /> : <Add />}
                    </IconButton>
                  </Box>
                </Box>

                {declarationData?.associates?.length > 0 ? (
                  declarationData.associates.map((associate, index) => (
                    <Box key={index} sx={{ borderTop: 1, borderColor: "divider", display: "flex", justifyContent: "space-between" }}>
                      <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
                        <Box sx={{ display: "flex", flexDirection: "row", gap: 1 }}>
                          <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center", pl: 1 }}>
                            <IconButton onClick={() => getAssociateData(associate.uuid)}>
                              <Edit />
                            </IconButton>
                          </Box>
                          <Typography variant="subtitle" sx={{ fontSize: "13px", p: 2 }}>
                            {associate.name}
                          </Typography>
                        </Box>
                      </Box>
                      <Box sx={{ px: 2, py: 1, display: "flex", flexDirection: "column", gap: 1 }}>
                        <Typography variant="subtitle2" sx={{ fontSize: "13px", textAlign: "end" }}>
                          {associate.relationship}
                        </Typography>

                        <Typography variant="subtitle2" sx={{ fontSize: "13px", textAlign: "end" }}>
                          {associate.position_type_label}
                        </Typography>

                        <Typography variant="subtitle2" sx={{ fontSize: "13px", textAlign: "end" }}>
                          {associate.country?.name}
                        </Typography>

                        {associate.country?.high_risk && (
                          <Chip
                            className="square-chip"
                            label="High risk"
                            size="small"
                            sx={{ backgroundColor: "#C83E4D", color: "#ffffff", textTransform: "uppercase" }}
                          />
                        )}
                        {associate.country?.conflict && (
                          <Chip
                            className="square-chip"
                            label="Conflict"
                            size="small"
                            sx={{ backgroundColor: "#FF4A1C", color: "#1C2321", textTransform: "uppercase" }}
                          />
                        )}
                        {associate.country?.borders_conflict && (
                          <Chip
                            className="square-chip"
                            label="Borders conflict"
                            size="small"
                            sx={{ backgroundColor: "#977390", color: "#1C2321", textTransform: "uppercase" }}
                          />
                        )}
                        {associate.country?.is_secrecy && (
                          <Chip
                            className="square-chip"
                            label="Secrecy"
                            size="small"
                            sx={{ backgroundColor: "#999999", color: "#1C2321", textTransform: "uppercase" }}
                          />
                        )}
                      </Box>
                    </Box>
                  ))
                ) : (
                  <NoResults message={`Add atleast one associate to complete the declaration`} />
                )}
              </Box>
            )}
          </Box>

          <Box sx={{ p: 2, display: "flex", flexDirection: "row", justifyContent: "flex-end", borderTop: 1, borderColor: "divider" }}>
            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                requestData.requirements.fatca ? (activeView.value = 9) : (activeView.value = 1);
              }}
            >
              Continue
            </Button>
          </Box>
        </Box>
      ) : (
        <Box>
          {editType == 0 ? (
            <Box sx={{}}>
              <Box sx={{ display: "flex", flexDirection: "column" }}>
                <Box>
                  <Box sx={{ p: 2 }}>
                    <Typography variant="subtitle" sx={{ fontSize: "13px" }}>
                      A Politically Exposed Person (PEP) is defined as an individual that holds or has held prominent public functions, either foreign
                      or domestic, within a country or international organization.
                    </Typography>
                  </Box>
                  <Divider />
                </Box>
                <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "space-between", p: 2 }}>
                  <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
                    <Typography variant="subtitle2">Are you a Politically Exposed Person?</Typography>
                  </Box>

                  <Box>
                    <ToggleButtonGroup
                      color="primary"
                      value={isPEP}
                      exclusive
                      onChange={(e, value) => {
                        if (value !== null) setIsPEP(value);
                      }}
                    >
                      <ToggleButton value={true}>Yes</ToggleButton>
                      <ToggleButton value={false}>No</ToggleButton>
                    </ToggleButtonGroup>
                  </Box>
                </Box>

                <Collapse in={isPEP} exit={!isPEP}>
                  <Divider />
                  <Box sx={{ p: 2 }}>
                    <Box>
                      <FormControl fullWidth>
                        <InputLabel id="position-type-label">What type of position do you hold?</InputLabel>
                        <Select
                          labelId="position-type-label"
                          id="position-type"
                          label="What type of position do you hold?"
                          value={positionType ? positionType : ""}
                          onChange={(e) => {
                            setPositionType(e.target.value);
                          }}
                        >
                          <MenuItem value="0" disabled={true}>
                            Select a position
                          </MenuItem>
                          {pepPositions.map((position) => (
                            <MenuItem key={position.value} value={position.value}>
                              {position.label}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Box>
                  </Box>
                  <Divider />
                  <Box sx={{ p: 2 }}>
                    <CountrySelect label="In what country are you a PEP?" value={pepCountry} setValue={setPepCountry} />
                  </Box>
                  <Divider />
                  <Typography variant="subtitle2" sx={{ p: 2 }}>
                    Add any additional information
                  </Typography>
                  <Divider />
                  <Box>
                    <ReactQuill theme="snow" value={pepContent} onChange={setPepContent} style={{ minHeight: "300px" }} />
                  </Box>
                </Collapse>

                <Divider />

                <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "space-between", p: 2 }}>
                  <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
                    <Typography variant="subtitle2">Does you have any relatives or close associates that are considered a PEP?</Typography>
                  </Box>

                  <Box>
                    <ToggleButtonGroup
                      color="primary"
                      value={hasAssociates}
                      exclusive
                      onChange={(e, value) => {
                        if (value !== null) setHasAssociates(value);
                      }}
                    >
                      <ToggleButton value={true}>Yes</ToggleButton>
                      <ToggleButton value={false}>No</ToggleButton>
                    </ToggleButtonGroup>
                  </Box>
                </Box>

                <Divider />

                <Box sx={{ p: 2, display: "flex", flexDirection: "row", justifyContent: "flex-end" }}>
                  <LoadingButton
                    disableElevation
                    variant="contained"
                    color="success"
                    onClick={(e) => {
                      handleSaveDeclaration(e);
                    }}
                    loading={processing}
                  >
                    Save declaration
                  </LoadingButton>
                </Box>
              </Box>
            </Box>
          ) : (
            <Box sx={{}}>
              <Box sx={{ display: "flex", flexDirection: "column" }}>
                <Box>
                  <Box sx={{ p: 2 }}>
                    <Typography variant="subtitle" sx={{ fontSize: "13px" }}>
                      A Politically Exposed Person (PEP) is defined as an individual that holds or has held prominent public functions, either foreign
                      or domestic, within a country or international organization.
                    </Typography>
                  </Box>
                  <Divider />
                </Box>

                <Box sx={{ display: "flex", flexDirection: "column", gap: 2, p: 2 }}>
                  <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
                    <Typography variant="subtitle2">What is their name?</Typography>
                  </Box>

                  <Box sx={{}}>
                    <FormControl fullWidth>
                      <InputLabel id="name-title-label">Title</InputLabel>
                      <Select
                        labelId="name-title"
                        id="name-title"
                        value={associateTitle}
                        label="Title"
                        onChange={(e) => setAssociateTitle(e.target.value)}
                      >
                        {titleOptions.map((option, index) => (
                          <MenuItem key={index} value={option.value}>
                            {option.label}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Box>

                  <Box sx={{}}>
                    <TextField
                      fullWidth
                      label="First name"
                      variant="outlined"
                      value={associateFirstName ? associateFirstName : ""}
                      onChange={(e) => {
                        setAssociateFirstName(e.target.value);
                      }}
                    />
                  </Box>

                  <Box sx={{}}>
                    <TextField
                      fullWidth
                      label="Middle names"
                      variant="outlined"
                      value={associateMiddleNames ? associateMiddleNames : ""}
                      onChange={(e) => {
                        setAssociateMiddleNames(e.target.value);
                      }}
                    />
                  </Box>

                  <Box sx={{}}>
                    <TextField
                      fullWidth
                      label="Last name"
                      variant="outlined"
                      value={associateLastName ? associateLastName : ""}
                      onChange={(e) => {
                        setAssociateLastName(e.target.value);
                      }}
                    />
                  </Box>
                </Box>

                <Divider />

                <Box sx={{ p: 2 }}>
                  <Box>
                    <FormControl fullWidth>
                      <InputLabel id="position-type-label">What is their relationship to you?</InputLabel>
                      <Select
                        labelId="associate-relationship-label"
                        id="associate-relationship-type"
                        label={`What is their relationship to you?`}
                        value={associateRelationshipType ? associateRelationshipType : ""}
                        onChange={(e) => {
                          setAssociateRelationshipType(e.target.value);
                        }}
                      >
                        <MenuItem value="0" disabled={true}>
                          Select a relationship type
                        </MenuItem>
                        {relationshipTypes.map((position) => (
                          <MenuItem key={position.value} value={position.value}>
                            {position.label}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Box>
                </Box>

                <Collapse in={associateRelationshipType == 9} exit={associateRelationshipType != 9}>
                  <Box sx={{ p: 2, pt: 0 }}>
                    <TextField
                      fullWidth
                      label="Other relationship"
                      variant="outlined"
                      value={associateRelationshipOther ? associateRelationshipOther : ""}
                      onChange={(e) => {
                        setAssociateRelationshipOther(e.target.value);
                      }}
                    />
                  </Box>
                </Collapse>

                <Divider />
                <Box sx={{ p: 2 }}>
                  <Box>
                    <FormControl fullWidth>
                      <InputLabel id="position-type-label">What type of position do they hold?</InputLabel>
                      <Select
                        labelId="associate-position-type-label"
                        id="associate-position-type"
                        label="What type of position do they hold?"
                        value={associatePositionType ? associatePositionType : ""}
                        onChange={(e) => {
                          setAssociatePositionType(e.target.value);
                        }}
                      >
                        <MenuItem value="0" disabled={true}>
                          Select a position
                        </MenuItem>
                        {pepPositions.map((position) => (
                          <MenuItem key={position.value} value={position.value}>
                            {position.label}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Box>
                </Box>
                <Divider />
                <Box sx={{ p: 2 }}>
                  <CountrySelect label="In what country are they a PEP?" value={associateCountry} setValue={setAssociateCountry} />
                </Box>
                <Divider />
                <Typography variant="subtitle2" sx={{ p: 2 }}>
                  Add any additional information
                </Typography>
                <Divider />
                <Box>
                  <ReactQuill theme="snow" value={associateContent} onChange={setAssociateContent} style={{ minHeight: "300px" }} />
                </Box>

                <Divider />

                <Box sx={{ p: 2, display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                  <Box sx={{}}>
                    {activeAssociate && (
                      <Button variant="outlined" color="error" startIcon={<Delete />} onClick={() => {}}>
                        Delete associate
                      </Button>
                    )}
                  </Box>
                  <LoadingButton
                    disableElevation
                    variant="contained"
                    color="success"
                    onClick={(e) => {
                      activeAssociate ? "" : handleAddAssociate(e);
                    }}
                    loading={processing}
                  >
                    Add associate
                  </LoadingButton>
                </Box>
              </Box>
              <Dialog
                open={deleteAssociateDialog}
                onClose={() => setDeleteAssociateDialog(false)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
              >
                <DialogTitle id="alert-dialog-title">Are you sure?</DialogTitle>
                <DialogContent>
                  <Box>
                    <DialogContentText sx={{ mb: 1 }} id="alert-dialog-description">
                      Are you sure you want to delete this associate?
                    </DialogContentText>
                  </Box>
                </DialogContent>
                <DialogActions>
                  <LoadingButton disableElevation loading={processing} onClick={() => setDeleteAssociateDialog(false)} color="primary">
                    Cancel
                  </LoadingButton>

                  <LoadingButton
                    disableElevation
                    sx={{ color: "#ffffff" }}
                    loading={processing}
                    onClick={(e) => {
                      handleDeleteAssociate(e);
                    }}
                    variant="contained"
                    color="danger"
                    autoFocus
                  >
                    Confirm
                  </LoadingButton>
                </DialogActions>
              </Dialog>
            </Box>
          )}
        </Box>
      )}
    </Box>
  ) : (
    <Box>
      <Lottie speed={2.0} options={defaultOptions} height={200} width={200} />
    </Box>
  );
};

export default PepDeclaration;
