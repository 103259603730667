import { React, useEffect, useState } from "react";

import partner from "../../api/partner";
import central from "../../api/central";
import authHeader from "../../api/authHeader";

import HandleError from "../../components/functions/HandleError";
import HandleToast from "../../components/functions/HandleToast";

import Lottie from "react-lottie";
import loadingLottie from "../../components/lotties/loading-dots.json";

import moment from "moment";
import DataTable from "react-data-table-component";
import CurrencyTextField from "@puhl/mui-currency-textfield";
import getSymbolFromCurrency from "currency-symbol-map";

import {
  Box,
  Typography,
  Chip,
  Grid,
  Icon,
  IconButton,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Divider,
  Autocomplete,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  Collapse,
  InputAdornment,
  ToggleButtonGroup,
  ToggleButton,
  Alert,
} from "@mui/material";

import { Close, Delete, Description, Edit, Facebook } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import CurrencySelect from "../../components/form-elements/CurrencySelect";
import CountrySelect from "../../components/form-elements/CountrySelect";

const Financials = ({ partnerData, partnerUser, caseData, entityData }) => {
  const useAuthHeader = authHeader();
  const handleError = HandleError();
  const displayToast = HandleToast();

  const [locale, setLocale] = useState("en-gb");

  if (moment.locale() != locale) {
    moment.locale(locale);
  }

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: loadingLottie,
  };

  const [isLoaded, setIsLoaded] = useState(false);

  const [processing, setProcessing] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [editType, setEditType] = useState(0);

  const [financials, setFinancials] = useState();

  const [counterparties, setCounterparties] = useState([]);
  const [selectedCounterpartyType, setSelectedCounterpartyType] = useState();
  const [counterpartyName, setCounterpartyName] = useState();
  const [counterpartyAddCountry, setCounterpartyAddCountry] = useState(false);
  const [counterpartyCountry, setCounterpartyCountry] = useState();
  const [counterpartyAddAmount, setCounterpartyAddAmount] = useState(false);
  const [counterpartyAmountType, setCounterpartyAmountType] = useState();
  const [counterpartyCurrency, setCounterpartyCurrency] = useState();
  const [counterpartyAmount, setCounterpartyAmount] = useState(0);
  const [counterpartyPercentage, setCounterpartyPercentage] = useState();
  const [counterpartyCurrencySymbol, setCounterpartyCurrencySymbol] = useState("£");

  const [deleteCounterpartyDialog, setDeleteCounterpartyDialog] = useState(false);
  const [deleteCounterparty, setDeleteCounterparty] = useState("");

  const [primaryCurrency, setPrimaryCurrency] = useState();

  const [currencySymbol, setCurrencySymbol] = useState("£");

  const [initialInvestment, setInitialInvestment] = useState();
  const [previousTurnover, setPreviousTurnover] = useState();
  const [projectedTurnover, setProjectedTurnover] = useState();

  const customStyles = {
    headCells: {
      style: {
        paddingLeft: "8px", // override the cell padding for head cells
        paddingRight: "8px",
        fontWeight: "bold",
      },
    },
    cells: {
      style: {
        paddingLeft: "8px", // override the cell padding for data cells
        paddingRight: "8px",
      },
    },
  };

  const counterpartyColumns = [
    {
      name: "Name",
      sortable: true,
      width: "50%",
      selector: (row) => row.name,
    },
    {
      name: "Information",
      sortable: true,
      right: "true",
      selector: (row) => row.code,
      cell: (row) => (
        <Box sx={{ display: "flex", flexDirection: "row", gap: 2 }}>
          {row.high_risk && (
            <Chip
              className="square-chip"
              label="High risk"
              sx={{ color: "#ffffff", backgroundColor: "#C83E4D", textTransform: "uppercase" }}
            />
          )}
          <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
            <Typography sx={{ fontSize: "13px" }}>{row.code}</Typography>
          </Box>
        </Box>
      ),
    },
  ];

  const manageCounterpartyColumns = [
    {
      name: "Name",
      sortable: true,
      width: "30%",
      selector: (row) => row.name,
    },
    {
      name: "Information",
      sortable: true,
      right: "true",
      selector: (row) => row.code,
      cell: (row) => (
        <Box>
          <Typography sx={{ fontSize: "13px" }}>{row.code}</Typography>
        </Box>
      ),
    },
    {
      name: "Actions",
      sortable: false,
      right: "true",
      cell: (row) => (
        <Box sx={{ display: "flex", flexDirection: "row", gap: 2 }}>
          <IconButton
            title="Edit"
            onClick={() => {
              getCounterpartyData(row.uuid);
            }}
          >
            <Edit />
          </IconButton>
          <Box
            onClick={() => {
              setDeleteCounterparty(row.uuid);
              setDeleteCounterpartyDialog(true);
            }}
            sx={{ color: "#C83E4D", cursor: "pointer" }}
          >
            <Delete />
          </Box>
        </Box>
      ),
    },
  ];

  const counterpartyOptions = [
    { value: 1, label: "Customer" },
    { value: 2, label: "Supplier" },
    { value: 3, label: "Partner" },
    { value: 4, label: "Payments" },
    { value: 5, label: "Other" },
  ];

  const counterpartyAmountTypes = [
    { value: 1, label: "Money" },
    { value: 2, label: "Percentage" },
  ];

  const getFinancialData = async () => {
    try {
      const response = await partner.post(
        `/v1/entity/corporations/corporation/data/financial`,
        JSON.stringify({
          corporation_uuid: entityData.value.corporation_uuid,
        }),
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Basic " + useAuthHeader.base64encodedData,
            service: "partners",
          },

          //withCredentials: true,
        }
      );

      setFinancials(response.data);
      setPrimaryCurrency(
        response.data.currency
          ? { value: response.data.currency, name: "" }
          : { value: "GBP", name: "" }
      );
      setCounterparties(response.data.counterparties ? response.data.counterparties : []);
      setPreviousTurnover(response.data.previous_annual_turnover);
      setProjectedTurnover(response.data.est_annual_turnover);
      setInitialInvestment(response.data.startup_financing);
      setIsLoaded(true);
    } catch (err) {
      console.log(err);
      handleError(err);
    }
  };

  const handleUpdateCurrency = async (e) => {
    e.preventDefault();
    setProcessing(true);
    try {
      const response = await partner.post(
        `/v1/entity/corporations/corporation/currency`,
        JSON.stringify({
          corporation_uuid: entityData.value.corporation_uuid,
          currency: primaryCurrency.value,
        }),
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Basic " + useAuthHeader.base64encodedData,
            service: "partners",
          },

          //withCredentials: true,
        }
      );

      getFinancialData();

      displayToast("Primary currency updated successfully", () => {
        setProcessing(false);
        setEditMode(false);
        setEditType(0);
      });
    } catch (err) {
      console.log(err);
      handleError(err);
      setProcessing(false);
    }
  };

  const handleUpdateRevenues = async (e) => {
    e.preventDefault();
    setProcessing(true);
    try {
      const response = await partner.post(
        `/v1/entity/corporations/corporation/financial`,
        JSON.stringify({
          corporation_uuid: entityData.value.corporation_uuid,
          previous_turnover: parseFloat(previousTurnover),
          startup_funding: parseFloat(initialInvestment),
          est_turnover: parseFloat(projectedTurnover),
        }),
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Basic " + useAuthHeader.base64encodedData,
            service: "partners",
          },

          //withCredentials: true,
        }
      );

      getFinancialData();

      displayToast("Financial information updated successfully", () => {
        setProcessing(false);
        setEditMode(false);
        setEditType(0);
      });
    } catch (err) {
      console.log(err);
      handleError(err);
      setProcessing(false);
    }
  };

  const numberFormat = (value) =>
    new Intl.NumberFormat("en-gb", {
      style: "currency",
      currency: primaryCurrency.value,
    }).format(value);

  useEffect(() => {
    getFinancialData();
  }, []);

  useEffect(() => {
    if (primaryCurrency) {
      setCurrencySymbol(getSymbolFromCurrency(primaryCurrency.value));
    }
  }, [primaryCurrency]);

  useEffect(() => {
    if (counterpartyCurrency) {
      setCounterpartyCurrencySymbol(getSymbolFromCurrency(counterpartyCurrency.value));
    }
  }, [counterpartyCurrency]);

  return isLoaded ? (
    <Grid container spacing={2}>
      <Grid item xs={12} xl={12}>
        <Box sx={{}}>
          {!editMode ? (
            <Box
              sx={{
                borderRight: { xs: 0, xl: 0 },
                borderColor: { xs: "divider", xl: "divider" },
                borderBottom: 0,
              }}
            >
              <Box
                sx={{
                  borderBottom: 1,
                  borderColor: "divider",
                  px: 2,
                  py: 1,
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
                  <Typography variant="h6">Financial information</Typography>
                </Box>
              </Box>

              <Box
                sx={{
                  px: 2,
                  py: 1,
                  borderBottom: 1,
                  borderColor: "divider",
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
                  <Typography variant="subtitle2" sx={{ fontWeight: 600 }}>
                    Primary currency
                  </Typography>
                </Box>
                <Box>
                  <IconButton
                    title="Edit"
                    onClick={() => {
                      setEditType(1);
                      setEditMode(true);
                    }}
                  >
                    <Edit />
                  </IconButton>
                </Box>
              </Box>

              <Box
                sx={{
                  px: 2,
                  py: 1,
                  borderBottom: !financials.currency ? 0 : 1,
                  borderColor: "divider",
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                {financials ? (
                  <Typography align="center" variant="h6">
                    {financials.currency ? financials.currency : "-"}
                  </Typography>
                ) : (
                  <Typography variant="subtitle2">-</Typography>
                )}
              </Box>

              <Collapse
                in={financials && financials.currency != ""}
                exit={!financials ? true : financials.currency == ""}
              >
                <Box
                  sx={{
                    px: 2,
                    py: 1,
                    borderBottom: 1,
                    borderColor: "divider",
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
                    <Typography variant="body2" sx={{ fontWeight: 600 }}>
                      Income & Revenues
                    </Typography>
                  </Box>

                  <Box>
                    {caseData.value.business_information && (
                      <IconButton
                        title="Edit"
                        onClick={() => {
                          setEditType(2);
                          setEditMode(true);
                        }}
                      >
                        <Edit />
                      </IconButton>
                    )}
                  </Box>
                </Box>

                {caseData.value.business_information ? (
                  <>
                    {financials && financials.currency != "" && financials.is_startup ? (
                      <Box
                        sx={{
                          px: 2,
                          py: 1,
                          borderBottom: 1,
                          borderColor: "divider",
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                          }}
                        >
                          <Typography variant="body2">Startup funding</Typography>
                        </Box>
                        <Box>
                          <Typography variant="subtitle2" sx={{ letterSpacing: ".05rem" }}>
                            {financials?.startup_financing
                              ? numberFormat(financials.startup_financing)
                              : "-"}
                          </Typography>
                        </Box>
                      </Box>
                    ) : (
                      <Box
                        sx={{
                          px: 2,
                          py: 1,
                          borderBottom: 1,
                          borderColor: "divider",
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                          }}
                        >
                          <Typography variant="body2">Last years revenue</Typography>
                        </Box>
                        <Box>
                          <Typography variant="subtitle2" sx={{ letterSpacing: ".05rem" }}>
                            {financials?.previous_annual_turnover
                              ? numberFormat(financials.previous_annual_turnover)
                              : "-"}
                          </Typography>
                        </Box>
                      </Box>
                    )}

                    <Box
                      sx={{
                        px: 2,
                        py: 1,
                        borderBottom: 0,
                        borderColor: "divider",
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <Box
                        sx={{ display: "flex", flexDirection: "column", justifyContent: "center" }}
                      >
                        <Typography variant="body2">Projected revenue</Typography>
                      </Box>
                      <Box>
                        <Typography variant="subtitle2" sx={{ letterSpacing: ".05rem" }}>
                          {financials?.est_annual_turnover
                            ? numberFormat(financials.est_annual_turnover)
                            : "-"}
                        </Typography>
                      </Box>
                    </Box>
                  </>
                ) : (
                  <Box sx={{ px: 2, py: 1, borderBottom: 1, borderColor: "divider" }}>
                    <Box
                      sx={{ display: "flex", flexDirection: "column", justifyContent: "center" }}
                    >
                      <Alert severity="info">
                        Complete the business details to add income and revenue data
                      </Alert>
                    </Box>
                  </Box>
                )}
              </Collapse>

              {/*
              <Collapse in={financials && financials.currency != ""} exit={!financials ? true : financials.currency == ""}>
                <Box sx={{ px: 2, py: 1, borderBottom: 1, borderColor: "divider", display: "flex", justifyContent: "space-between" }}>
                  <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
                    <Typography variant="subtitle2" sx={{ fontWeight: 600 }}>
                      Counterparties
                    </Typography>
                  </Box>

                  <Box>
                    <IconButton
                      title="Edit"
                      onClick={() => {
                        setEditType(4);
                        setEditMode(true);
                      }}
                    >
                      <Edit />
                    </IconButton>
                  </Box>
                </Box>

                <Box sx={{ borderBottom: 0, borderColor: "divider" }}>
                  <Box>
                    <DataTable
                      columns={counterpartyColumns}
                      data={counterparties}
                      customStyles={customStyles}
                      striped
                      noTableHead={true}
                      noDataComponent={
                        <Box sx={{ width: "100%", px: 2, py: 1 }}>
                          <Typography sx={{ textAlign: "left" }} variant="subtitle2">
                            -
                          </Typography>
                        </Box>
                      }
                    />
                  </Box>
                </Box>
              </Collapse> */}
            </Box>
          ) : (
            <Box>
              <Box
                sx={{
                  borderRight: { xs: 0, xl: 0 },
                  borderColor: { xs: "divider", xl: "divider" },
                }}
              >
                <Box
                  sx={{
                    borderBottom: 1,
                    borderColor: "divider",
                    px: 2,
                    py: 1,
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
                    <Typography variant="h6">Financial information</Typography>
                  </Box>
                  <Box>
                    <IconButton title="Close" onClick={() => setEditMode(false)}>
                      <Close />
                    </IconButton>
                  </Box>
                </Box>

                {editType == 1 && (
                  <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
                    <Box sx={{ display: "flex", flexDirection: "column" }}>
                      <Box sx={{ p: 2 }}>
                        <Typography variant="subtitle2">Primary currency</Typography>
                      </Box>
                      <Divider />
                      <Box sx={{ p: 2 }}>
                        <CurrencySelect value={primaryCurrency} setValue={setPrimaryCurrency} />
                      </Box>
                    </Box>
                    <Divider />

                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "flex-end",
                        px: 2,
                        pb: 2,
                      }}
                    >
                      <LoadingButton
                        disableElevation
                        variant="contained"
                        color="success"
                        loading={processing}
                        onClick={(e) => handleUpdateCurrency(e)}
                      >
                        Save changes
                      </LoadingButton>
                    </Box>
                  </Box>
                )}

                {editType == 2 && (
                  <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
                    <Box sx={{ display: "flex", flexDirection: "column" }}>
                      {financials && financials.currency != "" && financials.is_startup ? (
                        <>
                          <Box sx={{ p: 2 }}>
                            <Typography variant="subtitle2">Initial startup capital</Typography>
                          </Box>
                          <Divider />
                          <Box sx={{ p: 2 }}>
                            <CurrencyTextField
                              fullWidth
                              label="Startup funding"
                              variant="outlined"
                              value={initialInvestment}
                              currencySymbol={currencySymbol}
                              outputFormat="number"
                              minimumValue="0"
                              decimalCharacter="."
                              digitGroupSeparator=","
                              onChange={(event, value) => setInitialInvestment(value)}
                            />
                          </Box>
                        </>
                      ) : (
                        <>
                          <Box sx={{ p: 2 }}>
                            <Typography variant="subtitle2">
                              Revenue from the last financial year
                            </Typography>
                          </Box>
                          <Divider />
                          <Box sx={{ p: 2 }}>
                            <CurrencyTextField
                              fullWidth
                              label="Last financial year income"
                              variant="outlined"
                              value={previousTurnover}
                              currencySymbol={currencySymbol}
                              minimumValue="0"
                              outputFormat="number"
                              decimalCharacter="."
                              digitGroupSeparator=","
                              onChange={(event, value) => setPreviousTurnover(value)}
                            />
                          </Box>
                        </>
                      )}

                      <Divider />
                      <Box sx={{ display: "flex", flexDirection: "column" }}>
                        <Box sx={{ p: 2 }}>
                          <Typography variant="subtitle2">
                            Projected revenues for this year
                          </Typography>
                        </Box>
                        <Divider />
                        <Box sx={{ p: 2 }}>
                          <CurrencyTextField
                            fullWidth
                            label="Projected income"
                            variant="outlined"
                            value={projectedTurnover}
                            currencySymbol={currencySymbol}
                            minimumValue="0"
                            outputFormat="number"
                            decimalCharacter="."
                            digitGroupSeparator=","
                            onChange={(event, value) => setProjectedTurnover(value)}
                          />
                        </Box>
                      </Box>
                    </Box>
                    <Divider />

                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "flex-end",
                        px: 2,
                        pb: 2,
                      }}
                    >
                      <LoadingButton
                        disableElevation
                        variant="contained"
                        color="success"
                        loading={processing}
                        onClick={(e) => handleUpdateRevenues(e)}
                      >
                        Save changes
                      </LoadingButton>
                    </Box>
                  </Box>
                )}

                {editType == 4 && (
                  <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
                    <Box sx={{ display: "flex", flexDirection: "column", gap: 2, p: 2 }}>
                      <Typography variant="subtitle2">Add a new counterparty</Typography>

                      <FormControl fullWidth>
                        <InputLabel id="counterparty-type-label">Counterparty type</InputLabel>
                        <Select
                          labelId="counterparty-type-label"
                          id="counterparty-type"
                          value={selectedCounterpartyType}
                          label="Counterparty type"
                          onChange={(e) => setSelectedCounterpartyType(e.target.value)}
                        >
                          {counterpartyOptions.map((option, index) => (
                            <MenuItem key={index} value={option.value}>
                              {option.label}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>

                      <TextField
                        label="Counterparty name"
                        fullWidth
                        value={counterpartyName}
                        onChange={(e) => setCounterpartyName(e.target.value)}
                      />

                      <Box
                        sx={{
                          p: 2,
                          display: "flex",
                          justifyContent: "space-between",
                          border: 1,
                          borderColor: "divider",
                        }}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                          }}
                        >
                          <Typography variant="subtitle2">Add country information</Typography>
                        </Box>

                        <Box>
                          <ToggleButtonGroup
                            color="primary"
                            value={counterpartyAddCountry}
                            exclusive
                            onChange={(e, newValue) => {
                              if (newValue !== null) {
                                setCounterpartyAddCountry(newValue);
                              }
                            }}
                            aria-label="Add a country"
                          >
                            <ToggleButton value={true}>Yes</ToggleButton>
                            <ToggleButton value={false}>No</ToggleButton>
                          </ToggleButtonGroup>
                        </Box>
                      </Box>

                      <Collapse in={counterpartyAddCountry} exit={!counterpartyAddCountry}>
                        <Box>
                          <CountrySelect
                            value={counterpartyCountry}
                            setValue={setCounterpartyCountry}
                          />
                        </Box>
                      </Collapse>

                      <Box
                        sx={{
                          p: 2,
                          display: "flex",
                          justifyContent: "space-between",
                          border: 1,
                          borderColor: "divider",
                        }}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                          }}
                        >
                          <Typography variant="subtitle2">Add a value/amount</Typography>
                        </Box>

                        <Box>
                          <ToggleButtonGroup
                            color="primary"
                            value={counterpartyAddAmount}
                            exclusive
                            onChange={(e, newValue) => {
                              if (newValue !== null) {
                                setCounterpartyAddAmount(newValue);
                              }
                            }}
                            aria-label="Add a value"
                          >
                            <ToggleButton value={true}>Yes</ToggleButton>
                            <ToggleButton value={false}>No</ToggleButton>
                          </ToggleButtonGroup>
                        </Box>
                      </Box>

                      <Collapse in={counterpartyAddAmount} exit={!counterpartyAddAmount}>
                        <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
                          <FormControl fullWidth>
                            <InputLabel id="counterparty-type-label">Amount type</InputLabel>
                            <Select
                              labelId="counterparty-amount-type-label"
                              id="counterparty-amount-type"
                              value={counterpartyAmountType}
                              label="Counterparty type"
                              onChange={(e) => setCounterpartyAmountType(e.target.value)}
                            >
                              {counterpartyAmountTypes.map((option, index) => (
                                <MenuItem key={index} value={option.value}>
                                  {option.label}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>

                          {counterpartyAmountType == 1 && (
                            <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
                              <CurrencySelect
                                value={counterpartyCurrency}
                                setValue={setCounterpartyCurrency}
                              />

                              {counterpartyCurrency && counterpartyCurrency.value && (
                                <CurrencyTextField
                                  fullWidth
                                  label="Amount"
                                  variant="outlined"
                                  value={counterpartyAmount}
                                  currencySymbol={counterpartyCurrencySymbol}
                                  outputFormat="number"
                                  minimumValue="0"
                                  decimalCharacter="."
                                  digitGroupSeparator=","
                                  onChange={(event, value) => setCounterpartyAmount(value)}
                                />
                              )}
                            </Box>
                          )}

                          {counterpartyAmountType == 2 && (
                            <Box>
                              <TextField
                                label="Percentage"
                                fullWidth
                                value={counterpartyPercentage}
                                onChange={(e) => setCounterpartyPercentage(e.target.value)}
                                inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
                                InputProps={{
                                  endAdornment: <InputAdornment position="end">%</InputAdornment>,
                                }}
                              />
                            </Box>
                          )}
                        </Box>
                      </Collapse>

                      <Box
                        sx={{ display: "flex", flexDirection: "row", justifyContent: "flex-end" }}
                      >
                        <LoadingButton
                          disableElevation
                          variant="contained"
                          color="success"
                          loading={processing}
                          onClick={(e) => handleAddLink(e)}
                        >
                          Add counterparty
                        </LoadingButton>
                      </Box>
                    </Box>

                    <Dialog
                      open={deleteCounterpartyDialog}
                      onClose={() => setDeleteCounterpartyDialog(false)}
                      aria-labelledby="alert-dialog-title"
                      aria-describedby="alert-dialog-description"
                    >
                      <DialogTitle id="alert-dialog-title">Are you sure?</DialogTitle>
                      <DialogContent>
                        <Box>
                          <DialogContentText sx={{ mb: 1 }} id="alert-dialog-description">
                            Are you sure you want to remove this counterparty?
                          </DialogContentText>
                        </Box>
                      </DialogContent>
                      <DialogActions>
                        <LoadingButton
                          disableElevation
                          loading={processing}
                          onClick={() => setDeleteCounterpartyDialog(false)}
                          color="primary"
                        >
                          Cancel
                        </LoadingButton>

                        <LoadingButton
                          disableElevation
                          sx={{ color: "#ffffff" }}
                          loading={processing}
                          onClick={(e) => {
                            handleRemoveCounterparty(e);
                          }}
                          variant="contained"
                          color="danger"
                          autoFocus
                        >
                          Confirm
                        </LoadingButton>
                      </DialogActions>
                    </Dialog>

                    {counterparties && counterparties.length > 0 && (
                      <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
                        <Divider />
                        <DataTable
                          columns={manageCounterpartyColumns}
                          data={counterparties}
                          customStyles={customStyles}
                          pagination
                          striped
                        />
                      </Box>
                    )}
                  </Box>
                )}
              </Box>
            </Box>
          )}
        </Box>
      </Grid>
    </Grid>
  ) : (
    <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", height: "50vh" }}>
      <Lottie speed={2.0} options={defaultOptions} height={200} width={200} />
    </Box>
  );
};

export default Financials;
