import { React, useEffect, useState } from "react";

import partner from "../../api/partner";
import authHeader from "../../api/authHeader";

import ErrorHandling from "../../components/functions/HandleError";
import HandleToast from "../../components/functions/HandleToast";

import Lottie from "react-lottie";
import loadingLottie from "../../components/lotties/loading-dots.json";

import PropTypes from "prop-types";
import { Box, Divider, Typography, Tabs, Tab, ToggleButtonGroup, ToggleButton, Grid } from "@mui/material";
import { LoadingButton } from "@mui/lab";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div role="tabpanel" hidden={value !== index} id={`vertical-tabpanel-${index}`} aria-labelledby={`vertical-tab-${index}`} {...other}>
      {value === index && <Box sx={{}}>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
}

const Permissions = ({ partnerUser, partnerData, userData }) => {
  const useAuthHeader = authHeader();
  const handleError = ErrorHandling();
  const displayToast = HandleToast();

  const [isLoaded, setIsLoaded] = useState(false);
  const [processing, setProcessing] = useState(false);

  const [navValue, setNavValue] = useState(0);

  const handleNav = (event, newValue) => {
    setNavValue(newValue);
  };

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: loadingLottie,
  };

  const [permissions, setPermissions] = useState([]);

  const [userManagement, setUserManagement] = useState(false);
  const [mobileAccess, setMobileAccess] = useState(false);

  const [complianceSettings, setComplianceSettings] = useState(false);
  const [assessmentApprovals, setAssessmentApprovals] = useState(false);
  const [complianceReports, setComplianceReports] = useState(false);

  const getPermissions = async (userUUID) => {
    try {
      const response = await partner.post(
        `/v1/users/admin/data/user/permissions`,
        JSON.stringify({
          partner_user_uuid: userUUID,
        }),
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Basic " + useAuthHeader.base64encodedData,
            service: "partners",
          },

          //withCredentials: true,
        }
      );

      console.log(response.data);

      if (response.data.results > 0) {
        setPermissions(response.data.entries);

        response.data.entries.map((permission) => {
          switch (permission.permission_type) {
            case 3:
              setUserManagement(true);
              break;
            case 35:
              setMobileAccess(true);
              break;
            default:
              break;
          }
        });
      } else {
        setPermissions([]);
      }

      setIsLoaded(true);
      // getNotes();
    } catch (err) {
      console.log(err);
      handleError(err);
    }
  };

  const handleSaveAdministration = async (e) => {
    e.preventDefault();
    setProcessing(true);
    try {
      const response = await partner.post(
        `/v1/users/admin/permissions/admin`,
        JSON.stringify({
          partner_user_uuid: userData.value.uuid,
          mobile_access: mobileAccess,
          user_management: userManagement,
        }),
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Basic " + useAuthHeader.base64encodedData,
            service: "partners",
          },

          //withCredentials: true,
        }
      );

      getPermissions(userData.value.uuid);

      displayToast("Permissions updated successfully", () => {
        setProcessing(false);
      });
    } catch (err) {
      console.log(err);
      handleError(err);
    }
  };

  const handleSaveCompliance = async (e) => {
    e.preventDefault();
    setProcessing(true);
    try {
      const response = await partner.post(
        `/v1/users/admin/permissions/admin`,
        JSON.stringify({
          partner_user_uuid: userData.value.uuid,
          client_approvals: assessmentApprovals,
          report_generation: complianceReports,
        }),
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Basic " + useAuthHeader.base64encodedData,
            service: "partners",
          },

          //withCredentials: true,
        }
      );

      getPermissions(userData.value.uuid);

      displayToast("Permissions updated successfully", () => {
        setProcessing(false);
      });
    } catch (err) {
      console.log(err);
      handleError(err);
    }
  };

  const handleSaveOperations = async (e) => {
    e.preventDefault();
    setProcessing(true);
    try {
      const response = await partner.post(
        `/v1/users/admin/permissions/operations`,
        JSON.stringify({
          partner_user_uuid: userData.value.uuid,
          mobile_access: mobileAccess,
        }),
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Basic " + useAuthHeader.base64encodedData,
            service: "partners",
          },

          //withCredentials: true,
        }
      );

      getPermissions(userData.value.uuid);

      displayToast("Permissions updated successfully", () => {
        setProcessing(false);
      });
    } catch (err) {
      console.log(err);
      handleError(err);
    }
  };

  useEffect(() => {
    getPermissions(userData.value.uuid);
  }, []);

  return isLoaded ? (
    <>
      <Box sx={{ border: 0, borderColor: "divider" }}>
        <Box sx={{ width: "100%" }}>
          <Tabs onChange={handleNav} value={navValue} aria-label="Tabs where selection follows focus" selectionFollowsFocus>
            <Tab label="Account admin" {...a11yProps(0)} />
            <Tab label="Compliance" {...a11yProps(1)} />
            {/*<Tab label="Finance" {...a11yProps(2)} />
            <Tab label="Operations" {...a11yProps(3)} /> */}
          </Tabs>
        </Box>
        <Divider />

        <Grid container spacing={2}>
          <Grid item xs={12} xl={6}>
            <Box sx={{ borderRight: { xs: 0, xl: 1 }, borderColor: { xs: "divider", xl: "divider" } }}>
              <TabPanel value={navValue} index={0}>
                <Box>
                  <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "space-between", borderBottom: 1, borderColor: "divider", p: 2 }}>
                    <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
                      <Typography variant="subtitle2">User management</Typography>
                    </Box>
                    <Box>
                      <ToggleButtonGroup
                        size="small"
                        color={userManagement ? "primary" : "danger"}
                        value={userManagement}
                        exclusive
                        onChange={(e, newValue) => {
                          if (newValue !== null) setUserManagement(newValue);
                        }}
                        aria-label="User management"
                      >
                        <ToggleButton value={true}>Enabled</ToggleButton>
                        <ToggleButton value={false}>Disabled</ToggleButton>
                      </ToggleButtonGroup>
                    </Box>
                  </Box>

                  <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "space-between", borderBottom: 1, borderColor: "divider", p: 2 }}>
                    <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
                      <Typography variant="subtitle2">Mobile access</Typography>
                    </Box>
                    <Box>
                      <ToggleButtonGroup
                        size="small"
                        color={mobileAccess ? "primary" : "danger"}
                        value={mobileAccess}
                        exclusive
                        onChange={(e, newValue) => {
                          if (newValue !== null) setMobileAccess(newValue);
                        }}
                        aria-label="User management"
                      >
                        <ToggleButton value={true}>Enabled</ToggleButton>
                        <ToggleButton value={false}>Disabled</ToggleButton>
                      </ToggleButtonGroup>
                    </Box>
                  </Box>

                  <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "flex-end", p: 2 }}>
                    <LoadingButton
                      disableElevation
                      variant="contained"
                      loading={processing}
                      onClick={(e) => {
                        handleSaveAdministration(e);
                      }}
                      color="success"
                    >
                      Save changes
                    </LoadingButton>
                  </Box>
                </Box>
              </TabPanel>

              <TabPanel value={navValue} index={1}>
                <Box>
                  <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "space-between", borderBottom: 1, borderColor: "divider", p: 2 }}>
                    <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
                      <Typography variant="subtitle2">Compliance settings</Typography>
                    </Box>
                    <Box>
                      <ToggleButtonGroup
                        size="small"
                        color={complianceSettings ? "primary" : "danger"}
                        value={complianceSettings}
                        exclusive
                        onChange={(e, newValue) => {
                          if (newValue !== null) setComplianceSettings(newValue);
                        }}
                        aria-label="User management"
                      >
                        <ToggleButton value={true}>Enabled</ToggleButton>
                        <ToggleButton value={false}>Disabled</ToggleButton>
                      </ToggleButtonGroup>
                    </Box>
                  </Box>

                  <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "space-between", borderBottom: 1, borderColor: "divider", p: 2 }}>
                    <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
                      <Typography variant="subtitle2">Assessment approvals</Typography>
                    </Box>
                    <Box>
                      <ToggleButtonGroup
                        size="small"
                        color={assessmentApprovals ? "primary" : "danger"}
                        value={assessmentApprovals}
                        exclusive
                        onChange={(e, newValue) => {
                          if (newValue !== null) setAssessmentApprovals(newValue);
                        }}
                        aria-label="User management"
                      >
                        <ToggleButton value={true}>Enabled</ToggleButton>
                        <ToggleButton value={false}>Disabled</ToggleButton>
                      </ToggleButtonGroup>
                    </Box>
                  </Box>

                  <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "space-between", borderBottom: 1, borderColor: "divider", p: 2 }}>
                    <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
                      <Typography variant="subtitle2">Report generation</Typography>
                    </Box>
                    <Box>
                      <ToggleButtonGroup
                        size="small"
                        color={complianceReports ? "primary" : "danger"}
                        value={complianceReports}
                        exclusive
                        onChange={(e, newValue) => {
                          if (newValue !== null) setComplianceReports(newValue);
                        }}
                        aria-label="User management"
                      >
                        <ToggleButton value={true}>Enabled</ToggleButton>
                        <ToggleButton value={false}>Disabled</ToggleButton>
                      </ToggleButtonGroup>
                    </Box>
                  </Box>

                  <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "flex-end", p: 2 }}>
                    <LoadingButton
                      disableElevation
                      variant="contained"
                      loading={processing}
                      onClick={(e) => {
                        handleSaveCompliance(e);
                      }}
                      color="success"
                    >
                      Save changes
                    </LoadingButton>
                  </Box>
                </Box>
              </TabPanel>

              <TabPanel value={navValue} index={2}>
                <Box>Finance</Box>
              </TabPanel>

              <TabPanel value={navValue} index={3}>
                <Box>
                  <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "space-between", borderBottom: 1, borderColor: "divider", p: 2 }}>
                    <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
                      <Typography variant="subtitle2">Mobile app access</Typography>
                    </Box>
                    <Box>
                      <ToggleButtonGroup
                        size="small"
                        color={mobileAccess ? "primary" : "danger"}
                        value={mobileAccess}
                        exclusive
                        onChange={(e, newValue) => {
                          if (newValue !== null) setMobileAccess(newValue);
                        }}
                        aria-label="User management"
                      >
                        <ToggleButton value={true}>Enabled</ToggleButton>
                        <ToggleButton value={false}>Disabled</ToggleButton>
                      </ToggleButtonGroup>
                    </Box>
                  </Box>

                  <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "flex-end", p: 2 }}>
                    <LoadingButton
                      disableElevation
                      variant="contained"
                      loading={processing}
                      onClick={(e) => {
                        handleSaveOperations(e);
                      }}
                      color="success"
                    >
                      Save changes
                    </LoadingButton>
                  </Box>
                </Box>
              </TabPanel>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </>
  ) : (
    <Box>
      <Lottie speed={2.0} options={defaultOptions} height={200} width={200} />
    </Box>
  );
};

export default Permissions;
