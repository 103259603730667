import { React, useEffect, useState } from "react";

import partner from "../../api/partner";
import central from "../../api/central";
import authHeader from "../../api/authHeader";

import HandleError from "../../components/functions/HandleError";
import HandleToast from "../../components/functions/HandleToast";

import Lottie from "react-lottie";
import loadingLottie from "../../components/lotties/loading-dots.json";

import "moment/dist/locale/en-gb";
import moment from "moment";
import Cookies from "universal-cookie";

import {
  Box,
  Typography,
  Chip,
  Grid,
  Icon,
  IconButton,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Button,
  Collapse,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  DialogContentText,
  ToggleButtonGroup,
  ToggleButton,
  Drawer,
} from "@mui/material";
import { Close, Delete, Edit, InsertDriveFile, Upload, PermMedia } from "@mui/icons-material";

import { LoadingButton } from "@mui/lab";
import FileUpload from "./components/corporate-documents/FileUpload";

const CorporateDocuments = ({
  partnerData,
  partnerUser,
  caseData,
  entityData,
  getCorporation,
  getCase,
}) => {
  const useAuthHeader = authHeader();
  const handleError = HandleError();
  const displayToast = HandleToast();

  const [locale, setLocale] = useState("en-gb");

  if (moment.locale() != locale) {
    moment.locale(locale);
  }

  const partnerURL = () => {
    const cookies = new Cookies();
    return "https://" + cookies.get("platformURL");
  };

  const partnerAPI = partnerURL();

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: loadingLottie,
  };

  const [isLoaded, setIsLoaded] = useState(false);
  const [processing, setProcessing] = useState(false);
  const [editMode, setEditMode] = useState(false);

  const [certificateOfIncorporation, setCertificateOfIncorporation] = useState(null);
  const [memOfArts, setMemOfArts] = useState(null);

  const [documentType, setDocumentType] = useState(1);
  const [file, setFile] = useState(null);

  const [documentDisplayURL, setDocumentDisplayURL] = useState(null);
  const [documentDisplayType, setDocumentDisplayType] = useState(null);
  const [documentDisplayMimetype, setDocumentDisplayMimetype] = useState(null);
  const [viewDocument, setViewDocument] = useState(false);

  const getDocumentsData = async () => {
    try {
      const response = await partner.post(
        `/v1/entity/corporations/corporation/data/corporate-documents`,
        JSON.stringify({
          corporation_uuid: entityData.value.corporation_uuid,
        }),
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Basic " + useAuthHeader.base64encodedData,
            service: "partners",
          },

          //withCredentials: true,
        }
      );

      console.log(response.data);

      setCertificateOfIncorporation(response.data.incorporation_certificate);
      setMemOfArts(response.data.mem_of_arts);
      setIsLoaded(true);
    } catch (err) {
      console.log(err);
      handleError(err);
    }
  };

  const processUpload = async (e) => {
    e.preventDefault();
    setProcessing(true);

    const formData = new FormData();
    formData.append("file", file);
    formData.append("fileName", file.name);

    formData.append("case_uuid", caseData.value.case_uuid);
    formData.append("corporation_uuid", entityData.value.corporation_uuid);
    formData.append("document_type", documentType);

    try {
      const response = await partner.post(
        "/v1/entity/corporations/corporation/corporate-document/upload",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: "Basic " + useAuthHeader.base64encodedData,
            service: "partners",
          },
          onUploadProgress: function (progressEvent) {},

          //withCredentials: true,
        }
      );

      getDocumentsData();
      getCase();

      displayToast("Document uploaded successfully", () => {
        setProcessing(false);
        setEditMode(false);
      });
    } catch (err) {
      handleError(err);
      setProcessing(false);
    }
  };

  const downloadDocument = async (documentUUID) => {
    try {
      const response = await partner.post(
        `/v1/entity/corporations/corporation/corporate-document/download`,
        JSON.stringify({
          file_uuid: documentUUID,
        }),
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Basic " + useAuthHeader.base64encodedData,
            service: "partners",
          },

          // withCredentials: true,
        }
      );

      setDocumentDisplayURL(response.data.url);
      setDocumentDisplayType(response.data.filetype);
      setDocumentDisplayMimetype(response.data.mimetype);
      setViewDocument(true);
    } catch (err) {
      console.log(err);
      handleError(err);
      setProcessing(false);
    }
  };

  useEffect(() => {
    getDocumentsData();
  }, []);

  return isLoaded ? (
    <Grid container spacing={2}>
      <Grid item xs={12} xl={12}>
        <Box sx={{}}>
          {!editMode ? (
            <Box
              sx={{
                borderRight: { xs: 0, xl: 0 },
                borderColor: { xs: "divider", xl: "divider" },
                borderBottom: 0,
              }}
            >
              <Box
                sx={{
                  borderBottom: 1,
                  borderColor: "divider",
                  px: 2,
                  py: 1,
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
                  <Typography variant="h6">Corporate Documents</Typography>
                </Box>
                <Box>
                  <Button
                    variant="outlined"
                    color="primary"
                    startIcon={<Upload />}
                    onClick={() => setEditMode(true)}
                  >
                    Upload document
                  </Button>
                </Box>
              </Box>

              <Box
                sx={{
                  px: 2,
                  py: 1,
                  borderBottom: 1,
                  borderColor: "divider",
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
                  <Typography variant="body2">Certificate of incorporation</Typography>
                </Box>

                {certificateOfIncorporation?.document_uuid != "" ? (
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                      cursor: "pointer",
                    }}
                    onClick={() => downloadDocument(certificateOfIncorporation.document_uuid)}
                  >
                    <InsertDriveFile fontSize="large" />
                    <Typography variant="subtitle2">View Document</Typography>
                  </Box>
                ) : caseData.value.options.corporate_documents ? (
                  <Chip label="Upload required" color="primary" className="square-chip" />
                ) : (
                  <Chip label="Upload optional" color="primary" className="square-chip" />
                )}
              </Box>

              <Box
                sx={{
                  px: 2,
                  py: 1,
                  borderBottom: 0,
                  borderColor: "divider",
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
                  <Typography variant="body2">Memorandum and articles of association</Typography>
                </Box>
                <Box sx={{ display: "flex", flexDirection: "row", gap: 2 }}>
                  {memOfArts?.document_uuid != "" ? (
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                        cursor: "pointer",
                      }}
                      onClick={() => downloadDocument(memOfArts.document_uuid)}
                    >
                      <InsertDriveFile fontSize="large" />
                      <Typography variant="subtitle2">View Document</Typography>
                    </Box>
                  ) : caseData.value.options.corporate_documents ? (
                    <Chip label="Upload required" color="primary" className="square-chip" />
                  ) : (
                    <Chip label="Upload optional" color="primary" className="square-chip" />
                  )}
                </Box>
              </Box>
            </Box>
          ) : (
            <Box>
              <Box
                sx={{
                  borderRight: { xs: 0, xl: 0 },
                  borderColor: { xs: "divider", xl: "divider" },
                }}
              >
                <Box
                  sx={{
                    borderBottom: 1,
                    borderColor: "divider",
                    px: 2,
                    py: 1,
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
                    <Typography variant="h6">Corporate Documents</Typography>
                  </Box>
                  <Box>
                    <IconButton title="Close" onClick={() => setEditMode(false)}>
                      <Close />
                    </IconButton>
                  </Box>
                </Box>

                <Box sx={{ p: 2, display: "flex", flexDirection: "column", gap: 2 }}>
                  <Typography variant="body2">
                    Select the type of document you wish to upload
                  </Typography>

                  <ToggleButtonGroup
                    color={"primary"}
                    value={documentType}
                    exclusive
                    onChange={(e, value) => {
                      if (value !== null) setDocumentType(value);
                    }}
                  >
                    <ToggleButton value={1}>Certificate of incorporation</ToggleButton>
                    <ToggleButton value={2}>Memorandum and articles of association</ToggleButton>
                  </ToggleButtonGroup>

                  <FileUpload setFile={setFile} />

                  {file && (
                    <>
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          gap: 2,

                          border: 1,
                          borderColor: "divider",
                          justifyContent: "space-between",
                        }}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "row",
                            width: "100%",
                            justifyContent: "space-between",
                          }}
                        >
                          <Box sx={{ display: "flex" }}>
                            <Box
                              sx={{
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "center",
                              }}
                            >
                              <Box
                                sx={{
                                  display: "flex",
                                  flexDirection: "row",
                                  gap: 2,
                                  fontWeight: 600,
                                }}
                              >
                                <Box
                                  sx={{
                                    display: "flex",
                                    flexDirection: "column",
                                    justifyContent: "center",
                                    pl: 2,
                                  }}
                                >
                                  <PermMedia />
                                </Box>
                                <Box
                                  sx={{
                                    borderRight: 1,
                                    borderLeft: 1,
                                    borderColor: "divider",
                                    p: 1,
                                  }}
                                >
                                  <Typography variant="">Filename</Typography>
                                </Box>
                              </Box>
                            </Box>

                            <Box
                              sx={{
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "center",
                                fontSize: 13,
                                pl: 2,
                              }}
                            >
                              {file.path}
                            </Box>
                          </Box>

                          <Box sx={{ pr: 1 }}>
                            <IconButton
                              title="Remove file"
                              onClick={() => {
                                setFile(null);
                              }}
                            >
                              <Delete />
                            </IconButton>
                          </Box>
                        </Box>
                      </Box>
                      <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                        <LoadingButton
                          variant="contained"
                          color="success"
                          loading={processing}
                          onClick={(e) => {
                            processUpload(e);
                          }}
                        >
                          Upload Document
                        </LoadingButton>
                      </Box>
                    </>
                  )}
                </Box>
              </Box>
            </Box>
          )}

          <Drawer anchor="right" open={viewDocument} onClose={() => setViewDocument(false)}>
            <Box sx={{ width: "90vw" }}>
              <Box
                sx={{
                  borderBottom: 1,
                  borderColor: "divider",
                  px: 2,
                  py: 1,
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
                  <Typography variant="h6">Document Viewer</Typography>
                </Box>

                <Box sx={{}}>
                  <IconButton title="Close" onClick={() => setViewDocument(false)}>
                    <Close />
                  </IconButton>
                </Box>
              </Box>
              <Box sx={{ p: 2 }}>
                <Box sx={{ mt: 2, border: 1, borderColor: "divider" }}>
                  {documentDisplayType == ".pdf" ? (
                    <embed
                      width="100%"
                      height="1200"
                      src={`${partnerAPI}/${documentDisplayURL}`}
                      type={documentDisplayMimetype}
                    />
                  ) : (
                    <Box sx={{ p: 2, display: "flex", justifyContent: "center" }}>
                      <img width={"60%"} sx={{}} src={`${partnerAPI}/${documentDisplayURL}`} />
                    </Box>
                  )}
                </Box>
              </Box>
            </Box>
          </Drawer>
        </Box>
      </Grid>
    </Grid>
  ) : (
    <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", height: "50vh" }}>
      <Lottie speed={2.0} options={defaultOptions} height={200} width={200} />
    </Box>
  );
};

export default CorporateDocuments;
