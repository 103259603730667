import { React, useState, useRef, useCallback, useEffect } from "react";

import partner from "../../../../../api/partner";
import authHeader from "../../../../../api/authHeader";

import HandleError from "../../../../../components/functions/HandleError";

import Lottie from "react-lottie";
import loadingLottie from "../../../../../components/lotties/loading-dots.json";

import * as faceapi from "face-api.js";
import Webcam from "react-webcam";
import { signal } from "@preact/signals-react";

import {
  Box,
  Typography,
  List,
  ListItem,
  IconButton,
  Collapse,
  Button,
  Select,
  FormControl,
  InputLabel,
  MenuItem,
  Fade,
} from "@mui/material";
import { Circle, Close, PlayArrow } from "@mui/icons-material";
import { styled } from "@mui/material/styles";
import { keyframes } from "@mui/system";

const capturing = signal(false);
const screenshotImage = signal(null);

const VideoCapture = ({ setVideoCapture, setFaceImage, setVideoContent }) => {
  const useAuthHeader = authHeader();
  const handleError = HandleError();

  function hasGetUserMedia() {
    return !!(
      navigator.getUserMedia ||
      navigator.webkitGetUserMedia ||
      navigator.mozGetUserMedia ||
      navigator.msGetUserMedia
    );
  }

  if (hasGetUserMedia()) {
    var errorCallback = function (e) {
      setPermissionsError(true);
    };

    // Not showing vendor prefixes.
    navigator.getUserMedia(
      {
        video: true,
        audio: true,
      },
      function (localMediaStream) {
        //alert("good to go");
        navigator.mediaDevices.enumerateDevices().then(handleDevices);
      },
      errorCallback
    );
  } else {
    setPermissionsError(true);
  }

  const [isLoaded, setIsLoaded] = useState(false);

  const videoElement = useRef(null);
  const mediaRecorderRef = useRef(null);
  const snapshotInterval = useRef(null);
  const canvasRef = useRef();

  const [devices, setDevices] = useState([]);
  const [selectedDevice, setSelectedDevice] = useState({ deviceId: "", label: "" });

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: loadingLottie,
  };

  const [videoConstraints, setVideoConstraints] = useState({
    width: 640,
    height: 400,
    facingMode: "user",
    deviceId: selectedDevice.deviceId ? { exact: selectedDevice.deviceId } : undefined,
  });

  function GetDevice(id) {
    navigator.mediaDevices
      .enumerateDevices()
      .then(function (devices) {
        devices.forEach(function (device) {
          if (device.deviceId == id) {
            setSelectedDevice({
              deviceId: device.deviceId === "default" ? "" : device.deviceId,
              label: device.label,
            });
            //console.log(device.kind + ": " + device.label != undefined ? device.label : "Default");
          }
        });
      })
      .catch(function (err) {
        console.log(err.name + ": " + err.message);
      });
  }

  const handleDevices = useCallback(
    (mediaDevices) => setDevices(mediaDevices.filter(({ kind }) => kind === "videoinput")),
    [setDevices]
  );

  useEffect(() => {
    navigator.mediaDevices.enumerateDevices().then(handleDevices);
  }, [handleDevices]);

  const [modelsLoaded, setModelsLoaded] = useState(false);
  const [faceDetected, setFaceDetected] = useState(false);

  const loadModels = async () => {
    const MODEL_URL = "/models";

    Promise.all([faceapi.nets.tinyFaceDetector.loadFromUri(MODEL_URL)])
      .then(setModelsLoaded(true))
      .catch(function (err) {
        console.log(err);
        setStartOption(true);
        setFaceDetected(true);
        setModelsLoaded(false);
      });
  };

  useEffect(() => {
    console.log("Models loaded");
    setIsLoaded(true);
  }, [modelsLoaded]);

  const faceDetectionInterval = useRef(null);

  const faceDetection = () => {
    // detect face visibility

    if (!modelsLoaded) {
      return;
    }

    console.log("Face detection started");

    faceDetectionInterval.current = setInterval(async () => {
      console.log("Face detection running");
      if (canvasRef && canvasRef.current) {
        const input = document.getElementById("video-output");

        canvasRef.current.innerHTML = faceapi.createCanvasFromMedia(input);

        const displaySize = {
          width: videoConstraints.width,
          height: videoConstraints.height,
        };

        faceapi.matchDimensions(canvasRef.current, displaySize);

        const detections = await faceapi.detectSingleFace(
          input,
          new faceapi.TinyFaceDetectorOptions()
        );

        if (detections) {
          setFaceDetected(true);

          if (!capturing.value) {
            setStartOption(true);
          }
        } else {
          setFaceDetected(false);
          setStartOption(false);
        }

        // const resizedDetections = faceapi.resizeResults(detections, displaySize);

        // canvasRef && canvasRef.current && canvasRef.current.getContext("2d").clearRect(0, 0, displaySize.width, displaySize.height);
        //canvasRef && canvasRef.current && faceapi.draw.drawDetections(canvasRef.current, resizedDetections);
      }
    }, 250);
  };

  const [startOption, setStartOption] = useState(false);

  const [baseInstructions, setBaseInstructions] = useState(
    "Camera and microphone permissions required"
  );

  const [permissionsError, setPermissionsError] = useState(false);

  const handleDataAvailable = useCallback(
    ({ data }) => {
      if (data.size > 0) {
        setVideoContent((prev) => prev.concat(data));
      }
    },
    [setVideoContent]
  );

  const startRecording = () => {
    capturing.value = true;
    setBaseInstructions("Ensure your face is clearly visible");
    setStartOption(false);

    mediaRecorderRef.current = new MediaRecorder(videoElement.current.stream, {
      mimeType: "video/webm",
    });
    mediaRecorderRef.current.addEventListener("dataavailable", handleDataAvailable);
    mediaRecorderRef.current.start();

    // start capturing the snapshots
    setTimeout(function () {
      snapshotInterval.current = setInterval(() => {
        captureSnapshot();
      }, 3000);
    }, 2000);
  };

  const stopRecording = useCallback(() => {
    clearInterval(snapshotInterval.current);
    snapshotInterval.current = null;
    mediaRecorderRef.current.stop();
    let stream = videoElement.current.stream;
    const tracks = stream.getTracks();
    tracks.forEach((track) => track.stop());
    setVideoCapture(false);
    capturing.value = false;
  }, [mediaRecorderRef, videoElement]);

  const captureSnapshot = useCallback(() => {
    if (videoElement.current == null) {
      return;
    }
    setFaceImage(videoElement.current.getScreenshot());
    stopRecording();
  }, [videoElement]);

  useEffect(() => {
    loadModels();
  }, []);

  return (
    <>
      {isLoaded ? (
        <Box sx={{}}>
          <Box sx={{ p: 2 }}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
                width: "100%",
              }}
            >
              <Box sx={{ display: "flex", flexDirection: "column", width: "100%" }}>
                <Collapse in={capturing.value === true} exit={capturing.value !== true}>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      gap: 1,
                      justifyContent: "center",
                      backgroundColor: "#0b0b0b",
                      py: 1,
                      zIndex: 500,
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <Typography
                        sx={{
                          fontWeight: 600,
                          textTransform: "uppercase",
                          color: "#ffffff",
                        }}
                        variant="h6"
                      >
                        Processing
                      </Typography>
                    </Box>
                  </Box>
                </Collapse>

                <Box
                  sx={{
                    minWidth: 640,
                    minHeight: 400,
                    border: permissionsError ? 1 : 0,
                    borderColor: "divider",
                  }}
                >
                  <Box sx={{ position: "relative" }}>
                    <Webcam
                      id="video-output"
                      audio={true}
                      muted={true}
                      ref={videoElement}
                      videoConstraints={videoConstraints}
                      onUserMedia={(e) => {
                        console.log("video: onUserMedia");
                        setPermissionsError(false);
                        var tracks = e.getTracks();
                        for (var i = 0; i < tracks.length; i++) {
                          GetDevice(tracks[i].getSettings().deviceId);
                        }
                        faceDetection();
                        setBaseInstructions("Ensure your face is clearly visible");
                        setStartOption(true);
                      }}
                      onUserMediaError={() => {
                        console.log("video: onUserMediaError");
                        setPermissionsError(true);
                      }}
                    />
                    <div className="overlay-container">
                      <canvas className="w-full h-fit" ref={canvasRef} />
                    </div>
                  </Box>

                  {permissionsError && (
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        justifyItems: "center",
                        textAlign: "center",
                        textTransform: "uppercase",
                      }}
                    >
                      <Typography variant="subtitle2">
                        Please allow access to your camera and microphone to proceed
                      </Typography>
                    </Box>
                  )}
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    width: "100%",
                  }}
                >
                  <Box
                    sx={{
                      py: 1,
                      backgroundColor: "#0b0b0b",
                      color: "#ffffff",
                      width: "100%",
                      textAlign: "center",
                    }}
                  >
                    <Typography sx={{ fontWeight: 600, textTransform: "uppercase" }} variant="h6">
                      {baseInstructions}
                    </Typography>
                  </Box>

                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                      pt: 2,
                      gap: 2,
                      width: "100%",
                    }}
                  >
                    <Box sx={{ width: "50%" }}>
                      {!capturing.value && devices.length > 0 && (
                        <FormControl fullWidth>
                          <InputLabel id="recording-device-label">Recording device</InputLabel>
                          <Select
                            labelId="recording-device-label"
                            id="recording-device"
                            label="Select recording device"
                            value={selectedDevice.deviceId}
                            onChange={(e) => {
                              setSelectedDevice({
                                deviceId: e.target.value,
                                label: devices.find((device) => device.deviceId === e.target.value)
                                  .label,
                              });
                              setVideoConstraints({
                                width: 640,
                                height: 400,
                                facingMode: "user",
                                deviceId: e.target.value ? { exact: e.target.value } : undefined,
                              });
                            }}
                          >
                            <MenuItem disabled={true} value="">
                              Select a device
                            </MenuItem>
                            {devices.map((option, index) => (
                              <MenuItem key={index} value={option.deviceId}>
                                {option.label}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      )}
                    </Box>

                    <Fade in={startOption} exit={!startOption} timeout={750}>
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <Button
                          variant="contained"
                          color="success"
                          endIcon={<PlayArrow />}
                          onClick={() => {
                            console.log("Started recording");
                            capturing.value = true;
                            clearInterval(faceDetectionInterval.current);
                            startRecording();
                          }}
                        >
                          Start
                        </Button>
                      </Box>
                    </Fade>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      ) : (
        <Box>
          <Lottie speed={2.0} options={defaultOptions} height={200} width={200} />
        </Box>
      )}
    </>
  );
};

export default VideoCapture;
