import { React, useEffect, useState } from "react";

import partner from "../../../../../api/partner";
import central from "../../../../../api/central";

import authHeader from "../../../../../api/authHeader";

import HandleError from "../../../../../components/functions/HandleError";

import { Canvas, Node, Label, Edge } from "reaflow";

import { Box, TextField, FormControl, InputLabel, Select, MenuItem, Typography, Collapse, Divider, IconButton, Chip } from "@mui/material";
import {
  Edit,
  Close,
  Delete,
  ImportContacts,
  People,
  Business,
  Group,
  Diversity3,
  Check,
  ArrowForward,
  Person,
  AccountTree,
  List,
} from "@mui/icons-material";

const Ownership = ({ clientData, entityData }) => {
  const useAuthHeader = authHeader();
  const handleError = HandleError();

  const [processing, setProcessing] = useState(false);
  const [displayList, setDisplayList] = useState(true);

  const [shareholdersLoaded, setShareholdersLoaded] = useState(false);

  const [nodes, setNodes] = useState([]);
  const [edges, setEdges] = useState([]);
  const [shareholders, setShareholders] = useState([]);

  function calculateApparentControl() {
    let nodeList = [];

    nodeList = recursiveSearch(100, entityData.value.corporation_uuid, nodeList);

    let toSet = createUniquenodeList(nodeList);

    //console.log(`To Set`);
    //console.log(toSet);
    setNodes(toSet);

    return;
  }

  function createUniquenodeList(nodeList) {
    let newList = [];
    nodeList.forEach((node) => {
      const index = newList.findIndex((currentNode) => {
        return currentNode.id === node.id;
      });

      if (index === -1) {
        newList.push(node);
      } else {
        const current = newList[index];
        const roundedIndirect = current.data.indirect_control + node.data.indirect_control;

        const newNode = {
          ...current,
          data: {
            ...current.data,
            direct_control: node.data.direct_control,
            indirect_control: roundedIndirect,
          },
        };

        newList[index] = newNode;
      }
    });
    return newList;
  }

  function recursiveSearch(control, nodeID, nodeList, isDirect = false) {
    // console.log(`Node ID (recursiveSearch): ${nodeID}`);

    let newList = edges.filter((edge) => {
      return edge.from === nodeID;
    });

    //console.log(`New List (recursiveSearch)`);
    //console.log(newList);

    nodeList.push(setNodeControl(nodeID, control, isDirect));

    //console.log(`Node List (recursiveSearch)`);
    // console.log(nodeList);
    //console.log(`Edges (recursiveSearch)`);
    newList.forEach((edge) => {
      let newControl = control * (edge.value / 100);
      // console.log(`New Control (recursiveSearch) ${edge.to} -> ${edge.from} : ${control} -> ${newControl}`);
      nodeList.concat(recursiveSearch(newControl, edge.to, nodeList, edge.from == entityData.value.corporation_uuid ? true : false));
    });

    return nodeList;
  }

  function setNodeControl(nodeId, control, isDirect) {
    const index = nodes.findIndex((node) => {
      //console.log(`Node ID (setNodeControl): ${node.id}`);
      return node.id === nodeId;
    });

    let roundedControl = control.toFixed(2);

    if (Number.isInteger(control)) {
      roundedControl = control;
    }

    let newNode = {};

    isDirect
      ? (newNode = {
          ...nodes[index],
          data: {
            ...nodes[index].data,
            direct_control: parseFloat(roundedControl),
          },
        })
      : (newNode = {
          ...nodes[index],
          data: {
            ...nodes[index].data,
            indirect_control: parseFloat(roundedControl),
          },
        });

    //console.log(`New Node (setNodeControl)`);
    //console.log(isDirect);
    //console.log(newNode);

    return newNode;
  }

  const getOrgChartData = async () => {
    try {
      const response = await partner.post(
        `/v1/entity/corporations/corporation/data/ownership/chart`,
        JSON.stringify({
          corporation_uuid: entityData.value.corporation_uuid,
        }),
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Basic " + useAuthHeader.base64encodedData,
            service: "partners",
          },

          //withCredentials: true,
        }
      );

      console.log(response.data);

      setNodes(response.data.nodes);
      if (response.data.links != null) {
        setEdges(response.data.links);
      } else {
        setEdges([]);
      }
    } catch (err) {
      console.log(err);
      handleError(err);
    }
  };

  const getShareholderList = async () => {
    try {
      const response = await partner.post(
        `/v1/entity/corporations/corporation/data/ownership/list`,
        JSON.stringify({
          corporation_uuid: entityData.value.corporation_uuid,
        }),
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Basic " + useAuthHeader.base64encodedData,
            service: "partners",
          },

          //withCredentials: true,
        }
      );

      console.log(response.data);

      if (response.data.results > 0) {
        setShareholders(response.data.entries);
      } else {
        setShareholders([]);
      }

      setShareholdersLoaded(true);
    } catch (err) {
      console.log(err);
      handleError(err);
    }
  };

  useEffect(() => {
    getShareholderList();
    getOrgChartData();
  }, []);

  return (
    <Box>
      <Box sx={{ display: "flex", justifyContent: "space-between", gap: 2 }}>
        <Box></Box>
        <Box sx={{ p: 2 }}>
          <IconButton
            title="Toggle view"
            onClick={() => {
              setDisplayList(!displayList);
            }}
          >
            {!displayList ? <List fontSize="large" /> : <AccountTree fontSize="large" />}
          </IconButton>
        </Box>
      </Box>

      {displayList ? (
        <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
          {shareholders.map((sh, index) => (
            <Box key={index}>
              <Box sx={{ display: "flex", flexDirection: "row", border: 1, borderColor: "divider" }}>
                <Box sx={{ display: "flex", justifyContent: "space-between", width: "40%" }}>
                  <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center", p: 2 }}>
                    <Typography variant="subtitle2">{sh.name}</Typography>
                  </Box>
                  <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center", p: 2 }}>
                    {sh.entity_type == 1 ? <People /> : <Business />}
                  </Box>
                </Box>
                <Box
                  sx={{
                    borderLeft: 1,
                    borderColor: "divider",
                    p: 2,
                    textTransform: "uppercase",
                    display: "flex",
                    flexDirection: "column",
                    gap: 2,
                    justifyContent: "center",
                    textAlign: "center",
                  }}
                >
                  <Box>
                    <Typography variant="subtitle2">Direct</Typography>
                    <Typography variant="subtitle2">{sh.direct_control} %</Typography>
                  </Box>
                  {sh.indirect_control > 0 && (
                    <Box>
                      <Typography variant="subtitle2">Indirect</Typography>
                      <Typography variant="subtitle2">{sh.indirect_control} %</Typography>
                    </Box>
                  )}
                </Box>
                <Box sx={{ borderLeft: 1, borderColor: "divider", p: 2, display: "flex", flexDirection: "column", gap: 1 }}>
                  <Box sx={{ display: "flex", flexDirection: "row", gap: 1 }}>
                    {sh.identity && <Chip className="square-chip" size="small" color="secondary" label="Identity" />}
                    {sh.address && <Chip className="square-chip" size="small" color="secondary" label="Address" />}
                    {sh.proof_of_life && <Chip className="square-chip" size="small" color="secondary" label="Life" />}
                    {sh.ownership && <Chip className="square-chip" size="small" color="secondary" label="Ownership" />}
                    {sh.control && <Chip className="square-chip" size="small" color="secondary" label="Control" />}
                  </Box>
                  <Box sx={{ display: "flex", flexDirection: "row", gap: 1 }}>
                    {sh.fatca && <Chip className="square-chip" size="small" color="info" label="FATCA" />}
                    {sh.pep_declaration && <Chip className="square-chip" size="small" color="info" label="PEP Declaration" />}
                    {sh.source_of_wealth && <Chip className="square-chip" size="small" color="info" label="Source of wealth" />}
                  </Box>
                  <Box sx={{ display: "flex", flexDirection: "row", gap: 1 }}>
                    {sh.associated_countries?.length > 0 &&
                      sh.associated_countries.map((country, index) => (
                        <Chip
                          key={index}
                          className="square-chip"
                          size="small"
                          title={country.high_risk && "High risk"}
                          sx={{ backgroundColor: country.high_risk ? "#C83E4D" : "#0b0b0b", color: "#ffffff" }}
                          label={country.name}
                        />
                      ))}
                  </Box>
                </Box>
              </Box>
            </Box>
          ))}
        </Box>
      ) : (
        <Box sx={{ p: 2, width: "100%", height: "50vh" }}>
          <Canvas
            nodes={nodes}
            edges={edges}
            direction="UP"
            readonly={true}
            pannable={false}
            fit={true}
            edge={<Edge className="edge" selectable={false} />}
            node={
              <Node style={{ fill: "#474044" }}>
                {(event) => (
                  <foreignObject height={event.height} width={event.width} x={0} y={0}>
                    {event.node.data.entity_type != 0 && (
                      <Box
                        sx={{
                          background: "#ffffff",
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-between",
                          borderBottom: 1,
                          borderColor: "#0b0b0b",
                          height: 45,
                        }}
                      >
                        <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center", p: 1 }}>
                          {event.node.data.entity_type == 1 ? <People /> : event.node.data.entity_type == 2 ? <Business /> : <Diversity3 />}
                        </Box>

                        <Box sx={{ display: "flex", flexDirection: "row" }}>
                          {event.node.data.direct_control > 0 && (
                            <Box
                              sx={{
                                backgroundColor: "#0b0b0b",
                                color: "#ffffff",
                                height: "100%",
                                p: 1,
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "center",
                                fontWeight: 500,
                              }}
                              title="Direct"
                            >
                              {event.node.data.direct_control}%
                            </Box>
                          )}

                          {event.node.data.entity_type != 3 && event.node.data.indirect_control > 0 && (
                            <Box
                              sx={{
                                backgroundColor: "#aaaaaa",
                                color: "#ffffff",
                                height: "100%",
                                p: 1,
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "center",
                                fontWeight: 500,
                                fontSize: "12px",
                              }}
                              title="Indirect"
                            >
                              {event.node.data.indirect_control.toFixed(2)}%
                            </Box>
                          )}
                        </Box>
                      </Box>
                    )}

                    <Box
                      sx={{
                        p: 1,
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                        height: event.node.data.entity_type == 0 ? "100%" : "auto",
                        fontWeight: 500,
                        cursor: "pointer",
                        height: event.node.data.entity_type != 0 ? 55 : 75,
                        backgroundColor:
                          event.node.data.entity_type == 0
                            ? "#474044"
                            : event.node.data.entity_type == 1
                            ? "#4AFE7B"
                            : event.node.data.entity_type == 2
                            ? "#4A8EFE"
                            : "#B74FFF",
                      }}
                    >
                      <Typography
                        sx={{
                          fontWeight: 500,
                          fontSize: event.node.data.entity_type == 0 ? 16 : "14px",
                          color:
                            event.node.data.entity_type == 0
                              ? "#ffffff"
                              : event.node.data.entity_type == 1
                              ? "#0b0b0b"
                              : event.node.data.entity_type == 2
                              ? "#0b0b0b"
                              : "#ffffff",
                        }}
                      >
                        {event.node.data.label}
                      </Typography>
                      {event.node.data.entity_type == 2 ? (
                        event.node.data.control_assigned == 100 ? (
                          <Check sx={{ color: "#0b0b0b" }} />
                        ) : (
                          <Typography sx={{ color: "#0b0b0b", fontWeight: 500, fontSize: "13px" }}>{event.node.data.control_assigned}%</Typography>
                        )
                      ) : (
                        ""
                      )}

                      {event.node.data.entity_type == 0 ? (
                        event.node.data.control_assigned == 100 ? (
                          <Check sx={{ color: "#8BBF9F" }} />
                        ) : (
                          <Typography sx={{ color: "#ffffff", fontWeight: 500, fontSize: "13px" }}>{event.node.data.control_assigned}%</Typography>
                        )
                      ) : (
                        ""
                      )}
                    </Box>
                  </foreignObject>
                )}
              </Node>
            }
          />
        </Box>
      )}
    </Box>
  );
};

export default Ownership;
