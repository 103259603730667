import { React, useState } from "react";

import authHeader from "../../../../../../../api/authHeader";

import HandleError from "../../../../../../../components/functions/HandleError";
import HandleToast from "../../../../../../../components/functions/HandleToast";

import { Box, Divider, Grid, TextField, Typography } from "@mui/material";
import { LoadingButton } from "@mui/lab";

const NewTemplate = ({ partner, partnerUser, partnerData, setNewTemplate, getTemplates, setManageTemplate, setActiveTemplate }) => {
  const useAuthHeader = authHeader();

  const handleError = HandleError();
  const displayToast = HandleToast();

  const [processing, setProcessing] = useState(false);

  const [templateName, setTemplateName] = useState("");

  const handleCreateTemplate = async (e) => {
    e.preventDefault();
    setProcessing(true);
    try {
      const response = await partner.post(
        `/v1/screening/templates/enhanced/create`,
        JSON.stringify({
          name: templateName,
        }),
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Basic " + useAuthHeader.base64encodedData,
            service: "partners",
          },

          //withCredentials: true,
        }
      );

      getTemplates();
      setActiveTemplate(response.data.template_uuid);

      displayToast("Template created successfully", () => {
        setProcessing(false);
        setNewTemplate(false);
        setManageTemplate(true);
      });
    } catch (err) {
      handleError(err);
      setProcessing(false);
    }
  };

  return (
    <Box>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={12} lg={6} xl={4}>
          <Box sx={{ p: 2 }}>
            <Box sx={{ p: 2, border: 1, borderColor: "divider" }}>
              <Typography variant="subtitle2">What is the name of the template?</Typography>
            </Box>

            <Box sx={{ p: 2, display: "flex", flexDirection: "column", gap: 2, border: 1, borderTop: 0, borderColor: "divider" }}>
              <TextField
                fullWidth
                id="outlined-basic"
                label="Template name"
                variant="outlined"
                value={templateName}
                onChange={(e) => setTemplateName(e.target.value)}
              />

              <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                <LoadingButton
                  disableElevation
                  variant="contained"
                  color="success"
                  loading={processing}
                  onClick={(e) => {
                    handleCreateTemplate(e);
                  }}
                >
                  Create template
                </LoadingButton>
              </Box>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default NewTemplate;
