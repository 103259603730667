import { React, useEffect, useState } from "react";

import partner from "../../../../api/partner";
import authHeader from "../../../../api/authHeader";

import HandleError from "../../../../components/functions/HandleError";
import HandleToast from "../../../../components/functions/HandleToast";

import Lottie from "react-lottie";
import loadingLottie from "../../../../components/lotties/loading-dots.json";

import Cookies from "universal-cookie";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import moment from "moment";
import parse from "html-react-parser";
import DataTable from "react-data-table-component";
import getSymbolFromCurrency from "currency-symbol-map";

import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { MuiTelInput } from "mui-tel-input";

import Address from "./Address";
import CountrySelect from "../../../../components/form-elements/CountrySelect";
import CurrencySelect from "../../../../components/form-elements/CurrencySelect";
import TrueFalse from "./TrueFalse";
import YesNo from "./YesNo";
import Money from "./Money";
import SingleFileUpload from "./SingleFileUpload";
import MultipleFileUpload from ".//MultipleFileUpload";
import CorporateEntity from "./CorporateEntity";
import Individual from "./Individual";
import MultipleCorporateEntities from "./MultipleCorporateEntities";
import MultipleIndividuals from "./MultipleIndividuals";

import {
  Box,
  Typography,
  Chip,
  Grid,
  Divider,
  Button,
  IconButton,
  ToggleButtonGroup,
  ToggleButton,
  Collapse,
  TextField,
  Select,
  MenuItem,
  MenuList,
  Drawer,
  Icon,
  Link,
  Modal,
  FormControl,
  InputLabel,
} from "@mui/material";
import {
  Check,
  ChevronRight,
  Close,
  Delete,
  Description,
  Download,
  Edit,
  Facebook,
  ImportContacts,
  InsertDriveFile,
  UploadFile,
  Visibility,
} from "@mui/icons-material";

import { LoadingButton } from "@mui/lab";
import { act } from "react";

function ViewRequest({ partnerUser, partnerData, requestUUID, setRequestUUID }) {
  const useAuthHeader = authHeader();
  const handleError = HandleError();
  const displayToast = HandleToast();

  const partnerURL = () => {
    const cookies = new Cookies();
    return "https://" + cookies.get("platformURL");
  };

  const partnerAPI = partnerURL();

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: loadingLottie,
  };

  const [isLoaded, setIsLoaded] = useState(false);
  const [processing, setProcessing] = useState(false);

  const [requestData, setRequestData] = useState(null);

  const [reviewComment, setReviewComment] = useState("");

  const [fileData, setFileData] = useState(null);
  const [viewFile, setViewFile] = useState(false);

  const getRequestData = async (requestUUID) => {
    try {
      const response = await partner.post(
        `/v1/screening/case/kyc/source-of-wealth/data`,
        JSON.stringify({
          request_uuid: requestUUID,
        }),
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Basic " + useAuthHeader.base64encodedData,
            service: "partners",
          },

          //withCredentials: true,
        }
      );

      setRequestData(response.data);
      console.log(response.data);
      setIsLoaded(true);
    } catch (err) {
      handleError(err);
    }
  };

  useEffect(() => {
    if (requestUUID === null) return;
    if (requestUUID === "") return;
    if (requestUUID === undefined) return;
    getRequestData(requestUUID);
  }, [requestUUID]);

  function printSingleSelectLabel(data) {
    let parsedData = JSON.parse(data);

    return parsedData?.label;
  }

  function printMultipleSelectLabels(data) {
    let parsedData = JSON.parse(data);

    let output = "";

    if (parsedData?.length > 0) {
      parsedData.map((item, index) => {
        if (index > 0) {
          output += ", " + item.label;
        } else {
          output += item.label;
        }
      });
    }

    return output;
  }

  function printMultipleCountries(data) {
    let parsedData = JSON.parse(data).countries;

    let output = "";

    if (parsedData?.length > 0) {
      parsedData.map((item, index) => {
        if (index > 0) {
          output += ", " + item.name;
        } else {
          output += item.name;
        }
      });
    }

    return output;
  }

  function printTelephoneNumber(data) {
    let parsedData = JSON.parse(data);

    return parsedData?.telephone.phone_value;
  }

  function parseAddress(data) {
    let parsedData = JSON.parse(data);

    return parsedData?.address;
  }

  function parseCurrency(data) {
    let parsedData = JSON.parse(data);

    return parsedData?.currency;
  }

  function parseCurrencies(data) {
    let parsedData = JSON.parse(data);

    return parsedData?.currencies;
  }

  function printMoney(data) {
    let parsedData = JSON.parse(data);

    let output = "";

    // get the currency symbol

    output = getSymbolFromCurrency(parsedData.currency.value);
    output += parsedData.amount;
    output += " (" + parsedData.currency.name + ")";

    return output;
  }

  function printPerson(data) {
    let parsedData = JSON.parse(data).person;

    let output = "";

    if (parsedData?.title) {
      output += parsedData.title + " ";
    }

    output += parsedData.first_name;

    if (parsedData?.middle_names) {
      output += " " + parsedData.middle_names;
    }

    output += " " + parsedData.last_name;

    return output;
  }

  function printMultiplePeople(data) {
    let parsedData = JSON.parse(data).people;

    let output = "";

    if (parsedData.length > 0) {
      parsedData.map((person, index) => {
        if (index > 0) {
          output += ", ";
          if (person?.title) {
            output += person.title + " ";
          }

          output += person.first_name;

          if (person?.middle_names) {
            output += " " + person.middle_names;
          }

          output += " " + person.last_name;
        } else {
          if (person?.title) {
            output += person.title + " ";
          }

          output += person.first_name;

          if (person?.middle_names) {
            output += " " + person.middle_names;
          }

          output += " " + person.last_name;
        }
      });
    }

    return output;
  }

  function parseCorporation(data) {
    let parsedData = JSON.parse(data);

    return parsedData?.Corporation;
  }

  function parseUploadedFile(data) {
    let parsedData = JSON.parse(data);

    return parsedData;
  }

  const handleLoadFile = async (fileUUID) => {
    try {
      const response = await partner.post(
        `/v1/screening/case/kyc/source-of-wealth/data/file`,
        JSON.stringify({
          file_uuid: fileUUID,
        }),
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Basic " + useAuthHeader.base64encodedData,
            service: "partners",
          },

          //withCredentials: true,
        }
      );

      console.log(response.data);
      setFileData(response.data);
      setViewFile(true);
    } catch (err) {
      handleError(err);
    }
  };

  const handleReview = async (e) => {
    e.preventDefault();
    setProcessing(true);
    try {
      const response = await partner.post(
        `/v1/screening/case/kyc/source-of-wealth/review`,
        JSON.stringify({
          request_uuid: requestUUID,
          content: reviewComment,
        }),
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Basic " + useAuthHeader.base64encodedData,
            service: "partners",
          },

          //withCredentials: true,
        }
      );

      getRequestData(requestUUID);

      displayToast("Review completed successfully", () => {
        setProcessing(false);
      });
    } catch (err) {
      console.log(err);
      handleError(err);
    }
  };

  const [activeQuestion, setActiveQuestion] = useState(null);
  const [manageResponse, setManageResponse] = useState(false);
  const [answersData, setAnswersData] = useState({});

  const handleChange = (event) => {
    setAnswersData((curr) => ({
      ...curr,
      [event.target.name]: event.target.value,
    }));
  };

  const getQuestionData = async (questionUUID) => {
    setManageResponse(true);
    try {
      const response = await partner.post(
        `/v1/screening/case/kyc/source-of-wealth/data/question`,
        JSON.stringify({
          request_uuid: requestUUID,
          question_uuid: questionUUID,
        }),
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Basic " + useAuthHeader.base64encodedData,
            service: "partners",
          },

          //withCredentials: true,
        }
      );

      setActiveQuestion(response.data);

      if (response.data.has_response) {
        let existingAnswer;

        switch (response.data.field_type) {
          case 3:
            let parsedAnswer3 = JSON.parse(response.data.answer?.response);
            existingAnswer = parsedAnswer3.value;

            if (existingAnswer == -1) {
              setAnswersData((curr) => ({
                ...curr,
                [response.data.uuid + "-other"]: parsedAnswer3.label ? parsedAnswer3.label : "",
              }));
            }

            break;

          case 4:
            let parsedAnswer4 = JSON.parse(response.data.answer?.response);

            if (parsedAnswer4.length > 0) {
              // loop through the array and push the value into a new array
              let answerArray = [];

              parsedAnswer4.map((answer) => {
                answerArray.push(answer.value);

                if (answer.value == -1) {
                  setAnswersData((curr) => ({
                    ...curr,
                    [response.data.uuid + "-other"]: answer.label ? answer.label : "",
                  }));
                }
              });

              existingAnswer = answerArray;
            }

            break;

          case 5:
            existingAnswer = {
              moment: moment(response.data.answer.response),
              date: moment(response.data.answer.response).format("YYYY-MM-DD"),
            };
            break;

          case 7:
            let parsedAnswer7 = JSON.parse(response.data.answer?.response);

            existingAnswer = {
              phone_value: parsedAnswer7.telephone?.phone_value,
              country_code: parsedAnswer7.telephone?.country_code,
              dialling_code: parsedAnswer7.telephone?.dialling_code,
              national_number: parsedAnswer7.telephone?.national_number,
            };

            break;

          case 8:
            let parsedAnswer8 = JSON.parse(response.data.answer?.response);

            existingAnswer = {
              line_one: parsedAnswer8.address.line_one,
              line_two: parsedAnswer8.address.line_two,
              line_three: parsedAnswer8.address.line_three,
              line_four: parsedAnswer8.address.line_four,
              line_five: parsedAnswer8.address.line_five,
              line_six: parsedAnswer8.address.line_six,
              city: parsedAnswer8.address.city,
              state: parsedAnswer8.address.state,
              code: parsedAnswer8.address.code,
              country: parsedAnswer8.address.country,
            };

            break;

          case 9:
            let parsedAnswer9 = JSON.parse(response.data.answer?.response);

            existingAnswer = parsedAnswer9.country;
            break;

          case 10:
            let parsedAnswer10 = JSON.parse(response.data.answer?.response);

            let countryArray = [];

            parsedAnswer10.countries.map((country) => {
              countryArray.push(country);
            });

            existingAnswer = countryArray;

            break;

          case 13:
            let parsedAnswer13 = JSON.parse(response.data.answer?.response);
            existingAnswer = parsedAnswer13.currency;

            break;

          case 14:
            let parsedAnswer14 = JSON.parse(response.data.answer?.response);

            let currencyArray = [];

            parsedAnswer14.currencies.map((currency) => {
              currencyArray.push(currency);
            });

            existingAnswer = currencyArray;

            break;

          case 15:
            let parsedAnswer15 = JSON.parse(response.data.answer?.response);

            existingAnswer = {
              amount: parsedAnswer15.amount,
              currency: parsedAnswer15.currency,
            };

            break;

          case 19:
            existingAnswer = JSON.parse(response.data.answer?.response);

            break;

          case 20:
            let parsedAnswer20 = JSON.parse(response.data.answer?.response);

            if (parsedAnswer20.length > 0) {
              existingAnswer = true;
            } else {
              existingAnswer = false;
            }

            break;

          case 21:
            let parsedAnswer21 = JSON.parse(response.data.answer?.response);

            existingAnswer = {
              registration_name: parsedAnswer21.Corporation.registration_name,
              selected_corporation: parsedAnswer21.Corporation.selected_corporation,
              registration_country: parsedAnswer21.Corporation.registration_country,
              registration_date: parsedAnswer21.Corporation.registration_date,
              registration_reference: parsedAnswer21.Corporation.registration_reference,
              registration_type: parsedAnswer21.Corporation.registration_type,
            };

            break;

          case 22:
            let parsedAnswer22 = JSON.parse(response.data.answer?.response);

            existingAnswer = {
              title: parsedAnswer22.person.title,
              first_name: parsedAnswer22.person.first_name,
              middle_names: parsedAnswer22.person.middle_names,
              last_name: parsedAnswer22.person.last_name,
            };

            break;

          case 23:
            let parsedAnswer23 = JSON.parse(response.data.answer?.response);

            existingAnswer = [];

            parsedAnswer23.corporations.map((corporation) => {
              existingAnswer.push({
                registration_name: corporation.registration_name,
                selected_corporation: corporation.selected_corporation,
                registration_country: corporation.registration_country,
                registration_date: corporation.registration_date,
                registration_reference: corporation.registration_reference,
                registration_type: corporation.registration_type,
              });
            });

            break;

          case 24:
            let parsedAnswer24 = JSON.parse(response.data.answer?.response);

            existingAnswer = [];

            parsedAnswer24.people.map((person) => {
              existingAnswer.push({
                title: person.title,
                first_name: person.first_name,
                middle_names: person.middle_names,
                last_name: person.last_name,
              });
            });

            break;

          default:
            existingAnswer = response.data.answer.response;
            break;
        }

        setAnswersData({
          [response.data.uuid]: existingAnswer,
        });
      } else {
        let existingAnswer;

        switch (response.data.field_type) {
          case 3:
            existingAnswer = null;
            break;
          case 4:
            existingAnswer = [];
            break;
          case 5:
            existingAnswer = null;

            break;

          case 7:
            existingAnswer = null;

            break;

          case 8:
            existingAnswer = null;

            break;

          case 9:
            existingAnswer = null;
            break;

          case 10:
            existingAnswer = [];

            break;

          case 13:
            existingAnswer = null;

            break;

          case 14:
            existingAnswer = [];

            break;

          case 15:
            existingAnswer = null;

            break;

          case 21:
            existingAnswer = null;

            break;

          case 22:
            existingAnswer = null;

            break;

          case 23:
            existingAnswer = [];

            break;

          case 24:
            existingAnswer = [];

            break;

          default:
            existingAnswer = "";
            break;
        }

        setAnswersData({
          [response.data.uuid]: null,
        });
      }

      setManageResponse(true);
    } catch (err) {
      console.log(err);
      handleError(err);
    }
  };

  const handleSaveAnswer = async (e) => {
    e.preventDefault();
    setProcessing(true);

    try {
      const response = await partner.post(
        `/v1/screening/case/kyc/source-of-wealth/answer`,
        JSON.stringify({
          case_uuid: requestData.case_uuid,
          question_uuid: activeQuestion.uuid,
          answer: answersData,
        }),
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Basic " + useAuthHeader.base64encodedData,
            service: "partners",
          },

          // withCredentials: true,
        }
      );

      getRequestData(requestUUID);
      setManageResponse(false);

      displayToast("Answer saved", () => {
        setActiveQuestion(null);
        setAnswersData({});
        setProcessing(false);
      });
    } catch (err) {
      console.log(err);
      handleError(err);
      setProcessing(false);
    }
  };

  useEffect(() => {
    console.log(answersData);
  }, [answersData]);

  useEffect(() => {
    if (!manageResponse) {
      setAnswersData({});
      setActiveQuestion(null);
    }
  }, [manageResponse]);

  return isLoaded ? (
    <Box>
      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            width: "50%",
            borderRight: 1,
            borderColor: "divider",
          }}
        >
          <Box sx={{ display: "flex", flexDirection: "column" }}>
            <Box
              sx={{
                p: 2,
                borderBottom: requestData.review_required ? 1 : 0,
                borderColor: "divider",
              }}
            >
              <Typography variant="subtitle2">Request date</Typography>
              <Typography variant="h6">
                {moment.unix(requestData.request_timestamp).format("DD/MM/YYYY")}
              </Typography>
            </Box>
          </Box>
        </Box>
        <Box sx={{ display: "flex", flexDirection: "column", width: "50%" }}>
          <Box
            sx={{
              p: 2,
              borderBottom: requestData.review_required ? 1 : 0,
              borderColor: "divider",
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <Box>
              <Typography variant="subtitle2" sx={{ textAlign: "end" }}>
                Status
              </Typography>
              <Box
                sx={{ display: "flex", flexDirection: "row", gap: 2, justifyContent: "flex-end" }}
              >
                {requestData.cancelled ? (
                  <Chip
                    className="square-chip"
                    size="medium"
                    label="Cancelled"
                    color="error"
                    sx={{ textTransform: "uppercase" }}
                  />
                ) : requestData.review_complete ? (
                  <Chip
                    className="square-chip"
                    size="medium"
                    label="Review complete"
                    color="success"
                    sx={{ textTransform: "uppercase" }}
                  />
                ) : requestData.review_required ? (
                  <Chip
                    className="square-chip"
                    size="medium"
                    label="Review required"
                    color="warning"
                    sx={{ textTransform: "uppercase" }}
                  />
                ) : (
                  <Chip
                    className="square-chip"
                    size="medium"
                    label="Pending"
                    color="info"
                    sx={{ textTransform: "uppercase" }}
                  />
                )}
              </Box>
            </Box>
          </Box>

          {requestData.review_complete ? (
            <Box sx={{}}>
              <Box sx={{ p: 2, borderBottom: 0, borderColor: "divider", textAlign: "end" }}>
                <Typography variant="subtitle2">Review date</Typography>
                <Typography variant="h6">
                  {moment.unix(requestData.review_timestamp).format("DD/MM/YYYY")}
                </Typography>
                <Typography variant="subtitle2" sx={{ mt: 1 }}>
                  Review completed by
                </Typography>
                <Typography variant="h6">{requestData.review_actor}</Typography>

                {requestData.review_comments && (
                  <Box sx={{ mt: 1 }}>
                    <Typography variant="subtitle2">Review comments</Typography>
                    <Box>{parse(requestData.review_comments)}</Box>
                  </Box>
                )}
              </Box>
            </Box>
          ) : (
            requestData.review_required && (
              <Box sx={{ borderTop: 1, borderColor: "divider" }}>
                <Box sx={{ p: 2, borderBottom: 1, borderColor: "divider" }}>
                  <Typography variant="subtitle2">Review comments</Typography>
                </Box>
                <ReactQuill value={reviewComment} onChange={setReviewComment} theme="snow" />

                <Box
                  sx={{
                    p: 2,
                    display: "flex",
                    justifyContent: "flex-end",
                    borderTop: 1,
                    borderColor: "divider",
                  }}
                >
                  <LoadingButton
                    variant="contained"
                    color="success"
                    loading={processing}
                    onClick={(e) => {
                      handleReview(e);
                    }}
                  >
                    Submit review
                  </LoadingButton>
                </Box>
              </Box>
            )
          )}
        </Box>
      </Box>

      <Box sx={{ backgroundColor: "#0b0b0b", color: "#ffffff", p: 2 }}>
        <Typography variant="h6" sx={{ px: 2, py: 1 }}>
          Requested information
        </Typography>
      </Box>

      <Box>
        {requestData.questions.map((data, index) => (
          <Box
            key={index}
            sx={{
              borderBottom: index == requestData.questions.length - 1 ? 0 : 1,
              borderColor: "divider",
            }}
          >
            <Box
              sx={{
                px: 2,
                py: 1,
                backgroundColor: "divider",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
                <Typography>{data.label}</Typography>
              </Box>
              <Box>
                <Box sx={{ display: "flex", gap: 2 }}>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      p: 1,
                    }}
                    onClick={() => {
                      getQuestionData(data.uuid);
                    }}
                  >
                    <IconButton>
                      <Edit />
                    </IconButton>
                  </Box>
                </Box>
              </Box>
            </Box>

            {data.has_response ? (
              <Box sx={{ p: 2, borderTop: 1, borderColor: "divider" }}>
                {data.field_type == 1 && <Box>{parse(data.answer.response)}</Box>}
                {data.field_type == 2 && (
                  <Box>
                    <Typography>{data.answer.response}</Typography>
                  </Box>
                )}
                {data.field_type == 3 && <Box>{printSingleSelectLabel(data.answer.response)}</Box>}
                {data.field_type == 4 && (
                  <Box>{printMultipleSelectLabels(data.answer.response)}</Box>
                )}
                {data.field_type == 5 && (
                  <Box>{moment(data.answer.response).format("DD/MM/YYYY")}</Box>
                )}
                {data.field_type == 6 && (
                  <Box>
                    <Typography>{data.answer.response}</Typography>
                  </Box>
                )}
                {data.field_type == 7 && (
                  <Box>
                    <Typography>{printTelephoneNumber(data.answer.response)}</Typography>
                  </Box>
                )}
                {data.field_type == 8 && (
                  <Box>
                    <Typography>{parseAddress(data.answer.response).line_one}</Typography>
                    {parseAddress(data.answer.response).line_two && (
                      <Typography>{parseAddress(data.answer.response).line_two}</Typography>
                    )}
                    {parseAddress(data.answer.response).line_three && (
                      <Typography>{parseAddress(data.answer.response).line_three}</Typography>
                    )}
                    {parseAddress(data.answer.response).line_four && (
                      <Typography>{parseAddress(data.answer.response).line_four}</Typography>
                    )}
                    {parseAddress(data.answer.response).line_five && (
                      <Typography>{parseAddress(data.answer.response).line_five}</Typography>
                    )}
                    {parseAddress(data.answer.response).line_six && (
                      <Typography>{parseAddress(data.answer.response).line_six}</Typography>
                    )}
                    <Typography>{parseAddress(data.answer.response).city}</Typography>
                    <Typography>{parseAddress(data.answer.response).code}</Typography>
                    <Typography>{parseAddress(data.answer.response).country?.name}</Typography>
                  </Box>
                )}
                {data.field_type == 9 && (
                  <Box>
                    <Typography>{JSON.parse(data.answer.response).country?.name}</Typography>
                  </Box>
                )}
                {data.field_type == 10 && <Box>{printMultipleCountries(data.answer.response)}</Box>}
                {data.field_type == 11 && (
                  <Box>
                    {data.answer.response == "false" ? (
                      <Typography>False</Typography>
                    ) : (
                      <Typography>True</Typography>
                    )}
                  </Box>
                )}
                {data.field_type == 12 && (
                  <Box>
                    {data.answer.response == "false" ? (
                      <Typography>No</Typography>
                    ) : (
                      <Typography>Yes</Typography>
                    )}
                  </Box>
                )}
                {data.field_type == 13 && (
                  <Box>
                    <Typography>{parseCurrency(data.answer.response).name}</Typography>
                  </Box>
                )}
                {data.field_type == 14 && (
                  <Box>
                    {parseCurrencies(data.answer.response).map((currency, index) => {
                      return <Typography key={index}>{currency.name}</Typography>;
                    })}
                  </Box>
                )}
                {data.field_type == 15 && (
                  <Box>
                    <Typography>{printMoney(data.answer.response)}</Typography>
                  </Box>
                )}
                {data.field_type == 16 && (
                  <Box>
                    <Typography>{data.answer.response}</Typography>
                  </Box>
                )}
                {data.field_type == 17 && (
                  <Box>
                    <Typography>{data.answer.response}</Typography>
                  </Box>
                )}
                {data.field_type == 18 && (
                  <Box>
                    <Typography>{data.answer.response} %</Typography>
                  </Box>
                )}
                {data.field_type == 19 && (
                  <Box>
                    <Box
                      title="View file"
                      onClick={() => {
                        handleLoadFile(parseUploadedFile(data.answer.response).document_uuid);
                      }}
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        gap: 1,
                        width: "fit-content",
                        cursor: "pointer",
                        border: 1,
                        borderColor: "divider",
                        p: 2,
                      }}
                    >
                      <Box>
                        <InsertDriveFile fontSize="large" />
                      </Box>
                      <Box>
                        <Typography>
                          {parseUploadedFile(data.answer.response).original_filename}
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                )}
                {data.field_type == 20 && (
                  <Box>
                    <Box sx={{ display: "flex", flexDirection: "row", gap: 2 }}>
                      {JSON.parse(data.answer.response).map((file, index) => (
                        <Box
                          key={index}
                          title="View file"
                          onClick={() => {
                            handleLoadFile(file.document_uuid);
                          }}
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            gap: 1,
                            width: "fit-content",
                            cursor: "pointer",
                            border: 1,
                            borderColor: "divider",
                            p: 2,
                          }}
                        >
                          <Box>
                            <InsertDriveFile fontSize="large" />
                          </Box>
                          <Box>
                            <Typography>{file.original_filename}</Typography>
                          </Box>
                        </Box>
                      ))}
                    </Box>
                  </Box>
                )}
                {data.field_type == 21 && (
                  <Box sx={{ border: 1, borderColor: "divider" }}>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        gap: 2,
                        borderBottom: 1,
                        borderColor: "divider",
                      }}
                    >
                      <Box sx={{ borderRight: 1, borderColor: "divider", width: "50%", p: 2 }}>
                        <Typography>Corporation name</Typography>
                      </Box>
                      <Box sx={{ p: 2 }}>
                        <Typography variant="subtitle2">
                          {parseCorporation(data.answer.response).registration_name}
                        </Typography>
                      </Box>
                    </Box>

                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        gap: 2,
                        borderBottom: 1,
                        borderColor: "divider",
                      }}
                    >
                      <Box sx={{ borderRight: 1, borderColor: "divider", width: "50%", p: 2 }}>
                        <Typography>Registration country</Typography>
                      </Box>
                      <Box sx={{ p: 2 }}>
                        <Typography variant="subtitle2">
                          {parseCorporation(data.answer.response).registration_country.name}
                        </Typography>
                      </Box>
                    </Box>

                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        gap: 2,
                        borderBottom: 1,
                        borderColor: "divider",
                      }}
                    >
                      <Box sx={{ borderRight: 1, borderColor: "divider", width: "50%", p: 2 }}>
                        <Typography>Registration reference</Typography>
                      </Box>
                      <Box sx={{ p: 2 }}>
                        <Typography variant="subtitle2">
                          {parseCorporation(data.answer.response).registration_reference}
                        </Typography>
                      </Box>
                    </Box>

                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        gap: 2,
                        borderBottom: 1,
                        borderColor: "divider",
                      }}
                    >
                      <Box sx={{ borderRight: 1, borderColor: "divider", width: "50%", p: 2 }}>
                        <Typography>Registration type</Typography>
                      </Box>
                      <Box sx={{ p: 2 }}>
                        <Typography variant="subtitle2">
                          {parseCorporation(data.answer.response).registration_type_label
                            ? parseCorporation(data.answer.response).registration_type_label
                            : "-"}
                        </Typography>
                      </Box>
                    </Box>

                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        gap: 2,
                      }}
                    >
                      <Box sx={{ borderRight: 1, borderColor: "divider", width: "50%", p: 2 }}>
                        <Typography>Registration date</Typography>
                      </Box>
                      <Box sx={{ p: 2 }}>
                        <Typography variant="subtitle2">
                          {moment(parseCorporation(data.answer.response).registration_date).format(
                            "DD/MM/YYYY"
                          )}
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                )}
                {data.field_type == 22 && <Box>{printPerson(data.answer.response)}</Box>}
                {data.field_type == 23 && (
                  <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
                    {JSON.parse(data.answer.response).corporations.map((corporation, index) => {
                      return (
                        <Box key={index}>
                          <Box sx={{ border: 1, borderColor: "divider" }}>
                            <Box
                              sx={{
                                p: 2,
                                borderBottom: 1,
                                borderColor: "divider",
                                backgroundColor: "#0b0b0b",
                                color: "#ffffff",
                              }}
                            >
                              <Typography variant="h6">Corporation {index + 1}</Typography>
                            </Box>
                            <Box
                              sx={{
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "space-between",
                                gap: 2,
                                borderBottom: 1,
                                borderColor: "divider",
                              }}
                            >
                              <Box
                                sx={{ borderRight: 1, borderColor: "divider", width: "50%", p: 2 }}
                              >
                                <Typography>Corporation name</Typography>
                              </Box>
                              <Box sx={{ p: 2 }}>
                                <Typography variant="subtitle2">
                                  {corporation.registration_name}
                                </Typography>
                              </Box>
                            </Box>

                            <Box
                              sx={{
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "space-between",
                                gap: 2,
                                borderBottom: 1,
                                borderColor: "divider",
                              }}
                            >
                              <Box
                                sx={{ borderRight: 1, borderColor: "divider", width: "50%", p: 2 }}
                              >
                                <Typography>Registration country</Typography>
                              </Box>
                              <Box sx={{ p: 2 }}>
                                <Typography variant="subtitle2">
                                  {corporation.registration_country.name}
                                </Typography>
                              </Box>
                            </Box>

                            <Box
                              sx={{
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "space-between",
                                gap: 2,
                                borderBottom: 1,
                                borderColor: "divider",
                              }}
                            >
                              <Box
                                sx={{ borderRight: 1, borderColor: "divider", width: "50%", p: 2 }}
                              >
                                <Typography>Registration reference</Typography>
                              </Box>
                              <Box sx={{ p: 2 }}>
                                <Typography variant="subtitle2">
                                  {corporation.registration_reference}
                                </Typography>
                              </Box>
                            </Box>

                            <Box
                              sx={{
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "space-between",
                                gap: 2,
                                borderBottom: 1,
                                borderColor: "divider",
                              }}
                            >
                              <Box
                                sx={{ borderRight: 1, borderColor: "divider", width: "50%", p: 2 }}
                              >
                                <Typography>Registration type</Typography>
                              </Box>
                              <Box sx={{ p: 2 }}>
                                <Typography variant="subtitle2">
                                  {corporation.registration_type_label
                                    ? corporation.registration_type_label
                                    : "-"}
                                </Typography>
                              </Box>
                            </Box>

                            <Box
                              sx={{
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "space-between",
                                gap: 2,
                              }}
                            >
                              <Box
                                sx={{ borderRight: 1, borderColor: "divider", width: "50%", p: 2 }}
                              >
                                <Typography>Registration date</Typography>
                              </Box>
                              <Box sx={{ p: 2 }}>
                                <Typography variant="subtitle2">
                                  {moment(corporation.registration_date).format("DD/MM/YYYY")}
                                </Typography>
                              </Box>
                            </Box>
                          </Box>
                        </Box>
                      );
                    })}
                  </Box>
                )}
                {data.field_type == 24 && <Box>{printMultiplePeople(data.answer.response)}</Box>}
              </Box>
            ) : (
              <Box sx={{ p: 2, borderTop: 1, borderColor: "divider" }}>
                <Typography>-</Typography>
              </Box>
            )}
          </Box>
        ))}
      </Box>

      <Modal
        open={manageResponse}
        onClose={() => setManageResponse(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "20%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "75%",
            minWidth: 650,
            bgcolor: "background.paper",
            border: 2,
            borderColor: "divider",
            p: 4,
          }}
        >
          <Typography id="modal-modal-title" variant="h6" component="h2">
            {activeQuestion?.label}
          </Typography>

          <Box sx={{ marginTop: 2 }}>
            {activeQuestion?.field_type == 1 && (
              <Box>
                <ReactQuill
                  sx={{ border: 1, borderColor: "divider" }}
                  theme="snow"
                  value={answersData[activeQuestion?.uuid] ? answersData[activeQuestion?.uuid] : ""}
                  onChange={(value) =>
                    setAnswersData((curr) => ({
                      ...curr,
                      [activeQuestion?.uuid]: value,
                    }))
                  }
                  style={{ minHeight: "300px", border: "1px solid #0000001f" }}
                />
              </Box>
            )}

            {activeQuestion?.field_type == 2 && (
              <Box>
                <TextField
                  fullWidth
                  label={`Answer`}
                  variant="outlined"
                  name={activeQuestion?.uuid}
                  value={answersData[activeQuestion?.uuid] ? answersData[activeQuestion?.uuid] : ""}
                  onChange={(e) => handleChange(e)}
                />
              </Box>
            )}

            {activeQuestion?.field_type == 3 && (
              <Box>
                <FormControl fullWidth>
                  <InputLabel id={`${activeQuestion?.uuid}-title`}>Select an option</InputLabel>
                  <Select
                    labelId={`${activeQuestion?.uuid}-title`}
                    value={
                      answersData[activeQuestion?.uuid] ? answersData[activeQuestion?.uuid] : ""
                    }
                    name={activeQuestion?.uuid}
                    label="Select an option"
                    onChange={(e) => handleChange(e)}
                  >
                    {activeQuestion?.options?.map((option, index) => (
                      <MenuItem key={index} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                    {activeQuestion?.enable_other && <MenuItem value={-1}>Other</MenuItem>}
                  </Select>
                </FormControl>

                {activeQuestion?.enable_other && (
                  <Collapse
                    sx={{ mt: 2 }}
                    in={answersData ? answersData[activeQuestion?.uuid] == -1 : false}
                    exit={answersData ? answersData[activeQuestion?.uuid] != -1 : true}
                  >
                    <Box sx={{ mb: 2 }}>
                      <TextField
                        fullWidth
                        label="Other"
                        variant="outlined"
                        name={`${activeQuestion?.uuid}-other`}
                        value={answersData[`${activeQuestion?.uuid}-other`]}
                        onChange={(e) => handleChange(e)}
                      />
                    </Box>
                  </Collapse>
                )}
              </Box>
            )}

            {activeQuestion?.field_type == 4 && (
              <Box>
                <FormControl fullWidth>
                  <InputLabel id={`${activeQuestion?.uuid}-title`}>
                    Select multiple options
                  </InputLabel>
                  <Select
                    labelId={`${activeQuestion?.uuid}-title`}
                    value={
                      answersData[activeQuestion?.uuid] ? answersData[activeQuestion?.uuid] : []
                    }
                    name={activeQuestion?.uuid}
                    label="Select multiple options"
                    multiple
                    onChange={(e) => handleChange(e)}
                  >
                    {activeQuestion?.options?.map((option, index) => (
                      <MenuItem key={index} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                    {activeQuestion?.enable_other && <MenuItem value={-1}>Other</MenuItem>}
                  </Select>
                </FormControl>

                {activeQuestion?.enable_other && (
                  <Collapse
                    sx={{ mt: 2 }}
                    in={answersData[activeQuestion?.uuid]?.includes(-1)}
                    exit={!answersData[activeQuestion?.uuid]?.includes(-1)}
                  >
                    <Box sx={{ mb: 2 }}>
                      <TextField
                        fullWidth
                        label="Other"
                        variant="outlined"
                        name={`${activeQuestion?.uuid}-other`}
                        value={answersData[`${activeQuestion?.uuid}-other`]}
                        onChange={(e) => handleChange(e)}
                      />
                    </Box>
                  </Collapse>
                )}
              </Box>
            )}

            {activeQuestion?.field_type == 5 && (
              <Box>
                <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale={locale}>
                  <DatePicker
                    fullWidth
                    openTo="year"
                    label={`Date`}
                    value={
                      answersData[activeQuestion?.uuid]?.moment
                        ? answersData[activeQuestion?.uuid].moment
                        : null
                    }
                    onChange={(date) => {
                      setAnswersData((curr) => ({
                        ...curr,
                        [activeQuestion?.uuid]: {
                          date: date.format("YYYY-MM-DD"),
                          moment: date,
                        },
                      }));
                    }}
                    slotProps={{ textField: { fullWidth: true } }}
                  />
                </LocalizationProvider>
              </Box>
            )}

            {activeQuestion?.field_type == 6 && (
              <Box>
                <TextField
                  fullWidth
                  type="email"
                  label="Email address"
                  variant="outlined"
                  name={activeQuestion?.uuid}
                  value={answersData[activeQuestion?.uuid] ? answersData[activeQuestion?.uuid] : ""}
                  onChange={(e) => handleChange(e)}
                />
              </Box>
            )}

            {activeQuestion?.field_type == 7 && (
              <Box>
                <MuiTelInput
                  fullWidth
                  defaultCountry="IM"
                  value={
                    answersData[activeQuestion?.uuid]?.phone_value
                      ? answersData[activeQuestion?.uuid].phone_value
                      : ""
                  }
                  onChange={(phone, meta) => {
                    setAnswersData((curr) => ({
                      ...curr,
                      [activeQuestion?.uuid]: {
                        phone_value: phone,
                        country_code: meta.countryCode,
                        dialling_code: meta.countryCallingCode,
                        national_number: meta.nationalNumber,
                      },
                    }));
                  }}
                />
              </Box>
            )}

            {activeQuestion?.field_type == 8 && (
              <Box>
                <Address
                  partnerUUID={requestData.partner_uuid}
                  questionUUID={activeQuestion?.uuid}
                  setAnswersData={setAnswersData}
                  answersData={answersData}
                  handleSaveAnswer={(e) => handleSaveAnswer(e, activeQuestion?.uuid, index)}
                  processing={processing}
                  setProcessing={setProcessing}
                  currentIndex={index}
                />
              </Box>
            )}

            {activeQuestion?.field_type == 9 && (
              <Box>
                <CountrySelect
                  isEdd={activeQuestion?.uuid}
                  value={answersData}
                  setValue={setAnswersData}
                />
              </Box>
            )}

            {activeQuestion?.field_type == 10 && (
              <Box>
                <CountrySelect
                  isEdd={activeQuestion?.uuid}
                  value={answersData}
                  setValue={setAnswersData}
                  isMultiple={true}
                />
              </Box>
            )}

            {activeQuestion?.field_type == 11 && (
              <Box>
                <TrueFalse
                  questionUUID={activeQuestion?.uuid}
                  answersData={answersData}
                  setAnswersData={setAnswersData}
                />
              </Box>
            )}

            {activeQuestion?.field_type == 12 && (
              <Box>
                <YesNo
                  questionUUID={activeQuestion?.uuid}
                  answersData={answersData}
                  setAnswersData={setAnswersData}
                />
              </Box>
            )}

            {activeQuestion?.field_type == 13 && (
              <Box>
                <CurrencySelect
                  isEdd={activeQuestion?.uuid}
                  value={answersData}
                  setValue={setAnswersData}
                />
              </Box>
            )}

            {activeQuestion?.field_type == 14 && (
              <Box>
                <CurrencySelect
                  isEdd={activeQuestion?.uuid}
                  value={answersData}
                  setValue={setAnswersData}
                  isMultiple={true}
                />
              </Box>
            )}

            {activeQuestion?.field_type == 15 && (
              <Box>
                <Money
                  questionUUID={activeQuestion?.uuid}
                  answersData={answersData}
                  setAnswersData={setAnswersData}
                />
              </Box>
            )}

            {activeQuestion?.field_type == 16 && (
              <Box>
                <TextField
                  fullWidth
                  type="number"
                  InputProps={{ step: "1" }}
                  label={activeQuestion?.label}
                  variant="outlined"
                  name={activeQuestion?.uuid}
                  value={answersData[activeQuestion?.uuid] ? answersData[activeQuestion?.uuid] : 0}
                  onChange={(e) => handleChange(e)}
                />
              </Box>
            )}

            {activeQuestion?.field_type == 17 && (
              <Box>
                <TextField
                  fullWidth
                  type="number"
                  InputProps={{ step: "0.00000001" }}
                  label={activeQuestion?.label}
                  variant="outlined"
                  name={activeQuestion?.uuid}
                  value={answersData[activeQuestion?.uuid] ? answersData[activeQuestion?.uuid] : 0}
                  onChange={(e) => handleChange(e)}
                />
              </Box>
            )}

            {activeQuestion?.field_type == 18 && (
              <Box>
                <TextField
                  fullWidth
                  type="number"
                  InputProps={{ step: "0.0001", endAdornment: <Percent /> }}
                  label={activeQuestion?.label}
                  variant="outlined"
                  name={activeQuestion?.uuid}
                  value={answersData[activeQuestion?.uuid] ? answersData[activeQuestion?.uuid] : 0}
                  onChange={(e) => handleChange(e)}
                />
              </Box>
            )}

            {activeQuestion?.field_type == 19 && (
              <Box>
                <SingleFileUpload
                  questionUUID={activeQuestion?.uuid}
                  setAnswersData={setAnswersData}
                  answersData={answersData}
                  getRequestData={() => getRequestData(requestUUID)}
                  setManageResponse={setManageResponse}
                />
              </Box>
            )}

            {activeQuestion?.field_type == 20 && (
              <Box>
                <MultipleFileUpload
                  questionUUID={activeQuestion?.uuid}
                  setAnswersData={setAnswersData}
                  answersData={answersData}
                  getRequestData={() => getRequestData(requestUUID)}
                  setManageResponse={setManageResponse}
                />
              </Box>
            )}

            {activeQuestion?.field_type == 21 && (
              <Box>
                <CorporateEntity
                  questionUUID={activeQuestion?.uuid}
                  partnerUUID={requestData.partner_uuid}
                  answersData={answersData}
                  setAnswersData={setAnswersData}
                />
              </Box>
            )}

            {activeQuestion?.field_type == 22 && (
              <Box>
                <Individual
                  questionUUID={activeQuestion?.uuid}
                  answersData={answersData}
                  setAnswersData={setAnswersData}
                />
              </Box>
            )}

            {activeQuestion?.field_type == 23 && (
              <Box>
                <MultipleCorporateEntities
                  questionUUID={activeQuestion?.uuid}
                  answersData={answersData}
                  setAnswersData={setAnswersData}
                  partnerUUID={requestData.partner_uuid}
                  handleSaveAnswer={(e) => handleSaveAnswer(e, activeQuestion?.uuid, index)}
                />
              </Box>
            )}

            {activeQuestion?.field_type == 24 && (
              <Box>
                <MultipleIndividuals
                  questionUUID={activeQuestion?.uuid}
                  questionIndex={index}
                  answersData={answersData}
                  setAnswersData={setAnswersData}
                  handleSaveAnswer={(e) => handleSaveAnswer(e, activeQuestion?.uuid, index)}
                  processing={processing}
                />
              </Box>
            )}
          </Box>

          <Box sx={{ display: "flex", justifyContent: "space-between", marginTop: 2 }}>
            <Button
              disableElevation
              variant="outlined"
              color="primary"
              onClick={() => setManageResponse(false)}
            >
              Close
            </Button>

            {activeQuestion?.field_type != 19 && activeQuestion?.field_type != 20 && (
              <LoadingButton
                disableElevation
                variant="contained"
                loading={processing}
                color="success"
                onClick={(e) => handleSaveAnswer(e)}
              >
                Save answer
              </LoadingButton>
            )}
          </Box>
        </Box>
      </Modal>

      <Drawer
        PaperProps={{ sx: { width: "90%" } }}
        anchor="right"
        open={viewFile}
        onClose={() => setViewFile(false)}
      >
        <Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              p: 2,
              borderBottom: 1,
              borderColor: "divider",
            }}
          >
            <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
              <Typography variant="h6" sx={{ fontWeight: 600 }}>
                File Viewer
              </Typography>
            </Box>
            <Box>
              <IconButton onClick={() => setViewFile(false)}>
                <Close />
              </IconButton>
            </Box>
          </Box>
          <Box sx={{ p: 2 }}>
            <Box sx={{ border: 1, borderColor: "divider", minHeight: 100 }}>
              {fileData?.filetype == "image" && (
                <Box sx={{ p: 2, display: "flex", justifyContent: "center" }}>
                  <img width={"50%"} sx={{}} src={`${partnerAPI}/${fileData.url}`} />
                </Box>
              )}

              {fileData?.filetype == "pdf" && (
                <embed
                  className="pdf-viewer"
                  src={`${partnerAPI}/${fileData.url}#view=Fit`}
                  type={`application/pdf`}
                />
              )}

              {fileData?.filetype == "download" && (
                <Box
                  sx={{ display: "flex", justifyContent: "center" }}
                  onClick={() => {
                    console.log("download file");
                  }}
                >
                  <Box>
                    <IconButton>
                      <Download />
                    </IconButton>
                  </Box>
                  <Box>
                    <Typography>Download file</Typography>
                  </Box>
                </Box>
              )}
            </Box>
          </Box>
        </Box>
      </Drawer>
    </Box>
  ) : (
    <Box>
      <Lottie speed={2.0} options={defaultOptions} height={200} width={200} />
    </Box>
  );
}

export default ViewRequest;
