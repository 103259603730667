export const fileTypes = {
  "image/png": [".png"],
  "image/jpeg": [".jpg", ".jpeg"], // Note: "image/jpg" is not a valid MIME type, "image/jpeg" covers .jpg files
  "image/gif": [".gif"],
  "application/pdf": [".pdf"],
  "application/msword": [".doc"],
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document": [".docx"],
  "application/vnd.ms-excel": [".xls"],
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": [".xlsx"],
  "application/vnd.ms-powerpoint": [".ppt"],
  "application/vnd.openxmlformats-officedocument.presentationml.presentation": [".pptx"],
  "application/vnd.oasis.opendocument.text": [".odt"],
  "application/vnd.oasis.opendocument.spreadsheet": [".ods"],
  "application/vnd.oasis.opendocument.presentation": [".odp"],
  "audio/mpeg": [".mp3"],
  "audio/ogg": [".ogg", ".oga"],
  "audio/wav": [".wav"],
  "audio/midi": [".midi", ".mid"],
  "audio/x-ms-wma": [".wma"],
  "audio/x-ms-wax": [".wax"],
  "audio/xm": [".xm"],
  "audio/x-aac": [".aac"],
  "audio/x-aiff": [".aiff", ".aif"],
  "audio/x-flac": [".flac"],
  "audio/x-matroska": [".mka"],
  "audio/x-mpegurl": [".m3u"],
  "audio/x-pn-realaudio": [".ra", ".ram"],
  "audio/x-pn-realaudio-plugin": [".rmp"],
  "video/mp4": [".mp4", ".m4v"],
  "video/mpeg": [".mpeg", ".mpg"],
  "video/ogg": [".ogv"],
  "video/quicktime": [".mov"],
  "video/webm": [".webm"],
  "video/x-msvideo": [".avi"],
  "video/x-flv": [".flv"],
  "video/x-matroska": [".mkv"],
  "video/x-ms-wmv": [".wmv"],
  "video/x-ms-asf": [".asf", ".asx"],
  "video/x-mng": [".mng"],
  "video/x-ms-wvx": [".wvx"],
  "video/x-ms-wmx": [".wmx"],
  "video/x-ms-wm": [".wm"],
  "video/x-ms-wmp": [".wmp"],
  "text/plain": [".txt"],
  "application/zip": [".zip"],
  "application/x-rar-compressed": [".rar"],
  "application/x-7z-compressed": [".7z"],
  "application/x-tar": [".tar"],
  "application/x-gzip": [".gz"],
  "application/x-bzip2": [".bz2"],
  "application/x-xz": [".xz"],
};
