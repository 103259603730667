import { React, useEffect, useState, useRef, useCallback } from "react";

import authHeader from "../../../../../api/authHeader";

import HandleToast from "../../../../../components/functions/HandleToast";
import ErrorHandling from "../../../../../components/functions/HandleError";

import Lottie from "react-lottie";
import successLottie from "../../../../../components/lotties/success.json";
import loadingLottie from "../../../../../components/lotties/loading-dots.json";

import { signal } from "@preact/signals-react";

import {
  Container,
  Typography,
  Grid,
  TextField,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Box,
  Fade,
  ToggleButtonGroup,
  ToggleButton,
} from "@mui/material";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";

import CaptureImage from "./CaptureImage";

const Facial = ({ partner, partnerUser, partnerData }) => {
  const useAuthHeader = authHeader();
  const displayToast = HandleToast();
  const handleError = ErrorHandling();

  const [isLoaded, setIsLoaded] = useState(false);
  const [processing, setProcessing] = useState(false);
  const [displaySuccess, setDisplaySuccess] = useState(false);

  const [editMode, setEditMode] = useState(false);

  const [confirmDialog, setConfirmDialog] = useState(false);

  const [currentImage, setCurrentImage] = useState(null);
  const [enabled, setEnabled] = useState(false);

  const defaultOptions = {
    loop: false,
    autoplay: true,
    animationData: successLottie,
  };

  const loadingOptions = {
    loop: false,
    autoplay: true,
    animationData: loadingLottie,
  };

  const getFacialData = async () => {
    try {
      const response = await partner.post(
        `/v1/users/self/data/facial-recognition`,
        JSON.stringify({}),
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Basic " + useAuthHeader.base64encodedData,
            service: "partners",
          },

          //withCredentials: true,
        }
      );

      if (!response.data.image_set) {
        setEditMode(true);
      } else {
        setCurrentImage(response.data.image);
        setEnabled(response.data.enabled);
      }

      setIsLoaded(true);
    } catch (err) {
      console.log(err);
      handleError(err);
    }
  };

  const handleSaveStatusChange = async (e) => {
    setProcessing(true);

    try {
      const response = await partner.post(
        `/v1/users/self/settings/facial-recognition`,
        JSON.stringify({
          partner_user_uuid: partnerUser.value.uuid,
        }),
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Basic " + useAuthHeader.base64encodedData,
            service: "partners",
          },

          //withCredentials: true,
        }
      );

      getFacialData();

      displayToast(`Facial recognition settings updated successfully`, () => {
        setProcessing(false);
        setConfirmDialog(false);
      });
    } catch (err) {
      console.log(err);
      handleError(err);

      setProcessing(false);
    }
  };

  useEffect(() => {
    getFacialData();
  }, []);

  return (
    <>
      <Box sx={{ borderBottom: 1, borderTop: 1, borderColor: "divider", p: 0 }}>
        <Typography sx={{ mb: 0, p: 2 }} variant="h6" gutterBottom>
          Facial Recognition Settings
        </Typography>
      </Box>

      {isLoaded ? (
        <Box sx={{ border: 0, borderColor: "divider", p: 2, borderTop: 0 }}>
          {editMode ? (
            <CaptureImage
              partnerUser={partnerUser}
              getFacialData={() => {
                getFacialData();
              }}
              setEditMode={setEditMode}
            />
          ) : (
            <Box>
              <Typography variant="body1" gutterBottom>
                Facial recognition is used to verify your identity when logging in and approving
                payments or beneficiaries.
              </Typography>

              <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "center", mt: 2 }}>
                <img src={currentImage} alt="webcam" />
              </Box>

              <Box
                sx={{
                  mt: 2,
                  width: "100%",
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    gap: 4,
                    p: 2,
                    width: "75%",
                  }}
                >
                  <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
                    <Typography variant="subtitle2">Facial recognition protection</Typography>
                  </Box>
                  <Box>
                    <ToggleButtonGroup
                      size="small"
                      color={enabled ? "success" : "danger"}
                      value={enabled}
                      exclusive
                      onChange={(e, newValue) => {
                        if (newValue !== null) {
                          setEnabled(newValue);
                          setConfirmDialog(true);
                        }
                      }}
                      aria-label="Facial recognition protection"
                    >
                      <ToggleButton value={true}>Enabled</ToggleButton>
                      <ToggleButton value={false}>Disabled</ToggleButton>
                    </ToggleButtonGroup>
                  </Box>
                </Box>
                <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
                  <Button
                    variant="contained"
                    disableElevation
                    color="primary"
                    onClick={() => setEditMode(true)}
                  >
                    Update Image
                  </Button>
                </Box>
              </Box>
            </Box>
          )}
        </Box>
      ) : (
        <Box>
          <Lottie speed={2.0} options={loadingOptions} height={200} width={200} />
        </Box>
      )}

      <Dialog
        open={confirmDialog}
        onClose={() => {
          getFacialData();
          setConfirmDialog(false);
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {displaySuccess ? "Complete" : "Please confirm"}
        </DialogTitle>
        <DialogContent>
          {displaySuccess ? (
            <Lottie options={defaultOptions} height={100} width={100} />
          ) : (
            <DialogContentText id="alert-dialog-description">
              Are you sure you want to update the settings?
            </DialogContentText>
          )}
        </DialogContent>
        <DialogActions>
          {!displaySuccess && (
            <LoadingButton
              disableElevation
              loading={processing}
              onClick={() => {
                getFacialData();
                setConfirmDialog(false);
              }}
              color="primary"
            >
              Cancel
            </LoadingButton>
          )}

          <LoadingButton
            disableElevation
            fullWidth={displaySuccess}
            loading={processing}
            onClick={(e) => {
              handleSaveStatusChange(e);
            }}
            variant="contained"
            color="success"
            autoFocus
          >
            Confirm
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default Facial;
