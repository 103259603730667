import { React, useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import partner from "../../api/partner";
import authHeader from "../../api/authHeader";

import HandleError from "../../components/functions/HandleError";

import { signal } from "@preact/signals-react";
import Lottie from "react-lottie";
import loadingLottie from "../../components/lotties/loading-dots.json";

import { CssBaseline, Box, Divider, Typography, Button, Chip, Drawer } from "@mui/material";

import UserNav from "./UserNav";
import Overview from "./Overview";
import Permissions from "./Permissions";
import Sessions from "./Sessions";
import Settings from "./Settings";

const activeView = signal(0);
const partnerUser = signal({});
const partnerData = signal({});
const userData = signal({});

const entityData = signal({});

const ManageUser = () => {
  const { t } = useParams();

  const useAuthHeader = authHeader();
  const handleError = HandleError();

  const [navOpen, setNavOpen] = useState(true);

  const [userLoaded, setUserLoaded] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);

  const [telephoneData, setTelephoneData] = useState();

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: loadingLottie,
  };

  const getUser = async (userUUID) => {
    try {
      const response = await partner.post(
        `/v1/users/admin/data/user`,
        JSON.stringify({
          partner_user_uuid: userUUID,
        }),
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Basic " + useAuthHeader.base64encodedData,
            service: "partners",
          },

          //withCredentials: true,
        }
      );

      userData.value = response.data;

      setIsLoaded(true);
      // getNotes();
    } catch (err) {
      console.log(err);
      handleError(err);
    }
  };

  const getPerson = async (personUUID) => {
    try {
      const response = await partner.post(
        `/v1/entity/people/person/data`,
        JSON.stringify({
          person_uuid: personUUID,
        }),
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Basic " + useAuthHeader.base64encodedData,
            service: "partners",
          },

          //withCredentials: true,
        }
      );

      entityData.value = response.data;
      setIsLoaded(true);
    } catch (err) {
      console.log(err);
      handleError(err);
    }
  };

  useEffect(() => {
    getUser(t);
  }, []);

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />

      <UserNav
        navOpen={navOpen}
        setNavOpen={setNavOpen}
        activeView={activeView}
        partnerUser={partnerUser}
        partnerData={partnerData}
        userLoaded={userLoaded}
        setUserLoaded={setUserLoaded}
      />

      <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
        {isLoaded ? (
          <>
            <Box sx={{ border: 1, borderColor: "divider" }}>
              <Box sx={{ p: 2, display: "flex", justifyContent: "space-between", gap: 2 }}>
                <Box sx={{ display: "flex", gap: 4 }}>
                  <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
                    <Typography variant="h6">Manage user</Typography>
                  </Box>
                </Box>

                <Box></Box>
              </Box>
              <Divider />
              <Box>
                {activeView.value === 0 && <Overview partnerUser={partnerUser} partnerData={partnerData} userData={userData} />}
                {activeView.value === 1 && <Permissions partnerUser={partnerUser} partnerData={partnerData} userData={userData} />}
                {activeView.value === 2 && <Sessions partnerUser={partnerUser} partnerData={partnerData} userData={userData} />}
                {activeView.value === 3 && <Settings partnerUser={partnerUser} partnerData={partnerData} userData={userData} />}
              </Box>
            </Box>
          </>
        ) : (
          <Box>
            <Lottie speed={2.0} options={defaultOptions} height={200} width={200} />
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default ManageUser;
