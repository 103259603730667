import { React, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";

import hostedAuthHeader from "../../api/hostedAuthHeader";

import HandleError from "../../components/functions/HostedHandleError";
import HandleToast from "../../components/functions/HandleToast";

import Cookies from "universal-cookie";
import axios from "axios";
import Lottie from "react-lottie";
import loadingLottie from "../../components/lotties/loading-dots.json";
import successCheckLottie from "../../components/lotties/success-check2.json";

import { signal } from "@preact/signals-react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import moment from "moment";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { MuiTelInput } from "mui-tel-input";

import {
  CssBaseline,
  Box,
  Divider,
  Typography,
  Button,
  Chip,
  Grid,
  TextField,
  List,
  ListItem,
  ToggleButtonGroup,
  ToggleButton,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Select,
  Collapse,
} from "@mui/material";

import { Percent } from "@mui/icons-material";

import { LoadingButton } from "@mui/lab";

import Address from "./components/edd-fields/Address";

import CountrySelect from "../../components/form-elements/CountrySelect";
import CurrencySelect from "../../components/form-elements/CurrencySelect";
import TrueFalse from "./components/edd-fields/TrueFalse";
import YesNo from "./components/edd-fields/YesNo";
import Money from "./components/edd-fields/Money";
import SingleFileUpload from "./components/edd-fields/SingleFileUpload";
import MultipleFileUpload from "./components/edd-fields/MultipleFileUpload";
import CorporateEntity from "./components/edd-fields/CorporateEntity";
import Individual from "./components/edd-fields/Individual";
import MultipleCorporateEntities from "./components/edd-fields/MultipleCorporateEntities";
import MultipleIndividuals from "./components/edd-fields/MultipleIndividuals";

const activeView = signal(0);
const caseData = signal({});
const entityData = signal({});

const EddHosted = () => {
  const cookies = new Cookies();
  const useAuthHeader = hostedAuthHeader();
  const { t } = useParams();

  const [locale, setLocale] = useState("en-gb");

  if (moment.locale() != locale) {
    moment.locale(locale);
  }

  const handleError = HandleError();
  const displayToast = HandleToast();

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: loadingLottie,
  };

  const successOptions = {
    loop: false,
    autoplay: true,
    animationData: successCheckLottie,
  };

  const apiBaseURL = "https://" + cookies.get("platformURL");

  const partner = axios.create({ baseURL: apiBaseURL });

  const [isLoaded, setIsLoaded] = useState(false);
  const [entityLoaded, setEntityLoaded] = useState(false);
  const [processing, setProcessing] = useState(false);

  const [partnerName, setPartnerName] = useState("");
  const [partnerLogo, setPartnerLogo] = useState("");
  const [partnerDataLocation, setPartnerDataLocation] = useState("United Kingdom");

  const [requestData, setRequestData] = useState(null);

  const [answersData, setAnswersData] = useState(null);

  const getHostedData = async (inviteUUID) => {
    try {
      const response = await partner.post(
        `/v1/screening/hosted/data/edd`,
        JSON.stringify({
          invite_uuid: inviteUUID,
        }),
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Basic " + useAuthHeader.base64encodedData,
            service: "hosted",
          },

          //withCredentials: true,
        }
      );

      setRequestData(response.data);
      setPartnerName(response.data.partner_name);
      setPartnerDataLocation(response.data.data_location);
      setPartnerLogo(response.data.partner_logo);

      if (response.data.accepted) {
        getCase(response.data.case_uuid);

        if (answersData == null) {
          response.data.questions.map((question) => {
            if (question.has_response) {
              let existingAnswer;

              switch (question.field_type) {
                case 3:
                  let parsedAnswer3 = JSON.parse(question.answer?.response);
                  existingAnswer = parsedAnswer3.value;

                  if (existingAnswer == -1) {
                    setAnswersData((curr) => ({
                      ...curr,
                      [question.uuid + "-other"]: parsedAnswer3.label ? parsedAnswer3.label : "",
                    }));
                  }

                  break;

                case 4:
                  let parsedAnswer4 = JSON.parse(question.answer?.response);

                  if (parsedAnswer4.length > 0) {
                    // loop through the array and push the value into a new array
                    let answerArray = [];

                    parsedAnswer4.map((answer) => {
                      answerArray.push(answer.value);

                      if (answer.value == -1) {
                        setAnswersData((curr) => ({
                          ...curr,
                          [question.uuid + "-other"]: answer.label ? answer.label : "",
                        }));
                      }
                    });

                    existingAnswer = answerArray;
                  }

                  break;

                case 5:
                  existingAnswer = {
                    moment: moment(question.answer.response),
                    date: moment(question.answer.response).format("YYYY-MM-DD"),
                  };
                  break;

                case 7:
                  let parsedAnswer7 = JSON.parse(question.answer?.response);

                  existingAnswer = {
                    phone_value: parsedAnswer7.telephone?.phone_value,
                    country_code: parsedAnswer7.telephone?.country_code,
                    dialling_code: parsedAnswer7.telephone?.dialling_code,
                    national_number: parsedAnswer7.telephone?.national_number,
                  };

                  break;

                case 8:
                  let parsedAnswer8 = JSON.parse(question.answer?.response);

                  existingAnswer = {
                    line_one: parsedAnswer8.address.line_one,
                    line_two: parsedAnswer8.address.line_two,
                    line_three: parsedAnswer8.address.line_three,
                    line_four: parsedAnswer8.address.line_four,
                    line_five: parsedAnswer8.address.line_five,
                    line_six: parsedAnswer8.address.line_six,
                    city: parsedAnswer8.address.city,
                    state: parsedAnswer8.address.state,
                    code: parsedAnswer8.address.code,
                    country: parsedAnswer8.address.country,
                  };

                  break;

                case 9:
                  let parsedAnswer9 = JSON.parse(question.answer?.response);

                  existingAnswer = parsedAnswer9.country;
                  break;

                case 10:
                  let parsedAnswer10 = JSON.parse(question.answer?.response);

                  let countryArray = [];

                  parsedAnswer10.countries.map((country) => {
                    countryArray.push(country);
                  });

                  existingAnswer = countryArray;

                  break;

                case 13:
                  let parsedAnswer13 = JSON.parse(question.answer?.response);
                  existingAnswer = parsedAnswer13.currency;

                  break;

                case 14:
                  let parsedAnswer14 = JSON.parse(question.answer?.response);

                  let currencyArray = [];

                  parsedAnswer14.currencies.map((currency) => {
                    currencyArray.push(currency);
                  });

                  existingAnswer = currencyArray;

                  break;

                case 15:
                  let parsedAnswer15 = JSON.parse(question.answer?.response);

                  existingAnswer = {
                    amount: parsedAnswer15.amount,
                    currency: parsedAnswer15.currency,
                  };

                  break;

                case 19:
                  existingAnswer = JSON.parse(question.answer?.response);

                  break;

                case 20:
                  let parsedAnswer20 = JSON.parse(question.answer?.response);

                  if (parsedAnswer20.length > 0) {
                    existingAnswer = true;
                  } else {
                    existingAnswer = false;
                  }

                  break;

                case 21:
                  let parsedAnswer21 = JSON.parse(question.answer?.response);

                  existingAnswer = {
                    registration_name: parsedAnswer21.Corporation.registration_name,
                    selected_corporation: parsedAnswer21.Corporation.selected_corporation,
                    registration_country: parsedAnswer21.Corporation.registration_country,
                    registration_date: parsedAnswer21.Corporation.registration_date,
                    registration_reference: parsedAnswer21.Corporation.registration_reference,
                    registration_type: parsedAnswer21.Corporation.registration_type,
                  };

                  break;

                case 22:
                  let parsedAnswer22 = JSON.parse(question.answer?.response);

                  existingAnswer = {
                    title: parsedAnswer22.person.title,
                    first_name: parsedAnswer22.person.first_name,
                    middle_names: parsedAnswer22.person.middle_names,
                    last_name: parsedAnswer22.person.last_name,
                  };

                  break;

                case 23:
                  let parsedAnswer23 = JSON.parse(question.answer?.response);

                  existingAnswer = [];

                  parsedAnswer23.corporations.map((corporation) => {
                    existingAnswer.push({
                      registration_name: corporation.registration_name,
                      selected_corporation: corporation.selected_corporation,
                      registration_country: corporation.registration_country,
                      registration_date: corporation.registration_date,
                      registration_reference: corporation.registration_reference,
                      registration_type: corporation.registration_type,
                    });
                  });

                  break;

                case 24:
                  let parsedAnswer24 = JSON.parse(question.answer?.response);

                  existingAnswer = [];

                  parsedAnswer24.people.map((person) => {
                    existingAnswer.push({
                      title: person.title,
                      first_name: person.first_name,
                      middle_names: person.middle_names,
                      last_name: person.last_name,
                    });
                  });

                  break;

                default:
                  existingAnswer = question.answer.response;
                  break;
              }

              setAnswersData((curr) => ({
                ...curr,
                [question.uuid]: existingAnswer ? existingAnswer : "",
              }));
            } else {
              let existingAnswer;

              switch (question.field_type) {
                case 3:
                  existingAnswer = null;
                  break;
                case 4:
                  existingAnswer = [];
                  break;
                case 5:
                  existingAnswer = null;

                  break;

                case 7:
                  existingAnswer = null;

                  break;

                case 8:
                  existingAnswer = null;

                  break;

                case 9:
                  existingAnswer = null;
                  break;

                case 10:
                  existingAnswer = [];

                  break;

                case 13:
                  existingAnswer = null;

                  break;

                case 14:
                  existingAnswer = [];

                  break;

                case 15:
                  existingAnswer = null;

                  break;

                case 21:
                  existingAnswer = null;

                  break;

                case 22:
                  existingAnswer = null;

                  break;

                case 23:
                  existingAnswer = [];

                  break;

                case 24:
                  existingAnswer = [];

                  break;

                default:
                  existingAnswer = "";
                  break;
              }

              setAnswersData((curr) => ({
                ...curr,
                [question.uuid]: null,
              }));
            }
          });
        }
      } else {
        setIsLoaded(true);
      }
    } catch (err) {
      console.log(err);
      handleError(err);
    }
  };

  const handleAcceptDataNotice = async (e) => {
    e.preventDefault();
    setProcessing(true);

    try {
      const response = await partner.post(
        `/v1/screening/hosted/data-notice/accept/edd`,
        JSON.stringify({
          case_uuid: requestData?.case_uuid,
        }),
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Basic " + useAuthHeader.base64encodedData,
            service: "hosted",
          },

          // withCredentials: true,
        }
      );

      getHostedData();

      displayToast("Data notice accepted", () => {
        setProcessing(false);
      });
    } catch (err) {
      console.log(err);
      handleError(err);
      setProcessing(false);
    }
  };

  const handleRejectDataNotice = async (e) => {
    e.preventDefault();
    setProcessing(true);

    try {
      const response = await partner.post(
        `/v1/screening/hosted/data-notice/reject/edd`,
        JSON.stringify({
          case_uuid: requestData?.case_uuid,
        }),
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Basic " + useAuthHeader.base64encodedData,
            service: "hosted",
          },

          // withCredentials: true,
        }
      );

      getHostedData();

      displayToast("Data notice rejected", () => {
        setProcessing(false);
      });
    } catch (err) {
      console.log(err);
      handleError(err);
      setProcessing(false);
    }
  };

  const getCase = async (caseUUID) => {
    try {
      const response = await partner.post(
        `/v1/screening/case/kyc/data`,
        JSON.stringify({
          case_uuid: caseUUID,
        }),
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Basic " + useAuthHeader.base64encodedData,
            service: "hosted",
          },

          //withCredentials: true,
        }
      );

      caseData.value = response.data;
      getPerson(response.data.person_uuid);
    } catch (err) {
      console.log(err);
      handleError(err);
    }
  };

  const getPerson = async (personUUID) => {
    try {
      const response = await partner.post(
        `/v1/entity/people/person/data`,
        JSON.stringify({
          person_uuid: personUUID,
        }),
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Basic " + useAuthHeader.base64encodedData,
            service: "hosted",
          },

          //withCredentials: true,
        }
      );

      entityData.value = response.data;
      setIsLoaded(true);
    } catch (err) {
      console.log(err);
      handleError(err);
    }
  };

  const handleChange = (event) => {
    setAnswersData((curr) => ({
      ...curr,
      [event.target.name]: event.target.value,
    }));
  };

  const handleSaveAnswer = async (e, questionUUID, index) => {
    e.preventDefault();
    setProcessing(true);

    try {
      const response = await partner.post(
        `/v1/screening/case/kyc/enhanced/answer`,
        JSON.stringify({
          case_uuid: requestData.case_uuid,
          question_uuid: questionUUID,
          answer: answersData,
        }),
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Basic " + useAuthHeader.base64encodedData,
            service: "hosted",
          },

          // withCredentials: true,
        }
      );

      getHostedData(t);

      displayToast("Answer saved", () => {
        setProcessing(false);
        activeView.value = requestData.questions[index + 1].uuid;
      });
    } catch (err) {
      console.log(err);
      handleError(err);
      setProcessing(false);
    }
  };

  useEffect(() => {
    getHostedData(t);
  }, []);

  useEffect(() => {
    if (entityData.value.person_uuid) {
      setEntityLoaded(true);
      if (activeView.value == 0) {
        activeView.value = requestData?.questions[0].uuid;
      }
    }
  }, [entityData.value]);

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />

      <Box component="main" sx={{ flexGrow: 1, p: { xs: 0, sm: 1, md: 3 } }}>
        {isLoaded ? (
          <Box>
            {requestData?.rejected ? (
              <Grid container>
                <Grid
                  item
                  xs={12}
                  lg={5}
                  xl={4}
                  sx={{ mx: "auto", border: 1, borderColor: "divider" }}
                >
                  <Box sx={{ display: "flex", flexDirection: "column" }}>
                    <Box sx={{ width: "100%", p: 2, display: "flex", justifyContent: "center" }}>
                      <Box
                        component="img"
                        src={partnerLogo ? partnerLogo : "/cleverli-logo-black.svg"}
                        alt="logo"
                        sx={{ maxWidth: 400, maxHeight: 300 }}
                      />
                    </Box>
                    {partnerLogo && (
                      <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                        <Box sx={{ py: 1, px: 2 }}>
                          <Typography
                            variant="subtitle2"
                            sx={{
                              fontSize: "13px",
                              textAlign: "end",
                              fontStyle: "italic",
                              mr: "5px",
                              mb: 0,
                            }}
                          >
                            Powered by
                          </Typography>
                          <Box sx={{ display: "flex", justifyContent: "flex-end", mt: "-3px" }}>
                            <img
                              src="/cleverli-logo-black.svg"
                              alt="logo"
                              style={{ width: "150px" }}
                            />
                          </Box>
                        </Box>
                      </Box>
                    )}
                  </Box>

                  <Divider />

                  <Box
                    sx={{
                      p: 2,
                      backgroundColor: "#0b0b0b",
                      color: "#ffffff",
                      textTransform: "uppercase",
                    }}
                  >
                    <Typography variant="h4" align="center" sx={{ color: "#C83E4D" }}>
                      Data notice - rejected
                    </Typography>
                  </Box>

                  <Divider />
                  <Box sx={{ p: 2, textAlign: "center" }}>
                    <Typography variant="body1" gutterBottom>
                      You were invited to provide information by {partnerName}.
                    </Typography>
                    <Typography variant="body1">
                      You declined the data notice, to try again please contact {partnerName}{" "}
                      directly.
                    </Typography>
                  </Box>
                </Grid>
              </Grid>
            ) : requestData?.accepted ? (
              requestData.complete ? (
                <Grid container>
                  <Grid
                    item
                    xs={12}
                    lg={8}
                    xl={6}
                    sx={{ mx: "auto", border: 1, borderColor: "divider" }}
                  >
                    <Box sx={{ display: "flex", flexDirection: "column" }}>
                      <Box sx={{ width: "100%", p: 2, display: "flex", justifyContent: "center" }}>
                        <Box
                          component="img"
                          src={partnerLogo ? partnerLogo : "/cleverli-logo-black.svg"}
                          alt="logo"
                          sx={{ maxWidth: 400, maxHeight: 300 }}
                        />
                      </Box>
                      {partnerLogo && (
                        <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                          <Box sx={{ py: 1, px: 2 }}>
                            <Typography
                              variant="subtitle2"
                              sx={{
                                fontSize: "13px",
                                textAlign: "end",
                                fontStyle: "italic",
                                mr: "5px",
                                mb: 0,
                              }}
                            >
                              Powered by
                            </Typography>
                            <Box sx={{ display: "flex", justifyContent: "flex-end", mt: "-3px" }}>
                              <img
                                src="/cleverli-logo-black.svg"
                                alt="logo"
                                style={{ width: "150px" }}
                              />
                            </Box>
                          </Box>
                        </Box>
                      )}
                    </Box>

                    <Divider />

                    <Box sx={{ backgroundColor: "#8BBF9F", p: 2 }}>
                      <Typography
                        variant="h6"
                        sx={{ color: "#ffffff", textTransform: "uppercase", textAlign: "center" }}
                      >
                        Request Completed Successfully
                      </Typography>
                      <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "center" }}>
                        <Lottie speed={1.0} options={successOptions} height={100} width={100} />
                      </Box>
                    </Box>

                    <Box sx={{ p: 2 }}>
                      <Typography
                        sx={{ textTransform: "uppercase", textAlign: "center" }}
                        variant="body1"
                      >
                        Thank you, {partnerName} has everything they requested.
                      </Typography>
                    </Box>
                  </Grid>
                </Grid>
              ) : (
                <Grid container>
                  <Grid
                    item
                    xs={12}
                    lg={8}
                    xl={6}
                    sx={{ mx: "auto", border: 1, borderColor: "divider" }}
                  >
                    <Box sx={{ display: "flex", flexDirection: "column" }}>
                      <Box sx={{ width: "100%", p: 2, display: "flex", justifyContent: "center" }}>
                        <Box
                          component="img"
                          src={partnerLogo ? partnerLogo : "/cleverli-logo-black.svg"}
                          alt="logo"
                          sx={{ maxWidth: 400, maxHeight: 300 }}
                        />
                      </Box>
                      {partnerLogo && (
                        <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                          <Box sx={{ py: 1, px: 2 }}>
                            <Typography
                              variant="subtitle2"
                              sx={{
                                fontSize: "13px",
                                textAlign: "end",
                                fontStyle: "italic",
                                mr: "5px",
                                mb: 0,
                              }}
                            >
                              Powered by
                            </Typography>
                            <Box sx={{ display: "flex", justifyContent: "flex-end", mt: "-3px" }}>
                              <img
                                src="/cleverli-logo-black.svg"
                                alt="logo"
                                style={{ width: "150px" }}
                              />
                            </Box>
                          </Box>
                        </Box>
                      )}
                    </Box>

                    <Divider />

                    {entityLoaded ? (
                      <>
                        <Box
                          sx={{
                            backgroundColor: "#0b0b0b",
                            color: "#ffffff",
                            textTransform: "uppercase",
                          }}
                        >
                          <Box sx={{ display: "flex", justifyContent: "space-evenly" }}>
                            {requestData?.questions.map((question, index) => (
                              <Box
                                key={index}
                                onClick={() => (activeView.value = question.uuid)}
                                sx={{
                                  cursor: "pointer",
                                  display: "flex",
                                  flexDirection: "column",
                                  justifyContent: "center",
                                  height: "40px",
                                }}
                              >
                                <Box
                                  className="hosted-indicator"
                                  sx={{
                                    width: "30px",
                                    height: "5px",
                                    backgroundColor: question.has_response
                                      ? "#D5E950"
                                      : activeView.value == question.uuid
                                      ? "#FCCA46"
                                      : "#ffffff",
                                  }}
                                ></Box>
                                {activeView.value == question.uuid && (
                                  <Box
                                    className="hosted-indicator"
                                    sx={{
                                      marginTop: "5px",
                                      width: "30px",
                                      height: "2px",
                                      backgroundColor: question.has_response
                                        ? "#D5E950"
                                        : "#FCCA46",
                                    }}
                                  ></Box>
                                )}
                              </Box>
                            ))}
                          </Box>
                        </Box>

                        <Divider />

                        <Box sx={{}}>
                          {requestData?.questions.map((question, index) => (
                            <Box
                              key={index}
                              sx={{ display: activeView.value == question.uuid ? "block" : "none" }}
                            >
                              <Box sx={{ p: 2, display: "flex", flexDirection: "column", gap: 2 }}>
                                <Typography variant="subtitle2">{question.label}</Typography>

                                {question.field_type == 1 && (
                                  <Box>
                                    <ReactQuill
                                      sx={{ border: 1, borderColor: "divider" }}
                                      theme="snow"
                                      value={
                                        answersData[question.uuid] ? answersData[question.uuid] : ""
                                      }
                                      onChange={(value) =>
                                        setAnswersData((curr) => ({
                                          ...curr,
                                          [question.uuid]: value,
                                        }))
                                      }
                                      style={{ minHeight: "300px", border: "1px solid #0000001f" }}
                                    />

                                    <Box
                                      sx={{
                                        display: "flex",
                                        flexDirection: "row",
                                        justifyContent: "flex-end",
                                        pt: 2,
                                      }}
                                    >
                                      <LoadingButton
                                        disableElevation
                                        onClick={(e) => {
                                          handleSaveAnswer(e, question.uuid, index);
                                        }}
                                        loading={processing}
                                        variant="contained"
                                        color="success"
                                      >
                                        Save Answer
                                      </LoadingButton>
                                    </Box>
                                  </Box>
                                )}

                                {question.field_type == 2 && (
                                  <Box>
                                    <TextField
                                      fullWidth
                                      label={`Answer`}
                                      variant="outlined"
                                      name={question.uuid}
                                      value={
                                        answersData[question.uuid] ? answersData[question.uuid] : ""
                                      }
                                      onChange={(e) => handleChange(e)}
                                    />
                                    <Box
                                      sx={{
                                        display: "flex",
                                        flexDirection: "row",
                                        justifyContent: "flex-end",
                                        mt: 2,
                                      }}
                                    >
                                      <LoadingButton
                                        disableElevation
                                        onClick={(e) => {
                                          handleSaveAnswer(e, question.uuid, index);
                                        }}
                                        loading={processing}
                                        variant="contained"
                                        color="success"
                                      >
                                        Save Answer
                                      </LoadingButton>
                                    </Box>
                                  </Box>
                                )}

                                {question.field_type == 3 && (
                                  <Box>
                                    <FormControl fullWidth>
                                      <InputLabel id={`${question.uuid}-title`}>
                                        Select an option
                                      </InputLabel>
                                      <Select
                                        labelId={`${question.uuid}-title`}
                                        value={
                                          answersData[question.uuid]
                                            ? answersData[question.uuid]
                                            : ""
                                        }
                                        name={question.uuid}
                                        label="Select an option"
                                        onChange={(e) => handleChange(e)}
                                      >
                                        {question.options?.map((option, index) => (
                                          <MenuItem key={index} value={option.value}>
                                            {option.label}
                                          </MenuItem>
                                        ))}
                                        {question.enable_other && (
                                          <MenuItem value={-1}>Other</MenuItem>
                                        )}
                                      </Select>
                                    </FormControl>

                                    {question.enable_other && (
                                      <Collapse
                                        sx={{ mt: 2 }}
                                        in={answersData ? answersData[question.uuid] == -1 : false}
                                        exit={answersData ? answersData[question.uuid] != -1 : true}
                                      >
                                        <Box sx={{ mb: 2 }}>
                                          <TextField
                                            fullWidth
                                            label="Other"
                                            variant="outlined"
                                            name={`${question.uuid}-other`}
                                            value={answersData[`${question.uuid}-other`]}
                                            onChange={(e) => handleChange(e)}
                                          />
                                        </Box>
                                      </Collapse>
                                    )}

                                    <Box
                                      sx={{
                                        display: "flex",
                                        flexDirection: "row",
                                        justifyContent: "flex-end",
                                      }}
                                    >
                                      <LoadingButton
                                        disableElevation
                                        onClick={(e) => {
                                          handleSaveAnswer(e, question.uuid, index);
                                        }}
                                        loading={processing}
                                        variant="contained"
                                        color="success"
                                      >
                                        Save Answer
                                      </LoadingButton>
                                    </Box>
                                  </Box>
                                )}

                                {question.field_type == 4 && (
                                  <Box>
                                    <FormControl fullWidth>
                                      <InputLabel id={`${question.uuid}-title`}>
                                        Select multiple options
                                      </InputLabel>
                                      <Select
                                        labelId={`${question.uuid}-title`}
                                        value={
                                          answersData[question.uuid]
                                            ? answersData[question.uuid]
                                            : []
                                        }
                                        name={question.uuid}
                                        label="Select multiple options"
                                        multiple
                                        onChange={(e) => handleChange(e)}
                                      >
                                        {question.options?.map((option, index) => (
                                          <MenuItem key={index} value={option.value}>
                                            {option.label}
                                          </MenuItem>
                                        ))}
                                        {question.enable_other && (
                                          <MenuItem value={-1}>Other</MenuItem>
                                        )}
                                      </Select>
                                    </FormControl>

                                    {question.enable_other && (
                                      <Collapse
                                        sx={{ mt: 2 }}
                                        in={answersData[question.uuid]?.includes(-1)}
                                        exit={!answersData[question.uuid]?.includes(-1)}
                                      >
                                        <Box sx={{ mb: 2 }}>
                                          <TextField
                                            fullWidth
                                            label="Other"
                                            variant="outlined"
                                            name={`${question.uuid}-other`}
                                            value={answersData[`${question.uuid}-other`]}
                                            onChange={(e) => handleChange(e)}
                                          />
                                        </Box>
                                      </Collapse>
                                    )}

                                    <Box
                                      sx={{
                                        display: "flex",
                                        flexDirection: "row",
                                        justifyContent: "flex-end",
                                      }}
                                    >
                                      <LoadingButton
                                        disableElevation
                                        onClick={(e) => {
                                          handleSaveAnswer(e, question.uuid, index);
                                        }}
                                        loading={processing}
                                        variant="contained"
                                        color="success"
                                      >
                                        Save Answer
                                      </LoadingButton>
                                    </Box>
                                  </Box>
                                )}

                                {question.field_type == 5 && (
                                  <Box>
                                    <LocalizationProvider
                                      dateAdapter={AdapterMoment}
                                      adapterLocale={locale}
                                    >
                                      <DatePicker
                                        fullWidth
                                        openTo="year"
                                        label={`Date`}
                                        value={
                                          answersData[question.uuid]?.moment
                                            ? answersData[question.uuid].moment
                                            : null
                                        }
                                        onChange={(date) => {
                                          setAnswersData((curr) => ({
                                            ...curr,
                                            [question.uuid]: {
                                              date: date.format("YYYY-MM-DD"),
                                              moment: date,
                                            },
                                          }));
                                        }}
                                        slotProps={{ textField: { fullWidth: true } }}
                                      />
                                    </LocalizationProvider>

                                    <Box
                                      sx={{
                                        display: "flex",
                                        flexDirection: "row",
                                        justifyContent: "flex-end",
                                        pt: 2,
                                      }}
                                    >
                                      <LoadingButton
                                        disableElevation
                                        onClick={(e) => {
                                          handleSaveAnswer(e, question.uuid, index);
                                        }}
                                        loading={processing}
                                        variant="contained"
                                        color="success"
                                      >
                                        Save Answer
                                      </LoadingButton>
                                    </Box>
                                  </Box>
                                )}

                                {question.field_type == 6 && (
                                  <Box>
                                    <TextField
                                      fullWidth
                                      type="email"
                                      label="Email address"
                                      variant="outlined"
                                      name={question.uuid}
                                      value={
                                        answersData[question.uuid] ? answersData[question.uuid] : ""
                                      }
                                      onChange={(e) => handleChange(e)}
                                    />

                                    <Box
                                      sx={{
                                        display: "flex",
                                        flexDirection: "row",
                                        justifyContent: "flex-end",
                                        pt: 2,
                                      }}
                                    >
                                      <LoadingButton
                                        disableElevation
                                        onClick={(e) => {
                                          handleSaveAnswer(e, question.uuid, index);
                                        }}
                                        loading={processing}
                                        variant="contained"
                                        color="success"
                                      >
                                        Save Answer
                                      </LoadingButton>
                                    </Box>
                                  </Box>
                                )}

                                {question.field_type == 7 && (
                                  <Box>
                                    <MuiTelInput
                                      fullWidth
                                      defaultCountry="IM"
                                      value={
                                        answersData[question.uuid]?.phone_value
                                          ? answersData[question.uuid].phone_value
                                          : ""
                                      }
                                      onChange={(phone, meta) => {
                                        setAnswersData((curr) => ({
                                          ...curr,
                                          [question.uuid]: {
                                            phone_value: phone,
                                            country_code: meta.countryCode,
                                            dialling_code: meta.countryCallingCode,
                                            national_number: meta.nationalNumber,
                                          },
                                        }));
                                      }}
                                    />

                                    <Box
                                      sx={{
                                        display: "flex",
                                        flexDirection: "row",
                                        justifyContent: "flex-end",
                                        pt: 2,
                                      }}
                                    >
                                      <LoadingButton
                                        disableElevation
                                        onClick={(e) => {
                                          handleSaveAnswer(e, question.uuid, index);
                                        }}
                                        loading={processing}
                                        variant="contained"
                                        color="success"
                                      >
                                        Save Answer
                                      </LoadingButton>
                                    </Box>
                                  </Box>
                                )}

                                {question.field_type == 8 && (
                                  <Box>
                                    <Address
                                      partnerUUID={requestData.partner_uuid}
                                      questionUUID={question.uuid}
                                      setAnswersData={setAnswersData}
                                      answersData={answersData}
                                      handleSaveAnswer={(e) =>
                                        handleSaveAnswer(e, question.uuid, index)
                                      }
                                      processing={processing}
                                      setProcessing={setProcessing}
                                      currentIndex={index}
                                    />
                                  </Box>
                                )}

                                {question.field_type == 9 && (
                                  <Box>
                                    <CountrySelect
                                      isEdd={question.uuid}
                                      value={answersData}
                                      setValue={setAnswersData}
                                    />
                                    <Box
                                      sx={{
                                        display: "flex",
                                        flexDirection: "row",
                                        justifyContent: "flex-end",
                                        pt: 2,
                                      }}
                                    >
                                      <LoadingButton
                                        disableElevation
                                        onClick={(e) => {
                                          handleSaveAnswer(e, question.uuid, index);
                                        }}
                                        loading={processing}
                                        variant="contained"
                                        color="success"
                                      >
                                        Save Answer
                                      </LoadingButton>
                                    </Box>
                                  </Box>
                                )}

                                {question.field_type == 10 && (
                                  <Box>
                                    <CountrySelect
                                      isEdd={question.uuid}
                                      value={answersData}
                                      setValue={setAnswersData}
                                      isMultiple={true}
                                    />
                                    <Box
                                      sx={{
                                        display: "flex",
                                        flexDirection: "row",
                                        justifyContent: "flex-end",
                                        pt: 2,
                                      }}
                                    >
                                      <LoadingButton
                                        disableElevation
                                        onClick={(e) => {
                                          handleSaveAnswer(e, question.uuid, index);
                                        }}
                                        loading={processing}
                                        variant="contained"
                                        color="success"
                                      >
                                        Save Answer
                                      </LoadingButton>
                                    </Box>
                                  </Box>
                                )}

                                {question.field_type == 11 && (
                                  <Box>
                                    <TrueFalse
                                      questionUUID={question.uuid}
                                      answersData={answersData}
                                      setAnswersData={setAnswersData}
                                    />
                                    <Box
                                      sx={{
                                        display: "flex",
                                        flexDirection: "row",
                                        justifyContent: "flex-end",
                                      }}
                                    >
                                      <LoadingButton
                                        disableElevation
                                        onClick={(e) => {
                                          handleSaveAnswer(e, question.uuid, index);
                                        }}
                                        loading={processing}
                                        variant="contained"
                                        color="success"
                                      >
                                        Save Answer
                                      </LoadingButton>
                                    </Box>
                                  </Box>
                                )}

                                {question.field_type == 12 && (
                                  <Box>
                                    <YesNo
                                      questionUUID={question.uuid}
                                      answersData={answersData}
                                      setAnswersData={setAnswersData}
                                    />
                                    <Box
                                      sx={{
                                        display: "flex",
                                        flexDirection: "row",
                                        justifyContent: "flex-end",
                                      }}
                                    >
                                      <LoadingButton
                                        disableElevation
                                        onClick={(e) => {
                                          handleSaveAnswer(e, question.uuid, index);
                                        }}
                                        loading={processing}
                                        variant="contained"
                                        color="success"
                                      >
                                        Save Answer
                                      </LoadingButton>
                                    </Box>
                                  </Box>
                                )}

                                {question.field_type == 13 && (
                                  <Box>
                                    <CurrencySelect
                                      isEdd={question.uuid}
                                      value={answersData}
                                      setValue={setAnswersData}
                                    />
                                    <Box
                                      sx={{
                                        display: "flex",
                                        flexDirection: "row",
                                        justifyContent: "flex-end",
                                        pt: 2,
                                      }}
                                    >
                                      <LoadingButton
                                        disableElevation
                                        onClick={(e) => {
                                          handleSaveAnswer(e, question.uuid, index);
                                        }}
                                        loading={processing}
                                        variant="contained"
                                        color="success"
                                      >
                                        Save Answer
                                      </LoadingButton>
                                    </Box>
                                  </Box>
                                )}

                                {question.field_type == 14 && (
                                  <Box>
                                    <CurrencySelect
                                      isEdd={question.uuid}
                                      value={answersData}
                                      setValue={setAnswersData}
                                      isMultiple={true}
                                    />
                                    <Box
                                      sx={{
                                        display: "flex",
                                        flexDirection: "row",
                                        justifyContent: "flex-end",
                                        pt: 2,
                                      }}
                                    >
                                      <LoadingButton
                                        disableElevation
                                        onClick={(e) => {
                                          handleSaveAnswer(e, question.uuid, index);
                                        }}
                                        loading={processing}
                                        variant="contained"
                                        color="success"
                                      >
                                        Save Answer
                                      </LoadingButton>
                                    </Box>
                                  </Box>
                                )}

                                {question.field_type == 15 && (
                                  <Box>
                                    <Money
                                      questionUUID={question.uuid}
                                      answersData={answersData}
                                      setAnswersData={setAnswersData}
                                    />
                                    <Box
                                      sx={{
                                        display: "flex",
                                        flexDirection: "row",
                                        justifyContent: "flex-end",
                                      }}
                                    >
                                      <LoadingButton
                                        disableElevation
                                        onClick={(e) => {
                                          handleSaveAnswer(e, question.uuid, index);
                                        }}
                                        loading={processing}
                                        variant="contained"
                                        color="success"
                                      >
                                        Save Answer
                                      </LoadingButton>
                                    </Box>
                                  </Box>
                                )}

                                {question.field_type == 16 && (
                                  <Box>
                                    <TextField
                                      fullWidth
                                      type="number"
                                      InputProps={{ step: "1" }}
                                      label={question.label}
                                      variant="outlined"
                                      name={question.uuid}
                                      value={
                                        answersData[question.uuid] ? answersData[question.uuid] : 0
                                      }
                                      onChange={(e) => handleChange(e)}
                                    />
                                    <Box
                                      sx={{
                                        display: "flex",
                                        flexDirection: "row",
                                        justifyContent: "flex-end",
                                        pt: 2,
                                      }}
                                    >
                                      <LoadingButton
                                        disableElevation
                                        onClick={(e) => {
                                          handleSaveAnswer(e, question.uuid, index);
                                        }}
                                        loading={processing}
                                        variant="contained"
                                        color="success"
                                      >
                                        Save Answer
                                      </LoadingButton>
                                    </Box>
                                  </Box>
                                )}

                                {question.field_type == 17 && (
                                  <Box>
                                    <TextField
                                      fullWidth
                                      type="number"
                                      InputProps={{ step: "0.00000001" }}
                                      label={question.label}
                                      variant="outlined"
                                      name={question.uuid}
                                      value={
                                        answersData[question.uuid] ? answersData[question.uuid] : 0
                                      }
                                      onChange={(e) => handleChange(e)}
                                    />
                                    <Box
                                      sx={{
                                        display: "flex",
                                        flexDirection: "row",
                                        justifyContent: "flex-end",
                                        pt: 2,
                                      }}
                                    >
                                      <LoadingButton
                                        disableElevation
                                        onClick={(e) => {
                                          handleSaveAnswer(e, question.uuid, index);
                                        }}
                                        loading={processing}
                                        variant="contained"
                                        color="success"
                                      >
                                        Save Answer
                                      </LoadingButton>
                                    </Box>
                                  </Box>
                                )}

                                {question.field_type == 18 && (
                                  <Box>
                                    <TextField
                                      fullWidth
                                      type="number"
                                      InputProps={{ step: "0.0001", endAdornment: <Percent /> }}
                                      label={question.label}
                                      variant="outlined"
                                      name={question.uuid}
                                      value={
                                        answersData[question.uuid] ? answersData[question.uuid] : 0
                                      }
                                      onChange={(e) => handleChange(e)}
                                    />
                                    <Box
                                      sx={{
                                        display: "flex",
                                        flexDirection: "row",
                                        justifyContent: "flex-end",
                                        pt: 2,
                                      }}
                                    >
                                      <LoadingButton
                                        disableElevation
                                        onClick={(e) => {
                                          handleSaveAnswer(e, question.uuid, index);
                                        }}
                                        loading={processing}
                                        variant="contained"
                                        color="success"
                                      >
                                        Save Answer
                                      </LoadingButton>
                                    </Box>
                                  </Box>
                                )}

                                {question.field_type == 19 && (
                                  <Box>
                                    <SingleFileUpload
                                      questionUUID={question.uuid}
                                      setAnswersData={setAnswersData}
                                      answersData={answersData}
                                      getRequestData={() => getHostedData(t)}
                                    />
                                  </Box>
                                )}

                                {question.field_type == 20 && (
                                  <Box>
                                    <MultipleFileUpload
                                      questionUUID={question.uuid}
                                      setAnswersData={setAnswersData}
                                      answersData={answersData}
                                      getRequestData={() => getHostedData(t)}
                                    />
                                  </Box>
                                )}

                                {question.field_type == 21 && (
                                  <Box>
                                    <CorporateEntity
                                      questionUUID={question.uuid}
                                      partnerUUID={requestData.partner_uuid}
                                      answersData={answersData}
                                      setAnswersData={setAnswersData}
                                    />
                                    <Box
                                      sx={{
                                        display: "flex",
                                        flexDirection: "row",
                                        justifyContent: "flex-end",
                                        pt: 2,
                                      }}
                                    >
                                      <LoadingButton
                                        disableElevation
                                        onClick={(e) => {
                                          handleSaveAnswer(e, question.uuid, index);
                                        }}
                                        loading={processing}
                                        variant="contained"
                                        color="success"
                                      >
                                        Save Answer
                                      </LoadingButton>
                                    </Box>
                                  </Box>
                                )}

                                {question.field_type == 22 && (
                                  <Box>
                                    <Individual
                                      questionUUID={question.uuid}
                                      answersData={answersData}
                                      setAnswersData={setAnswersData}
                                    />
                                    <Box
                                      sx={{
                                        display: "flex",
                                        flexDirection: "row",
                                        justifyContent: "flex-end",
                                        pt: 2,
                                      }}
                                    >
                                      <LoadingButton
                                        disableElevation
                                        onClick={(e) => {
                                          handleSaveAnswer(e, question.uuid, index);
                                        }}
                                        loading={processing}
                                        variant="contained"
                                        color="success"
                                      >
                                        Save Answer
                                      </LoadingButton>
                                    </Box>
                                  </Box>
                                )}

                                {question.field_type == 23 && (
                                  <Box>
                                    <MultipleCorporateEntities
                                      questionUUID={question.uuid}
                                      answersData={answersData}
                                      setAnswersData={setAnswersData}
                                      partnerUUID={requestData.partner_uuid}
                                      handleSaveAnswer={(e) =>
                                        handleSaveAnswer(e, question.uuid, index)
                                      }
                                    />
                                  </Box>
                                )}

                                {question.field_type == 24 && (
                                  <Box>
                                    <MultipleIndividuals
                                      questionUUID={question.uuid}
                                      questionIndex={index}
                                      answersData={answersData}
                                      setAnswersData={setAnswersData}
                                      handleSaveAnswer={(e) =>
                                        handleSaveAnswer(e, question.uuid, index)
                                      }
                                      processing={processing}
                                    />
                                  </Box>
                                )}
                              </Box>

                              <Divider />

                              <Box
                                sx={{
                                  display: "flex",
                                  flexDirection: "row",
                                  justifyContent: "space-between",
                                  py: 1,
                                  px: 1,
                                }}
                              >
                                <Box>
                                  <Button
                                    variant="outlined"
                                    color="primary"
                                    onClick={() =>
                                      (activeView.value = requestData.questions[index - 1].uuid)
                                    }
                                    disabled={index == 0}
                                  >
                                    Previous Question
                                  </Button>
                                </Box>
                                <Box>
                                  <Button
                                    variant="outlined"
                                    color="primary"
                                    onClick={() =>
                                      (activeView.value = requestData.questions[index + 1].uuid)
                                    }
                                    disabled={index == requestData.questions.length - 1}
                                  >
                                    Next Question
                                  </Button>
                                </Box>
                              </Box>
                            </Box>
                          ))}
                        </Box>
                      </>
                    ) : (
                      <Box>
                        <Lottie speed={2.0} options={defaultOptions} height={200} width={200} />
                      </Box>
                    )}
                  </Grid>
                </Grid>
              )
            ) : (
              <Grid container>
                <Grid
                  item
                  xs={12}
                  lg={5}
                  xl={4}
                  sx={{ mx: "auto", border: 1, borderColor: "divider" }}
                >
                  <Box sx={{ display: "flex", flexDirection: "column" }}>
                    <Box sx={{ width: "100%", p: 2, display: "flex", justifyContent: "center" }}>
                      <Box
                        component="img"
                        src={partnerLogo ? partnerLogo : "/cleverli-logo-black.svg"}
                        alt="logo"
                        sx={{ maxWidth: 400, maxHeight: 300 }}
                      />
                    </Box>
                    {partnerLogo && (
                      <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                        <Box sx={{ py: 1, px: 2 }}>
                          <Typography
                            variant="subtitle2"
                            sx={{
                              fontSize: "13px",
                              textAlign: "end",
                              fontStyle: "italic",
                              mr: "5px",
                              mb: 0,
                            }}
                          >
                            Powered by
                          </Typography>
                          <Box sx={{ display: "flex", justifyContent: "flex-end", mt: "-3px" }}>
                            <img
                              src="/cleverli-logo-black.svg"
                              alt="logo"
                              style={{ width: "150px" }}
                            />
                          </Box>
                        </Box>
                      </Box>
                    )}
                  </Box>

                  <Divider />

                  <Box
                    sx={{
                      p: 2,
                      backgroundColor: "#0b0b0b",
                      color: "#ffffff",
                      textTransform: "uppercase",
                    }}
                  >
                    <Typography variant="h4" align="center">
                      Data notice
                    </Typography>
                  </Box>

                  <Divider />
                  <Box sx={{ p: 2, textAlign: "center" }}>
                    <Typography variant="body1" gutterBottom>
                      You have been invited to provide some information by {partnerName}.
                    </Typography>
                    <Typography variant="body1">
                      Please read and accept the data notice using the options below
                    </Typography>
                  </Box>

                  <Box
                    sx={{
                      maxHeight: "350px",
                      minHeight: "300px",
                      p: 2,
                      overflowY: "scroll",
                      borderTop: 1,
                      borderBottom: 1,
                      borderColor: "divider",
                      display: "flex",
                      flexDirection: "column",
                      gap: 1,
                    }}
                    className=""
                  >
                    <Box>
                      <Typography variant="subtitle2">Introduction</Typography>
                      <Typography sx={{ fontSize: "13px" }}>
                        Thank you for choosing {partnerName} and Cleverli Solutions Limited. To
                        comply with regulatory requirements and to safeguard your account, we are
                        required to verify your identity through our Know Your Customer (KYC)
                        process. This involves collecting, processing, and storing personal
                        information you provide to us.
                      </Typography>
                    </Box>

                    <Box>
                      <Typography variant="subtitle2">
                        What personal data is processed and how is it used?
                      </Typography>

                      <Typography sx={{ fontSize: "13px" }}>
                        At Cleverli Solutions Limited, we conduct due diligence checks, including
                        background checks that cover politically exposed persons, sanctions and
                        adverse media as well as identity and document verification, to help
                        mitigate potential compliance risks.
                      </Typography>

                      <Typography sx={{ fontSize: "13px", mt: 1 }}>
                        The checks that we undertake mean that we could process the following
                        aspects of your personal data depending on what our partner has requested us
                        to do. The data that we use may be provided by you or our partner or a
                        combination of both. It is important that you are also familiar with our
                        partners Privacy statement which they are responsible for providing to you.
                      </Typography>

                      <Box sx={{ ml: 2 }}>
                        <List sx={{ listStyleType: "disc", pl: 2, fontSize: "13px" }}>
                          <ListItem sx={{ display: "list-item" }}>
                            <span variant="subtitle2">Name data</span> – your first, middle and last
                            names as well as any previous names and any aliases you regularly use;
                          </ListItem>
                          <ListItem sx={{ display: "list-item" }}>
                            <span>Birth data</span> – your date of birth, your country of birth,
                            your nationality and any other nationalities and citizenships that you
                            have;
                          </ListItem>
                          <ListItem sx={{ display: "list-item" }}>
                            <span variant="subtitle2">Contact data</span> – your telephone numbers
                            and email address;
                          </ListItem>
                          <ListItem sx={{ display: "list-item" }}>
                            <span variant="subtitle2">Residential data</span> – your residential
                            address and previous addresses for the number of years that our customer
                            requires;
                          </ListItem>
                          <ListItem sx={{ display: "list-item" }}>
                            <span variant="subtitle2">Financial data</span> – your employment
                            status, how much you earn as well as proof of your income;
                          </ListItem>
                          <ListItem sx={{ display: "list-item" }}>
                            <span variant="subtitle2">Identity data</span> – an image of your
                            identity document showing your picture, the issue and expiry dates, the
                            official reference number, the type of document it is and the country of
                            issue;
                          </ListItem>
                          <ListItem sx={{ display: "list-item" }}>
                            <span variant="subtitle2">Address verification data</span> – an image of
                            a document showing your residential address, the type of document it is
                            and the date of issue; and{" "}
                          </ListItem>
                          <ListItem sx={{ display: "list-item" }}>
                            <span variant="subtitle2">Video data</span> – a very short recording of
                            you which you initiate and where you follow automated instructions which
                            may involve you speaking during the recording.
                          </ListItem>
                        </List>
                      </Box>
                    </Box>

                    <Typography variant="subtitle2">
                      Through a combination of AI and review by Cleverli Solutions Limited analysts,
                      the above data is used for the following purposes:{" "}
                    </Typography>

                    <Box sx={{ ml: 2 }}>
                      <List sx={{ listStyleType: "disc", pl: 2, fontSize: "13px" }}>
                        <ListItem sx={{ display: "list-item" }}>
                          To undertake background checks on you which include checking for adverse
                          media against publicly available sources, undertaking sanctions screening
                          against a range of sanctions lists, assessing whether you are a
                          politically exposed person and assisting our partner to understand whether
                          there are any other fitness and probity issues which they may wish to
                          consider;{" "}
                        </ListItem>
                        <ListItem sx={{ display: "list-item" }}>
                          To undertake social media and internet checks on you which means that we
                          will analyse your social media and other online presence to identify
                          potential risks;
                        </ListItem>
                        <ListItem sx={{ display: "list-item" }}>
                          To carry out proof of life checks to validate your identity;{" "}
                        </ListItem>
                        <ListItem sx={{ display: "list-item" }}>
                          To ensure the identity and address verification documents you have
                          provided are authentic and legitimate; and
                        </ListItem>
                        <ListItem sx={{ display: "list-item" }}>
                          To help our partner stay informed if there is any change in your status or
                          reputation in the media.
                        </ListItem>
                      </List>
                    </Box>

                    <Typography sx={{ fontSize: "13px" }}>
                      When our checks have been completed, our partner will receive a report with
                      all of the personal data that has been provided for the purposes of our
                      checks. The report will also include the results of any screening that we have
                      carried out as well as our reasons for discounting any results from the
                      screening, where applicable. The report will include a link to the video data
                      and a snapshot of the video as a still image of you.
                    </Typography>

                    <Typography variant="subtitle2" sx={{ mt: 1 }}>
                      Where is the data stored?
                    </Typography>

                    <Typography sx={{ fontSize: "13px" }}>
                      All of the data that we process about you is held in a {partnerDataLocation}{" "}
                      data centre. The data that we hold is all of the data that is input by you or
                      our partner for the purposes of our screening as well as the results of our
                      analysis including the video of you and the report we issue to our partner.
                    </Typography>

                    <Typography sx={{ mt: 1 }} variant="subtitle2">
                      Who can see your data?
                    </Typography>

                    <Typography sx={{ fontSize: "13px" }}>
                      Our partner will be able to see all of the data that you input as well as the
                      resultant report.
                    </Typography>
                    <Typography sx={{ fontSize: "13px" }}>
                      Our analysts will be able to see your personal data for the purposes of
                      undertaking the screening. Access to your data by the analysts is subject to
                      access logs and any access to your identification documentation images and
                      your video recording are logged separately.
                    </Typography>
                    <Typography sx={{ fontSize: "13px" }}>
                      There is the potential that your data may be seen by members of our IT team
                      who are responsible for the smooth running of our systems if there is an issue
                      that needs to be resolved.
                    </Typography>
                    <Typography sx={{ fontSize: "13px", mt: 1 }}>
                      We may also have to share your data with a law enforcement agency if we are
                      required to do so.
                    </Typography>

                    <Typography variant="subtitle2" sx={{ mt: 1 }}>
                      Do we use your data for marketing purposes?
                    </Typography>
                    <Typography sx={{ fontSize: "13px" }}>
                      We will not use your data for marketing purposes nor will we provide your data
                      to any third party so that they can market their products or services to you.
                    </Typography>

                    <Typography variant="subtitle2">How long do we keep your data?</Typography>
                    <Typography sx={{ fontSize: "13px" }}>
                      We will maintain records and data to comply with our statutory, legal and
                      regulatory responsibilities as well as what we deem to be reasonable practice
                      where there is no record keeping period set down in law or other rules. We
                      will destroy data in line with these retention periods and our commencement
                      date for any retention period will normally be the date on which the report
                      has been made available to our partner or the date on which our monitoring of
                      the customer ceases as requested by our partner.
                    </Typography>

                    <Typography sx={{ fontSize: "13px" }}>
                      If you have specific queries in relation to retention periods, please email{" "}
                      <a
                        className="hover:text-primary transition-all duration-300"
                        href="mailto:dpo@cleverli.com"
                      >
                        dpo@cleverli.com
                      </a>{" "}
                      for more information.
                    </Typography>

                    <Typography variant="subtitle2">What are your rights?</Typography>
                    <Typography sx={{ fontSize: "13px" }}>
                      If you wish to see the data processed about you by Cleverli Solutions Limited,
                      please contact the Data Protection Officer via email at{" "}
                      <a href="mailto:dpo@cleverli.com">dpo@cleverli.com</a>.
                    </Typography>

                    <Typography variant="subtitle2" sx={{ fontStyle: "italic" }}>
                      Isle of Man residents
                    </Typography>
                    <Typography sx={{ fontSize: "13px" }}>
                      You have additional rights under the Isle of Man data protection legislation
                      such as the right to rectification of incomplete data and the right of erasure
                      of data. You also have the right to receive the personal data you provide to
                      us in a structured, commonly used and machine readable format and have the
                      right to transmit this data to another controller, without hindrance from us.
                    </Typography>

                    <Typography variant="subtitle2">
                      If you wish to exercise any of these rights, please contact the Data
                      Protection Officer via email at{" "}
                      <a
                        className="hover:text-primary transition-all duration-300"
                        href="mailto:dpo@cleverli.com"
                      >
                        dpo@cleverli.com
                      </a>
                      .
                    </Typography>
                    <Typography sx={{ fontSize: "13px" }}>
                      If you wish to make a complaint about how we have processed your data, please
                      contact the Data Protection Officer via email at{" "}
                      <a
                        className="hover:text-primary transition-all duration-300"
                        href="mailto:dpo@cleverli.com"
                      >
                        dpo@cleverli.com
                      </a>
                      .
                    </Typography>
                    <Typography sx={{ fontSize: "13px" }}>
                      You can also contact the respective Information Commissioner on:
                    </Typography>

                    <Box sx={{ ml: 2 }}>
                      <List sx={{ listStyleType: "disc", pl: 2, fontSize: "13px" }}>
                        <ListItem sx={{ display: "list-item" }}>
                          <Typography display="inline" variant="subtitle2">
                            United Kingdom:
                          </Typography>
                          <Typography display="inline" sx={{ fontSize: "13px", ml: 1 }}>
                            <a href="https://ico.org.uk" target="_blank">
                              ico.org.uk
                            </a>
                          </Typography>
                        </ListItem>
                        <ListItem sx={{ display: "list-item" }}>
                          <Typography display="inline" variant="subtitle2">
                            Isle of Man:
                          </Typography>
                          <Typography display="inline" sx={{ fontSize: "13px", ml: 1 }}>
                            01624 693260 or by emailing{" "}
                            <a href="mailto:ask@inforights.im">ask@inforights.im</a>
                          </Typography>
                        </ListItem>
                        <ListItem sx={{ display: "list-item" }}>
                          <Typography display="inline" variant="subtitle2">
                            Australia:
                          </Typography>
                          <Typography display="inline" sx={{ fontSize: "13px", ml: 1 }}>
                            <a href="https://oaic.gov.au" target="_blank">
                              oaic.gov.au
                            </a>
                          </Typography>
                        </ListItem>
                      </List>
                    </Box>

                    <Typography sx={{ fontSize: "13px", textAlign: "end" }}>
                      Last updated: 05/02/2024
                    </Typography>
                  </Box>

                  <Box>
                    <Box sx={{ p: 2, display: "flex", justifyContent: "space-between" }}>
                      <LoadingButton
                        disableElevation
                        loading={processing}
                        onClick={(e) => handleRejectDataNotice(e)}
                        variant="contained"
                        color="danger"
                      >
                        Decline
                      </LoadingButton>
                      <LoadingButton
                        disableElevation
                        loading={processing}
                        onClick={(e) => handleAcceptDataNotice(e)}
                        variant="contained"
                        color="success"
                      >
                        Accept
                      </LoadingButton>
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            )}
          </Box>
        ) : (
          <Box>
            <Lottie speed={2.0} options={defaultOptions} height={200} width={200} />
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default EddHosted;
