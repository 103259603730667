import { Modal as MUIModal, Paper } from "@mui/material";
import PropTypes from "prop-types";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "50%",
  p: 4,
  overflow: "auto",
};

export function Modal({ open, onClose, children, customStyle }) {
  return (
    <MUIModal open={open} onClose={onClose}>
      <Paper style={{ ...style, ...customStyle }}>{children}</Paper>
    </MUIModal>
  );
}

Modal.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  children: PropTypes.node,
  customStyle: PropTypes.object,
};
