import { Box, Button, Stack, Typography } from "@mui/material";
import PropTypes from "prop-types";
import { TaskFilesList } from "./task-files-list";
import { RenderWhen } from "../../../../../components/render-when";
import { ConfirmationModal } from "./confirmation-modal";
import { htmlParser } from "../../../../../utils/sring";

export function TaskDescription({ taskData, clientUUID, getTaskDataQuery, goToTaskList }) {
  // const { taskFilesList } = useGetTaskFiles(taskData?.task_uuid);

  return (
    <Stack direction="column">
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        sx={{ p: 2, borderBottom: 1, borderColor: "divider" }}
      >
        <Typography variant="h5">{taskData?.task_name}</Typography>
        <Button variant="outlined" onClick={goToTaskList}>
          Go To Task list
        </Button>
      </Stack>
      <Box className="quill-rest" sx={{ p: 2 }}>
        {htmlParser(taskData?.description)}
      </Box>
      {taskData?.steps?.map((step) => {
        return (
          <Stack
            key={step?.step_uuid}
            direction="column"
            sx={{
              m: 1,
              border: 1,
              borderStyle: "double",
              borderColor: "divider",
            }}
          >
            <Stack direction="row" justifyContent="space-between" alignItems="stretch" height={56}>
              <Typography
                variant="body1"
                fontWeight={600}
                sx={{
                  bgcolor: "divider",
                  flex: 1,
                  display: "flex",
                  alignItems: "center",
                  px: 1,
                }}
              >
                {step?.label}
              </Typography>
              <ConfirmationModal
                getTaskDataQuery={getTaskDataQuery}
                taskData={taskData}
                step={step}
                taskUUID={taskData?.task_uuid}
              />
            </Stack>
            <Stack direction="row" sx={{ px: 1 }}>
              {htmlParser(step?.description)}
              <Box width={56} height={56} />
            </Stack>
            <RenderWhen when={step?.file_required}>
              <Stack
                direction="row"
                alignItems="center"
                sx={{
                  p: 1,
                  borderBottom: 1,
                  borderTop: 1,
                  borderColor: "divider",
                }}
              >
                <TaskFilesList step={step} />
              </Stack>
              <Box sx={{ px: 1 }}>{htmlParser(step?.file_description)}</Box>
            </RenderWhen>
          </Stack>
        );
      })}
    </Stack>
  );
}

TaskDescription.propTypes = {
  taskData: PropTypes.object,
  clientUUID: PropTypes.string,
  getTaskDataQuery: PropTypes.func,
  goToTaskList: PropTypes.func,
};
