import { React, useState, useEffect } from "react";

import { ToggleButton, ToggleButtonGroup } from "@mui/material";

const TrueFalse = ({ questionUUID, answersData, setAnswersData }) => {
  const [answer, setAnswer] = useState(null);

  useEffect(() => {
    if (answer !== null) {
      setAnswersData({
        ...answersData,
        [questionUUID]: answer,
      });
    }
  }, [answer]);

  useEffect(() => {
    if (answersData[questionUUID] !== undefined) {
      if (answersData[questionUUID] === "true") {
        setAnswer(true);
      } else {
        setAnswer(false);
      }
    }
  }, []);

  return (
    <ToggleButtonGroup
      color="primary"
      value={answer}
      exclusive
      name={questionUUID}
      onChange={(e, newValue) => {
        setAnswer(newValue);
      }}
      aria-label="True or false?"
    >
      <ToggleButton value={true}>True</ToggleButton>
      <ToggleButton value={false}>False</ToggleButton>
    </ToggleButtonGroup>
  );
};

export default TrueFalse;
