import { React, useEffect, useState } from "react";

import authHeader from "../../../../../../../api/authHeader";

import HandleError from "../../../../../../../components/functions/HandleError";
import HandleToast from "../../../../../../../components/functions/HandleToast";

import Lottie from "react-lottie";
import loadingLottie from "../../../../../../../components/lotties/loading-dots.json";

import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

import {
  Box,
  Divider,
  Grid,
  TextField,
  Typography,
  ToggleButtonGroup,
  ToggleButton,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
  Chip,
  Collapse,
} from "@mui/material";
import { Check, Delete, DoDisturb } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import NoResults from "../../../../../../../components/functions/NoResults";

const ManageTemplate = ({ partner, partnerUser, partnerData, activeTemplate, setManageTemplate, getTemplates, setActiveTemplate }) => {
  const useAuthHeader = authHeader();

  const handleError = HandleError();
  const displayToast = HandleToast();

  const [isLoaded, setIsLoaded] = useState(false);
  const [processing, setProcessing] = useState(false);

  const [templateName, setTemplateName] = useState("");
  const [templateDescription, setTemplateDescription] = useState("");
  const [templateType, setTemplateType] = useState(1);
  const [templateActive, setTemplateActive] = useState(false);

  const [stepsData, setStepsData] = useState([]);

  const [manageSteps, setManageSteps] = useState(false);

  const [stepLabel, setStepLabel] = useState("");
  const [stepDescription, setStepDescription] = useState("");
  const [requireConfirmation, setRequireConfirmation] = useState(false);
  const [requireFile, setRequireFile] = useState(false);
  const [fileDescription, setFileDescription] = useState("");
  const [stepActive, setStepActive] = useState(false);

  const [activeStep, setActiveStep] = useState("");
  const [deleteStepDialog, setDeleteStepDialog] = useState(false);

  const [deleteTemplateDialog, setDeleteTemplateDialog] = useState(false);

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: loadingLottie,
  };

  const getTemplateData = async (uuid) => {
    try {
      const response = await partner.post(
        `/v1/client/tasks/templates`,
        JSON.stringify({
          partner_uuid: partnerUser.value.partner_uuid,
          uuid: uuid,
        }),
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Basic " + useAuthHeader.base64encodedData,
            service: "partners",
          },

          //withCredentials: true,
        }
      );

      setTemplateName(response.data.name);
      setTemplateDescription(response.data.description);
      setTemplateType(response.data.template_type);
      setTemplateActive(response.data.active);
      setStepsData(response.data.workflow_steps);

      setIsLoaded(true);
    } catch (err) {
      console.log(err);
      handleError(err);
    }
  };

  const handleUpdateTemplate = async (e) => {
    e.preventDefault();
    setProcessing(true);

    try {
      const response = await partner.post(
        `/v1/client/tasks/templates/update`,
        JSON.stringify({
          partner_uuid: partnerUser.value.partner_uuid,
          template_uuid: activeTemplate,
          name: templateName,
          template_type: 1,
          description: templateDescription,
          active: templateActive,
        }),
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Basic " + useAuthHeader.base64encodedData,
            service: "partners",
          },

          //withCredentials: true,
        }
      );

      getTemplates();

      displayToast("Template updated", () => {
        setProcessing(false);
        setManageTemplate(false);
      });
    } catch (err) {
      handleError(err);
      setProcessing(false);
    }
  };

  const handleDeleteTemplate = async (e) => {
    e.preventDefault();
    setProcessing(true);

    try {
      const response = await partner.post(
        `/v1/client/tasks/templates/delete`,
        JSON.stringify({
          partner_uuid: partnerUser.value.partner_uuid,
          template_uuid: activeTemplate,
        }),
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Basic " + useAuthHeader.base64encodedData,
            service: "partners",
          },

          //withCredentials: true,
        }
      );

      getTemplates();

      displayToast("Template deleted", () => {
        setActiveTemplate("");
        setProcessing(false);
        setManageTemplate(false);
      });
    } catch (err) {
      handleError(err);
      setProcessing(false);
    }
  };

  const getStepData = async (uuid) => {
    try {
      const response = await partner.post(
        `/v1/client/tasks/templates/workflow/data`,
        JSON.stringify({
          uuid: uuid,
        }),
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Basic " + useAuthHeader.base64encodedData,
            service: "partners",
          },

          //withCredentials: true,
        }
      );

      setActiveStep(uuid);
      setStepLabel(response.data.label);
      setStepDescription(response.data.description);
      setRequireConfirmation(response.data.requires_confirmation);
      setRequireFile(response.data.requires_file);
      setFileDescription(response.data.file_description);
      setStepActive(response.data.active);
      setManageSteps(true);
    } catch (err) {
      console.log(err);
      handleError(err);
    }
  };

  const handleUpdateStep = async (e) => {
    e.preventDefault();
    setProcessing(true);

    try {
      const response = await partner.post(
        `/v1/client/tasks/templates/workflow/update`,
        JSON.stringify({
          step_uuid: activeStep,
          label: stepLabel,
          require_confirmation: requireConfirmation,
          require_file: requireFile,
          file_description: fileDescription,
          description: stepDescription,
          active: stepActive,
        }),
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Basic " + useAuthHeader.base64encodedData,
            service: "partners",
          },

          //withCredentials: true,
        }
      );

      getTemplateData(activeTemplate);

      displayToast("Workflow step updated", () => {
        setProcessing(false);
        setManageSteps(false);
      });
    } catch (err) {
      handleError(err);
      setProcessing(false);
    }
  };

  const handleCreateStep = async (e) => {
    e.preventDefault();
    setProcessing(true);

    try {
      const response = await partner.post(
        `/v1/client/tasks/templates/workflow/add`,
        JSON.stringify({
          template_uuid: activeTemplate,
          label: stepLabel,
          require_confirmation: requireConfirmation,
          require_file: requireFile,
          file_description: fileDescription,
          description: stepDescription,
        }),
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Basic " + useAuthHeader.base64encodedData,
            service: "partners",
          },

          //withCredentials: true,
        }
      );

      getTemplateData(activeTemplate);

      displayToast("Workflow step created", () => {
        setProcessing(false);
        setManageSteps(false);
      });
    } catch (err) {
      handleError(err);
      setProcessing(false);
    }
  };

  const handleDeleteStep = async (e) => {
    e.preventDefault();
    setProcessing(true);

    try {
      const response = await partner.post(
        `/v1/client/tasks/templates/workflow/delete`,
        JSON.stringify({
          step_uuid: activeStep,
        }),
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Basic " + useAuthHeader.base64encodedData,
            service: "partners",
          },

          //withCredentials: true,
        }
      );

      getTemplateData(activeTemplate);
      setDeleteStepDialog(false);

      displayToast("Workflow step deleted", () => {
        setProcessing(false);
        setManageSteps(false);
      });
    } catch (err) {
      handleError(err);
      setProcessing(false);
    }
  };

  useEffect(() => {
    getTemplateData(activeTemplate);
  }, []);

  return (
    <Box>
      {isLoaded ? (
        <Box sx={{ p: 2, display: "flex", flexDirection: "column", gap: 2 }}>
          <Box sx={{ display: "flex", justifyContent: "space-between", border: 1, borderColor: "divider" }}>
            <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center", p: 2 }}>
              <Typography variant="subtitle2">Template type</Typography>
            </Box>
            <Box sx={{ p: 2 }}>
              <ToggleButtonGroup
                color="primary"
                value={templateType}
                exclusive
                onChange={(e, newValue) => {
                  if (newValue !== null) setTemplateType(newValue);
                }}
                aria-label="Template type"
              >
                <ToggleButton value={1} disabled={true}>
                  Freeform
                </ToggleButton>
                <ToggleButton value={2} disabled={true}>
                  Workflow
                </ToggleButton>
              </ToggleButtonGroup>
            </Box>
          </Box>

          {!manageSteps && (
            <Box>
              <Box sx={{ border: 1, borderColor: "divider" }}>
                <Box sx={{ p: 2 }}>
                  <Typography variant="subtitle2">What is the name of the template?</Typography>
                </Box>

                <Divider />
                <Box sx={{ p: 2, display: "flex", flexDirection: "column", gap: 2 }}>
                  <TextField
                    fullWidth
                    id="outlined-basic"
                    label="Template name"
                    variant="outlined"
                    value={templateName}
                    onChange={(e) => setTemplateName(e.target.value)}
                  />
                </Box>
              </Box>

              <Box sx={{ border: 1, borderTop: 0, borderColor: "divider" }}>
                <Box sx={{ p: 2 }}>
                  <Typography variant="subtitle2">Provide a description {templateType == 1 && "and instructions "}for the task</Typography>
                </Box>

                <Divider />
                <ReactQuill theme="snow" value={templateDescription} onChange={setTemplateDescription} style={{ minHeight: "300px" }} />
              </Box>
            </Box>
          )}

          {templateType == 2 && (
            <Box sx={{ border: 1, borderColor: "divider" }}>
              <Box sx={{ p: 2, display: "flex", justifyContent: "space-between" }}>
                <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
                  <Typography variant="subtitle2">Workflow steps</Typography>
                </Box>
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={() => {
                    setStepLabel("");
                    setStepDescription("");
                    setRequireConfirmation(false);
                    setRequireFile(false);
                    setFileDescription("");
                    setActiveStep("");
                    setManageSteps(!manageSteps);
                  }}
                >
                  {manageSteps ? "Close" : "Add step"}
                </Button>
              </Box>

              <Divider />

              {manageSteps ? (
                <Box>
                  <Box sx={{}}>
                    <Box sx={{ p: 2 }}>
                      <Typography variant="subtitle2">Provide a label for the step</Typography>
                    </Box>
                    <Divider />

                    <Box sx={{ p: 2, display: "flex", flexDirection: "column", gap: 2 }}>
                      <TextField
                        fullWidth
                        id="outlined-basic"
                        label="Step label"
                        variant="outlined"
                        value={stepLabel}
                        onChange={(e) => setStepLabel(e.target.value)}
                      />
                    </Box>
                    <Divider />

                    <Box sx={{}}>
                      <Box sx={{ p: 2 }}>
                        <Typography variant="subtitle2">Provide instructions for the step</Typography>
                      </Box>

                      <Divider />
                      <ReactQuill theme="snow" value={stepDescription} onChange={setStepDescription} style={{ minHeight: "300px" }} />
                    </Box>

                    <Divider />

                    <Box sx={{ display: "flex", justifyContent: "space-between", p: 2 }}>
                      <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
                        <Typography variant="subtitle2">Require confirmation</Typography>
                      </Box>
                      <Box sx={{ display: "flex", flexDirection: "column", gap: 2, width: "50%" }}>
                        <Box sx={{ display: "flex", justifyContent: "flex-end", gap: 1 }}>
                          <ToggleButtonGroup
                            size="small"
                            color={requireConfirmation ? "primary" : "danger"}
                            value={requireConfirmation}
                            exclusive
                            onChange={(e, newValue) => {
                              if (newValue !== null) setRequireConfirmation(newValue);
                            }}
                            aria-label="Require confirmation"
                          >
                            <ToggleButton value={true}>Enabled</ToggleButton>
                            <ToggleButton value={false}>Disabled</ToggleButton>
                          </ToggleButtonGroup>
                        </Box>
                      </Box>
                    </Box>

                    <Divider />

                    <Box sx={{ display: "flex", justifyContent: "space-between", p: 2 }}>
                      <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
                        <Typography variant="subtitle2">Require file upload</Typography>
                      </Box>
                      <Box sx={{ display: "flex", flexDirection: "column", gap: 2, width: "50%" }}>
                        <Box sx={{ display: "flex", justifyContent: "flex-end", gap: 1 }}>
                          <ToggleButtonGroup
                            size="small"
                            color={requireFile ? "primary" : "danger"}
                            value={requireFile}
                            exclusive
                            onChange={(e, newValue) => {
                              if (newValue !== null) setRequireFile(newValue);
                            }}
                            aria-label="Require confirmation"
                          >
                            <ToggleButton value={true}>Enabled</ToggleButton>
                            <ToggleButton value={false}>Disabled</ToggleButton>
                          </ToggleButtonGroup>
                        </Box>
                      </Box>
                    </Box>

                    <Collapse in={requireFile} exit={!requireFile}>
                      <Box sx={{ borderTop: 1, borderColor: "divider" }}>
                        <ReactQuill theme="snow" value={fileDescription} onChange={setFileDescription} style={{ minHeight: "300px" }} />
                      </Box>
                    </Collapse>

                    {activeStep && (
                      <>
                        <Divider />
                        <Box sx={{ display: "flex", justifyContent: "space-between", p: 2 }}>
                          <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
                            <Typography variant="subtitle2">Enable or disable step</Typography>
                          </Box>
                          <Box sx={{ display: "flex", flexDirection: "column", gap: 2, width: "50%" }}>
                            <Box sx={{ display: "flex", justifyContent: "flex-end", gap: 1 }}>
                              <ToggleButtonGroup
                                size="small"
                                color={stepActive ? "primary" : "danger"}
                                value={stepActive}
                                exclusive
                                onChange={(e, newValue) => {
                                  if (newValue !== null) setStepActive(newValue);
                                }}
                                aria-label="Step status"
                              >
                                <ToggleButton value={true}>Enabled</ToggleButton>
                                <ToggleButton value={false}>Disabled</ToggleButton>
                              </ToggleButtonGroup>
                            </Box>
                          </Box>
                        </Box>
                      </>
                    )}

                    <Divider />

                    <Box sx={{ display: "flex", justifyContent: "space-between", p: 2 }}>
                      <Box>
                        {activeStep && (
                          <Button variant="outlined" color="danger" startIcon={<Delete />} onClick={() => setDeleteStepDialog(true)}>
                            Delete step
                          </Button>
                        )}
                      </Box>
                      <LoadingButton
                        disableElevation
                        variant="contained"
                        color="success"
                        loading={processing}
                        onClick={(e) => {
                          activeStep ? handleUpdateStep(e) : handleCreateStep(e);
                        }}
                      >
                        {activeStep ? "Save changes" : "Create step"}
                      </LoadingButton>
                    </Box>
                  </Box>
                </Box>
              ) : stepsData?.length > 0 ? (
                <Box>
                  {stepsData.map((step, index) => (
                    <Box
                      key={index}
                      sx={{ p: 2, display: "flex", justifyContent: "space-between", borderTop: index > 0 ? 1 : 0, borderColor: "divider" }}
                    >
                      <Box onClick={() => getStepData(step.uuid)} sx={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
                        <Typography className="link" variant="subtitle2">
                          {step.label}
                        </Typography>
                      </Box>
                      <Box sx={{ display: "flex", gap: 1 }}>
                        {step.requires_confirmation && <Chip className="square-chip" label="Requires confirmation" color="success" />}
                        {step.requires_file && <Chip className="square-chip" label="Requires file upload" color="success" />}
                        {!step.active && <Chip label="Disabled" className="square-chip" color="error" size="small" />}
                      </Box>
                    </Box>
                  ))}
                </Box>
              ) : (
                <NoResults message="Add the first step of the task" />
              )}
            </Box>
          )}

          {!manageSteps && (
            <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
              <Box sx={{ display: "flex", justifyContent: "space-between", border: 1, borderColor: "divider", p: 2 }}>
                <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
                  <Typography variant="h6">Template status</Typography>
                </Box>
                <Box sx={{ display: "flex", flexDirection: "column", gap: 2, width: "50%" }}>
                  <Box sx={{ display: "flex", justifyContent: "flex-end", gap: 1 }}>
                    <ToggleButtonGroup
                      size="small"
                      color={templateActive ? "primary" : "danger"}
                      value={templateActive}
                      exclusive
                      onChange={(e, newValue) => {
                        if (newValue !== null) setTemplateActive(newValue);
                      }}
                      aria-label="Enable or disable the template"
                    >
                      <ToggleButton value={true}>Enabled</ToggleButton>
                      <ToggleButton value={false}>Disabled</ToggleButton>
                    </ToggleButtonGroup>
                  </Box>
                </Box>
              </Box>
              <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                <Box>
                  {activeTemplate && (
                    <Button variant="outlined" color="danger" startIcon={<Delete />} onClick={() => setDeleteTemplateDialog(true)}>
                      Delete template
                    </Button>
                  )}
                </Box>
                <LoadingButton
                  disableElevation
                  variant="contained"
                  color="success"
                  loading={processing}
                  onClick={(e) => {
                    handleUpdateTemplate(e);
                  }}
                >
                  Save changes
                </LoadingButton>
              </Box>
            </Box>
          )}
        </Box>
      ) : (
        <Box>
          <Lottie speed={2.0} options={defaultOptions} height={200} width={200} />
        </Box>
      )}

      <Dialog
        open={deleteTemplateDialog}
        onClose={() => setDeleteTemplateDialog(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Are you sure?</DialogTitle>
        <DialogContent>
          <Box>
            <DialogContentText sx={{ mb: 1 }} id="alert-dialog-description">
              Are you sure you want to delete this template?
            </DialogContentText>
          </Box>
        </DialogContent>
        <DialogActions>
          <LoadingButton disableElevation loading={processing} onClick={() => setDeleteTemplateDialog(false)} color="primary">
            Cancel
          </LoadingButton>

          <LoadingButton
            disableElevation
            sx={{ color: "#ffffff" }}
            loading={processing}
            onClick={(e) => {
              handleDeleteTemplate(e);
            }}
            variant="contained"
            color="danger"
            autoFocus
          >
            Confirm
          </LoadingButton>
        </DialogActions>
      </Dialog>

      <Dialog
        open={deleteStepDialog}
        onClose={() => setDeleteStepDialog(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Are you sure?</DialogTitle>
        <DialogContent>
          <Box>
            <DialogContentText sx={{ mb: 1 }} id="alert-dialog-description">
              Are you sure you want to delete this workflow step?
            </DialogContentText>
          </Box>
        </DialogContent>
        <DialogActions>
          <LoadingButton disableElevation loading={processing} onClick={() => setDeleteStepDialog(false)} color="primary">
            Cancel
          </LoadingButton>

          <LoadingButton
            disableElevation
            sx={{ color: "#ffffff" }}
            loading={processing}
            onClick={(e) => {
              handleDeleteStep(e);
            }}
            variant="contained"
            color="danger"
            autoFocus
          >
            Confirm
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default ManageTemplate;
