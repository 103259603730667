import { React, useCallback } from "react";

import { useDropzone } from "react-dropzone";

import { Typography, Box } from "@mui/material";

const MultipleFileUpload = ({ setFile }) => {
  const onDrop = useCallback((acceptedFiles) => {
    acceptedFiles.forEach((file) => {
      setFile(file);
    });
  }, []);

  const { acceptedFiles, getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: {
      "image/png": [".png"],
      "image/jpeg": [".jpg", ".jpeg"],
      "application/pdf": [".pdf"],
    },
    maxFiles: 10,
  });

  return (
    <>
      <Box
        className="file-drop"
        sx={{
          border: 2,
          borderColor: "divider",
          borderStyle: "dashed",
          cursor: "pointer",
          p: 5,
          my: 2,

          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
        }}
        {...getRootProps()}
      >
        <input {...getInputProps()} />
        {isDragActive ? (
          <Typography align="center">Drop the file here ...</Typography>
        ) : (
          <Typography align="center">Drop a file here, or click to select files</Typography>
        )}
      </Box>
    </>
  );
};

export default MultipleFileUpload;
