import React from "react";

import moment from "moment";
import DataTable from "react-data-table-component";

import { Box, Link, Chip } from "@mui/material";
import NoResults from "../../../../components/functions/NoResults";

const ListRequests = ({ tableData, setActiveRequest, setViewRequest }) => {
  const customStyles = {
    headCells: {
      style: {
        paddingLeft: "8px", // override the cell padding for head cells
        paddingRight: "8px",
        fontWeight: "bold",
      },
    },
    cells: {
      style: {
        paddingLeft: "8px", // override the cell padding for data cells
        paddingRight: "8px",
      },
    },
  };

  const columns = [
    {
      name: "Request date",
      sortable: true,
      selector: (row) => row.added,
      cell: (row) => (
        <Box
          onClick={() => {
            setActiveRequest(row.request_uuid);
            setViewRequest(true);
          }}
          sx={{ fontSize: "13px", cursor: "pointer" }}
          className="link"
        >
          {moment.unix(row.added).format("DD/MM/YYYY")}
        </Box>
      ),
    },
    {
      name: "Request template",
      sortable: true,
      width: "70%",
      selector: (row) => row.template_name,
      cell: (row) => (
        <Box
          onClick={() => {
            setActiveRequest(row.request_uuid);
            setViewRequest(true);
          }}
          sx={{ fontSize: "13px", cursor: "pointer" }}
          className="link"
        >
          {row.template_name}
        </Box>
      ),
    },
    {
      name: "Status",
      sortable: true,
      right: "true",
      selector: (row) => (row.review_required ? 1 : row.deleted ? 2 : 0),
      cell: (row) => (
        <Box sx={{ display: "flex", flexDirection: "row", gap: 2 }}>
          {row.cancelled ? (
            <Chip className="square-chip" label="Cancelled" color="primary" sx={{ textTransform: "uppercase" }} />
          ) : row.review_complete ? (
            <Chip className="square-chip" label="Complete" color="success" sx={{ textTransform: "uppercase" }} />
          ) : row.review_required ? (
            <Chip className="square-chip" label="Review Required" color="warning" sx={{ textTransform: "uppercase" }} />
          ) : row.accepted ? (
            <Chip className="square-chip" label="In progress" color="success" sx={{ textTransform: "uppercase" }} />
          ) : row.rejected ? (
            <Chip className="square-chip" label="Rejected" color="error" sx={{ textTransform: "uppercase" }} />
          ) : row.seen ? (
            <Chip className="square-chip" label="Opened" color="warning" sx={{ textTransform: "uppercase" }} />
          ) : (
            <Chip className="square-chip" label="Pending" color="info" sx={{ textTransform: "uppercase" }} />
          )}
        </Box>
      ),
    },
  ];

  return (
    <DataTable
      columns={columns}
      data={tableData}
      customStyles={customStyles}
      striped
      pagination
      noDataComponent={<NoResults message={`There are no requests on this case`} />}
    />
  );
};

export default ListRequests;
