import { useCallback, useEffect, useState } from "react";
import { getClientTasks, getClientTasksFilter } from "../../api/services";

export function useGetClientTasks({ clientUUID, filterType, targetUserUUID }) {
  const [state, setState] = useState({
    isLoading: true,
    data: null,
  });

  const getClientTasksQuery = useCallback(async () => {
    try {
      if (!clientUUID) return;
      if (typeof filterType !== "undefined" || typeof targetUserUUID !== "undefined") {
        const res = await getClientTasksFilter({ clientUUID, filterType, targetUserUUID });
        setState({
          isLoading: false,
          data: res?.entries || [],
        });

        return;
      }
      const res = await getClientTasks(clientUUID);
      setState({
        isLoading: false,
        data: res?.entries || [],
      });
    } catch (e) {
      console.error(e);
    }
  }, [clientUUID, filterType, targetUserUUID]);

  useEffect(() => {
    getClientTasksQuery();
  }, [getClientTasksQuery]);

  return {
    clientTasksList: state.data,
    isLoading: state.isLoading,
    getClientTasksQuery,
  };
}
