import React from "react";
import Webcam from "react-webcam";

import { Box } from "@mui/material";

const WebcamDisplay = ({
  videoElement,
  videoConstraints,
  setPermissionsError,
  setBaseInstructions,
  getDevice,
}) => {
  return (
    <Box sx={{ width: "100%", display: "flex", flexDirection: "row", justifyContent: "center" }}>
      <Webcam
        sx={{ marginLeft: "auto", marginRight: "auto" }}
        id="video-output"
        audio={false}
        muted={true}
        ref={videoElement}
        screenshotFormat="image/png"
        videoConstraints={videoConstraints}
        onUserMedia={(e) => {
          var tracks = e.getTracks();
          for (var i = 0; i < tracks.length; i++) {
            getDevice(tracks[i].getSettings().deviceId);
          }

          setBaseInstructions("Press the capture image button");
        }}
        onUserMediaError={() => {
          setPermissionsError(true);
        }}
      />
    </Box>
  );
};

export default WebcamDisplay;
