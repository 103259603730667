import { React, useEffect, useState } from "react";

import partner from "../../../../../api/partner";
import central from "../../../../../api/central";

import authHeader from "../../../../../api/authHeader";

import HandleError from "../../../../../components/functions/HandleError";

import { Box, TextField, FormControl, InputLabel, Select, MenuItem, Typography, Collapse, Divider, IconButton, Chip } from "@mui/material";
import { People, Business } from "@mui/icons-material";

const Control = ({ clientData, entityData }) => {
  const useAuthHeader = authHeader();
  const handleError = HandleError();

  const [processing, setProcessing] = useState(false);
  const [displayList, setDisplayList] = useState(true);

  const [officersLoaded, setOfficersLoaded] = useState(false);
  const [officers, setOfficers] = useState([]);

  const getOfficersList = async () => {
    try {
      const response = await partner.post(
        `/v1/entity/corporations/corporation/data/officers/list`,
        JSON.stringify({
          corporation_uuid: entityData.value.corporation_uuid,
        }),
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Basic " + useAuthHeader.base64encodedData,
            service: "partners",
          },

          //withCredentials: true,
        }
      );

      if (response.data.results > 0) {
        setOfficers(response.data.entries);
      } else {
        setOfficers([]);
      }

      setOfficersLoaded(true);
    } catch (err) {
      console.log(err);
      handleError(err);
    }
  };

  useEffect(() => {
    getOfficersList();
  }, []);

  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
      {officers.map((sh, index) => (
        <Box key={index}>
          <Box sx={{ display: "flex", flexDirection: "row", border: 1, borderColor: "divider" }}>
            <Box sx={{ display: "flex", justifyContent: "space-between", width: "40%" }}>
              <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center", p: 2 }}>
                <Typography variant="subtitle2">{sh.name}</Typography>
              </Box>
              <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center", p: 2 }}>
                {sh.entity_type == 1 ? <People /> : <Business />}
              </Box>
            </Box>
            <Box
              sx={{
                borderLeft: 1,
                borderColor: "divider",
                p: 2,
                textTransform: "uppercase",
                display: "flex",
                flexDirection: "column",
                gap: 2,
                justifyContent: "center",
                textAlign: "center",
              }}
            >
              {sh.roles?.length > 0 &&
                sh.roles.map((role, index) => (
                  <Typography key={index} variant="subtitle">
                    {role.label}
                  </Typography>
                ))}
            </Box>
            <Box sx={{ borderLeft: 1, borderColor: "divider", p: 2, display: "flex", flexDirection: "column", gap: 1 }}>
              <Box sx={{ display: "flex", flexDirection: "row", gap: 1 }}>
                {sh.identity && <Chip className="square-chip" size="small" color="secondary" label="Identity" />}
                {sh.address && <Chip className="square-chip" size="small" color="secondary" label="Address" />}
                {sh.proof_of_life && <Chip className="square-chip" size="small" color="secondary" label="Life" />}
                {sh.ownership && <Chip className="square-chip" size="small" color="secondary" label="Ownership" />}
                {sh.control && <Chip className="square-chip" size="small" color="secondary" label="Control" />}
              </Box>
              <Box sx={{ display: "flex", flexDirection: "row", gap: 1 }}>
                {sh.fatca && <Chip className="square-chip" size="small" color="info" label="FATCA" />}
                {sh.pep_declaration && <Chip className="square-chip" size="small" color="info" label="PEP Declaration" />}
                {sh.source_of_wealth && <Chip className="square-chip" size="small" color="info" label="Source of wealth" />}
              </Box>
              <Box sx={{ display: "flex", flexDirection: "row", gap: 1 }}>
                {sh.associated_countries?.length > 0 &&
                  sh.associated_countries.map((country, index) => (
                    <Chip
                      key={index}
                      className="square-chip"
                      size="small"
                      title={country.high_risk && "High risk"}
                      sx={{ backgroundColor: country.high_risk ? "#C83E4D" : "#0b0b0b", color: "#ffffff" }}
                      label={country.name}
                    />
                  ))}
              </Box>
            </Box>
          </Box>
        </Box>
      ))}
    </Box>
  );
};

export default Control;
