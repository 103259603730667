import { useCallback, useEffect, useState } from "react";
import { getTaskFiles } from "../../api/services";

export function useGetTaskFiles(taskUUID) {
  const [data, setData] = useState({
    data: null,
    isLoading: true,
  });

  const getTaskFilesQuery = useCallback(async () => {
    try {
      const res = await getTaskFiles({ taskUUID });
      setData({
        data: res?.entries || [],
        isLoading: false,
      });
    } catch (error) {
      console.log(error);
    }
  }, [taskUUID]);

  useEffect(() => {
    if (data?.data || !taskUUID) return;
    getTaskFilesQuery(taskUUID);
  }, [getTaskFilesQuery, data?.data, taskUUID]);

  return {
    taskFilesList: data.data,
    isLoading: data.isLoading,
    getTaskFilesQuery,
  };
}
