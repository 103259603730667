import { Box, Button, Divider, Stack, Typography } from "@mui/material";
import { Modal } from "../../../../../../components/modal";
import { useDropzone } from "react-dropzone";
import { uploadTaskFile } from "../../../../../../api/services";
import { fileTypes } from "../file-type";
import { useState } from "react";
import { LoadingButton } from "@mui/lab";
import HandleToast from "../../../../../../components/functions/HandleToast";
import { RenderWhen } from "../../../../../../components/render-when";
import PropTypes from "prop-types";

export function UploadTaskFileButton({ taskUUID, clientUUID, updateTaskData, getTaskFilesQuery }) {
  const displayToast = HandleToast();
  const [uploadModalOpen, setUploadModalOpen] = useState(false);
  const [file, setFile] = useState(null);
  const [loading, setLoading] = useState(false);

  const onDrop = async (acceptedFiles) => {
    const uploadedFile = acceptedFiles?.[0];
    if (!uploadedFile) return;
    setFile(uploadedFile);
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: fileTypes,
  });

  async function handleUploadFile() {
    if (!file) {
      displayToast("Please select a file to upload", undefined, "error");
      return;
    }
    if (!taskUUID || !clientUUID || !file) {
      displayToast("Invalid task data", undefined, "error");
      return;
    }
    const formData = new FormData();
    formData.append("file", file);
    formData.append("task_uuid", taskUUID);
    formData.append("file_description", "file description");
    formData.append("file_name", file?.name);

    try {
      setLoading(true);
      await uploadTaskFile(formData);
      await updateTaskData();
      await getTaskFilesQuery();
      displayToast("File uploaded successfully");
    } catch (error) {
      displayToast("Failed to upload file", undefined, "error");
    } finally {
      setLoading(false);
      setUploadModalOpen(false);
      setFile(null);
    }
  }

  return (
    <>
      <Button onClick={() => setUploadModalOpen(true)} variant="contained">
        Upload
      </Button>
      <Modal open={uploadModalOpen} onClose={() => setUploadModalOpen(false)}>
        <Stack direction="column">
          <Typography variant="h6" sx={{ p: 2 }}>
            Upload File
          </Typography>
          <Divider />
          <RenderWhen when={Boolean(!file)}>
            <Box
              className="file-drop"
              sx={{
                border: 2,
                borderColor: "divider",
                borderStyle: "dashed",
                cursor: "pointer",
                p: 5,
                m: 2,
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
              }}
              {...getRootProps()}
            >
              <input {...getInputProps()} />
              {isDragActive ? (
                <Typography align="center">Drop the file here ...</Typography>
              ) : (
                <Typography align="center">Drop a file here, or click to select files</Typography>
              )}
            </Box>
            <Divider />
          </RenderWhen>
          <RenderWhen when={Boolean(file)}>
            <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ p: 2 }}>
              <Typography variant="body1">{file?.name}</Typography>
              <Button onClick={() => setFile(null)} variant="outlined">
                Remove
              </Button>
            </Stack>
            <Divider />
          </RenderWhen>
          <Stack direction="row" justifyContent="flex-end" sx={{ p: 2 }}>
            <LoadingButton loading={loading} onClick={handleUploadFile} variant="contained">
              Upload
            </LoadingButton>
          </Stack>
        </Stack>
      </Modal>
    </>
  );
}

UploadTaskFileButton.propTypes = {
  taskUUID: PropTypes.string,
  clientUUID: PropTypes.string,
  updateTaskData: PropTypes.func,
  getTaskFilesQuery: PropTypes.func,
};
