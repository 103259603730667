import { React, useState } from "react";

import PropTypes from "prop-types";
import { Tabs, Tab, Box, Card, CardContent, CardHeader, Divider, Typography } from "@mui/material";
import GenerateReport from "./components/reports/GenerateReport";
import ReportHistory from "./components/reports/ReportHistory";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div role="tabpanel" hidden={value !== index} id={`vertical-tabpanel-${index}`} aria-labelledby={`vertical-tab-${index}`} {...other}>
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
}

const Reports = ({ partner, partnerData, partnerUser }) => {
  const [navValue, setNavValue] = useState(0);

  const handleNav = (event, newValue) => {
    setNavValue(newValue);
  };

  return (
    <>
      <Card
        variant="outlined"
        sx={{
          p: 0,
          width: "100%",
          borderRadius: "0",
        }}
      >
        <Box p={2} display="flex" alignItems="center">
          <Box>
            <Typography variant="h5">Reports</Typography>
          </Box>
        </Box>
        <Divider />
        <CardContent sx={{ p: 0 }}>
          <Box sx={{ width: "100%" }}>
            <Tabs onChange={handleNav} value={navValue} aria-label="Tabs where selection follows focus" selectionFollowsFocus>
              <Tab label="Generate report" {...a11yProps(0)} />
              <Tab label="Report History" {...a11yProps(1)} />
            </Tabs>
          </Box>

          <TabPanel value={navValue} index={0}>
            <GenerateReport partner={partner} partnerData={partnerData} partnerUser={partnerUser} />
          </TabPanel>

          <TabPanel value={navValue} index={1}>
            <ReportHistory partner={partner} partnerData={partnerData} partnerUser={partnerUser} />
          </TabPanel>
        </CardContent>
      </Card>
    </>
  );
};

export default Reports;
