import { React, useState, useEffect } from "react";

import partner from "../../../../api/partner";
import authHeader from "../../../../api/authHeader";

import HandleError from "../../../../components/functions/HandleError";
import HandleToast from "../../../../components/functions/HandleToast";

import CurrencyTextField from "@puhl/mui-currency-textfield";
import getSymbolFromCurrency from "currency-symbol-map";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

import {
  Box,
  Collapse,
  Divider,
  Grid,
  TextField,
  Typography,
  ToggleButtonGroup,
  ToggleButton,
  FormControl,
  Select,
  InputLabel,
  MenuItem,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";

import CurrencySelect from "../../../../components/form-elements/CurrencySelect";

const NewAsset = ({ partnerUser, partnerData, clientData, entityData, setNewAsset, getAssets, setManageAsset, setActiveAsset }) => {
  const useAuthHeader = authHeader();

  const handleError = HandleError();
  const displayToast = HandleToast();

  const [processing, setProcessing] = useState(false);

  const [assetName, setAssetName] = useState("");
  const [assetCurrency, setAssetCurrency] = useState("");
  const [currencySymbol, setCurrencySymbol] = useState("£");
  const [assetValue, setAssetValue] = useState("");

  const [selectedAssetType, setSelectedAssetType] = useState();

  const [additionalInformation, setAdditionalInformation] = useState("");

  const [trackValuation, setTrackValuation] = useState(false);

  const [assetTypes, setAssetTypes] = useState([
    { value: 1, label: "Residential Property" },
    { value: 2, label: "Commercial Property" },
    { value: 3, label: "Industrial Property" },
    { value: 4, label: "Development Land" },
    { value: 5, label: "Factory Building" },
    { value: 6, label: "Office Equipment" },
    { value: 7, label: "Manufacturing Equipment" },
    { value: 8, label: "Farm Machinery" },
    { value: 9, label: "Construction Equipment" },
    { value: 10, label: "Heavy Machinery" },
    { value: 11, label: "Agricultural Land" },
    { value: 12, label: "Livestock" },
    { value: 13, label: "Stocks" },
    { value: 14, label: "Bonds" },
    { value: 15, label: "Mutual Funds" },
    { value: 16, label: "ETFs" },
    { value: 17, label: "Automobile" },
    { value: 18, label: "Motorcycle" },
    { value: 19, label: "Boat" },
    { value: 20, label: "Aircraft" },
    { value: 21, label: "Art and Collectibles" },
    { value: 22, label: "Jewelry" },
    { value: 23, label: "Furniture" },
    { value: 24, label: "Electronics" },
    { value: 25, label: "Cryptocurrencies" },
    { value: 26, label: "Trademarks" },
    { value: 27, label: "Patents" },
    { value: 28, label: "Copyrights" },
    { value: 30, label: "Software" },
    { value: 32, label: "Domain Names" },
    { value: 33, label: "Social Media Accounts" },
    { value: 34, label: "Contracts" },
    { value: 35, label: "Licenses" },
    { value: 36, label: "Permits" },
    { value: 37, label: "Franchises" },
    { value: 38, label: "Partnerships" },
    { value: 39, label: "Joint Ventures" },
    { value: 40, label: "Precious Metals" },
    { value: 41, label: "Precious stones" },
    { value: 42, label: "Watch" },
    { value: 42, label: "Other" },
  ]);

  const handleCreateTemplate = async (e) => {
    e.preventDefault();
    setProcessing(true);
    try {
      const response = await partner.post(
        `/v1/client/templates/billing-rates/create`,
        JSON.stringify({
          name: assetName,
        }),
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Basic " + useAuthHeader.base64encodedData,
            service: "partners",
          },

          //withCredentials: true,
        }
      );

      getAssets();
      setActiveAsset(response.data.asset_uuid);

      displayToast("Asset created successfully", () => {
        setProcessing(false);
        setNewAsset(false);
        setManageAsset(true);
      });
    } catch (err) {
      handleError(err);
      setProcessing(false);
    }
  };

  useEffect(() => {
    if (assetCurrency) {
      setCurrencySymbol(getSymbolFromCurrency(assetCurrency.value));
    }
  }, [assetCurrency]);

  return (
    <Box>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={12} lg={10} xl={6}>
          <Box
            sx={{
              p: 2,
              display: "flex",
              flexDirection: "column",
              gap: 2,
              borderRight: { xs: 0, lg: 1 },
              borderTop: 0,
              borderColor: { xs: "divider", lg: "divider" },
            }}
          >
            <TextField
              fullWidth
              id="outlined-basic"
              label="Asset label"
              variant="outlined"
              value={assetName}
              onChange={(e) => setAssetName(e.target.value)}
            />

            <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
              <Typography variant="subtitle2">Select the asset type</Typography>

              <FormControl fullWidth>
                <InputLabel id="asset-type-label">Asset type</InputLabel>
                <Select
                  labelId="asset-type-label"
                  id="asset-type-select"
                  value={selectedAssetType}
                  label="Asset type"
                  onChange={(e) => setSelectedAssetType(e.target.value)}
                >
                  {assetTypes.map((assetType) => (
                    <MenuItem key={assetType.value} value={assetType.value}>
                      {assetType.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>

            <Box sx={{ display: "flex", justifyContent: "space-between", border: 1, borderColor: "divider" }}>
              <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center", p: 2 }}>
                <Typography variant="subtitle2">Track valuation changes</Typography>
              </Box>
              <Box sx={{ p: 2 }}>
                <ToggleButtonGroup
                  size="small"
                  color={trackValuation ? "primary" : "danger"}
                  value={trackValuation}
                  exclusive
                  onChange={(e, newValue) => {
                    if (newValue !== null) setTrackValuation(newValue);
                  }}
                  aria-label="Track value changes"
                >
                  <ToggleButton value={true}>Enabled</ToggleButton>
                  <ToggleButton value={false}>Disabled</ToggleButton>
                </ToggleButtonGroup>
              </Box>
            </Box>

            <Box>
              <CurrencySelect value={assetCurrency} setValue={setAssetCurrency} />
            </Box>

            <Collapse in={assetCurrency ? assetCurrency?.value != "" : false} exit={!assetCurrency}>
              <CurrencyTextField
                fullWidth
                label={"Value"}
                variant="outlined"
                value={assetValue}
                currencySymbol={currencySymbol}
                outputFormat="number"
                minimumValue="0"
                decimalCharacter="."
                digitGroupSeparator=","
                onChange={(event, value) => setAssetValue(value)}
              />
            </Collapse>

            <Typography variant="subtitle2">Additional information</Typography>

            <Box sx={{ border: 1, borderColor: "divider" }}>
              <ReactQuill theme="snow" value={additionalInformation} onChange={setAdditionalInformation} style={{ minHeight: "300px" }} />
            </Box>

            <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
              <LoadingButton
                disableElevation
                variant="contained"
                color="success"
                loading={processing}
                onClick={(e) => {
                  handleCreateTemplate(e);
                }}
              >
                Create template
              </LoadingButton>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default NewAsset;
