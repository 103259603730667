import { React, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import partner from "../../api/partner";
import authHeader from "../../api/authHeader";

import HandleError from "../../components/functions/HandleError";

import moment from "moment";
import Lottie from "react-lottie";
import loadingLottie from "../../components/lotties/loading-dots.json";

import { Box, Typography, Alert, Divider, Grid, Chip } from "@mui/material";
import { OpenInBrowser } from "@mui/icons-material";

import OnItsOwn from "../../components/functions/OnItsOwn";
import DocumentSearch from "../../components/form-elements/DocumentSearch";

const Overview = ({
  partnerUser,
  partnerData,
  clientData,
  entityData,
  telephoneData,
  addressData,
}) => {
  const useAuthHeader = authHeader();
  const navigate = useNavigate();

  const handleError = HandleError();

  const [screeningIsLoaded, setScreeningIsLoaded] = useState(false);
  const [linkagesIsLoaded, setLinkagesIsLoaded] = useState(false);

  const [screeningCase, setScreeningCase] = useState({});

  const [linkedEntities, setLinkedEntities] = useState([]);
  const [flags, setFlags] = useState([]);

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: loadingLottie,
  };

  const getScreeningData = async () => {
    try {
      const response = await partner.post(
        `/v1/screening/case/${clientData.value.entity_type == 1 ? "kyc" : "kyb"}/data`,
        clientData.value.entity_type == 1
          ? JSON.stringify({ case_uuid: entityData.value.kyc_token })
          : JSON.stringify({ case_uuid: entityData.value.kyb_token }),
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Basic " + useAuthHeader.base64encodedData,
            service: "partners",
          },

          //withCredentials: true,
        }
      );

      setScreeningCase(response.data);
      setScreeningIsLoaded(true);
    } catch (err) {
      handleError(err);
    }
  };

  const getLinkedEntities = async () => {
    try {
      const response = await partner.post(
        `/v1/entity/${
          clientData.value.entity_type == 1 ? "people/person" : "corporations/corporation"
        }/data/linkages`,
        JSON.stringify({ client_uuid: clientData.value.client_uuid }),
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Basic " + useAuthHeader.base64encodedData,
            service: "partners",
          },

          //withCredentials: true,
        }
      );

      setLinkedEntities(response.data);
      setLinkagesIsLoaded(true);
    } catch (err) {
      console.error(err);
      handleError(err);
    }
  };

  const getEntityFlags = async () => {
    try {
      const response = await partner.post(
        `/v1/entity/${
          clientData.value.entity_type == 1
            ? "people/person/data/flags"
            : "corporations/corporation/data/flags"
        }`,
        clientData.value.entity_type == 1
          ? JSON.stringify({ person_uuid: entityData.value.person_uuid })
          : JSON.stringify({
              corporation_uuid: entityData.value.corporation_uuid,
            }),
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Basic " + useAuthHeader.base64encodedData,
            service: "partners",
          },

          //withCredentials: true,
        }
      );

      if (response.data.results > 0) {
        setFlags(response.data.entries);
      } else {
        setFlags([]);
      }
      setScreeningIsLoaded(true);
    } catch (err) {
      handleError(err);
    }
  };

  useEffect(() => {
    if (clientData.value.status === 0) {
      getScreeningData();
    } else {
      getEntityFlags();
    }
    getLinkedEntities();
  }, []);

  return (
    <>
      <Box sx={{ p: 2, display: "flex", flexDirection: "column", gap: 2 }}>
        {clientData.value.status == 0 && (
          <Alert severity="info">This client is still onboarding</Alert>
        )}

        <Grid container spacing={2}>
          <Grid item xs={12} xl={6}>
            <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
              <Box sx={{ border: 1, borderColor: "divider", borderBottom: 0 }}>
                <Box sx={{ p: 2 }}>
                  <Typography variant="subtitle2">Client details</Typography>
                </Box>
                <Divider />

                {screeningIsLoaded ? (
                  <Box>
                    {clientData.value.entity_type == 1 ? (
                      <Box>
                        <Box
                          sx={{
                            px: 2,
                            py: 1,
                            borderBottom: 1,
                            borderColor: "divider",
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              justifyContent: "center",
                            }}
                          >
                            <Typography variant="body2">Full name</Typography>
                          </Box>
                          <Box>
                            <Typography variant="subtitle2">
                              {entityData.value.title ? entityData.value.title + " " : ""}
                              {entityData.value.first_name ? entityData.value.first_name + " " : ""}
                              {entityData.value.middle_names
                                ? entityData.value.middle_names + " "
                                : ""}
                              {entityData.value.last_name ? entityData.value.last_name : "-"}
                            </Typography>
                          </Box>
                        </Box>
                        <Box
                          sx={{
                            px: 2,
                            py: 1,
                            borderBottom: 1,
                            borderColor: "divider",
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              justifyContent: "center",
                            }}
                          >
                            <Typography variant="body2">Email address</Typography>
                          </Box>
                          <Box>
                            <Typography variant="subtitle2">
                              {entityData.value.email_address
                                ? entityData.value.email_address
                                : "-"}
                            </Typography>
                          </Box>
                        </Box>

                        <Box
                          sx={{
                            px: 2,
                            py: 1,
                            borderBottom: 1,
                            borderColor: "divider",
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              justifyContent: "center",
                            }}
                          >
                            <Typography variant="body2">Telephone number</Typography>
                          </Box>
                          <Box>
                            <Typography variant="subtitle2">
                              {telephoneData?.telephone_number != ""
                                ? "+" +
                                  telephoneData.dialling_code +
                                  " " +
                                  telephoneData.telephone_number
                                : "-"}
                            </Typography>

                            {telephoneData?.telephone_number != "" && (
                              <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
                                <Typography variant="subtitle2" sx={{ textAlign: "end" }}>
                                  {telephoneData.country.name}
                                </Typography>

                                {telephoneData.country?.high_risk && (
                                  <Chip
                                    className="square-chip"
                                    label="High risk"
                                    size="small"
                                    sx={{
                                      backgroundColor: "#C83E4D",
                                      color: "#ffffff",
                                      textTransform: "uppercase",
                                    }}
                                  />
                                )}
                                {telephoneData.country?.conflict && (
                                  <Chip
                                    className="square-chip"
                                    label="Conflict"
                                    size="small"
                                    sx={{
                                      backgroundColor: "#FF4A1C",
                                      color: "#1C2321",
                                      textTransform: "uppercase",
                                    }}
                                  />
                                )}
                                {telephoneData.country?.borders_conflict && (
                                  <Chip
                                    className="square-chip"
                                    label="Borders conflict"
                                    size="small"
                                    sx={{
                                      backgroundColor: "#977390",
                                      color: "#1C2321",
                                      textTransform: "uppercase",
                                    }}
                                  />
                                )}
                                {telephoneData.country?.is_secrecy && (
                                  <Chip
                                    className="square-chip"
                                    label="Secrecy"
                                    size="small"
                                    sx={{
                                      backgroundColor: "#999999",
                                      color: "#1C2321",
                                      textTransform: "uppercase",
                                    }}
                                  />
                                )}
                              </Box>
                            )}
                          </Box>
                        </Box>

                        <Box
                          sx={{
                            px: 2,
                            py: 1,
                            borderBottom: 1,
                            borderColor: "divider",
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              justifyContent: "center",
                            }}
                          >
                            <Typography variant="body2">Date of birth</Typography>
                          </Box>
                          <Box>
                            <Typography variant="subtitle2">
                              {entityData.value.date_of_birth
                                ? moment(entityData.value.date_of_birth).format("DD/MM/YYYY")
                                : "-"}
                              {entityData.value.age > 0 &&
                                " (" + entityData.value.age + " years old)"}
                            </Typography>
                          </Box>
                        </Box>
                        <Box
                          sx={{
                            px: 2,
                            py: 1,
                            borderBottom: 1,
                            borderColor: "divider",
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              justifyContent: "center",
                            }}
                          >
                            <Typography variant="body2">Country of birth</Typography>
                          </Box>
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              gap: 1,
                            }}
                          >
                            <Typography variant="subtitle2" align="right">
                              {entityData.value.birth_country?.code
                                ? entityData.value.birth_country.name
                                : "-"}
                            </Typography>

                            {entityData.value.birth_country?.high_risk && (
                              <Chip
                                className="square-chip"
                                label="High risk"
                                size="small"
                                sx={{
                                  backgroundColor: "#C83E4D",
                                  color: "#ffffff",
                                  textTransform: "uppercase",
                                }}
                              />
                            )}
                            {entityData.value.birth_country?.conflict && (
                              <Chip
                                className="square-chip"
                                label="Conflict"
                                size="small"
                                sx={{
                                  backgroundColor: "#FF4A1C",
                                  color: "#1C2321",
                                  textTransform: "uppercase",
                                }}
                              />
                            )}
                            {entityData.value.birth_country?.borders_conflict && (
                              <Chip
                                className="square-chip"
                                label="Borders conflict"
                                size="small"
                                sx={{
                                  backgroundColor: "#977390",
                                  color: "#1C2321",
                                  textTransform: "uppercase",
                                }}
                              />
                            )}
                            {entityData.value.birth_country?.is_secrecy && (
                              <Chip
                                className="square-chip"
                                label="Secrecy"
                                size="small"
                                sx={{
                                  backgroundColor: "#999999",
                                  color: "#1C2321",
                                  textTransform: "uppercase",
                                }}
                              />
                            )}
                          </Box>
                        </Box>
                        {entityData.value.citizenship && (
                          <Box>
                            <Box
                              sx={{
                                px: 2,
                                py: 1,
                                borderBottom: 1,
                                borderColor: "divider",
                                display: "flex",
                                justifyContent: "space-between",
                              }}
                            >
                              <Box
                                sx={{
                                  display: "flex",
                                  flexDirection: "column",
                                  justifyContent: "center",
                                }}
                              >
                                <Typography variant="body2">Citizenship</Typography>
                              </Box>

                              <Box>
                                {entityData.value.citizenship.length > 0 &&
                                  entityData.value.citizenship.map((citizenship, index) => (
                                    <Box
                                      key={index}
                                      sx={{
                                        display: "flex",
                                        flexDirection: "column",
                                        gap: 1,
                                      }}
                                    >
                                      <Typography variant="subtitle2" align="right">
                                        {citizenship.name}
                                      </Typography>

                                      {citizenship.high_risk && (
                                        <Chip
                                          className="square-chip"
                                          label="High risk"
                                          size="small"
                                          sx={{
                                            backgroundColor: "#C83E4D",
                                            color: "#ffffff",
                                            textTransform: "uppercase",
                                          }}
                                        />
                                      )}
                                      {citizenship.conflict && (
                                        <Chip
                                          className="square-chip"
                                          label="Conflict"
                                          size="small"
                                          sx={{
                                            backgroundColor: "#FF4A1C",
                                            color: "#1C2321",
                                            textTransform: "uppercase",
                                          }}
                                        />
                                      )}
                                      {citizenship.borders_conflict && (
                                        <Chip
                                          className="square-chip"
                                          label="Borders conflict"
                                          size="small"
                                          sx={{
                                            backgroundColor: "#977390",
                                            color: "#1C2321",
                                            textTransform: "uppercase",
                                          }}
                                        />
                                      )}
                                      {citizenship.is_secrecy && (
                                        <Chip
                                          className="square-chip"
                                          label="Secrecy"
                                          size="small"
                                          sx={{
                                            backgroundColor: "#999999",
                                            color: "#1C2321",
                                            textTransform: "uppercase",
                                          }}
                                        />
                                      )}
                                    </Box>
                                  ))}
                              </Box>
                            </Box>
                          </Box>
                        )}

                        <Box
                          sx={{
                            px: 2,
                            py: 1,
                            borderBottom: 1,
                            borderColor: "divider",
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              justifyContent: "center",
                            }}
                          >
                            <Typography variant="body2">Address</Typography>
                          </Box>
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              gap: 1,
                            }}
                          >
                            <Typography variant="subtitle2" align="right">
                              {addressData?.line_one ? addressData?.line_one : "-"}
                            </Typography>

                            {addressData.line_two && (
                              <Typography variant="subtitle2" align="right">
                                {addressData.line_two}
                              </Typography>
                            )}

                            {addressData.line_three && (
                              <Typography variant="subtitle2" align="right">
                                {addressData.line_three}
                              </Typography>
                            )}

                            {addressData.line_four && (
                              <Typography variant="subtitle2" align="right">
                                {addressData.line_four}
                              </Typography>
                            )}

                            {addressData.line_five && (
                              <Typography variant="subtitle2" align="right">
                                {addressData.line_five}
                              </Typography>
                            )}

                            {addressData.line_six && (
                              <Typography variant="subtitle2" align="right">
                                {addressData.line_six}
                              </Typography>
                            )}

                            {addressData.city && (
                              <Typography variant="subtitle2" align="right">
                                {addressData.city}
                              </Typography>
                            )}

                            {addressData.country?.name && (
                              <Typography variant="subtitle2" align="right">
                                {addressData.country.name}
                              </Typography>
                            )}

                            {addressData.country?.high_risk && (
                              <Chip
                                className="square-chip"
                                label="High risk"
                                size="small"
                                sx={{
                                  backgroundColor: "#C83E4D",
                                  color: "#ffffff",
                                  textTransform: "uppercase",
                                }}
                              />
                            )}
                            {addressData.country?.conflict && (
                              <Chip
                                className="square-chip"
                                label="Conflict"
                                size="small"
                                sx={{
                                  backgroundColor: "#FF4A1C",
                                  color: "#1C2321",
                                  textTransform: "uppercase",
                                }}
                              />
                            )}
                            {addressData.country?.borders_conflict && (
                              <Chip
                                className="square-chip"
                                label="Borders conflict"
                                size="small"
                                sx={{
                                  backgroundColor: "#977390",
                                  color: "#1C2321",
                                  textTransform: "uppercase",
                                }}
                              />
                            )}
                            {addressData.country?.is_secrecy && (
                              <Chip
                                className="square-chip"
                                label="Secrecy"
                                size="small"
                                sx={{
                                  backgroundColor: "#999999",
                                  color: "#1C2321",
                                  textTransform: "uppercase",
                                }}
                              />
                            )}
                          </Box>
                        </Box>
                      </Box>
                    ) : (
                      <Box>
                        <Box
                          sx={{
                            px: 2,
                            py: 1,
                            borderBottom: 1,
                            borderColor: "divider",
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              justifyContent: "center",
                            }}
                          >
                            <Typography variant="body2">Entity type</Typography>
                          </Box>
                          <Box>
                            <Typography variant="subtitle2">
                              {entityData.value.registration_type
                                ? entityData.value.registration_type
                                : "-"}
                            </Typography>
                          </Box>
                        </Box>

                        <Box
                          sx={{
                            px: 2,
                            py: 1,
                            borderBottom: 1,
                            borderColor: "divider",
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              justifyContent: "center",
                            }}
                          >
                            <Typography variant="body2">Company reference</Typography>
                          </Box>
                          <Box>
                            <Typography variant="subtitle2">
                              {entityData.value.registration_reference
                                ? entityData.value.registration_reference
                                : "-"}
                            </Typography>
                          </Box>
                        </Box>

                        <Box
                          sx={{
                            px: 2,
                            py: 1,
                            borderBottom: 1,
                            borderColor: "divider",
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              justifyContent: "center",
                            }}
                          >
                            <Typography variant="body2">Registration date</Typography>
                          </Box>
                          <Box>
                            <Typography variant="subtitle2">
                              {entityData.value.registration_date
                                ? moment(entityData.value.registration_date).format("Do MMMM YYYY")
                                : "-"}
                            </Typography>
                          </Box>
                        </Box>

                        <Box
                          sx={{
                            px: 2,
                            py: 1,
                            borderBottom: 1,
                            borderColor: "divider",
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              justifyContent: "center",
                            }}
                          >
                            <Typography variant="body2">Registration country</Typography>
                          </Box>
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              gap: 1,
                            }}
                          >
                            <Typography variant="subtitle2" align="right">
                              {entityData.value.registration_country?.code
                                ? entityData.value.registration_country.name
                                : "-"}
                            </Typography>

                            {entityData.value.registration_country?.high_risk && (
                              <Chip
                                className="square-chip"
                                label="High risk"
                                size="small"
                                sx={{
                                  backgroundColor: "#C83E4D",
                                  color: "#ffffff",
                                  textTransform: "uppercase",
                                }}
                              />
                            )}
                            {entityData.value.registration_country?.conflict && (
                              <Chip
                                className="square-chip"
                                label="Conflict"
                                size="small"
                                sx={{
                                  backgroundColor: "#FF4A1C",
                                  color: "#1C2321",
                                  textTransform: "uppercase",
                                }}
                              />
                            )}
                            {entityData.value.registration_country?.borders_conflict && (
                              <Chip
                                className="square-chip"
                                label="Borders conflict"
                                size="small"
                                sx={{
                                  backgroundColor: "#977390",
                                  color: "#1C2321",
                                  textTransform: "uppercase",
                                }}
                              />
                            )}
                            {entityData.value.registration_country?.is_secrecy && (
                              <Chip
                                className="square-chip"
                                label="Secrecy"
                                size="small"
                                sx={{
                                  backgroundColor: "#999999",
                                  color: "#1C2321",
                                  textTransform: "uppercase",
                                }}
                              />
                            )}
                          </Box>
                        </Box>

                        <Box
                          sx={{
                            px: 2,
                            py: 1,
                            borderBottom: 1,
                            borderColor: "divider",
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              justifyContent: "center",
                            }}
                          >
                            <Typography variant="body2">Financial year end</Typography>
                          </Box>
                          <Box>
                            <Typography variant="subtitle2">
                              {entityData.value.year_end_day > 0
                                ? moment(
                                    new Date(
                                      2023,
                                      entityData.value.year_end_month - 1,
                                      entityData.value.year_end_day
                                    )
                                  ).format("Do MMMM")
                                : "-"}
                            </Typography>
                          </Box>
                        </Box>

                        <Box
                          sx={{
                            px: 2,
                            py: 1,
                            borderBottom: 1,
                            borderColor: "divider",
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              justifyContent: "center",
                            }}
                          >
                            <Typography variant="body2">Registered address</Typography>
                          </Box>
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              gap: 1,
                            }}
                          >
                            <Typography variant="subtitle2" align="right">
                              {addressData?.line_one ? addressData?.line_one : "-"}
                            </Typography>

                            {addressData?.line_two && (
                              <Typography variant="subtitle2" align="right">
                                {addressData.line_two}
                              </Typography>
                            )}

                            {addressData?.line_three && (
                              <Typography variant="subtitle2" align="right">
                                {addressData.line_three}
                              </Typography>
                            )}

                            {addressData?.line_four && (
                              <Typography variant="subtitle2" align="right">
                                {addressData.line_four}
                              </Typography>
                            )}

                            {addressData?.line_five && (
                              <Typography variant="subtitle2" align="right">
                                {addressData.line_five}
                              </Typography>
                            )}

                            {addressData?.line_six && (
                              <Typography variant="subtitle2" align="right">
                                {addressData.line_six}
                              </Typography>
                            )}

                            {addressData?.city && (
                              <Typography variant="subtitle2" align="right">
                                {addressData.city}
                              </Typography>
                            )}

                            {addressData?.country?.name && (
                              <Typography variant="subtitle2" align="right">
                                {addressData.country.name}
                              </Typography>
                            )}

                            {addressData?.country?.high_risk && (
                              <Chip
                                className="square-chip"
                                label="High risk"
                                size="small"
                                sx={{
                                  backgroundColor: "#C83E4D",
                                  color: "#ffffff",
                                  textTransform: "uppercase",
                                }}
                              />
                            )}
                            {addressData?.country?.conflict && (
                              <Chip
                                className="square-chip"
                                label="Conflict"
                                size="small"
                                sx={{
                                  backgroundColor: "#FF4A1C",
                                  color: "#1C2321",
                                  textTransform: "uppercase",
                                }}
                              />
                            )}
                            {addressData?.country?.borders_conflict && (
                              <Chip
                                className="square-chip"
                                label="Borders conflict"
                                size="small"
                                sx={{
                                  backgroundColor: "#977390",
                                  color: "#1C2321",
                                  textTransform: "uppercase",
                                }}
                              />
                            )}
                            {addressData?.country?.is_secrecy && (
                              <Chip
                                className="square-chip"
                                label="Secrecy"
                                size="small"
                                sx={{
                                  backgroundColor: "#999999",
                                  color: "#1C2321",
                                  textTransform: "uppercase",
                                }}
                              />
                            )}
                          </Box>
                        </Box>
                      </Box>
                    )}
                  </Box>
                ) : (
                  <Box>
                    <Lottie speed={2.0} options={defaultOptions} height={200} width={200} />
                  </Box>
                )}
              </Box>

              {clientData.value.status == 0 && (
                <>
                  {screeningIsLoaded ? (
                    <Box sx={{ border: 1, borderColor: "divider" }}>
                      <Box
                        sx={{
                          p: 2,
                          display: "flex",
                          justifyContent: "space-between",
                          gap: 2,
                        }}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                          }}
                        >
                          <Typography variant="subtitle2">Screening case</Typography>
                        </Box>
                        <Box
                          className="icon-link"
                          title="View case"
                          onClick={() => {
                            navigate(
                              `${
                                clientData.value.entity_type == 1
                                  ? "/case/kyc/" + entityData.value.kyc_token
                                  : "/case/kyb/" + entityData.value.kyb_token
                              }`
                            );
                          }}
                        >
                          <OpenInBrowser fontSize="large" />
                        </Box>
                      </Box>
                      <Divider />
                      <Box>
                        {clientData.value.entity_type == 1 ? (
                          <Box>
                            <Box
                              sx={{
                                px: 2,
                                py: 1,
                                borderBottom: 1,
                                borderColor: "divider",
                                display: "flex",
                                justifyContent: "space-between",
                              }}
                            >
                              <Box
                                sx={{
                                  display: "flex",
                                  flexDirection: "column",
                                  justifyContent: "center",
                                }}
                              >
                                <Typography variant="body2">Date of birth</Typography>
                              </Box>
                              <Box>
                                {screeningCase.date_of_birth ? (
                                  <Chip
                                    label="Complete"
                                    className="square-chip"
                                    size="small"
                                    sx={{
                                      backgroundColor: "#8BBF9F",
                                      color: "#1C2321",
                                      textTransform: "uppercase",
                                    }}
                                  />
                                ) : (
                                  <Chip
                                    label="Pending"
                                    className="square-chip"
                                    size="small"
                                    sx={{
                                      backgroundColor: "#55828b",
                                      color: "#ffffff",
                                      textTransform: "uppercase",
                                    }}
                                  />
                                )}
                              </Box>
                            </Box>
                            <Box
                              sx={{
                                px: 2,
                                py: 1,
                                borderBottom: 1,
                                borderColor: "divider",
                                display: "flex",
                                justifyContent: "space-between",
                              }}
                            >
                              <Box
                                sx={{
                                  display: "flex",
                                  flexDirection: "column",
                                  justifyContent: "center",
                                }}
                              >
                                <Typography variant="body2">Email address</Typography>
                              </Box>
                              <Box>
                                {screeningCase.options?.email_address ? (
                                  screeningCase.email_address ? (
                                    screeningCase.options?.email_verification ? (
                                      screeningCase.email_verification ? (
                                        <Chip
                                          label="Complete"
                                          className="square-chip"
                                          size="small"
                                          sx={{
                                            backgroundColor: "#8BBF9F",
                                            color: "#1C2321",
                                            textTransform: "uppercase",
                                          }}
                                        />
                                      ) : (
                                        <Chip
                                          label="Pending verification"
                                          className="square-chip"
                                          size="small"
                                          sx={{
                                            backgroundColor: "#55828b",
                                            color: "#ffffff",
                                            textTransform: "uppercase",
                                          }}
                                        />
                                      )
                                    ) : (
                                      <Chip
                                        label="Complete"
                                        className="square-chip"
                                        size="small"
                                        sx={{
                                          backgroundColor: "#8BBF9F",
                                          color: "#1C2321",
                                          textTransform: "uppercase",
                                        }}
                                      />
                                    )
                                  ) : (
                                    <Chip
                                      label="Pending"
                                      className="square-chip"
                                      size="small"
                                      sx={{
                                        backgroundColor: "#55828b",
                                        color: "#ffffff",
                                        textTransform: "uppercase",
                                      }}
                                    />
                                  )
                                ) : (
                                  <Chip
                                    label="-"
                                    className="square-chip"
                                    size="small"
                                    sx={{
                                      backgroundColor: "#474044",
                                      color: "#ffffff",
                                      textTransform: "uppercase",
                                    }}
                                  />
                                )}
                              </Box>
                            </Box>
                            <Box
                              sx={{
                                px: 2,
                                py: 1,
                                borderBottom: 1,
                                borderColor: "divider",
                                display: "flex",
                                justifyContent: "space-between",
                              }}
                            >
                              <Box
                                sx={{
                                  display: "flex",
                                  flexDirection: "column",
                                  justifyContent: "center",
                                }}
                              >
                                <Typography variant="body2">Telephone number</Typography>
                              </Box>
                              <Box>
                                {screeningCase.options?.telephone_number ? (
                                  screeningCase.telephone_number ? (
                                    screeningCase.options?.telephone_verification ? (
                                      screeningCase.telephone_verification ? (
                                        <Chip
                                          label="Complete"
                                          className="square-chip"
                                          size="small"
                                          sx={{
                                            backgroundColor: "#8BBF9F",
                                            color: "#1C2321",
                                            textTransform: "uppercase",
                                          }}
                                        />
                                      ) : (
                                        <Chip
                                          label="Pending verification"
                                          className="square-chip"
                                          size="small"
                                          sx={{
                                            backgroundColor: "#55828b",
                                            color: "#ffffff",
                                            textTransform: "uppercase",
                                          }}
                                        />
                                      )
                                    ) : (
                                      <Chip
                                        label="Complete"
                                        className="square-chip"
                                        size="small"
                                        sx={{
                                          backgroundColor: "#8BBF9F",
                                          color: "#1C2321",
                                          textTransform: "uppercase",
                                        }}
                                      />
                                    )
                                  ) : (
                                    <Chip
                                      label="Pending"
                                      className="square-chip"
                                      size="small"
                                      sx={{
                                        backgroundColor: "#55828b",
                                        color: "#ffffff",
                                        textTransform: "uppercase",
                                      }}
                                    />
                                  )
                                ) : (
                                  <Chip
                                    label="-"
                                    className="square-chip"
                                    size="small"
                                    sx={{
                                      backgroundColor: "#474044",
                                      color: "#ffffff",
                                      textTransform: "uppercase",
                                    }}
                                  />
                                )}
                              </Box>
                            </Box>
                            <Box
                              sx={{
                                px: 2,
                                py: 1,
                                borderBottom: 1,
                                borderColor: "divider",
                                display: "flex",
                                justifyContent: "space-between",
                              }}
                            >
                              <Box
                                sx={{
                                  display: "flex",
                                  flexDirection: "column",
                                  justifyContent: "center",
                                }}
                              >
                                <Typography variant="body2">Citizenship</Typography>
                              </Box>
                              <Box>
                                {screeningCase.options?.citizenship ? (
                                  screeningCase.citizenship ? (
                                    <Chip
                                      label="Complete"
                                      className="square-chip"
                                      size="small"
                                      sx={{
                                        backgroundColor: "#8BBF9F",
                                        color: "#1C2321",
                                        textTransform: "uppercase",
                                      }}
                                    />
                                  ) : (
                                    <Chip
                                      label="Pending"
                                      className="square-chip"
                                      size="small"
                                      sx={{
                                        backgroundColor: "#55828b",
                                        color: "#ffffff",
                                        textTransform: "uppercase",
                                      }}
                                    />
                                  )
                                ) : (
                                  <Chip
                                    label="-"
                                    className="square-chip"
                                    size="small"
                                    sx={{
                                      backgroundColor: "#474044",
                                      color: "#ffffff",
                                      textTransform: "uppercase",
                                    }}
                                  />
                                )}
                              </Box>
                            </Box>
                            <Box
                              sx={{
                                px: 2,
                                py: 1,
                                borderBottom: 1,
                                borderColor: "divider",
                                display: "flex",
                                justifyContent: "space-between",
                              }}
                            >
                              <Box
                                sx={{
                                  display: "flex",
                                  flexDirection: "column",
                                  justifyContent: "center",
                                }}
                              >
                                <Typography variant="body2">Identity verification</Typography>
                              </Box>
                              <Box>
                                {console.log(screeningCase)}
                                {screeningCase.options?.identity ? (
                                  screeningCase.identity ? (
                                    <Chip
                                      label="Complete"
                                      className="square-chip"
                                      size="small"
                                      sx={{
                                        backgroundColor: "#8BBF9F",
                                        color: "#1C2321",
                                        textTransform: "uppercase",
                                      }}
                                    />
                                  ) : (
                                    <Chip
                                      label="Pending"
                                      className="square-chip"
                                      size="small"
                                      sx={{
                                        backgroundColor: "#55828b",
                                        color: "#ffffff",
                                        textTransform: "uppercase",
                                      }}
                                    />
                                  )
                                ) : (
                                  <Chip
                                    label="-"
                                    className="square-chip"
                                    size="small"
                                    sx={{
                                      backgroundColor: "#474044",
                                      color: "#ffffff",
                                      textTransform: "uppercase",
                                    }}
                                  />
                                )}
                              </Box>
                            </Box>
                            <Box
                              sx={{
                                px: 2,
                                py: 1,
                                borderBottom: 1,
                                borderColor: "divider",
                                display: "flex",
                                justifyContent: "space-between",
                              }}
                            >
                              <Box
                                sx={{
                                  display: "flex",
                                  flexDirection: "column",
                                  justifyContent: "center",
                                }}
                              >
                                <Typography variant="body2">Proof of life</Typography>
                              </Box>
                              <Box>
                                {screeningCase.options?.proof_of_life ? (
                                  screeningCase.proof_of_life ? (
                                    <Chip
                                      label="Complete"
                                      className="square-chip"
                                      size="small"
                                      sx={{
                                        backgroundColor: "#8BBF9F",
                                        color: "#1C2321",
                                        textTransform: "uppercase",
                                      }}
                                    />
                                  ) : (
                                    <Chip
                                      label="Pending"
                                      className="square-chip"
                                      size="small"
                                      sx={{
                                        backgroundColor: "#55828b",
                                        color: "#ffffff",
                                        textTransform: "uppercase",
                                      }}
                                    />
                                  )
                                ) : (
                                  <Chip
                                    label="-"
                                    className="square-chip"
                                    size="small"
                                    sx={{
                                      backgroundColor: "#474044",
                                      color: "#ffffff",
                                      textTransform: "uppercase",
                                    }}
                                  />
                                )}
                              </Box>
                            </Box>
                          </Box>
                        ) : (
                          <Box>
                            <Box
                              sx={{
                                px: 2,
                                py: 1,
                                borderBottom: 1,
                                borderColor: "divider",
                                display: "flex",
                                justifyContent: "space-between",
                              }}
                            >
                              <Box
                                sx={{
                                  display: "flex",
                                  flexDirection: "column",
                                  justifyContent: "center",
                                }}
                              >
                                <Typography variant="body2">Business information</Typography>
                              </Box>
                              <Box>
                                {screeningCase.business_information ? (
                                  <Chip
                                    label="Complete"
                                    className="square-chip"
                                    size="small"
                                    sx={{
                                      backgroundColor: "#8BBF9F",
                                      color: "#1C2321",
                                      textTransform: "uppercase",
                                    }}
                                  />
                                ) : (
                                  <Chip
                                    label="Pending"
                                    className="square-chip"
                                    size="small"
                                    sx={{
                                      backgroundColor: "#55828b",
                                      color: "#ffffff",
                                      textTransform: "uppercase",
                                    }}
                                  />
                                )}
                              </Box>
                            </Box>
                            <Box
                              sx={{
                                px: 2,
                                py: 1,
                                borderBottom: 1,
                                borderColor: "divider",
                                display: "flex",
                                justifyContent: "space-between",
                              }}
                            >
                              <Box
                                sx={{
                                  display: "flex",
                                  flexDirection: "column",
                                  justifyContent: "center",
                                }}
                              >
                                <Typography variant="body2">Business activities</Typography>
                              </Box>
                              <Box>
                                {screeningCase.business_activities ? (
                                  <Chip
                                    label="Complete"
                                    className="square-chip"
                                    size="small"
                                    sx={{
                                      backgroundColor: "#8BBF9F",
                                      color: "#1C2321",
                                      textTransform: "uppercase",
                                    }}
                                  />
                                ) : (
                                  <Chip
                                    label="Pending"
                                    className="square-chip"
                                    size="small"
                                    sx={{
                                      backgroundColor: "#55828b",
                                      color: "#ffffff",
                                      textTransform: "uppercase",
                                    }}
                                  />
                                )}
                              </Box>
                            </Box>
                            <Box
                              sx={{
                                px: 2,
                                py: 1,
                                borderBottom: 1,
                                borderColor: "divider",
                                display: "flex",
                                justifyContent: "space-between",
                              }}
                            >
                              <Box
                                sx={{
                                  display: "flex",
                                  flexDirection: "column",
                                  justifyContent: "center",
                                }}
                              >
                                <Typography variant="body2">Ownership</Typography>
                              </Box>
                              <Box>
                                {screeningCase.options?.ownership ? (
                                  screeningCase.ownership ? (
                                    <Chip
                                      label="Complete"
                                      className="square-chip"
                                      size="small"
                                      sx={{
                                        backgroundColor: "#8BBF9F",
                                        color: "#1C2321",
                                        textTransform: "uppercase",
                                      }}
                                    />
                                  ) : (
                                    <Chip
                                      label="Pending"
                                      className="square-chip"
                                      size="small"
                                      sx={{
                                        backgroundColor: "#55828b",
                                        color: "#ffffff",
                                        textTransform: "uppercase",
                                      }}
                                    />
                                  )
                                ) : (
                                  <Chip
                                    label="-"
                                    className="square-chip"
                                    size="small"
                                    sx={{
                                      backgroundColor: "#474044",
                                      color: "#ffffff",
                                      textTransform: "uppercase",
                                    }}
                                  />
                                )}
                              </Box>
                            </Box>
                            <Box
                              sx={{
                                px: 2,
                                py: 1,
                                borderBottom: 1,
                                borderColor: "divider",
                                display: "flex",
                                justifyContent: "space-between",
                              }}
                            >
                              <Box
                                sx={{
                                  display: "flex",
                                  flexDirection: "column",
                                  justifyContent: "center",
                                }}
                              >
                                <Typography variant="body2">Management</Typography>
                              </Box>
                              <Box>
                                {screeningCase.options?.officers ? (
                                  screeningCase.officers ? (
                                    <Chip
                                      label="Complete"
                                      className="square-chip"
                                      size="small"
                                      sx={{
                                        backgroundColor: "#8BBF9F",
                                        color: "#1C2321",
                                        textTransform: "uppercase",
                                      }}
                                    />
                                  ) : (
                                    <Chip
                                      label="Pending"
                                      className="square-chip"
                                      size="small"
                                      sx={{
                                        backgroundColor: "#55828b",
                                        color: "#ffffff",
                                        textTransform: "uppercase",
                                      }}
                                    />
                                  )
                                ) : (
                                  <Chip
                                    label="-"
                                    className="square-chip"
                                    size="small"
                                    sx={{
                                      backgroundColor: "#474044",
                                      color: "#ffffff",
                                      textTransform: "uppercase",
                                    }}
                                  />
                                )}
                              </Box>
                            </Box>
                          </Box>
                        )}
                        <Box
                          sx={{
                            px: 2,
                            py: 1,
                            borderBottom: 1,
                            borderColor: "divider",
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              justifyContent: "center",
                            }}
                          >
                            <Typography variant="body2">Address</Typography>
                          </Box>
                          <Box>
                            {screeningCase.address ? (
                              screeningCase.options?.address_verification ? (
                                screeningCase.address_document ? (
                                  <Chip
                                    label="Complete"
                                    className="square-chip"
                                    size="small"
                                    sx={{
                                      backgroundColor: "#8BBF9F",
                                      color: "#1C2321",
                                      textTransform: "uppercase",
                                    }}
                                  />
                                ) : (
                                  <Chip
                                    label="Pending document"
                                    className="square-chip"
                                    size="small"
                                    sx={{
                                      backgroundColor: "#55828b",
                                      color: "#ffffff",
                                      textTransform: "uppercase",
                                    }}
                                  />
                                )
                              ) : (
                                <Chip
                                  label="Complete"
                                  className="square-chip"
                                  size="small"
                                  sx={{
                                    backgroundColor: "#8BBF9F",
                                    color: "#1C2321",
                                    textTransform: "uppercase",
                                  }}
                                />
                              )
                            ) : (
                              <Chip
                                label="Pending"
                                className="square-chip"
                                size="small"
                                sx={{
                                  backgroundColor: "#55828b",
                                  color: "#ffffff",
                                  textTransform: "uppercase",
                                }}
                              />
                            )}
                          </Box>
                        </Box>
                        <Box
                          sx={{
                            px: 2,
                            py: 1,
                            borderBottom: 1,
                            borderColor: "divider",
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              justifyContent: "center",
                            }}
                          >
                            <Typography variant="body2">Sanctions check</Typography>
                          </Box>
                          <Box>
                            {screeningCase.options?.sanctions ? (
                              screeningCase.background_check ? (
                                <Chip
                                  label="Complete"
                                  className="square-chip"
                                  size="small"
                                  sx={{
                                    backgroundColor: "#8BBF9F",
                                    color: "#1C2321",
                                    textTransform: "uppercase",
                                  }}
                                />
                              ) : (
                                <Chip
                                  label="Pending"
                                  className="square-chip"
                                  size="small"
                                  sx={{
                                    backgroundColor: "#55828b",
                                    color: "#ffffff",
                                    textTransform: "uppercase",
                                  }}
                                />
                              )
                            ) : (
                              <Chip
                                label="-"
                                className="square-chip"
                                size="small"
                                sx={{
                                  backgroundColor: "#474044",
                                  color: "#ffffff",
                                  textTransform: "uppercase",
                                }}
                              />
                            )}
                          </Box>
                        </Box>
                        <Box
                          sx={{
                            px: 2,
                            py: 1,
                            borderBottom: 1,
                            borderColor: "divider",
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              justifyContent: "center",
                            }}
                          >
                            <Typography variant="body2">
                              {clientData.value.entity_type == 1
                                ? "Politically exposed person"
                                : "State owned organisation"}{" "}
                              check
                            </Typography>
                          </Box>
                          <Box>
                            {screeningCase.options?.peps ? (
                              screeningCase.background_check ? (
                                <Chip
                                  label="Complete"
                                  className="square-chip"
                                  size="small"
                                  sx={{
                                    backgroundColor: "#8BBF9F",
                                    color: "#1C2321",
                                    textTransform: "uppercase",
                                  }}
                                />
                              ) : (
                                <Chip
                                  label="Pending"
                                  className="square-chip"
                                  size="small"
                                  sx={{
                                    backgroundColor: "#55828b",
                                    color: "#ffffff",
                                    textTransform: "uppercase",
                                  }}
                                />
                              )
                            ) : (
                              <Chip
                                label="-"
                                className="square-chip"
                                size="small"
                                sx={{
                                  backgroundColor: "#474044",
                                  color: "#ffffff",
                                  textTransform: "uppercase",
                                }}
                              />
                            )}
                          </Box>
                        </Box>
                        <Box
                          sx={{
                            px: 2,
                            py: 1,
                            borderBottom: 1,
                            borderColor: "divider",
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              justifyContent: "center",
                            }}
                          >
                            <Typography variant="body2">Warning list check</Typography>
                          </Box>
                          <Box>
                            {screeningCase.options?.warnings ? (
                              screeningCase.background_check ? (
                                <Chip
                                  label="Complete"
                                  className="square-chip"
                                  size="small"
                                  sx={{
                                    backgroundColor: "#8BBF9F",
                                    color: "#1C2321",
                                    textTransform: "uppercase",
                                  }}
                                />
                              ) : (
                                <Chip
                                  label="Pending"
                                  className="square-chip"
                                  size="small"
                                  sx={{
                                    backgroundColor: "#55828b",
                                    color: "#ffffff",
                                    textTransform: "uppercase",
                                  }}
                                />
                              )
                            ) : (
                              <Chip
                                label="-"
                                className="square-chip"
                                size="small"
                                sx={{
                                  backgroundColor: "#474044",
                                  color: "#ffffff",
                                  textTransform: "uppercase",
                                }}
                              />
                            )}
                          </Box>
                        </Box>
                        <Box
                          sx={{
                            px: 2,
                            py: 1,
                            borderBottom: 1,
                            borderColor: "divider",
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              justifyContent: "center",
                            }}
                          >
                            <Typography variant="body2">Adverse media check</Typography>
                          </Box>
                          <Box>
                            {screeningCase.options?.adverse_media ? (
                              screeningCase.background_check ? (
                                <Chip
                                  label="Complete"
                                  className="square-chip"
                                  size="small"
                                  sx={{
                                    backgroundColor: "#8BBF9F",
                                    color: "#1C2321",
                                    textTransform: "uppercase",
                                  }}
                                />
                              ) : (
                                <Chip
                                  label="Pending"
                                  className="square-chip"
                                  size="small"
                                  sx={{
                                    backgroundColor: "#55828b",
                                    color: "#ffffff",
                                    textTransform: "uppercase",
                                  }}
                                />
                              )
                            ) : (
                              <Chip
                                label="-"
                                className="square-chip"
                                size="small"
                                sx={{
                                  backgroundColor: "#474044",
                                  color: "#ffffff",
                                  textTransform: "uppercase",
                                }}
                              />
                            )}
                          </Box>
                        </Box>
                        <Box>
                          <Box
                            sx={{
                              px: 2,
                              py: 1,
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            <Box
                              sx={{
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "center",
                              }}
                            >
                              <Typography variant="subtitle2">Case status</Typography>
                            </Box>
                            <Box>
                              {screeningCase.status == 0 && (
                                <Chip
                                  label="Input Required"
                                  className="square-chip"
                                  sx={{
                                    backgroundColor: "#2274A5",
                                    color: "#ffffff",
                                    textTransform: "uppercase",
                                  }}
                                />
                              )}
                              {screeningCase.status == 1 && (
                                <Chip
                                  label="Review Required"
                                  className="square-chip"
                                  sx={{
                                    backgroundColor: "#FFC107",
                                    color: "#1C2321",
                                    textTransform: "uppercase",
                                  }}
                                />
                              )}
                              {screeningCase.status == 2 && (
                                <Chip
                                  label="Complete"
                                  className="square-chip"
                                  sx={{
                                    backgroundColor: "#8BBF9F",
                                    color: "#1C2321",
                                    textTransform: "uppercase",
                                  }}
                                />
                              )}
                              {screeningCase.status == 3 && (
                                <Chip
                                  label="Failed"
                                  className="square-chip"
                                  sx={{
                                    backgroundColor: "#C83E4D",
                                    color: "#ffffff",
                                    textTransform: "uppercase",
                                  }}
                                />
                              )}
                              {screeningCase.status == 4 && (
                                <Chip
                                  className="square-chip"
                                  label="Assessment required"
                                  sx={{
                                    backgroundColor: "#D5E950",
                                    color: "#1C2321",
                                    textTransform: "uppercase",
                                  }}
                                />
                              )}
                              {screeningCase.status == 5 && (
                                <Chip
                                  className="square-chip"
                                  label="Waiting"
                                  sx={{
                                    backgroundColor: "#2274A5",
                                    color: "#ffffff",
                                    textTransform: "uppercase",
                                  }}
                                />
                              )}
                              {screeningCase.status == 6 && (
                                <Chip
                                  className="square-chip"
                                  label="Decision required"
                                  sx={{
                                    backgroundColor: "#D5E950",
                                    color: "#1C2321",
                                    textTransform: "uppercase",
                                  }}
                                />
                              )}
                            </Box>
                          </Box>
                        </Box>
                      </Box>
                    </Box>
                  ) : (
                    <Box>
                      <Lottie speed={2.0} options={defaultOptions} height={200} width={200} />
                    </Box>
                  )}
                </>
              )}
            </Box>
          </Grid>

          <Grid item xs={12} xl={6}>
            <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
              {entityData.value.entity_type == 1 && entityData.value.cra_score != 0 && (
                <Box sx={{ border: 1, borderColor: "divider" }}>
                  <Box sx={{ p: 2 }}>
                    <Typography variant="subtitle2">Risk assessment</Typography>
                  </Box>
                  <Divider />
                  <Box>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <Box sx={{ p: 2 }}>
                        <Typography variant="body2">Risk score</Typography>
                        <Typography variant="h6">{entityData.value.cra_score}</Typography>
                      </Box>
                      <Box
                        sx={{
                          p: 2,
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                        }}
                      >
                        <Chip
                          label={entityData.value.risk_rating}
                          className="square-chip"
                          sx={{
                            backgroundColor:
                              entityData.value.risk_tier == 1
                                ? "#0b0b0b"
                                : entityData.value.risk_tier == 2
                                ? "#FCCA46"
                                : "#C83E4D",
                            color:
                              entityData.value.risk_tier == 1
                                ? "#ffffff"
                                : entityData.value.risk_tier == 2
                                ? "#0b0b0b"
                                : "#ffffff",
                          }}
                        />
                      </Box>
                    </Box>
                    {flags?.length > 0 && (
                      <Box sx={{ px: 2 }}>
                        <Divider />
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "row",
                            gap: 1,
                          }}
                        >
                          {flags.map((flag, index) => (
                            <Box
                              key={index}
                              sx={{ py: 2 }}
                              title={flag.country?.code != "" ? flag.country.name : ""}
                            >
                              <Chip
                                label={flag.flag_type_label}
                                className="square-chip"
                                sx={{
                                  backgroundColor:
                                    flag.flag_type < 3
                                      ? "#C83E4D"
                                      : flag.flag_type < 5
                                      ? "#FCCA46"
                                      : flag.flag_type == 5
                                      ? "#2274A5"
                                      : "#FCCA46",
                                  color:
                                    flag.flag_type < 3
                                      ? "#ffffff"
                                      : flag.flag_type < 5
                                      ? "#0b0b0b"
                                      : flag.flag_type == 5
                                      ? "#0b0b0b"
                                      : "#0b0b0b",
                                  textTransform: "uppercase",
                                  cursor: "pointer",
                                }}
                              />
                            </Box>
                          ))}
                        </Box>
                      </Box>
                    )}
                  </Box>
                </Box>
              )}

              {entityData.value.entity_type == 2 && entityData.value.bra_score != 0 && (
                <Box sx={{ border: 1, borderColor: "divider" }}>
                  <Box sx={{ p: 2 }}>
                    <Typography variant="subtitle2">Risk assessment</Typography>
                  </Box>
                  <Divider />
                  <Box>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <Box sx={{ p: 2 }}>
                        <Typography variant="subtitle2">Risk score</Typography>
                        <Typography variant="h4">{entityData.value.bra_score}</Typography>
                      </Box>
                      <Box
                        sx={{
                          p: 2,
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                        }}
                      >
                        <Chip
                          label={entityData.value.risk_rating}
                          className="square-chip"
                          sx={{
                            backgroundColor:
                              entityData.value.risk_tier == 1
                                ? "#0b0b0b"
                                : entityData.value.risk_tier == 2
                                ? "#FCCA46"
                                : "#C83E4D",
                            color:
                              entityData.value.risk_tier == 1
                                ? "#ffffff"
                                : entityData.value.risk_tier == 2
                                ? "#0b0b0b"
                                : "#ffffff",
                          }}
                        />
                      </Box>
                    </Box>
                    {flags?.length > 0 && (
                      <Box sx={{ px: 2 }}>
                        <Divider />
                        <Box sx={{ display: "flex", flexDirection: "row" }}>
                          {flags.map((flag, index) => (
                            <Box
                              key={index}
                              sx={{ py: 2 }}
                              title={flag.country?.code != "" ? flag.country.name : ""}
                            >
                              <Chip
                                label={flag.flag_type_label}
                                className="square-chip"
                                sx={{
                                  backgroundColor:
                                    flag.flag_type < 3
                                      ? "#C83E4D"
                                      : flag.flag_type < 5
                                      ? "#FCCA46"
                                      : flag.flag_type == 5
                                      ? "#2274A5"
                                      : "#FCCA46",
                                  color:
                                    flag.flag_type < 3
                                      ? "#ffffff"
                                      : flag.flag_type < 5
                                      ? "#0b0b0b"
                                      : flag.flag_type == 5
                                      ? "#0b0b0b"
                                      : "#0b0b0b",
                                  textTransform: "uppercase",
                                  cursor: "pointer",
                                }}
                              />
                            </Box>
                          ))}
                        </Box>
                      </Box>
                    )}
                  </Box>
                </Box>
              )}

              {/* <Box sx={{ border: 1, borderColor: "divider" }}>
                <Box sx={{ p: 2 }}>
                  <Typography variant="subtitle2">Document search</Typography>
                </Box>
                <Divider />
                <Box sx={{ p: 2 }}>
                  <DocumentSearch />
                </Box>
              </Box> */}

              <Box sx={{ border: 1, borderColor: "divider" }}>
                <Box sx={{ p: 2 }}>
                  <Typography variant="subtitle2">Linked entities and groups</Typography>
                </Box>
                <Divider />
                <Box>
                  {linkagesIsLoaded ? (
                    <Box>
                      {linkedEntities?.length > 0 ? (
                        linkedEntities.value.map((linkage, index) => (
                          <Box
                            key={index}
                            sx={{
                              p: 2,
                              borderTop: index > 0 ? 1 : 0,
                              borderColor: "divider",
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            <Box>{linkage.name}</Box>
                            <Box>{linkage.entity_type} - risk-score - flags </Box>
                          </Box>
                        ))
                      ) : (
                        <Box>
                          <OnItsOwn message={`There are no linked entities`} />
                        </Box>
                      )}
                    </Box>
                  ) : (
                    <Box>
                      <Lottie speed={2.0} options={defaultOptions} height={200} width={200} />
                    </Box>
                  )}
                </Box>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default Overview;
