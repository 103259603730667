import { React, useState } from "react";

import { Box, FormControl, InputLabel, MenuItem, Select, TextField } from "@mui/material";

function Individual() {
  const titleOptions = [
    { value: "Mr", label: "Mr" },
    { value: "Mrs", label: "Mrs" },
    { value: "Miss", label: "Miss" },
    { value: "Ms", label: "Ms" },
    { value: "Dr", label: "Dr" },
    { value: "Prof", label: "Prof" },
    { value: "Rev", label: "Rev" },
    { value: "Sir", label: "Sir" },
    { value: "Madam", label: "Madam" },
    { value: "Lord", label: "Lord" },
    { value: "Lady", label: "Lady" },
    { value: "Fr", label: "Father" },
    { value: "Sr", label: "Sister" },
    { value: "Imam", label: "Imam" },
    { value: "Sheikh", label: "Sheikh" },
    { value: "Rabbi", label: "Rabbi" },
    { value: "Duke", label: "Duke" },
    { value: "Duchess", label: "Duchess" },
    { value: "Earl", label: "Earl" },
    { value: "Count", label: "Count" },
    { value: "Countess", label: "Countess" },
    { value: "Baron", label: "Baron" },
    { value: "Baroness", label: "Baroness" },
    { value: "Capt", label: "Captain" },
    { value: "Lt", label: "Lieutenant" },
    { value: "Col", label: "Colonel" },
    { value: "Gen", label: "General" },
  ];

  const [title, setTitle] = useState("");
  const [firstName, setFirstName] = useState("");
  const [middleNames, setMiddleNames] = useState("");
  const [lastName, setLastName] = useState("");

  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
      <FormControl fullWidth>
        <InputLabel id="name-title-label">Title</InputLabel>
        <Select labelId="name-title" id="name-title" value={title} label="Title" onChange={(e) => setTitle(e.target.value)}>
          {titleOptions.map((option, index) => (
            <MenuItem key={index} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      <TextField
        fullWidth
        id="outlined-basic"
        label="First name"
        variant="outlined"
        value={firstName}
        onChange={(e) => setFirstName(e.target.value)}
      />

      <TextField
        fullWidth
        id="outlined-basic"
        label="Middle name(s)"
        variant="outlined"
        value={middleNames}
        onChange={(e) => setMiddleNames(e.target.value)}
        helperText="If applicable, separate with spaces"
      />

      <TextField fullWidth id="outlined-basic" label="Last name" variant="outlined" value={lastName} onChange={(e) => setLastName(e.target.value)} />
    </Box>
  );
}

export default Individual;
