import { React, useState, useEffect } from "react";

import authHeader from "../../../../../api/authHeader";

import HandleToast from "../../../../../components/functions/HandleToast";
import ErrorHandling from "../../../../../components/functions/HandleError";

import { PhoneNumberUtil, PhoneNumberFormat } from "google-libphonenumber";
import Lottie from "react-lottie";
import successLottie from "../../../../../components/lotties/success.json";
import loadingLottie from "../../../../../components/lotties/loading-dots.json";

import { Container, Typography, Grid, TextField, Box, Divider } from "@mui/material";
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { MuiTelInput } from "mui-tel-input";

const Contact = ({ partner, partnerUser, partnerData }) => {
  const useAuthHeader = authHeader();
  const handleError = ErrorHandling();
  const displayToast = HandleToast();

  const [isLoaded, setIsLoaded] = useState(false);
  const [processing, setProcessing] = useState(false);
  const [displaySuccess, setDisplaySuccess] = useState(false);

  const [diallingCode, setDiallingCode] = useState("");
  const [phoneCountry, setPhoneCountry] = useState("");
  const [telephoneNumber, setTelephoneNumber] = useState("");
  const [phoneValue, setPhoneValue] = useState("");
  const [emailAddress, setEmailAddress] = useState(partnerUser.value.email_address ? partnerUser.value.email_address : "");

  const [confirmTelephoneDialog, setConfirmTelephoneDialog] = useState(false);
  const [confirmEmailDialog, setConfirmEmailDialog] = useState(false);

  const defaultOptions = {
    loop: isLoaded ? false : true,
    autoplay: true,
    animationData: isLoaded ? successLottie : loadingLottie,
  };

  const getTelephoneData = async () => {
    try {
      const response = await partner.post(
        `/v1/entity/people/person/data/telephone-number`,
        JSON.stringify({
          person_uuid: partnerUser.value.person_uuid,
        }),
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Basic " + useAuthHeader.base64encodedData,
            service: "partners",
          },

          //withCredentials: true,
        }
      );

      if (response.data.telephone_number != "") {
        setPhoneValue("+" + response.data.dialling_code + response.data.telephone_number);
        setPhoneCountry(response.data.country.code.toLowerCase());
        setDiallingCode(response.data.dialling_code);
        setTelephoneNumber(response.data.telephone_number);
      } else {
        setPhoneValue("");
        setPhoneCountry("");
        setDiallingCode("");
        setTelephoneNumber("");
      }
      setIsLoaded(true);
    } catch (err) {
      console.log(err);
      handleError(err);
    }
  };

  useEffect(() => {
    getTelephoneData();
  }, []);

  const handleSaveTelephoneNumber = async (e) => {
    e.preventDefault();
    setProcessing(true);

    try {
      const response = await partner.post(
        `/v1/entity/people/person/telephone-number/update`,
        JSON.stringify({
          person_uuid: partnerUser.value.person_uuid,
          telephone_number: telephoneNumber,
          dialling_code: diallingCode,
          telephone_country: phoneCountry,
        }),
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Basic " + useAuthHeader.base64encodedData,
            service: "partners",
          },

          //withCredentials: true,
        }
      );

      setDisplaySuccess(true);

      setTimeout(() => {
        setConfirmTelephoneDialog(false);
        setDisplaySuccess(false);
        displayToast(`Telephone number updated successfully`, () => {
          setProcessing(false);
        });
      }, 2000);
    } catch (err) {
      console.log(err);
      handleError(err);
      setProcessing(false);
    }
  };

  const handleSaveEmailAddress = async (e) => {
    e.preventDefault();
    setProcessing(true);

    try {
      const response = await entities.post(
        `/v1/people/update/contact/email`,
        JSON.stringify({
          person_uuid: partnerUser.value.person_uuid,
          email_address: emailAddress,
        }),
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Basic " + useAuthHeader.base64encodedData,
            service: "partners",
          },

          //withCredentials: true,
        }
      );

      setDisplaySuccess(true);

      setTimeout(() => {
        setConfirmTelephoneDialog(false);
        setDisplaySuccess(false);
        displayToast(`Email address updated successfully`, () => {
          setProcessing(false);
        });
      }, 2000);
    } catch (err) {
      handleError(err);
      setProcessing(false);
    }
  };

  return (
    <>
      {!isLoaded ? (
        <>
          <Box>
            <Lottie speed={2.0} options={defaultOptions} height={200} width={200} />
          </Box>
        </>
      ) : (
        <>
          <Box sx={{ borderTop: 1, borderColor: "divider", p: 2 }}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} md={6}>
                <Box sx={{ border: 1, borderColor: "divider", p: 2 }}>
                  <Typography sx={{ mb: 0 }} variant="h6" gutterBottom>
                    What is your telephone number?
                  </Typography>
                </Box>
                <Box sx={{ p: 2, border: 1, borderColor: "divider", borderTop: 0 }}>
                  <MuiTelInput
                    fullWidth
                    defaultCountry="IM"
                    value={phoneValue}
                    onChange={(phone, meta) => {
                      setTelephoneNumber(meta.nationalNumber);
                      setPhoneCountry(meta.countryCode);
                      setDiallingCode(meta.countryCallingCode);
                      setPhoneValue(phone);
                    }}
                  />
                  <Box sx={{ mt: 2, display: "flex", justifyContent: "flex-end" }}>
                    <LoadingButton
                      disableElevation
                      loading={processing}
                      onClick={() => setConfirmTelephoneDialog(true)}
                      variant="contained"
                      color="primary"
                    >
                      Save changes
                    </LoadingButton>
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={12} sm={12} md={6}>
                <Box sx={{ border: 1, borderColor: "divider", p: 2 }}>
                  <Typography sx={{ mb: 0 }} variant="h6" gutterBottom>
                    What is your email address?
                  </Typography>
                </Box>
                <Box sx={{ p: 2, border: 1, borderColor: "divider", borderTop: 0 }}>
                  <TextField
                    fullWidth
                    id="outlined-basic"
                    label="Email address"
                    variant="outlined"
                    value={emailAddress}
                    onChange={(e) => setEmailAddress(e.target.value)}
                  />
                  <Box sx={{ mt: 2, display: "flex", justifyContent: "flex-end" }}>
                    <LoadingButton
                      disableElevation
                      loading={processing}
                      onClick={() => setConfirmEmailDialog(true)}
                      variant="contained"
                      color="primary"
                    >
                      Save changes
                    </LoadingButton>
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </Box>

          <Dialog
            open={confirmTelephoneDialog}
            onClose={() => setConfirmTelephoneDialog(false)}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">{displaySuccess ? "Complete" : "Please confirm"}</DialogTitle>
            <DialogContent>
              {displaySuccess ? (
                <Lottie options={defaultOptions} height={100} width={100} />
              ) : (
                <DialogContentText id="alert-dialog-description">Are you sure you want to change your telephone number?</DialogContentText>
              )}
            </DialogContent>
            <DialogActions>
              {!displaySuccess && (
                <LoadingButton disableElevation loading={processing} onClick={() => setConfirmTelephoneDialog(false)} color="primary">
                  Cancel
                </LoadingButton>
              )}

              <LoadingButton
                disableElevation
                fullWidth={displaySuccess}
                loading={processing}
                onClick={(e) => {
                  handleSaveTelephoneNumber(e);
                }}
                variant="contained"
                color="success"
                autoFocus
              >
                Confirm
              </LoadingButton>
            </DialogActions>
          </Dialog>

          <Dialog
            open={confirmEmailDialog}
            onClose={() => setConfirmEmailDialog(false)}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">{displaySuccess ? "Complete" : "Please confirm"}</DialogTitle>
            <DialogContent>
              {displaySuccess ? (
                <Lottie options={defaultOptions} height={100} width={100} />
              ) : (
                <DialogContentText id="alert-dialog-description">Are you sure you want to change your email address?</DialogContentText>
              )}
            </DialogContent>
            <DialogActions>
              {!displaySuccess && (
                <LoadingButton disableElevation loading={processing} onClick={() => setConfirmEmailDialog(false)} color="primary">
                  Cancel
                </LoadingButton>
              )}

              <LoadingButton
                disableElevation
                fullWidth={displaySuccess}
                loading={processing}
                onClick={(e) => {
                  handleSaveEmailAddress(e);
                }}
                variant="contained"
                color="success"
                autoFocus
              >
                Confirm
              </LoadingButton>
            </DialogActions>
          </Dialog>
        </>
      )}
    </>
  );
};

export default Contact;
