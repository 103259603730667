import { React, useEffect, useState } from "react";

import partner from "../../../../../api/partner";
import authHeader from "../../../../../api/authHeader";

import HandleError from "../../../../../components/functions/HandleError";
import HandleToast from "../../../../../components/functions/HandleToast";

import Lottie from "react-lottie";
import loadingLottie from "../../../../../components/lotties/loading-dots.json";

import DataTable from "react-data-table-component";

import {
  Typography,
  Box,
  Button,
  Divider,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Chip,
  Collapse,
  InputAdornment,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";

import {
  Block,
  Fingerprint,
  Groups,
  Home,
  Language,
  LocalPolice,
  MailLock,
  MonitorHeartOutlined,
  Newspaper,
  Phone,
  ReportProblem,
  Schema,
  Delete,
  Diversity2,
  Percent,
  Hail,
  ContentCopy,
  PriceCheck,
} from "@mui/icons-material";

const CaseTemplates = ({ partner, partnerUser, partnerData }) => {
  const useAuthHeader = authHeader();

  const handleError = HandleError();
  const displayToast = HandleToast();

  const [isLoaded, setIsLoaded] = useState(false);
  const [processing, setProcessing] = useState(false);
  const [data, setData] = useState([]);

  const [caseType, setCaseType] = useState(1); // 1 kyc | 2 kyb
  const [manageTemplate, setManageTemplate] = useState(false);

  const [activeTemplate, setActiveTemplate] = useState("");

  const [templateName, setTemplateName] = useState("");

  const [ownership, setOwnership] = useState(false);
  const [officers, setOfficers] = useState(false);

  const [directControlThreshold, setDirectControlThreshold] = useState("");
  const [indirectControlThreshold, setIndirectControlThreshold] = useState("");

  const [telephone, setTelephone] = useState(false);
  const [verifyTelephone, setVerifyTelephone] = useState(false);

  const [email, setEmail] = useState(false);
  const [verifyEmail, setVerifyEmail] = useState(false);

  const [citizenship, setCitizenship] = useState(false);

  const [address, setAddress] = useState(false);
  const [addressValidation, setAddressValidation] = useState(false);

  const [peps, setPeps] = useState(false);
  const [sanctions, setSanctions] = useState(false);
  const [warnings, setWarnings] = useState(false);
  const [adverseMedia, setAdverseMedia] = useState(false);

  const [proofOfLife, setProofOfLife] = useState(false);
  const [identityVerification, setIdentityVerification] = useState(false);

  const [declarePEP, setDeclarePEP] = useState(false);
  const [fatca, setFatca] = useState(false);

  const [sourceOfWealth, setSourceOfWealth] = useState(false);
  const [corporateDocuments, setCorporateDocuments] = useState(false);

  const [isDefault, setIsDefault] = useState(false);
  const [deleteTemplateDialog, setDeleteTemplateDialog] = useState(false);
  const [defaultTemplateDialog, setDefaultTemplateDialog] = useState(false);

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: loadingLottie,
  };

  const customStyles = {
    headCells: {
      style: {
        paddingLeft: "8px", // override the cell padding for head cells
        paddingRight: "8px",
        fontWeight: "bold",
      },
    },
    cells: {
      style: {
        paddingLeft: "8px", // override the cell padding for data cells
        paddingRight: "8px",
      },
    },
  };

  const columns = [
    {
      name: "Template name",
      sortable: true,
      width: "35%",
      selector: (row) => row.name,
      cell: (row) => (
        <Box className="link" onClick={() => getTemplateData(row.uuid)} sx={{ fontSize: "13px" }}>
          {row.name}
        </Box>
      ),
    },
    {
      name: "Type",
      width: "10%",
      sortable: true,
      selector: (row) => row.template_type,
      cell: (row) => (
        <Box>
          {row.template_type == 1 ? (
            <Chip
              className="square-chip"
              label="KYC"
              sx={{ backgroundColor: "#55828b", color: "#ffffff", textTransform: "uppercase" }}
            />
          ) : (
            <Chip
              className="square-chip"
              label="KYB"
              sx={{ backgroundColor: "#474044", color: "#ffffff", textTransform: "uppercase" }}
            />
          )}
        </Box>
      ),
    },
    {
      name: "",
      width: "5%",
      cell: (row) => (
        <Box>
          {row.is_default && (
            <Chip
              className="square-chip"
              label="Default"
              sx={{ backgroundColor: "#2274A5", color: "#ffffff", textTransform: "uppercase" }}
            />
          )}
        </Box>
      ),
    },
    {
      name: "",
      width: "50%",
      cell: (row) => (
        <Box
          sx={{
            display: "flex",
            justifyContent: "end",
            justifyItems: "center",
            gap: 1,
            color: "#999999",
            fontSize: "13px",
            width: "100%",
          }}
        >
          {row.options?.email_verification && (
            <Box title="Email verification">
              <MailLock title="Email verification" />
            </Box>
          )}
          {row.options?.telephone_verification && (
            <Box title="Telephone verification">
              <Phone />
            </Box>
          )}
          {row.options?.citizenship && (
            <Box title="Citizenship">
              <Language />
            </Box>
          )}
          {row.options?.address_verification && (
            <Box title="Address validation">
              <Home />
            </Box>
          )}
          {row.options?.identity && (
            <Box title="Identity verification">
              <Fingerprint title="Identity verification" />
            </Box>
          )}
          {row.options?.proof_of_life && (
            <Box title="Proof of life">
              <MonitorHeartOutlined />
            </Box>
          )}
          {row.options?.officers && (
            <Box title="Officers and management">
              <Groups />
            </Box>
          )}
          {row.options?.ownership && (
            <Box title="Ownership">
              <Schema />
            </Box>
          )}
          {row.options?.sanctions && (
            <Box title="Sanctions screening">
              <Block />
            </Box>
          )}
          {row.options?.pep_declaration && (
            <Box title="PEP self declaration">
              <Hail />
            </Box>
          )}
          {row.options?.peps && (
            <Box title="PEP search">
              <LocalPolice />
            </Box>
          )}
          {row.options?.warnings && (
            <Box title="Warning list search">
              <ReportProblem />
            </Box>
          )}
          {row.options?.adverse_media && (
            <Box title="Adverse media search">
              <Newspaper />
            </Box>
          )}
          {row.options?.fatca && (
            <Box title="FATCA identification">
              <Diversity2 />
            </Box>
          )}
          {row.options?.corporate_documents && (
            <Box title="Corporate documents">
              <ContentCopy />
            </Box>
          )}
          {row.options?.source_of_wealth && (
            <Box title="Source of wealth">
              <PriceCheck />
            </Box>
          )}
        </Box>
      ),
    },
  ];

  const getTemplates = async () => {
    try {
      const response = await partner.post(`/v1/screening/templates/list`, JSON.stringify({}), {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Basic " + useAuthHeader.base64encodedData,
          service: "partners",
        },

        //withCredentials: true,
      });

      if (response.data.results > 0) {
        setData(response.data.entries);
      } else {
        setData([]);
      }
      setIsLoaded(true);
    } catch (err) {
      handleError(err);
    }
  };

  const getTemplateData = async (uuid) => {
    try {
      const response = await partner.post(
        `/v1/screening/templates/data`,
        JSON.stringify({
          template_uuid: uuid,
        }),
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Basic " + useAuthHeader.base64encodedData,
            service: "partners",
          },

          //withCredentials: true,
        }
      );

      setActiveTemplate(response.data.uuid);
      setCaseType(response.data.template_type);

      setOwnership(response.data.options.ownership);
      setOfficers(response.data.options.officers);

      setDirectControlThreshold(response.data.options.direct_control_threshold.toString());
      setIndirectControlThreshold(response.data.options.indirect_control_threshold.toString());

      setSourceOfWealth(response.data.options.source_of_wealth);
      setCorporateDocuments(response.data.options.corporate_documents);

      setTemplateName(response.data.name);

      setTelephone(response.data.options.telephone_number);
      setVerifyTelephone(response.data.options.telephone_verification);

      setEmail(response.data.options.email_address);
      setVerifyEmail(response.data.options.email_verification);

      setCitizenship(response.data.options.citizenship);

      setAddress(response.data.options.address);
      setAddressValidation(response.data.options.address_verification);

      setPeps(response.data.options.peps);
      setSanctions(response.data.options.sanctions);
      setWarnings(response.data.options.warnings);
      setAdverseMedia(response.data.options.adverse_media);

      setProofOfLife(response.data.options.proof_of_life);
      setIdentityVerification(response.data.options.identity);
      setIsDefault(response.data.is_default);

      setFatca(response.data.options.fatca);
      setDeclarePEP(response.data.options.pep_declaration);

      setManageTemplate(true);
    } catch (err) {
      handleError(err);
    }
  };

  const handleCreateTemplate = async (e) => {
    e.preventDefault();
    setProcessing(true);
    try {
      const response = await partner.post(
        `/v1/screening/templates/create`,
        JSON.stringify({
          template_name: templateName,
          template_type: caseType,

          officers: officers,
          ownership: ownership,
          direct_control_threshold: directControlThreshold,
          indirect_control_threshold: indirectControlThreshold,

          citizenship: citizenship,
          email_address: email,
          email_verification: verifyEmail,

          telephone_number: telephone,
          telephone_verification: verifyTelephone,

          address: address,
          address_verification: addressValidation,

          sanctions: sanctions,
          peps: peps,
          warnings: warnings,
          adverse_media: adverseMedia,

          identity: identityVerification,
          proof_of_life: proofOfLife,

          fatca: fatca,
          pep_declaration: declarePEP,

          source_of_wealth: sourceOfWealth,
          corporate_documents: corporateDocuments,
        }),
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Basic " + useAuthHeader.base64encodedData,
            service: "partners",
          },

          //withCredentials: true,
        }
      );

      getTemplates();

      displayToast("Template created successfully", () => {
        setProcessing(false);
        resetForm();
        setManageTemplate(false);
      });
    } catch (err) {
      console.log(err);
      handleError(err);
      setProcessing(false);
    }
  };

  const handleUpdateTemplate = async (e) => {
    e.preventDefault();
    setProcessing(true);
    try {
      const response = await partner.post(
        `/v1/screening/templates/update`,
        JSON.stringify({
          template_uuid: activeTemplate,

          template_name: templateName,
          citizenship: citizenship,
          email_address: email,
          email_verification: verifyEmail,

          telephone_number: telephone,
          telephone_verification: verifyTelephone,

          address: address,
          address_verification: addressValidation,

          sanctions: sanctions,
          peps: peps,
          warnings: warnings,
          adverse_media: adverseMedia,

          identity: identityVerification,
          proof_of_life: proofOfLife,

          officers: officers,
          ownership: ownership,
          direct_control_threshold: directControlThreshold,
          indirect_control_threshold: indirectControlThreshold,

          fatca: fatca,
          pep_declaration: declarePEP,

          source_of_wealth: sourceOfWealth,
          corporate_documents: corporateDocuments,
        }),
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Basic " + useAuthHeader.base64encodedData,
            service: "partners",
          },

          //withCredentials: true,
        }
      );

      getTemplates();

      displayToast("Template updated successfully", () => {
        setProcessing(false);
        resetForm();
        setManageTemplate(false);
      });
    } catch (err) {
      console.log(err);
      handleError(err);
      setProcessing(false);
    }
  };

  const handleSetDefaultTemplate = async () => {
    setProcessing(true);
    try {
      const response = await partner.post(
        `/v1/screening/templates/make-default`,
        JSON.stringify({
          template_uuid: activeTemplate,
        }),
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Basic " + useAuthHeader.base64encodedData,
            service: "partners",
          },

          //withCredentials: true,
        }
      );

      getTemplates();
      setDefaultTemplateDialog(false);

      displayToast("New default template selected", () => {
        resetForm();
        setProcessing(false);
        setManageTemplate(false);
      });
    } catch (err) {
      console.log(err);
      handleError(err);
      setProcessing(false);
    }
  };

  const handleDeleteTemplate = async () => {
    setProcessing(true);
    try {
      const response = await partner.post(
        `/v1/screening/templates/delete`,
        JSON.stringify({
          partner_uuid: partnerUser.value.partner_uuid,
          template_uuid: activeTemplate,
        }),
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Basic " + useAuthHeader.base64encodedData,
            service: "partners",
          },

          //withCredentials: true,
        }
      );

      getTemplates();
      setDeleteTemplateDialog(false);

      displayToast("Template deleted successfully", () => {
        setProcessing(false);
        resetForm();
        setManageTemplate(false);
      });
    } catch (err) {
      handleError(err);
      setProcessing(false);
    }
  };

  const resetForm = () => {
    setCaseType(1);
    setManageTemplate(false);
    setActiveTemplate("");
    setTemplateName("");
    setOwnership(false);
    setOfficers(false);
    setDirectControlThreshold("");
    setIndirectControlThreshold("");
    setTelephone(false);
    setVerifyTelephone(false);
    setEmail(false);
    setVerifyEmail(false);
    setCitizenship(false);
    setAddress(false);
    setAddressValidation(false);
    setPeps(false);
    setSanctions(false);
    setWarnings(false);
    setAdverseMedia(false);
    setProofOfLife(false);
    setIdentityVerification(false);
    setFatca(false);
    setDeclarePEP(false);
    setSourceOfWealth(false);
    setCorporateDocuments(false);
    setIsDefault(false);
  };

  useEffect(() => {
    getTemplates();
  }, []);

  return (
    <>
      {isLoaded ? (
        <>
          <Box sx={{ borderTop: 1, borderColor: "divider" }}>
            <Box sx={{ p: 2, display: "flex", justifyContent: "space-between" }}>
              <Typography variant="h6">
                {manageTemplate
                  ? activeTemplate
                    ? "Manage template"
                    : "Create a new template"
                  : "Case templates"}
              </Typography>
              <Box sx={{ display: "flex", gap: 1 }}>
                <Button
                  onClick={() => {
                    resetForm();
                    setManageTemplate(!manageTemplate);
                  }}
                  variant="contained"
                  color="primary"
                >
                  {manageTemplate ? "Close" : "New template"}
                </Button>
              </Box>
            </Box>
            <Divider />
            {manageTemplate ? (
              <Box sx={{ p: 2 }}>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={12} md={12} lg={6}>
                    <Box sx={{ display: "flex", flexDirection: "column" }}>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          border: 1,
                          borderColor: "divider",
                        }}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            p: 2,
                          }}
                        >
                          <Typography variant="subtitle2">Template type</Typography>
                        </Box>
                        <Box sx={{ p: 2 }}>
                          <ToggleButtonGroup
                            color="primary"
                            value={caseType}
                            exclusive
                            onChange={(e, newValue) => {
                              if (newValue !== null) setCaseType(newValue);
                            }}
                            aria-label="Case type"
                          >
                            <ToggleButton disabled={activeTemplate ? true : false} value={1}>
                              KYC
                            </ToggleButton>
                            <ToggleButton disabled={activeTemplate ? true : false} value={2}>
                              KYB
                            </ToggleButton>
                          </ToggleButtonGroup>
                        </Box>
                      </Box>

                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          border: 1,
                          borderTop: 0,
                          borderColor: "divider",
                        }}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            p: 2,
                          }}
                        >
                          <Typography variant="subtitle2">Template name</Typography>
                        </Box>
                        <Box sx={{ p: 2, width: "65%" }}>
                          <TextField
                            label="Template name"
                            fullWidth
                            variant="outlined"
                            value={templateName}
                            onChange={(e) => {
                              setTemplateName(e.target.value);
                            }}
                          />
                        </Box>
                      </Box>

                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          border: 1,
                          borderTop: 0,
                          borderColor: "divider",
                        }}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            p: 1,
                            backgroundColor: "primary.main",
                            width: "100%",
                          }}
                        >
                          <Typography color="white.main" variant="h6">
                            Data capture and validation options
                          </Typography>
                        </Box>
                      </Box>

                      {caseType === 1 && (
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            border: 1,
                            borderTop: 0,
                            borderColor: "divider",
                          }}
                        >
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              justifyContent: "center",
                              p: 2,
                            }}
                          >
                            <Typography variant="subtitle2">Capture their email address</Typography>
                          </Box>
                          <Box sx={{ p: 2 }}>
                            <ToggleButtonGroup
                              size="small"
                              color={email ? "primary" : "danger"}
                              value={email}
                              exclusive
                              onChange={(e, newValue) => {
                                if (newValue !== null) setEmail(newValue);
                              }}
                              aria-label="Cature email address"
                            >
                              <ToggleButton value={true}>Enabled</ToggleButton>
                              <ToggleButton value={false}>Disabled</ToggleButton>
                            </ToggleButtonGroup>
                          </Box>
                        </Box>
                      )}

                      {caseType === 1 && (
                        <Collapse in={email} exit={!email} orientation="vertical">
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "space-between",
                              border: 1,
                              borderTop: 0,
                              borderColor: "divider",
                            }}
                          >
                            <Box
                              sx={{
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "center",
                                p: 2,
                              }}
                            >
                              <Typography variant="subtitle2">
                                Verify their email address
                              </Typography>
                            </Box>
                            <Box sx={{ p: 2 }}>
                              <ToggleButtonGroup
                                size="small"
                                color={verifyEmail ? "primary" : "danger"}
                                value={verifyEmail}
                                exclusive
                                onChange={(e, newValue) => {
                                  if (newValue !== null) setVerifyEmail(newValue);
                                }}
                                aria-label="Verify their email address"
                              >
                                <ToggleButton value={true}>Enabled</ToggleButton>
                                <ToggleButton value={false}>Disabled</ToggleButton>
                              </ToggleButtonGroup>
                            </Box>
                          </Box>
                        </Collapse>
                      )}

                      {caseType === 1 && (
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            border: 1,
                            borderTop: 0,
                            borderColor: "divider",
                          }}
                        >
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              justifyContent: "center",
                              p: 2,
                            }}
                          >
                            <Typography variant="subtitle2">
                              Capture their telephone number
                            </Typography>
                          </Box>
                          <Box sx={{ p: 2 }}>
                            <ToggleButtonGroup
                              size="small"
                              color={telephone ? "primary" : "danger"}
                              value={telephone}
                              exclusive
                              onChange={(e, newValue) => {
                                if (newValue !== null) setTelephone(newValue);
                              }}
                              aria-label="Cature telephone number"
                            >
                              <ToggleButton value={true}>Enabled</ToggleButton>
                              <ToggleButton value={false}>Disabled</ToggleButton>
                            </ToggleButtonGroup>
                          </Box>
                        </Box>
                      )}

                      {caseType === 1 && (
                        <Collapse in={telephone} exit={!telephone} orientation="vertical">
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "space-between",
                              border: 1,
                              borderTop: 0,
                              borderColor: "divider",
                            }}
                          >
                            <Box
                              sx={{
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "center",
                                p: 2,
                              }}
                            >
                              <Typography variant="subtitle2">
                                Verify their telephone number
                              </Typography>
                            </Box>
                            <Box sx={{ p: 2 }}>
                              <ToggleButtonGroup
                                size="small"
                                color={verifyTelephone ? "primary" : "danger"}
                                value={verifyTelephone}
                                exclusive
                                onChange={(e, newValue) => {
                                  if (newValue !== null) setVerifyTelephone(newValue);
                                }}
                                aria-label="Verify their telephone number"
                              >
                                <ToggleButton value={true}>Enabled</ToggleButton>
                                <ToggleButton value={false}>Disabled</ToggleButton>
                              </ToggleButtonGroup>
                            </Box>
                          </Box>
                        </Collapse>
                      )}

                      {caseType === 1 && (
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            border: 1,
                            borderTop: 0,
                            borderColor: "divider",
                          }}
                        >
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              justifyContent: "center",
                              p: 2,
                            }}
                          >
                            <Typography variant="subtitle2">
                              Require their citizenship and birth country
                            </Typography>
                          </Box>
                          <Box sx={{ p: 2 }}>
                            <ToggleButtonGroup
                              size="small"
                              color={citizenship ? "primary" : "danger"}
                              value={citizenship}
                              exclusive
                              onChange={(e, newValue) => {
                                if (newValue !== null) setCitizenship(newValue);
                              }}
                              aria-label="Cature citizenship"
                            >
                              <ToggleButton value={true}>Enabled</ToggleButton>
                              <ToggleButton value={false}>Disabled</ToggleButton>
                            </ToggleButtonGroup>
                          </Box>
                        </Box>
                      )}

                      {caseType === 1 && (
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            border: 1,
                            borderTop: 0,
                            borderColor: "divider",
                          }}
                        >
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              justifyContent: "center",
                              p: 2,
                            }}
                          >
                            <Typography variant="subtitle2">
                              Require their residential address
                            </Typography>
                          </Box>
                          <Box sx={{ p: 2 }}>
                            <ToggleButtonGroup
                              size="small"
                              color={address ? "primary" : "danger"}
                              value={address}
                              exclusive
                              onChange={(e, newValue) => {
                                if (newValue !== null) setAddress(newValue);
                              }}
                              aria-label="Cature address"
                            >
                              <ToggleButton value={true}>Enabled</ToggleButton>
                              <ToggleButton value={false}>Disabled</ToggleButton>
                            </ToggleButtonGroup>
                          </Box>
                        </Box>
                      )}

                      {caseType == 2 ? (
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            border: 1,
                            borderTop: 0,
                            borderColor: "divider",
                          }}
                        >
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              justifyContent: "center",
                              p: 2,
                            }}
                          >
                            <Typography variant="subtitle2">
                              Validate {caseType == 1 ? "their residential" : "the registered"}{" "}
                              address
                            </Typography>
                          </Box>
                          <Box sx={{ p: 2 }}>
                            <ToggleButtonGroup
                              size="small"
                              color={addressValidation ? "primary" : "danger"}
                              value={addressValidation}
                              exclusive
                              onChange={(e, newValue) => {
                                if (newValue !== null) setAddressValidation(newValue);
                              }}
                              aria-label="Validate address"
                            >
                              <ToggleButton value={true}>Enabled</ToggleButton>
                              <ToggleButton value={false}>Disabled</ToggleButton>
                            </ToggleButtonGroup>
                          </Box>
                        </Box>
                      ) : (
                        <Collapse in={address} exit={!address}>
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "space-between",
                              border: 1,
                              borderTop: 0,
                              borderColor: "divider",
                            }}
                          >
                            <Box
                              sx={{
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "center",
                                p: 2,
                              }}
                            >
                              <Typography variant="subtitle2">
                                Validate {caseType == 1 ? "their residential" : "the registered"}{" "}
                                address
                              </Typography>
                            </Box>
                            <Box sx={{ p: 2 }}>
                              <ToggleButtonGroup
                                size="small"
                                color={addressValidation ? "primary" : "danger"}
                                value={addressValidation}
                                exclusive
                                onChange={(e, newValue) => {
                                  if (newValue !== null) setAddressValidation(newValue);
                                }}
                                aria-label="Validate address"
                              >
                                <ToggleButton value={true}>Enabled</ToggleButton>
                                <ToggleButton value={false}>Disabled</ToggleButton>
                              </ToggleButtonGroup>
                            </Box>
                          </Box>
                        </Collapse>
                      )}

                      {caseType === 1 && (
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            border: 1,
                            borderTop: 0,
                            borderColor: "divider",
                          }}
                        >
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              justifyContent: "center",
                              p: 2,
                            }}
                          >
                            <Typography variant="subtitle2">
                              Verify their identity document
                            </Typography>
                          </Box>
                          <Box sx={{ p: 2 }}>
                            <ToggleButtonGroup
                              size="small"
                              color={identityVerification ? "primary" : "danger"}
                              value={identityVerification}
                              exclusive
                              onChange={(e, newValue) => {
                                if (newValue !== null) setIdentityVerification(newValue);
                              }}
                              aria-label="Identity verification"
                            >
                              <ToggleButton value={true}>Enabled</ToggleButton>
                              <ToggleButton value={false}>Disabled</ToggleButton>
                            </ToggleButtonGroup>
                          </Box>
                        </Box>
                      )}

                      {caseType === 1 && (
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            border: 1,
                            borderTop: 0,
                            borderColor: "divider",
                          }}
                        >
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              justifyContent: "center",
                              p: 2,
                            }}
                          >
                            <Typography variant="subtitle2">
                              Require them to complete a proof of life video
                            </Typography>
                          </Box>
                          <Box sx={{ p: 2 }}>
                            <ToggleButtonGroup
                              size="small"
                              color={proofOfLife ? "primary" : "danger"}
                              value={proofOfLife}
                              exclusive
                              onChange={(e, newValue) => {
                                if (newValue !== null) setProofOfLife(newValue);
                              }}
                              aria-label="Proof of life"
                            >
                              <ToggleButton value={true}>Enabled</ToggleButton>
                              <ToggleButton value={false}>Disabled</ToggleButton>
                            </ToggleButtonGroup>
                          </Box>
                        </Box>
                      )}

                      {caseType === 2 && (
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            border: 1,
                            borderTop: 0,
                            borderColor: "divider",
                          }}
                        >
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              justifyContent: "center",
                              p: 2,
                            }}
                          >
                            <Typography variant="subtitle2">
                              Capture the ownership structure and identify UBO's
                            </Typography>
                          </Box>
                          <Box sx={{ p: 2 }}>
                            <ToggleButtonGroup
                              size="small"
                              color={ownership ? "primary" : "danger"}
                              value={ownership}
                              exclusive
                              onChange={(e, newValue) => {
                                if (newValue !== null) setOwnership(newValue);
                              }}
                              aria-label="Ownership structure"
                            >
                              <ToggleButton value={true}>Enabled</ToggleButton>
                              <ToggleButton value={false}>Disabled</ToggleButton>
                            </ToggleButtonGroup>
                          </Box>
                        </Box>
                      )}

                      {caseType === 2 && (
                        <Collapse in={ownership} exit={!ownership}>
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "space-between",
                              border: 1,
                              borderTop: 0,
                              borderColor: "divider",
                            }}
                          >
                            <Box
                              sx={{
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "center",
                                p: 2,
                              }}
                            >
                              <Typography variant="subtitle2">Control thresholds</Typography>
                            </Box>
                            <Box
                              sx={{
                                p: 2,
                                display: "flex",
                                flexDirection: "column",
                                gap: 2,
                                width: "50%",
                              }}
                            >
                              <TextField
                                label="Direct control threshold"
                                variant="outlined"
                                value={directControlThreshold}
                                onChange={(e) => setDirectControlThreshold(e.target.value)}
                                InputProps={{
                                  endAdornment: (
                                    <InputAdornment position="end">
                                      <Percent />
                                    </InputAdornment>
                                  ),
                                }}
                              />

                              <TextField
                                type="number"
                                label="Indirect control threshold"
                                variant="outlined"
                                value={indirectControlThreshold}
                                onChange={(e) => setIndirectControlThreshold(e.target.value)}
                                InputProps={{
                                  endAdornment: (
                                    <InputAdornment position="end">
                                      <Percent />
                                    </InputAdornment>
                                  ),
                                }}
                              />
                            </Box>
                          </Box>
                        </Collapse>
                      )}

                      {caseType === 2 && (
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            border: 1,
                            borderTop: 0,
                            borderColor: "divider",
                          }}
                        >
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              justifyContent: "center",
                              p: 2,
                            }}
                          >
                            <Typography variant="subtitle2">
                              Capture and verify officers and representatives
                            </Typography>
                          </Box>
                          <Box sx={{ p: 2 }}>
                            <ToggleButtonGroup
                              size="small"
                              color={officers ? "primary" : "danger"}
                              value={officers}
                              exclusive
                              onChange={(e, newValue) => {
                                if (newValue !== null) setOfficers(newValue);
                              }}
                              aria-label="Officers and representatives"
                            >
                              <ToggleButton value={true}>Enabled</ToggleButton>
                              <ToggleButton value={false}>Disabled</ToggleButton>
                            </ToggleButtonGroup>
                          </Box>
                        </Box>
                      )}

                      {caseType === 1 && (
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            border: 1,
                            borderTop: 0,
                            borderColor: "divider",
                          }}
                        >
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              justifyContent: "center",
                              p: 2,
                            }}
                          >
                            <Typography variant="subtitle2">
                              Self declaration of being a PEP
                            </Typography>
                          </Box>
                          <Box sx={{ p: 2 }}>
                            <ToggleButtonGroup
                              size="small"
                              color={declarePEP ? "primary" : "danger"}
                              value={declarePEP}
                              exclusive
                              onChange={(e, newValue) => {
                                if (newValue !== null) setDeclarePEP(newValue);
                              }}
                              aria-label="Self declaration of being a PEP"
                            >
                              <ToggleButton value={true}>Enabled</ToggleButton>
                              <ToggleButton value={false}>Disabled</ToggleButton>
                            </ToggleButtonGroup>
                          </Box>
                        </Box>
                      )}

                      {caseType === 1 && (
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            border: 1,
                            borderTop: 0,
                            borderColor: "divider",
                          }}
                        >
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              justifyContent: "center",
                              p: 2,
                            }}
                          >
                            <Typography variant="subtitle2">
                              Source of wealth questionnaire
                            </Typography>
                          </Box>
                          <Box sx={{ p: 2 }}>
                            <ToggleButtonGroup
                              size="small"
                              color={sourceOfWealth ? "primary" : "danger"}
                              value={sourceOfWealth}
                              exclusive
                              onChange={(e, newValue) => {
                                if (newValue !== null) setSourceOfWealth(newValue);
                              }}
                              aria-label="Source of wealth questionnaire"
                            >
                              <ToggleButton value={true}>Enabled</ToggleButton>
                              <ToggleButton value={false}>Disabled</ToggleButton>
                            </ToggleButtonGroup>
                          </Box>
                        </Box>
                      )}

                      {caseType === 2 && (
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            border: 1,
                            borderTop: 0,
                            borderColor: "divider",
                          }}
                        >
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              justifyContent: "center",
                              p: 2,
                            }}
                          >
                            <Typography variant="subtitle2">Upload corporate documents</Typography>
                          </Box>
                          <Box sx={{ p: 2 }}>
                            <ToggleButtonGroup
                              size="small"
                              color={corporateDocuments ? "primary" : "danger"}
                              value={corporateDocuments}
                              exclusive
                              onChange={(e, newValue) => {
                                if (newValue !== null) setCorporateDocuments(newValue);
                              }}
                              aria-label="Upload corporate documents"
                            >
                              <ToggleButton value={true}>Enabled</ToggleButton>
                              <ToggleButton value={false}>Disabled</ToggleButton>
                            </ToggleButtonGroup>
                          </Box>
                        </Box>
                      )}

                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          border: 1,
                          borderTop: 0,
                          borderColor: "divider",
                        }}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            p: 2,
                          }}
                        >
                          <Typography variant="subtitle2">Require FATCA/CRS declaration</Typography>
                        </Box>
                        <Box sx={{ p: 2 }}>
                          <ToggleButtonGroup
                            size="small"
                            color={fatca ? "primary" : "danger"}
                            value={fatca}
                            exclusive
                            onChange={(e, newValue) => {
                              if (newValue !== null) setFatca(newValue);
                            }}
                            aria-label="Facta/CRS declaration"
                          >
                            <ToggleButton value={true}>Enabled</ToggleButton>
                            <ToggleButton value={false}>Disabled</ToggleButton>
                          </ToggleButtonGroup>
                        </Box>
                      </Box>
                    </Box>
                  </Grid>

                  <Grid item xs={12} sm={12} md={12} lg={6}>
                    <Box sx={{ display: "flex", flexDirection: "column" }}>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          border: 1,
                          borderTop: 0,
                          borderColor: "divider",
                        }}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            p: 1,
                            backgroundColor: "primary.main",
                            width: "100%",
                          }}
                        >
                          <Typography color="white.main" variant="h6">
                            Background checks
                          </Typography>
                        </Box>
                      </Box>
                    </Box>

                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        border: 1,
                        borderTop: 0,
                        borderColor: "divider",
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                          p: 2,
                        }}
                      >
                        <Typography variant="subtitle2">
                          {caseType == 1
                            ? "Politically exposed persons"
                            : "State owned enterprises"}
                        </Typography>
                      </Box>
                      <Box sx={{ p: 2 }}>
                        <ToggleButtonGroup
                          size="small"
                          color={peps ? "primary" : "danger"}
                          value={peps}
                          exclusive
                          onChange={(e, newValue) => {
                            if (newValue !== null) setPeps(newValue);
                          }}
                          aria-label="PEP screening"
                        >
                          <ToggleButton value={true}>Enabled</ToggleButton>
                          <ToggleButton value={false}>Disabled</ToggleButton>
                        </ToggleButtonGroup>
                      </Box>
                    </Box>

                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        border: 1,
                        borderTop: 0,
                        borderColor: "divider",
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                          p: 2,
                        }}
                      >
                        <Typography variant="subtitle2">Sanction screening</Typography>
                      </Box>
                      <Box sx={{ p: 2 }}>
                        <ToggleButtonGroup
                          size="small"
                          color={sanctions ? "primary" : "danger"}
                          value={sanctions}
                          exclusive
                          onChange={(e, newValue) => {
                            if (newValue !== null) setSanctions(newValue);
                          }}
                          aria-label="Sanction screening"
                        >
                          <ToggleButton value={true}>Enabled</ToggleButton>
                          <ToggleButton value={false}>Disabled</ToggleButton>
                        </ToggleButtonGroup>
                      </Box>
                    </Box>

                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        border: 1,
                        borderTop: 0,
                        borderColor: "divider",
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                          p: 2,
                        }}
                      >
                        <Typography variant="subtitle2">Warning list searches</Typography>
                      </Box>
                      <Box sx={{ p: 2 }}>
                        <ToggleButtonGroup
                          size="small"
                          color={warnings ? "primary" : "danger"}
                          value={warnings}
                          exclusive
                          onChange={(e, newValue) => {
                            if (newValue !== null) setWarnings(newValue);
                          }}
                          aria-label="Warning list searches"
                        >
                          <ToggleButton value={true}>Enabled</ToggleButton>
                          <ToggleButton value={false}>Disabled</ToggleButton>
                        </ToggleButtonGroup>
                      </Box>
                    </Box>

                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        border: 1,
                        borderTop: 0,
                        borderColor: "divider",
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                          p: 2,
                        }}
                      >
                        <Typography variant="subtitle2">Adverse media searches</Typography>
                      </Box>
                      <Box sx={{ p: 2 }}>
                        <ToggleButtonGroup
                          size="small"
                          color={adverseMedia ? "primary" : "danger"}
                          value={adverseMedia}
                          exclusive
                          onChange={(e, newValue) => {
                            if (newValue !== null) setAdverseMedia(newValue);
                          }}
                          aria-label="Adverse media searches"
                        >
                          <ToggleButton value={true}>Enabled</ToggleButton>
                          <ToggleButton value={false}>Disabled</ToggleButton>
                        </ToggleButtonGroup>
                      </Box>
                    </Box>
                  </Grid>
                </Grid>
                <Box sx={{ display: "flex", justifyContent: "space-between", gap: 1, mt: 2 }}>
                  <Box>
                    {activeTemplate && (
                      <Box sx={{ display: "flex", flexDirection: "row", gap: 2 }}>
                        <Button
                          variant="outlined"
                          color="danger"
                          startIcon={<Delete />}
                          onClick={() => setDeleteTemplateDialog(true)}
                        >
                          Delete template
                        </Button>
                        {!isDefault && (
                          <Button
                            variant="outlined"
                            color="info"
                            onClick={() => setDefaultTemplateDialog(true)}
                          >
                            Set as default
                          </Button>
                        )}
                      </Box>
                    )}
                  </Box>
                  <LoadingButton
                    disableElevation
                    variant="contained"
                    color="success"
                    loading={processing}
                    onClick={(e) => {
                      activeTemplate ? handleUpdateTemplate(e) : handleCreateTemplate(e);
                    }}
                  >
                    {activeTemplate ? "Save changes" : "Create template"}
                  </LoadingButton>
                </Box>
              </Box>
            ) : (
              <DataTable
                columns={columns}
                data={data}
                customStyles={customStyles}
                pagination
                striped
              />
            )}
          </Box>

          <Dialog
            open={deleteTemplateDialog}
            onClose={() => setDeleteTemplateDialog(false)}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">Are you sure?</DialogTitle>
            <DialogContent>
              <Box>
                <DialogContentText sx={{ mb: 1 }} id="alert-dialog-description">
                  Are you sure you want to delete this template?
                </DialogContentText>
              </Box>
            </DialogContent>
            <DialogActions>
              <LoadingButton
                disableElevation
                loading={processing}
                onClick={() => setDeleteTemplateDialog(false)}
                color="primary"
              >
                Cancel
              </LoadingButton>

              <LoadingButton
                disableElevation
                sx={{ color: "#ffffff" }}
                loading={processing}
                onClick={(e) => {
                  handleDeleteTemplate(e);
                }}
                variant="contained"
                color="danger"
                autoFocus
              >
                Confirm
              </LoadingButton>
            </DialogActions>
          </Dialog>

          <Dialog
            open={defaultTemplateDialog}
            onClose={() => setDefaultTemplateDialog(false)}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">Are you sure?</DialogTitle>
            <DialogContent>
              <Box>
                <DialogContentText sx={{ mb: 1 }} id="alert-dialog-description">
                  Are you sure you want to make this the default template?
                </DialogContentText>
              </Box>
            </DialogContent>
            <DialogActions>
              <LoadingButton
                disableElevation
                loading={processing}
                onClick={() => setDefaultTemplateDialog(false)}
                color="primary"
              >
                Cancel
              </LoadingButton>

              <LoadingButton
                disableElevation
                sx={{ color: "#ffffff" }}
                loading={processing}
                onClick={(e) => {
                  handleSetDefaultTemplate(e);
                }}
                variant="contained"
                color="primary"
                autoFocus
              >
                Confirm
              </LoadingButton>
            </DialogActions>
          </Dialog>
        </>
      ) : (
        <Box>
          <Lottie speed={2.0} options={defaultOptions} height={200} width={200} />
        </Box>
      )}
    </>
  );
};

export default CaseTemplates;
