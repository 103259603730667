import PropTypes from "prop-types";

export const replyPropType = PropTypes.shape({
  uuid: PropTypes.string,
  title: PropTypes.string,
  content: PropTypes.string,
  created: PropTypes.number,
  updated: PropTypes.number,
  replaced: PropTypes.number,
  last_action: PropTypes.number,
  has_replies: PropTypes.bool,
  owner_name: PropTypes.string,
  owner_uuid: PropTypes.string,
  version: PropTypes.number,
  replies: PropTypes.array,
});

export const notesPropType = PropTypes.shape({
  uuid: PropTypes.string,
  title: PropTypes.string,
  content: PropTypes.string,
  created: PropTypes.number,
  updated: PropTypes.number,
  replaced: PropTypes.number,
  last_action: PropTypes.number,
  has_replies: PropTypes.bool,
  owner_name: PropTypes.string,
  owner_uuid: PropTypes.string,
  version: PropTypes.number,
  replies: PropTypes.arrayOf(replyPropType),
});
