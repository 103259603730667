import { React, useEffect, useState } from "react";

import partner from "../../../../../api/partner";
import authHeader from "../../../../../api/authHeader";

import HandleError from "../../../../../components/functions/HandleError";
import HandleToast from "../../../../../components/functions/HandleToast";

import Lottie from "react-lottie";
import loadingLottie from "../../../../../components/lotties/loading-dots.json";

import { Typography, Box, Divider, Fade } from "@mui/material";
import { Grid, TextField, ToggleButton, ToggleButtonGroup } from "@mui/material";
import { LoadingButton } from "@mui/lab";

const Age = ({ partner, partnerUser, partnerData }) => {
  const useAuthHeader = authHeader();

  const handleError = HandleError();
  const displayToast = HandleToast();

  const [isLoaded, setIsLoaded] = useState(false);
  const [processing, setProcessing] = useState(false);

  const [lowAge, setLowAge] = useState("");
  const [highAge, setHighAge] = useState("");
  const [lowActive, setLowActive] = useState(false);
  const [highActive, setHighActive] = useState(false);

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: loadingLottie,
  };

  const getAgeAlertSettings = async () => {
    try {
      const response = await partner.post(`/v1/screening/risk-assessment/age`, JSON.stringify({}), {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Basic " + useAuthHeader.base64encodedData,
          service: "partners",
        },

        //withCredentials: true,
      });

      setLowAge(response.data.low_age.toString());
      setHighAge(response.data.high_age.toString());
      setLowActive(response.data.low_active);
      setHighActive(response.data.high_active);
      setIsLoaded(true);
    } catch (err) {
      handleError(err);
    }
  };

  const handleSaveSettings = async (e) => {
    e.preventDefault();
    setProcessing(true);

    try {
      const response = await partner.post(
        `/v1/screening/risk-assessment/age/update`,
        JSON.stringify({
          low_age: parseInt(lowAge),
          high_age: parseInt(highAge),
          low_active: lowActive,
          high_active: highActive,
        }),
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Basic " + useAuthHeader.base64encodedData,
            service: "partners",
          },

          //withCredentials: true,
        }
      );

      getAgeAlertSettings();
      displayToast("Settings updated successfully", () => {
        setProcessing(false);
      });
    } catch (err) {
      handleError(err);
      setProcessing(false);
    }
  };

  useEffect(() => {
    getAgeAlertSettings();
  }, []);

  return (
    <>
      {isLoaded ? (
        <>
          <Box sx={{ borderTop: 1, borderColor: "divider" }}>
            <Box sx={{ p: 2, display: "flex", justifyContent: "space-between" }}>
              <Box sx={{ display: "flex", gap: 4 }}>
                <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
                  <Typography variant="h6">Age alerts</Typography>
                </Box>
              </Box>
            </Box>
            <Divider />

            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} md={12} lg={6}>
                <Box sx={{ p: 2 }}>
                  <Box sx={{ display: "flex", justifyContent: "space-between", border: 1, borderColor: "divider" }}>
                    <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center", p: 2 }}>
                      <Typography variant="h6">Low age alerts</Typography>
                    </Box>
                    <Box sx={{ p: 2 }}>
                      <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                        <ToggleButtonGroup
                          size="small"
                          color={lowActive ? "primary" : "danger"}
                          value={lowActive}
                          exclusive
                          onChange={(e, newValue) => {
                            if (newValue !== null) setLowActive(newValue);
                          }}
                          aria-label="Low age alerts"
                        >
                          <ToggleButton value={true}>Enabled</ToggleButton>
                          <ToggleButton value={false}>Disabled</ToggleButton>
                        </ToggleButtonGroup>
                      </Box>

                      <Fade in={lowActive} exit={!lowActive} timeout={750}>
                        <Box sx={{ mt: 2 }}>
                          <TextField label="Low age" type="number" min={0} value={lowAge} onChange={(e) => setLowAge(e.target.value)} fullWidth />
                        </Box>
                      </Fade>
                    </Box>
                  </Box>
                </Box>
              </Grid>

              <Grid item xs={12} sm={12} md={12} lg={6}>
                <Box sx={{ p: 2 }}>
                  <Box sx={{ display: "flex", justifyContent: "space-between", border: 1, borderColor: "divider" }}>
                    <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center", p: 2 }}>
                      <Typography variant="h6">High age alerts</Typography>
                    </Box>
                    <Box sx={{ p: 2 }}>
                      <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                        <ToggleButtonGroup
                          size="small"
                          color={highActive ? "primary" : "danger"}
                          value={highActive}
                          exclusive
                          onChange={(e, newValue) => {
                            if (newValue !== null) setHighActive(newValue);
                          }}
                          aria-label="High age alerts"
                        >
                          <ToggleButton value={true}>Enabled</ToggleButton>
                          <ToggleButton value={false}>Disabled</ToggleButton>
                        </ToggleButtonGroup>
                      </Box>

                      <Fade in={highActive} exit={!highActive} timeout={750}>
                        <Box sx={{ mt: 2 }}>
                          <TextField label="High age" type="number" min={0} value={highAge} onChange={(e) => setHighAge(e.target.value)} fullWidth />
                        </Box>
                      </Fade>
                    </Box>
                  </Box>
                </Box>
              </Grid>
            </Grid>

            <Box sx={{ px: 2, display: "flex", justifyContent: "flex-end" }}>
              <LoadingButton
                disableElevation
                variant="contained"
                color="success"
                loading={processing}
                onClick={(e) => {
                  handleSaveSettings(e);
                }}
              >
                Save changes
              </LoadingButton>
            </Box>
          </Box>
        </>
      ) : (
        <Box>
          <Lottie speed={2.0} options={defaultOptions} height={200} width={200} />
        </Box>
      )}
    </>
  );
};

export default Age;
