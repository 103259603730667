import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import App from "./App.jsx";
import "./index.css";
import { createTheme, ThemeProvider } from "@mui/material/styles";
// Supports weights 100-900
// Supports weights 300-800
import "@fontsource-variable/open-sans";

import * as Sentry from "@sentry/react";

Sentry.init({
  dsn: "https://e8a723a077885e80ece2874d07d9bf72@o4506833931993088.ingest.us.sentry.io/4507402669588480",
  integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],
  // Performance Monitoring
  tracesSampleRate: import.meta.env.VITE_IS_TESTING == 1 ? 0 : 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ["localhost", /^https:\/\/app.cleverli\.com/],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  environment: import.meta.env.VITE_IS_TESTING == 1 ? "testing" : "production",
});

const theme = createTheme({
  classes: {
    menuItem: {
      backgroundColor: "white",
      "&:active": {
        borderLeft: "4px solid #51bcda",
        backgroundColor: "yellow",
      },
      selected: {
        backgroundColor: "yellow !important",
      },
    },
  },
  typography: {
    fontFamily: `"Open Sans Variable", "Helvetica", "Arial", sans-serif`,
    fontSize: 14,
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 500,
  },
  palette: {
    contrastThreshold: 4.5,
    primary: {
      light: "#494f4d",
      main: "#0b0b0b",
      dark: "#6b6b6b",
      contrastText: "#fff",
    },
    secondary: {
      light: "#adadad",
      main: "#D5E950",
      dark: "#6b6b6b",
      contrastText: "#1C2321",
    },
    danger: {
      light: "#d36470",
      main: "#C83E4D",
      dark: "#C83E4D",
    },
    success: {
      light: "#a2cbb2",
      main: "#8BBF9F",
      dark: "#589f73",
    },
    warning: {
      light: "#fcd46b",
      main: "#FCCA46",
      dark: "#b08d31",
    },
    info: {
      light: "#4e8fb7",
      main: "#2274A5",
      dark: "#175173",
    },
    white: {
      light: "#FFFFFF",
      main: "#FFFFFF",
      dark: "#FFFFFF",
    },
  },
});

ReactDOM.createRoot(document.getElementById("root")).render(
  <BrowserRouter>
    <ThemeProvider theme={theme}>
      <App />
    </ThemeProvider>
  </BrowserRouter>
);
