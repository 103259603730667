import { React, useEffect, useState } from "react";

import central from "../../api/central";
import authHeader from "../../api/authHeader";

import HandleError from "../../components/functions/HandleError";
import NoResults from "../../components/functions/NoResults";

import Lottie from "react-lottie";
import loadingLottie from "../../components/lotties/loading-dots.json";

import DataTable from "react-data-table-component";

import {
  Typography,
  Divider,
  Box,
  Button,
  Chip,
  Link,
  Fade,
} from "@mui/material";
import { Close } from "@mui/icons-material";
import NewClient from "./components/clients/NewClient";

const Clients = ({ partner, partnerUser, partnerData }) => {
  const useAuthHeader = authHeader();

  const handleError = HandleError();

  const [isLoaded, setIsLoaded] = useState(false);
  const [isFiltered, setIsFiltered] = useState(false);
  const [data, setData] = useState([]);

  const [newClient, setNewClient] = useState(false);

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: loadingLottie,
  };

  const customStyles = {
    headCells: {
      style: {
        paddingLeft: "8px", // override the cell padding for head cells
        paddingRight: "8px",
        fontWeight: "bold",
      },
    },
    cells: {
      style: {
        paddingLeft: "8px", // override the cell padding for data cells
        paddingRight: "8px",
      },
    },
  };

  const columns = [
    {
      name: "Client name",
      sortable: true,
      width: "25%",
      selector: (row) => row.name,
      cell: (row) => (
        <Link
          className="link"
          href={`/client/${row.client_uuid}`}
          underline="none"
          sx={{ fontSize: "13px" }}
        >
          {row.name}
        </Link>
      ),
    },
    {
      name: "Client type",
      width: "10%",
      sortable: true,
      selector: (row) => row.client_entity_type,
      cell: (row) => (
        <Box>
          <Typography sx={{ fontSize: "13px" }}>
            {row.client_entity_type == 1 ? "Individual" : "Business"}
          </Typography>
        </Box>
      ),
    },
    {
      name: "Segments",
      width: "25%",
      sortable: false,
      cell: (row) => (
        <Box sx={{ display: "flex", gap: 1 }}>
          {row.segments?.map((segment, index) => (
            <Box
              className="box-label"
              onClick={() => filterClientsBySegment(segment.segment_uuid)}
              key={index}
              sx={{
                backgroundColor: segment.color,
                color: segment.text_color,
                cursor: "pointer",
                textTransform: "uppercase",
              }}
            >
              {segment.label}
            </Box>
          ))}
        </Box>
      ),
    },
    {
      name: "Flags",
      sortable: false,
      cell: (row) => (
        <Box sx={{ display: "flex", flexDirection: "column", gap: 1, py: 1 }}>
          {!row.flags.pep &&
            !row.flags.sanctions &&
            !row.flags.age &&
            !row.flags.fatca &&
            "-"}
          {row.flags.pep && (
            <Chip
              className="square-chip"
              label="PEP"
              sx={{
                backgroundColor: "#C83E4D",
                color: "#ffffff",
                textTransform: "uppercase",
              }}
            />
          )}
          {row.flags.sanctions && (
            <Chip
              className="square-chip"
              label="Sanctioned"
              sx={{
                backgroundColor: "#C83E4D",
                color: "#ffffff",
                textTransform: "uppercase",
              }}
            />
          )}

          {row.flags.age && (
            <Chip
              className="square-chip"
              label="Age risk"
              sx={{
                backgroundColor: "#FCCA46",
                color: "#1C2321",
                textTransform: "uppercase",
              }}
            />
          )}

          {row.flags.fatca && (
            <Chip
              className="square-chip"
              label="FATCA"
              sx={{
                backgroundColor: "#2274A5",
                color: "#ffffff",
                textTransform: "uppercase",
              }}
            />
          )}
        </Box>
      ),
    },
    {
      name: "Status",
      sortable: true,
      right: "true",
      selector: (row) => row.status,
      cell: (row) => (
        <Box sx={{ display: "flex", gap: 1 }}>
          {row.status == 0 && (
            <Chip
              className="square-chip"
              label="Onboarding"
              color="warning"
              size="small"
            />
          )}
          {row.status == 1 && (
            <Chip
              className="square-chip"
              label="Active"
              color="success"
              size="small"
            />
          )}
          {row.status == 2 && (
            <Chip
              className="square-chip"
              label="Closed"
              color="danger"
              size="small"
            />
          )}
        </Box>
      ),
    },
  ];

  const getClients = async () => {
    try {
      const response = await partner.post(
        `/v1/client/data/list`,
        JSON.stringify({}),
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Basic " + useAuthHeader.base64encodedData,
            service: "partners",
          },

          //withCredentials: true,
        }
      );

      if (response.data.results > 0) {
        setData(response.data.entries);
        console.log(response.data.entries);
      } else {
        setData([]);
      }
      setIsLoaded(true);
    } catch (err) {
      console.log(err);
      handleError(err);
    }
  };

  const filterClientsBySegment = async (segmentUUID) => {
    try {
      const response = await partner.post(
        `/v1/client/data/list/segment-filter`,
        JSON.stringify({
          segment_uuid: segmentUUID,
        }),
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Basic " + useAuthHeader.base64encodedData,
            service: "partners",
          },

          //withCredentials: true,
        }
      );

      if (response.data.results > 0) {
        setData(response.data.entries);
        console.log(response.data.entries);
      } else {
        setData([]);
      }
      setIsFiltered(true);
    } catch (err) {
      handleError(err);
    }
  };

  useEffect(() => {
    getClients();
  }, []);

  return (
    <>
      {isLoaded ? (
        <>
          <Box sx={{ border: 1, borderColor: "divider" }}>
            <Box
              sx={{ p: 2, display: "flex", justifyContent: "space-between" }}
            >
              <Typography variant="h6">
                {newClient ? "Create a new client" : "Clients"}
              </Typography>
              <Box sx={{ display: "flex", gap: 1 }}>
                <Fade in={isFiltered} exit={!isFiltered} timeout={750}>
                  <Box>
                    <Button
                      onClick={() => {
                        getClients();
                        setIsFiltered(false);
                      }}
                      startIcon={<Close />}
                      variant="outlined"
                      color="primary"
                    >
                      Remove filter
                    </Button>
                  </Box>
                </Fade>

                <Button
                  onClick={() => {
                    setNewClient(!newClient);
                  }}
                  variant="contained"
                  color="primary"
                >
                  {newClient ? "Close" : "Create client"}
                </Button>
              </Box>
            </Box>
            <Divider />
            {newClient ? (
              <Box>
                <NewClient
                  partner={partner}
                  partnerUser={partnerUser}
                  partnerData={partnerData}
                  getClients={getClients}
                />
              </Box>
            ) : (
              <DataTable
                columns={columns}
                data={data}
                customStyles={customStyles}
                pagination
                striped
                noDataComponent={
                  <NoResults
                    message={`Create your first client to get started`}
                  />
                }
              />
            )}
          </Box>
        </>
      ) : (
        <Box>
          <Lottie
            speed={2.0}
            options={defaultOptions}
            height={200}
            width={200}
          />
        </Box>
      )}
    </>
  );
};

export default Clients;
