import { React, useState, useEffect } from "react";

import partner from "../../../../../api/partner";
import authHeader from "../../../../../api/authHeader";

import HandleToast from "../../../../../components/functions/HandleToast";
import ErrorHandling from "../../../../../components/functions/HandleError";

import Lottie from "react-lottie";
import loadingLottie from "../../../../../components/lotties/loading-dots.json";

import DataTable from "react-data-table-component";
import moment from "moment";

import { Box, IconButton, Typography, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { Computer, Delete, Smartphone } from "@mui/icons-material";

const Sessions = ({ partner, partnerUser, partnerData }) => {
  const useAuthHeader = authHeader();
  const handleError = ErrorHandling();
  const displayToast = HandleToast();

  const [isLoaded, setIsLoaded] = useState(false);
  const [sessionsData, setSessionsData] = useState([]);
  const [processing, setProcessing] = useState(false);

  const [killSessionToken, setKillSessionToken] = useState(""); // [sessionToken, setSessionToken
  const [killSessionDialog, setKillSessionDialog] = useState(false);

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: loadingLottie,
  };

  const customStyles = {
    headCells: {
      style: {
        paddingLeft: "8px", // override the cell padding for head cells
        paddingRight: "8px",
        fontWeight: "bold",
      },
    },
    cells: {
      style: {
        paddingLeft: "8px", // override the cell padding for data cells
        paddingRight: "8px",
      },
    },
  };

  const columns = [
    {
      name: "Session created",
      sortable: true,
      selector: (row) => moment.unix(row.timestamp).format("DD/MM/YYYY HH:mm"),
    },
    {
      name: "Platform",
      cell: (row) => (row.is_mobile ? <Smartphone color="success" /> : <Computer color="success" />),
    },

    {
      name: "IP & Country",
      sortable: true,
      selector: (row) => row.ip_country.name,
      cell: (row) => (
        <Box>
          <Typography sx={{ fontSize: "13px" }}>{row.ip_address}</Typography>
          <Typography sx={{ fontSize: "13px" }}>{row.ip_country.name}</Typography>
        </Box>
      ),
    },
    {
      name: "Expires",
      cell: (row) => <Box>{row.expires > 0 ? moment.unix(row.expires).fromNow() : "-"}</Box>,
    },
    {
      name: "Actions",
      right: "true",
      cell: (row) => (
        <>
          {row.active && (
            <IconButton
              aria-label="Kill session"
              size="large"
              color="danger"
              onClick={() => {
                setKillSessionToken(row.session_token);
                setKillSessionDialog(true);
              }}
            >
              <Delete />
            </IconButton>
          )}
        </>
      ),
    },
  ];

  const getSessions = async () => {
    try {
      const response = await partner.get(`/v1/users/self/data/sessions`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Basic " + useAuthHeader.base64encodedData,
          service: "partners",
        },

        //withCredentials: true,
      });

      if (response.data.results > 0) {
        setSessionsData(response.data.entries);
      } else {
        setSessionsData([]);
      }
      setIsLoaded(true);
    } catch (err) {
      handleError(err);
    }
  };

  const handleKillSession = async () => {
    setProcessing(true);
    try {
      const response = await partner.get(`/v1/users/auth/logout/${killSessionToken}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Basic " + useAuthHeader.base64encodedData,
          service: "partners",
        },

        //withCredentials: true,
      });

      getSessions();
      setKillSessionToken("");
      setKillSessionDialog(false);
      displayToast("Session terminated", () => {
        setProcessing(false);
      });
    } catch (err) {
      setKillSessionDialog(false);
      setProcessing(false);
      handleError(err);
    }
  };

  useEffect(() => {
    getSessions();
  }, []);

  return (
    <>
      {!isLoaded ? (
        <>
          <Box>
            <Lottie speed={2.0} options={defaultOptions} height={200} width={200} />
          </Box>
        </>
      ) : (
        <>
          <Box sx={{ borderTop: 1, borderColor: "divider" }}>
            <DataTable columns={columns} data={sessionsData} customStyles={customStyles} pagination striped />
          </Box>

          <Dialog
            open={killSessionDialog}
            onClose={() => setKillSessionDialog(false)}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">Are you sure?</DialogTitle>
            <DialogContent>
              <Box>
                <DialogContentText sx={{ mb: 1 }} id="alert-dialog-description">
                  Are you sure you want to log the session out?
                </DialogContentText>
              </Box>
            </DialogContent>
            <DialogActions>
              <LoadingButton disableElevation loading={processing} onClick={() => setKillSessionDialog(false)} color="primary">
                Cancel
              </LoadingButton>

              <LoadingButton
                disableElevation
                sx={{ color: "#ffffff" }}
                loading={processing}
                onClick={(e) => {
                  handleKillSession();
                }}
                variant="contained"
                color="danger"
                autoFocus
              >
                Confirm
              </LoadingButton>
            </DialogActions>
          </Dialog>
        </>
      )}
    </>
  );
};

export default Sessions;
