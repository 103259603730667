import { Box, Button, Stack, Typography } from "@mui/material";
import PropTypes from "prop-types";

const fileBaseUrl = document?.cookie
  ?.split(";")
  ?.find((c) => c.includes("platformURL"))
  ?.split("=")[1];

const imageFileTypes = ["png", "jpg", "jpeg", "gif", "bmp", "svg", "jpe"];

const downloadFile = (fileUrl) => {
  if (!fileUrl) return;
  const fileName = fileUrl?.split("/")?.pop() || "file";
  // Create an anchor element
  const anchor = document.createElement("a");
  anchor.href = fileUrl;
  anchor.download = fileName; // Optional: Specify a default file name for downloading

  // Append the anchor to the body, click it, and then remove it
  document.body.appendChild(anchor);
  anchor.click();
  document.body.removeChild(anchor);
};

export function FileViewer({ fileUrl }) {
  const fileType = fileUrl?.split(".")?.pop();
  if (fileType === "pdf") {
    return (
      <Box sx={{ width: "50vw", height: "100%", padding: 2 }}>
        <Button onClick={() => downloadFile(fileUrl)} sx={{ m: 2 }} variant="contained">
          Download
        </Button>
        <iframe
          src={`https://${fileBaseUrl}/${fileUrl}`}
          width="100%"
          title="file"
          style={{
            border: "none",
            outline: "none",
            height: "calc(100% - 100px)",
          }}
        />
      </Box>
    );
  }
  if (imageFileTypes?.includes(fileType)) {
    return (
      <Box sx={{ width: "50vw", height: "100%", padding: 2 }}>
        <Button onClick={() => downloadFile(fileUrl)} sx={{ m: 2 }} variant="contained">
          Download
        </Button>
        <img
          src={`https://${fileBaseUrl}/${fileUrl}`}
          alt="file"
          style={{
            border: "none",
            outline: "none",
            width: "100%",
            objectFit: "contain",
            height: "calc(100% - 100px)",
          }}
        />
      </Box>
    );
  } else {
    return (
      <Stack direction="column" spacing={2} sx={{ width: "50vw", height: "100%", padding: 2 }}>
        <Typography variant="h6">Please Download the file to see the content</Typography>
        <Button onClick={() => downloadFile(fileUrl)} sx={{ m: 2 }} variant="contained">
          Download
        </Button>
      </Stack>
    );
  }
}

FileViewer.propTypes = {
  fileUrl: PropTypes.string,
};
