import { React, useEffect, useState } from "react";

import partner from "../../../../api/partner";
import authHeader from "../../../../api/authHeader";

import HandleError from "../../../../components/functions/HandleError";
import HandleToast from "../../../../components/functions/HandleToast";

import Lottie from "react-lottie";
import loadingLottie from "../../../../components/lotties/loading-dots.json";

import Cookies from "universal-cookie";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import moment from "moment";
import parse from "html-react-parser";
import DataTable from "react-data-table-component";
import getSymbolFromCurrency from "currency-symbol-map";

import {
  Box,
  Typography,
  Chip,
  Grid,
  Divider,
  Button,
  IconButton,
  ToggleButtonGroup,
  ToggleButton,
  Collapse,
  TextField,
  Select,
  MenuItem,
  MenuList,
  Drawer,
  Icon,
  Link,
} from "@mui/material";
import {
  Check,
  ChevronRight,
  Close,
  Delete,
  Description,
  Edit,
  Facebook,
  ImportContacts,
  InsertDriveFile,
  UploadFile,
  Visibility,
} from "@mui/icons-material";

import { LoadingButton } from "@mui/lab";

function ViewRequest({ partnerUser, partnerData, requestUUID, setRequestUUID }) {
  const useAuthHeader = authHeader();
  const handleError = HandleError();
  const displayToast = HandleToast();

  const partnerURL = () => {
    const cookies = new Cookies();
    return "https://" + cookies.get("platformURL");
  };

  const partnerAPI = partnerURL();

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: loadingLottie,
  };

  const [isLoaded, setIsLoaded] = useState(false);
  const [processing, setProcessing] = useState(false);

  const [requestData, setRequestData] = useState(null);

  const [reviewComment, setReviewComment] = useState("");

  const [fileData, setFileData] = useState(null);
  const [viewFile, setViewFile] = useState(false);

  const getRequestData = async (requestUUID) => {
    try {
      const response = await partner.post(
        `/v1/screening/case/kyc/enhanced/data`,
        JSON.stringify({
          request_uuid: requestUUID,
        }),
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Basic " + useAuthHeader.base64encodedData,
            service: "partners",
          },

          //withCredentials: true,
        }
      );

      setRequestData(response.data);
      console.log(response.data);
      setIsLoaded(true);
    } catch (err) {
      handleError(err);
    }
  };

  useEffect(() => {
    if (requestUUID === null) return;
    if (requestUUID === "") return;
    if (requestUUID === undefined) return;
    getRequestData(requestUUID);
  }, [requestUUID]);

  function printSingleSelectLabel(data) {
    let parsedData = JSON.parse(data);

    return parsedData?.label;
  }

  function printMultipleSelectLabels(data) {
    let parsedData = JSON.parse(data);

    let output = "";

    if (parsedData?.length > 0) {
      parsedData.map((item, index) => {
        if (index > 0) {
          output += ", " + item.label;
        } else {
          output += item.label;
        }
      });
    }

    return output;
  }

  function printMultipleCountries(data) {
    let parsedData = JSON.parse(data).countries;

    let output = "";

    if (parsedData?.length > 0) {
      parsedData.map((item, index) => {
        if (index > 0) {
          output += ", " + item.name;
        } else {
          output += item.name;
        }
      });
    }

    return output;
  }

  function printTelephoneNumber(data) {
    let parsedData = JSON.parse(data);

    return parsedData?.telephone.phone_value;
  }

  function parseAddress(data) {
    let parsedData = JSON.parse(data);

    return parsedData?.address;
  }

  function parseCurrency(data) {
    let parsedData = JSON.parse(data);

    return parsedData?.currency;
  }

  function parseCurrencies(data) {
    let parsedData = JSON.parse(data);

    return parsedData?.currencies;
  }

  function printMoney(data) {
    let parsedData = JSON.parse(data);

    let output = "";

    // get the currency symbol

    output = getSymbolFromCurrency(parsedData.currency.value);
    output += parsedData.amount;
    output += " (" + parsedData.currency.name + ")";

    return output;
  }

  function printPerson(data) {
    let parsedData = JSON.parse(data).person;

    let output = "";

    if (parsedData?.title) {
      output += parsedData.title + " ";
    }

    output += parsedData.first_name;

    if (parsedData?.middle_names) {
      output += " " + parsedData.middle_names;
    }

    output += " " + parsedData.last_name;

    return output;
  }

  function printMultiplePeople(data) {
    let parsedData = JSON.parse(data).people;

    let output = "";

    if (parsedData.length > 0) {
      parsedData.map((person, index) => {
        if (index > 0) {
          output += ", ";
          if (person?.title) {
            output += person.title + " ";
          }

          output += person.first_name;

          if (person?.middle_names) {
            output += " " + person.middle_names;
          }

          output += " " + person.last_name;
        } else {
          if (person?.title) {
            output += person.title + " ";
          }

          output += person.first_name;

          if (person?.middle_names) {
            output += " " + person.middle_names;
          }

          output += " " + person.last_name;
        }
      });
    }

    return output;
  }

  function parseCorporation(data) {
    let parsedData = JSON.parse(data);

    return parsedData?.Corporation;
  }

  function parseUploadedFile(data) {
    let parsedData = JSON.parse(data);

    return parsedData;
  }

  const handleLoadFile = async (fileUUID) => {
    try {
      const response = await partner.post(
        `/v1/screening/case/kyc/enhanced/data/file`,
        JSON.stringify({
          file_uuid: fileUUID,
        }),
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Basic " + useAuthHeader.base64encodedData,
            service: "partners",
          },

          //withCredentials: true,
        }
      );

      console.log(response.data);
      setFileData(response.data);
      setViewFile(true);
    } catch (err) {
      handleError(err);
    }
  };

  const handleReview = async (e) => {
    e.preventDefault();
    setProcessing(true);
    try {
      const response = await partner.post(
        `/v1/screening/case/kyc/enhanced/review`,
        JSON.stringify({
          request_uuid: requestUUID,
          content: reviewComment,
        }),
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Basic " + useAuthHeader.base64encodedData,
            service: "partners",
          },

          //withCredentials: true,
        }
      );

      getRequestData(requestUUID);

      displayToast("Review completed successfully", () => {
        setProcessing(false);
      });
    } catch (err) {
      console.log(err);
      handleError(err);
    }
  };

  return isLoaded ? (
    <Box>
      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            width: "50%",
            borderRight: 1,
            borderColor: "divider",
          }}
        >
          <Box sx={{ display: "flex", flexDirection: "column" }}>
            <Box sx={{ p: 2, borderBottom: 1, borderColor: "divider" }}>
              <Typography variant="subtitle2">Template name</Typography>
              <Typography variant="h6">{requestData.template_name}</Typography>
            </Box>

            <Box
              sx={{
                p: 2,
                borderBottom: requestData.review_required ? 1 : 0,
                borderColor: "divider",
              }}
            >
              <Typography variant="subtitle2">Request date</Typography>
              <Typography variant="h6">
                {moment.unix(requestData.request_timestamp).format("DD/MM/YYYY")}
              </Typography>
            </Box>
          </Box>
        </Box>
        <Box sx={{ display: "flex", flexDirection: "column", width: "50%" }}>
          <Box
            sx={{
              p: 2,
              borderBottom: requestData.review_required ? 1 : 0,
              borderColor: "divider",
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <Box>
              <Typography variant="subtitle2" sx={{ textAlign: "end" }}>
                Status
              </Typography>
              <Box
                sx={{ display: "flex", flexDirection: "row", gap: 2, justifyContent: "flex-end" }}
              >
                {requestData.cancelled ? (
                  <Chip
                    className="square-chip"
                    size="medium"
                    label="Cancelled"
                    color="error"
                    sx={{ textTransform: "uppercase" }}
                  />
                ) : requestData.review_complete ? (
                  <Chip
                    className="square-chip"
                    size="medium"
                    label="Review complete"
                    color="success"
                    sx={{ textTransform: "uppercase" }}
                  />
                ) : requestData.review_required ? (
                  <Chip
                    className="square-chip"
                    size="medium"
                    label="Review required"
                    color="warning"
                    sx={{ textTransform: "uppercase" }}
                  />
                ) : (
                  <Chip
                    className="square-chip"
                    size="medium"
                    label="Pending"
                    color="info"
                    sx={{ textTransform: "uppercase" }}
                  />
                )}
              </Box>
            </Box>
          </Box>

          {requestData.review_complete ? (
            <Box sx={{}}>
              <Box sx={{ p: 2, borderBottom: 0, borderColor: "divider", textAlign: "end" }}>
                <Typography variant="subtitle2">Review date</Typography>
                <Typography variant="h6">
                  {moment.unix(requestData.review_timestamp).format("DD/MM/YYYY")}
                </Typography>
                <Typography variant="subtitle2" sx={{ mt: 1 }}>
                  Review completed by
                </Typography>
                <Typography variant="h6">{requestData.review_actor}</Typography>

                {requestData.review_comments && (
                  <Box sx={{ mt: 1 }}>
                    <Typography variant="subtitle2">Review comments</Typography>
                    <Box>{parse(requestData.review_comments)}</Box>
                  </Box>
                )}
              </Box>
            </Box>
          ) : (
            requestData.review_required && (
              <Box sx={{ borderTop: 1, borderColor: "divider" }}>
                <Box sx={{ p: 2, borderBottom: 1, borderColor: "divider" }}>
                  <Typography variant="subtitle2">Review comments</Typography>
                </Box>
                <ReactQuill value={reviewComment} onChange={setReviewComment} theme="snow" />

                <Box
                  sx={{
                    p: 2,
                    display: "flex",
                    justifyContent: "flex-end",
                    borderTop: 1,
                    borderColor: "divider",
                  }}
                >
                  <LoadingButton
                    variant="contained"
                    color="success"
                    loading={processing}
                    onClick={(e) => {
                      handleReview(e);
                    }}
                  >
                    Submit review
                  </LoadingButton>
                </Box>
              </Box>
            )
          )}
        </Box>
      </Box>

      <Box sx={{ backgroundColor: "#0b0b0b", color: "#ffffff", p: 2 }}>
        <Typography variant="h6" sx={{ px: 2, py: 1 }}>
          Requested information
        </Typography>
      </Box>

      <Box>
        {requestData.questions.map((data, index) => (
          <Box
            key={index}
            sx={{
              borderBottom: index == requestData.questions.length - 1 ? 0 : 1,
              borderColor: "divider",
            }}
          >
            <Box
              sx={{
                px: 2,
                py: 1,
                backgroundColor: "divider",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
                <Typography>{data.label}</Typography>
              </Box>
              <Box>
                <Box sx={{ display: "flex", gap: 2 }}>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      p: 1,
                    }}
                  >
                    <IconButton>
                      <Edit />
                    </IconButton>
                  </Box>
                </Box>
              </Box>
            </Box>

            {data.has_response ? (
              <Box sx={{ p: 2, borderTop: 1, borderColor: "divider" }}>
                {data.field_type == 1 && <Box>{parse(data.answer.response)}</Box>}
                {data.field_type == 2 && (
                  <Box>
                    <Typography>{data.answer.response}</Typography>
                  </Box>
                )}
                {data.field_type == 3 && <Box>{printSingleSelectLabel(data.answer.response)}</Box>}
                {data.field_type == 4 && (
                  <Box>{printMultipleSelectLabels(data.answer.response)}</Box>
                )}
                {data.field_type == 5 && (
                  <Box>{moment(data.answer.response).format("DD/MM/YYYY")}</Box>
                )}
                {data.field_type == 6 && (
                  <Box>
                    <Typography>{data.answer.response}</Typography>
                  </Box>
                )}
                {data.field_type == 7 && (
                  <Box>
                    <Typography>{printTelephoneNumber(data.answer.response)}</Typography>
                  </Box>
                )}
                {data.field_type == 8 && (
                  <Box>
                    <Typography>{parseAddress(data.answer.response).line_one}</Typography>
                    {parseAddress(data.answer.response).line_two && (
                      <Typography>{parseAddress(data.answer.response).line_two}</Typography>
                    )}
                    {parseAddress(data.answer.response).line_three && (
                      <Typography>{parseAddress(data.answer.response).line_three}</Typography>
                    )}
                    {parseAddress(data.answer.response).line_four && (
                      <Typography>{parseAddress(data.answer.response).line_four}</Typography>
                    )}
                    {parseAddress(data.answer.response).line_five && (
                      <Typography>{parseAddress(data.answer.response).line_five}</Typography>
                    )}
                    {parseAddress(data.answer.response).line_six && (
                      <Typography>{parseAddress(data.answer.response).line_six}</Typography>
                    )}
                    <Typography>{parseAddress(data.answer.response).city}</Typography>
                    <Typography>{parseAddress(data.answer.response).code}</Typography>
                    <Typography>{parseAddress(data.answer.response).country?.name}</Typography>
                  </Box>
                )}
                {data.field_type == 9 && (
                  <Box>
                    <Typography>{JSON.parse(data.answer.response).country?.name}</Typography>
                  </Box>
                )}
                {data.field_type == 10 && <Box>{printMultipleCountries(data.answer.response)}</Box>}
                {data.field_type == 11 && (
                  <Box>
                    {data.answer.response == "false" ? (
                      <Typography>False</Typography>
                    ) : (
                      <Typography>True</Typography>
                    )}
                  </Box>
                )}
                {data.field_type == 12 && (
                  <Box>
                    {data.answer.response == "false" ? (
                      <Typography>No</Typography>
                    ) : (
                      <Typography>Yes</Typography>
                    )}
                  </Box>
                )}
                {data.field_type == 13 && (
                  <Box>
                    <Typography>{parseCurrency(data.answer.response).name}</Typography>
                  </Box>
                )}
                {data.field_type == 14 && (
                  <Box>
                    {parseCurrencies(data.answer.response).map((currency, index) => {
                      return <Typography key={index}>{currency.name}</Typography>;
                    })}
                  </Box>
                )}
                {data.field_type == 15 && (
                  <Box>
                    <Typography>{printMoney(data.answer.response)}</Typography>
                  </Box>
                )}
                {data.field_type == 16 && (
                  <Box>
                    <Typography>{data.answer.response}</Typography>
                  </Box>
                )}
                {data.field_type == 17 && (
                  <Box>
                    <Typography>{data.answer.response}</Typography>
                  </Box>
                )}
                {data.field_type == 18 && (
                  <Box>
                    <Typography>{data.answer.response} %</Typography>
                  </Box>
                )}
                {data.field_type == 19 && (
                  <Box>
                    <Box
                      title="View file"
                      onClick={() => {
                        handleLoadFile(parseUploadedFile(data.answer.response).document_uuid);
                      }}
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        gap: 1,
                        width: "fit-content",
                        cursor: "pointer",
                        border: 1,
                        borderColor: "divider",
                        p: 2,
                      }}
                    >
                      <Box>
                        <InsertDriveFile fontSize="large" />
                      </Box>
                      <Box>
                        <Typography>{parseUploadedFile(data.answer.response).filename}</Typography>
                      </Box>
                    </Box>
                  </Box>
                )}
                {data.field_type == 20 && (
                  <Box>
                    <Box sx={{ display: "flex", flexDirection: "row", gap: 2 }}>
                      {JSON.parse(data.answer.response).map((file, index) => (
                        <Box
                          key={index}
                          title="View file"
                          onClick={() => {
                            handleLoadFile(file.document_uuid);
                          }}
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            gap: 1,
                            width: "fit-content",
                            cursor: "pointer",
                            border: 1,
                            borderColor: "divider",
                            p: 2,
                          }}
                        >
                          <Box>
                            <InsertDriveFile fontSize="large" />
                          </Box>
                          <Box>
                            <Typography>{file.filename}</Typography>
                          </Box>
                        </Box>
                      ))}
                    </Box>
                  </Box>
                )}
                {data.field_type == 21 && (
                  <Box sx={{ border: 1, borderColor: "divider" }}>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        gap: 2,
                        borderBottom: 1,
                        borderColor: "divider",
                      }}
                    >
                      <Box sx={{ borderRight: 1, borderColor: "divider", width: "50%", p: 2 }}>
                        <Typography>Corporation name</Typography>
                      </Box>
                      <Box sx={{ p: 2 }}>
                        <Typography variant="subtitle2">
                          {parseCorporation(data.answer.response).registration_name}
                        </Typography>
                      </Box>
                    </Box>

                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        gap: 2,
                        borderBottom: 1,
                        borderColor: "divider",
                      }}
                    >
                      <Box sx={{ borderRight: 1, borderColor: "divider", width: "50%", p: 2 }}>
                        <Typography>Registration country</Typography>
                      </Box>
                      <Box sx={{ p: 2 }}>
                        <Typography variant="subtitle2">
                          {parseCorporation(data.answer.response).registration_country.name}
                        </Typography>
                      </Box>
                    </Box>

                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        gap: 2,
                        borderBottom: 1,
                        borderColor: "divider",
                      }}
                    >
                      <Box sx={{ borderRight: 1, borderColor: "divider", width: "50%", p: 2 }}>
                        <Typography>Registration reference</Typography>
                      </Box>
                      <Box sx={{ p: 2 }}>
                        <Typography variant="subtitle2">
                          {parseCorporation(data.answer.response).registration_reference}
                        </Typography>
                      </Box>
                    </Box>

                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        gap: 2,
                        borderBottom: 1,
                        borderColor: "divider",
                      }}
                    >
                      <Box sx={{ borderRight: 1, borderColor: "divider", width: "50%", p: 2 }}>
                        <Typography>Registration type</Typography>
                      </Box>
                      <Box sx={{ p: 2 }}>
                        <Typography variant="subtitle2">
                          {parseCorporation(data.answer.response).registration_type_label
                            ? parseCorporation(data.answer.response).registration_type_label
                            : "-"}
                        </Typography>
                      </Box>
                    </Box>

                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        gap: 2,
                      }}
                    >
                      <Box sx={{ borderRight: 1, borderColor: "divider", width: "50%", p: 2 }}>
                        <Typography>Registration date</Typography>
                      </Box>
                      <Box sx={{ p: 2 }}>
                        <Typography variant="subtitle2">
                          {moment(parseCorporation(data.answer.response).registration_date).format(
                            "DD/MM/YYYY"
                          )}
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                )}
                {data.field_type == 22 && <Box>{printPerson(data.answer.response)}</Box>}
                {data.field_type == 23 && (
                  <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
                    {JSON.parse(data.answer.response).corporations.map((corporation, index) => {
                      return (
                        <Box key={index}>
                          <Box sx={{ border: 1, borderColor: "divider" }}>
                            <Box
                              sx={{
                                p: 2,
                                borderBottom: 1,
                                borderColor: "divider",
                                backgroundColor: "#0b0b0b",
                                color: "#ffffff",
                              }}
                            >
                              <Typography variant="h6">Corporation {index + 1}</Typography>
                            </Box>
                            <Box
                              sx={{
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "space-between",
                                gap: 2,
                                borderBottom: 1,
                                borderColor: "divider",
                              }}
                            >
                              <Box
                                sx={{ borderRight: 1, borderColor: "divider", width: "50%", p: 2 }}
                              >
                                <Typography>Corporation name</Typography>
                              </Box>
                              <Box sx={{ p: 2 }}>
                                <Typography variant="subtitle2">
                                  {corporation.registration_name}
                                </Typography>
                              </Box>
                            </Box>

                            <Box
                              sx={{
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "space-between",
                                gap: 2,
                                borderBottom: 1,
                                borderColor: "divider",
                              }}
                            >
                              <Box
                                sx={{ borderRight: 1, borderColor: "divider", width: "50%", p: 2 }}
                              >
                                <Typography>Registration country</Typography>
                              </Box>
                              <Box sx={{ p: 2 }}>
                                <Typography variant="subtitle2">
                                  {corporation.registration_country.name}
                                </Typography>
                              </Box>
                            </Box>

                            <Box
                              sx={{
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "space-between",
                                gap: 2,
                                borderBottom: 1,
                                borderColor: "divider",
                              }}
                            >
                              <Box
                                sx={{ borderRight: 1, borderColor: "divider", width: "50%", p: 2 }}
                              >
                                <Typography>Registration reference</Typography>
                              </Box>
                              <Box sx={{ p: 2 }}>
                                <Typography variant="subtitle2">
                                  {corporation.registration_reference}
                                </Typography>
                              </Box>
                            </Box>

                            <Box
                              sx={{
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "space-between",
                                gap: 2,
                                borderBottom: 1,
                                borderColor: "divider",
                              }}
                            >
                              <Box
                                sx={{ borderRight: 1, borderColor: "divider", width: "50%", p: 2 }}
                              >
                                <Typography>Registration type</Typography>
                              </Box>
                              <Box sx={{ p: 2 }}>
                                <Typography variant="subtitle2">
                                  {corporation.registration_type_label
                                    ? corporation.registration_type_label
                                    : "-"}
                                </Typography>
                              </Box>
                            </Box>

                            <Box
                              sx={{
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "space-between",
                                gap: 2,
                              }}
                            >
                              <Box
                                sx={{ borderRight: 1, borderColor: "divider", width: "50%", p: 2 }}
                              >
                                <Typography>Registration date</Typography>
                              </Box>
                              <Box sx={{ p: 2 }}>
                                <Typography variant="subtitle2">
                                  {moment(corporation.registration_date).format("DD/MM/YYYY")}
                                </Typography>
                              </Box>
                            </Box>
                          </Box>
                        </Box>
                      );
                    })}
                  </Box>
                )}
                {data.field_type == 24 && <Box>{printMultiplePeople(data.answer.response)}</Box>}
              </Box>
            ) : (
              <Box sx={{ p: 2, borderTop: 1, borderColor: "divider" }}>
                <Typography>-</Typography>
              </Box>
            )}
          </Box>
        ))}
      </Box>

      <Drawer
        PaperProps={{ sx: { minWidth: 750 } }}
        anchor="right"
        open={viewFile}
        onClose={() => setViewFile(false)}
      >
        <Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              p: 2,
              borderBottom: 1,
              borderColor: "divider",
            }}
          >
            <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
              <Typography variant="h6" sx={{ fontWeight: 600 }}>
                File Viewer
              </Typography>
            </Box>
            <Box>
              <IconButton onClick={() => setViewFile(false)}>
                <Close />
              </IconButton>
            </Box>
          </Box>
          <Box sx={{ p: 2 }}>
            <Box sx={{ border: 1, borderColor: "divider", minHeight: 100 }}>
              {fileData?.filetype == "image" && (
                <Box
                  component="img"
                  sx={{ display: "flex", justifyContent: "center", maxWidth: "1250px" }}
                  src={`${partnerAPI}/${fileData.url}`}
                ></Box>
              )}

              {fileData?.filetype == "pdf" && (
                <embed
                  className="pdf-viewer"
                  src={`${partnerAPI}/${fileData.url}#view=Fit`}
                  type={`application/pdf`}
                />
              )}
            </Box>
          </Box>
        </Box>
      </Drawer>
    </Box>
  ) : (
    <Box>
      <Lottie speed={2.0} options={defaultOptions} height={200} width={200} />
    </Box>
  );
}

export default ViewRequest;
