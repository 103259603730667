import { React, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { styled } from "@mui/material/styles";

import Cookies from "universal-cookie";
import partner from "../../api/partner";
import authHeader from "../../api/authHeader";

import ErrorHandling from "../../components/functions/HandleError";

import FeatherIcon from "feather-icons-react";
import Lottie from "react-lottie";
import loadingLottie from "../../components/lotties/loading-dots.json";

import {
  Apps,
  Assessment,
  Settings,
  WorkspacePremium,
  ArrowBack,
  LocalPolice,
  ChevronRight,
  ChevronLeft,
  Checklist,
  Toc,
  Home,
  MonitorHeart,
  Fingerprint,
  PermIdentity,
  ManageSearch,
  Diversity2,
  ZoomIn,
  Send,
  Schema,
  Groups,
  Store,
  Work,
  Paid,
  ContentCopy,
} from "@mui/icons-material";

import MuiDrawer from "@mui/material/Drawer";

import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import { Box, Collapse, Fade } from "@mui/material";

const drawerWidth = 275;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== "open" })(
  ({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: "nowrap",
    boxSizing: "border-box",
    ...(open && {
      ...openedMixin(theme),
      "& .MuiDrawer-paper": openedMixin(theme),
    }),
    ...(!open && {
      ...closedMixin(theme),
      "& .MuiDrawer-paper": closedMixin(theme),
    }),
  })
);

const DrawerHeader = styled("div")(({ theme }) => ({
  width: "100%",
  display: "flex",
  flexDirection: "row",
  justifyContent: "flex-start",
  backgroundColor: "#000000",
  color: "#ffffff",
  //padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const KybCaseNav = ({
  navOpen,
  setNavOpen,
  activeView,
  partnerUser,
  partnerData,
  userLoaded,
  setUserLoaded,
}) => {
  const cookies = new Cookies();
  const useAuthHeader = authHeader();
  const navigate = useNavigate();
  const handleError = ErrorHandling();

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: loadingLottie,
  };

  const Sections = [
    {
      title: "General",
      options: [
        {
          title: "Overview",
          icon: Apps,
          isActive: activeView.value == 0,
          clickFunction: () => {
            activeView.value = 0;
          },
          subOptions: [],
        },

        {
          title: "Business details",
          icon: Work,
          isActive: activeView.value == 1,
          clickFunction: () => {
            activeView.value = 1;
          },
          subOptions: [],
        },
        {
          title: "Corporate documents",
          icon: ContentCopy,
          isActive: activeView.value == 12,
          clickFunction: () => {
            activeView.value = 12;
          },
          subOptions: [],
        },
        {
          title: "Activities",
          icon: Store,
          isActive: activeView.value == 2,
          clickFunction: () => {
            activeView.value = 2;
          },
          subOptions: [],
        },
        {
          title: "Address",
          icon: Home,
          isActive: activeView.value == 3,
          clickFunction: () => {
            activeView.value = 3;
          },
          subOptions: [],
        },
        {
          title: "Financials",
          icon: Paid,
          isActive: activeView.value == 11,
          clickFunction: () => {
            activeView.value = 11;
          },
          subOptions: [],
        },
        {
          title: "Ownership",
          icon: Schema,
          isActive: activeView.value == 4,
          clickFunction: () => {
            activeView.value = 4;
          },
          subOptions: [],
        },
        {
          title: "Officers & Management",
          icon: Groups,
          isActive: activeView.value == 5,
          clickFunction: () => {
            activeView.value = 5;
          },
          subOptions: [],
        },
        {
          title: "Background",
          icon: ManageSearch,
          isActive: activeView.value == 6,
          clickFunction: () => {
            activeView.value = 6;
          },
          subOptions: [],
        },
        {
          title: "FATCA/CRS",
          icon: Diversity2,
          isActive: activeView.value == 7,
          clickFunction: () => {
            activeView.value = 7;
          },
          subOptions: [],
        },
        {
          title: "Enhanced",
          icon: ZoomIn,
          isActive: activeView.value == 8,
          clickFunction: () => {
            activeView.value = 8;
          },
          subOptions: [],
        },
      ],
    },
    {
      title: "Processes",
      options: [
        {
          title: "Logs",
          icon: Toc,
          isActive: activeView.value == 9,
          clickFunction: () => {
            activeView.value = 9;
          },
          subOptions: [],
        },
      ],
    },
    /* {
      title: "Account",
      options: [
        {
          title: "Settings",
          icon: Settings,
          isActive: activeView.value == 10,
          clickFunction: () => {
            activeView.value = 10;
          },
        },
      ],
    }, */

    {
      title: "Access",
      options: [
        {
          title: "Back",
          icon: ArrowBack,
          isLogout: false,
          spacing: true,
          clickFunction: () => {
            navigate(-1);
          },
          subOptions: [],
        },
      ],
    },
  ];

  const getPartnerUserData = async () => {
    try {
      const response = await partner.post("/v1/users/self/data", JSON.stringify({}), {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Basic " + useAuthHeader.base64encodedData,
          service: "partners",
        },
        mode: "no-cors",
        //withCredentials: true,
      });

      partnerUser.value = response.data;

      setUserLoaded(true);
      // setIsLoaded(true);
    } catch (err) {
      handleError(err);
    }
  };

  const getPartnerData = async () => {
    try {
      const response = await partner.get("/v1/partner/data", {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Basic " + useAuthHeader.base64encodedData,
          service: "partners",
        },
        mode: "no-cors",
        //withCredentials: true,
      });

      partnerData.value = response.data;
    } catch (err) {
      handleError(err);
    }
  };

  useEffect(() => {
    !userLoaded && getPartnerUserData();
    getPartnerData();
  }, []);

  return (
    <Drawer variant="permanent" open={navOpen}>
      <DrawerHeader>
        <IconButton sx={{ color: "#ffffff", fill: "#ffffff" }} onClick={() => setNavOpen(!navOpen)}>
          {navOpen ? <ChevronLeft color="white.main" /> : <ChevronRight color="white.main" />}
        </IconButton>
        <Fade in={true} timeout={2000}>
          <Box
            onClick={() => navigate("/dashboard")}
            sx={{ mb: 0, marginLeft: 3, cursor: "pointer" }}
            width={150}
            component="img"
            alt="Logo"
            src="/cleverli-logo.svg"
          />
        </Fade>
      </DrawerHeader>
      <Divider />
      {userLoaded ? (
        Sections.map((section, index) => (
          <Box sx={{ display: "block" }} key={index}>
            <List key={index + `section`}>
              {section.options.map((item, index) => (
                <ListItem
                  onClick={() => item.clickFunction()}
                  key={index}
                  disablePadding
                  sx={{ display: "block" }}
                >
                  <ListItemButton
                    className={`transition-all ${item.isActive && "selected"} ${
                      item.isLogout && "logout"
                    }`}
                    sx={{
                      minHeight: 48,
                      justifyContent: navOpen ? "initial" : "center",
                      px: 2.5,
                    }}
                  >
                    <ListItemIcon
                      className="transition-all"
                      sx={{
                        minWidth: 0,
                        mr: navOpen ? 3 : "auto",
                        justifyContent: "center",
                      }}
                    >
                      {<item.icon />}
                    </ListItemIcon>
                    <ListItemText primary={item.title} sx={{ opacity: navOpen ? 1 : 0 }} />
                  </ListItemButton>
                  {item.subOptions?.length > 0 && (
                    <Collapse
                      in={item.isActive}
                      exit={!item.isActive}
                      orientation="vertical"
                      timeout={1000}
                    >
                      <List>
                        {item.subOptions.map((subItem, index) => (
                          <ListItem
                            onClick={() => subItem.clickFunction()}
                            key={index}
                            disablePadding
                            sx={{ display: "block" }}
                          >
                            <ListItemButton
                              sx={{
                                minHeight: 48,
                                justifyContent: navOpen ? "initial" : "center",
                                px: 2.5,
                              }}
                              className={`transition-all ${subItem.isActive && "sub-selected"} ${
                                subItem.isLogout && "logout"
                              } ${subItem.spacing && "spacing"}`}
                            >
                              <ListItemIcon
                                className="transition-all"
                                sx={{
                                  minWidth: 0,
                                  mr: navOpen ? 3 : "auto",
                                  justifyContent: "center",
                                }}
                              >
                                {subItem.icon}
                              </ListItemIcon>
                              <ListItemText
                                primary={subItem.title}
                                sx={{ opacity: navOpen ? 1 : 0 }}
                              />
                            </ListItemButton>
                          </ListItem>
                        ))}
                      </List>
                    </Collapse>
                  )}
                </ListItem>
              ))}
            </List>

            {section.options?.length > 0 && <Divider />}
          </Box>
        ))
      ) : (
        <Lottie speed={1.75} options={defaultOptions} height={200} width={200} />
      )}
      <Divider />
    </Drawer>
  );
};

export default KybCaseNav;
