import { React, useEffect, useState } from "react";

import partner from "../../../../api/partner";
import authHeader from "../../../../api/authHeader";

import HandleError from "../../../../components/functions/HandleError";

import { Box, Typography, Autocomplete, TextField } from "@mui/material";

function TeamMembers({ value, setValue }) {
  const useAuthHeader = authHeader();
  const handleError = HandleError();

  const [options, setOptions] = useState([]);

  const getUsers = async () => {
    try {
      const response = await partner.post(`/v1/users/data/users`, JSON.stringify({}), {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Basic " + useAuthHeader.base64encodedData,
          service: "partners",
        },

        //withCredentials: true,
      });

      if (response.data.results > 0) {
        response.data.entries.forEach((item) => {
          setOptions((prev) => [
            ...prev,
            {
              name: item.name,
              value: item.uuid,
            },
          ]);
        });

        setOptions((prev) => [
          ...prev,
          {
            name: "Other",
            value: "other",
          },
        ]);
      } else {
        setOptions([]);
      }
    } catch (err) {
      handleError(err);
    }
  };

  useEffect(() => {
    getUsers();
  }, []);

  return (
    <Autocomplete
      id="team-member-select"
      sx={{ width: "100%" }}
      options={options}
      value={value ? value : null}
      onChange={(event, newValue) => {
        setValue(newValue ? newValue : "");
      }}
      autoHighlight
      isOptionEqualToValue={(option, value) => option.value === value.value}
      getOptionLabel={(option) => option.name}
      renderOption={(props, option) => (
        <Box component="li" sx={{}} {...props}>
          {option.name}
        </Box>
      )}
      renderInput={(params) => (
        <TextField
          {...params}
          label="Choose a team member"
          inputProps={{
            ...params.inputProps,
            autoComplete: "new-password", // disable autocomplete and autofill
          }}
        />
      )}
    />
  );
}

export default TeamMembers;
