import { React, useState } from "react";

import partner from "../../../../../api/partner";
import authHeader from "../../../../../api/authHeader";

import HandleToast from "../../../../../components/functions/HandleToast";
import ErrorHandling from "../../../../../components/functions/HandleError";

import Lottie from "react-lottie";
import successLottie from "../../../../../components/lotties/success.json";

import { Container, Typography, Grid, TextField, FormControl, InputLabel, MenuItem, Select, Box } from "@mui/material";
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";
import { LoadingButton } from "@mui/lab";

function toTitleCase(str) {
  return str.replace(/\w\S*/g, function (txt) {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  });
}

const Personal = ({ partner, partnerUser, partnerData }) => {
  const useAuthHeader = authHeader();
  const handleError = ErrorHandling();
  const displayToast = HandleToast();

  const [processing, setProcessing] = useState(false);
  const [displaySuccess, setDisplaySuccess] = useState(false);

  const [confirmDialog, setConfirmDialog] = useState(false);
  const [title, setTitle] = useState(partnerUser.value.title ? toTitleCase(partnerUser.value.title) : "");
  const [firstName, setFirstName] = useState(partnerUser.value.first_name ? partnerUser.value.first_name : "");
  const [middleNames, setMiddleNames] = useState(partnerUser.value.middle_names ? partnerUser.value.middle_names : "");
  const [lastName, setLastName] = useState(partnerUser.value.last_name ? partnerUser.value.last_name : "");

  const titleOptions = [
    { value: "Mr", label: "Mr" },
    { value: "Mrs", label: "Mrs" },
    { value: "Miss", label: "Miss" },
    { value: "Ms", label: "Ms" },
    { value: "Dr", label: "Dr" },
    { value: "Prof", label: "Prof" },
    { value: "Rev", label: "Rev" },
    { value: "Sir", label: "Sir" },
    { value: "Madam", label: "Madam" },
    { value: "Lord", label: "Lord" },
    { value: "Lady", label: "Lady" },
    { value: "Fr", label: "Father" },
    { value: "Sr", label: "Sister" },
    { value: "Imam", label: "Imam" },
    { value: "Sheikh", label: "Sheikh" },
    { value: "Rabbi", label: "Rabbi" },
    { value: "Duke", label: "Duke" },
    { value: "Duchess", label: "Duchess" },
    { value: "Earl", label: "Earl" },
    { value: "Count", label: "Count" },
    { value: "Countess", label: "Countess" },
    { value: "Baron", label: "Baron" },
    { value: "Baroness", label: "Baroness" },
    { value: "Capt", label: "Captain" },
    { value: "Lt", label: "Lieutenant" },
    { value: "Col", label: "Colonel" },
    { value: "Gen", label: "General" },
  ];

  const defaultOptions = {
    loop: false,
    autoplay: true,
    animationData: successLottie,
  };

  const handleSaveNameSettings = async (e) => {
    e.preventDefault();
    setProcessing(true);

    try {
      const response = await partner.post(
        `/v1/people/update/name`,
        JSON.stringify({
          person_uuid: partnerUser.value.person_uuid,
          title: title,
          first_name: firstName,
          middle_names: middleNames,
          last_name: lastName,
        }),
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Basic " + useAuthHeader.base64encodedData,
            service: "partners",
          },

          //withCredentials: true,
        }
      );

      setDisplaySuccess(true);

      setTimeout(() => {
        setConfirmDialog(false);
        setDisplaySuccess(false);
        displayToast(`Name updated successfully`, () => {
          setProcessing(false);
        });
      }, 2000);
    } catch (err) {
      setConfirmDialog(false);
      handleError(err);
      setProcessing(false);
    }
  };

  return (
    <>
      <Box sx={{ borderBottom: 1, borderTop: 1, borderColor: "divider", p: 0 }}>
        <Typography sx={{ mb: 0, p: 2 }} variant="h6" gutterBottom>
          What is your name?
        </Typography>
      </Box>

      <Box sx={{ border: 0, borderColor: "divider", p: 2, borderTop: 0 }}>
        <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "space-evenly", gap: 2 }}>
          <Box sx={{ width: "100%" }}>
            <FormControl fullWidth>
              <InputLabel id="name-title-label">Title</InputLabel>
              <Select labelId="name-title" id="name-title" value={title} label="Title" onChange={(e) => setTitle(e.target.value)}>
                {titleOptions.map((option, index) => (
                  <MenuItem key={index} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
          <Box sx={{ width: "100%" }}>
            <TextField
              fullWidth
              id="outlined-basic"
              label="First name"
              variant="outlined"
              value={firstName}
              onChange={(e) => setFirstName(e.target.value)}
            />
          </Box>
          <Box sx={{ width: "100%" }}>
            <TextField
              fullWidth
              id="outlined-basic"
              label="Middle name(s)"
              variant="outlined"
              value={middleNames}
              onChange={(e) => setMiddleNames(e.target.value)}
              helperText="If applicable, separate with spaces"
            />
          </Box>
          <Box sx={{ width: "100%" }}>
            <TextField
              fullWidth
              id="outlined-basic"
              label="Last name"
              variant="outlined"
              value={lastName}
              onChange={(e) => setLastName(e.target.value)}
            />

            <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
              <LoadingButton
                disableElevation
                loading={processing}
                variant="contained"
                color="primary"
                sx={{ mt: 2 }}
                onClick={() => setConfirmDialog(true)}
              >
                Save changes
              </LoadingButton>
            </Box>
          </Box>
        </Box>
      </Box>

      <Dialog
        open={confirmDialog}
        onClose={() => setConfirmDialog(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{displaySuccess ? "Complete" : "Please confirm"}</DialogTitle>
        <DialogContent>
          {displaySuccess ? (
            <Lottie options={defaultOptions} height={100} width={100} />
          ) : (
            <DialogContentText id="alert-dialog-description">Are you sure you want to change your name?</DialogContentText>
          )}
        </DialogContent>
        <DialogActions>
          {!displaySuccess && (
            <LoadingButton disableElevation loading={processing} onClick={() => setConfirmDialog(false)} color="primary">
              Cancel
            </LoadingButton>
          )}

          <LoadingButton
            disableElevation
            fullWidth={displaySuccess}
            loading={processing}
            onClick={(e) => {
              handleSaveNameSettings(e);
            }}
            variant="contained"
            color="success"
            autoFocus
          >
            Confirm
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default Personal;
