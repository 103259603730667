import { React, useEffect, useState } from "react";

import authHeader from "../../../../api/authHeader";

import HandleError from "../../../../components/functions/HandleError";
import HandleToast from "../../../../components/functions/HandleToast";

import Lottie from "react-lottie";
import loadingLottie from "../../../../components/lotties/loading-dots.json";

import moment from "moment";
import DataTable from "react-data-table-component";

import { Box, Typography } from "@mui/material";
import NoResults from "../../../../components/functions/NoResults";

const Transactions = ({ partner, partnerUser, partnerData }) => {
  const useAuthHeader = authHeader();

  const handleError = HandleError();
  const displayToast = HandleToast();

  const [isLoaded, setIsLoaded] = useState(false);
  const [processing, setProcessing] = useState(false);
  const [data, setData] = useState([]);

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: loadingLottie,
  };

  const customStyles = {
    headCells: {
      style: {
        paddingLeft: "8px", // override the cell padding for head cells
        paddingRight: "8px",
        fontWeight: "bold",
      },
    },
    cells: {
      style: {
        paddingLeft: "8px", // override the cell padding for data cells
        paddingRight: "8px",
      },
    },
  };

  const columns = [
    {
      name: "Transaction time",
      sortable: true,
      selector: (row) => row.timestamp,
      cell: (row) => (
        <Box>
          <Typography sx={{ fontSize: "13px" }}>{moment.unix(row.timestamp).format("DD/MM/YYYY HH:mm")}</Typography>
        </Box>
      ),
    },
    {
      name: "Transaction type",
      sortable: true,
      selector: (row) => row.transaction_type,
      cell: (row) => (
        <Box>
          <Typography sx={{ fontSize: "13px" }}>{row.transaction_type_label}</Typography>
        </Box>
      ),
    },
    {
      name: "Amount",
      sortable: false,
      right: "true",
      selector: (row) => row.amount,
      cell: (row) => (
        <Box>
          <Typography sx={{ fontSize: "13px" }}>{row.amount}</Typography>
        </Box>
      ),
    },
  ];

  useEffect(() => {
    setIsLoaded(true);
  }, []);

  return (
    <>
      {isLoaded ? (
        <>
          <Box sx={{ border: 1, borderColor: "divider" }}>
            <DataTable
              columns={columns}
              data={data}
              customStyles={customStyles}
              pagination
              striped
              noDataComponent={<NoResults message={`Waiting on your first transaction`} />}
            />
          </Box>
        </>
      ) : (
        <>
          <Box>
            <Lottie speed={2.0} options={defaultOptions} height={200} width={200} />
          </Box>
        </>
      )}
    </>
  );
};

export default Transactions;
