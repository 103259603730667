import { React, useState } from "react";

import { Typography, Box, Button, Divider, Card, Avatar, CardHeader, CardContent, IconButton, Alert, Icon } from "@mui/material";
import { Grid, TextField, FormControl, InputLabel, MenuItem, Select, ToggleButton, ToggleButtonGroup, Chip } from "@mui/material";
import { Add, Delete } from "@mui/icons-material";
import NoResults from "../../../../../../../../components/functions/NoResults";

const MultipleIndividuals = () => {
  const titleOptions = [
    { value: "Mr", label: "Mr" },
    { value: "Mrs", label: "Mrs" },
    { value: "Miss", label: "Miss" },
    { value: "Ms", label: "Ms" },
    { value: "Dr", label: "Dr" },
    { value: "Prof", label: "Prof" },
    { value: "Rev", label: "Rev" },
    { value: "Sir", label: "Sir" },
    { value: "Madam", label: "Madam" },
    { value: "Lord", label: "Lord" },
    { value: "Lady", label: "Lady" },
    { value: "Fr", label: "Father" },
    { value: "Sr", label: "Sister" },
    { value: "Imam", label: "Imam" },
    { value: "Sheikh", label: "Sheikh" },
    { value: "Rabbi", label: "Rabbi" },
    { value: "Duke", label: "Duke" },
    { value: "Duchess", label: "Duchess" },
    { value: "Earl", label: "Earl" },
    { value: "Count", label: "Count" },
    { value: "Countess", label: "Countess" },
    { value: "Baron", label: "Baron" },
    { value: "Baroness", label: "Baroness" },
    { value: "Capt", label: "Captain" },
    { value: "Lt", label: "Lieutenant" },
    { value: "Col", label: "Colonel" },
    { value: "Gen", label: "General" },
  ];

  const [title, setTitle] = useState("");
  const [firstName, setFirstName] = useState("");
  const [middleNames, setMiddleNames] = useState("");
  const [lastName, setLastName] = useState("");

  const [individuals, setIndividuals] = useState([{ title: "Mr", firstName: "Thomas", middleNames: "", lastName: "Smith" }]);
  const [addIndividual, setAddIndividual] = useState(false);

  const addIndividualHandler = () => {
    if (title === "" || firstName === "" || lastName === "") {
      return;
    }

    setIndividuals([...individuals, { title: title, firstName: firstName, middleNames: middleNames, lastName: lastName }]);
    setAddIndividual(false);
    setTitle("");
    setFirstName("");
    setMiddleNames("");
    setLastName("");
  };

  const removeIndividualHandler = (index) => {
    const newIndividuals = [...individuals];
    newIndividuals.splice(index, 1);
    setIndividuals(newIndividuals);
  };

  return !addIndividual ? (
    <Box sx={{ border: 1, borderColor: "divider" }}>
      <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "space-between", p: 2 }}>
        <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
          <Typography variant="subtitle2">People</Typography>
        </Box>
        <Button onClick={() => setAddIndividual(true)} variant="outlined" color="primary" startIcon={<Add />}>
          Add person
        </Button>
      </Box>
      <Box sx={{}}>
        {individuals.length > 0 ? (
          individuals.map((individual, index) => (
            <Box
              key={index}
              sx={{
                display: "flex",
                flexDirection: "row",
                gap: 2,
                justifyContent: "space-between",
                px: 2,
                py: 1,
                borderTop: 1,
                borderColor: "divider",
              }}
            >
              <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
                <Typography variant="subtitle2">
                  {individual.title} {individual.firstName} {individual.middleNames} {individual.lastName}
                </Typography>
              </Box>
              <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                <IconButton color="danger" onClick={() => removeIndividualHandler(index)}>
                  <Delete />
                </IconButton>
              </Box>
            </Box>
          ))
        ) : (
          <NoResults message={`Add your first person`} />
        )}
      </Box>
    </Box>
  ) : (
    <Box sx={{ display: "flex", flexDirection: "column", border: 1, borderColor: "divider" }}>
      <Box sx={{ display: "flex", flexDirection: "row", gap: 2, justifyContent: "space-between", p: 2 }}>
        <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
          <Typography variant="subtitle2">Add a person</Typography>
        </Box>
        <Button onClick={() => setAddIndividual(false)} variant="outlined" color="primary">
          Cancel
        </Button>
      </Box>
      <Divider />
      <Box sx={{ display: "flex", flexDirection: "column", gap: 2, p: 2 }}>
        <FormControl fullWidth>
          <InputLabel id="name-title-label">Title</InputLabel>
          <Select labelId="name-title" id="name-title" value={title} label="Title" onChange={(e) => setTitle(e.target.value)}>
            {titleOptions.map((option, index) => (
              <MenuItem key={index} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <TextField
          fullWidth
          id="outlined-basic"
          label="First name"
          variant="outlined"
          value={firstName}
          onChange={(e) => setFirstName(e.target.value)}
        />

        <TextField
          fullWidth
          id="outlined-basic"
          label="Middle name(s)"
          variant="outlined"
          value={middleNames}
          onChange={(e) => setMiddleNames(e.target.value)}
          helperText="If applicable, separate with spaces"
        />

        <TextField
          fullWidth
          id="outlined-basic"
          label="Last name"
          variant="outlined"
          value={lastName}
          onChange={(e) => setLastName(e.target.value)}
        />

        <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
          <Button onClick={() => addIndividualHandler()} disableElevation variant="contained" color="success">
            Add person
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default MultipleIndividuals;
