export const tasksStatus = {
  0: {
    label: "Unassigned",
    color: "default",
  },
  1: {
    label: "Pending",
    color: "warning",
  },
  2: {
    label: "In Progress",
    color: "info",
  },
  3: {
    label: "On Hold",
    color: "secondary",
  },
  4: {
    label: "Waiting",
    color: "warning",
  },
  5: {
    label: "Complete",
    color: "success",
  },
  6: {
    label: "Cancelled",
    color: "error",
  },
};
