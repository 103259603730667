import { Delete } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import {
  Box,
  Button,
  Divider,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from "@mui/material";
import ReactQuill from "react-quill";
import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { generateRandomId } from "../../../../../../utils/number";
import { Modal } from "../../../../../../components/modal";

const defaultStepFormState = {
  label: "",
  description: "",
  requires_confirmation: false,
  requires_file: false,
  file_description: "",
};

export function StepFrom({ open, onClose, onSave, editStepData }) {
  const [stepFormState, setStepFormState] = useState(defaultStepFormState);

  function handleResetForm() {
    setStepFormState(defaultStepFormState);
  }

  function handleSave() {
    if (editStepData) {
      onSave({
        type: "UPDATE_STEP",
        payload: { id: editStepData.id, ...stepFormState },
      });
    } else {
      onSave({
        type: "ADD_STEP",
        payload: { id: generateRandomId(), ...stepFormState },
      });
    }
    onClose();
    handleResetForm();
  }

  function handleDelete() {
    onSave({
      type: "DELETE_STEP",
      payload: { id: editStepData.id },
    });
    onClose();
    handleResetForm();
  }

  useEffect(() => {
    if (editStepData) {
      handleResetForm();
      setStepFormState(editStepData);
    } else {
      handleResetForm();
    }
  }, [editStepData]);

  return (
    <Modal
      open={open}
      onClose={onClose}
      customStyle={{
        height: "fit-content",
      }}
    >
      <Box sx={{ p: 2, display: "flex", justifyContent: "space-between" }}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
          }}
        >
          <Typography variant="subtitle2">Workflow steps</Typography>
        </Box>
        <Button
          variant="outlined"
          color="primary"
          onClick={() => {
            onClose();
          }}
        >
          close
        </Button>
      </Box>

      <Divider />
      <Box>
        <Box>
          <Box sx={{ p: 2, display: "flex", flexDirection: "column", gap: 2 }}>
            <TextField
              fullWidth
              id="outlined-basic"
              label="Step label"
              variant="outlined"
              value={stepFormState?.label}
              onChange={(e) => {
                setStepFormState({
                  ...stepFormState,
                  label: e.target.value,
                });
              }}
            />
          </Box>
          <Divider />

          <Box sx={{}}>
            <Box sx={{ p: 2 }}>
              <Typography variant="subtitle2">Provide instructions for the step</Typography>
            </Box>

            <Divider />
            <ReactQuill
              theme="snow"
              value={stepFormState?.description}
              onChange={(value) => {
                setStepFormState({
                  ...stepFormState,
                  description: value,
                });
              }}
              style={{ height: "200px" }}
            />
          </Box>

          <Divider />

          <Box sx={{ display: "flex", justifyContent: "space-between", p: 2 }}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
              }}
            >
              <Typography variant="subtitle2">Require confirmation</Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: 2,
                width: "50%",
              }}
            >
              <Box sx={{ display: "flex", justifyContent: "flex-end", gap: 1 }}>
                <ToggleButtonGroup
                  size="small"
                  color={stepFormState?.requires_confirmation ? "primary" : "danger"}
                  value={stepFormState?.requires_confirmation}
                  exclusive
                  onChange={(e, newValue) => {
                    setStepFormState({
                      ...stepFormState,
                      requires_confirmation: newValue,
                    });
                  }}
                  aria-label="Require confirmation"
                >
                  <ToggleButton value={true}>Yes</ToggleButton>
                  <ToggleButton value={false}>No</ToggleButton>
                </ToggleButtonGroup>
              </Box>
            </Box>
          </Box>

          <Box sx={{ display: "flex", justifyContent: "space-between", p: 2 }}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
              }}
            >
              <Typography variant="subtitle2">Require File</Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: 2,
                width: "50%",
              }}
            >
              <Box sx={{ display: "flex", justifyContent: "flex-end", gap: 1 }}>
                <ToggleButtonGroup
                  size="small"
                  color={stepFormState?.requires_file ? "primary" : "danger"}
                  value={stepFormState?.requires_file}
                  exclusive
                  onChange={(e, newValue) => {
                    setStepFormState({
                      ...stepFormState,
                      requires_file: newValue,
                    });
                  }}
                  aria-label="Step status"
                >
                  <ToggleButton value={true}>Yes</ToggleButton>
                  <ToggleButton value={false}>No</ToggleButton>
                </ToggleButtonGroup>
              </Box>
            </Box>
          </Box>

          <Divider />

          <Box sx={{ display: "flex", justifyContent: "space-between", p: 2 }}>
            <Box>
              {editStepData && (
                <Button
                  variant="outlined"
                  color="danger"
                  startIcon={<Delete />}
                  onClick={handleDelete}
                >
                  Delete step
                </Button>
              )}
            </Box>
            <LoadingButton
              disableElevation
              variant="contained"
              color="success"
              onClick={handleSave}
            >
              {editStepData ? "Save changes" : "Create step"}
            </LoadingButton>
          </Box>
        </Box>
      </Box>
    </Modal>
  );
}

StepFrom.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  onSave: PropTypes.func,
  editStepData: PropTypes.shape({
    id: PropTypes.number,
    label: PropTypes.string,
    description: PropTypes.string,
    requires_confirmation: PropTypes.bool,
    requires_file: PropTypes.bool,
    file_description: PropTypes.string,
  }),
};
