import { React, useEffect, useState } from "react";

import { useNavigate } from "react-router-dom";

import authHeader from "../../../../api/authHeader";

import HandleError from "../../../../components/functions/HandleError";
import HandleToast from "../../../../components/functions/HandleToast";

import Lottie from "react-lottie";
import loadingLottie from "../../../../components/lotties/loading-dots.json";

import { Typography, Box, Button, Divider } from "@mui/material";
import { Grid, TextField, FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { LoadingButton } from "@mui/lab";

const NewUser = ({ partner, partnerUser, partnerData }) => {
  const useAuthHeader = authHeader();
  const navigate = useNavigate();

  const handleError = HandleError();
  const displayToast = HandleToast();

  const [isLoaded, setIsLoaded] = useState(false);
  const [processing, setProcessing] = useState(false);

  const [step, setStep] = useState(0);

  const [title, setTitle] = useState("");
  const [firstName, setFirstName] = useState("");
  const [middleNames, setMiddleNames] = useState("");
  const [lastName, setLastName] = useState("");

  const [emailAddress, setEmailAddress] = useState("");

  const [billingTiers, setBillingTiers] = useState([]);
  const [selectedBillingTiers, setSelectedBillingTiers] = useState([]);

  const titleOptions = [
    { value: "Mr", label: "Mr" },
    { value: "Mrs", label: "Mrs" },
    { value: "Miss", label: "Miss" },
    { value: "Ms", label: "Ms" },
    { value: "Dr", label: "Dr" },
    { value: "Prof", label: "Prof" },
    { value: "Rev", label: "Rev" },
    { value: "Sir", label: "Sir" },
    { value: "Madam", label: "Madam" },
    { value: "Lord", label: "Lord" },
    { value: "Lady", label: "Lady" },
    { value: "Fr", label: "Father" },
    { value: "Sr", label: "Sister" },
    { value: "Imam", label: "Imam" },
    { value: "Sheikh", label: "Sheikh" },
    { value: "Rabbi", label: "Rabbi" },
    { value: "Duke", label: "Duke" },
    { value: "Duchess", label: "Duchess" },
    { value: "Earl", label: "Earl" },
    { value: "Count", label: "Count" },
    { value: "Countess", label: "Countess" },
    { value: "Baron", label: "Baron" },
    { value: "Baroness", label: "Baroness" },
    { value: "Capt", label: "Captain" },
    { value: "Lt", label: "Lieutenant" },
    { value: "Col", label: "Colonel" },
    { value: "Gen", label: "General" },
  ];

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: loadingLottie,
  };

  const handleCreateUser = async (e) => {
    e.preventDefault();
    setProcessing(true);
    try {
      const response = await partner.post(
        `/v1/users/admin/create`,
        JSON.stringify({
          title: title,
          first_name: firstName,
          middle_names: middleNames,
          last_name: lastName,
          email_address: emailAddress,
        }),
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Basic " + useAuthHeader.base64encodedData,
            service: "partners",
          },

          //withCredentials: true,
        }
      );

      displayToast("User created successfully", () => {
        setStep(0);
        setEmailAddress("");
        setTitle("");
        setFirstName("");
        setMiddleNames("");
        setLastName("");
        setSelectedBillingTiers([]);
        setProcessing(false);
        navigate("/user/" + response.data.uuid);
      });
    } catch (err) {
      handleError(err);
      setProcessing(false);
    }
  };

  useEffect(() => {
    setIsLoaded(true);
  }, []);

  return (
    <>
      {isLoaded ? (
        <Box>
          {step == 0 && (
            <>
              <Box sx={{}}>
                <Box sx={{ display: "flex", flexDirection: "column" }}>
                  <Box sx={{ p: 2 }}>
                    <Typography variant="body2">
                      <strong>What is the users name?</strong>
                    </Typography>
                  </Box>

                  <Divider />

                  <Box sx={{ display: "flex", flexDirection: "column", gap: 2, p: 2 }}>
                    <FormControl fullWidth>
                      <InputLabel id="name-title-label">Title</InputLabel>
                      <Select labelId="name-title" id="name-title" value={title} label="Title" onChange={(e) => setTitle(e.target.value)}>
                        {titleOptions.map((option, index) => (
                          <MenuItem key={index} value={option.value}>
                            {option.label}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>

                    <TextField
                      fullWidth
                      id="outlined-basic"
                      label="First name"
                      variant="outlined"
                      value={firstName}
                      onChange={(e) => setFirstName(e.target.value)}
                    />

                    <TextField
                      fullWidth
                      id="outlined-basic"
                      label="Middle name(s)"
                      variant="outlined"
                      value={middleNames}
                      onChange={(e) => setMiddleNames(e.target.value)}
                      helperText="If applicable, separate with spaces"
                    />

                    <TextField
                      fullWidth
                      id="outlined-basic"
                      label="Last name"
                      variant="outlined"
                      value={lastName}
                      onChange={(e) => setLastName(e.target.value)}
                    />
                  </Box>
                </Box>

                <Divider />

                <Box sx={{ p: 2 }}>
                  <Typography variant="body2">
                    <strong>What is their email address?</strong>
                  </Typography>
                </Box>

                <Divider />

                <Box sx={{ p: 2 }}>
                  <TextField
                    fullWidth
                    id="outlined-basic"
                    label="Email address"
                    variant="outlined"
                    value={emailAddress}
                    onChange={(e) => setEmailAddress(e.target.value)}
                  />
                </Box>

                <Box sx={{ display: "flex", justifyContent: "flex-end", px: 2, pb: 2 }}>
                  <LoadingButton disableElevation loading={processing} variant="contained" color="success" onClick={(e) => handleCreateUser(e)}>
                    Create user
                  </LoadingButton>
                </Box>
              </Box>
            </>
          )}

          {step == 1 && (
            <>
              <Box sx={{ display: "flex", flexDirection: "column", gap: 2, p: 2 }}>
                <Box>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <Box sx={{ border: 1, borderColor: "divider" }}>
                        <Box sx={{ p: 2 }}>
                          <Typography variant="body2">
                            <strong>Select the users standard billing tiers</strong>
                          </Typography>
                        </Box>

                        <Divider />

                        <Box sx={{ p: 2 }}>
                          <FormControl fullWidth>
                            <InputLabel id="billing-tiers-label">Billing tiers</InputLabel>

                            <Select
                              labelId="billing-tiers-label"
                              id="billing-tiers"
                              multiple
                              value={selectedBillingTiers}
                              label="Billing tiers"
                              onChange={(e) => setSelectedBillingTiers(e.target.value)}
                            >
                              {billingTiers?.map((option, index) => (
                                <MenuItem key={index} value={option.value}>
                                  {option.label}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </Box>
                      </Box>
                    </Grid>
                  </Grid>
                  <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                    <LoadingButton disableElevation loading={processing} variant="outlined" color="primary" sx={{ mt: 2 }} onClick={() => setStep(0)}>
                      Back
                    </LoadingButton>

                    <LoadingButton
                      disableElevation
                      loading={processing}
                      variant="contained"
                      color="success"
                      sx={{ mt: 2, color: "#ffffff" }}
                      onClick={(e) => handleCreateUser(e)}
                    >
                      Create user
                    </LoadingButton>
                  </Box>
                </Box>
              </Box>
            </>
          )}
        </Box>
      ) : (
        <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", height: "50vh" }}>
          <Lottie speed={2.0} options={defaultOptions} height={200} width={200} />
        </Box>
      )}
    </>
  );
};

export default NewUser;
