import { React, useEffect, useState } from "react";

import authHeader from "../../../../api/authHeader";

import HandleError from "../../../../components/functions/HandleError";
import HandleToast from "../../../../components/functions/HandleToast";

import Lottie from "react-lottie";
import loadingLottie from "../../../../components/lotties/loading-dots.json";

import { Box, Typography, Button, Grid, Divider } from "@mui/material";

const Wallet = ({ partner, partnerUser, partnerData }) => {
  const useAuthHeader = authHeader();

  const handleError = HandleError();
  const displayToast = HandleToast();

  const [isLoaded, setIsLoaded] = useState(false);
  const [processing, setProcessing] = useState(false);

  const [accountBalance, setAccountBalance] = useState(0);

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: loadingLottie,
  };

  const formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "GBP",

    // These options are needed to round to whole numbers if that's what you want.
    //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
    //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
  });

  useEffect(() => {
    setIsLoaded(true);
  }, []);

  return (
    <>
      {isLoaded ? (
        <>
          <Grid container spacing={2}>
            <Grid item xs={12} lg={6}>
              <Box sx={{ border: 1, borderColor: "divider" }}>
                <Box p={{ display: "flex", justifyContent: "space-between" }}>
                  <Box p={2}>
                    <Typography variant="h6">Current balance</Typography>
                  </Box>
                  <Box p={2}>
                    <Button variant="contained" color="primary" onClick={() => {}}>
                      Deposit
                    </Button>
                  </Box>
                </Box>
                <Divider />
                <Box sx={{ p: 2, textAlign: "center" }}>
                  <Typography variant="h3">{formatter.format(accountBalance)}</Typography>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </>
      ) : (
        <Box>
          <Lottie speed={2.0} options={defaultOptions} height={200} width={200} />
        </Box>
      )}
    </>
  );
};

export default Wallet;
