import { React, useEffect, useState } from "react";

import partner from "../../api/partner";
import authHeader from "../../api/authHeader";

import HandleError from "../../components/functions/HandleError";
import HandleToast from "../../components/functions/HandleToast";

import Lottie from "react-lottie";
import loadingLottie from "../../components/lotties/loading-dots.json";

import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import moment from "moment";
import parse from "html-react-parser";
import DataTable from "react-data-table-component";

import {
  Box,
  Typography,
  Chip,
  Grid,
  Divider,
  Button,
  IconButton,
  ToggleButtonGroup,
  ToggleButton,
  Collapse,
  TextField,
  Select,
  MenuItem,
  MenuList,
  Drawer,
  Icon,
  Link,
} from "@mui/material";
import { ChevronRight, Close, Delete, Description, Edit, Facebook, ImportContacts, TrendingUpRounded, Visibility } from "@mui/icons-material";

import { LoadingButton } from "@mui/lab";
import NoResults from "../../components/functions/NoResults";
import ViewRequest from "./components/enhanced/ViewRequest";
import ListRequests from "./components/enhanced/ListRequests";

const Enhanced = ({ partnerUser, partnerData, caseData, entityData }) => {
  const useAuthHeader = authHeader();
  const handleError = HandleError();
  const displayToast = HandleToast();

  const [isLoaded, setIsLoaded] = useState(false);
  const [processing, setProcessing] = useState(false);

  const [data, setData] = useState();

  const [newRequest, setNewRequest] = useState(false);
  const [viewRequest, setViewRequest] = useState(false);
  const [activeRequest, setActiveRequest] = useState("");

  const [templateOptions, setTemplateOptions] = useState([]);
  const [selectedTemplate, setSelectedTemplate] = useState("");
  const [activeTemplate, setActiveTemplate] = useState({});
  const [activeTemplateView, setActiveTemplateView] = useState(false);
  const [previewedTemplate, setPreviewedTemplate] = useState({});
  const [previewTemplate, setPreviewTemplate] = useState(false);

  const [instructions, setInstructions] = useState("");
  const [inviteType, setInviteType] = useState(1);

  const [viewContacts, setViewContacts] = useState(false);
  const [contacts, setContacts] = useState([]);

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [emailAddress, setEmailAddress] = useState(entityData.value.email_address ? entityData.value.email_address : "");

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: loadingLottie,
  };

  const customStyles = {
    headCells: {
      style: {
        paddingLeft: "8px", // override the cell padding for head cells
        paddingRight: "8px",
        fontWeight: "bold",
      },
    },
    cells: {
      style: {
        paddingLeft: "8px", // override the cell padding for data cells
        paddingRight: "8px",
      },
    },
  };

  const templateColumns = [
    {
      name: "Template name",
      selector: (row) => row.code,
      cell: (row) => (
        <Box sx={{ display: "flex", flexDirection: "row", gap: 2 }}>
          <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
            <Typography sx={{ fontSize: "13px" }}>{row.name}</Typography>
          </Box>
        </Box>
      ),
    },
    {
      name: "Options",
      sortable: false,
      right: "true",
      cell: (row) => (
        <Box sx={{ display: "flex", flexDirection: "row" }}>
          <IconButton
            title="Preview"
            onClick={() => {
              getTemplateData(row.uuid);
            }}
          >
            <ChevronRight />
          </IconButton>
        </Box>
      ),
    },
  ];

  const getRequests = async () => {
    try {
      const response = await partner.post(
        `/v1/screening/case/kyc/enhanced/list`,
        JSON.stringify({
          person_uuid: entityData.value.person_uuid,
        }),
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Basic " + useAuthHeader.base64encodedData,
            service: "partners",
          },

          //withCredentials: true,
        }
      );

      if (response.data.results > 0) {
        setData(response.data.entries);
      } else {
        setData([]);
      }
      setIsLoaded(true);
    } catch (err) {
      handleError(err);
    }
  };

  const getTemplates = async () => {
    try {
      const response = await partner.post(`/v1/screening/templates/enhanced/list/active`, JSON.stringify({}), {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Basic " + useAuthHeader.base64encodedData,
          service: "partners",
        },

        //withCredentials: true,
      });

      if (response.data.results > 0) {
        setTemplateOptions(response.data.entries);
      } else {
        setTemplateOptions([]);
      }
    } catch (err) {
      handleError(err);
    }
  };

  const getTemplateData = async (templateUUID) => {
    try {
      const response = await partner.post(
        `/v1/screening/templates/enhanced/data`,
        JSON.stringify({
          template_uuid: templateUUID,
        }),
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Basic " + useAuthHeader.base64encodedData,
            service: "partners",
          },

          //withCredentials: true,
        }
      );

      console.log(response.data);
      setPreviewedTemplate(response.data);
      setPreviewTemplate(true);
    } catch (err) {
      handleError(err);
    }
  };

  const fieldTypeLabel = (fieldType) => {
    switch (fieldType) {
      case 1:
        return "Textarea";
      case 2:
        return "Single line text";
      case 3:
        return "Select option (single)";
      case 4:
        return "Select option (multiple)";
      case 5:
        return "Date";
      case 6:
        return "Email address";
      case 7:
        return "Telephone number";
      case 8:
        return "Address";
      case 9:
        return "Country (single)";
      case 10:
        return "Country (multiple)";
      case 11:
        return "True/False";
      case 12:
        return "Yes/No";
      case 13:
        return "Currency (single)";
      case 14:
        return "Currency (multiple)";
      case 15:
        return "Number (money)";
      case 16:
        return "Number (integer)";
      case 17:
        return "Number (decimal)";
      case 18:
        return "Number (percentage)";
      case 19:
        return "File upload (single)";
      case 20:
        return "File upload (multiple)";
      case 21:
        return "Corporate entity (single)";
      case 22:
        return "Individual person";
      case 23:
        return "Multiple corporate entities";
      case 24:
        return "Multiple people";
      default:
        return "Unknown";
    }
  };

  const handleSendRequest = async (e) => {
    e.preventDefault();
    setProcessing(true);

    try {
      const response = await partner.post(
        `/v1/screening/case/kyc/enhanced/create`,
        JSON.stringify({
          case_uuid: caseData.value.case_uuid,
          note: instructions ? instructions : "",
          send_invitation: inviteType === 1 ? true : false,
          email_address: emailAddress,
          template_uuid: selectedTemplate.template_data.uuid,
        }),
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Basic " + useAuthHeader.base64encodedData,
            service: "partners",
          },

          //withCredentials: true,
        }
      );

      getRequests();

      displayToast("EDD request created successfully", () => {
        setNewRequest(false);
        setProcessing(false);
      });
    } catch (err) {
      console.log(err);
      handleError(err);
      setProcessing(false);
    }
  };

  useEffect(() => {
    getRequests();
    getTemplates();
  }, []);

  return (
    <>
      {isLoaded ? (
        <Box sx={{}}>
          <Box>
            <Box sx={{ display: "flex", flexDirection: "row", gap: 2, justifyContent: "space-between" }}>
              <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center", p: 2 }}>
                <Typography variant="h6">Enhanced due diligence</Typography>
              </Box>
              <Box sx={{ p: 2 }}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    if (newRequest) {
                      setPreviewTemplate(false);
                      setPreviewedTemplate({});
                      setNewRequest(false);
                      return;
                    }
                    if (viewRequest) {
                      setActiveRequest("");
                      setViewRequest(false);
                      return;
                    }

                    setNewRequest(true);
                  }}
                >
                  {newRequest ? "Close" : viewRequest ? "Close" : "New request"}
                </Button>
              </Box>
            </Box>
            <Divider />

            {!newRequest && !viewRequest && (
              <Box>
                <ListRequests tableData={data} setActiveRequest={setActiveRequest} setViewRequest={setViewRequest} />
              </Box>
            )}

            {newRequest && (
              <Box sx={{}}>
                <Box sx={{}}>
                  {!selectedTemplate ? (
                    <Box sx={{}}>
                      {!previewTemplate ? (
                        <Box>
                          <Box sx={{ borderBottom: 1, borderColor: "divider", p: 2 }}>
                            <Typography variant="subtitle2">Select a template</Typography>
                          </Box>
                          <DataTable
                            columns={templateColumns}
                            data={templateOptions}
                            customStyles={customStyles}
                            striped
                            noDataComponent={<NoResults message={`There are no templates available`} />}
                          />
                        </Box>
                      ) : (
                        <Box>
                          <Box
                            sx={{
                              borderBottom: 1,
                              borderColor: "divider",
                              p: 2,
                              display: "flex",
                              flexDirection: "row",
                              justifyContent: "space-between",
                            }}
                          >
                            <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
                              <Typography variant="subtitle2">{previewedTemplate.template_data?.name}</Typography>
                            </Box>
                            <Box>
                              <Typography variant="h6">Preview</Typography>
                            </Box>
                          </Box>
                          <Box sx={{ p: 2, display: "flex", flexDirection: "column", gap: 2 }}>
                            {previewedTemplate.fields.length > 0 &&
                              previewedTemplate.fields.map((field, index) => (
                                <Box sx={{ border: 1, borderColor: "divider", display: "flex" }} key={index}>
                                  <Box
                                    sx={{
                                      p: 2,
                                      display: "flex",
                                      flexDirection: "column",
                                      justifyContent: "center",
                                      borderRight: 1,
                                      borderColor: "divider",
                                    }}
                                  >
                                    {field.order > 0 ? field.order : index + 1}
                                  </Box>
                                  <Box sx={{ p: 2, display: "flex", flexDirection: "column", justifyContent: "center" }}>
                                    <Typography sx={{ fontWeight: 500, fontSize: "13px" }}>{field.label}</Typography>
                                    <Typography sx={{ fontSize: "12px" }}>{fieldTypeLabel(field.field_type)}</Typography>
                                  </Box>
                                </Box>
                              ))}
                            <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "space-between", gap: 2 }}>
                              <Button variant="outlined" color="primary" onClick={() => setPreviewTemplate(false)}>
                                Change selection
                              </Button>
                              <Button
                                variant="contained"
                                color="success"
                                onClick={() => {
                                  setSelectedTemplate(previewedTemplate);
                                  setPreviewTemplate(false);
                                }}
                              >
                                Continue
                              </Button>
                            </Box>
                          </Box>
                        </Box>
                      )}
                    </Box>
                  ) : (
                    <Box>
                      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                        <Box sx={{ p: 2, display: "flex", justifyContent: "space-between" }}>
                          <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
                            <Typography variant="subtitle2">
                              {selectedTemplate.template_data.name ? selectedTemplate.template_data.name : "Custom form"}
                            </Typography>
                          </Box>
                          <Box sx={{ display: "flex", flexDirection: "row" }}>
                            <IconButton
                              title="Change selection"
                              onClick={() => {
                                setSelectedTemplate();
                              }}
                            >
                              <Edit />
                            </IconButton>

                            <IconButton
                              title="View fields"
                              onClick={() => {
                                setActiveTemplateView(true);
                              }}
                            >
                              <Visibility />
                            </IconButton>
                          </Box>
                        </Box>
                      </Box>

                      <Box sx={{ borderBottom: 1, borderColor: "divider", p: 2, backgroundColor: "#F8F8F8" }}>
                        <Typography variant="subtitle2" sx={{ fontSize: "13px" }}>
                          Add a note or additional instructions to include with the request
                        </Typography>
                      </Box>

                      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                        <ReactQuill
                          theme="snow"
                          value={instructions}
                          onChange={(e) => {
                            setInstructions(e.target.value);
                          }}
                        />
                      </Box>

                      <Box sx={{ borderBottom: 1, borderColor: "divider", p: 2, backgroundColor: "#F8F8F8" }}>
                        <Typography variant="subtitle2" sx={{ fontSize: "13px" }}>
                          Data completion and invitation options
                        </Typography>
                      </Box>

                      <Box sx={{ p: 2, display: "flex", flexDirection: "column", gap: 2 }}>
                        <ToggleButtonGroup
                          color="primary"
                          value={inviteType}
                          exclusive
                          onChange={(e, newValue) => setInviteType(newValue)}
                          aria-label="Invitation type"
                        >
                          <ToggleButton value={1}>Email invitation</ToggleButton>
                          <ToggleButton value={2}>Internal data entry</ToggleButton>
                        </ToggleButtonGroup>

                        <Collapse in={inviteType === 1} exit={inviteType !== 1}>
                          <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
                            <Grid container spacing={2}>
                              <Grid item xs={12}>
                                <TextField
                                  label="Email address"
                                  variant="outlined"
                                  fullWidth
                                  value={emailAddress}
                                  onChange={(e) => setEmailAddress(e.target.value)}
                                />
                              </Grid>
                            </Grid>
                          </Box>
                        </Collapse>

                        <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                          <LoadingButton
                            disableElevation
                            loading={processing}
                            variant="contained"
                            color="success"
                            onClick={(e) => {
                              handleSendRequest(e);
                            }}
                          >
                            Create request
                          </LoadingButton>
                        </Box>
                      </Box>
                    </Box>
                  )}
                </Box>
              </Box>
            )}

            {viewRequest && (
              <ViewRequest partnerUser={partnerUser} partnerData={partnerData} requestUUID={activeRequest} setRequestUUID={setActiveRequest} />
            )}
          </Box>

          <Drawer anchor="right" open={activeTemplateView} onClose={() => setActiveTemplateView(false)}>
            <Box sx={{ borderBottom: 1, borderColor: "divider", p: 2, display: "flex", justifyContent: "space-between" }}>
              <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
                <Typography variant="h6">{selectedTemplate.template_data?.name}</Typography>
              </Box>
              <Box>
                <IconButton
                  title="Close"
                  onClick={() => {
                    setActiveTemplateView(false);
                  }}
                >
                  <Close />
                </IconButton>
              </Box>
            </Box>

            <Box sx={{ width: "60rem", p: 2, display: "flex", flexDirection: "column", gap: 2 }}>
              {selectedTemplate.fields?.length > 0 &&
                selectedTemplate.fields.map((field, index) => (
                  <Box sx={{ border: 1, borderColor: "divider", display: "flex" }} key={index}>
                    <Box
                      sx={{
                        p: 2,
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        borderRight: 1,
                        borderColor: "divider",
                      }}
                    >
                      {field.order > 0 ? field.order : index + 1}
                    </Box>
                    <Box sx={{ p: 2, display: "flex", flexDirection: "column", justifyContent: "center" }}>
                      <Typography sx={{ fontWeight: 500, fontSize: "13px" }}>{field.label}</Typography>
                      <Typography sx={{ fontSize: "12px" }}>{fieldTypeLabel(field.field_type)}</Typography>
                    </Box>
                  </Box>
                ))}
            </Box>
          </Drawer>

          <Drawer anchor="right" open={viewContacts} onClose={() => setViewContacts(false)}>
            <Box sx={{ borderBottom: 1, borderColor: "divider", p: 2, display: "flex", justifyContent: "space-between" }}>
              <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
                <Typography variant="h6">Linked contacts</Typography>
              </Box>
              <Box>
                <IconButton
                  title="Close"
                  onClick={() => {
                    setViewContacts(false);
                  }}
                >
                  <Close />
                </IconButton>
              </Box>
            </Box>

            <Box sx={{ width: "40rem", display: "flex", flexDirection: "column" }}>
              {contacts.length > 0 ? (
                contacts.map((person, index) => (
                  <Box key={index} sx={{ borderBottom: 1, borderColor: "divider", p: 2, display: "flex", justifyContent: "space-between" }}>
                    <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
                      <Typography>
                        {person.first_name} {person.last_name} - {person.email_address}
                      </Typography>
                    </Box>
                    <Box>
                      <IconButton
                        title="Select contact"
                        onClick={() => {
                          setFirstName(person.first_name);
                          setLastName(person.last_name);
                          setEmailAddress(person.email_address);
                          setViewContacts(false);
                        }}
                      >
                        <ChevronRight />
                      </IconButton>
                    </Box>
                  </Box>
                ))
              ) : (
                <NoResults message={`There are no contacts linked to this entity`} />
              )}
            </Box>
          </Drawer>
        </Box>
      ) : (
        <Box>
          <Lottie speed={2.0} options={defaultOptions} height={200} width={200} />
        </Box>
      )}
    </>
  );
};

export default Enhanced;
