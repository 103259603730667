import { React, useEffect, useState, useCallback } from "react";

import authHeader from "../../../api/hostedAuthHeader";

import HandleError from "../../../components/functions/HostedHandleError";
import HandleToast from "../../../components/functions/HandleToast";

import Lottie from "react-lottie";
import loadingLottie from "../../../components/lotties/loading-dots.json";

import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import parse from "html-react-parser";
import DataTable from "react-data-table-component";
import { useDropzone } from "react-dropzone";
import "moment/dist/locale/en-gb";
import moment from "moment";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

import { Close, Delete, Edit, Add, PermMedia } from "@mui/icons-material";
import {
  Box,
  Divider,
  IconButton,
  Typography,
  Button,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  DialogContentText,
  Collapse,
  Chip,
  InputAdornment,
  ToggleButtonGroup,
  ToggleButton,
  FormControlLabel,
  RadioGroup,
  Radio,
  FormLabel,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";

import CountrySelect from "../../../components/form-elements/CountrySelect";
import NoResults from "../../../components/functions/NoResults";

const Fatca = ({ partner, caseUUID, entityData, caseData, activeView, getPerson, requestData, getRequestData, getCase }) => {
  const useAuthHeader = authHeader();
  const handleError = HandleError();
  const displayToast = HandleToast();

  const [locale, setLocale] = useState("en-gb");

  if (moment.locale() != locale) {
    moment.locale(locale);
  }

  const [isLoaded, setIsLoaded] = useState(false);
  const [processing, setProcessing] = useState(false);

  const [editMode, setEditMode] = useState(false);

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: loadingLottie,
  };

  const [fatcaEdit, setFatcaEdit] = useState(false);
  const [codesEdit, setCodesEdit] = useState(false);

  const [entityType, setEntityType] = useState(0);
  const [usPersonTin, setUsPersonTin] = useState("");

  const [fatcaDisplay, setFatcaDisplay] = useState();

  const [taxResidencyData, setTaxResidencyData] = useState([{ jurisdiction: "United Kingdom", tin: "123456789", uuid: "1234" }]);
  const [residencyCountry, setResidencyCountry] = useState();
  const [residencyTin, setResidencyTin] = useState("");

  const [residencyTinExemption, setResidencyTinExemption] = useState(0);
  const [residencyTinExemptionReason, setResidencyTinExemptionReason] = useState("");

  const [deleteTaxResidency, setDeleteTaxResidency] = useState("");
  const [deleteTaxResidencyCountry, setDeleteTaxResidencyCountry] = useState("");
  const [deleteTaxResidencyDialog, setDeleteTaxResidencyDialog] = useState(false);

  const [file, setFile] = useState();

  const onDrop = useCallback((acceptedFiles) => {
    acceptedFiles.forEach((file) => {
      setFile(file);
    });
  }, []);

  const { acceptedFiles, getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: {
      "image/png": [".png"],
      "image/jpeg": [".jpg", ".jpeg"],
      "application/pdf": [".pdf"],
    },
    maxFiles: 1,
  });

  const getFatcaDeclaration = async () => {
    try {
      const response = await partner.post(
        `/v1/entity/people/person/data/fatca`,
        JSON.stringify({
          person_uuid: entityData.value.person_uuid,
        }),
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Basic " + useAuthHeader.base64encodedData,
            service: "hosted",
          },

          //withCredentials: true,
        }
      );

      if (response.data.complete) {
        setEntityType(response.data.entity_type);
        setUsPersonTin(response.data.tin);
      } else {
        setEditMode(true);
      }

      setFatcaDisplay(response.data);
      setIsLoaded(true);
    } catch (err) {
      console.log(err);
      handleError(err);
    }
  };

  const handleSaveFatcaDeclaration = async (e) => {
    e.preventDefault();
    setProcessing(true);

    const formData = new FormData();
    formData.append("file", file);
    formData.append("fileName", file ? file.name : "");

    formData.append("entity_type", entityType);
    formData.append("person_uuid", entityData.value.person_uuid);
    formData.append("tin", usPersonTin);

    try {
      const response = await partner.post("/v1/entity/people/person/tax/fatca", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: "Basic " + useAuthHeader.base64encodedData,
          service: "hosted",
        },
        onUploadProgress: function (progressEvent) {},

        //withCredentials: true,
      });

      getFatcaDeclaration();
      getRequestData();
      getCase();

      displayToast("FATCA declaration saved successfully", () => {
        setProcessing(false);
        setFatcaEdit(false);
        setEditMode(false);
      });
    } catch (err) {
      handleError(err);
      setProcessing(false);
    }
  };

  useEffect(() => {
    getFatcaDeclaration();
  }, []);

  return isLoaded ? (
    <Box>
      <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "space-between", borderBottom: 1, borderColor: "divider" }}>
        <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center", p: 2 }}>
          <Typography variant="subtitle2">FATCA Declaration</Typography>
        </Box>
        <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center", p: 2 }}>
          {fatcaDisplay?.complete && (
            <IconButton
              title="Edit"
              onClick={() => {
                setEditMode(!editMode);
              }}
            >
              {editMode ? <Close /> : <Edit />}
            </IconButton>
          )}
        </Box>
      </Box>

      {!editMode ? (
        <Box>
          {isLoaded ? (
            <Box>
              <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
                  {fatcaDisplay.entity_type == 1 && (
                    <Typography variant="subtitle2" sx={{ p: 2 }}>
                      US Citizen or Tax Resident
                    </Typography>
                  )}

                  {fatcaDisplay.entity_type == 2 && (
                    <Typography variant="subtitle2" sx={{ p: 2 }}>
                      Former US Citizen
                    </Typography>
                  )}

                  {fatcaDisplay.entity_type == 3 && (
                    <Typography variant="subtitle2" sx={{ p: 2 }}>
                      Non US Citizen
                    </Typography>
                  )}
                </Box>
              </Box>

              {fatcaDisplay.entity_type == 1 && (
                <Box sx={{ borderTop: 1, borderColor: "divider", display: "flex", justifyContent: "space-between" }}>
                  <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
                    <Typography variant="subtitle" sx={{ fontSize: "13px", p: 2 }}>
                      TIN
                    </Typography>
                  </Box>
                  <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
                    <Typography variant="subtitle2" sx={{ fontSize: "13px", p: 2 }}>
                      {fatcaDisplay.tin}
                    </Typography>
                  </Box>
                </Box>
              )}
            </Box>
          ) : (
            <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", height: "" }}>
              <Lottie speed={2.0} options={defaultOptions} height={100} width={200} />
            </Box>
          )}
        </Box>
      ) : (
        <Box sx={{}}>
          <Box sx={{ display: "flex", flexDirection: "column" }}>
            <Box>
              <Box sx={{ p: 2 }}>
                <Typography variant="subtitle" sx={{ fontSize: "13px" }}>
                  FATCA is a US law that requires financial institutions to identify their US customers and report information about financial
                  accounts held by US persons either directly or through certain foreign entities. FATCA requires financial institutions to report on
                  the foreign assets held by their US account holders.
                </Typography>
              </Box>
              <Divider />
              <Box sx={{ p: 2 }}>
                <FormControl>
                  <RadioGroup
                    aria-labelledby="fatca-type-options-label"
                    defaultValue={0}
                    value={entityType}
                    onChange={(e) => setEntityType(e.target.value)}
                    name="radio-buttons-group"
                  >
                    <FormControlLabel
                      sx={{ mb: 1 }}
                      value={1}
                      control={<Radio />}
                      label="I am a US citizen and/or resident in the US for tax purposes"
                    />
                    <FormControlLabel
                      sx={{ mb: 1 }}
                      value={2}
                      control={<Radio />}
                      label="I was born in the US (or a US territory) but I am no longer a US citizen as I have voluntarily surrendered my citizenship and have a Certificate of Loss of Nationality of the United States"
                    />
                    <FormControlLabel
                      sx={{ mb: 1 }}
                      value={3}
                      control={<Radio />}
                      label="I am not a US citizen or resident in the US for tax purposes"
                    />
                  </RadioGroup>
                </FormControl>
              </Box>
            </Box>

            <Collapse in={entityType == 1} exit={entityType != 1}>
              <Box>
                <Box sx={{ borderBottom: 1, borderTop: 1, borderColor: "divider" }}>
                  <Box sx={{ p: 2 }}>
                    <Typography variant="h6">US Person or tax resident</Typography>
                  </Box>
                </Box>

                <Box sx={{ p: 2, display: "flex", flexDirection: "column", gap: 1 }}>
                  <Typography variant="subtitle2">Provide the Tax identification number (TIN)</Typography>
                  <TextField fullWidth label="TIN" variant="outlined" value={usPersonTin} onChange={(e) => setUsPersonTin(e.target.value)} />
                </Box>
              </Box>
            </Collapse>

            <Collapse in={entityType == 2} exit={entityType != 2}>
              <Box>
                <Box sx={{ borderBottom: 1, borderTop: 1, borderColor: "divider" }}>
                  <Box sx={{ p: 2 }}>
                    <Typography variant="h6">Former US citizen</Typography>
                    <Typography variant="subtitle" sx={{ fontSize: "13px" }}>
                      Please upload the Certificate of Loss of Nationality of the United States.
                    </Typography>
                  </Box>
                </Box>

                <Box sx={{ p: 2 }}>
                  <Box
                    className="file-drop"
                    sx={{
                      border: 2,
                      borderColor: "divider",
                      borderStyle: "dashed",
                      cursor: "pointer",
                      p: 5,
                      my: 2,

                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                    }}
                    {...getRootProps()}
                  >
                    <input {...getInputProps()} />
                    {isDragActive ? (
                      <Typography align="center">Drop the file here ...</Typography>
                    ) : (
                      <Typography align="center">Drop a file here, or click to select files</Typography>
                    )}
                    <Typography align="center" variant="caption">
                      (Only pdf, jpeg or png, files will be accepted)
                    </Typography>
                  </Box>

                  {file && (
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        gap: 2,

                        border: 1,
                        borderColor: "divider",
                        justifyContent: "space-between",
                      }}
                    >
                      <Box sx={{ display: "flex", flexDirection: "row", width: "100%", justifyContent: "space-between" }}>
                        <Box sx={{ display: "flex" }}>
                          <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
                            <Box sx={{ display: "flex", flexDirection: "row", gap: 2, fontWeight: 600 }}>
                              <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center", pl: 2 }}>
                                <PermMedia />
                              </Box>
                              <Box sx={{ borderRight: 1, borderLeft: 1, borderColor: "divider", p: 1 }}>
                                <Typography variant=""> "Filename"</Typography>
                              </Box>
                            </Box>
                          </Box>

                          <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center", fontSize: 13, pl: 2 }}>{file.path}</Box>
                        </Box>

                        <Box sx={{ pr: 1 }}>
                          <IconButton
                            title="Remove file"
                            onClick={() => {
                              setFile(null);
                            }}
                          >
                            <Delete />
                          </IconButton>
                        </Box>
                      </Box>
                    </Box>
                  )}
                </Box>
              </Box>
            </Collapse>

            <Divider />

            <Box sx={{ p: 2, display: "flex", flexDirection: "row", justifyContent: "flex-end" }}>
              <LoadingButton
                disableElevation
                variant="contained"
                color="success"
                onClick={(e) => {
                  handleSaveFatcaDeclaration(e);
                }}
                loading={processing}
              >
                Save declaration
              </LoadingButton>
            </Box>
          </Box>
        </Box>
      )}
    </Box>
  ) : (
    <Box>
      <Lottie speed={2.0} options={defaultOptions} height={200} width={200} />
    </Box>
  );
};

export default Fatca;
