import React, { useState, useEffect } from "react";

import partner from "../../../../api/partner";
import authHeader from "../../../../api/authHeader";

import HandleError from "../../../../components/functions/HandleError";
import HandleToast from "../../../../components/functions/HandleToast";

import Lottie from "react-lottie";
import loadingLottie from "../../../../components/lotties/loading-dots.json";

import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { signal } from "@preact/signals-react";
import moment from "moment";
import parse from "html-react-parser";

import {
  Box,
  Typography,
  Chip,
  Grid,
  Icon,
  IconButton,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Divider,
  Autocomplete,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  Collapse,
  InputAdornment,
  ToggleButtonGroup,
  ToggleButton,
  FormControlLabel,
  RadioGroup,
  Radio,
  FormLabel,
  Fade,
} from "@mui/material";
import {
  Edit,
  Close,
  Delete,
  Visibility,
  PermMedia,
  KeyboardArrowDown,
  KeyboardArrowUp,
  Check,
  HorizontalRule,
  AutoFixHigh,
} from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";

const Decision = ({ caseData, entityData, getCase }) => {
  const useAuthHeader = authHeader();
  const handleError = HandleError();
  const displayToast = HandleToast();
  const [isLoaded, setIsLoaded] = useState(false);
  const [processing, setProcessing] = useState(false);

  const [assessmentData, setAssessmentData] = useState(null);

  const [visibleFormula, setVisibleFormula] = useState(false);

  const [resetDialog, setResetDialog] = useState(false);

  const [approvalDialog, setApprovalDialog] = useState(false);
  const [decisionNote, setDecisionNote] = useState("");

  const getAssessmentData = async () => {
    try {
      const response = await partner.post(
        `/v1/screening/case/kyc/risk-assessment/data`,
        JSON.stringify({
          person_uuid: entityData.value.person_uuid,
        }),
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Basic " + useAuthHeader.base64encodedData,
            service: "partners",
          },

          //withCredentials: true,
        }
      );

      setAssessmentData(response.data);

      setIsLoaded(true);
    } catch (err) {
      console.log(err);
      handleError(err);
    }
  };

  const handleResetRiskAssessment = async (e) => {
    e.preventDefault();
    setProcessing(true);

    try {
      const response = await partner.post(
        `/v1/screening/case/kyc/risk-assessment/reset`,
        JSON.stringify({
          case_uuid: caseData.value.case_uuid,
        }),
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Basic " + useAuthHeader.base64encodedData,
            service: "partners",
          },

          //withCredentials: true,
        }
      );

      getCase();

      setResetDialog(false);

      displayToast("Risk assessment reset successfully", () => {
        setProcessing(false);
      });
    } catch (err) {
      console.log(err);
      setResetDialog(false);
      handleError(err);
      setProcessing(false);
    }
  };

  const handleApproveRiskAssessment = async (e) => {
    e.preventDefault();
    setProcessing(true);

    try {
      const response = await partner.post(
        `/v1/screening/case/kyc/risk-assessment/approve`,
        JSON.stringify({
          case_uuid: caseData.value.case_uuid,
          note: decisionNote,
        }),
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Basic " + useAuthHeader.base64encodedData,
            service: "partners",
          },

          //withCredentials: true,
        }
      );

      getCase();
      getAssessmentData();
      setApprovalDialog(false);

      displayToast("Risk assessment approved", () => {
        setProcessing(false);
      });
    } catch (err) {
      console.log(err);
      setApprovalDialog(false);
      handleError(err);
      setProcessing(false);
    }
  };

  const handleDeclineRiskAssessment = async (e) => {
    e.preventDefault();
    setProcessing(true);

    try {
      const response = await partner.post(
        `/v1/screening/case/kyc/risk-assessment/decline`,
        JSON.stringify({
          case_uuid: caseData.value.case_uuid,
          note: decisionNote,
        }),
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Basic " + useAuthHeader.base64encodedData,
            service: "partners",
          },

          //withCredentials: true,
        }
      );

      getCase();
      getAssessmentData();
      setApprovalDialog(false);

      displayToast("Risk assessment declined", () => {
        setProcessing(false);
      });
    } catch (err) {
      console.log(err);
      setApprovalDialog(false);
      handleError(err);
      setProcessing(false);
    }
  };

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: loadingLottie,
  };

  useEffect(() => {
    getAssessmentData();
  }, []);

  return (
    <Box sx={{ border: 1, borderColor: "divider" }}>
      <Box sx={{ borderBottom: 1, borderColor: "divider", px: 2, py: 1 }}>
        <Typography variant="h6">Risk Assessment & Decision</Typography>
      </Box>

      {isLoaded ? (
        <Box>
          <Box sx={{ px: 2, py: 1, display: "flex", justifyContent: "space-between", borderBottom: 1, borderColor: "divider" }}>
            <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
              <Typography variant="subtitle" sx={{ fontSize: "13px" }}>
                Calculated Score
              </Typography>
            </Box>
            <Box sx={{ display: "flex", gap: 2 }}>
              <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
                <Chip
                  label={assessmentData.risk_category_label}
                  className="square-chip"
                  sx={{
                    backgroundColor: assessmentData.risk_category == 1 ? "#8BBF9F" : assessmentData.risk_category == 2 ? "#FCCA46" : "#C83E4D",
                    color: assessmentData.risk_category == 1 ? "#0b0b0b" : assessmentData.risk_category == 2 ? "#0b0b0b" : "#ffffff",
                  }}
                />
              </Box>
              <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
                <Typography variant="h6">{assessmentData.total_score}</Typography>
              </Box>
            </Box>
          </Box>

          <Box sx={{ px: 2, py: 1, display: "flex", justifyContent: "space-between", borderBottom: 1, borderColor: "divider" }}>
            <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
              <Typography variant="subtitle" sx={{ fontSize: "13px" }}>
                Assessment note
              </Typography>
            </Box>
            <Box sx={{ fontSize: "0.875rem", fontWeight: 500, maxWidth: "75%", textAlign: "end" }}>{parse(assessmentData.content)}</Box>
          </Box>

          <Box
            sx={{
              px: 2,
              py: 1,
              display: "flex",
              justifyContent: "space-between",
              borderBottom: visibleFormula ? 1 : 0,
              borderColor: "divider",
              backgroundColor: "#0b0b0b",
              color: "#ffffff",
            }}
          >
            <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
              <Typography variant="subtitle2">Calculation formula</Typography>
            </Box>
            <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
              <IconButton
                onClick={() => {
                  setVisibleFormula(!visibleFormula);
                }}
                sx={{ color: "#ffffff" }}
              >
                {visibleFormula ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
              </IconButton>
            </Box>
          </Box>
          <Collapse in={visibleFormula} exit={!visibleFormula}>
            <Box sx={{ p: 0 }}>
              {assessmentData.questions.map((question, index) => (
                <Box
                  key={index}
                  sx={{ display: "flex", flexDirection: "row", justifyContent: "space-between", borderBottom: 1, borderColor: "divider", p: 2 }}
                >
                  <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
                    <Typography variant="subtitle" sx={{ fontSize: "13px" }}>
                      {question.question_text}
                    </Typography>
                  </Box>

                  <Box sx={{ display: "flex", flexDirection: "row", gap: 2 }}>
                    {question.auto_calculated && (
                      <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center" }} title="Automatically calculated">
                        <AutoFixHigh />
                      </Box>
                    )}
                    <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
                      <Box sx={{ textAlign: "right", color: question.value ? "#C83E4D" : "#0b0b0b" }}>
                        {question.value ? <Check /> : <HorizontalRule />}
                      </Box>
                      <Typography variant="subtitle2">Points: {question.points}</Typography>
                    </Box>
                  </Box>
                </Box>
              ))}
              {assessmentData.manual_adjustment && (
                <Box>
                  <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "space-between", borderBottom: 1, borderColor: "divider", p: 2 }}>
                    <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
                      <Typography variant="subtitle" sx={{ fontSize: "13px" }}>
                        Manual adjustment
                      </Typography>
                    </Box>
                    <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
                      <Typography variant="subtitle2">{assessmentData.manual_adjustment_score} points</Typography>
                    </Box>
                  </Box>
                  <Box sx={{ p: 2, borderBottom: 1, borderColor: "divider", fontSize: "13px" }}>
                    <Typography variant="subtitle2">Reason for the manual adjustment</Typography>
                    {parse(assessmentData.manual_adjustment_reason)}
                  </Box>
                </Box>
              )}
            </Box>
          </Collapse>

          <Box sx={{ px: 2, py: 1, display: "flex", justifyContent: "space-between", borderBottom: 1, borderColor: "divider" }}>
            <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
              <Typography variant="subtitle" sx={{ fontSize: "13px" }}>
                Completed by
              </Typography>
            </Box>
            <Box sx={{}}>
              <Typography variant="subtitle2">{assessmentData.actor_name}</Typography>
            </Box>
          </Box>
          <Box sx={{ px: 2, py: 1, display: "flex", justifyContent: "space-between", borderBottom: 1, borderColor: "divider" }}>
            <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
              <Typography variant="subtitle" sx={{ fontSize: "13px" }}>
                Completed time
              </Typography>
            </Box>
            <Box sx={{}}>
              <Typography variant="subtitle2">{moment.unix(assessmentData.timestamp).format("DD/MM/YYYY HH:mm")}</Typography>
            </Box>
          </Box>
          <Box sx={{ px: 2, py: 1, display: "flex", justifyContent: "space-between", borderBottom: 1, borderColor: "divider" }}>
            <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
              <Typography variant="subtitle" sx={{ fontSize: "13px" }}>
                Approvals required
              </Typography>
            </Box>
            <Box sx={{}}>
              <Typography variant="subtitle2">{assessmentData.approvals_required}</Typography>
            </Box>
          </Box>

          {assessmentData.approvals?.length > 0 && (
            <>
              <Box
                sx={{
                  px: 2,
                  py: 1,
                  display: "flex",
                  justifyContent: "space-between",
                  borderBottom: 1,
                  borderTop: 1,
                  borderColor: "divider",
                  backgroundColor: "#0b0b0b",
                  color: "#ffffff",
                }}
              >
                <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
                  <Typography variant="subtitle2">Approvals</Typography>
                </Box>
                <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center" }}></Box>
              </Box>
              <Box sx={{ display: "flex", flexDirection: "column" }}>
                {assessmentData.approvals.map((approval, index) => (
                  <Box
                    key={index}
                    sx={{ px: 2, py: 1, display: "flex", justifyContent: "space-between", borderTop: index == 0 ? 0 : 1, borderColor: "divider" }}
                  >
                    <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
                      <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
                        <Typography variant="subtitle" sx={{ fontSize: "13px" }}>
                          {approval.actor}
                        </Typography>
                        <Typography variant="subtitle" sx={{ fontSize: "13px" }}>
                          {moment.unix(approval.timestamp).format("DD/MM/YYYY HH:mm")}
                        </Typography>
                      </Box>
                    </Box>
                    <Box sx={{ display: "flex", flexDirection: "column", gap: 1, width: "75%" }}>
                      <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "flex-end" }}>
                        <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                          {approval.approved ? (
                            <Chip
                              className="square-chip"
                              size="small"
                              label="Approved"
                              sx={{ backgroundColor: "#8BBF9F", color: "#0b0b0b", textTransform: "uppercase" }}
                            />
                          ) : (
                            <Chip
                              className="square-chip"
                              size="small"
                              label="Declined"
                              sx={{ backgroundColor: "#C83E4D", color: "#ffffff", textTransform: "uppercase" }}
                            />
                          )}
                        </Box>
                      </Box>
                      <Box sx={{ textAlign: "end" }}>
                        <Box>{parse(approval.note)}</Box>
                      </Box>
                    </Box>
                  </Box>
                ))}
              </Box>
            </>
          )}

          {caseData.value.status == 6 && (
            <>
              <Box
                sx={{
                  px: 2,
                  py: 1,
                  display: "flex",
                  justifyContent: "space-between",
                  borderBottom: 1,
                  borderTop: 1,
                  borderColor: "divider",
                  backgroundColor: "#0b0b0b",
                  color: "#ffffff",
                }}
              >
                <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
                  <Typography variant="subtitle2">Assessment options</Typography>
                </Box>
                <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center" }}></Box>
              </Box>
              <Box sx={{ p: 2, display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                <Button disableElevation variant="outlined" color="danger" onClick={() => setResetDialog(true)}>
                  Reset assessment
                </Button>
                <Button disableElevation variant="contained" color="success" onClick={() => setApprovalDialog(true)}>
                  Approve / Decline
                </Button>
              </Box>
            </>
          )}

          <Dialog
            open={resetDialog}
            onClose={() => setResetDialog(false)}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">Are you sure?</DialogTitle>
            <DialogContent>
              <Box>
                <DialogContentText sx={{ mb: 1 }} id="alert-dialog-description">
                  Are you sure you want to reset the risk assessment?
                </DialogContentText>
              </Box>
            </DialogContent>
            <DialogActions>
              <LoadingButton disableElevation loading={processing} onClick={() => setResetDialog(false)} color="primary">
                Cancel
              </LoadingButton>

              <LoadingButton
                sx={{ color: "#ffffff" }}
                loading={processing}
                onClick={(e) => {
                  handleResetRiskAssessment(e);
                }}
                variant="contained"
                color="danger"
                disableElevation
                autoFocus
              >
                Confirm
              </LoadingButton>
            </DialogActions>
          </Dialog>

          <Dialog
            open={approvalDialog}
            onClose={() => setApprovalDialog(false)}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            maxWidth="lg"
          >
            <DialogTitle id="alert-dialog-title">Approve or decline the client</DialogTitle>
            <DialogContent>
              <Box>
                <DialogContentText sx={{ mb: 1, fontWeight: 500, fontSize: "13px" }} id="alert-dialog-description">
                  Provide the reason for your decision
                </DialogContentText>
                <Box sx={{ border: 1, borderColor: "divider" }}>
                  <ReactQuill theme="snow" value={decisionNote} onChange={setDecisionNote} style={{ minHeight: "300px", minWidth: "600px" }} />
                </Box>
              </Box>
            </DialogContent>
            <DialogActions>
              <LoadingButton variant="outlined" disableElevation loading={processing} onClick={(e) => handleDeclineRiskAssessment(e)} color="danger">
                Decline
              </LoadingButton>

              <LoadingButton
                loading={processing}
                onClick={(e) => {
                  handleApproveRiskAssessment(e);
                }}
                variant="contained"
                color="success"
                disableElevation
              >
                Approve
              </LoadingButton>
            </DialogActions>
          </Dialog>
        </Box>
      ) : (
        <Box>
          <Lottie speed={2.0} options={defaultOptions} height={200} width={200} />
        </Box>
      )}
    </Box>
  );
};

export default Decision;
