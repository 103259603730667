import { React, useState } from "react";

import "moment/dist/locale/en-gb";
import moment from "moment";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

import { Box, Typography, Grid, TextField, Select, FormControl, FormControlLabel, InputLabel, MenuItem } from "@mui/material";
import { MuiTelInput } from "mui-tel-input";

function Notary({
  firstName,
  setFirstName,
  middleNames,
  setMiddleNames,
  lastName,
  setLastName,
  emailAddress,
  setEmailAddress,
  phoneValue,
  setPhoneValue,
  setTelephoneNumber,
  setPhoneCountry,
  setDiallingCode,
  signatureDate,
  setSignatureDate,
}) {
  const [locale, setLocale] = useState("en-gb");

  if (moment.locale() != locale) {
    moment.locale(locale);
  }

  return (
    <Box sx={{ border: 1, borderColor: "divider" }}>
      <Box sx={{ borderBottom: 1, borderColor: "divider", p: 1, backgroundColor: "#0b0b0b", color: "#ffffff" }}>
        <Typography variant="subtitle2">Notary information</Typography>
      </Box>
      <Box sx={{ p: 2 }}>
        <Typography variant="body2">What is the notaries name?</Typography>
        <Grid sx={{ pt: 2 }} container spacing={2}>
          <Grid item xs={12} sm={12} md={12} lg={4}>
            <TextField
              fullWidth
              id="outlined-basic"
              label="First name"
              variant="outlined"
              value={firstName}
              onChange={(e) => setFirstName(e.target.value)}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={3} xl={4}>
            <TextField
              fullWidth
              id="outlined-basic"
              label="Middle name(s)"
              variant="outlined"
              value={middleNames}
              onChange={(e) => setMiddleNames(e.target.value)}
              helperText="If applicable, separate with spaces"
            />
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={3} xl={4}>
            <TextField
              fullWidth
              id="outlined-basic"
              label="Last name"
              variant="outlined"
              value={lastName}
              onChange={(e) => setLastName(e.target.value)}
            />
          </Grid>
        </Grid>

        <Box sx={{ mt: 2, display: "flex", flexDirection: "column", gap: 2 }}>
          <Typography variant="body2">What is the notaries email address?</Typography>
          <TextField
            fullWidth
            value={emailAddress}
            onChange={(e) => setEmailAddress(e.target.value)}
            id="outlined-basic"
            label="Email address"
            variant="outlined"
          />
        </Box>

        <Box sx={{ mt: 2, display: "flex", flexDirection: "column", gap: 2 }}>
          <Typography variant="body2">What is the notaries telephone number?</Typography>

          <MuiTelInput
            fullWidth
            defaultCountry="IM"
            value={phoneValue}
            onChange={(phone, meta) => {
              setTelephoneNumber(meta.nationalNumber);
              setPhoneCountry(meta.countryCode);
              setDiallingCode(meta.countryCallingCode);
              setPhoneValue(phone);
            }}
          />
        </Box>

        <Box sx={{ mt: 2, display: "flex", flexDirection: "column", gap: 2 }}>
          <Typography variant="body2">When was the document notarised?</Typography>
          <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale={locale}>
            <DatePicker
              fullWidth
              openTo="year"
              label={`Signature date`}
              value={signatureDate ? signatureDate : null}
              onChange={(date) => {
                setSignatureDate(date);
              }}
              slotProps={{ textField: { fullWidth: true } }}
              maxDate={moment()}
            />
          </LocalizationProvider>
        </Box>
      </Box>
    </Box>
  );
}

export default Notary;
