import { React, useState } from "react";

import partner from "../../../../api/partner";
import authHeader from "../../../../api/authHeader";

import HandleError from "../../../../components/functions/HandleError";
import HandleToast from "../../../../components/functions/HandleToast";

import {
  Box,
  Typography,
  Chip,
  Grid,
  Icon,
  IconButton,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Divider,
  Autocomplete,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  Collapse,
  InputAdornment,
  ToggleButtonGroup,
  ToggleButton,
  FormControlLabel,
  RadioGroup,
  Radio,
  FormLabel,
} from "@mui/material";
import { Edit, Close, Delete, Visibility, PermMedia, AutoFixHigh } from "@mui/icons-material";

function Question({ questionUUID, question, answers, index }) {
  const useAuthHeader = authHeader();
  const handleError = HandleError();
  const displayToast = HandleToast();

  const [isChecked, setIsChecked] = useState(
    question.auto_calculate ? (question.answers?.yes ? true : question.answers?.high_risk ? true : false) : null
  );

  const [pointsOutput, setPointsOutput] = useState(
    question.auto_calculate
      ? question.answers?.yes
        ? question.answers?.points
        : question.answers?.high_risk
        ? question.answers?.points
        : question.answers?.points
      : 0
  );

  const getQuestionScore = async (questionUUID, newState) => {
    try {
      const response = await partner.post(
        `/v1/screening/risk-assessment/questions`,
        JSON.stringify({
          question_uuid: questionUUID,
        }),
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Basic " + useAuthHeader.base64encodedData,
            service: "partners",
          },

          //withCredentials: true,
        }
      );

      switch (response.data.answer_type) {
        case 1:
          // yes/no

          if (newState) {
            setPointsOutput(response.data.yes_score);
            answers.value[index].points = response.data.yes_score;
          } else {
            setPointsOutput(response.data.no_score);
            answers.value[index].points = response.data.no_score;
          }

          break;

        case 4:
          // country

          if (newState) {
            setPointsOutput(response.data.high_risk_score);
            answers.value[index].points = response.data.high_risk_score;
          } else {
            setPointsOutput(0);
            answers.value[index].points = 0;
          }
          break;

        default:
      }
    } catch (err) {
      handleError(err);
    }
  };

  return (
    <Box
      sx={{
        borderTop: index > 0 ? 1 : 0,
        borderColor: "divider",
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        p: 2,
      }}
      key={index}
    >
      <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
        <Typography variant="subtitle2">{question.question}</Typography>
      </Box>
      <Box sx={{ display: "flex", flexDirection: "row", gap: 2 }}>
        {question.auto_calculate && (
          <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center" }} title="Automatically calculated">
            <AutoFixHigh />
          </Box>
        )}
        <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
          <ToggleButtonGroup
            id={questionUUID}
            value={isChecked}
            color={isChecked ? "success" : "error"}
            exclusive
            onChange={(event, value) => {
              if (value !== null) {
                setIsChecked(value);
                getQuestionScore(questionUUID, value, index);
                answers.value[index].answer = value;
              }
            }}
            aria-label={question.question}
          >
            <ToggleButton value={true} aria-label="left aligned">
              Yes
            </ToggleButton>
            <ToggleButton value={false} aria-label="centered">
              No
            </ToggleButton>
          </ToggleButtonGroup>
          <Typography variant="caption">Points: {pointsOutput}</Typography>
        </Box>
      </Box>
    </Box>
  );
}

export default Question;
