import { React, useState, useEffect } from "react";

import authHeader from "../../../../../api/authHeader";

import HandleToast from "../../../../../components/functions/HandleToast";
import ErrorHandling from "../../../../../components/functions/HandleError";

import Lottie from "react-lottie";
import loadingLottie from "../../../../../components/lotties/loading-dots.json";

import { QRCode } from "react-qrcode-logo";

import { Container, Typography, Grid, TextField, Box } from "@mui/material";
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Alert } from "@mui/material";
import { LoadingButton } from "@mui/lab";

const Authentication = ({ partner, partnerUser, partnerData }) => {
  const useAuthHeader = authHeader();
  const handleError = ErrorHandling();
  const displayToast = HandleToast();

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: loadingLottie,
  };

  const [isLoaded, setIsLoaded] = useState(false);
  const [processing, setProcessing] = useState(false);
  const [confirmDisableDialog, setConfirmDisableDialog] = useState(false);
  const [displaySuccess, setDisplaySuccess] = useState(false);

  const [isEnabled, setIsEnabled] = useState(false);
  const [secret, setSecret] = useState("");
  const [qrCode, setQrCode] = useState("");

  const [code1, setCode1] = useState("");
  const [code2, setCode2] = useState("");
  const [code3, setCode3] = useState("");
  const [code4, setCode4] = useState("");
  const [code5, setCode5] = useState("");
  const [code6, setCode6] = useState("");

  const handleSaveAuthenticationSettings = async (e) => {
    e.preventDefault();
    setProcessing(true);

    try {
      const response = await partner.post(
        `/v1/users/self/settings/authentication`,
        JSON.stringify({
          partner_user_uuid: partnerUser.value.uuid,
          code: code1 + code2 + code3 + code4 + code5 + code6,
        }),
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Basic " + useAuthHeader.base64encodedData,
            service: "partners",
          },

          //withCredentials: true,
        }
      );

      getAuthenticationData();
      setDisplaySuccess(true);

      setTimeout(() => {
        setCode1("");
        setCode2("");
        setCode3("");
        setCode4("");
        setCode5("");
        setCode6("");
        setConfirmDisableDialog(false);
        setDisplaySuccess(false);
        displayToast(`Authentication settings updated successfully`, () => {
          setProcessing(false);
        });
      }, 2000);
    } catch (err) {
      handleError(err);
      setCode1("");
      setCode2("");
      setCode3("");
      setCode4("");
      setCode5("");
      setCode6("");
      setProcessing(false);
    }
  };

  const getAuthenticationData = async () => {
    try {
      const response = await partner.post(
        `/v1/users/self/data/authentication`,
        JSON.stringify({
          partner_user_uuid: partnerUser.value.uuid,
        }),
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Basic " + useAuthHeader.base64encodedData,
            service: "partners",
          },

          //withCredentials: true,
        }
      );

      setIsEnabled(response.data.enabled);
      setSecret(response.data.secret);

      if (!response.data.enabled) {
        setQrCode(
          `otpauth://totp/Cleverli:${partnerUser.value.email_address}?secret=${response.data.secret}&issuer=Cleverli&label=Cleverli:${partnerUser.value.email_address}`
        );
      } else {
        setQrCode(`invalid`);
      }

      setIsLoaded(true);
    } catch (err) {
      handleError(err);
    }
  };

  useEffect(() => {
    getAuthenticationData();
  }, []);

  return (
    <>
      {isLoaded ? (
        <>
          <Box sx={{ borderTop: 1, borderColor: "divider" }}>
            <Box sx={{ borderRight: { xs: 0, lg: 1 }, borderColor: { xs: "divider", lg: "divider" }, width: { xs: "100%", lg: "75%", xl: "50%" } }}>
              {isEnabled ? (
                <Box sx={{ p: 2 }}>
                  <Alert sx={{ mb: 3 }} variant="filled" severity="success">
                    Authentication is enabled
                  </Alert>

                  <Typography variant="body">
                    Disabling authentication is not recommended. If you disable it, your account will not be as secure.
                  </Typography>
                </Box>
              ) : (
                <Box sx={{ p: 2 }}>
                  <Typography variant="body" gutterBottom>
                    Scan the QR code using any authentication application on your phone (e.g. Google Authenticator, Duo Mobile, Authy) or enter the
                    following code:
                  </Typography>

                  <Typography align="center" sx={{ my: 3 }} variant="h6" gutterBottom>
                    {secret}
                  </Typography>

                  <Box sx={{ mb: 2, display: "flex", justifyContent: "center" }}>
                    <QRCode value={qrCode} qrStyle="dots" fgColor="#1C2321" size={200} ecLevel="L" />
                  </Box>
                </Box>
              )}

              <Box sx={{ p: 2, display: "flex", flexDirection: "column", gap: 2 }}>
                <Typography sx={{ m: 0 }} variant="body">
                  Enter the 6 digit confirmation code shown on the app:
                </Typography>

                <Grid container spacing={2} sx={{ mt: -2 }}>
                  <Grid item xs={2}>
                    <TextField
                      id="input1"
                      type="tel"
                      inputProps={{ min: 0, maxLength: 1, pattern: "[0-9]*", style: { textAlign: "center", fontSize: 32 } }}
                      fullWidth
                      autoFocus
                      variant="outlined"
                      value={code1}
                      onChange={(e) => setCode1(e.target.value)}
                      onKeyUp={(e) => {
                        if (e.target.value.length === 1) {
                          document.getElementById("input2").focus();
                        }
                      }}
                    />
                  </Grid>
                  <Grid item xs={2}>
                    <TextField
                      id="input2"
                      inputProps={{ min: 0, maxLength: 1, pattern: "[0-9]*", style: { textAlign: "center", fontSize: 32 } }}
                      fullWidth
                      variant="outlined"
                      value={code2}
                      onChange={(e) => setCode2(e.target.value)}
                      onKeyUp={(e) => {
                        if (e.target.value.length === 1) {
                          document.getElementById("input3").focus();
                        } else if (e.key === "Backspace") {
                          document.getElementById("input1").focus();
                        }
                      }}
                    />
                  </Grid>
                  <Grid item xs={2}>
                    <TextField
                      id="input3"
                      inputProps={{ min: 0, maxLength: 1, pattern: "[0-9]*", style: { textAlign: "center", fontSize: 32 } }}
                      fullWidth
                      variant="outlined"
                      value={code3}
                      onChange={(e) => setCode3(e.target.value)}
                      onKeyUp={(e) => {
                        if (e.target.value.length === 1) {
                          document.getElementById("input4").focus();
                        } else if (e.key === "Backspace") {
                          document.getElementById("input2").focus();
                        }
                      }}
                    />
                  </Grid>
                  <Grid item xs={2}>
                    <TextField
                      id="input4"
                      inputProps={{ min: 0, maxLength: 1, pattern: "[0-9]*", style: { textAlign: "center", fontSize: 32 } }}
                      fullWidth
                      variant="outlined"
                      value={code4}
                      onChange={(e) => setCode4(e.target.value)}
                      onKeyUp={(e) => {
                        if (e.target.value.length === 1) {
                          document.getElementById("input5").focus();
                        } else if (e.key === "Backspace") {
                          document.getElementById("input3").focus();
                        }
                      }}
                    />
                  </Grid>
                  <Grid item xs={2}>
                    <TextField
                      id="input5"
                      inputProps={{ min: 0, maxLength: 1, pattern: "[0-9]*", style: { textAlign: "center", fontSize: 32 } }}
                      fullWidth
                      variant="outlined"
                      value={code5}
                      onChange={(e) => setCode5(e.target.value)}
                      onKeyUp={(e) => {
                        if (e.target.value.length === 1) {
                          document.getElementById("input6").focus();
                        } else if (e.key === "Backspace") {
                          document.getElementById("input4").focus();
                        }
                      }}
                    />
                  </Grid>
                  <Grid item xs={2}>
                    <TextField
                      id="input6"
                      inputProps={{ min: 0, maxLength: 1, pattern: "[0-9]*", style: { textAlign: "center", fontSize: 32 } }}
                      fullWidth
                      variant="outlined"
                      value={code6}
                      onChange={(e) => setCode6(e.target.value)}
                      onKeyUp={(e) => {
                        if (e.key === "Backspace") {
                          document.getElementById("input5").focus();
                        }
                      }}
                    />
                  </Grid>
                </Grid>

                <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                  <LoadingButton
                    disableElevation
                    loading={processing}
                    onClick={(e) => {
                      if (isEnabled) {
                        setConfirmDisableDialog(true);
                      } else {
                        handleSaveAuthenticationSettings(e);
                      }
                    }}
                    variant="contained"
                    color="primary"
                  >
                    Save changes
                  </LoadingButton>
                </Box>
              </Box>
            </Box>

            <Dialog
              open={confirmDisableDialog}
              onClose={() => setConfirmTelephoneDialog(false)}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-title">{displaySuccess ? "Complete" : "Are you sure?"}</DialogTitle>
              <DialogContent>
                {displaySuccess ? (
                  <Lottie options={defaultOptions} height={100} width={100} />
                ) : (
                  <Box>
                    <DialogContentText sx={{ mb: 1 }} id="alert-dialog-description">
                      Are you sure you want to disable authentication?
                    </DialogContentText>
                    <Alert severity="error">We advise against disabling authentication.</Alert>
                  </Box>
                )}
              </DialogContent>
              <DialogActions>
                {!displaySuccess && (
                  <LoadingButton disableElevation loading={processing} onClick={() => setConfirmDisableDialog(false)} color="primary">
                    Cancel
                  </LoadingButton>
                )}

                <LoadingButton
                  disableElevation
                  fullWidth={displaySuccess}
                  sx={{ color: "#ffffff" }}
                  loading={processing}
                  onClick={(e) => {
                    handleSaveAuthenticationSettings(e);
                  }}
                  variant="contained"
                  color="danger"
                  autoFocus
                >
                  Confirm
                </LoadingButton>
              </DialogActions>
            </Dialog>
          </Box>
        </>
      ) : (
        <>
          <Box>
            <Lottie speed={2.0} options={defaultOptions} height={200} width={200} />
          </Box>
        </>
      )}
    </>
  );
};

export default Authentication;
