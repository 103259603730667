import { React, useEffect, useState } from "react";

import partner from "../../api/partner";
import central from "../../api/central";
import authHeader from "../../api/authHeader";

import HandleError from "../../components/functions/HandleError";
import HandleToast from "../../components/functions/HandleToast";

import Lottie from "react-lottie";
import loadingLottie from "../../components/lotties/loading-dots.json";

import {
  Box,
  Typography,
  Chip,
  Grid,
  Icon,
  IconButton,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Collapse,
  Fade,
  Button,
} from "@mui/material";
import { Close, Edit } from "@mui/icons-material";

import CountrySelect from "../../components/form-elements/CountrySelect";
import { LoadingButton } from "@mui/lab";

const Address = ({
  partnerData,
  partnerUser,
  caseData,
  entityData,
  registeredAddress,
  getAddress,
}) => {
  const useAuthHeader = authHeader();
  const handleError = HandleError();
  const displayToast = HandleToast();

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: loadingLottie,
  };

  const [isLoaded, setIsLoaded] = useState(false);
  const [processing, setProcessing] = useState(false);
  const [editMode, setEditMode] = useState(false);

  const [addressCountry, setAddressCountry] = useState();

  const [lookupDisplay, setLookupDisplay] = useState(false);
  const [lookupSupported, setLookupSupported] = useState(false);
  const [lookupValue, setLookupValue] = useState("");
  const [lookupOptions, setLookupOptions] = useState([]);
  const [formDisplay, setFormDisplay] = useState(false);
  const [stateRequired, setStateRequired] = useState(false);

  const [selectedAddress, setSelectedAddress] = useState("");

  const [addressLine1, setAddressLine1] = useState("");
  const [addressLine2, setAddressLine2] = useState("");
  const [addressLine3, setAddressLine3] = useState("");
  const [addressLine4, setAddressLine4] = useState("");
  const [addressLine5, setAddressLine5] = useState("");
  const [addressLine6, setAddressLine6] = useState("");
  const [addressCity, setAddressCity] = useState("");
  const [addressState, setAddressState] = useState("");
  const [addressPostcode, setAddressPostcode] = useState("");

  const handleLookupAddress = async (e) => {
    e.preventDefault();
    setProcessing(true);
    try {
      const response = await central.post(
        `/v1/service/addresses/lookup`,
        JSON.stringify({
          partner_uuid: partnerUser.value.partner_uuid,
          country: addressCountry ? addressCountry.value : "",
          postcode: lookupValue,
        }),
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Basic " + useAuthHeader.base64encodedData,
            service: "partners",
            partner_uuid: partnerUser.value.partner_uuid,
          },

          // withCredentials: true,
        }
      );

      if (response.data.results > 0) {
        setLookupOptions(response.data.entries);
      } else {
        //handleError("No addresses found");
        setLookupOptions([]);
      }
      setProcessing(false);
    } catch (err) {
      console.log(err);
      handleError(err);
      setProcessing(false);
    }
  };

  const getSelectedAddress = async (addressUUID) => {
    setProcessing(true);
    try {
      const response = await central.post(
        `/v1/service/addresses/data`,
        JSON.stringify({
          partner_uuid: partnerUser.value.partner_uuid,
          country: addressCountry ? addressCountry.value : "",
          uuid: addressUUID,
        }),
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Basic " + useAuthHeader.base64encodedData,
            service: "partners",
            partner_uuid: partnerUser.value.partner_uuid,
          },

          // withCredentials: true,
        }
      );

      setAddressLine1(response.data.line_one);
      setAddressLine2(response.data.line_two);
      setAddressLine3(response.data.line_three);
      setAddressLine4(response.data.line_four);
      setAddressLine5(response.data.line_five);
      setAddressLine6(response.data.line_six);
      setAddressCity(response.data.city);
      setAddressPostcode(response.data.code);
      setLookupDisplay(false);
      setFormDisplay(true);

      setProcessing(false);
    } catch (err) {
      console.log(err);
      handleError(err);
      setProcessing(false);
    }
  };

  const handleAddAddress = async (e) => {
    e.preventDefault();
    setProcessing(true);
    try {
      const response = await partner.post(
        `/v1/entity/corporations/corporation/address/add`,
        JSON.stringify({
          corporation_uuid: entityData.value.corporation_uuid,
          address_type: 1,
          line_one: addressLine1,
          line_two: addressLine2,
          line_three: addressLine3,
          line_four: addressLine4,
          line_five: addressLine5,
          line_six: addressLine6,
          city: addressCity,
          postcode: addressPostcode,
          country: addressCountry ? addressCountry.value : "",
        }),
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Basic " + useAuthHeader.base64encodedData,
            service: "partners",
          },

          // withCredentials: true,
        }
      );

      getAddress();

      displayToast("Address updated successfully", () => {
        setProcessing(false);
        setEditMode(false);
        setAddressCountry();
        setAddressLine1("");
        setAddressLine2("");
        setAddressLine3("");
        setAddressLine4("");
        setAddressLine5("");
        setAddressLine6("");
        setAddressCity("");
        setAddressState("");
        setAddressPostcode("");
        setFormDisplay(false);
        setLookupDisplay(false);
        setLookupSupported(false);
        setStateRequired(false);
        setLookupDisplay(false);
        setLookupValue("");
        setLookupOptions([]);
        setSelectedAddress("");
      });
    } catch (err) {
      console.log(err);
      handleError(err);
      setProcessing(false);
    }
  };

  useEffect(() => {
    setIsLoaded(true);
  }, []);

  useEffect(() => {
    if (addressCountry == "" || addressCountry == undefined) {
      console.log("undefined or empty address country");
      setFormDisplay(false);
      setLookupDisplay(false);
      setLookupSupported(false);
      setStateRequired(false);
      return;
    }

    switch (addressCountry?.value) {
      case "GB":
        setLookupDisplay(true);
        setLookupSupported(true);
        setStateRequired(false);
        setFormDisplay(false);
        break;
      case "JE":
        setLookupDisplay(true);
        setLookupSupported(true);
        setStateRequired(false);
        setFormDisplay(false);
        break;
      case "GG":
        setLookupDisplay(true);
        setLookupSupported(true);
        setStateRequired(false);
        setFormDisplay(false);
        break;
      case "IM":
        setLookupDisplay(true);
        setLookupSupported(true);
        setStateRequired(false);
        setFormDisplay(false);
        break;
      case "US":
        setLookupDisplay(false);
        setLookupSupported(false);
        setStateRequired(true);
        setFormDisplay(true);
        break;
      default:
        setLookupDisplay(false);
        setLookupSupported(false);
        setStateRequired(false);
        setFormDisplay(true);
        break;
    }
  }, [addressCountry]);

  useEffect(() => {
    if (selectedAddress != "") {
      getSelectedAddress();
    }
  }, [selectedAddress]);

  return isLoaded ? (
    <Grid container spacing={2}>
      <Grid item xs={12} xl={12}>
        <Box sx={{}}>
          {!editMode ? (
            <Box
              sx={{ borderRight: { xs: 0, xl: 0 }, borderColor: { xs: "divider", xl: "divider" } }}
            >
              <Box
                sx={{
                  borderBottom: 1,
                  borderColor: "divider",
                  px: 2,
                  py: 1,
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
                  <Typography variant="h6">Registered Address</Typography>
                </Box>
                <Box>
                  <IconButton title="Edit" onClick={() => setEditMode(true)}>
                    <Edit />
                  </IconButton>
                </Box>
              </Box>

              <Box sx={{ p: 2 }}>
                <Box>
                  <Typography variant="subtitle2">
                    {registeredAddress?.line_one ? registeredAddress.line_one : "-"}
                  </Typography>
                  {registeredAddress?.line_two && (
                    <Typography variant="subtitle2">
                      {registeredAddress.line_two ? registeredAddress.line_two : "-"}
                    </Typography>
                  )}
                  {registeredAddress?.line_three && (
                    <Typography variant="subtitle2">
                      {registeredAddress.line_three ? registeredAddress.line_three : "-"}
                    </Typography>
                  )}
                  {registeredAddress?.line_four && (
                    <Typography variant="subtitle2">
                      {registeredAddress.line_four ? registeredAddress.line_four : "-"}
                    </Typography>
                  )}
                  {registeredAddress?.line_five && (
                    <Typography variant="subtitle2">
                      {registeredAddress.line_five ? registeredAddress.line_five : "-"}
                    </Typography>
                  )}
                  {registeredAddress?.line_six && (
                    <Typography variant="subtitle2">
                      {registeredAddress.line_six ? registeredAddress.line_six : "-"}
                    </Typography>
                  )}
                  {registeredAddress?.city && (
                    <Typography variant="subtitle2">
                      {registeredAddress.city ? registeredAddress.city : "-"}
                    </Typography>
                  )}
                  {registeredAddress?.postcode && (
                    <Typography variant="subtitle2">
                      {registeredAddress.postcode ? registeredAddress.postcode : "-"}
                    </Typography>
                  )}
                  {registeredAddress?.state && (
                    <Typography variant="subtitle2">
                      {registeredAddress.state ? registeredAddress.state : "-"}
                    </Typography>
                  )}
                  {registeredAddress?.country.code && (
                    <Typography variant="subtitle2">
                      {registeredAddress.country.code ? registeredAddress.country.name : "-"}
                    </Typography>
                  )}
                  {registeredAddress.country?.high_risk && (
                    <Chip
                      className="square-chip"
                      label="High risk"
                      size="small"
                      sx={{
                        backgroundColor: "#C83E4D",
                        color: "#ffffff",
                        textTransform: "uppercase",
                      }}
                    />
                  )}
                  {registeredAddress.country?.conflict && (
                    <Chip
                      className="square-chip"
                      label="Conflict"
                      size="small"
                      sx={{
                        backgroundColor: "#FF4A1C",
                        color: "#1C2321",
                        textTransform: "uppercase",
                      }}
                    />
                  )}
                  {registeredAddress.country?.borders_conflict && (
                    <Chip
                      className="square-chip"
                      label="Borders conflict"
                      size="small"
                      sx={{
                        backgroundColor: "#977390",
                        color: "#1C2321",
                        textTransform: "uppercase",
                      }}
                    />
                  )}
                  {registeredAddress.country?.is_secrecy && (
                    <Chip
                      className="square-chip"
                      label="Secrecy"
                      size="small"
                      sx={{
                        backgroundColor: "#999999",
                        color: "#1C2321",
                        textTransform: "uppercase",
                      }}
                    />
                  )}
                </Box>
              </Box>
            </Box>
          ) : (
            <Box>
              <Box
                sx={{
                  borderRight: { xs: 0, xl: 0 },
                  borderColor: { xs: "divider", xl: "divider" },
                }}
              >
                <Box
                  sx={{
                    borderBottom: 1,
                    borderColor: "divider",
                    px: 2,
                    py: 1,
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
                    <Typography variant="h6">Update address</Typography>
                  </Box>
                  <Box>
                    <IconButton title="Close" onClick={() => setEditMode(false)}>
                      <Close />
                    </IconButton>
                  </Box>
                </Box>
                <Box sx={{ p: 2, display: "flex", flexDirection: "column", gap: 2 }}>
                  <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
                    <CountrySelect value={addressCountry} setValue={setAddressCountry} />

                    <Collapse in={lookupDisplay} exit={!lookupDisplay}>
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          gap: 1,
                          p: 2,
                          border: 1,
                          borderColor: "divider",
                        }}
                      >
                        <Typography variant="subtitle2">Address lookup</Typography>
                        <TextField
                          label="Postcode / Zipcode"
                          variant="outlined"
                          fullWidth
                          onChange={(e) => {
                            setLookupValue(e.target.value);
                          }}
                        />

                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-between",
                            gap: 2,
                          }}
                        >
                          <Box sx={{ minWidth: "300px;", width: "50%" }}>
                            {lookupOptions && lookupOptions.length > 0 && (
                              <Box>
                                <Typography variant="subtitle2" sx={{ mb: 1 }}>
                                  Lookup results
                                </Typography>

                                <FormControl fullWidth>
                                  <InputLabel id="address-results-label">
                                    Select an address
                                  </InputLabel>
                                  <Select
                                    labelId="address-results-label"
                                    id="address-results"
                                    value={selectedAddress}
                                    label="Select an address"
                                    onChange={(e) => getSelectedAddress(e.target.value)}
                                  >
                                    {lookupOptions.map((option, index) => (
                                      <MenuItem key={index} value={option.value}>
                                        {option.label}
                                      </MenuItem>
                                    ))}
                                  </Select>
                                </FormControl>
                              </Box>
                            )}
                          </Box>
                          <Box sx={{ display: "flex", flexDirection: "row", gap: 2 }}>
                            <Box>
                              <Button
                                variant="outlined"
                                color="primary"
                                onClick={(e) => {
                                  setLookupDisplay(false);
                                  setFormDisplay(true);
                                }}
                              >
                                Manual entry
                              </Button>
                            </Box>
                            <Box>
                              <LoadingButton
                                disableElevation
                                variant="contained"
                                color="success"
                                loading={processing}
                                onClick={(e) => handleLookupAddress(e)}
                              >
                                Search
                              </LoadingButton>
                            </Box>
                          </Box>
                        </Box>
                      </Box>
                    </Collapse>

                    <Collapse in={formDisplay} exit={!formDisplay}>
                      <Box sx={{ display: "flex", flexDirection: "column" }}>
                        <TextField
                          label="Address line 1"
                          variant="outlined"
                          sx={{ mt: 1 }}
                          fullWidth
                          value={addressLine1}
                          onChange={(e) => setAddressLine1(e.target.value)}
                        />

                        <Collapse
                          in={addressLine1 != "" ? true : addressLine2 != "" ? true : false}
                          exit={(addressLine1 == "") & (addressLine2 == "") ? true : false}
                        >
                          <TextField
                            label="Address line 2"
                            variant="outlined"
                            sx={{ mt: 1 }}
                            fullWidth
                            value={addressLine2}
                            onChange={(e) => setAddressLine2(e.target.value)}
                          />
                        </Collapse>
                        <Collapse
                          in={addressLine2 != "" ? true : addressLine3 != "" ? true : false}
                          exit={(addressLine2 == "") & (addressLine3 == "") ? true : false}
                        >
                          <TextField
                            label="Address line 3"
                            variant="outlined"
                            sx={{ mt: 1 }}
                            fullWidth
                            value={addressLine3}
                            onChange={(e) => setAddressLine3(e.target.value)}
                          />
                        </Collapse>

                        <Collapse
                          in={addressLine3 != "" ? true : addressLine4 != "" ? true : false}
                          exit={(addressLine3 == "") & (addressLine4 == "") ? true : false}
                        >
                          <TextField
                            label="Address line 4"
                            variant="outlined"
                            sx={{ mt: 1 }}
                            fullWidth
                            value={addressLine4}
                            onChange={(e) => setAddressLine4(e.target.value)}
                          />
                        </Collapse>
                        <Collapse
                          in={addressLine4 != "" ? true : addressLine5 != "" ? true : false}
                          exit={(addressLine4 == "") & (addressLine5 == "") ? true : false}
                        >
                          <TextField
                            label="Address line 5"
                            variant="outlined"
                            sx={{ mt: 1 }}
                            fullWidth
                            value={addressLine5}
                            onChange={(e) => setAddressLine5(e.target.value)}
                          />
                        </Collapse>
                        <Collapse
                          in={addressLine5 != "" ? true : addressLine6 != "" ? true : false}
                          exit={(addressLine5 == "") & (addressLine6 == "") ? true : false}
                        >
                          <TextField
                            label="Address line 6"
                            variant="outlined"
                            sx={{ mt: 1 }}
                            fullWidth
                            value={addressLine6}
                            onChange={(e) => setAddressLine6(e.target.value)}
                          />
                        </Collapse>
                        <TextField
                          label="City"
                          variant="outlined"
                          sx={{ mt: 1 }}
                          fullWidth
                          value={addressCity}
                          onChange={(e) => setAddressCity(e.target.value)}
                        />

                        <Collapse in={stateRequired} exit={!stateRequired}>
                          <TextField
                            label="State"
                            variant="outlined"
                            sx={{ mt: 1 }}
                            fullWidth
                            value={addressState}
                            onChange={(e) => setAddressState(e.target.value)}
                          />
                        </Collapse>
                        <TextField
                          label="Postcode/Zipcode"
                          variant="outlined"
                          sx={{ mt: 1 }}
                          fullWidth
                          value={addressPostcode}
                          onChange={(e) => setAddressPostcode(e.target.value)}
                        />
                      </Box>

                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "flex-end",
                          mt: 2,
                        }}
                      >
                        <Box sx={{ display: "flex", flexDirection: "row", gap: 2 }}>
                          {lookupSupported && (
                            <Button
                              variant="outlined"
                              color="primary"
                              onClick={(e) => {
                                setLookupDisplay(true);
                                setFormDisplay(false);
                              }}
                            >
                              Lookup address
                            </Button>
                          )}
                          <LoadingButton
                            disableElevation
                            variant="contained"
                            color="success"
                            loading={processing}
                            onClick={(e) => handleAddAddress(e)}
                          >
                            Save address
                          </LoadingButton>
                        </Box>
                      </Box>
                    </Collapse>
                  </Box>
                </Box>
              </Box>
            </Box>
          )}
        </Box>
      </Grid>
    </Grid>
  ) : (
    <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", height: "50vh" }}>
      <Lottie speed={2.0} options={defaultOptions} height={200} width={200} />
    </Box>
  );
};

export default Address;
