import { React, useEffect, useState } from "react";

import authHeader from "../../../../../api/authHeader";

import HandleError from "../../../../../components/functions/HandleError";
import HandleToast from "../../../../../components/functions/HandleToast";

import Lottie from "react-lottie";
import loadingLottie from "../../../../../components/lotties/loading-dots.json";

import DataTable from "react-data-table-component";

import {
  Typography,
  Box,
  Button,
  Divider,
  Card,
  Avatar,
  CardHeader,
  CardContent,
  IconButton,
  Alert,
  Fade,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import { Grid, TextField, FormControl, InputLabel, MenuItem, Select, ToggleButton, ToggleButtonGroup, Chip, Switch, Collapse } from "@mui/material";
import { LoadingButton } from "@mui/lab";

import { Add, Close, Delete, Edit } from "@mui/icons-material";

const RiskAssessment = ({ partner, partnerUser, partnerData }) => {
  const useAuthHeader = authHeader();

  const handleError = HandleError();
  const displayToast = HandleToast();

  const [isLoaded, setIsLoaded] = useState(false);
  const [processing, setProcessing] = useState(false);
  const [data, setData] = useState([]);

  const [assessmentType, setAssessmentType] = useState(5); // 1 individual | 2 business | 3 thresholds | 4 approvals | 5 terminology
  const [manageQuestion, setManageQuestion] = useState(false);

  const [questionText, setQuestionText] = useState("");
  const [answerType, setAnswerType] = useState("");
  const [autoCalculate, setAutoCalculate] = useState(false);
  const [autoCalculationType, setAutoCalculationType] = useState(0);
  const [countryDataType, setCountryDataType] = useState(0);
  const [activeQuestion, setActiveQuestion] = useState({});

  const [yesScore, setYesScore] = useState(0);
  const [noScore, setNoScore] = useState(0);

  const [highRiskScore, setHighRiskScore] = useState(0);
  const [conflictScore, setConflictScore] = useState(0);
  const [bordersConflictScore, setBordersConflictScore] = useState(0);
  const [privacyScore, setPrivacyScore] = useState(0);

  const [corporateAutoDeclineThreshold, setCorporateAutoDeclineThreshold] = useState(0);
  const [individualAutoDeclineThreshold, setIndividualAutoDeclineThreshold] = useState(0);

  const [tierThreeApprovals, setTierThreeApprovals] = useState(0);
  const [tierTwoApprovals, setTierTwoApprovals] = useState(0);
  const [tierOneApprovals, setTierOneApprovals] = useState(0);
  const [selfApproval, setSelfApproval] = useState(false);

  const [terminologyData, setTerminologyData] = useState(null);

  const [tierOneLabel, setTierOneLabel] = useState("");
  const [tierTwoActive, setTierTwoActive] = useState(false);
  const [tierTwoLabel, setTierTwoLabel] = useState("");
  const [tierThreeLabel, setTierThreeLabel] = useState("");

  const [individualTierTwoThreshold, setIndividualTierTwoThreshold] = useState(0);
  const [individualTierThreeThreshold, setIndividualTierThreeThreshold] = useState(0);
  const [corporateTierTwoThreshold, setCorporateTierTwoThreshold] = useState(0);
  const [corporateTierThreeThreshold, setCorporateTierThreeThreshold] = useState(0);

  const [confirmDialog, setConfirmDialog] = useState(false);

  const [removeQuestionDialog, setRemoveQuestionDialog] = useState(false);

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: loadingLottie,
  };

  const customStyles = {
    headCells: {
      style: {
        paddingLeft: "8px", // override the cell padding for head cells
        paddingRight: "8px",
        fontWeight: "bold",
      },
    },
    cells: {
      style: {
        paddingLeft: "8px", // override the cell padding for data cells
        paddingRight: "8px",
      },
    },
  };

  const columns = [
    {
      name: "Question",
      sortable: true,
      width: "50%",
      wrap: true,
      selector: (row) => row.question,
    },
    {
      name: "Type",
      sortable: true,
      wrap: true,
      right: "true",
      selector: (row) => row.answer_type,
      cell: (row) => (
        <>
          {row.answer_type == 1 && <Chip className="square-chip" label="Yes/No" color="primary" sx={{ textTransform: "uppercase" }} />}
          {row.answer_type == 2 && <Chip className="square-chip" label="Select" color="primary" sx={{ textTransform: "uppercase" }} />}
          {row.answer_type == 3 && <Chip className="square-chip" label="Free text" color="primary" sx={{ textTransform: "uppercase" }} />}
          {row.answer_type == 4 && <Chip className="square-chip" label="Country" color="primary" sx={{ textTransform: "uppercase" }} />}
          {row.answer_type == 5 && <Chip className="square-chip" label="Multiple" color="primary" sx={{ textTransform: "uppercase" }} />}
        </>
      ),
    },
    {
      name: "Auto calculate",
      sortable: true,
      right: "true",
      wrap: true,
      selector: (row) => row.auto_calculate,
      cell: (row) => (
        <>
          {row.auto_calculate && (
            <Chip className="square-chip" label="Yes" sx={{ backgroundColor: "#55828b", color: "#ffffff", textTransform: "uppercase" }} />
          )}
          {!row.auto_calculate && "-"}
        </>
      ),
    },
    {
      name: "Edit",
      right: "true",
      sortable: false,
      cell: (row) => (
        <>
          <IconButton
            color="primary"
            onClick={() => {
              getQuestionData(row.uuid);
            }}
          >
            <Edit />
          </IconButton>
        </>
      ),
    },
  ];

  const autoCalculateOptions = [
    { value: 13, label: "Is a PEP" },
    { value: 14, label: "Is sanctioned" },
    { value: 15, label: "Has adverse media" },
    { value: 18, label: "Age alert (low)" },
    { value: 19, label: "Age alert (high)" },
  ];

  const countryDataTypeOptions = [
    { value: 1, label: "Address country" },
    { value: 2, label: "Telephone country" },
    { value: 3, label: "Birth country" },
    { value: 4, label: "Citizenship country" },
    { value: 5, label: "Access country (IP address)" },
    { value: 6, label: "Transaction counterparty" },
  ];

  const getThresholds = async () => {
    try {
      const response = await partner.post(
        `/v1/screening/risk-assessment/thresholds`,
        JSON.stringify({
          assessment_type: assessmentType,
        }),
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Basic " + useAuthHeader.base64encodedData,
            service: "partners",
          },

          //withCredentials: true,
        }
      );

      setIndividualTierTwoThreshold(response.data.individual_medium_threshold);
      setIndividualTierThreeThreshold(response.data.individual_high_threshold);
      setCorporateTierTwoThreshold(response.data.corporation_medium_threshold);
      setCorporateTierThreeThreshold(response.data.corporation_high_threshold);
    } catch (err) {
      handleError(err);
    }
  };

  const getTerminology = async () => {
    try {
      const response = await partner.post(
        `/v1/screening/risk-assessment/terminology`,
        JSON.stringify({
          assessment_type: assessmentType,
        }),
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Basic " + useAuthHeader.base64encodedData,
            service: "partners",
          },

          //withCredentials: true,
        }
      );

      setTerminologyData(response.data);

      setTierOneLabel(response.data.tier_one_label);
      setTierTwoActive(response.data.tier_two_label ? true : false);
      setTierTwoLabel(response.data.tier_two_label);
      setTierThreeLabel(response.data.tier_three_label);

      setIsLoaded(true);
    } catch (err) {
      handleError(err);
    }
  };

  const getApprovals = async () => {
    try {
      const response = await partner.post(
        `/v1/screening/risk-assessment/approvals`,
        JSON.stringify({
          assessment_type: assessmentType,
        }),
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Basic " + useAuthHeader.base64encodedData,
            service: "partners",
          },

          //withCredentials: true,
        }
      );

      setIndividualAutoDeclineThreshold(response.data.individual_auto_decline);
      setCorporateAutoDeclineThreshold(response.data.corporation_auto_decline);
      setTierThreeApprovals(response.data.tier_three_approvals);
      setTierTwoApprovals(response.data.tier_two_approvals);
      setTierOneApprovals(response.data.tier_one_approvals);
      setSelfApproval(response.data.self_approval);
    } catch (err) {
      handleError(err);
    }
  };

  const getQuestions = async () => {
    try {
      const response = await partner.post(
        `/v1/screening/risk-assessment/questions/list`,
        JSON.stringify({
          assessment_type: assessmentType,
        }),
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Basic " + useAuthHeader.base64encodedData,
            service: "partners",
          },

          //withCredentials: true,
        }
      );

      if (response.data.results > 0) {
        setData(response.data.entries);
      } else {
        setData([]);
      }
      setIsLoaded(true);
    } catch (err) {
      handleError(err);
    }
  };

  const getQuestionData = async (uuid) => {
    try {
      const response = await partner.post(
        `/v1/screening/risk-assessment/questions`,
        JSON.stringify({
          question_uuid: uuid,
        }),
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Basic " + useAuthHeader.base64encodedData,
            service: "partners",
          },

          //withCredentials: true,
        }
      );

      setQuestionText(response.data.question);
      setAnswerType(response.data.answer_type);

      setYesScore(response.data.yes_score);
      setNoScore(response.data.no_score);
      setConflictScore(response.data.conflict_score);
      setHighRiskScore(response.data.high_risk_score);
      setBordersConflictScore(response.data.borders_conflict_score);
      setPrivacyScore(response.data.privacy_score);

      setAutoCalculate(response.data.auto_calculate);
      if (response.data.answer_type == 1) {
        setAutoCalculationType(response.data.auto_calculation_type);
        setCountryDataType(0);
      } else if (response.data.answer_type == 4) {
        setCountryDataType(response.data.auto_calculation_type);
        setAutoCalculationType(0);
      }

      setActiveQuestion(response.data);
      setManageQuestion(true);
    } catch (err) {
      handleError(err);
    }
  };

  const handleAddQuestion = async (e) => {
    e.preventDefault();
    setProcessing(true);

    var answerData =
      answerType == 1
        ? JSON.stringify({
            yes: parseInt(yesScore),
            no: parseInt(noScore),
          })
        : answerType == 4 &&
          JSON.stringify({
            high_risk: parseInt(highRiskScore),
            active_conflict: parseInt(conflictScore),
            borders_conflict: parseInt(bordersConflictScore),
            is_secrecy: parseInt(privacyScore),
          });

    try {
      const response = await partner.post(
        `/v1/screening/risk-assessment/questions/add`,
        JSON.stringify({
          assessment_type: assessmentType,
          question: questionText,
          answer_type: answerType,
          auto_calculate: autoCalculate,
          auto_calculation_type: parseInt(autoCalculationType),
          country_data_type: parseInt(countryDataType),
          answer_data: answerData,
        }),
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Basic " + useAuthHeader.base64encodedData,
            service: "partners",
          },

          //withCredentials: true,
        }
      );

      getQuestions();
      setActiveQuestion({});

      displayToast("Question created successfully", () => {
        setProcessing(false);
        setManageQuestion(false);
      });
    } catch (err) {
      console.log(err);
      handleError(err);
      setProcessing(false);
    }
  };

  const handleUpdateQuestion = async (e) => {
    e.preventDefault();
    setProcessing(true);

    var answerData =
      answerType == 1
        ? JSON.stringify({
            yes: parseInt(yesScore),
            no: parseInt(noScore),
          })
        : answerType == 4 &&
          JSON.stringify({
            high_risk: parseInt(highRiskScore),
            active_conflict: parseInt(conflictScore),
            borders_conflict: parseInt(bordersConflictScore),
            is_secrecy: parseInt(privacyScore),
          });

    try {
      const response = await partner.post(
        `/v1/screening/risk-assessment/questions/update`,
        JSON.stringify({
          question_uuid: activeQuestion.uuid,
          question: questionText,
          auto_calculate: autoCalculate,
          auto_calculation_type: parseInt(autoCalculationType),
          country_data_type: parseInt(countryDataType),
          answer_data: answerData,
        }),
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Basic " + useAuthHeader.base64encodedData,
            service: "partners",
          },

          //withCredentials: true,
        }
      );

      getQuestions();
      setActiveQuestion({});

      displayToast("Question updated successfully", () => {
        setProcessing(false);
        setManageQuestion(false);
      });
    } catch (err) {
      console.log(err);
      handleError(err);
      setProcessing(false);
    }
  };

  const handleUpdateTerminology = async (e) => {
    e.preventDefault();
    setProcessing(true);

    try {
      const response = await partner.post(
        `/v1/screening/risk-assessment/terminology/update`,
        JSON.stringify({
          label_one: tierOneLabel,
          two_enabled: tierTwoActive,
          label_two: tierTwoActive ? tierTwoLabel : "",
          label_three: tierThreeLabel,
        }),
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Basic " + useAuthHeader.base64encodedData,
            service: "partners",
          },

          //withCredentials: true,
        }
      );

      getTerminology();
      setConfirmDialog(false);
      displayToast("Terminology updated successfully", () => {
        setProcessing(false);
      });
    } catch (err) {
      console.log(err);
      setConfirmDialog(false);
      handleError(err);
      setProcessing(false);
    }
  };

  const handleUpdateApprovals = async (e) => {
    e.preventDefault();
    setProcessing(true);

    try {
      const response = await partner.post(
        `/v1/screening/risk-assessment/approvals/update`,
        JSON.stringify({
          tier_one_approvals: parseInt(tierOneApprovals),
          tier_two_approvals: parseInt(tierTwoApprovals),
          tier_three_approvals: parseInt(tierThreeApprovals),
          individual_auto_decline: parseInt(individualAutoDeclineThreshold),
          corporate_auto_decline: parseInt(corporateAutoDeclineThreshold),
          self_approval: selfApproval,
        }),
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Basic " + useAuthHeader.base64encodedData,
            service: "partners",
          },

          //withCredentials: true,
        }
      );

      getApprovals();
      setConfirmDialog(false);

      displayToast("Approval settings updated successfully", () => {
        setProcessing(false);
      });
    } catch (err) {
      console.log(err);
      setConfirmDialog(false);
      handleError(err);
      setProcessing(false);
    }
  };

  const handleUpdateThresholds = async (e) => {
    e.preventDefault();
    setProcessing(true);

    console.log(individualTierTwoThreshold);
    console.log(individualTierThreeThreshold);

    try {
      const response = await partner.post(
        `/v1/screening/risk-assessment/thresholds/update`,
        JSON.stringify({
          individual_tier_two: parseInt(individualTierTwoThreshold),
          individual_tier_three: parseInt(individualTierThreeThreshold),
          corporate_tier_two: parseInt(corporateTierTwoThreshold),
          corporate_tier_three: parseInt(corporateTierThreeThreshold),
        }),
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Basic " + useAuthHeader.base64encodedData,
            service: "partners",
          },

          //withCredentials: true,
        }
      );

      getApprovals();
      setConfirmDialog(false);

      displayToast("Approval settings updated successfully", () => {
        setProcessing(false);
      });
    } catch (err) {
      console.log(err);
      setConfirmDialog(false);
      handleError(err);
      setProcessing(false);
    }
  };

  useEffect(() => {
    getTerminology();
    getThresholds();
    getApprovals();
  }, []);

  useEffect(() => {
    getQuestions();
  }, [assessmentType]);

  useEffect(() => {
    if (!activeQuestion.uuid) {
      setQuestionText("");
      setAnswerType("");
      setAutoCalculate(false);
      setAutoCalculationType("");
      setCountryDataType("");
    }
  }, [activeQuestion]);

  return (
    <>
      {isLoaded ? (
        <>
          <Box sx={{ borderTop: 1, borderColor: "divider" }}>
            <Box sx={{ p: 2, display: "flex", justifyContent: "space-between" }}>
              <Box sx={{ display: "flex", gap: 4 }}>
                <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
                  <Typography variant="h6">
                    {assessmentType == 1
                      ? "Individual risk assessment"
                      : assessmentType == 2
                      ? "Client business risk assessment"
                      : assessmentType == 3
                      ? "Threshold settings"
                      : assessmentType == 4
                      ? "Approval settings"
                      : "Terminology settings"}
                  </Typography>
                </Box>

                <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center", width: "fit-content" }}>
                  {assessmentType < 3 && (
                    <Button
                      onClick={() => {
                        setActiveQuestion({});
                        setManageQuestion(!manageQuestion);
                      }}
                      size="small"
                      variant="contained"
                      color="primary"
                      startIcon={manageQuestion ? <Close /> : <Add />}
                    >
                      {manageQuestion ? "Close" : "Add question"}
                    </Button>
                  )}
                </Box>
              </Box>

              {!manageQuestion && (
                <Box sx={{ p: 2 }}>
                  <ToggleButtonGroup
                    color="primary"
                    value={assessmentType}
                    exclusive
                    onChange={(e, newValue) => {
                      if (newValue !== null) setAssessmentType(newValue);
                    }}
                    aria-label="Assessment type"
                  >
                    <ToggleButton value={1}>Individuals</ToggleButton>
                    <ToggleButton value={2}>Corporations</ToggleButton>
                    <ToggleButton value={3} disabled={manageQuestion ? true : false}>
                      Thresholds
                    </ToggleButton>
                    <ToggleButton value={4} disabled={manageQuestion ? true : false}>
                      Approvals
                    </ToggleButton>
                    <ToggleButton value={5} disabled={manageQuestion ? true : false}>
                      Terminology
                    </ToggleButton>
                  </ToggleButtonGroup>
                </Box>
              )}
            </Box>
            <Divider />
            {manageQuestion ? (
              <>
                <Box sx={{ p: 2 }}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={12} md={12} lg={6}>
                      <Box sx={{ display: "flex", flexDirection: "column", gap: 2, border: 1, borderColor: "divider", p: 2 }}>
                        <TextField
                          fullWidth
                          label="Question"
                          value={questionText}
                          onChange={(e) => setQuestionText(e.target.value)}
                          variant="outlined"
                        />

                        <FormControl fullWidth variant="outlined">
                          <InputLabel id="answer-type">Answer type</InputLabel>
                          <Select labelId="answer-type" value={answerType} onChange={(e) => setAnswerType(e.target.value)} label="Answer type">
                            <MenuItem value={1}>Yes/No</MenuItem>
                            <MenuItem value={3}>Free text</MenuItem>
                            <MenuItem value={4}>Country</MenuItem>
                          </Select>
                        </FormControl>

                        {answerType == 1 && (
                          <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
                            <FormControl fullWidth>
                              <Box sx={{ display: "flex", alignItems: "center" }}>
                                <Typography variant="body1">Auto calculate</Typography>
                                <Switch checked={autoCalculate} onChange={(e) => setAutoCalculate(e.target.checked)} />
                              </Box>
                            </FormControl>

                            {autoCalculate && (
                              <FormControl fullWidth variant="outlined">
                                <InputLabel id="auto-calculate">Auto calculate</InputLabel>
                                <Select
                                  labelId="auto-calculate"
                                  value={autoCalculationType}
                                  onChange={(e) => setAutoCalculationType(e.target.value)}
                                  label="Auto calculate"
                                >
                                  {autoCalculateOptions.map((option) => (
                                    <MenuItem key={option.value} value={option.value}>
                                      {option.label}
                                    </MenuItem>
                                  ))}
                                </Select>
                              </FormControl>
                            )}

                            <Grid container spacing={2}>
                              <Grid item xs={12} sm={12} md={6} lg={6}>
                                <TextField
                                  fullWidth
                                  label="Yes score"
                                  type="number"
                                  value={yesScore}
                                  onChange={(e) => setYesScore(e.target.value)}
                                  variant="outlined"
                                />
                              </Grid>
                              <Grid item xs={12} sm={12} md={6} lg={6}>
                                <TextField
                                  fullWidth
                                  label="No score"
                                  type="number"
                                  value={noScore}
                                  onChange={(e) => setNoScore(e.target.value)}
                                  variant="outlined"
                                />
                              </Grid>
                            </Grid>
                          </Box>
                        )}

                        {answerType == 4 && (
                          <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
                            <FormControl fullWidth variant="outlined">
                              <InputLabel id="country-data-type">Country data type</InputLabel>
                              <Select
                                labelId="country-data-type"
                                value={countryDataType}
                                onChange={(e) => setCountryDataType(e.target.value)}
                                label="Country data type"
                              >
                                {countryDataTypeOptions.map((option) => (
                                  <MenuItem key={option.value} value={option.value}>
                                    {option.label}
                                  </MenuItem>
                                ))}
                              </Select>
                            </FormControl>

                            <Grid container spacing={2}>
                              <Grid item xs={12} sm={12} md={3} lg={3}>
                                <TextField
                                  fullWidth
                                  label="High risk score"
                                  type="number"
                                  value={highRiskScore}
                                  onChange={(e) => setHighRiskScore(e.target.value)}
                                  variant="outlined"
                                />
                              </Grid>
                              <Grid item xs={12} sm={12} md={3} lg={3}>
                                <TextField
                                  fullWidth
                                  label="Conflict score"
                                  type="number"
                                  value={conflictScore}
                                  onChange={(e) => setConflictScore(e.target.value)}
                                  variant="outlined"
                                />
                              </Grid>
                              <Grid item xs={12} sm={12} md={3} lg={3}>
                                <TextField
                                  fullWidth
                                  label="Borders conflict score"
                                  type="number"
                                  value={bordersConflictScore}
                                  onChange={(e) => setBordersConflictScore(e.target.value)}
                                  variant="outlined"
                                />
                              </Grid>
                              <Grid item xs={12} sm={12} md={3} lg={3}>
                                <TextField
                                  fullWidth
                                  label="Privacy score"
                                  type="number"
                                  value={privacyScore}
                                  onChange={(e) => setPrivacyScore(e.target.value)}
                                  variant="outlined"
                                />
                              </Grid>
                            </Grid>
                          </Box>
                        )}

                        <Box sx={{ display: "flex", justifyContent: "space-between", gap: 2 }}>
                          <LoadingButton
                            disableElevation
                            loading={processing}
                            onClick={() => {
                              setActiveQuestion({});
                              setManageQuestion(false);
                            }}
                            color="primary"
                            variant="outlined"
                          >
                            Cancel
                          </LoadingButton>
                          <LoadingButton
                            disableElevation
                            loading={processing}
                            onClick={(e) => {
                              activeQuestion.uuid ? handleUpdateQuestion(e) : handleAddQuestion(e);
                            }}
                            variant="contained"
                            color="success"
                          >
                            Save
                          </LoadingButton>
                        </Box>
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
              </>
            ) : (
              <>
                {assessmentType < 3 ? (
                  <DataTable columns={columns} data={data} customStyles={customStyles} pagination striped />
                ) : assessmentType == 3 ? (
                  <>
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={12} md={12} lg={12} xl={6}>
                        <Box sx={{ p: 2 }}>
                          <Box sx={{ p: 2, display: "flex", flexDirection: "column", gap: 2 }}>
                            <Box sx={{ border: 1, borderColor: "divider" }}>
                              <Box sx={{ p: 2, display: "flex", flexDirection: "column", gap: 1 }}>
                                <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                                  <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
                                    <Typography variant="h6">Risk level thresholds (individuals)</Typography>
                                  </Box>
                                  <Box sx={{ display: "flex", flexDirection: "column", gap: 2, width: "50%" }}>
                                    <Box sx={{ display: "flex", justifyContent: "flex-end", width: "100%" }}>
                                      <Box sx={{ display: "flex", flexDirection: "column", gap: 2, width: "100%" }}>
                                        <Collapse in={tierTwoActive} exit={!tierTwoActive}>
                                          <TextField
                                            label={`Scores above this threshold are considered ${terminologyData.tier_two_label}`}
                                            type="number"
                                            min={1}
                                            value={individualTierTwoThreshold}
                                            onChange={(e) => setIndividualTierTwoThreshold(e.target.value)}
                                            fullWidth
                                          />
                                        </Collapse>

                                        <TextField
                                          label={`Scores above this threshold are considered ${terminologyData.tier_three_label}`}
                                          type="number"
                                          min={1}
                                          value={individualTierThreeThreshold}
                                          onChange={(e) => setIndividualTierThreeThreshold(e.target.value)}
                                          fullWidth
                                        />
                                      </Box>
                                    </Box>
                                  </Box>
                                </Box>
                              </Box>
                            </Box>

                            <Box sx={{ border: 1, borderColor: "divider" }}>
                              <Box sx={{ p: 2, display: "flex", flexDirection: "column", gap: 1 }}>
                                <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                                  <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
                                    <Typography variant="h6">Risk level thresholds (corporations)</Typography>
                                  </Box>
                                  <Box sx={{ display: "flex", flexDirection: "column", gap: 2, width: "50%" }}>
                                    <Box sx={{ display: "flex", justifyContent: "flex-end", width: "100%" }}>
                                      <Box sx={{ display: "flex", flexDirection: "column", gap: 3, width: "100%" }}>
                                        <Collapse in={tierTwoActive} exit={!tierTwoActive}>
                                          <TextField
                                            label={`Scores above this threshold are considered ${terminologyData.tier_two_label}`}
                                            type="number"
                                            min={1}
                                            value={corporateTierTwoThreshold}
                                            onChange={(e) => setCorporateTierTwoThreshold(e.target.value)}
                                            fullWidth
                                          />
                                        </Collapse>

                                        <TextField
                                          label={`Scores above this threshold are considered ${terminologyData.tier_three_label}`}
                                          type="number"
                                          min={1}
                                          value={corporateTierThreeThreshold}
                                          onChange={(e) => setCorporateTierThreeThreshold(e.target.value)}
                                          fullWidth
                                        />
                                      </Box>
                                    </Box>
                                  </Box>
                                </Box>
                              </Box>
                            </Box>

                            <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                              <LoadingButton
                                disableElevation
                                variant="contained"
                                color="success"
                                loading={processing}
                                onClick={(e) => {
                                  setConfirmDialog(true);
                                }}
                              >
                                Save changes
                              </LoadingButton>
                            </Box>
                          </Box>
                        </Box>
                      </Grid>
                    </Grid>

                    <Dialog
                      open={confirmDialog}
                      onClose={() => setConfirmDialog(false)}
                      aria-labelledby="alert-dialog-title"
                      aria-describedby="alert-dialog-description"
                    >
                      <DialogTitle id="alert-dialog-title">Are you sure?</DialogTitle>
                      <DialogContent>
                        <Box>
                          <DialogContentText sx={{ mb: 1 }} id="alert-dialog-description">
                            Are you sure you want to update these settings?
                          </DialogContentText>
                        </Box>
                      </DialogContent>
                      <DialogActions>
                        <LoadingButton disableElevation loading={processing} onClick={() => setConfirmDialog(false)} color="primary">
                          Cancel
                        </LoadingButton>

                        <LoadingButton
                          disableElevation
                          sx={{ color: "#ffffff" }}
                          loading={processing}
                          onClick={(e) => {
                            handleUpdateThresholds(e);
                          }}
                          variant="contained"
                          color="success"
                          autoFocus
                        >
                          Confirm
                        </LoadingButton>
                      </DialogActions>
                    </Dialog>
                  </>
                ) : assessmentType == 4 ? (
                  <>
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={12} md={12} lg={12} xl={6}>
                        <Box sx={{ p: 2 }}>
                          <Box sx={{ p: 2, display: "flex", flexDirection: "column", gap: 2 }}>
                            <Box sx={{ border: 1, borderColor: "divider" }}>
                              <Box sx={{ p: 2, display: "flex", flexDirection: "column", gap: 1 }}>
                                <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                                  <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
                                    <Typography variant="h6">Automatic decline thresholds</Typography>
                                  </Box>
                                  <Box sx={{ display: "flex", flexDirection: "column", gap: 2, width: "50%" }}>
                                    <Box sx={{ display: "flex", justifyContent: "flex-end", width: "100%" }}>
                                      <Box sx={{ display: "flex", flexDirection: "column", gap: 2, width: "100%" }}>
                                        <TextField
                                          label="Individual assessment threshold for automatic decline"
                                          type="number"
                                          min={0}
                                          value={individualAutoDeclineThreshold}
                                          onChange={(e) => setIndividualAutoDeclineThreshold(e.target.value)}
                                          fullWidth
                                        />

                                        <TextField
                                          label="Corporation assessment threshold for automatic decline"
                                          type="number"
                                          min={0}
                                          value={corporateAutoDeclineThreshold}
                                          onChange={(e) => setCorporateAutoDeclineThreshold(e.target.value)}
                                          fullWidth
                                        />
                                      </Box>
                                    </Box>
                                  </Box>
                                </Box>
                              </Box>
                            </Box>

                            <Box sx={{ border: 1, borderColor: "divider" }}>
                              <Box sx={{ p: 2, display: "flex", flexDirection: "column", gap: 1 }}>
                                <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                                  <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
                                    <Typography variant="h6">Risk assessment approvals</Typography>
                                  </Box>
                                  <Box sx={{ display: "flex", flexDirection: "column", gap: 2, width: "50%" }}>
                                    <Box sx={{ display: "flex", justifyContent: "flex-end", width: "100%" }}>
                                      <Box sx={{ display: "flex", flexDirection: "column", gap: 2, width: "100%" }}>
                                        <TextField
                                          label={`Approvals required to approve ${terminologyData.tier_one_label} assessments`}
                                          type="number"
                                          min={1}
                                          value={tierOneApprovals}
                                          onChange={(e) => setTierOneApprovals(e.target.value)}
                                          fullWidth
                                        />

                                        <Collapse in={tierTwoActive} exit={!tierTwoActive}>
                                          <TextField
                                            label={`Approvals required to approve ${terminologyData.tier_two_label} assessments`}
                                            type="number"
                                            min={1}
                                            value={tierTwoApprovals}
                                            onChange={(e) => setTierTwoApprovals(e.target.value)}
                                            fullWidth
                                          />
                                        </Collapse>

                                        <TextField
                                          label={`Approvals required to approve ${terminologyData.tier_three_label} assessments`}
                                          type="number"
                                          min={1}
                                          value={tierThreeApprovals}
                                          onChange={(e) => setTierThreeApprovals(e.target.value)}
                                          fullWidth
                                        />

                                        <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
                                          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                                            <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
                                              <Typography variant="subtitle2">Self approval</Typography>
                                            </Box>
                                            <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                                              <ToggleButtonGroup
                                                size="small"
                                                color={selfApproval ? "danger" : "primary"}
                                                value={selfApproval}
                                                exclusive
                                                onChange={(e, newValue) => {
                                                  if (newValue !== null) setSelfApproval(newValue);
                                                }}
                                                aria-label="Self approval active"
                                              >
                                                <ToggleButton value={true}>Enabled</ToggleButton>
                                                <ToggleButton value={false}>Disabled</ToggleButton>
                                              </ToggleButtonGroup>
                                            </Box>
                                          </Box>
                                        </Box>
                                      </Box>
                                    </Box>
                                  </Box>
                                </Box>
                              </Box>
                            </Box>

                            <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                              <LoadingButton
                                disableElevation
                                variant="contained"
                                color="success"
                                loading={processing}
                                onClick={(e) => {
                                  setConfirmDialog(true);
                                }}
                              >
                                Save changes
                              </LoadingButton>
                            </Box>
                          </Box>
                        </Box>
                      </Grid>
                    </Grid>

                    <Dialog
                      open={confirmDialog}
                      onClose={() => setConfirmDialog(false)}
                      aria-labelledby="alert-dialog-title"
                      aria-describedby="alert-dialog-description"
                    >
                      <DialogTitle id="alert-dialog-title">Are you sure?</DialogTitle>
                      <DialogContent>
                        <Box>
                          <DialogContentText sx={{ mb: 1 }} id="alert-dialog-description">
                            Are you sure you want to update these settings?
                          </DialogContentText>
                        </Box>
                      </DialogContent>
                      <DialogActions>
                        <LoadingButton disableElevation loading={processing} onClick={() => setConfirmDialog(false)} color="primary">
                          Cancel
                        </LoadingButton>

                        <LoadingButton
                          disableElevation
                          sx={{ color: "#ffffff" }}
                          loading={processing}
                          onClick={(e) => {
                            handleUpdateApprovals(e);
                          }}
                          variant="contained"
                          color="success"
                          autoFocus
                        >
                          Confirm
                        </LoadingButton>
                      </DialogActions>
                    </Dialog>
                  </>
                ) : (
                  <>
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={12} md={12} lg={12} xl={6}>
                        <Box sx={{ p: 2 }}>
                          <Box sx={{ p: 2, display: "flex", flexDirection: "column", gap: 2 }}>
                            <Box sx={{ border: 1, borderColor: "divider" }}>
                              <Box sx={{ p: 2, display: "flex", flexDirection: "column", gap: 1 }}>
                                <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                                  <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
                                    <Typography variant="h6">Interface terminology</Typography>
                                  </Box>
                                  <Box sx={{ display: "flex", flexDirection: "column", gap: 2, width: "50%" }}>
                                    <Box sx={{ display: "flex", justifyContent: "flex-end", width: "100%" }}>
                                      <Box sx={{ display: "flex", flexDirection: "column", gap: 2, width: "100%" }}>
                                        <TextField
                                          label="Tier one label"
                                          type="text"
                                          value={tierOneLabel}
                                          onChange={(e) => setTierOneLabel(e.target.value)}
                                          fullWidth
                                        />
                                        <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
                                          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                                            <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
                                              <Typography variant="subtitle2">Tier two</Typography>
                                            </Box>
                                            <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                                              <ToggleButtonGroup
                                                size="small"
                                                color={tierTwoActive ? "success" : "primary"}
                                                value={tierTwoActive}
                                                exclusive
                                                onChange={(e, newValue) => {
                                                  if (newValue !== null) setTierTwoActive(newValue);
                                                }}
                                                aria-label="Tier two active"
                                              >
                                                <ToggleButton value={true}>Enabled</ToggleButton>
                                                <ToggleButton value={false}>Disabled</ToggleButton>
                                              </ToggleButtonGroup>
                                            </Box>
                                          </Box>
                                          <Collapse in={tierTwoActive} exit={!tierTwoActive}>
                                            <TextField
                                              label="Tier two label"
                                              type="text"
                                              value={tierTwoLabel}
                                              onChange={(e) => setTierTwoLabel(e.target.value)}
                                              fullWidth
                                            />
                                          </Collapse>
                                        </Box>

                                        <TextField
                                          label="Tier three label"
                                          type="text"
                                          value={tierThreeLabel}
                                          onChange={(e) => setTierThreeLabel(e.target.value)}
                                          fullWidth
                                        />
                                      </Box>
                                    </Box>
                                  </Box>
                                </Box>
                              </Box>
                            </Box>

                            <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                              <LoadingButton
                                disableElevation
                                variant="contained"
                                color="success"
                                loading={processing}
                                onClick={(e) => {
                                  setConfirmDialog(true);
                                }}
                              >
                                Save changes
                              </LoadingButton>
                            </Box>
                          </Box>
                        </Box>
                      </Grid>
                    </Grid>

                    <Dialog
                      open={confirmDialog}
                      onClose={() => setConfirmDialog(false)}
                      aria-labelledby="alert-dialog-title"
                      aria-describedby="alert-dialog-description"
                    >
                      <DialogTitle id="alert-dialog-title">Are you sure?</DialogTitle>
                      <DialogContent>
                        <Box>
                          <DialogContentText sx={{ mb: 1 }} id="alert-dialog-description">
                            Are you sure you want to update these settings?
                          </DialogContentText>
                        </Box>
                      </DialogContent>
                      <DialogActions>
                        <LoadingButton disableElevation loading={processing} onClick={() => setConfirmDialog(false)} color="primary">
                          Cancel
                        </LoadingButton>

                        <LoadingButton
                          disableElevation
                          sx={{ color: "#ffffff" }}
                          loading={processing}
                          onClick={(e) => {
                            handleUpdateTerminology(e);
                          }}
                          variant="contained"
                          color="success"
                          autoFocus
                        >
                          Confirm
                        </LoadingButton>
                      </DialogActions>
                    </Dialog>
                  </>
                )}
              </>
            )}
          </Box>

          <Dialog
            open={removeQuestionDialog}
            onClose={() => setRemoveQuestionDialog(false)}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">Are you sure?</DialogTitle>
            <DialogContent>
              <Box>
                <DialogContentText sx={{ mb: 1 }} id="alert-dialog-description">
                  Are you sure you want to remove the question?
                </DialogContentText>
              </Box>
            </DialogContent>
            <DialogActions>
              <LoadingButton disableElevation loading={processing} onClick={() => setRemoveQuestionDialog(false)} color="primary">
                Cancel
              </LoadingButton>

              <LoadingButton
                disableElevation
                sx={{ color: "#ffffff" }}
                loading={processing}
                onClick={(e) => {
                  handleRemoveQuestion();
                }}
                variant="contained"
                color="danger"
                autoFocus
              >
                Confirm
              </LoadingButton>
            </DialogActions>
          </Dialog>
        </>
      ) : (
        <Box>
          <Lottie speed={2.0} options={defaultOptions} height={200} width={200} />
        </Box>
      )}
    </>
  );
};

export default RiskAssessment;
