import { React, useState } from "react";
import { useNavigate } from "react-router-dom";

import Lottie from "react-lottie";
import errorLottie from "../components/lotties/error.json";

import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Avatar from "@mui/material/Avatar";
import CssBaseline from "@mui/material/CssBaseline";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Button from "@mui/lab/LoadingButton";

function Copyright(props) {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {"Copyright © Cleverli.com "}
      {new Date().getFullYear()}
    </Typography>
  );
}

const NoPage = () => {
  const navigate = useNavigate();
  const [processing, setProcessing] = useState(false);

  return (
    <>
      <CssBaseline />
      <Grid container component="main" sx={{ height: "100vh" }}>
        <Grid align="center" sx={{ width: "fit-content", marginLeft: "auto", marginRight: "auto" }}>
          <Box
            sx={{
              my: 8,
              mx: 4,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Box component="img" sx={{ width: "80%" }} alt="Logo" src="/cleverli-logo-black.svg" />

            <Typography component="h1" variant="h1" sx={{ textTransform: "uppercase" }}>
              <strong>404</strong>
            </Typography>

            <Lottie options={{ loop: false, autoplay: true, animationData: errorLottie }} height={200} width={200} />

            <Typography component="h1" variant="h5" sx={{ mt: 4, textTransform: "uppercase" }}>
              <strong>That page cannot be found</strong>
            </Typography>

            <>
              <Box component="div" sx={{ mt: 1 }}>
                <Button onClick={(e) => navigate("/")} loading={processing} type="button" fullWidth variant="contained" sx={{ mt: 3, mb: 2 }}>
                  Home
                </Button>

                <Copyright sx={{ mt: 5 }} />
              </Box>
            </>
          </Box>
        </Grid>
      </Grid>
    </>
  );
};

export default NoPage;
