import { React, useEffect, useState } from "react";

import partner from "../../api/partner";
import authHeader from "../../api/authHeader";

import HandleError from "../../components/functions/HandleError";

import moment from "moment";
import DataTable from "react-data-table-component";

import Lottie from "react-lottie";
import loadingLottie from "../../components/lotties/loading-dots.json";

import { Box, Chip } from "@mui/material";

const Settings = ({ partnerUser, partnerData, caseData, entityData }) => {
  const useAuthHeader = authHeader();
  const handleError = HandleError();

  const [isLoaded, setIsLoaded] = useState(false);

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: loadingLottie,
  };

  return (
    <>
      {isLoaded ? (
        <Box></Box>
      ) : (
        <Box>
          <Lottie speed={2.0} options={defaultOptions} height={200} width={200} />
        </Box>
      )}
    </>
  );
};

export default Settings;
