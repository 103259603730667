import { React, useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import partner from "../../api/partner";
import authHeader from "../../api/authHeader";

import HandleError from "../../components/functions/HandleError";

import { signal } from "@preact/signals-react";
import Lottie from "react-lottie";
import loadingLottie from "../../components/lotties/loading-dots.json";

import { CssBaseline, Box, Divider, Typography, Button, Chip, Grid } from "@mui/material";
import NotesIcon from "@mui/icons-material/Notes";

import KycCaseNav from "./KycCaseNav";
import Overview from "./Overview";
import PersonalDetails from "./PersonalDetails";
import Address from "./Address";
import Identity from "./Identity";
import ProofOfLife from "./ProofOfLife";
import Background from "./Background";
import Fatca from "./Fatca";
import Enhanced from "./Enhanced";
import Logs from "./Logs";
import Settings from "./Settings";
import Invites from "./Invites";
import Notes from "./Notes";
import PepDeclaration from "./PepDeclaration";
import SourceOfWealth from "./SourceOfWealth";

const activeView = signal(0);
const partnerUser = signal({});
const partnerData = signal({});
const caseData = signal({});

const entityData = signal({});
const activeNote = signal({});

const KycCase = () => {
  const { t } = useParams();

  const useAuthHeader = authHeader();
  const handleError = HandleError();

  const [navOpen, setNavOpen] = useState(true);
  const [notesOpen, setNotesOpen] = useState(false);

  const [userLoaded, setUserLoaded] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);

  const [telephoneData, setTelephoneData] = useState();
  const [addressData, setAddressData] = useState();
  const [previousNames, setPreviousNames] = useState([]);

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: loadingLottie,
  };

  const getCase = async (caseUUID) => {
    try {
      const response = await partner.post(
        `/v1/screening/case/kyc/data`,
        JSON.stringify({
          case_uuid: caseUUID,
        }),
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Basic " + useAuthHeader.base64encodedData,
            service: "partners",
          },

          //withCredentials: true,
        }
      );

      caseData.value = response.data;

      getPerson(response.data.person_uuid);
      getPersonTelephoneData(response.data.person_uuid);
      getPersonAddressData(response.data.person_uuid);
      getPreviousNames(response.data.person_uuid);
    } catch (err) {
      console.log(err);
      handleError(err);
    }
  };

  const getPerson = async (personUUID) => {
    try {
      const response = await partner.post(
        `/v1/entity/people/person/data`,
        JSON.stringify({
          person_uuid: personUUID,
        }),
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Basic " + useAuthHeader.base64encodedData,
            service: "partners",
          },

          //withCredentials: true,
        }
      );

      entityData.value = response.data;
      setIsLoaded(true);
    } catch (err) {
      console.log(err);
      handleError(err);
    }
  };

  const getPersonTelephoneData = async (personUUID) => {
    try {
      const response = await partner.post(
        `/v1/entity/people/person/data/telephone-number`,
        JSON.stringify({
          person_uuid: personUUID,
        }),
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Basic " + useAuthHeader.base64encodedData,
            service: "partners",
          },

          //withCredentials: true,
        }
      );

      setTelephoneData(response.data);
    } catch (err) {
      console.log(err);
      handleError(err);
    }
  };

  const getPersonAddressData = async (personUUID) => {
    try {
      const response = await partner.post(
        `/v1/entity/people/person/data/address`,
        JSON.stringify({
          person_uuid: personUUID,
        }),
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Basic " + useAuthHeader.base64encodedData,
            service: "partners",
          },

          //withCredentials: true,
        }
      );

      setAddressData(response.data);
    } catch (err) {
      console.log(err);
      handleError(err);
    }
  };

  const getPreviousNames = async (personUUID) => {
    try {
      const response = await partner.post(
        `/v1/entity/people/person/data/previous-names`,
        JSON.stringify({
          person_uuid: personUUID,
        }),
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Basic " + useAuthHeader.base64encodedData,
            service: "partners",
          },

          //withCredentials: true,
        }
      );

      if (response.data.results > 0) {
        setPreviousNames(response.data.entries);
      } else {
        setPreviousNames([]);
      }
    } catch (err) {
      console.log(err);
      handleError(err);
    }
  };

  useEffect(() => {
    getCase(t);
  }, []);

  useEffect(() => {
    if (activeView.value === 0) {
      getCase(t);
    }
  }, [activeView.value]);

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />

      <KycCaseNav
        navOpen={navOpen}
        setNavOpen={setNavOpen}
        activeView={activeView}
        partnerUser={partnerUser}
        partnerData={partnerData}
        userLoaded={userLoaded}
        setUserLoaded={setUserLoaded}
      />

      <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
        {isLoaded ? (
          <>
            <Box sx={{ border: 1, borderColor: "divider" }}>
              <Box sx={{ p: 2, display: "flex", justifyContent: "space-between", gap: 2 }}>
                <Box sx={{ display: "flex", gap: 4 }}>
                  <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
                    <Typography variant="h6">
                      KYC case - {entityData.value.first_name} {entityData.value.last_name}
                    </Typography>
                  </Box>

                  {entityData.value.is_pep && (
                    <Box
                      sx={{ display: "flex", flexDirection: "column", justifyContent: "center" }}
                    >
                      <Chip
                        className="square-chip"
                        label="PEP"
                        size="large"
                        sx={{
                          backgroundColor: "#C83E4D",
                          color: "#ffffff",
                          textTransform: "uppercase",
                        }}
                      />
                    </Box>
                  )}
                </Box>

                <Box>
                  <Button
                    startIcon={<NotesIcon />}
                    variant="outlined"
                    color="primary"
                    onClick={() => {
                      setNotesOpen(true);
                    }}
                  >
                    Notes
                  </Button>
                </Box>
              </Box>
              <Divider />
              <Box>
                {activeView.value === 0 && (
                  <Overview
                    partnerUser={partnerUser}
                    partnerData={partnerData}
                    caseData={caseData}
                    getCase={() => {
                      getCase(t);
                    }}
                    entityData={entityData}
                    addressData={addressData}
                    telephoneData={telephoneData}
                    previousNames={previousNames}
                  />
                )}
                {activeView.value === 1 && (
                  <PersonalDetails
                    partnerUser={partnerUser}
                    partnerData={partnerData}
                    caseData={caseData}
                    entityData={entityData}
                    telephoneData={telephoneData}
                    getPerson={() => {
                      getPerson(entityData.value.person_uuid);
                    }}
                    getTelephoneData={() => {
                      getPersonTelephoneData(entityData.value.person_uuid);
                    }}
                    previousNames={previousNames}
                    getPreviousNames={() => {
                      getPreviousNames(entityData.value.person_uuid);
                    }}
                    getCase={() => {
                      getCase(t);
                    }}
                  />
                )}
                {activeView.value === 2 && (
                  <Address
                    partnerUser={partnerUser}
                    partnerData={partnerData}
                    caseData={caseData}
                    entityData={entityData}
                    addressData={addressData}
                    getAddress={() => {
                      getPersonAddressData(entityData.value.person_uuid);
                    }}
                    getCase={() => {
                      getCase(t);
                    }}
                  />
                )}
                {activeView.value === 3 && (
                  <Identity
                    partnerUser={partnerUser}
                    partnerData={partnerData}
                    caseData={caseData}
                    entityData={entityData}
                    getCase={() => {
                      getCase(t);
                    }}
                  />
                )}
                {activeView.value === 4 && (
                  <ProofOfLife
                    partnerUser={partnerUser}
                    partnerData={partnerData}
                    caseData={caseData}
                    entityData={entityData}
                    getCase={() => {
                      getCase(t);
                    }}
                  />
                )}
                {activeView.value === 5 && (
                  <Background
                    partnerUser={partnerUser}
                    partnerData={partnerData}
                    caseData={caseData}
                    entityData={entityData}
                    getCase={() => {
                      getCase(t);
                    }}
                  />
                )}
                {activeView.value === 6 && (
                  <Fatca
                    partnerUser={partnerUser}
                    partnerData={partnerData}
                    caseData={caseData}
                    entityData={entityData}
                    getCase={() => {
                      getCase(t);
                    }}
                  />
                )}
                {activeView.value === 7 && (
                  <Enhanced
                    partnerUser={partnerUser}
                    partnerData={partnerData}
                    caseData={caseData}
                    entityData={entityData}
                    getCase={() => {
                      getCase(t);
                    }}
                  />
                )}
                {activeView.value === 8 && (
                  <Logs
                    partnerUser={partnerUser}
                    partnerData={partnerData}
                    caseData={caseData}
                    entityData={entityData}
                    getCase={() => {
                      getCase(t);
                    }}
                  />
                )}
                {activeView.value === 9 && (
                  <Settings
                    partnerUser={partnerUser}
                    partnerData={partnerData}
                    caseData={caseData}
                    entityData={entityData}
                    getCase={() => {
                      getCase(t);
                    }}
                  />
                )}
                {activeView.value === 10 && (
                  <Invites
                    partnerUser={partnerUser}
                    partnerData={partnerData}
                    caseData={caseData}
                    entityData={entityData}
                    getCase={() => {
                      getCase(t);
                    }}
                  />
                )}
                {activeView.value === 11 && (
                  <PepDeclaration
                    partnerUser={partnerUser}
                    partnerData={partnerData}
                    caseData={caseData}
                    entityData={entityData}
                    getCase={() => {
                      getCase(t);
                    }}
                  />
                )}
                {activeView.value === 12 && (
                  <SourceOfWealth
                    partnerUser={partnerUser}
                    partnerData={partnerData}
                    caseData={caseData}
                    entityData={entityData}
                    getCase={() => {
                      getCase(t);
                    }}
                  />
                )}

                <Notes
                  partnerUser={partnerUser}
                  notesOpen={notesOpen}
                  setNotesOpen={setNotesOpen}
                  entityData={entityData}
                />
              </Box>
            </Box>
          </>
        ) : (
          <Box>
            <Lottie speed={2.0} options={defaultOptions} height={200} width={200} />
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default KycCase;
