import { Box, Button, FormControlLabel, Stack, Switch, Typography } from "@mui/material";
import PropTypes from "prop-types";
import { NoteHeader } from "./note-header";
import { NoteContent } from "./note-content";
import { NoteControls } from "./note-controls";
import { notesPropType } from "./propTypes";
import { NoteModal } from "./note-modal";
import { Fragment, useState } from "react";
import { NoteHistoryModal } from "./note-history-modal";
import { RenderWhen } from "../render-when";
import Lottie from "react-lottie";
import emptyNotePlaceholder from "../../components/lotties/empty-note-placeholder.json";

export function NotesConversation({ notes, userUUID, updateTaskData, taskData }) {
  const [noteModal, setNoteModal] = useState({
    note: null,
    open: false,
    noteType: "note", // note or reply
    noteUUID: null,
  });
  const [noteHistoryModal, setNoteHistoryModal] = useState({
    noteUUID: null,
    open: false,
  });
  const [showReplies, setShowReplies] = useState(true);
  const isThereAnyReplies = notes?.some((note) => note?.replies?.length > 0);

  const handleNoteHistoryModalClose = () => {
    setNoteHistoryModal({
      noteUUID: null,
      open: false,
    });
  };

  const handleOpenNoteHistoryModal = (noteUUID) => {
    setNoteHistoryModal({
      noteUUID,
      open: true,
    });
  };

  const handleNoteModalClose = () => {
    setNoteModal({
      note: null,
      open: false,
    });
    updateTaskData();
  };

  /**
   *
   * @param {string} note
   * @param {"note" | "reply"} noteType
   */
  const handleOpenNoteModal = (note, noteType, noteUUID) => {
    setNoteModal({
      note,
      open: true,
      noteType: noteType,
      noteUUID: noteUUID,
    });
  };

  return (
    <>
      <Stack
        direction="column"
        sx={{
          border: 1,
          borderColor: "divider",
          borderRadius: 1,
        }}
      >
        <Stack
          direction="row"
          justifyContent="space-between"
          sx={{
            p: 2,
            borderColor: "divider",
            borderBottomWidth: 1,
            borderBottomStyle: "solid",
          }}
        >
          <Typography variant="h6">Notes</Typography>
          <Stack direction="row">
            <RenderWhen when={isThereAnyReplies}>
              <FormControlLabel
                control={
                  <Switch checked={showReplies} onClick={() => setShowReplies(!showReplies)} />
                }
                label={`Show replies`}
              />
            </RenderWhen>
            <Button variant="contained" onClick={() => handleOpenNoteModal(undefined, "note")}>
              Add Note
            </Button>
          </Stack>
        </Stack>
        <Stack direction="column" spacing={[0, 1]}>
          <RenderWhen when={!notes?.length}>
            <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ p: 2 }}>
              <Typography variant="body1">No notes available</Typography>
              <Box sx={{ width: 100 }}>
                <Lottie
                  options={{ loop: true, autoplay: true, animationData: emptyNotePlaceholder }}
                  // style={{ margin: "0" }}
                />
              </Box>
            </Stack>
          </RenderWhen>
          {notes?.map((note) => {
            const hasReplies = note?.replies && note?.replies?.length > 0;
            return (
              <Stack
                direction="column"
                key={note.uuid}
                sx={{
                  borderTopWidth: 1,
                  borderBottomWidth: 1,
                  borderTopStyle: "solid",
                  borderBottomStyle: "solid",
                  borderColor: "divider",
                  ":last-of-type": {
                    borderBottomWidth: 0,
                  },
                }}
              >
                <NoteHeader note={note} />
                <NoteContent note={note} />
                <NoteControls
                  showReplies={showReplies}
                  repliesCount={note?.replies?.length}
                  hasHistory={note?.version > 1}
                  isEditable={userUUID === note?.owner_uuid}
                  hideBottomBorder={!hasReplies || !showReplies}
                  onReply={() => handleOpenNoteModal(undefined, "reply", note?.uuid)}
                  onEdit={() => {
                    handleOpenNoteModal(note, "note");
                  }}
                  onViewHistory={() => handleOpenNoteHistoryModal(note?.uuid)}
                />
                {hasReplies && showReplies && (
                  <>
                    <Stack
                      sx={{
                        p: 1,
                        borderColor: "divider",
                        borderBottomWidth: 1,
                        borderBottomStyle: "solid",
                        bgcolor: "#0b0b0b",
                        color: "#ffffff",
                      }}
                    >
                      <Typography variant="subtitle2">Replies</Typography>
                    </Stack>
                    {note?.replies?.map((reply) => {
                      return (
                        <Fragment key={reply.uuid}>
                          <NoteHeader note={reply} />
                          <NoteContent note={reply} />
                          <NoteControls
                            hasHistory={reply?.version > 1}
                            isReply
                            // for some reason reply?.owner_uuid is not the same as userUUID
                            // but btoa(reply?.owner_uuid) === btoa(userUUID) works!!!
                            isEditable={btoa(reply?.owner_uuid) === btoa(userUUID)}
                            onEdit={() => {
                              handleOpenNoteModal(reply, "reply", note?.uuid);
                            }}
                            onViewHistory={() => handleOpenNoteHistoryModal(reply?.uuid)}
                          />
                        </Fragment>
                      );
                    })}
                  </>
                )}
              </Stack>
            );
          })}
        </Stack>
      </Stack>
      <NoteModal
        note={noteModal?.note}
        open={noteModal.open}
        onClose={handleNoteModalClose}
        taskData={taskData}
        noteType={noteModal.noteType}
        noteUUID={noteModal?.noteUUID}
      />
      <NoteHistoryModal
        open={noteHistoryModal?.open}
        onClose={handleNoteHistoryModalClose}
        noteUUID={noteHistoryModal?.noteUUID}
      />
    </>
  );
}

NotesConversation.propTypes = {
  notes: PropTypes.arrayOf(notesPropType),
  userUUID: PropTypes.string,
  updateTaskData: PropTypes.func,
  taskData: PropTypes.object,
};
