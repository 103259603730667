import { React, useEffect, useState } from "react";

import partner from "../../../../../api/partner";
import authHeader from "../../../../../api/authHeader";

import HandleError from "../../../../../components/functions/HandleError";
import HandleToast from "../../../../../components/functions/HandleToast";

import Lottie from "react-lottie";
import loadingLottie from "../../../../../components/lotties/loading-dots.json";

import {
  Box,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Typography,
  Collapse,
  Divider,
  Paper,
  Grid,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";

const Submit = ({ clientData, entityData, applicationData, setOpenAccount, getAccounts }) => {
  const [isLoaded, setIsLoaded] = useState(false);

  const [processing, setProcessing] = useState(false);

  const useAuthHeader = authHeader();
  const handleError = HandleError();
  const displayToast = HandleToast();

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: loadingLottie,
  };

  const [leadApplicantOptions, setLeadApplicantOptions] = useState([]);
  const [leadApplicant, setLeadApplicant] = useState("");

  const handleSubmitApplication = async (e) => {
    e.preventDefault();
    setProcessing(true);

    try {
      const response = await partner.post(
        `/v1/client/banking/apply/submit`,
        JSON.stringify({
          client_uuid: clientData.value.client_uuid,
          entity_uuid: clientData.value.entity_uuid,
          entity_type: clientData.value.entity_type,
          primary_individual: leadApplicant,
          application_uuid: applicationData?.application_uuid
            ? applicationData.application_uuid
            : "",
        }),
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Basic " + useAuthHeader.base64encodedData,
            service: "partners",
          },

          // withCredentials: true,
        }
      );

      getAccounts();

      displayToast("Account application submitted successfully", () => {
        setProcessing(false);
        setOpenAccount(false);
      });
    } catch (err) {
      console.log(err);
      handleError(err);
      setProcessing(false);
    }
  };

  const getLeadApplicants = async () => {
    try {
      const response = await partner.post(
        `/v1/client/banking/apply/lead-applicants`,
        JSON.stringify({
          corporation_uuid: entityData.value.corporation_uuid,
        }),
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Basic " + useAuthHeader.base64encodedData,
            service: "partners",
          },

          // withCredentials: true,
        }
      );

      console.log(response.data);

      if (response.data.results > 0) {
        setLeadApplicantOptions(response.data.entries);
      } else {
        setLeadApplicantOptions([]);
      }
      setIsLoaded(true);
    } catch (err) {
      console.log(err);
      handleError(err);
      setProcessing(false);
    }
  };

  useEffect(() => {
    getLeadApplicants();
  }, []);

  return isLoaded ? (
    <Grid container spacing={2}>
      <Grid item xs={12} lg={12}>
        <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
          <FormControl fullWidth>
            <InputLabel id="lead-applicant-label">Select a lead applicant</InputLabel>

            <Select
              labelId="lead-applicant-label"
              id="lead-applicant-select"
              value={leadApplicant}
              label="Lead applicant"
              onChange={(e) => setLeadApplicant(e.target.value)}
            >
              <MenuItem value="" disabled>
                Select an option
              </MenuItem>
              {leadApplicantOptions?.length > 0 &&
                leadApplicantOptions?.map((option, index) => (
                  <MenuItem key={index} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>

          <Paper variant="elevation" elevation={3} sx={{ p: 2 }}>
            <Typography variant="h6">Submit application</Typography>

            <Box sx={{ display: "flex", flexDirection: "column", gap: 1, mt: 2 }}>
              <Typography variant="body1">
                Please review the all information to ensure all details are correct before
                submitting your application.
              </Typography>
              <Typography variant="body1">
                Once you have submitted your application, you will be unable to make any changes.
              </Typography>
            </Box>

            <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "flex-end", mt: 2 }}>
              <LoadingButton
                disableElevation
                disabled={applicationData?.application_uuid != "" ? false : true}
                variant="contained"
                color="success"
                loading={processing}
                onClick={(e) => handleSubmitApplication(e)}
              >
                Submit application
              </LoadingButton>
            </Box>
          </Paper>
        </Box>
      </Grid>
    </Grid>
  ) : (
    <Box>
      <Lottie speed={2.0} options={defaultOptions} height={200} width={200} />
    </Box>
  );
};

export default Submit;
