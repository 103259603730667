import PropTypes from "prop-types";

export function RenderWhen({ children, when }) {
  return when ? children : null;
}

RenderWhen.propTypes = {
  children: PropTypes.node,
  when: PropTypes.bool,
};
