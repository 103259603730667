import { Edit, History, Reply } from "@mui/icons-material";
import { IconButton, Stack, Typography } from "@mui/material";
import PropTypes from "prop-types";
import { RenderWhen } from "../render-when";

export function NoteControls({
  isReply,
  isEditable,
  hasHistory,
  hideBottomBorder,
  onReply,
  onEdit,
  onViewHistory,
  showReplies,
  repliesCount,
}) {
  return (
    <Stack
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      sx={{
        p: 1,
        borderColor: "divider",
        borderBottomWidth: isReply || hideBottomBorder ? 0 : 1,
        borderBottomStyle: "solid",
      }}
    >
      <RenderWhen when={!showReplies && repliesCount > 0}>
        <Typography variant="body2" fontWeight={600}>
          Replies: {repliesCount}
        </Typography>
      </RenderWhen>
      <Stack direction="row" sx={{ ml: "auto" }}>
        {!isReply && (
          <IconButton title="Reply" aria-label="Replay" onClick={onReply}>
            <Reply />
          </IconButton>
        )}
        {hasHistory && (
          <IconButton title="Version history" aria-label="Version history" onClick={onViewHistory}>
            <History />
          </IconButton>
        )}
        {isEditable && (
          <IconButton title="Edit note" aria-label="Edit reply" onClick={onEdit}>
            <Edit />
          </IconButton>
        )}
      </Stack>
    </Stack>
  );
}

NoteControls.propTypes = {
  isReply: PropTypes.bool,
  isEditable: PropTypes.bool,
  hasHistory: PropTypes.bool,
  hideBottomBorder: PropTypes.bool,
  onReply: PropTypes.func,
  onEdit: PropTypes.func,
  onViewHistory: PropTypes.func,
  showReplies: PropTypes.bool,
  repliesCount: PropTypes.number,
};
