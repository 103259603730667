import { React, useEffect, useState } from "react";

import partner from "../../api/partner";
import authHeader from "../../api/authHeader";

import HandleError from "../../components/functions/HandleError";
import HandleToast from "../../components/functions/HandleToast";

import moment from "moment";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

import Lottie from "react-lottie";
import loadingLottie from "../../components/lotties/loading-dots.json";

import {
  Box,
  Typography,
  Chip,
  Grid,
  Icon,
  IconButton,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Button,
  Collapse,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  DialogContentText,
  ToggleButtonGroup,
  ToggleButton,
  Divider,
  Drawer,
} from "@mui/material";
import {
  CancelScheduleSend,
  Close,
  Delete,
  Edit,
  PermMedia,
  Send,
  ViewAgenda,
  Visibility,
} from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import NoResults from "../../components/functions/NoResults";

const Invites = ({ partnerUser, partnerData, caseData, entityData }) => {
  const useAuthHeader = authHeader();
  const handleError = HandleError();
  const displayToast = HandleToast();

  const [isLoaded, setIsLoaded] = useState(false);
  const [processing, setProcessing] = useState(false);
  const [data, setData] = useState([]);

  const [sendInvite, setSendInvite] = useState(false);
  const [inviteEmail, setInviteEmail] = useState(
    entityData.value.email_address ? entityData.value.email_address : ""
  );
  const [inviteNote, setInviteNote] = useState("");

  const [confirmRevokation, setConfirmRevokation] = useState(false);
  const [revokeUUID, setRevokeUUID] = useState("");

  const [confirmEddRevokation, setConfirmEddRevokation] = useState(false);
  const [revokeEddUUID, setRevokeEddUUID] = useState("");

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: loadingLottie,
  };

  const getInvites = async () => {
    try {
      const response = await partner.post(
        `/v1/screening/case/kyc/invite/list`,
        JSON.stringify({
          case_uuid: caseData.value.case_uuid,
        }),
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Basic " + useAuthHeader.base64encodedData,
            service: "partners",
          },

          //withCredentials: true,
        }
      );

      if (response.data.results > 0) {
        setData(response.data.entries);
      } else {
        setData([]);
      }
      setIsLoaded(true);
    } catch (err) {
      console.log(err);
      handleError(err);
    }
  };

  const handleSendInvite = async (e) => {
    e.preventDefault();
    setProcessing(true);

    try {
      const response = await partner.post(
        `/v1/screening/case/kyc/invite`,
        JSON.stringify({
          case_uuid: caseData.value.case_uuid,
          email_address: inviteEmail,
          content: inviteNote,
        }),
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Basic " + useAuthHeader.base64encodedData,
            service: "partners",
          },

          // withCredentials: true,
        }
      );

      getInvites();

      displayToast("Invite request created successfully", () => {
        setProcessing(false);
        setSendInvite(false);
      });
    } catch (err) {
      console.log(err);
      handleError(err);
      setProcessing(false);
    }
  };

  const handleRevokeInvite = async (e) => {
    e.preventDefault();
    setProcessing(true);

    try {
      const response = await partner.post(
        `/v1/screening/case/kyc/invite/revoke`,
        JSON.stringify({
          case_uuid: caseData.value.case_uuid,
          invite_uuid: revokeUUID,
        }),
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Basic " + useAuthHeader.base64encodedData,
            service: "partners",
          },

          // withCredentials: true,
        }
      );

      getInvites();
      setConfirmRevokation(false);

      displayToast("Invitation revoked successfully", () => {
        setProcessing(false);
      });
    } catch (err) {
      console.log(err);
      handleError(err);
      setProcessing(false);
    }
  };

  const handleRevokeEddInvite = async (e) => {
    e.preventDefault();
    setProcessing(true);

    try {
      const response = await partner.post(
        `/v1/screening/case/kyc/enhanced/revoke/invite`,
        JSON.stringify({
          case_uuid: caseData.value.case_uuid,
          invite_uuid: revokeEddUUID,
        }),
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Basic " + useAuthHeader.base64encodedData,
            service: "partners",
          },

          // withCredentials: true,
        }
      );

      getInvites();
      setConfirmEddRevokation(false);

      displayToast("Invitation revoked successfully", () => {
        setProcessing(false);
      });
    } catch (err) {
      console.log(err);
      handleError(err);
      setProcessing(false);
    }
  };

  useEffect(() => {
    getInvites();
  }, []);

  return (
    <>
      {isLoaded ? (
        <Box>
          <Grid container spacing={2}>
            <Grid item xs={12} xl={12}>
              <Box sx={{}}>
                {!sendInvite ? (
                  <Box
                    sx={{
                      borderRight: { xs: 0, xl: 0 },
                      borderColor: { xs: "divider", xl: "divider" },
                    }}
                  >
                    <Box
                      sx={{
                        borderBottom: 1,
                        borderColor: "divider",
                        px: 2,
                        py: 1,
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <Box
                        sx={{ display: "flex", flexDirection: "column", justifyContent: "center" }}
                      >
                        <Typography variant="h6">Invites</Typography>
                      </Box>
                      <Box>
                        {caseData.value.status == 0 && (
                          <Button
                            variant="outlined"
                            endIcon={<Send />}
                            onClick={() => setSendInvite(true)}
                          >
                            Send invite
                          </Button>
                        )}
                      </Box>
                    </Box>

                    <Box sx={{}}>
                      <Box>
                        {data.length > 0 ? (
                          data.map((inviteData, index) => (
                            <Box
                              key={index}
                              sx={{
                                p: 2,
                                display: "flex",
                                justifyContent: "space-between",
                                borderTop: index > 0 ? 1 : 0,
                                borderColor: "divider",
                              }}
                            >
                              <Box sx={{ display: "flex", flexDirection: "row", gap: 4 }}>
                                <Box>
                                  <Typography variant="subtitle2">
                                    {inviteData.email_address}
                                  </Typography>
                                  <Typography variant="subtitle">
                                    {moment.unix(inviteData.timestamp).format("DD/MM/YYYY HH:mm")}
                                  </Typography>
                                </Box>
                                {inviteData.invite_type == 2 && (
                                  <Box
                                    sx={{
                                      display: "flex",
                                      flexDirection: "column",
                                      justifyContent: "center",
                                    }}
                                  >
                                    <Chip
                                      className="square-chip"
                                      label="EDD Request"
                                      color="warning"
                                      sx={{ textTransform: "uppercase" }}
                                    />
                                  </Box>
                                )}
                              </Box>

                              <Box sx={{ display: "flex", flexDirection: "row", gap: 2 }}>
                                <Box
                                  sx={{
                                    display: "flex",
                                    flexDirection: "column",
                                    justifyContent: "center",
                                  }}
                                >
                                  {inviteData.accepted ? (
                                    <Chip
                                      className="square-chip"
                                      label="Accepted"
                                      color="success"
                                      sx={{ textTransform: "uppercase" }}
                                    />
                                  ) : inviteData.rejected ? (
                                    <Chip
                                      className="square-chip"
                                      label="Rejected"
                                      color="error"
                                      sx={{ textTransform: "uppercase" }}
                                    />
                                  ) : inviteData.seen ? (
                                    <Chip
                                      className="square-chip"
                                      label="Opened"
                                      color="warning"
                                      sx={{ textTransform: "uppercase" }}
                                    />
                                  ) : inviteData.revoked ? (
                                    <Chip
                                      className="square-chip"
                                      label="Revoked"
                                      color="primary"
                                      sx={{ textTransform: "uppercase" }}
                                    />
                                  ) : (
                                    <Chip
                                      className="square-chip"
                                      label="Pending"
                                      color="info"
                                      sx={{ textTransform: "uppercase" }}
                                    />
                                  )}
                                </Box>
                                <Box
                                  sx={{
                                    display: "flex",
                                    flexDirection: "column",
                                    justifyContent: "center",
                                  }}
                                >
                                  {!inviteData.revoked && !inviteData.rejected && (
                                    <IconButton
                                      title="Revoke invite"
                                      onClick={() => {
                                        if (inviteData.invite_type == 2) {
                                          setRevokeEddUUID(inviteData.invite_uuid);
                                          setConfirmEddRevokation(true);
                                        } else {
                                          setRevokeUUID(inviteData.invite_uuid);
                                          setConfirmRevokation(true);
                                        }
                                      }}
                                    >
                                      <CancelScheduleSend />
                                    </IconButton>
                                  )}
                                </Box>
                              </Box>
                            </Box>
                          ))
                        ) : (
                          <NoResults message={`Send the person an invite to collect their data`} />
                        )}
                      </Box>
                    </Box>
                  </Box>
                ) : (
                  <Box>
                    <Box
                      sx={{
                        borderRight: { xs: 0, xl: 0 },
                        borderColor: { xs: "divider", xl: "divider" },
                      }}
                    >
                      <Box
                        sx={{
                          borderBottom: 1,
                          borderColor: "divider",
                          px: 2,
                          py: 1,
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                          }}
                        >
                          <Typography variant="h6">Send a new invite</Typography>
                        </Box>
                        <Box>
                          <IconButton title="Close" onClick={() => setSendInvite(false)}>
                            <Close />
                          </IconButton>
                        </Box>
                      </Box>
                      <Box sx={{ display: "flex", flexDirection: "column" }}>
                        <Box sx={{ p: 2, width: "100%" }}>
                          <TextField
                            fullWidth
                            label="Email address"
                            variant="outlined"
                            value={inviteEmail}
                            onChange={(e) => setInviteEmail(e.target.value)}
                          />
                        </Box>
                        <Box>
                          <Box sx={{ p: 2, borderBottom: 1, borderTop: 1, borderColor: "divider" }}>
                            <Typography variant="subtitle2">
                              Include a note with the invitation
                            </Typography>
                          </Box>

                          <ReactQuill
                            theme="snow"
                            value={inviteNote}
                            onChange={(e) => {
                              setInviteNote(e.target.value);
                            }}
                          />
                        </Box>

                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "flex-end",
                            p: 2,
                            borderTop: 1,
                            borderColor: "divider",
                          }}
                        >
                          <LoadingButton
                            disableElevation
                            variant="contained"
                            loading={processing}
                            color="success"
                            onClick={(e) => handleSendInvite(e)}
                          >
                            Send invite
                          </LoadingButton>
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                )}
                <Dialog
                  open={confirmRevokation}
                  onClose={() => setConfirmRevokation(false)}
                  aria-labelledby="alert-dialog-title"
                  aria-describedby="alert-dialog-description"
                >
                  <DialogTitle id="alert-dialog-title">Are you sure?</DialogTitle>
                  <DialogContent>
                    <Box>
                      <DialogContentText sx={{ mb: 1 }} id="alert-dialog-description">
                        Are you sure you want to revoke the invitation?
                      </DialogContentText>
                    </Box>
                  </DialogContent>
                  <DialogActions>
                    <LoadingButton
                      disableElevation
                      loading={processing}
                      onClick={() => setConfirmRevokation(false)}
                      color="primary"
                    >
                      Cancel
                    </LoadingButton>

                    <LoadingButton
                      disableElevation
                      loading={processing}
                      onClick={(e) => {
                        handleRevokeInvite(e);
                      }}
                      variant="contained"
                      color="success"
                      autoFocus
                    >
                      Confirm
                    </LoadingButton>
                  </DialogActions>
                </Dialog>

                <Dialog
                  open={confirmEddRevokation}
                  onClose={() => setConfirmEddRevokation(false)}
                  aria-labelledby="alert-dialog-title"
                  aria-describedby="alert-dialog-description"
                >
                  <DialogTitle id="alert-dialog-title">Are you sure?</DialogTitle>
                  <DialogContent>
                    <Box>
                      <DialogContentText sx={{ mb: 1 }} id="alert-dialog-description">
                        Are you sure you want to revoke the invitation?
                      </DialogContentText>
                    </Box>
                  </DialogContent>
                  <DialogActions>
                    <LoadingButton
                      disableElevation
                      loading={processing}
                      onClick={() => setConfirmEddRevokation(false)}
                      color="primary"
                    >
                      Cancel
                    </LoadingButton>

                    <LoadingButton
                      disableElevation
                      loading={processing}
                      onClick={(e) => {
                        handleRevokeEddInvite(e);
                      }}
                      variant="contained"
                      color="success"
                      autoFocus
                    >
                      Confirm
                    </LoadingButton>
                  </DialogActions>
                </Dialog>
              </Box>
            </Grid>
          </Grid>
        </Box>
      ) : (
        <Box>
          <Lottie speed={2.0} options={defaultOptions} height={200} width={200} />
        </Box>
      )}
    </>
  );
};

export default Invites;
