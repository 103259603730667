import React from "react";

import { Box, Chip, Divider, Typography } from "@mui/material";

const Overview = ({ userData }) => {
  return (
    <>
      <Box sx={{ width: { xs: "100%", lg: "50%" }, borderRight: { xs: 0, lg: 1 }, borderColor: { xs: "divider", lg: "divider" } }}>
        <Box sx={{ display: "flex", justifyContent: "space-between", gap: 2, p: 2 }}>
          <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
            <Typography variant="body2">Full name</Typography>
          </Box>
          <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
            <Typography variant="subtitle2" sx={{ fontWeight: 600 }}>
              {userData.value.name}
            </Typography>
          </Box>
        </Box>
      </Box>

      <Box sx={{ width: { xs: "100%", lg: "50%" }, borderRight: { xs: 0, lg: 1 }, borderColor: { xs: "divider", lg: "divider" } }}>
        <Divider />
        <Box sx={{ display: "flex", justifyContent: "space-between", gap: 2, p: 2 }}>
          <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
            <Typography variant="body2">Email address</Typography>
          </Box>
          <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
            <Typography variant="subtitle2" sx={{ fontWeight: 600 }}>
              {userData.value.email_address}
            </Typography>
          </Box>
        </Box>
      </Box>

      <Box sx={{ width: { xs: "100%", lg: "50%" }, borderRight: { xs: 0, lg: 1 }, borderColor: { xs: "divider", lg: "divider" } }}>
        <Divider />
        <Box sx={{ display: "flex", justifyContent: "space-between", gap: 2, p: 2 }}>
          <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
            <Typography variant="body2">Telephone number</Typography>
          </Box>
          <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
            <Typography variant="subtitle2" sx={{ fontWeight: 600 }}>
              {userData.value.telephone?.dialling_code != ""
                ? "+" + userData.value.telephone.dialling_code + userData.value.telephone.telephone_number
                : "-"}
            </Typography>
          </Box>
        </Box>
      </Box>

      <Box sx={{ width: { xs: "100%", lg: "50%" }, borderRight: { xs: 0, lg: 1 }, borderColor: { xs: "divider", lg: "divider" } }}>
        <Divider />
        <Box sx={{ display: "flex", justifyContent: "space-between", gap: 2, p: 2 }}>
          <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
            <Typography variant="body2">Account status</Typography>
          </Box>
          <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
            {userData.value.active ? (
              <Chip className="square-chip" label="Active" color="success" sx={{ textTransform: "uppercase" }} />
            ) : (
              <Chip className="square-chip" label="Inactive" color="error" sx={{ textTransform: "uppercase" }} />
            )}
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default Overview;
