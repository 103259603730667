import { React, useState, useEffect } from "react";
import { useParams } from "react-router-dom";

import central from "../../api/central";
import authHeader from "../../api/authHeader";

import CssBaseline from "@mui/material/CssBaseline";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Button from "@mui/lab/LoadingButton";
import Lottie from "react-lottie";
import loadingLottie from "../../components/lotties/loading.json";
import successLottie from "../../components/lotties/success.json";
import errorLottie from "../../components/lotties/error.json";

function Copyright(props) {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {"Copyright © Cleverli.com "}
      {new Date().getFullYear()}
    </Typography>
  );
}

const VerifyEmail = () => {
  const { t } = useParams();
  const useAuthHeader = authHeader();

  const [processing, setProcessing] = useState(false);
  const [activeView, setActiveView] = useState(0); // 0 loading | 1 success | 2 error | 3 authentication

  const [errorOutput, setErrorOutput] = useState("test output");

  const defaultOptions = {
    loop: activeView == 0 ? true : false,
    autoplay: true,
    animationData: activeView == 0 ? loadingLottie : activeView == 1 ? successLottie : errorLottie,
  };

  const handleVerificationLink = async () => {
    try {
      const response = await central.post(
        "/verify-email/" + t,
        JSON.stringify({
          token: t,
        }),
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Basic " + useAuthHeader.base64encodedData,
            service: "partners",
          },
          mode: "no-cors",
          //withCredentials: true,
        }
      );

      setActiveView(1);
    } catch (err) {
      setErrorOutput(err.response.data.trim());
      setActiveView(2);
    }
  };

  useEffect(() => {
    handleVerificationLink();
  }, []);

  return (
    <>
      <CssBaseline />
      <Grid container component="main" sx={{ height: "100vh" }}>
        <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
          <Box
            sx={{
              my: 8,
              mx: 4,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              gap: 4,
            }}
          >
            <Box component="img" sx={{ maxWidth: "300px" }} alt="Logo" src="/cleverli-logo-vertical-black.svg" />

            {activeView == 0 && (
              <Box sx={{ width: "100%" }}>
                <Box
                  variant="div"
                  align="center"
                  alignContent={"center"}
                  sx={{ mt: 1, marginLeft: "auto", marginRight: "auto", width: "fit-content" }}
                >
                  <Lottie options={defaultOptions} height={200} width={250} isStopped={false} isPaused={false} />
                </Box>
                <Typography sx={{ mb: 2, textTransform: "uppercase" }} align="center" variant="h6" gutterBottom>
                  <strong>Verifying your email address</strong>
                </Typography>
              </Box>
            )}

            {activeView == 1 && (
              <Box sx={{ width: "100%" }}>
                <Box
                  variant="div"
                  align="center"
                  alignContent={"center"}
                  sx={{ mt: 1, marginLeft: "auto", marginRight: "auto", width: "fit-content" }}
                >
                  <Lottie options={defaultOptions} height={200} width={250} isStopped={false} isPaused={false} />
                </Box>
                <Typography sx={{ mt: 3, mb: 2, textTransform: "uppercase" }} variant="h6" align="center" gutterBottom>
                  <strong>Verification successful</strong>
                </Typography>
                <Box sx={{ width: "fit-content", mt: 3, mb: 2, marginLeft: "auto", marginRight: "auto" }}>
                  <Button
                    onClick={() => {
                      setProcessing(true);
                      window.opener = null;
                      window.open("", "_self");
                      window.close();
                    }}
                    loading={processing}
                    type="button"
                    variant="contained"
                    sx={{ mt: 3, mb: 2 }}
                  >
                    Close window
                  </Button>
                </Box>
              </Box>
            )}

            {activeView == 2 && (
              <Box sx={{ width: "100%" }}>
                <Box
                  variant="div"
                  align="center"
                  alignContent={"center"}
                  sx={{ mt: 1, marginLeft: "auto", marginRight: "auto", width: "fit-content" }}
                >
                  <Lottie options={defaultOptions} height={200} width={250} isStopped={false} isPaused={false} />
                </Box>
                <Typography sx={{ textTransform: "uppercase" }} variant="h6" align="center" gutterBottom>
                  <strong>Error</strong>
                </Typography>

                <Typography sx={{ textTransform: "uppercase" }} color="danger.main" variant="h6" align="center" gutterBottom>
                  <strong>{errorOutput}</strong>
                </Typography>
                <Box sx={{ width: "fit-content", mt: 3, mb: 2, marginLeft: "auto", marginRight: "auto" }}>
                  <Button
                    onClick={() => {
                      setProcessing(true);
                      window.opener = null;
                      window.open("", "_self");
                      window.close();
                    }}
                    loading={processing}
                    type="button"
                    variant="contained"
                    sx={{ mt: 3, mb: 2 }}
                  >
                    Close window
                  </Button>
                </Box>
              </Box>
            )}

            <Box component="div" sx={{ mt: 1 }}>
              <Copyright sx={{ mt: 5 }} />
            </Box>
          </Box>
        </Grid>

        <Grid
          item
          xs={false}
          sm={4}
          md={7}
          sx={{
            backgroundColor: (t) => (t.palette.mode === "light" ? t.palette.primary.main : t.palette.primary.main),
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        />
      </Grid>
    </>
  );
};

export default VerifyEmail;
