import { React, useState, useEffect } from "react";

import { ToggleButton, ToggleButtonGroup } from "@mui/material";

const YesNo = ({ questionUUID, answersData, setAnswersData }) => {
  const [answer, setAnswer] = useState(null);

  useEffect(() => {
    if (answer !== null) {
      setAnswersData({
        ...answersData,
        [questionUUID]: answer,
      });
    }
  }, [answer]);

  useEffect(() => {
    if (answersData[questionUUID] !== undefined) {
      if (answersData[questionUUID] === "true") {
        setAnswer(true);
      } else {
        setAnswer(false);
      }
    }
  }, []);

  return (
    <ToggleButtonGroup
      color="primary"
      value={answer}
      exclusive
      name={questionUUID}
      onChange={(e, newValue) => {
        setAnswer(newValue);
      }}
      aria-label="Yes or No?"
    >
      <ToggleButton value={true}>Yes</ToggleButton>
      <ToggleButton value={false}>No</ToggleButton>
    </ToggleButtonGroup>
  );
};

export default YesNo;
