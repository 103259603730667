import { React, useEffect, useState } from "react";

import { useNavigate } from "react-router-dom";

import authHeader from "../../../../api/authHeader";

import HandleError from "../../../../components/functions/HandleError";
import HandleToast from "../../../../components/functions/HandleToast";

import Lottie from "react-lottie";
import loadingLottie from "../../../../components/lotties/loading-dots.json";

import { Typography, Box, Button, Divider } from "@mui/material";
import { Grid, TextField, FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { LoadingButton } from "@mui/lab";

const NewGroup = ({ partner, partnerUser, partnerData, getGroups }) => {
  const useAuthHeader = authHeader();
  const navigate = useNavigate();

  const handleError = HandleError();
  const displayToast = HandleToast();

  const [isLoaded, setIsLoaded] = useState(false);
  const [processing, setProcessing] = useState(false);

  const [groupName, setGroupName] = useState("");

  const [ownerOptions, setOwnerOptions] = useState([]);
  const [selectedOwner, setSelectedOwner] = useState("");

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: loadingLottie,
  };

  const getClientOwners = async () => {
    try {
      const response = await partner.post(
        `/v1/users/data/users/with-permission/options`,
        JSON.stringify({
          partner_uuid: partnerUser.value.partner_uuid,
          permission: 13,
        }),
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Basic " + useAuthHeader.base64encodedData,
            service: "partners",
          },

          //withCredentials: true,
        }
      );

      if (response.data.results > 0) {
        setOwnerOptions(response.data.entries);
      } else {
        setOwnerOptions([]);
      }
      setIsLoaded(true);
    } catch (err) {
      console.log(err);
      handleError(err);
    }
  };

  const handleCreateGroup = async (e) => {
    e.preventDefault();
    setProcessing(true);

    try {
      const response = await partner.post(
        `/v1/client/groups/create`,
        JSON.stringify({
          group_name: groupName,
          owner_uuid: selectedOwner,
        }),
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Basic " + useAuthHeader.base64encodedData,
            service: "partners",
          },

          //withCredentials: true,
        }
      );

      getGroups();

      displayToast("Client group created successfully", () => {
        setProcessing(false);
        navigate(`/group/${response.data.group_uuid}`);
      });
    } catch (err) {
      console.log(err);
      handleError(err);
      setProcessing(false);
    }
  };

  useEffect(() => {
    getClientOwners();
  }, []);

  return (
    <>
      {isLoaded ? (
        <Box>
          <Box sx={{ p: 2 }}>
            <Typography variant="subtitle2">Group details</Typography>
          </Box>
          <Divider />

          <Box sx={{ display: "flex", flexDirection: "column", gap: 2, p: 2 }}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} md={12} lg={6}>
                <Box sx={{ border: 1, borderColor: "divider" }}>
                  <Box sx={{ p: 2 }}>
                    <Typography variant="body2">
                      <strong>What is the name of the group?</strong>
                    </Typography>
                  </Box>

                  <Divider />

                  <Box sx={{ p: 2 }}>
                    <TextField
                      fullWidth
                      id="outlined-basic"
                      label="Group name"
                      variant="outlined"
                      value={groupName}
                      onChange={(e) => setGroupName(e.target.value)}
                    />
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={6}>
                <Box sx={{ border: 1, borderColor: "divider" }}>
                  <Box sx={{ p: 2 }}>
                    <Typography variant="body2">
                      <strong>Assign the group to an account manager</strong>
                    </Typography>
                  </Box>

                  <Divider />

                  <Box sx={{ p: 2 }}>
                    <FormControl fullWidth>
                      <InputLabel id="owner-label">Group manager</InputLabel>

                      <Select
                        labelId="owner"
                        id="owner"
                        value={selectedOwner}
                        label="Group manager"
                        onChange={(e) => setSelectedOwner(e.target.value)}
                      >
                        {ownerOptions?.map((option, index) => (
                          <MenuItem key={index} value={option.value}>
                            {option.label}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Box>
                </Box>
              </Grid>
            </Grid>
            <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
              <LoadingButton
                disableElevation
                loading={processing}
                variant="contained"
                color="success"
                sx={{ color: "#ffffff" }}
                onClick={(e) => handleCreateGroup(e)}
              >
                Create group
              </LoadingButton>
            </Box>
          </Box>
        </Box>
      ) : (
        <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", height: "50vh" }}>
          <Lottie speed={2.0} options={defaultOptions} height={200} width={200} />
        </Box>
      )}
    </>
  );
};

export default NewGroup;
