import { React, useEffect, useState } from "react";

import partner from "../../../../../api/partner";
import authHeader from "../../../../../api/authHeader";

import HandleError from "../../../../../components/functions/HandleError";

import Lottie from "react-lottie";
import loadingLottie from "../../../../../components/lotties/loading-dots.json";
import parse from "html-react-parser";

import moment from "moment";

import { Box, TextField, FormControl, InputLabel, Select, MenuItem, Typography, Collapse, Divider, Chip } from "@mui/material";
import { FileUpload } from "@mui/icons-material";

const Corporation = ({ clientData, entityData }) => {
  const useAuthHeader = authHeader();
  const handleError = HandleError();

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: loadingLottie,
  };

  const [addressLoaded, setAddressLoaded] = useState(false);
  const [registeredAddress, setRegisteredAddress] = useState({});

  const [activitiesLoaded, setActivitiesLoaded] = useState(false);
  const [businessDescription, setBusinessDescription] = useState("");
  const [industryCodes, setIndustryCodes] = useState([]);
  const [industryConsideration, setIndustryConsideration] = useState("");
  const [links, setLinks] = useState([]);

  const [fatcaLoaded, setFatcaLoaded] = useState(false);
  const [fatcaDisplay, setFatcaDisplay] = useState({});

  const [crsLoaded, setCrsLoaded] = useState(false);
  const [crsDisplay, setCrsDisplay] = useState({});

  const getAddress = async (corporationUUID, addressType) => {
    try {
      const response = await partner.post(
        `/v1/entity/corporations/corporation/data/address`,
        JSON.stringify({
          corporation_uuid: corporationUUID,
          address_type: parseInt(addressType),
        }),
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Basic " + useAuthHeader.base64encodedData,
            service: "partners",
          },

          //withCredentials: true,
        }
      );

      setRegisteredAddress(response.data);
      setAddressLoaded(true);
    } catch (err) {
      console.log(err);
      handleError(err);
    }
  };

  const getActivityData = async () => {
    try {
      const response = await partner.post(
        `/v1/entity/corporations/corporation/data/activities`,
        JSON.stringify({
          corporation_uuid: entityData.value.corporation_uuid ? entityData.value.corporation_uuid : "",
        }),
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Basic " + useAuthHeader.base64encodedData,
            service: "partners",
          },

          //withCredentials: true,
        }
      );

      setBusinessDescription(response.data.business_description);
      setIndustryCodes(response.data.industry_codes ? response.data.industry_codes : []);
      setIndustryConsideration(response.data.industry_consideration);
      setLinks(response.data.links ? response.data.links : []);
      setActivitiesLoaded(true);
    } catch (err) {
      console.log(err);
      handleError(err);
    }
  };

  const getFatcaDeclaration = async () => {
    try {
      const response = await partner.post(
        `/v1/entity/corporations/corporation/data/fatca`,
        JSON.stringify({
          corporation_uuid: entityData.value.corporation_uuid,
        }),
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Basic " + useAuthHeader.base64encodedData,
            service: "partners",
          },

          //withCredentials: true,
        }
      );

      setFatcaDisplay(response.data);
      setFatcaLoaded(true);
    } catch (err) {
      console.log(err);
      handleError(err);
    }
  };

  const getCrsDeclaration = async () => {
    try {
      const response = await partner.post(
        `/v1/entity/corporations/corporation/data/crs`,
        JSON.stringify({
          corporation_uuid: entityData.value.corporation_uuid,
        }),
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Basic " + useAuthHeader.base64encodedData,
            service: "partners",
          },

          //withCredentials: true,
        }
      );

      setCrsDisplay(response.data);
      setCrsLoaded(true);
    } catch (err) {
      console.log(err);
      handleError(err);
    }
  };

  useEffect(() => {
    getAddress(entityData.value.corporation_uuid, 1);
    getActivityData();
    getFatcaDeclaration();
    getCrsDeclaration();
  }, []);

  return (
    <Box sx={{ border: 1, borderColor: "divider" }}>
      {console.log(entityData.value)}
      <Box sx={{ p: 2, backgroundColor: "#0b0b0b", color: "#ffffff" }}>
        <Typography variant="h6">Corporation details</Typography>
      </Box>
      <Divider />
      <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "space-between", gap: 2, p: 2, borderBottom: 1, borderColor: "divider" }}>
        <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
          <Typography variant="subtitle">Registered name</Typography>
        </Box>

        <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center", textAlign: "end" }}>
          <Typography variant="subtitle">{entityData.value.registered_name}</Typography>
        </Box>
      </Box>

      <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "space-between", gap: 2, p: 2, borderBottom: 1, borderColor: "divider" }}>
        <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
          <Typography variant="subtitle">Trading name</Typography>
        </Box>

        <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center", textAlign: "end" }}>
          <Typography variant="subtitle">{entityData.value.trading_name ? entityData.value.trading_name : "-"}</Typography>
        </Box>
      </Box>

      <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "space-between", gap: 2, p: 2, borderBottom: 1, borderColor: "divider" }}>
        <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
          <Typography variant="subtitle">Previous name(s)</Typography>
        </Box>

        <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center", textAlign: "end" }}>
          {entityData.value.previous_names?.length > 0 ? (
            entityData.value.previous_names.map((name, index) => (
              <Typography key={index} variant="subtitle">
                {name}
              </Typography>
            ))
          ) : (
            <Typography variant="subtitle">-</Typography>
          )}
        </Box>
      </Box>

      <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "space-between", gap: 2, p: 2, borderBottom: 1, borderColor: "divider" }}>
        <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
          <Typography variant="subtitle">Registration country</Typography>
        </Box>

        <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center", textAlign: "end" }}>
          <Typography variant="subtitle">{entityData.value.registration_country.name}</Typography>
          {entityData.value.registration_country?.high_risk && (
            <Chip
              className="square-chip"
              label="High risk"
              size="small"
              sx={{ backgroundColor: "#C83E4D", color: "#ffffff", textTransform: "uppercase" }}
            />
          )}
          {entityData.value.registration_country?.conflict && (
            <Chip
              className="square-chip"
              label="Conflict"
              size="small"
              sx={{ backgroundColor: "#FF4A1C", color: "#1C2321", textTransform: "uppercase" }}
            />
          )}
          {entityData.value.registration_country?.borders_conflict && (
            <Chip
              className="square-chip"
              label="Borders conflict"
              size="small"
              sx={{ backgroundColor: "#977390", color: "#1C2321", textTransform: "uppercase" }}
            />
          )}
          {entityData.value.registration_country?.is_secrecy && (
            <Chip
              className="square-chip"
              label="Secrecy"
              size="small"
              sx={{ backgroundColor: "#999999", color: "#1C2321", textTransform: "uppercase" }}
            />
          )}
        </Box>
      </Box>

      <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "space-between", gap: 2, p: 2, borderBottom: 1, borderColor: "divider" }}>
        <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
          <Typography variant="subtitle">Registration number</Typography>
        </Box>

        <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center", textAlign: "end" }}>
          <Typography variant="subtitle">{entityData.value.registration_reference}</Typography>
        </Box>
      </Box>

      <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "space-between", gap: 2, p: 2, borderBottom: 1, borderColor: "divider" }}>
        <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
          <Typography variant="subtitle">Registration date</Typography>
        </Box>

        <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center", textAlign: "end" }}>
          <Typography variant="subtitle">{moment(entityData.value.registration_date).format("DD/MM/YYYY")}</Typography>
        </Box>
      </Box>

      <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "space-between", gap: 2, p: 2, borderBottom: 1, borderColor: "divider" }}>
        <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
          <Typography variant="subtitle">Registred address</Typography>
        </Box>

        <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center", textAlign: "end" }}>
          {addressLoaded ? (
            <Box>
              <Typography variant="subtitle2">{registeredAddress?.line_one ? registeredAddress.line_one : "-"}</Typography>
              {registeredAddress?.line_two && (
                <Typography variant="subtitle2">{registeredAddress.line_two ? registeredAddress.line_two : "-"}</Typography>
              )}
              {registeredAddress?.line_three && (
                <Typography variant="subtitle2">{registeredAddress.line_three ? registeredAddress.line_three : "-"}</Typography>
              )}
              {registeredAddress?.line_four && (
                <Typography variant="subtitle2">{registeredAddress.line_four ? registeredAddress.line_four : "-"}</Typography>
              )}
              {registeredAddress?.line_five && (
                <Typography variant="subtitle2">{registeredAddress.line_five ? registeredAddress.line_five : "-"}</Typography>
              )}
              {registeredAddress?.line_six && (
                <Typography variant="subtitle2">{registeredAddress.line_six ? registeredAddress.line_six : "-"}</Typography>
              )}
              {registeredAddress?.city && <Typography variant="subtitle2">{registeredAddress.city ? registeredAddress.city : "-"}</Typography>}
              {registeredAddress?.postcode && (
                <Typography variant="subtitle2">{registeredAddress.postcode ? registeredAddress.postcode : "-"}</Typography>
              )}
              {registeredAddress?.state && <Typography variant="subtitle2">{registeredAddress.state ? registeredAddress.state : "-"}</Typography>}
              {registeredAddress?.country.code && (
                <Typography variant="subtitle2">{registeredAddress.country.code ? registeredAddress.country.name : "-"}</Typography>
              )}
              {registeredAddress.country?.high_risk && (
                <Chip
                  className="square-chip"
                  label="High risk"
                  size="small"
                  sx={{ backgroundColor: "#C83E4D", color: "#ffffff", textTransform: "uppercase" }}
                />
              )}
              {registeredAddress.country?.conflict && (
                <Chip
                  className="square-chip"
                  label="Conflict"
                  size="small"
                  sx={{ backgroundColor: "#FF4A1C", color: "#1C2321", textTransform: "uppercase" }}
                />
              )}
              {registeredAddress.country?.borders_conflict && (
                <Chip
                  className="square-chip"
                  label="Borders conflict"
                  size="small"
                  sx={{ backgroundColor: "#977390", color: "#1C2321", textTransform: "uppercase" }}
                />
              )}
              {registeredAddress.country?.is_secrecy && (
                <Chip
                  className="square-chip"
                  label="Secrecy"
                  size="small"
                  sx={{ backgroundColor: "#999999", color: "#1C2321", textTransform: "uppercase" }}
                />
              )}
            </Box>
          ) : (
            <Box>
              <Lottie speed={2.0} options={defaultOptions} height={200} width={200} />
            </Box>
          )}
        </Box>
      </Box>

      <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "space-between", gap: 2, p: 2, borderBottom: 1, borderColor: "divider" }}>
        <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
          <Typography variant="subtitle">Registry URL</Typography>
        </Box>

        <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center", textAlign: "end" }}>
          <Typography variant="subtitle">{entityData.value.registry_url ? entityData.value.registry_url : "-"}</Typography>
        </Box>
      </Box>

      <Box sx={{ display: "flex", flexDirection: "column", gap: 2, p: 2, borderBottom: 0, borderColor: "divider" }}>
        <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
          <Typography variant="subtitle">Documents</Typography>
        </Box>

        <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "space-evenly" }}>
          <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", gap: 1 }}>
            <Typography variant="subtitle" sx={{ fontWeight: 600 }}>
              Certificate of Incorporation
            </Typography>
            <Box>
              <FileUpload fontSize="large" />
            </Box>
          </Box>

          <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", gap: 1 }}>
            <Typography variant="subtitle" sx={{ fontWeight: 600 }}>
              Articles of Association
            </Typography>
            <Box>
              <FileUpload fontSize="large" />
            </Box>
          </Box>
        </Box>
      </Box>

      <Box sx={{ p: 2, backgroundColor: "#0b0b0b", color: "#ffffff" }}>
        <Typography variant="h6">Business activities</Typography>
      </Box>

      <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "space-between", gap: 2, p: 2, borderBottom: 1, borderColor: "divider" }}>
        <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
          <Typography variant="subtitle">Business description</Typography>
        </Box>

        <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center", textAlign: "justified", width: "50%" }}>
          {activitiesLoaded ? (
            <Box>{parse(businessDescription)}</Box>
          ) : (
            <Box>
              <Lottie speed={2.0} options={defaultOptions} height={200} width={200} />
            </Box>
          )}
        </Box>
      </Box>

      <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "space-between", gap: 2, p: 2, borderBottom: 1, borderColor: "divider" }}>
        <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
          <Typography variant="subtitle">Industry codes</Typography>
        </Box>

        <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center", textAlign: "end" }}>
          {activitiesLoaded ? (
            <Box>
              {industryCodes?.length > 0 ? (
                industryCodes.map((code, index) => (
                  <Box key={index} sx={{ display: "flex", gap: 2 }}>
                    {code.high_risk && (
                      <Chip
                        className="square-chip"
                        label="High risk"
                        size="small"
                        sx={{ backgroundColor: "#C83E4D", color: "#ffffff", textTransform: "uppercase" }}
                      />
                    )}
                    <Typography variant="subtitle">{code.name}</Typography>
                    <Typography variant="subtitle">-</Typography>
                    <Typography variant="subtitle">{code.code}</Typography>
                  </Box>
                ))
              ) : (
                <Typography variant="subtitle">-</Typography>
              )}
            </Box>
          ) : (
            <Box>
              <Lottie speed={2.0} options={defaultOptions} height={200} width={200} />
            </Box>
          )}
        </Box>
      </Box>

      <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "space-between", gap: 2, p: 2, borderBottom: 0, borderColor: "divider" }}>
        <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
          <Typography variant="subtitle">Industry context</Typography>
        </Box>

        <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center", textAlign: "end" }}>
          {activitiesLoaded ? (
            industryConsideration ? (
              parse(industryConsideration)
            ) : (
              <Typography variant="subtitle">-</Typography>
            )
          ) : (
            <Lottie speed={2.0} options={defaultOptions} height={200} width={200} />
          )}
        </Box>
      </Box>

      <Box sx={{ p: 2, backgroundColor: "#0b0b0b", color: "#ffffff" }}>
        <Typography variant="h6">FATCA, CRS, VAT </Typography>
      </Box>

      <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "space-between", gap: 2, p: 2, borderBottom: 1, borderColor: "divider" }}>
        <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
          <Typography variant="subtitle">FATCA status</Typography>
        </Box>

        <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center", textAlign: "end" }}>
          {fatcaLoaded ? (
            <Box>
              <Box sx={{ display: "flex", flexDirection: "row", gap: 2 }}>
                {fatcaDisplay.reportable && (
                  <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
                    <Chip
                      className="square-chip"
                      label="Reportable"
                      sx={{ backgroundColor: "#C83E4D", color: "#ffffff", textTransform: "uppercase" }}
                    />
                  </Box>
                )}
                <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
                  {fatcaDisplay.entity_type == 1 && (
                    <Typography variant="subtitle2" sx={{}}>
                      Specified US Person
                    </Typography>
                  )}

                  {fatcaDisplay.entity_type == 2 && (
                    <Typography variant="subtitle2" sx={{}}>
                      Non specified US Person
                    </Typography>
                  )}

                  {fatcaDisplay.entity_type == 3 && (
                    <Typography variant="subtitle2" sx={{}}>
                      Non US Person
                    </Typography>
                  )}
                </Box>
              </Box>

              {fatcaDisplay.entity_type == 1 && (
                <Box sx={{ borderTop: 1, borderColor: "divider", display: "flex", justifyContent: "space-between" }}>
                  <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
                    <Typography variant="subtitle" sx={{ fontSize: "13px" }}>
                      TIN
                    </Typography>
                  </Box>
                  <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
                    <Typography variant="subtitle2" sx={{ fontSize: "13px" }}>
                      {fatcaDisplay.tin}
                    </Typography>
                  </Box>
                </Box>
              )}

              {fatcaDisplay.entity_type == 2 && (
                <Box sx={{ borderTop: 1, borderColor: "divider", display: "flex", justifyContent: "space-between" }}>
                  <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center", minWidth: "30%" }}>
                    <Typography variant="subtitle" sx={{ fontSize: "13px" }}>
                      Exemption
                    </Typography>
                  </Box>
                  <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
                    <Typography variant="subtitle2" sx={{ fontSize: "13px" }}>
                      {fatcaDisplay.us_person_exemption_label}
                    </Typography>
                  </Box>
                </Box>
              )}

              {fatcaDisplay.entity_type == 3 && fatcaDisplay.financial_institution && (
                <Box>
                  <Box sx={{ borderTop: 1, borderColor: "divider", display: "flex", justifyContent: "space-between" }}>
                    <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center", minWidth: "30%" }}>
                      <Typography variant="subtitle" sx={{ fontSize: "13px" }}>
                        Financial institution type
                      </Typography>
                    </Box>
                    <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
                      <Typography variant="subtitle2" sx={{ fontSize: "13px" }}>
                        {fatcaDisplay.financial_institution_type_label}
                      </Typography>
                    </Box>
                  </Box>

                  {fatcaDisplay.giin ? (
                    <Box sx={{ borderTop: 1, borderColor: "divider", display: "flex", justifyContent: "space-between" }}>
                      <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center", minWidth: "30%" }}>
                        <Typography variant="subtitle" sx={{ fontSize: "13px" }}>
                          GIIN
                        </Typography>
                      </Box>
                      <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
                        <Typography variant="subtitle2" sx={{ fontSize: "13px" }}>
                          {fatcaDisplay.giin}
                        </Typography>
                      </Box>
                    </Box>
                  ) : (
                    <Box>
                      <Box sx={{ borderTop: 1, borderColor: "divider", display: "flex", justifyContent: "space-between" }}>
                        <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center", minWidth: "30%" }}>
                          <Typography variant="subtitle" sx={{ fontSize: "13px" }}>
                            GIIN Exemption
                          </Typography>
                        </Box>
                        <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
                          <Typography variant="subtitle2" sx={{ fontSize: "13px" }}>
                            {fatcaDisplay.no_giin_reason_label}
                          </Typography>
                        </Box>
                      </Box>

                      {fatcaDisplay.no_giin_reason == 1 && (
                        <Box>
                          <Box sx={{ borderTop: 1, borderColor: "divider", display: "flex", justifyContent: "space-between" }}>
                            <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center", minWidth: "30%" }}>
                              <Typography variant="subtitle" sx={{ fontSize: "13px" }}>
                                GIIN Sponsor
                              </Typography>
                            </Box>
                            <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
                              <Typography variant="subtitle2" sx={{ fontSize: "13px" }}>
                                {fatcaDisplay.sponsor_name}
                              </Typography>
                            </Box>
                          </Box>
                          <Box sx={{ borderTop: 1, borderColor: "divider", display: "flex", justifyContent: "space-between" }}>
                            <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center", minWidth: "30%" }}>
                              <Typography variant="subtitle" sx={{ fontSize: "13px" }}>
                                Sponsors GIIN
                              </Typography>
                            </Box>
                            <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
                              <Typography variant="subtitle2" sx={{ fontSize: "13px" }}>
                                {fatcaDisplay.sponsor_giin}
                              </Typography>
                            </Box>
                          </Box>
                        </Box>
                      )}

                      {fatcaDisplay.no_giin_reason == 2 && (
                        <Box>
                          <Box sx={{ borderTop: 1, borderColor: "divider", display: "flex", justifyContent: "space-between" }}>
                            <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center", minWidth: "30%" }}>
                              <Typography variant="subtitle" sx={{ fontSize: "13px" }}>
                                GIIN Sponsor
                              </Typography>
                            </Box>
                            <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
                              <Typography variant="subtitle2" sx={{ fontSize: "13px" }}>
                                {fatcaDisplay.trustee_name}
                              </Typography>
                            </Box>
                          </Box>
                          <Box sx={{ borderTop: 1, borderColor: "divider", display: "flex", justifyContent: "space-between" }}>
                            <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center", minWidth: "30%" }}>
                              <Typography variant="subtitle" sx={{ fontSize: "13px" }}>
                                Sponsors GIIN
                              </Typography>
                            </Box>
                            <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
                              <Typography variant="subtitle2" sx={{ fontSize: "13px" }}>
                                {fatcaDisplay.trustee_giin}
                              </Typography>
                            </Box>
                          </Box>
                        </Box>
                      )}

                      {fatcaDisplay.no_giin_reason == 3 && (
                        <Box sx={{ borderTop: 1, borderColor: "divider", display: "flex", justifyContent: "space-between" }}>
                          <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center", minWidth: "30%" }}>
                            <Typography variant="subtitle" sx={{ fontSize: "13px" }}>
                              Exemption
                            </Typography>
                          </Box>
                          <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
                            <Typography variant="subtitle2" sx={{ fontSize: "13px" }}>
                              {fatcaDisplay.no_giin_exemption_type_label}
                            </Typography>
                          </Box>
                        </Box>
                      )}
                    </Box>
                  )}
                </Box>
              )}
            </Box>
          ) : (
            <Lottie speed={2.0} options={defaultOptions} height={200} width={200} />
          )}
        </Box>
      </Box>

      <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "space-between", gap: 2, p: 2, borderBottom: 1, borderColor: "divider" }}>
        <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
          <Typography variant="subtitle">CRS status</Typography>
        </Box>

        <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center", textAlign: "end" }}>
          {crsLoaded ? (
            <Box>
              <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
                  {crsDisplay.financial_institution ? (
                    <Typography variant="subtitle2" sx={{}}>
                      The entity is a Financial Institution
                    </Typography>
                  ) : (
                    <Typography variant="subtitle2" sx={{}}>
                      The entity is not a Financial Institution
                    </Typography>
                  )}
                </Box>
                <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center" }}></Box>
              </Box>

              {crsDisplay.financial_institution && (
                <Box>
                  <Box sx={{ borderTop: 1, borderColor: "divider", display: "flex", justifyContent: "space-between" }}>
                    <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
                      <Typography variant="subtitle" sx={{ fontSize: "13px" }}>
                        Reporting status
                      </Typography>
                    </Box>
                    <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
                      <Typography variant="subtitle2" sx={{ fontSize: "13px" }}>
                        {crsDisplay.reporting_financial_institution ? "Reporting" : "Non-reporting"}
                      </Typography>
                    </Box>
                  </Box>
                  <Box sx={{ borderTop: 1, borderColor: "divider", display: "flex", justifyContent: "space-between" }}>
                    <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
                      <Typography variant="subtitle" sx={{ fontSize: "13px" }}>
                        Institution type
                      </Typography>
                    </Box>
                    <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
                      <Typography variant="subtitle2" sx={{ fontSize: "13px" }}>
                        {crsDisplay.reporting_financial_institution
                          ? crsDisplay.reporting_financial_institution_type_label
                          : crsDisplay.non_reporting_financial_institution_type_label}
                      </Typography>
                      {!crsDisplay.reporting_financial_institution && crsDisplay.non_reporting_financial_institution == 9 && (
                        <Typography variant="subtitle2" sx={{ fontSize: "13px" }}>
                          {crsDisplay.non_reporting_financial_institution_type_other}
                        </Typography>
                      )}
                    </Box>
                  </Box>
                </Box>
              )}
            </Box>
          ) : (
            <Lottie speed={2.0} options={defaultOptions} height={200} width={200} />
          )}
        </Box>
      </Box>

      <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "space-between", gap: 2, p: 2, borderBottom: 0, borderColor: "divider" }}>
        <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
          <Typography variant="subtitle">VAT number</Typography>
        </Box>

        <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center", textAlign: "end" }}>
          {fatcaLoaded ? <Box></Box> : <Lottie speed={2.0} options={defaultOptions} height={200} width={200} />}
        </Box>
      </Box>
    </Box>
  );
};

export default Corporation;
