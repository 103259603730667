import PropTypes from "prop-types";
import { Divider, Grid, Stack, Typography } from "@mui/material";
import { useGetTaskAssignmentHistory } from "../../../../../hooks/use-get-task-assignment-history";
import { Fragment } from "react";
import { dateTime } from "../../../../../utils/date";

function TaskHistoryRow({ title, data }) {
  return (
    <Grid container>
      <Grid item xs={6}>
        <Typography variant="body2" fontWeight={600}>
          {title}
        </Typography>
      </Grid>
      <Grid item xs={6}>
        <Typography variant="body1">{data}</Typography>
      </Grid>
    </Grid>
  );
}

export function TaskMetaData({ taskUUID }) {
  const { taskAssignmentHistoryList } = useGetTaskAssignmentHistory(taskUUID);
  return (
    <Stack direction={"column"} sx={{ border: 1, borderColor: "divider", borderRadius: 1 }}>
      <Stack p={1} direction={"row"} justifyContent="space-between" alignItems="center">
        <Typography variant="body1" fontWeight={600}>
          Assigned to:
        </Typography>
      </Stack>
      <Divider />
      <Stack p={1} direction="column" spacing={1}>
        {taskAssignmentHistoryList?.map((history) => {
          return (
            <Fragment key={history?.uuid}>
              <Stack
                direction="row"
                spacing={1}
                alignItems="center"
                justifyContent="space-between"
                sx={{
                  py: 1,
                  borderBottom: 1,
                  borderBottomColor: "divider",
                  borderBottomStyle: "solid",
                  ":last-of-type": {
                    borderBottom: 0,
                  },
                }}
              >
                <Typography variant="body2">{history?.from_name}</Typography>
                <Typography textAlign="right" variant="caption">
                  {dateTime(history?.timestamp)}
                </Typography>
              </Stack>
              {/* <TaskHistoryRow title="To" data={history?.to_name} />
              <TaskHistoryRow title="In" data={dateTime(history?.timestamp)} /> */}
            </Fragment>
          );
        })}
      </Stack>
    </Stack>
  );
}

TaskMetaData.propTypes = {
  taskUUID: PropTypes.string,
};

TaskHistoryRow.propTypes = {
  title: PropTypes.string,
  data: PropTypes.string,
};
