import { React, useEffect, useState } from "react";

import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";

const currencies = [
  { value: "USD", name: "USD - United States Dollar" },
  { value: "EUR", name: "EUR - Euro" },
  { value: "JPY", name: "JPY - Japanese Yen" },
  { value: "GBP", name: "GBP - British Pound" },
  { value: "AUD", name: "AUD - Australian Dollar" },
  { value: "CAD", name: "CAD - Canadian Dollar" },
  { value: "CHF", name: "CHF - Swiss Franc" },
  { value: "CNY", name: "CNY - Chinese Yuan" },
  { value: "SEK", name: "SEK - Swedish Krona" },
  { value: "NZD", name: "NZD - New Zealand Dollar" },
  { value: "MXN", name: "MXN - Mexican Peso" },
  { value: "SGD", name: "SGD - Singapore Dollar" },
  { value: "HKD", name: "HKD - Hong Kong Dollar" },
  { value: "NOK", name: "NOK - Norwegian Krone" },
  { value: "KRW", name: "KRW - South Korean Won" },
  { value: "TRY", name: "TRY - Turkish Lira" },
  { value: "RUB", name: "RUB - Russian Ruble" },
  { value: "INR", name: "INR - Indian Rupee" },
  { value: "BRL", name: "BRL - Brazilian Real" },
  { value: "ZAR", name: "ZAR - South African Rand" },
  { value: "PLN", name: "PLN - Polish Zloty" },
  { value: "IDR", name: "IDR - Indonesian Rupiah" },
  { value: "THB", name: "THB - Thai Baht" },
  { value: "MYR", name: "MYR - Malaysian Ringgit" },
  { value: "PHP", name: "PHP - Philippine Peso" },
  { value: "CZK", name: "CZK - Czech Koruna" },
  { value: "ILS", name: "ILS - Israeli New Shekel" },
  { value: "CLP", name: "CLP - Chilean Peso" },
  { value: "PKR", name: "PKR - Pakistani Rupee" },
  { value: "AED", name: "AED - United Arab Emirates Dirham" },
  { value: "COP", name: "COP - Colombian Peso" },
  { value: "SAR", name: "SAR - Saudi Riyal" },
  { value: "RON", name: "RON - Romanian Leu" },
  { value: "HUF", name: "HUF - Hungarian Forint" },
  { value: "BGN", name: "BGN - Bulgarian Lev" },
  { value: "DKK", name: "DKK - Danish Krone" },
  { value: "FJD", name: "FJD - Fijian Dollar" },
  { value: "JMD", name: "JMD - Jamaican Dollar" },
  { value: "MAD", name: "MAD - Moroccan Dirham" },
  { value: "QAR", name: "QAR - Qatari Riyal" },
  { value: "EGP", name: "EGP - Egyptian Pound" },
  { value: "NAD", name: "NAD - Namibian Dollar" },
  { value: "NPR", name: "NPR - Nepalese Rupee" },
  { value: "OMR", name: "OMR - Omani Rial" },
  { value: "CRC", name: "CRC - Costa Rican Colón" },
  { value: "UYU", name: "UYU - Uruguayan Peso" },
  { value: "BDT", name: "BDT - Bangladeshi Taka" },
  { value: "PEN", name: "PEN - Peruvian Sol" },
  { value: "GEL", name: "GEL - Georgian Lari" },
  { value: "NGN", name: "NGN - Nigerian Naira" },
  { value: "LKR", name: "LKR - Sri Lankan Rupee" },
  { value: "ARS", name: "ARS - Argentine Peso" },
  { value: "HRK", name: "HRK - Croatian Kuna" },
  { value: "UZS", name: "UZS - Uzbekistan Som" },
  { value: "BHD", name: "BHD - Bahraini Dinar" },
  { value: "UAH", name: "UAH - Ukrainian Hryvnia" },
  { value: "VEF", name: "VEF - Venezuelan Bolívar" },
];

const CurrencySelect = ({ value, setValue, isMultiple, isEdd }) => {
  const [selected, setSelected] = useState(isEdd ? isMultiple && [] : null);

  useEffect(() => {
    if (isEdd) {
      setValue({
        ...value,
        [isEdd]: selected,
      });
    }
  }, [selected]);

  useEffect(() => {
    if (isEdd) {
      if (isMultiple) {
        if (value[isEdd] !== null) {
          setSelected(value[isEdd]);
        }
      } else {
        setSelected(value[isEdd]);
      }
    }
  }, []);

  return (
    <Autocomplete
      id="country-select"
      sx={{ width: "100%" }}
      options={currencies}
      value={isEdd ? (selected ? selected : null) : value ? value : null}
      multiple={isMultiple ? isMultiple : false}
      onChange={(event, newValue) => {
        if (isEdd) {
          setSelected(newValue ? newValue : isMultiple ? [] : null);
        } else {
          setValue(newValue ? newValue : isMultiple ? [] : null);
        }
      }}
      autoHighlight
      isOptionEqualToValue={(option, value) => option.value === value.value}
      getOptionLabel={(option) => (option?.name ? option.name : "")}
      renderOption={(props, option) => (
        <Box component="li" sx={{}} {...props}>
          {option.name}
        </Box>
      )}
      renderInput={(params) => (
        <TextField
          {...params}
          label={isMultiple ? "Select currencies" : "Select a currency"}
          inputProps={{
            ...params.inputProps,
            autoComplete: "new-password", // disable autocomplete and autofill
          }}
        />
      )}
    />
  );
};

export default CurrencySelect;
