import { React, useEffect, useState } from "react";

import partner from "../../api/partner";
import central from "../../api/central";
import authHeader from "../../api/authHeader";

import HandleError from "../../components/functions/HandleError";
import HandleToast from "../../components/functions/HandleToast";

import Lottie from "react-lottie";
import loadingLottie from "../../components/lotties/loading-dots.json";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import moment from "moment";
import parse from "html-react-parser";
import DataTable from "react-data-table-component";

import {
  Box,
  Typography,
  Chip,
  Grid,
  Icon,
  IconButton,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Divider,
  Autocomplete,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
} from "@mui/material";
import { Close, Delete, Description, Edit, Facebook } from "@mui/icons-material";

import { LoadingButton } from "@mui/lab";
import { Link } from "react-router-dom";

const Activities = ({ partnerData, partnerUser, caseData, entityData }) => {
  const useAuthHeader = authHeader();
  const handleError = HandleError();
  const displayToast = HandleToast();

  const [locale, setLocale] = useState("en-gb");

  if (moment.locale() != locale) {
    moment.locale(locale);
  }

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: loadingLottie,
  };

  const [isLoaded, setIsLoaded] = useState(false);

  const [processing, setProcessing] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [editType, setEditType] = useState(0);

  const [businessDescription, setBusinessDescription] = useState("");
  const [industryConsideration, setIndustryConsideration] = useState("");
  const [industryCodes, setIndustryCodes] = useState();
  const [industryCodeOptions, setIndustryCodeOptions] = useState([]);
  const [selectedIndustryCode, setSelectedIndustryCode] = useState({});
  const [links, setLinks] = useState();

  const [deleteIndustryCodeDialog, setDeleteIndustryCodeDialog] = useState(false);
  const [deleteIndustryCode, setDeleteIndustryCode] = useState("");

  const [linkUrl, setLinkUrl] = useState("");
  const [selectedLinkType, setSelectedLinkType] = useState();
  const [deleteLinkDialog, setDeleteLinkDialog] = useState(false);
  const [deleteLink, setDeleteLink] = useState("");

  const getActivityData = async () => {
    try {
      const response = await partner.post(
        `/v1/entity/corporations/corporation/data/activities`,
        JSON.stringify({
          corporation_uuid: entityData.value.corporation_uuid
            ? entityData.value.corporation_uuid
            : "",
        }),
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Basic " + useAuthHeader.base64encodedData,
            service: "partners",
          },

          //withCredentials: true,
        }
      );

      setBusinessDescription(response.data.business_description);
      setIndustryCodes(response.data.industry_codes ? response.data.industry_codes : []);
      setIndustryConsideration(response.data.industry_consideration);
      setLinks(response.data.links ? response.data.links : []);
      setIsLoaded(true);
    } catch (err) {
      console.log(err);
      handleError(err);
    }
  };

  const getIndustryOptions = async () => {
    try {
      const response = await central.post(
        `/v1/service/corporations/industry-codes/options`,
        JSON.stringify({ code_type: 0 }),
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Basic " + useAuthHeader.base64encodedData,
            service: "partners",
            partner_uuid: partnerUser.value.partner_uuid,
          },

          //withCredentials: true,
        }
      );

      if (response.data.results > 0) {
        setIndustryCodeOptions(response.data.entries);
      } else {
        setIndustryCodeOptions([]);
      }
    } catch (err) {
      console.log(err);
      handleError(err);
    }
  };

  const handleAddIndustryCode = async (e) => {
    e.preventDefault();
    setProcessing(true);
    try {
      const response = await partner.post(
        `/v1/entity/corporations/corporation/industry-codes/add`,
        JSON.stringify({
          corporation_uuid: entityData.value.corporation_uuid,
          code: selectedIndustryCode,
        }),
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Basic " + useAuthHeader.base64encodedData,
            service: "partners",
          },

          //withCredentials: true,
        }
      );

      getActivityData();

      displayToast("Industry code added successfully", () => {
        setProcessing(false);
        setEditType(0);
        setEditMode(false);
      });
    } catch (err) {
      handleError(err);
      setProcessing(false);
    }
  };

  const handleRemoveIndustryCode = async (e) => {
    e.preventDefault();
    setProcessing(true);
    try {
      const response = await partner.post(
        `/v1/entity/corporations/corporation/industry-codes/remove`,
        JSON.stringify({
          corporation_uuid: entityData.value.corporation_uuid,
          code_uuid: deleteIndustryCode,
        }),
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Basic " + useAuthHeader.base64encodedData,
            service: "partners",
          },

          //withCredentials: true,
        }
      );

      getActivityData();
      setDeleteIndustryCodeDialog(false);
      setDeleteIndustryCode("");

      displayToast("Industry code removed successfully", () => {
        setProcessing(false);
        setEditType(0);
        setEditMode(false);
      });
    } catch (err) {
      handleError(err);
      setProcessing(false);
    }
  };

  const handleUpdateBusinessDescription = async (e) => {
    e.preventDefault();
    setProcessing(true);
    try {
      const response = await partner.post(
        `/v1/entity/corporations/corporation/business-description`,
        JSON.stringify({
          corporation_uuid: entityData.value.corporation_uuid,
          description: businessDescription,
        }),
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Basic " + useAuthHeader.base64encodedData,
            service: "partners",
          },

          //withCredentials: true,
        }
      );

      getActivityData();

      displayToast("Business description updated successfully", () => {
        setProcessing(false);
        setEditType(0);
        setEditMode(false);
      });
    } catch (err) {
      handleError(err);
      setProcessing(false);
    }
  };

  const handleUpdateIndustryConsideration = async (e) => {
    e.preventDefault();
    setProcessing(true);
    try {
      const response = await partner.post(
        `/v1/entity/corporations/corporation/industry-consideration`,
        JSON.stringify({
          corporation_uuid: entityData.value.corporation_uuid,
          consideration: industryConsideration,
        }),
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Basic " + useAuthHeader.base64encodedData,
            service: "partners",
          },

          //withCredentials: true,
        }
      );

      getActivityData();

      displayToast("Industry consideration updated successfully", () => {
        setProcessing(false);
        setEditType(0);
        setEditMode(false);
      });
    } catch (err) {
      handleError(err);
      setProcessing(false);
    }
  };

  const handleAddLink = async (e) => {
    e.preventDefault();
    setProcessing(true);
    try {
      const response = await partner.post(
        `/v1/entity/corporations/corporation/links/add`,
        JSON.stringify({
          corporation_uuid: entityData.value.corporation_uuid,
          link_type: selectedLinkType ? selectedLinkType.value : -1,
          url: linkUrl,
        }),
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Basic " + useAuthHeader.base64encodedData,
            service: "partners",
          },

          //withCredentials: true,
        }
      );

      getActivityData();

      displayToast("Link added successfully", () => {
        setLinkUrl("");
        setProcessing(false);
        setEditType(0);
        setEditMode(false);
      });
    } catch (err) {
      handleError(err);
      setProcessing(false);
    }
  };

  const handleRemoveLink = async (e) => {
    e.preventDefault();
    setProcessing(true);
    try {
      const response = await partner.post(
        `/v1/entity/corporations/corporation/links/remove`,
        JSON.stringify({
          corporation_uuid: entityData.value.corporation_uuid,
          link_uuid: deleteLink,
        }),
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Basic " + useAuthHeader.base64encodedData,
            service: "partners",
          },

          //withCredentials: true,
        }
      );

      getActivityData();
      setDeleteLinkDialog(false);
      setDeleteLink("");

      displayToast("Link removed successfully", () => {
        setProcessing(false);
        setEditType(0);
        setEditMode(false);
      });
    } catch (err) {
      handleError(err);
      setProcessing(false);
    }
  };

  const customStyles = {
    headCells: {
      style: {
        paddingLeft: "8px", // override the cell padding for head cells
        paddingRight: "8px",
        fontWeight: "bold",
      },
    },
    cells: {
      style: {
        paddingLeft: "8px", // override the cell padding for data cells
        paddingRight: "8px",
      },
    },
  };

  const columns = [
    {
      name: "Industry name",
      sortable: true,
      width: "50%",
      selector: (row) => row.name,
    },
    {
      name: "Industry code",
      sortable: true,
      right: "true",
      selector: (row) => row.code,
      cell: (row) => (
        <Box sx={{ display: "flex", flexDirection: "row", gap: 2 }}>
          {row.high_risk && (
            <Chip
              className="square-chip"
              label="High risk"
              sx={{ color: "#ffffff", backgroundColor: "#C83E4D", textTransform: "uppercase" }}
            />
          )}
          <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
            <Typography sx={{ fontSize: "13px" }}>{row.code}</Typography>
          </Box>
        </Box>
      ),
    },
  ];

  const manageColumns = [
    {
      name: "Industry name",
      sortable: true,
      width: "30%",
      selector: (row) => row.name,
    },
    {
      name: "Industry code",
      sortable: true,
      right: "true",
      selector: (row) => row.code,
      cell: (row) => (
        <Box>
          <Typography sx={{ fontSize: "13px" }}>{row.code}</Typography>
        </Box>
      ),
    },
    {
      name: "Remove",
      sortable: false,
      right: "true",
      cell: (row) => (
        <Box
          onClick={() => {
            setDeleteIndustryCode(row.uuid);
            setDeleteIndustryCodeDialog(true);
          }}
          sx={{ color: "#C83E4D", cursor: "pointer" }}
        >
          <Delete />
        </Box>
      ),
    },
  ];

  const linkColumns = [
    {
      name: "Link URL",
      sortable: true,
      width: "75%",
      selector: (row) => row.url,
      cell: (row) => (
        <Box>
          <Link
            to={`https://${row.url}`}
            target="_blank"
            rel="noopener noreferrer"
            underline="none"
            sx={{ fontSize: "13px", textDecoration: "none", color: "#0b0b0b" }}
          >
            {row.url}
          </Link>
        </Box>
      ),
    },
    {
      name: "Type",
      sortable: true,
      right: "true",
      selector: (row) => row.link_name,
    },
  ];

  const manageLinkColumns = [
    {
      name: "Link URL",
      sortable: true,
      width: "75%",
      selector: (row) => row.url,
      cell: (row) => (
        <Box>
          <Link
            href={`https://${row.url}`}
            target="_blank"
            underline="none"
            sx={{ fontSize: "13px" }}
          >
            {row.url}
          </Link>
        </Box>
      ),
    },
    {
      name: "Type",
      sortable: true,
      right: "true",
      selector: (row) => row.link_name,
    },
    {
      name: "Remove",
      sortable: false,
      right: "true",
      cell: (row) => (
        <Box
          onClick={() => {
            setDeleteLink(row.link_uuid);
            setDeleteLinkDialog(true);
          }}
          sx={{ color: "#C83E4D", cursor: "pointer" }}
        >
          <Delete />
        </Box>
      ),
    },
  ];

  const linkOptions = [
    { value: 0, label: "Website" },
    { value: 1, label: "Facebook" },
    { value: 2, label: "Twitter | X.com" },
    { value: 3, label: "LinkedIn" },
    { value: 4, label: "Instagram" },
    { value: 5, label: "YouTube" },
    { value: 6, label: "TikTok" },
    { value: 7, label: "Snapchat" },
    { value: 8, label: "Pinterest" },
    { value: 9, label: "Reddit" },
    { value: 10, label: "Trust Pilot" },
    { value: 11, label: "Google Reviews" },
    { value: 12, label: "Yelp" },
    { value: 13, label: "Glassdoor" },
    { value: 14, label: "Tradebody" },
    { value: 15, label: "Licensing Authority" },
    { value: 16, label: "Other" },
  ];

  useEffect(() => {
    getActivityData();
  }, []);

  useEffect(() => {
    if (editType == 2) {
      getIndustryOptions();
    }
  }, [editType]);

  return isLoaded ? (
    <Grid container spacing={2}>
      <Grid item xs={12} xl={12}>
        <Box sx={{}}>
          {!editMode ? (
            <Box
              sx={{
                borderRight: { xs: 0, xl: 0 },
                borderColor: { xs: "divider", xl: "divider" },
                borderBottom: 0,
              }}
            >
              <Box
                sx={{
                  borderBottom: 1,
                  borderColor: "divider",
                  px: 2,
                  py: 1,
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
                  <Typography variant="h6">Business activity</Typography>
                </Box>
              </Box>

              <Box
                sx={{
                  px: 2,
                  py: 1,
                  borderBottom: 1,
                  borderColor: "divider",
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
                  <Typography variant="subtitle2" sx={{ fontWeight: 600 }}>
                    Business description
                  </Typography>
                </Box>
                <Box>
                  <IconButton
                    title="Edit"
                    onClick={() => {
                      setEditType(1);
                      setEditMode(true);
                    }}
                  >
                    <Edit />
                  </IconButton>
                </Box>
              </Box>

              <Box
                sx={{
                  px: 2,
                  py: 1,
                  borderBottom: 1,
                  borderColor: "divider",
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                {businessDescription != "" ? (
                  <Box sx={{ fontSize: "13px" }}>{parse(businessDescription)}</Box>
                ) : (
                  <Typography variant="subtitle2">-</Typography>
                )}
              </Box>

              <Box
                sx={{
                  px: 2,
                  py: 1,
                  borderBottom: 1,
                  borderColor: "divider",
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
                  <Typography variant="body2" sx={{ fontWeight: 600 }}>
                    Industry designations
                  </Typography>
                </Box>

                <Box>
                  <IconButton
                    title="Edit"
                    onClick={() => {
                      setEditType(2);
                      setEditMode(true);
                    }}
                  >
                    <Edit />
                  </IconButton>
                </Box>
              </Box>

              <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                <Box>
                  <DataTable
                    columns={columns}
                    data={industryCodes}
                    customStyles={customStyles}
                    striped
                    noTableHead={true}
                    noDataComponent={
                      <Box sx={{ width: "100%", px: 2, py: 1 }}>
                        <Typography sx={{ textAlign: "left" }} variant="subtitle2">
                          -
                        </Typography>
                      </Box>
                    }
                  />
                </Box>
              </Box>

              <Box
                sx={{
                  px: 2,
                  py: 1,
                  borderBottom: 1,
                  borderColor: "divider",
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
                  <Typography variant="subtitle2" sx={{ fontWeight: 600 }}>
                    Industry considerations
                  </Typography>
                </Box>

                <Box>
                  <IconButton
                    title="Edit"
                    onClick={() => {
                      setEditType(3);
                      setEditMode(true);
                    }}
                  >
                    <Edit />
                  </IconButton>
                </Box>
              </Box>

              <Box
                sx={{
                  px: 2,
                  py: 1,
                  borderBottom: 1,
                  borderColor: "divider",
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                {industryConsideration ? (
                  <Box sx={{ fontSize: "13px" }}>{parse(industryConsideration)}</Box>
                ) : (
                  <Typography variant="subtitle2">-</Typography>
                )}
              </Box>

              <Box
                sx={{
                  px: 2,
                  py: 1,
                  borderBottom: 1,
                  borderColor: "divider",
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
                  <Typography variant="subtitle2" sx={{ fontWeight: 600 }}>
                    Links
                  </Typography>
                </Box>

                <Box>
                  <IconButton
                    title="Edit"
                    onClick={() => {
                      setEditType(4);
                      setEditMode(true);
                    }}
                  >
                    <Edit />
                  </IconButton>
                </Box>
              </Box>

              <Box sx={{ borderBottom: 0, borderColor: "divider" }}>
                <Box>
                  <DataTable
                    columns={linkColumns}
                    data={links}
                    customStyles={customStyles}
                    striped
                    noTableHead={true}
                    noDataComponent={
                      <Box sx={{ width: "100%", px: 2, py: 1 }}>
                        <Typography sx={{ textAlign: "left" }} variant="subtitle2">
                          -
                        </Typography>
                      </Box>
                    }
                  />
                </Box>
              </Box>
            </Box>
          ) : (
            <Box>
              <Box
                sx={{
                  borderRight: { xs: 0, xl: 0 },
                  borderColor: { xs: "divider", xl: "divider" },
                }}
              >
                <Box
                  sx={{
                    borderBottom: 1,
                    borderColor: "divider",
                    px: 2,
                    py: 1,
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
                    <Typography variant="h6">Activity settings</Typography>
                  </Box>
                  <Box>
                    <IconButton title="Close" onClick={() => setEditMode(false)}>
                      <Close />
                    </IconButton>
                  </Box>
                </Box>
                {editType == 1 && (
                  <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
                    <Box sx={{ display: "flex", flexDirection: "column" }}>
                      <Box sx={{ p: 2 }}>
                        <Typography variant="subtitle2">Business description</Typography>
                      </Box>
                      <Divider />
                      <Box>
                        <ReactQuill
                          theme="snow"
                          value={businessDescription}
                          onChange={setBusinessDescription}
                          style={{ minHeight: "300px" }}
                        />
                      </Box>
                    </Box>
                    <Divider />

                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "flex-end",
                        px: 2,
                        pb: 2,
                      }}
                    >
                      <LoadingButton
                        disableElevation
                        variant="contained"
                        color="success"
                        loading={processing}
                        onClick={(e) => handleUpdateBusinessDescription(e)}
                      >
                        Save changes
                      </LoadingButton>
                    </Box>
                  </Box>
                )}

                {editType == 2 && (
                  <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
                    <Box sx={{ display: "flex", flexDirection: "column", gap: 1, p: 2 }}>
                      <Typography variant="subtitle2">Add industry code</Typography>
                      <FormControl fullWidth>
                        <Autocomplete
                          disablePortal
                          id="add-industry-code"
                          options={industryCodeOptions}
                          onChange={(e, v) => {
                            setSelectedIndustryCode(v.value);
                          }}
                          renderInput={(params) => <TextField {...params} label="Industry code" />}
                        />
                      </FormControl>

                      <Box
                        sx={{ display: "flex", flexDirection: "row", justifyContent: "flex-end" }}
                      >
                        <LoadingButton
                          disableElevation
                          variant="contained"
                          color="success"
                          loading={processing}
                          onClick={(e) => handleAddIndustryCode(e)}
                        >
                          Add industry code
                        </LoadingButton>
                      </Box>
                    </Box>

                    <Dialog
                      open={deleteIndustryCodeDialog}
                      onClose={() => setDeleteIndustryCodeDialog(false)}
                      aria-labelledby="alert-dialog-title"
                      aria-describedby="alert-dialog-description"
                    >
                      <DialogTitle id="alert-dialog-title">Are you sure?</DialogTitle>
                      <DialogContent>
                        <Box>
                          <DialogContentText sx={{ mb: 1 }} id="alert-dialog-description">
                            Are you sure you want to remove this industry code?
                          </DialogContentText>
                        </Box>
                      </DialogContent>
                      <DialogActions>
                        <LoadingButton
                          disableElevation
                          loading={processing}
                          onClick={() => setDeleteIndustryCodeDialog(false)}
                          color="primary"
                        >
                          Cancel
                        </LoadingButton>

                        <LoadingButton
                          disableElevation
                          sx={{ color: "#ffffff" }}
                          loading={processing}
                          onClick={(e) => {
                            handleRemoveIndustryCode(e);
                          }}
                          variant="contained"
                          color="danger"
                          autoFocus
                        >
                          Confirm
                        </LoadingButton>
                      </DialogActions>
                    </Dialog>

                    {industryCodes && industryCodes.length > 0 && (
                      <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
                        <Divider />
                        <DataTable
                          columns={manageColumns}
                          data={industryCodes}
                          customStyles={customStyles}
                          pagination
                          striped
                        />
                      </Box>
                    )}
                  </Box>
                )}

                {editType == 3 && (
                  <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
                    <Box sx={{ display: "flex", flexDirection: "column" }}>
                      <Box sx={{ p: 2 }}>
                        <Typography variant="subtitle2">Industry considerations</Typography>
                      </Box>
                      <Divider />
                      <Box>
                        <ReactQuill
                          theme="snow"
                          value={industryConsideration}
                          onChange={setIndustryConsideration}
                          style={{ minHeight: "300px" }}
                        />
                      </Box>
                    </Box>
                    <Divider />

                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "flex-end",
                        px: 2,
                        pb: 2,
                      }}
                    >
                      <LoadingButton
                        disableElevation
                        variant="contained"
                        color="success"
                        loading={processing}
                        onClick={(e) => handleUpdateIndustryConsideration(e)}
                      >
                        Save changes
                      </LoadingButton>
                    </Box>
                  </Box>
                )}

                {editType == 4 && (
                  <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
                    <Box sx={{ display: "flex", flexDirection: "column", gap: 1, p: 2 }}>
                      <Typography variant="subtitle2">Add a link</Typography>
                      <FormControl fullWidth>
                        <Autocomplete
                          disablePortal
                          id="add-link"
                          options={linkOptions}
                          onChange={(e, v) => {
                            setSelectedLinkType(v);
                          }}
                          isOptionEqualToValue={(option, value) => option.value === value.value}
                          renderInput={(params) => <TextField {...params} label="Link type" />}
                        />
                      </FormControl>

                      <TextField
                        label="Link URL"
                        fullWidth
                        value={linkUrl}
                        onChange={(e) => setLinkUrl(e.target.value)}
                      />

                      <Box
                        sx={{ display: "flex", flexDirection: "row", justifyContent: "flex-end" }}
                      >
                        <LoadingButton
                          disableElevation
                          variant="contained"
                          color="success"
                          loading={processing}
                          onClick={(e) => handleAddLink(e)}
                        >
                          Add link
                        </LoadingButton>
                      </Box>
                    </Box>

                    <Dialog
                      open={deleteLinkDialog}
                      onClose={() => setDeleteLinkDialog(false)}
                      aria-labelledby="alert-dialog-title"
                      aria-describedby="alert-dialog-description"
                    >
                      <DialogTitle id="alert-dialog-title">Are you sure?</DialogTitle>
                      <DialogContent>
                        <Box>
                          <DialogContentText sx={{ mb: 1 }} id="alert-dialog-description">
                            Are you sure you want to remove this link?
                          </DialogContentText>
                        </Box>
                      </DialogContent>
                      <DialogActions>
                        <LoadingButton
                          disableElevation
                          loading={processing}
                          onClick={() => setDeleteLinkDialog(false)}
                          color="primary"
                        >
                          Cancel
                        </LoadingButton>

                        <LoadingButton
                          disableElevation
                          sx={{ color: "#ffffff" }}
                          loading={processing}
                          onClick={(e) => {
                            handleRemoveLink(e);
                          }}
                          variant="contained"
                          color="danger"
                          autoFocus
                        >
                          Confirm
                        </LoadingButton>
                      </DialogActions>
                    </Dialog>

                    {links && links.length > 0 && (
                      <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
                        <Divider />
                        <DataTable
                          columns={manageLinkColumns}
                          data={links}
                          customStyles={customStyles}
                          pagination
                          striped
                        />
                      </Box>
                    )}
                  </Box>
                )}
              </Box>
            </Box>
          )}
        </Box>
      </Grid>
    </Grid>
  ) : (
    <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", height: "50vh" }}>
      <Lottie speed={2.0} options={defaultOptions} height={200} width={200} />
    </Box>
  );
};

export default Activities;
