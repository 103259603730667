import { useEffect, useState } from "react";
import { getPartnerUsers } from "../../api/services";

export function useGetPartnerUsersList() {
  const [state, setState] = useState({
    isLoading: true,
    data: null,
  });
  useEffect(() => {
    if (state?.data) return;
    getPartnerUsers()
      .then((data) => {
        setState({
          isLoading: false,
          data: data?.entries || [],
        });
      })
      .catch((error) => {
        console.error(error);
      });
  }, [state?.data]);

  return {
    isLoading: state.isLoading,
    partnerUsersList: state.data,
  };
}
