import { React, useState, useEffect } from "react";
import { useParams } from "react-router-dom";

import central from "../../api/central";
import authHeader from "../../api/authHeader";

import HandleError from "../../components/functions/HandleError";

import { CssBaseline, TextField, Paper, Box, Grid, Typography, Button, Modal } from "@mui/material";
import Lottie from "react-lottie";
import loadingLottie from "../../components/lotties/loading.json";
import successLottie from "../../components/lotties/success.json";
import errorLottie from "../../components/lotties/error.json";
import LoadingButton from "@mui/lab/LoadingButton";

import VideoCapture from "./LoginImage";

function Copyright(props) {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {"Copyright © Cleverli.com "}
      {new Date().getFullYear()}
    </Typography>
  );
}

const Magic = () => {
  const { t } = useParams();
  const useAuthHeader = authHeader();

  const handleError = HandleError();

  const [processing, setProcessing] = useState(false);
  const [activeView, setActiveView] = useState(0); // 0 loading | 1 success | 2 error | 3 authentication

  const [errorOutput, setErrorOutput] = useState("");

  const [code1, setCode1] = useState("");
  const [code2, setCode2] = useState("");
  const [code3, setCode3] = useState("");
  const [code4, setCode4] = useState("");
  const [code5, setCode5] = useState("");
  const [code6, setCode6] = useState("");

  const defaultOptions = {
    loop: activeView == 0 ? true : false,
    autoplay: true,
    animationData: activeView == 0 ? loadingLottie : activeView == 1 ? successLottie : errorLottie,
  };

  const handleMagicLink = async (e) => {
    e.preventDefault();
    setProcessing(true);
    try {
      const response = await central.post(
        "/v1/partner/auth/magic-link",
        JSON.stringify({
          token: t,
        }),
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Basic " + useAuthHeader.base64encodedData,
            service: "partners",
          },
          mode: "no-cors",
          //withCredentials: true,
        }
      );

      if (response.data.image_required) {
        setProcessing(false);
        setVideoCapture(true);
        return;
      } else if (response.data.authentication) {
        setActiveView(3);
        setProcessing(false);
        return;
      }

      setProcessing(false);
      setActiveView(1);
    } catch (err) {
      setErrorOutput(err.response.data.trim());
      setProcessing(false);
      setActiveView(2);
    }
  };

  const handleDecline = async (e) => {
    e.preventDefault();
    setProcessing(true);
    try {
      const response = await central.post(
        "/v1/partner/auth/magic-link/decline",
        JSON.stringify({
          token: t,
        }),
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Basic " + useAuthHeader.base64encodedData,
            service: "partners",
          },
          mode: "no-cors",
          //withCredentials: true,
        }
      );

      setProcessing(false);
      setActiveView(4);
    } catch (err) {
      setErrorOutput(err.response.data.trim());
      setProcessing(false);
      setActiveView(2);
    }
  };

  const handleAuthentication = async (e) => {
    e.preventDefault();
    setProcessing(true);
    try {
      const response = await central.post(
        "/v1/partner/auth/authentication",
        JSON.stringify({
          token: t,
          code: code1 + code2 + code3 + code4 + code5 + code6,
        }),
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Basic " + useAuthHeader.base64encodedData,
            service: "partners",
          },
          mode: "no-cors",
          //withCredentials: true,
        }
      );

      setProcessing(false);
      setActiveView(1);
    } catch (err) {
      setErrorOutput(err.response.data.trim());
      document.getElementById("input1").focus();
      setCode1("");
      setCode2("");
      setCode3("");
      setCode4("");
      setCode5("");
      setCode6("");
      setProcessing(false);
    }
  };

  const [videoCapture, setVideoCapture] = useState(false);
  const [videoContent, setVideoContent] = useState([]);
  const [faceImage, setFaceImage] = useState("");

  const handleFaceProcessing = async () => {
    setProcessing(true);

    try {
      const response = await central.post(
        `/v1/partner/auth/magic-link/facial-image`,
        JSON.stringify({
          token: t,
          facial_image: faceImage,
        }),
        {
          headers: { "Content-Type": "application/json", service: "partners" },

          //withCredentials: true,
        }
      );

      console.log(response.data);

      if (response.data.authentication) {
        setActiveView(3);
        setProcessing(false);
        return;
      }

      setProcessing(false);
      setActiveView(1);
    } catch (err) {
      handleError(err);
      setProcessing(false);
    }
  };

  useEffect(() => {
    if (faceImage) {
      handleFaceProcessing();
    }
  }, [faceImage]);

  return (
    <>
      <CssBaseline />
      <Grid container component="main" sx={{ height: "100vh" }}>
        <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
          <Box
            sx={{
              my: 8,
              mx: 4,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              gap: 4,
            }}
          >
            <Box
              component="img"
              sx={{ maxWidth: "300px" }}
              alt="Logo"
              src="/cleverli-logo-vertical-black.svg"
            />

            {activeView == 0 && (
              <Box sx={{ width: "100%" }}>
                <Box
                  variant="div"
                  align="center"
                  alignContent={"center"}
                  sx={{ mt: 1, marginLeft: "auto", marginRight: "auto", width: "fit-content" }}
                >
                  {/* <Lottie options={defaultOptions} height={200} width={250} isStopped={false} isPaused={false} /> */}
                </Box>
                <Typography
                  sx={{ mb: 2, textTransform: "uppercase" }}
                  align="center"
                  variant="h6"
                  gutterBottom
                >
                  <strong>Validating your login request</strong>
                </Typography>

                <Typography
                  sx={{ mb: 2, textTransform: "uppercase" }}
                  align="center"
                  variant="h6"
                  gutterBottom
                >
                  <strong>Did you make the request to login yourself?</strong>
                </Typography>

                <Box sx={{ display: "flex", flexDirection: "row", gap: 2 }}>
                  <Box sx={{ width: "50%" }}>
                    <LoadingButton
                      disableElevation
                      loading={processing}
                      onClick={(e) => {
                        handleDecline(e);
                      }}
                      type="button"
                      variant="outlined"
                      color="danger"
                      sx={{ mt: 3, mb: 2, width: "100%" }}
                    >
                      It wasn't me
                    </LoadingButton>
                  </Box>
                  <Box sx={{ width: "50%" }}>
                    <LoadingButton
                      disableElevation
                      loading={processing}
                      onClick={(e) => {
                        handleMagicLink(e);
                      }}
                      type="button"
                      variant="contained"
                      color="success"
                      sx={{ mt: 3, mb: 2, width: "100%" }}
                    >
                      Yes, it was me
                    </LoadingButton>
                  </Box>
                </Box>
              </Box>
            )}

            {activeView == 1 && (
              <Box sx={{ width: "100%" }}>
                <Box
                  variant="div"
                  align="center"
                  alignContent={"center"}
                  sx={{ mt: 1, marginLeft: "auto", marginRight: "auto", width: "fit-content" }}
                >
                  <Lottie
                    options={defaultOptions}
                    height={200}
                    width={250}
                    isStopped={false}
                    isPaused={false}
                  />
                </Box>
                <Typography
                  sx={{ mt: 3, mb: 2, textTransform: "uppercase" }}
                  variant="h6"
                  align="center"
                  gutterBottom
                >
                  <strong>Login successful</strong>
                </Typography>
                <Box
                  sx={{
                    width: "fit-content",
                    mt: 3,
                    mb: 2,
                    marginLeft: "auto",
                    marginRight: "auto",
                  }}
                >
                  <Button
                    onClick={() => {
                      setProcessing(true);
                      window.opener = null;
                      window.open("", "_self");
                      window.close();
                    }}
                    loading={processing}
                    type="button"
                    variant="contained"
                    sx={{ mt: 3, mb: 2 }}
                  >
                    Close window
                  </Button>
                </Box>
              </Box>
            )}

            {activeView == 2 && (
              <Box sx={{ width: "100%" }}>
                <Box
                  variant="div"
                  align="center"
                  alignContent={"center"}
                  sx={{ mt: 1, marginLeft: "auto", marginRight: "auto", width: "fit-content" }}
                >
                  <Lottie
                    options={defaultOptions}
                    height={200}
                    width={250}
                    isStopped={false}
                    isPaused={false}
                  />
                </Box>
                <Typography
                  sx={{ textTransform: "uppercase" }}
                  variant="h6"
                  align="center"
                  gutterBottom
                >
                  <strong>Error</strong>
                </Typography>

                <Typography
                  sx={{ textTransform: "uppercase" }}
                  color="danger.main"
                  variant="h6"
                  align="center"
                  gutterBottom
                >
                  <strong>{errorOutput}</strong>
                </Typography>
                <Box
                  sx={{
                    width: "fit-content",
                    mt: 3,
                    mb: 2,
                    marginLeft: "auto",
                    marginRight: "auto",
                  }}
                >
                  <Button
                    onClick={() => {
                      setProcessing(true);
                      window.opener = null;
                      window.open("", "_self");
                      window.close();
                    }}
                    loading={processing}
                    type="button"
                    variant="contained"
                    sx={{ mt: 3, mb: 2 }}
                  >
                    Close window
                  </Button>
                </Box>
              </Box>
            )}

            {activeView == 3 && (
              <Box sx={{ width: "100%" }}>
                <Box
                  variant="div"
                  align="center"
                  alignContent={"center"}
                  sx={{ mt: 1, marginLeft: "auto", marginRight: "auto", width: "fit-content" }}
                >
                  {/* <Lottie options={defaultOptions} height={200} width={250} isStopped={false} isPaused={false} /> */}
                </Box>
                <Typography
                  sx={{ mb: 2, textTransform: "uppercase" }}
                  align="center"
                  variant="h6"
                  gutterBottom
                >
                  <strong>Two factor authentication</strong>
                </Typography>

                <Typography
                  sx={{ mb: 2, textTransform: "uppercase" }}
                  align="center"
                  variant="h6"
                  gutterBottom
                >
                  <strong>Enter the code from your authenticator app</strong>
                </Typography>

                <Grid container spacing={2} sx={{ mt: -2 }}>
                  <Grid item xs={2}>
                    <TextField
                      id="input1"
                      type="tel"
                      inputProps={{
                        min: 0,
                        maxLength: 1,
                        pattern: "[0-9]*",
                        style: { textAlign: "center", fontSize: 32 },
                      }}
                      fullWidth
                      autoFocus
                      variant="outlined"
                      value={code1}
                      onChange={(e) => setCode1(e.target.value)}
                      onKeyUp={(e) => {
                        if (e.target.value.length === 1) {
                          document.getElementById("input2").focus();
                        }
                      }}
                    />
                  </Grid>
                  <Grid item xs={2}>
                    <TextField
                      id="input2"
                      inputProps={{
                        min: 0,
                        maxLength: 1,
                        pattern: "[0-9]*",
                        style: { textAlign: "center", fontSize: 32 },
                      }}
                      fullWidth
                      variant="outlined"
                      value={code2}
                      onChange={(e) => setCode2(e.target.value)}
                      onKeyUp={(e) => {
                        if (e.target.value.length === 1) {
                          document.getElementById("input3").focus();
                        } else if (e.key === "Backspace") {
                          document.getElementById("input1").focus();
                        }
                      }}
                    />
                  </Grid>
                  <Grid item xs={2}>
                    <TextField
                      id="input3"
                      inputProps={{
                        min: 0,
                        maxLength: 1,
                        pattern: "[0-9]*",
                        style: { textAlign: "center", fontSize: 32 },
                      }}
                      fullWidth
                      variant="outlined"
                      value={code3}
                      onChange={(e) => setCode3(e.target.value)}
                      onKeyUp={(e) => {
                        if (e.target.value.length === 1) {
                          document.getElementById("input4").focus();
                        } else if (e.key === "Backspace") {
                          document.getElementById("input2").focus();
                        }
                      }}
                    />
                  </Grid>
                  <Grid item xs={2}>
                    <TextField
                      id="input4"
                      inputProps={{
                        min: 0,
                        maxLength: 1,
                        pattern: "[0-9]*",
                        style: { textAlign: "center", fontSize: 32 },
                      }}
                      fullWidth
                      variant="outlined"
                      value={code4}
                      onChange={(e) => setCode4(e.target.value)}
                      onKeyUp={(e) => {
                        if (e.target.value.length === 1) {
                          document.getElementById("input5").focus();
                        } else if (e.key === "Backspace") {
                          document.getElementById("input3").focus();
                        }
                      }}
                    />
                  </Grid>
                  <Grid item xs={2}>
                    <TextField
                      id="input5"
                      inputProps={{
                        min: 0,
                        maxLength: 1,
                        pattern: "[0-9]*",
                        style: { textAlign: "center", fontSize: 32 },
                      }}
                      fullWidth
                      variant="outlined"
                      value={code5}
                      onChange={(e) => setCode5(e.target.value)}
                      onKeyUp={(e) => {
                        if (e.target.value.length === 1) {
                          document.getElementById("input6").focus();
                        } else if (e.key === "Backspace") {
                          document.getElementById("input4").focus();
                        }
                      }}
                    />
                  </Grid>
                  <Grid item xs={2}>
                    <TextField
                      id="input6"
                      inputProps={{
                        min: 0,
                        maxLength: 1,
                        pattern: "[0-9]*",
                        style: { textAlign: "center", fontSize: 32 },
                      }}
                      fullWidth
                      variant="outlined"
                      value={code6}
                      onChange={(e) => setCode6(e.target.value)}
                      onKeyUp={(e) => {
                        if (e.key === "Backspace") {
                          document.getElementById("input5").focus();
                        } else if (e.target.value !== "") {
                          handleAuthentication(e);
                        }
                      }}
                    />
                  </Grid>
                </Grid>

                <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "flex-end" }}>
                  <LoadingButton
                    disableElevation
                    loading={processing}
                    onClick={(e) => {
                      handleAuthentication(e);
                    }}
                    type="button"
                    variant="contained"
                    color="success"
                    sx={{ mt: 2, mb: 2 }}
                  >
                    Complete Authentication
                  </LoadingButton>
                </Box>
              </Box>
            )}

            {activeView == 4 && (
              <Box sx={{ width: "100%" }}>
                <Box
                  variant="div"
                  align="center"
                  alignContent={"center"}
                  sx={{ mt: 1, marginLeft: "auto", marginRight: "auto", width: "fit-content" }}
                >
                  <Lottie
                    options={defaultOptions}
                    height={200}
                    width={250}
                    isStopped={false}
                    isPaused={false}
                  />
                </Box>
                <Typography
                  sx={{ textTransform: "uppercase" }}
                  variant="h6"
                  align="center"
                  gutterBottom
                >
                  <strong>Access Prevented</strong>
                </Typography>

                <Typography
                  sx={{ textTransform: "uppercase" }}
                  color="danger.main"
                  variant="h6"
                  align="center"
                  gutterBottom
                >
                  <strong>We prevented the login from going any further</strong>
                </Typography>
                <Box
                  sx={{
                    width: "fit-content",
                    mt: 3,
                    mb: 2,
                    marginLeft: "auto",
                    marginRight: "auto",
                  }}
                >
                  <Button
                    onClick={() => {
                      setProcessing(true);
                      window.opener = null;
                      window.open("", "_self");
                      window.close();
                    }}
                    loading={processing}
                    type="button"
                    variant="contained"
                    sx={{ mt: 3, mb: 2 }}
                  >
                    Close window
                  </Button>
                </Box>
              </Box>
            )}

            <Box component="div" sx={{ mt: 1 }}>
              <Copyright sx={{ mt: 5 }} />
            </Box>
          </Box>
        </Grid>

        <Grid
          item
          xs={false}
          sm={4}
          md={7}
          sx={{
            backgroundColor: (t) =>
              t.palette.mode === "light" ? t.palette.primary.main : t.palette.primary.main,
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        />
      </Grid>
      <Modal
        open={videoCapture}
        onClose={() => setVideoCapture(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "30%",
            minWidth: 800,
            bgcolor: "background.paper",
            border: 2,
            borderColor: "divider",
            boxShadow: 24,
            p: 4,
          }}
        >
          <Box sx={{ width: "100%", border: 1, borderColor: "divider" }}>
            <Box
              variant="div"
              align="center"
              alignContent={"center"}
              sx={{ marginLeft: "auto", marginRight: "auto", width: "fit-content" }}
            >
              {/* <Lottie options={defaultOptions} height={200} width={250} isStopped={false} isPaused={false} /> */}
            </Box>

            <Box
              sx={{
                p: 2,
                borderBottom: 1,
                borderColor: "divider",
                backgroundColor: "#0b0b0b",
                color: "#ffffff",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
                <Typography variant="subtitle2">Verifying your request</Typography>
              </Box>
              <Box></Box>
            </Box>

            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <VideoCapture
                setVideoCapture={setVideoCapture}
                setVideoContent={setVideoContent}
                setFaceImage={setFaceImage}
              />
            </Box>
          </Box>
        </Box>
      </Modal>
    </>
  );
};

export default Magic;
