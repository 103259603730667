import { React, useEffect, useState } from "react";

import central from "../../../../api/central";
import authHeader from "../../../../api/hostedAuthHeader";

import HandleError from "../../../../components/functions/HandleError";

import {
  Box,
  Typography,
  Chip,
  Grid,
  Icon,
  IconButton,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Button,
  Collapse,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  DialogContentText,
  ToggleButtonGroup,
  ToggleButton,
  Divider,
  Drawer,
} from "@mui/material";
import { Close, Delete, Edit, PermMedia, ViewAgenda, Visibility } from "@mui/icons-material";

import CountrySelect from "../../../../components/form-elements/CountrySelect";
import { LoadingButton } from "@mui/lab";

const Address = ({ partnerUUID, questionUUID, setAnswersData, answersData, handleSaveAnswer, processing, setProcessing, currentIndex }) => {
  const useAuthHeader = authHeader();
  const handleError = HandleError();

  const [addressCountry, setAddressCountry] = useState(
    answersData[questionUUID] ? { name: answersData[questionUUID].country?.name, value: answersData[questionUUID].country?.value } : null
  );

  const [isLoaded, setIsLoaded] = useState(false);

  const [lookupDisplay, setLookupDisplay] = useState(answersData[questionUUID]?.line_one != "" ? false : true);
  const [lookupSupported, setLookupSupported] = useState(false);
  const [lookupValue, setLookupValue] = useState("");
  const [lookupOptions, setLookupOptions] = useState([]);
  const [formDisplay, setFormDisplay] = useState(answersData[questionUUID]?.line_one != "" ? true : false);
  const [stateRequired, setStateRequired] = useState(false);

  const [selectedAddress, setSelectedAddress] = useState("");

  const [addressLineOne, setAddressLineOne] = useState(answersData[questionUUID]?.line_one ? answersData[questionUUID].line_one : "");
  const [addressLineTwo, setAddressLineTwo] = useState(answersData[questionUUID]?.line_two ? answersData[questionUUID].line_two : "");
  const [addressLineThree, setAddressLineThree] = useState(answersData[questionUUID]?.line_three ? answersData[questionUUID].line_three : "");
  const [addressLineFour, setAddressLineFour] = useState(answersData[questionUUID]?.line_four ? answersData[questionUUID].line_four : "");
  const [addressLineFive, setAddressLineFive] = useState(answersData[questionUUID]?.line_five ? answersData[questionUUID].line_five : "");
  const [addressLineSix, setAddressLineSix] = useState(answersData[questionUUID]?.line_six ? answersData[questionUUID].line_six : "");
  const [addressCity, setAddressCity] = useState(answersData[questionUUID]?.city ? answersData[questionUUID].city : "");
  const [addressState, setAddressState] = useState(answersData[questionUUID]?.state ? answersData[questionUUID].state : "");
  const [addressCode, setAddressCode] = useState(answersData[questionUUID]?.code ? answersData[questionUUID].code : "");

  const handleLookupAddress = async (e) => {
    e.preventDefault();
    setProcessing(true);
    try {
      const response = await central.post(
        `/v1/service/addresses/lookup`,
        JSON.stringify({
          partner_uuid: partnerUUID,
          country: addressCountry ? addressCountry.value : "",
          postcode: lookupValue,
        }),
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Basic " + useAuthHeader.base64encodedData,
            service: "hosted",
            partner_uuid: partnerUUID,
          },

          // withCredentials: true,
        }
      );

      if (response.data.results > 0) {
        setLookupOptions(response.data.entries);
      } else {
        //handleError("No addresses found");
        setLookupOptions([]);
      }
      setProcessing(false);
    } catch (err) {
      console.log(err);
      handleError(err);
      setProcessing(false);
    }
  };

  const getSelectedAddress = async (addressUUID) => {
    setProcessing(true);
    try {
      const response = await central.post(
        `/v1/service/addresses/data`,
        JSON.stringify({
          partner_uuid: partnerUUID,
          country: addressCountry ? addressCountry.value : "",
          uuid: addressUUID,
        }),
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Basic " + useAuthHeader.base64encodedData,
            service: "hosted",
            partner_uuid: partnerUUID,
          },

          // withCredentials: true,
        }
      );

      setAddressLineOne(response.data.line_one);
      setAddressLineTwo(response.data.line_two);
      setAddressLineThree(response.data.line_three);
      setAddressLineFour(response.data.line_four);
      setAddressLineFive(response.data.line_five);
      setAddressLineSix(response.data.line_six);
      setAddressCity(response.data.city);
      setAddressState(response.data.state);
      setAddressCode(response.data.code);

      setAnswersData({
        ...answersData,
        questionUUID: {
          line_one: response.data.line_one,
          line_two: response.data.line_two,
          line_three: response.data.line_three,
          line_four: response.data.line_four,
          line_five: response.data.line_five,
          line_six: response.data.line_six,
          city: response.data.city,
          state: response.data.state,
          code: response.data.code,
        },
      });

      setLookupDisplay(false);
      setFormDisplay(true);
      setProcessing(false);
    } catch (err) {
      console.log(err);
      handleError(err);
      setProcessing(false);
    }
  };

  useEffect(() => {
    if (addressCountry == "" || addressCountry == undefined) {
      setFormDisplay(false);
      setLookupDisplay(false);
      setLookupSupported(false);
      setStateRequired(false);
      return;
    }

    if (isLoaded) {
      setAnswersData({
        ...answersData,
        questionUUID: {
          country: addressCountry ? addressCountry.value : "",
        },
      });

      switch (addressCountry?.value) {
        case "GB":
          setLookupDisplay(true);
          setLookupSupported(true);
          setStateRequired(false);
          setFormDisplay(false);
          break;
        case "JE":
          setLookupDisplay(true);
          setLookupSupported(true);
          setStateRequired(false);
          setFormDisplay(false);
          break;
        case "GG":
          setLookupDisplay(true);
          setLookupSupported(true);
          setStateRequired(false);
          setFormDisplay(false);
          break;
        case "IM":
          setLookupDisplay(true);
          setLookupSupported(true);
          setStateRequired(false);
          setFormDisplay(false);
          break;
        case "US":
          setLookupDisplay(false);
          setLookupSupported(false);
          setStateRequired(true);
          setFormDisplay(true);
          break;
        default:
          setLookupDisplay(false);
          setLookupSupported(false);
          setStateRequired(false);
          setFormDisplay(true);
          break;
      }
    } else {
      switch (addressCountry?.value) {
        case "GB":
          setLookupSupported(true);
          setStateRequired(false);
          break;
        case "JE":
          setLookupSupported(true);
          setStateRequired(false);
          break;
        case "GG":
          setLookupSupported(true);
          setStateRequired(false);
          break;
        case "IM":
          setLookupSupported(true);
          setStateRequired(false);
          break;
        case "US":
          setLookupSupported(false);
          setStateRequired(true);
          break;
        default:
          setLookupSupported(false);
          setStateRequired(false);
          break;
      }

      setIsLoaded(true);
    }
  }, [addressCountry]);

  useEffect(() => {
    if (selectedAddress != "") {
      getSelectedAddress();
    }
  }, [selectedAddress]);

  useEffect(() => {
    setAnswersData({
      ...answersData,
      [questionUUID]: {
        line_one: addressLineOne,
        line_two: addressLineTwo,
        line_three: addressLineThree,
        line_four: addressLineFour,
        line_five: addressLineFive,
        line_six: addressLineSix,
        city: addressCity,
        state: addressState,
        code: addressCode,
        country: addressCountry ? addressCountry.value : "",
      },
    });
  }, [addressLineOne, addressLineTwo, addressLineThree, addressLineFour, addressLineFive, addressLineSix, addressCity, addressState, addressCode]);

  return (
    <Box>
      <Box sx={{}}>
        <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
          <CountrySelect value={addressCountry} setValue={setAddressCountry} />

          <Collapse in={lookupDisplay} exit={!lookupDisplay}>
            <Box sx={{ display: "flex", flexDirection: "column", gap: 1, p: 2, border: 1, borderColor: "divider" }}>
              <Typography variant="subtitle2">Address lookup</Typography>
              <TextField
                label="Postcode / Zipcode"
                variant="outlined"
                fullWidth
                onChange={(e) => {
                  setLookupValue(e.target.value);
                }}
              />

              <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "space-between", gap: 2 }}>
                <Box sx={{ minWidth: "300px;", width: "50%" }}>
                  {lookupOptions && lookupOptions.length > 0 && (
                    <Box>
                      <Typography variant="subtitle2" sx={{ mb: 1 }}>
                        Lookup results
                      </Typography>

                      <FormControl fullWidth>
                        <InputLabel id="address-results-label">Select an address</InputLabel>
                        <Select
                          labelId="address-results-label"
                          id="address-results"
                          value={selectedAddress}
                          label="Select an address"
                          onChange={(e) => getSelectedAddress(e.target.value)}
                        >
                          {lookupOptions.map((option, index) => (
                            <MenuItem key={index} value={option.value}>
                              {option.label}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Box>
                  )}
                </Box>
                <Box sx={{ display: "flex", flexDirection: "row", gap: 2 }}>
                  <Box>
                    <Button
                      variant="outlined"
                      color="primary"
                      onClick={(e) => {
                        setLookupDisplay(false);
                        setFormDisplay(true);
                      }}
                    >
                      Manual entry
                    </Button>
                  </Box>
                  <Box>
                    <LoadingButton variant="contained" color="success" loading={processing} onClick={(e) => handleLookupAddress(e)}>
                      Search
                    </LoadingButton>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Collapse>

          <Collapse in={formDisplay} exit={!formDisplay}>
            <Box sx={{ display: "flex", flexDirection: "column" }}>
              <TextField
                label="Address line 1"
                variant="outlined"
                sx={{ mt: 1 }}
                fullWidth
                name="line_one"
                value={addressLineOne}
                onChange={(e) => setAddressLineOne(e.target.value)}
              />

              <Collapse
                in={addressLineOne != "" ? true : addressLineTwo != "" ? true : false}
                exit={(addressLineOne == "") & (addressLineTwo == "") ? true : false}
              >
                <TextField
                  label="Address line 2"
                  variant="outlined"
                  sx={{ mt: 1 }}
                  fullWidth
                  name="line_two"
                  value={addressLineTwo}
                  onChange={(e) => setAddressLineTwo(e.target.value)}
                />
              </Collapse>
              <Collapse
                in={addressLineTwo != "" ? true : addressLineThree != "" ? true : false}
                exit={(addressLineTwo == "") & (addressLineThree == "") ? true : false}
              >
                <TextField
                  label="Address line 3"
                  variant="outlined"
                  sx={{ mt: 1 }}
                  fullWidth
                  name="line_three"
                  value={addressLineThree}
                  onChange={(e) => setAddressLineThree(e.target.value)}
                />
              </Collapse>

              <Collapse
                in={addressLineThree != "" ? true : addressLineFour != "" ? true : false}
                exit={(addressLineThree == "") & (addressLineFour == "") ? true : false}
              >
                <TextField
                  label="Address line 4"
                  variant="outlined"
                  sx={{ mt: 1 }}
                  fullWidth
                  name="line_four"
                  value={addressLineFour}
                  onChange={(e) => setAddressLineFour(e.target.value)}
                />
              </Collapse>
              <Collapse
                in={addressLineFour != "" ? true : addressLineFive != "" ? true : false}
                exit={(addressLineFour == "") & (addressLineFive == "") ? true : false}
              >
                <TextField
                  label="Address line 5"
                  variant="outlined"
                  sx={{ mt: 1 }}
                  fullWidth
                  name="line_five"
                  value={addressLineFive}
                  onChange={(e) => setAddressLineFive(e.target.value)}
                />
              </Collapse>
              <Collapse
                in={addressLineFive != "" ? true : addressLineSix != "" ? true : false}
                exit={(addressLineFive == "") & (addressLineSix == "") ? true : false}
              >
                <TextField
                  label="Address line 6"
                  variant="outlined"
                  sx={{ mt: 1 }}
                  fullWidth
                  name="line_six"
                  value={addressLineSix}
                  onChange={(e) => setAddressLineSix(e.target.value)}
                />
              </Collapse>
              <TextField
                label="City"
                variant="outlined"
                sx={{ mt: 1 }}
                fullWidth
                name="city"
                value={addressCity}
                onChange={(e) => setAddressCity(e.target.value)}
              />

              <Collapse in={stateRequired} exit={!stateRequired}>
                <TextField
                  label="State"
                  variant="outlined"
                  sx={{ mt: 1 }}
                  fullWidth
                  name="state"
                  value={addressState}
                  onChange={(e) => setAddressState(e.target.value)}
                />
              </Collapse>
              <TextField
                label="Postcode/Zipcode"
                variant="outlined"
                sx={{ mt: 1 }}
                fullWidth
                name="code"
                value={addressCode}
                onChange={(e) => setAddressCity(e.target.value)}
              />
            </Box>

            <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "flex-end", mt: 2 }}>
              <Box sx={{ display: "flex", flexDirection: "row", gap: 2 }}>
                {lookupSupported && (
                  <Button
                    variant="outlined"
                    color="primary"
                    onClick={(e) => {
                      setLookupDisplay(true);
                      setFormDisplay(false);
                    }}
                  >
                    Lookup address
                  </Button>
                )}
                <LoadingButton
                  disableElevation
                  variant="contained"
                  color="success"
                  loading={processing}
                  onClick={(e) => handleSaveAnswer(e, questionUUID, currentIndex)}
                >
                  Save address
                </LoadingButton>
              </Box>
            </Box>
          </Collapse>
        </Box>
      </Box>
    </Box>
  );
};

export default Address;
