import { React, useEffect, useState } from "react";

import authHeader from "../../../../../api/authHeader";

import HandleError from "../../../../../components/functions/HandleError";
import HandleToast from "../../../../../components/functions/HandleToast";

import Lottie from "react-lottie";
import loadingLottie from "../../../../../components/lotties/loading-dots.json";

import DataTable from "react-data-table-component";

import { Typography, Divider, Box, Button, Chip, Link, Fade } from "@mui/material";
import { Close } from "@mui/icons-material";

import NewTemplate from "./components/tasks/NewTemplate";
import ManageTemplate from "./components/tasks/ManageTemplate";
import NoResults from "../../../../../components/functions/NoResults";

const Tasks = ({ partner, partnerUser, partnerData }) => {
  const useAuthHeader = authHeader();

  const handleError = HandleError();
  const displayToast = HandleToast();

  const [isLoaded, setIsLoaded] = useState(false);
  const [processing, setProcessing] = useState(false);

  const [data, setData] = useState([]);

  const [newTemplate, setNewTemplate] = useState(false);
  const [newTemplateName, setNewTemplateName] = useState("");

  const [manageTemplate, setManageTemplate] = useState(false);

  const [addFields, setAddFields] = useState(false);

  const [activeTemplate, setActiveTemplate] = useState("");
  const [templateName, setTemplateName] = useState("");
  const [templateActive, setTemplateActive] = useState(false);
  const [templateFields, setTemplateFields] = useState([]);
  const [isMandatory, setIsMandatory] = useState(false);
  const [hasHelper, setHasHelper] = useState(false);
  const [enableOther, setEnableOther] = useState(false);
  const [helperText, setHelperText] = useState("");

  const [selectedFieldType, setSelectedFieldType] = useState({ label: "Select field type", value: "" });
  const [fieldType, setFieldType] = useState("");
  const [activeField, setActiveField] = useState({});
  const [selectFieldOptions, setSelectFieldOptions] = useState([]);
  const [manageFieldOption, setManageFieldOption] = useState(false);
  const [activeFieldOption, setActiveFieldOption] = useState({});
  const [selectOptionLabel, setSelectOptionLabel] = useState("");
  const [selectOptionValue, setSelectOptionValue] = useState("");
  const [fieldLabel, setFieldLabel] = useState("");

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: loadingLottie,
  };

  const customStyles = {
    headCells: {
      style: {
        paddingLeft: "8px", // override the cell padding for head cells
        paddingRight: "8px",
        fontWeight: "bold",
      },
    },
    cells: {
      style: {
        paddingLeft: "8px", // override the cell padding for data cells
        paddingRight: "8px",
      },
    },
  };

  const columns = [
    {
      name: "Template name",
      sortable: true,
      width: "75%",
      selector: (row) => row.name,
      cell: (row) => (
        <Box
          className="link"
          onClick={() => {
            setActiveTemplate(row.uuid);
            setManageTemplate(true);
          }}
          sx={{ fontSize: "13px" }}
        >
          {row.name}
        </Box>
      ),
    },
    {
      name: "Template type",
      sortable: true,
      selector: (row) => row.template_type,
      cell: (row) => (
        <Box>
          <Typography sx={{ fontSize: "13px" }}>{row.template_type == 1 ? "Freeform" : "Workflow"}</Typography>
        </Box>
      ),
    },
    {
      name: "Status",
      sortable: true,
      right: "true",
      selector: (row) => row.active,
      cell: (row) => (
        <Box>
          {row.active ? (
            <Chip className="square-chip" label="Active" color="success" size="small" />
          ) : (
            <Chip className="square-chip" label="Inactive" color="error" size="small" />
          )}
        </Box>
      ),
    },
  ];

  const getTemplates = async () => {
    try {
      const response = await partner.post(`/v1/client/tasks/templates/list`, JSON.stringify({}), {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Basic " + useAuthHeader.base64encodedData,
          service: "partners",
        },

        //withCredentials: true,
      });

      if (response.data.results > 0) {
        setData(response.data.entries);
      } else {
        setData([]);
      }

      setIsLoaded(true);
    } catch (err) {
      console.log(err);
      handleError(err);
    }
  };

  useEffect(() => {
    getTemplates();
  }, []);

  return (
    <>
      {isLoaded ? (
        <>
          <Box sx={{ borderTop: 1, borderColor: "divider" }}>
            <Box sx={{ p: 2, display: "flex", justifyContent: "space-between" }}>
              <Typography variant="h6">{newTemplate ? "Create a new template" : manageTemplate ? "Manage template" : "Task Templates"}</Typography>
              <Box sx={{ display: "flex", gap: 1 }}>
                <Button
                  onClick={() => {
                    manageTemplate ? setManageTemplate(false) : newTemplate ? setNewTemplate(false) : setNewTemplate(true);
                  }}
                  variant="contained"
                  color="primary"
                >
                  {newTemplate ? "Close" : manageTemplate ? "Close" : "Create template"}
                </Button>
              </Box>
            </Box>
            <Divider />
            {newTemplate ? (
              <Box>
                <NewTemplate
                  partner={partner}
                  partnerUser={partnerUser}
                  partnerData={partnerData}
                  getTemplates={getTemplates}
                  setNewTemplate={setNewTemplate}
                  setManageTemplate={setManageTemplate}
                  setActiveTemplate={setActiveTemplate}
                />
              </Box>
            ) : manageTemplate ? (
              <Box>
                <ManageTemplate
                  partner={partner}
                  partnerUser={partnerUser}
                  partnerData={partnerData}
                  activeTemplate={activeTemplate}
                  setManageTemplate={setManageTemplate}
                  getTemplates={getTemplates}
                  setActiveTemplate={setActiveTemplate}
                />
              </Box>
            ) : (
              <DataTable
                columns={columns}
                data={data}
                customStyles={customStyles}
                noDataComponent={<NoResults message={`Create your first template to get started`} />}
                pagination
                striped
              />
            )}
          </Box>
        </>
      ) : (
        <Box>
          <Lottie speed={2.0} options={defaultOptions} height={200} width={200} />
        </Box>
      )}
    </>
  );
};

export default Tasks;
