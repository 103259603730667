import { React, useEffect, useState } from "react";

import authHeader from "../../../api/hostedAuthHeader";

import HandleError from "../../../components/functions/HostedHandleError";
import HandleToast from "../../../components/functions/HandleToast";

import Lottie from "react-lottie";
import loadingLottie from "../../../components/lotties/loading-dots.json";

import { signal } from "@preact/signals-react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import moment from "moment";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { MuiTelInput } from "mui-tel-input";

import { Percent } from "@mui/icons-material";
import {
  Box,
  Divider,
  IconButton,
  Typography,
  Button,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  DialogContentText,
  Collapse,
  ToggleButtonGroup,
  ToggleButton,
  Chip,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";

import Address from "./sow-fields/Address";
import CountrySelect from "../../../components/form-elements/CountrySelect";
import CurrencySelect from "../../../components/form-elements/CurrencySelect";
import TrueFalse from "./sow-fields/TrueFalse";
import YesNo from "./sow-fields/YesNo";
import Money from "./sow-fields/Money";
import SingleFileUpload from "./sow-fields/SingleFileUpload";
import MultipleFileUpload from "./sow-fields/MultipleFileUpload";
import CorporateEntity from "./sow-fields/CorporateEntity";
import Individual from "./sow-fields/Individual";
import MultipleCorporateEntities from "./sow-fields/MultipleCorporateEntities";
import MultipleIndividuals from "./sow-fields/MultipleIndividuals";

const sowActiveView = signal("");

const SourceOfWealth = ({
  partner,
  caseUUID,
  entityData,
  caseData,
  activeView,
  getPerson,
  requestData,
  getRequestData,
  getCase,
}) => {
  const useAuthHeader = authHeader();
  const handleError = HandleError();
  const displayToast = HandleToast();

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: loadingLottie,
  };

  const [isLoaded, setIsLoaded] = useState(false);
  const [processing, setProcessing] = useState(false);

  const [sowRequestData, setSowRequestData] = useState(null);

  const [answersData, setAnswersData] = useState(null);

  const getSowRequestData = async () => {
    try {
      const response = await partner.post(
        `/v1/screening/hosted/data/sow`,
        JSON.stringify({
          case_uuid: caseData.value.case_uuid,
        }),
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Basic " + useAuthHeader.base64encodedData,
            service: "hosted",
          },

          //withCredentials: true,
        }
      );

      setSowRequestData(response.data);

      if (answersData == null) {
        sowActiveView.value = response.data.questions[0].uuid;

        response.data.questions.map((question) => {
          if (question.has_response) {
            let existingAnswer;

            switch (question.field_type) {
              case 3:
                let parsedAnswer3 = JSON.parse(question.answer?.response);
                existingAnswer = parsedAnswer3.value;

                if (existingAnswer == -1) {
                  setAnswersData((curr) => ({
                    ...curr,
                    [question.uuid + "-other"]: parsedAnswer3.label ? parsedAnswer3.label : "",
                  }));
                }

                break;

              case 4:
                let parsedAnswer4 = JSON.parse(question.answer?.response);

                if (parsedAnswer4.length > 0) {
                  // loop through the array and push the value into a new array
                  let answerArray = [];

                  parsedAnswer4.map((answer) => {
                    answerArray.push(answer.value);

                    if (answer.value == -1) {
                      setAnswersData((curr) => ({
                        ...curr,
                        [question.uuid + "-other"]: answer.label ? answer.label : "",
                      }));
                    }
                  });

                  existingAnswer = answerArray;
                }

                break;

              case 5:
                existingAnswer = {
                  moment: moment(question.answer.response),
                  date: moment(question.answer.response).format("YYYY-MM-DD"),
                };
                break;

              case 7:
                let parsedAnswer7 = JSON.parse(question.answer?.response);

                existingAnswer = {
                  phone_value: parsedAnswer7.telephone?.phone_value,
                  country_code: parsedAnswer7.telephone?.country_code,
                  dialling_code: parsedAnswer7.telephone?.dialling_code,
                  national_number: parsedAnswer7.telephone?.national_number,
                };

                break;

              case 8:
                let parsedAnswer8 = JSON.parse(question.answer?.response);

                existingAnswer = {
                  line_one: parsedAnswer8.address.line_one,
                  line_two: parsedAnswer8.address.line_two,
                  line_three: parsedAnswer8.address.line_three,
                  line_four: parsedAnswer8.address.line_four,
                  line_five: parsedAnswer8.address.line_five,
                  line_six: parsedAnswer8.address.line_six,
                  city: parsedAnswer8.address.city,
                  state: parsedAnswer8.address.state,
                  code: parsedAnswer8.address.code,
                  country: parsedAnswer8.address.country,
                };

                break;

              case 9:
                let parsedAnswer9 = JSON.parse(question.answer?.response);

                existingAnswer = parsedAnswer9.country;
                break;

              case 10:
                let parsedAnswer10 = JSON.parse(question.answer?.response);

                let countryArray = [];

                parsedAnswer10.countries.map((country) => {
                  countryArray.push(country);
                });

                existingAnswer = countryArray;

                break;

              case 13:
                let parsedAnswer13 = JSON.parse(question.answer?.response);
                existingAnswer = parsedAnswer13.currency;

                break;

              case 14:
                let parsedAnswer14 = JSON.parse(question.answer?.response);

                let currencyArray = [];

                parsedAnswer14.currencies.map((currency) => {
                  currencyArray.push(currency);
                });

                existingAnswer = currencyArray;

                break;

              case 15:
                let parsedAnswer15 = JSON.parse(question.answer?.response);

                existingAnswer = {
                  amount: parsedAnswer15.amount,
                  currency: parsedAnswer15.currency,
                };

                break;

              case 19:
                existingAnswer = JSON.parse(question.answer?.response);

                break;

              case 20:
                let parsedAnswer20 = JSON.parse(question.answer?.response);

                if (parsedAnswer20.length > 0) {
                  existingAnswer = true;
                } else {
                  existingAnswer = false;
                }

                break;

              case 21:
                let parsedAnswer21 = JSON.parse(question.answer?.response);

                existingAnswer = {
                  registration_name: parsedAnswer21.Corporation.registration_name,
                  selected_corporation: parsedAnswer21.Corporation.selected_corporation,
                  registration_country: parsedAnswer21.Corporation.registration_country,
                  registration_date: parsedAnswer21.Corporation.registration_date,
                  registration_reference: parsedAnswer21.Corporation.registration_reference,
                  registration_type: parsedAnswer21.Corporation.registration_type,
                };

                break;

              case 22:
                let parsedAnswer22 = JSON.parse(question.answer?.response);

                existingAnswer = {
                  title: parsedAnswer22.person.title,
                  first_name: parsedAnswer22.person.first_name,
                  middle_names: parsedAnswer22.person.middle_names,
                  last_name: parsedAnswer22.person.last_name,
                };

                break;

              case 23:
                let parsedAnswer23 = JSON.parse(question.answer?.response);

                existingAnswer = [];

                parsedAnswer23.corporations.map((corporation) => {
                  existingAnswer.push({
                    registration_name: corporation.registration_name,
                    selected_corporation: corporation.selected_corporation,
                    registration_country: corporation.registration_country,
                    registration_date: corporation.registration_date,
                    registration_reference: corporation.registration_reference,
                    registration_type: corporation.registration_type,
                  });
                });

                break;

              case 24:
                let parsedAnswer24 = JSON.parse(question.answer?.response);

                existingAnswer = [];

                parsedAnswer24.people.map((person) => {
                  existingAnswer.push({
                    title: person.title,
                    first_name: person.first_name,
                    middle_names: person.middle_names,
                    last_name: person.last_name,
                  });
                });

                break;

              default:
                existingAnswer = question.answer.response;
                break;
            }

            setAnswersData((curr) => ({
              ...curr,
              [question.uuid]: existingAnswer ? existingAnswer : "",
            }));
          } else {
            let existingAnswer;

            switch (question.field_type) {
              case 3:
                existingAnswer = null;
                break;
              case 4:
                existingAnswer = [];
                break;
              case 5:
                existingAnswer = null;

                break;

              case 7:
                existingAnswer = null;

                break;

              case 8:
                existingAnswer = null;

                break;

              case 9:
                existingAnswer = null;
                break;

              case 10:
                existingAnswer = [];

                break;

              case 13:
                existingAnswer = null;

                break;

              case 14:
                existingAnswer = [];

                break;

              case 15:
                existingAnswer = null;

                break;

              case 21:
                existingAnswer = null;

                break;

              case 22:
                existingAnswer = null;

                break;

              case 23:
                existingAnswer = [];

                break;

              case 24:
                existingAnswer = [];

                break;

              default:
                existingAnswer = "";
                break;
            }

            setAnswersData((curr) => ({
              ...curr,
              [question.uuid]: null,
            }));
          }
        });
      }
      setIsLoaded(true);
    } catch (err) {
      console.log(err);
      handleError(err);
    }
  };

  const handleChange = (event) => {
    setAnswersData((curr) => ({
      ...curr,
      [event.target.name]: event.target.value,
    }));
  };

  const handleSaveAnswer = async (e, questionUUID, index) => {
    e.preventDefault();
    setProcessing(true);

    try {
      const response = await partner.post(
        `/v1/screening/case/kyc/source-of-wealth/answer`,
        JSON.stringify({
          case_uuid: sowRequestData.request_uuid,
          question_uuid: questionUUID,
          answer: answersData,
        }),
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Basic " + useAuthHeader.base64encodedData,
            service: "hosted",
          },

          // withCredentials: true,
        }
      );

      getSowRequestData();
      getRequestData();

      displayToast("Answer saved", () => {
        setProcessing(false);
        sowActiveView.value = sowRequestData.questions[index + 1].uuid;
      });
    } catch (err) {
      console.log(err);
      handleError(err);
      setProcessing(false);
    }
  };

  useEffect(() => {
    getSowRequestData();
  }, []);

  useEffect(() => {
    console.log(sowRequestData);
  }, [sowRequestData]);

  useEffect(() => {
    console.log(answersData);
  }, [answersData]);

  return isLoaded ? (
    <Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          borderBottom: 1,
          borderColor: "divider",
        }}
      >
        <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center", p: 2 }}>
          <Typography variant="subtitle2">Income and finances</Typography>
        </Box>
        <Box
          sx={{ display: "flex", flexDirection: "column", justifyContent: "center", p: 2 }}
        ></Box>
      </Box>

      <Box sx={{}}>
        {sowRequestData?.questions.map((question, index) => (
          <Box
            key={index}
            sx={{ display: sowActiveView.value == question.uuid ? "block" : "none" }}
          >
            <Box sx={{ p: 2, display: "flex", flexDirection: "column", gap: 2 }}>
              <Typography variant="subtitle2">{question.label}</Typography>

              {question.field_type == 1 && (
                <Box>
                  <ReactQuill
                    sx={{ border: 1, borderColor: "divider" }}
                    theme="snow"
                    value={answersData[question.uuid] ? answersData[question.uuid] : ""}
                    onChange={(value) =>
                      setAnswersData((curr) => ({
                        ...curr,
                        [question.uuid]: value,
                      }))
                    }
                    style={{ minHeight: "300px", border: "1px solid #0000001f" }}
                  />
                </Box>
              )}

              {question.field_type == 2 && (
                <Box>
                  <TextField
                    fullWidth
                    label={`Answer`}
                    variant="outlined"
                    name={question.uuid}
                    value={answersData[question.uuid] ? answersData[question.uuid] : ""}
                    onChange={(e) => handleChange(e)}
                  />
                </Box>
              )}

              {question.field_type == 3 && (
                <Box>
                  <FormControl fullWidth>
                    <InputLabel id={`${question.uuid}-title`}>Select an option</InputLabel>
                    <Select
                      labelId={`${question.uuid}-title`}
                      value={answersData[question.uuid] ? answersData[question.uuid] : ""}
                      name={question.uuid}
                      label="Select an option"
                      onChange={(e) => handleChange(e)}
                    >
                      {question.options?.map((option, index) => (
                        <MenuItem key={index} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                      {question.enable_other && <MenuItem value={-1}>Other</MenuItem>}
                    </Select>
                  </FormControl>

                  {question.enable_other && (
                    <Collapse
                      sx={{ mt: 2 }}
                      in={answersData ? answersData[question.uuid] == -1 : false}
                      exit={answersData ? answersData[question.uuid] != -1 : true}
                    >
                      <Box sx={{ mb: 2 }}>
                        <TextField
                          fullWidth
                          label="Other"
                          variant="outlined"
                          name={`${question.uuid}-other`}
                          value={answersData[`${question.uuid}-other`]}
                          onChange={(e) => handleChange(e)}
                        />
                      </Box>
                    </Collapse>
                  )}
                </Box>
              )}

              {question.field_type == 4 && (
                <Box>
                  <FormControl fullWidth>
                    <InputLabel id={`${question.uuid}-title`}>Select multiple options</InputLabel>
                    <Select
                      labelId={`${question.uuid}-title`}
                      value={answersData[question.uuid] ? answersData[question.uuid] : []}
                      name={question.uuid}
                      label="Select multiple options"
                      multiple
                      onChange={(e) => handleChange(e)}
                    >
                      {question.options?.map((option, index) => (
                        <MenuItem key={index} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                      {question.enable_other && <MenuItem value={-1}>Other</MenuItem>}
                    </Select>
                  </FormControl>

                  {question.enable_other && (
                    <Collapse
                      sx={{ mt: 2 }}
                      in={answersData[question.uuid]?.includes(-1)}
                      exit={!answersData[question.uuid]?.includes(-1)}
                    >
                      <Box sx={{ mb: 2 }}>
                        <TextField
                          fullWidth
                          label="Other"
                          variant="outlined"
                          name={`${question.uuid}-other`}
                          value={answersData[`${question.uuid}-other`]}
                          onChange={(e) => handleChange(e)}
                        />
                      </Box>
                    </Collapse>
                  )}
                </Box>
              )}

              {question.field_type == 5 && (
                <Box>
                  <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale={locale}>
                    <DatePicker
                      fullWidth
                      openTo="year"
                      label={`Date`}
                      value={
                        answersData[question.uuid]?.moment
                          ? answersData[question.uuid].moment
                          : null
                      }
                      onChange={(date) => {
                        setAnswersData((curr) => ({
                          ...curr,
                          [question.uuid]: {
                            date: date.format("YYYY-MM-DD"),
                            moment: date,
                          },
                        }));
                      }}
                      slotProps={{ textField: { fullWidth: true } }}
                    />
                  </LocalizationProvider>
                </Box>
              )}

              {question.field_type == 6 && (
                <Box>
                  <TextField
                    fullWidth
                    type="email"
                    label="Email address"
                    variant="outlined"
                    name={question.uuid}
                    value={answersData[question.uuid] ? answersData[question.uuid] : ""}
                    onChange={(e) => handleChange(e)}
                  />
                </Box>
              )}

              {question.field_type == 7 && (
                <Box>
                  <MuiTelInput
                    fullWidth
                    defaultCountry="IM"
                    value={
                      answersData[question.uuid]?.phone_value
                        ? answersData[question.uuid].phone_value
                        : ""
                    }
                    onChange={(phone, meta) => {
                      setAnswersData((curr) => ({
                        ...curr,
                        [question.uuid]: {
                          phone_value: phone,
                          country_code: meta.countryCode,
                          dialling_code: meta.countryCallingCode,
                          national_number: meta.nationalNumber,
                        },
                      }));
                    }}
                  />
                </Box>
              )}

              {question.field_type == 8 && (
                <Box>
                  <Address
                    partnerUUID={requestData.partner_uuid}
                    questionUUID={question.uuid}
                    setAnswersData={setAnswersData}
                    answersData={answersData}
                    handleSaveAnswer={(e) => handleSaveAnswer(e, question.uuid, index)}
                    processing={processing}
                    setProcessing={setProcessing}
                    currentIndex={index}
                  />
                </Box>
              )}

              {question.field_type == 9 && (
                <Box>
                  <CountrySelect
                    isEdd={question.uuid}
                    value={answersData}
                    setValue={setAnswersData}
                  />
                </Box>
              )}

              {question.field_type == 10 && (
                <Box>
                  <CountrySelect
                    isEdd={question.uuid}
                    value={answersData}
                    setValue={setAnswersData}
                    isMultiple={true}
                  />
                </Box>
              )}

              {question.field_type == 11 && (
                <Box>
                  <TrueFalse
                    questionUUID={question.uuid}
                    answersData={answersData}
                    setAnswersData={setAnswersData}
                  />
                </Box>
              )}

              {question.field_type == 12 && (
                <Box>
                  <YesNo
                    questionUUID={question.uuid}
                    answersData={answersData}
                    setAnswersData={setAnswersData}
                  />
                </Box>
              )}

              {question.field_type == 13 && (
                <Box>
                  <CurrencySelect
                    isEdd={question.uuid}
                    value={answersData}
                    setValue={setAnswersData}
                  />
                </Box>
              )}

              {question.field_type == 14 && (
                <Box>
                  <CurrencySelect
                    isEdd={question.uuid}
                    value={answersData}
                    setValue={setAnswersData}
                    isMultiple={true}
                  />
                </Box>
              )}

              {question.field_type == 15 && (
                <Box>
                  <Money
                    questionUUID={question.uuid}
                    answersData={answersData}
                    setAnswersData={setAnswersData}
                  />
                </Box>
              )}

              {question.field_type == 16 && (
                <Box>
                  <TextField
                    fullWidth
                    type="number"
                    InputProps={{ step: "1" }}
                    label={question.label}
                    variant="outlined"
                    name={question.uuid}
                    value={answersData[question.uuid] ? answersData[question.uuid] : 0}
                    onChange={(e) => handleChange(e)}
                  />
                </Box>
              )}

              {question.field_type == 17 && (
                <Box>
                  <TextField
                    fullWidth
                    type="number"
                    InputProps={{ step: "0.00000001" }}
                    label={question.label}
                    variant="outlined"
                    name={question.uuid}
                    value={answersData[question.uuid] ? answersData[question.uuid] : 0}
                    onChange={(e) => handleChange(e)}
                  />
                </Box>
              )}

              {question.field_type == 18 && (
                <Box>
                  <TextField
                    fullWidth
                    type="number"
                    InputProps={{ step: "0.0001", endAdornment: <Percent /> }}
                    label={question.label}
                    variant="outlined"
                    name={question.uuid}
                    value={answersData[question.uuid] ? answersData[question.uuid] : 0}
                    onChange={(e) => handleChange(e)}
                  />
                </Box>
              )}

              {question.field_type == 19 && (
                <Box>
                  <SingleFileUpload
                    questionUUID={question.uuid}
                    setAnswersData={setAnswersData}
                    answersData={answersData}
                    getRequestData={() => getSowRequestData()}
                  />
                </Box>
              )}

              {question.field_type == 20 && (
                <Box>
                  <MultipleFileUpload
                    questionUUID={question.uuid}
                    setAnswersData={setAnswersData}
                    answersData={answersData}
                    getRequestData={() => getSowRequestData()}
                  />
                </Box>
              )}

              {question.field_type == 21 && (
                <Box>
                  <CorporateEntity
                    questionUUID={question.uuid}
                    partnerUUID={requestData.partner_uuid}
                    answersData={answersData}
                    setAnswersData={setAnswersData}
                  />
                </Box>
              )}

              {question.field_type == 22 && (
                <Box>
                  <Individual
                    questionUUID={question.uuid}
                    answersData={answersData}
                    setAnswersData={setAnswersData}
                  />
                </Box>
              )}

              {question.field_type == 23 && (
                <Box>
                  <MultipleCorporateEntities
                    questionUUID={question.uuid}
                    answersData={answersData}
                    setAnswersData={setAnswersData}
                    partnerUUID={requestData.partner_uuid}
                    handleSaveAnswer={(e) => handleSaveAnswer(e, question.uuid, index)}
                  />
                </Box>
              )}

              {question.field_type == 24 && (
                <Box>
                  <MultipleIndividuals
                    questionUUID={question.uuid}
                    questionIndex={index}
                    answersData={answersData}
                    setAnswersData={setAnswersData}
                    handleSaveAnswer={(e) => handleSaveAnswer(e, question.uuid, index)}
                    processing={processing}
                  />
                </Box>
              )}
            </Box>

            <Divider />

            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                py: 1,
                px: 1,
              }}
            >
              <Box>
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={() => (sowActiveView.value = sowRequestData.questions[index - 1].uuid)}
                  disabled={index == 0}
                >
                  Previous Question
                </Button>
              </Box>
              <Box>
                <LoadingButton
                  variant="contained"
                  disableElevation
                  color="success"
                  loading={processing}
                  onClick={(e) => {
                    handleSaveAnswer(e, sowActiveView.value, index);
                  }}
                  disabled={index == sowRequestData.questions.length - 1}
                >
                  Save & Continue
                </LoadingButton>
              </Box>
            </Box>
          </Box>
        ))}
      </Box>
    </Box>
  ) : (
    <Box>
      <Lottie speed={2.0} options={defaultOptions} height={200} width={200} />
    </Box>
  );
};

export default SourceOfWealth;
