import { React, useState, useEffect } from "react";

import getSymbolFromCurrency from "currency-symbol-map";

import { Box, Collapse, TextField } from "@mui/material";
import CurrencyTextField from "@puhl/mui-currency-textfield";

import CurrencySelect from "../../../../../../../../components/form-elements/CurrencySelect";

const Money = ({ currencyValue, setCurrencyValue, amountValue, setAmountValue }) => {
  const [currencySymbol, setCurrencySymbol] = useState("");

  useEffect(() => {
    if (currencyValue) {
      setCurrencySymbol(getSymbolFromCurrency(currencyValue.value));
    } else {
      setCurrencySymbol("");
      setAmountValue("");
    }
  }, [currencyValue]);

  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
      <CurrencySelect value={currencyValue} setValue={setCurrencyValue} />

      <Collapse in={currencySymbol != ""} exit={currencySymbol == ""}>
        <CurrencyTextField
          fullWidth
          label="Amount"
          variant="outlined"
          value={amountValue}
          currencySymbol={currencySymbol}
          outputFormat="number"
          minimumValue="0"
          decimalCharacter="."
          digitGroupSeparator=","
          onChange={(event, value) => setAmountValue(value)}
        />
      </Collapse>
    </Box>
  );
};

export default Money;
