import { React, useEffect, useState } from "react";

import central from "../../../../../api/central";
import authHeader from "../../../../../api/authHeader";

import HandleError from "../../../../../components/functions/HandleError";
import HandleToast from "../../../../../components/functions/HandleToast";

import Lottie from "react-lottie";
import loadingLottie from "../../../../../components/lotties/loading-dots.json";

import DataTable from "react-data-table-component";

import {
  Typography,
  Box,
  Button,
  Divider,
  Card,
  Avatar,
  CardHeader,
  CardContent,
  IconButton,
  Alert,
  Fade,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import { Grid, TextField, FormControl, InputLabel, MenuItem, Select, ToggleButton, ToggleButtonGroup, Chip } from "@mui/material";
import { LoadingButton } from "@mui/lab";

import { Add, Close, Delete, Edit } from "@mui/icons-material";

const Industries = ({ partner, partnerUser, partnerData }) => {
  const useAuthHeader = authHeader();

  const handleError = HandleError();
  const displayToast = HandleToast();

  const [isLoaded, setIsLoaded] = useState(false);
  const [processing, setProcessing] = useState(false);
  const [data, setData] = useState([]);
  const [optionsData, setOptionsData] = useState([]);

  const [manageIndustries, setManageIndustries] = useState(false);

  const [removeDialog, setRemoveDialog] = useState(false);
  const [removeIndustry, setRemoveIndustry] = useState("");
  const [removeIndustryLabel, setRemoveIndustryLabel] = useState("");

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: loadingLottie,
  };

  const customStyles = {
    headCells: {
      style: {
        paddingLeft: "8px", // override the cell padding for head cells
        paddingRight: "8px",
        fontWeight: "bold",
      },
    },
    cells: {
      style: {
        paddingLeft: "8px", // override the cell padding for data cells
        paddingRight: "8px",
      },
    },
  };

  const columns = [
    {
      name: "Industry name",
      sortable: true,
      width: "75%",
      selector: (row) => row.label,
    },
    {
      name: "Industry code",
      sortable: true,
      selector: (row) => row.code,
    },
    {
      name: "Code type",
      sortable: true,
      right: "true",
      selector: (row) => row.code_type,
      cell: (row) => (
        <Box sx={{ display: "flex", flexDirection: "column", gap: 1, py: 1 }}>
          <Box>
            <Chip
              className="square-chip"
              label={row.code_type == 1 ? "SIC" : "NAICS"}
              sx={{ backgroundColor: "#55828b", color: "#ffffff", textTransform: "uppercase", width: "100%" }}
            />
          </Box>
        </Box>
      ),
    },
  ];

  const manageColumns = [
    {
      name: "Industry name",
      sortable: true,
      width: "75%",
      selector: (row) => row.description,
    },
    {
      name: "Industry code",
      sortable: true,
      selector: (row) => row.code,
    },
    {
      name: "Code type",
      sortable: true,
      right: "true",
      selector: (row) => row.code_type,
      cell: (row) => (
        <Box sx={{ display: "flex", flexDirection: "column", gap: 1, py: 1 }}>
          <Box>
            <Chip
              className="square-chip"
              label={row.code_type == 1 ? "SIC" : "NAICS"}
              sx={{ backgroundColor: "#55828b", color: "#ffffff", textTransform: "uppercase", width: "100%" }}
            />
          </Box>
        </Box>
      ),
    },
    {
      name: "Options",
      sortable: true,
      right: "true",
      width: "10%",
      selector: (row) => row.high_risk,
      cell: (row) => (
        <Box>
          {row.high_risk ? (
            <IconButton color="danger" onClick={() => removeIndustryDialog(row.uuid, row.description)}>
              <Delete />
            </IconButton>
          ) : (
            <IconButton color="success" onClick={() => handleAddIndustry(row.uuid)}>
              <Add />
            </IconButton>
          )}
        </Box>
      ),
    },
  ];

  const getPartnerIndustries = async () => {
    try {
      const response = await partner.post(`/v1/screening/risk-assessment/industries/list`, JSON.stringify({}), {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Basic " + useAuthHeader.base64encodedData,
          service: "partners",
        },

        //withCredentials: true,
      });

      if (response.data.results > 0) {
        setData(response.data.entries);
      } else {
        setData();
      }
      setIsLoaded(true);
    } catch (err) {
      handleError(err);
    }
  };

  const handleRemoveIndustry = async () => {
    try {
      const response = await partner.post(
        `/v1/screening/risk-assessment/industries/remove`,
        JSON.stringify({
          code_uuid: removeIndustry,
        }),
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Basic " + useAuthHeader.base64encodedData,
            service: "partners",
          },

          //withCredentials: true,
        }
      );

      getOptions();
      getPartnerIndustries();
      setRemoveIndustry("");
      setRemoveDialog(false);

      displayToast("Industry removed", () => {
        setManageIndustries(false);
      });
    } catch (err) {
      handleError(err);
    }
  };

  const handleAddIndustry = async (codeUUID) => {
    try {
      const response = await partner.post(
        `/v1/screening/risk-assessment/industries/add`,
        JSON.stringify({
          code_uuid: codeUUID,
        }),
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Basic " + useAuthHeader.base64encodedData,
            service: "partners",
          },

          //withCredentials: true,
        }
      );
      getOptions();
      getPartnerIndustries();
      displayToast("Industry added", () => {
        setManageIndustries(false);
      });
    } catch (err) {
      handleError(err);
    }
  };

  const getOptions = async () => {
    try {
      const response = await central.post(`/v1/service/corporations/industry-codes`, JSON.stringify({ code_type: 0 }), {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Basic " + useAuthHeader.base64encodedData,
          service: "partners",
          partner_uuid: partnerUser.value.partner_uuid,
        },

        //withCredentials: true,
      });

      if (response.data.results > 0) {
        var sourceOptions = [];

        response.data.entries.map((entry) => {
          var high_risk = false;

          if (data?.length > 0) {
            data.map((source) => {
              if (source.code_uuid == entry.uuid) {
                high_risk = true;
              }
              if (high_risk) {
                return;
              }
            });
          }

          sourceOptions = [
            ...sourceOptions,
            { description: entry.description, code: entry.code, uuid: entry.uuid, high_risk: high_risk, code_type: entry.code_type },
          ];
        });

        var sortedOptions = sourceOptions.sort((a, b) => {
          if (a.high_risk > b.high_risk) {
            return -1;
          }
          if (a.high_risk < b.high_risk) {
            return 1;
          }

          return 0;
        });

        setOptionsData(sortedOptions);
      } else {
        setOptionsData([]);
      }
    } catch (err) {
      console.log(err);
      handleError(err);
    }
  };

  const removeIndustryDialog = (uuid, industryLabel) => {
    setRemoveIndustryLabel(industryLabel);
    setRemoveDialog(true);
    setRemoveIndustry(uuid);
  };

  useEffect(() => {
    getPartnerIndustries();
  }, []);

  return (
    <>
      {isLoaded ? (
        <>
          <Box sx={{ borderTop: 1, borderColor: "divider" }}>
            <Box sx={{ p: 2, display: "flex", justifyContent: "space-between" }}>
              <Box sx={{ display: "flex", gap: 4 }}>
                <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
                  <Typography variant="h6">High risk industries</Typography>
                </Box>

                <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center", width: "fit-content" }}>
                  <Button
                    onClick={() => {
                      getOptions();
                      setManageIndustries(!manageIndustries);
                    }}
                    size="small"
                    variant="contained"
                    color="primary"
                    startIcon={manageIndustries ? <Close /> : <Edit />}
                  >
                    {manageIndustries ? "Close" : "Edit selected industries"}
                  </Button>
                </Box>
              </Box>
            </Box>
            <Divider />
            {manageIndustries ? (
              <DataTable columns={manageColumns} data={optionsData} customStyles={customStyles} pagination striped />
            ) : (
              <DataTable columns={columns} data={data} customStyles={customStyles} pagination striped />
            )}
          </Box>

          <Dialog
            open={removeDialog}
            onClose={() => setRemoveDialog(false)}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">Are you sure?</DialogTitle>
            <DialogContent>
              <Box>
                <DialogContentText sx={{ mb: 1 }} id="alert-dialog-description">
                  Are you sure you want to remove this industry?
                </DialogContentText>
              </Box>
            </DialogContent>
            <DialogActions>
              <LoadingButton disableElevation loading={processing} onClick={() => setRemoveDialog(false)} color="primary">
                Cancel
              </LoadingButton>

              <LoadingButton
                disableElevation
                sx={{ color: "#ffffff" }}
                loading={processing}
                onClick={(e) => {
                  handleRemoveIndustry();
                }}
                variant="contained"
                color="danger"
                autoFocus
              >
                Confirm
              </LoadingButton>
            </DialogActions>
          </Dialog>
        </>
      ) : (
        <Box>
          <Lottie speed={2.0} options={defaultOptions} height={200} width={200} />
        </Box>
      )}
    </>
  );
};

export default Industries;
