import {
  Box,
  Divider,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from "@mui/material";
import { useMemo, useReducer, useState } from "react";
import ReactQuill from "react-quill";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LoadingButton } from "@mui/lab";
import { StepFrom } from "./step-form";
import PropTypes from "prop-types";
import { getClientTasksTemplate, postCreateTask } from "../../../../../api/services";

import Lottie from "react-lottie";
import loadingLottie from "../../../../../components/lotties/loading-dots.json";
import { StepsLists } from "./steps-lists";
import moment from "moment";
import { useGetPartnerUsersList } from "../../../../../hooks/use-get-partner-users-list";
import { useGetClientTaskTemplates } from "../../../../../hooks/use-get-client-task-templates";
import { useDebounceCallback } from "usehooks-ts";
import HandleToast from "../../../../../components/functions/HandleToast";
import { RenderWhen } from "../../../../../components/render-when";

const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: loadingLottie,
};

const defaultNewTaskFormState = {
  showTemplates: "yes", // yes or no
  saveAsTemplate: "no", // yes or no
  taskData: {},
  taskType: "freeform", // Workflow or Freeform
  dueDate: "",
  withDueTime: false,
  dueTime: "",
  assignToUserUUID: "",
  stepsFormState: {
    data: null,
    open: false,
  },
  steps: [],
  updateStepId: null,
  /**
   * step:{
      uuid: string;
      label: string;
      description: string;
      requires_confirmation: boolean;
      requires_file: boolean;
      file_description: string;
      order: number;
      active: boolean;
   * */
};

function newTaskFormReducer(state, action) {
  switch (action.type) {
    case "SET_SHOW_TEMPLATES":
      return {
        ...state,
        showTemplates: action.payload,
        taskData: {},
        taskType: action.payload === "yes" ? "" : "freeform", // Workflow or Freeform
        dueDate: "",
        withDueTime: false,
        dueTime: "",
        assignToUserUUID: "",
        stepsFormState: {
          data: null,
          open: false,
        },
        steps: [],
      };
    case "SET_TASK_DATA":
      return { ...state, taskData: action.payload };
    case "SET_SAVE_AS_TEMPLATE":
      return { ...state, saveAsTemplate: action.payload };
    case "SET_TASK_TYPE":
      return { ...state, taskType: action.payload };
    case "SET_WITH_DUE_TIME":
      return { ...state, withDueTime: action.payload };
    case "SET_DUE_TIME":
      return { ...state, dueTime: action.payload };
    case "SET_DUE_DATE":
      return { ...state, dueDate: action.payload };
    case "SET_ASSIGN_TO_USER":
      return { ...state, assignToUserUUID: action.payload };
    case "STEPS_FORM_STATE":
      return { ...state, stepsFormState: action.payload };
    case "SET_UPDATE_STEP_DATA":
      return { ...state, updateStepData: action.payload };
    case "ADD_STEP":
      return { ...state, steps: [...state.steps, action.payload] };
    case "REMOVE_STEP":
      return {
        ...state,
        steps: state.steps.filter((step) => step.id !== action.payload),
      };
    case "UPDATE_STEP":
      return {
        ...state,
        steps: state.steps.map((step) => (step.id === action.payload.id ? action.payload : step)),
      };

    default:
      return state;
  }
}

export function CreateTaskTab({ clientData, onChangeTab }) {
  const displayToast = HandleToast();
  const [locale, setLocale] = useState("en-gb");
  const [loading, setLoading] = useState(false);
  const { partnerUsersList } = useGetPartnerUsersList();
  const { clientTaskTemplates } = useGetClientTaskTemplates();
  const [newTaskFormState, newTaskFormDispatch] = useReducer(
    newTaskFormReducer,
    defaultNewTaskFormState
  );

  const debouncedNewTaskFormDispatch = useDebounceCallback(newTaskFormDispatch, 500);

  const disableSaveButton = useMemo(() => {
    if (newTaskFormState?.showTemplates === "yes") {
      return !newTaskFormState?.taskData?.uuid;
    }

    if (newTaskFormState?.showTemplates === "no") {
      return !newTaskFormState?.taskData?.name || !newTaskFormState?.taskData?.description;
    }

    return true;
  }, [newTaskFormState]);

  function onUseTemplateChange(value) {
    newTaskFormDispatch({ type: "SET_SHOW_TEMPLATES", payload: value });
  }

  function onStepClick(stepData) {
    newTaskFormDispatch({
      type: "STEPS_FORM_STATE",
      payload: {
        data: stepData,
        open: true,
      },
    });
  }

  async function onChangeSelectedTemplate(e) {
    const selectedTemplateId = e.target.value;

    if (!selectedTemplateId) {
      newTaskFormDispatch({
        type: "SET_TASK_DATA",
        payload: {},
      });

      return;
    }

    try {
      const taskData = await getClientTasksTemplate(selectedTemplateId);
      newTaskFormDispatch({
        type: "SET_TASK_DATA",
        payload: taskData,
      });
    } catch (error) {
      console.error(error);
    }
  }

  async function createTask() {
    const taskBody = {
      client_uuid: clientData?.v?.client_uuid,
      assigned_user_uuid: newTaskFormState?.assignToUserUUID,
      due_date: newTaskFormState?.dueDate,
      with_time: newTaskFormState?.withDueTime,
      due_time: newTaskFormState?.dueTime,
    };

    try {
      if (newTaskFormState?.taskData?.uuid) {
        setLoading(true);
        await postCreateTask({
          template_uuid: newTaskFormState?.taskData?.uuid,
          ...taskBody,
        });
        onChangeTab("1");
        displayToast("Task created successfully");

        return;
      } else {
        await postCreateTask({
          ...taskBody,
          description: newTaskFormState?.taskData?.description,
          task_name: newTaskFormState?.taskData?.name,
          task_type: newTaskFormState?.taskType === "workflow" ? 2 : 1,
          // remove id from steps
          steps: newTaskFormState?.steps.map((step) => {
            const { id, ...rest } = step;
            return rest;
          }),
        });
        onChangeTab("1");
        displayToast("Task created successfully");
      }
    } catch (error) {
      console.error(error);
      displayToast("Failed to create task", undefined, "error", "error");
    } finally {
      setLoading(false);
    }
  }

  return (
    <Box>
      <Stack direction="row" justifyContent="space-between" sx={{ p: 1 }}>
        <Typography variant="h5">Use Template to Create Task</Typography>
        <ToggleButtonGroup
          size="small"
          color={newTaskFormState?.showTemplates === "yes" ? "primary" : "danger"}
          value={newTaskFormState?.showTemplates}
          exclusive
          onChange={(e, newValue) => {
            if (newValue !== null) {
              onUseTemplateChange(newValue);
            }
          }}
        >
          <ToggleButton value={"yes"}>Yes</ToggleButton>
          <ToggleButton value={"no"}>No</ToggleButton>
        </ToggleButtonGroup>
      </Stack>
      <Grid container>
        <Grid item xs={3}></Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>

        <Grid item xs={8}>
          <Box sx={{ borderRight: 1, borderRightColor: "divider" }}>
            <Box sx={{ p: 2 }}>
              <Typography variant="subtitle2">
                {newTaskFormState?.showTemplates === "yes"
                  ? "Task Details"
                  : "Provide a description and instructions for the task"}
              </Typography>
            </Box>
            <Divider />
            <RenderWhen
              when={
                !newTaskFormState?.taskData?.description &&
                newTaskFormState?.showTemplates === "yes"
              }
            >
              <Stack
                direction="column"
                justifyContent="center"
                alignItems="center"
                spacing={2}
                sx={{ p: 2 }}
              >
                <Typography variant="h6">Select a template to get started</Typography>
                <Lottie options={defaultOptions} height={150} width={150} />
              </Stack>
            </RenderWhen>
            <RenderWhen
              when={
                newTaskFormState?.taskData?.description !== "" ||
                newTaskFormState?.showTemplates === "no"
              }
            >
              <ReactQuill
                theme="snow"
                key={newTaskFormState?.showTemplates}
                modules={{
                  toolbar: newTaskFormState?.showTemplates === "yes" ? false : true,
                }}
                readOnly={newTaskFormState?.showTemplates === "yes"}
                value={newTaskFormState?.taskData?.description}
                style={{ minHeight: "300px", height: "100%" }}
                onChange={(value) => {
                  debouncedNewTaskFormDispatch({
                    type: "SET_TASK_DATA",
                    payload: {
                      ...newTaskFormState.taskData,
                      description: value,
                    },
                  });
                }}
              />
            </RenderWhen>
          </Box>
        </Grid>

        <Grid item xs={4}>
          <Stack direction="column" spacing={2} sx={{ p: 1 }}>
            <RenderWhen when={newTaskFormState?.showTemplates === "yes"}>
              <FormControl fullWidth>
                <InputLabel>Select Template</InputLabel>
                <Select fullWidth label="Select Template" onChange={onChangeSelectedTemplate}>
                  {clientTaskTemplates?.map((template) => (
                    <MenuItem key={template?.value} value={template?.value}>
                      {template?.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </RenderWhen>
            <RenderWhen when={newTaskFormState?.showTemplates === "no"}>
              <TextField
                label="Task Name"
                fullWidth
                variant="outlined"
                onChange={(e) => {
                  debouncedNewTaskFormDispatch({
                    type: "SET_TASK_DATA",
                    payload: {
                      ...newTaskFormState.taskData,
                      name: e.target.value,
                    },
                  });
                }}
              />
              <FormControl fullWidth>
                <InputLabel>Task Type</InputLabel>
                <Select
                  fullWidth
                  value={newTaskFormState?.taskType}
                  label="Task Type"
                  onChange={(e) =>
                    newTaskFormDispatch({
                      type: "SET_TASK_TYPE",
                      payload: e.target.value,
                    })
                  }
                >
                  <MenuItem value={"freeform"}>Freeform</MenuItem>
                  <MenuItem value={"workflow"}>Workflow</MenuItem>
                </Select>
              </FormControl>
            </RenderWhen>
            <Stack direction="row" justifyContent="space-between" alignItems="center">
              <Typography variant="subtitle2">With Time</Typography>
              <ToggleButtonGroup
                size="small"
                color={newTaskFormState?.withDueTime ? "primary" : "danger"}
                value={newTaskFormState?.withDueTime}
                exclusive
                onChange={(e, newValue) => {
                  if (newValue !== null) {
                    newTaskFormDispatch({
                      type: "SET_WITH_DUE_TIME",
                      payload: newValue,
                    });
                  }
                  if (newValue === false) {
                    newTaskFormDispatch({
                      type: "SET_DUE_TIME",
                      payload: "",
                    });
                  }
                }}
              >
                <ToggleButton value={true}>Yes</ToggleButton>
                <ToggleButton value={false}>No</ToggleButton>
              </ToggleButtonGroup>
            </Stack>
            <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale={locale}>
              <RenderWhen when={newTaskFormState?.withDueTime}>
                <DateTimePicker
                  onChange={(date) => {
                    newTaskFormDispatch({
                      type: "SET_DUE_DATE",
                      payload: moment(date).format("YYYY-MM-DD"),
                    });
                    newTaskFormDispatch({
                      type: "SET_DUE_TIME",
                      payload: moment(date).format("HH:mm"),
                    });
                  }}
                  fullWidth
                  label={`Due Date & Time`}
                  slotProps={{ textField: { fullWidth: true } }}
                />
              </RenderWhen>
              <RenderWhen when={!newTaskFormState?.withDueTime}>
                <DatePicker
                  onChange={(date) => {
                    newTaskFormDispatch({
                      type: "SET_DUE_DATE",
                      payload: moment(date).format("YYYY-MM-DD"),
                    });
                  }}
                  fullWidth
                  label={`Due Date`}
                  slotProps={{ textField: { fullWidth: true } }}
                />
              </RenderWhen>
            </LocalizationProvider>
            <FormControl fullWidth>
              <InputLabel>Assign to User</InputLabel>
              <Select
                fullWidth
                label="Assign to User"
                onChange={(e) => {
                  newTaskFormDispatch({
                    type: "SET_ASSIGN_TO_USER",
                    payload: e.target.value,
                  });
                }}
              >
                {partnerUsersList?.map((user) => (
                  <MenuItem key={user?.uuid} value={user?.uuid}>
                    {user?.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <RenderWhen when={newTaskFormState?.showTemplates === "no"}>
              <Stack direction="row" spacing={2} justifyContent="space-between" alignItems="center">
                <Typography variant="body1">Save as Template</Typography>
                <ToggleButtonGroup
                  size="small"
                  color={newTaskFormState?.saveAsTemplate === "yes" ? "primary" : "danger"}
                  value={newTaskFormState?.saveAsTemplate}
                  exclusive
                  onChange={(e, newValue) => {
                    if (newValue !== null) {
                      newTaskFormDispatch({
                        type: "SET_SAVE_AS_TEMPLATE",
                        payload: newValue,
                      });
                    }
                  }}
                >
                  <ToggleButton value={"yes"}>Yes</ToggleButton>
                  <ToggleButton value={"no"}>No</ToggleButton>
                </ToggleButtonGroup>
              </Stack>
            </RenderWhen>
          </Stack>
        </Grid>
        <RenderWhen
          when={
            newTaskFormState?.taskData?.template_type === 2 ||
            newTaskFormState?.taskType === "workflow"
          }
        >
          <Grid item xs={8}>
            <StepsLists
              stepsData={newTaskFormState?.taskData?.workflow_steps || newTaskFormState?.steps}
              onStepClick={onStepClick}
              newTaskFormState={newTaskFormState}
              onOpenStepModal={() => {
                newTaskFormDispatch({
                  type: "STEPS_FORM_STATE",
                  payload: {
                    data: null,
                    open: true,
                  },
                });
              }}
            />
          </Grid>
        </RenderWhen>
        <Grid item xs={12}>
          <Divider />
        </Grid>
        <Grid item xs={12}>
          <Stack direction="row" justifyContent="flex-end" spacing={2} sx={{ p: 1 }}>
            <LoadingButton
              disabled={disableSaveButton}
              variant="contained"
              color="success"
              loading={loading}
              onClick={createTask}
            >
              Create Task
            </LoadingButton>
          </Stack>
        </Grid>
      </Grid>
      <StepFrom
        onClose={() => {
          newTaskFormDispatch({
            type: "STEPS_FORM_STATE",
            payload: {
              data: null,
              open: false,
            },
          });
        }}
        open={newTaskFormState.stepsFormState.open}
        onSave={newTaskFormDispatch}
        editStepData={newTaskFormState?.stepsFormState?.data}
      />
    </Box>
  );
}

CreateTaskTab.propTypes = {
  clientData: PropTypes.object.isRequired,
  onChangeTab: PropTypes.func.isRequired,
};
