import { useCallback, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useGetTaskData } from "../../../../hooks/use-get-task-data";
import { Grid, Stack } from "@mui/material";
import { NotesConversation } from "../../../../components/notes-conversation";
import { TaskControls } from "./task-controls";
import { TaskFiles } from "./task-files";
import { useGetTaskNotes } from "../../../../hooks/use-get-task-notes";
import { TaskDescription } from "./task-description";
import { TaskMetaData } from "./task-meta-data";

export function Task({ clientData, partnerUser, activeView }) {
  // hacky way to force update the component when task data changes
  // this will be replaced with a better solution(react-query or redux)
  const [updater, setUpdater] = useState(0);
  const userUUID = partnerUser?.v?.uuid;
  const clientUUID = clientData?.v?.client_uuid;

  const [taskUUID, setTaskUUID] = useState(null);
  const { taskData, getTaskDataQuery } = useGetTaskData({
    taskUUID: taskUUID,
    clientUUID: clientUUID,
  });

  const { taskNotesList, getTaskNotesQuery } = useGetTaskNotes(taskUUID);

  const updateTaskData = useCallback(async () => {
    try {
      await getTaskDataQuery();
      await getTaskNotesQuery();
      setUpdater((prev) => prev + 1);
    } catch (error) {
      console.error(error);
    }
  }, [getTaskDataQuery, getTaskNotesQuery]);

  const goToTaskList = () => {
    activeView.value = 2;
    window.history.replaceState({}, "", `${window.location.pathname}`);
  };

  useEffect(() => {
    const currentUrl = window.location.href;
    const urlParams = new URLSearchParams(new URL(currentUrl).search);
    const taskId = urlParams.get("taskId");
    urlParams.delete("taskId");

    if (!taskId) {
      // hacky way to go back to client page
      window.location.reload();
    }
    setTaskUUID(taskId);
  }, []);

  return (
    <Grid container sx={{ p: 1 }} spacing={1} key={updater}>
      <Grid item xs={8}>
        <Stack direction="column" spacing={2}>
          <TaskDescription
            taskData={taskData}
            clientUUID={clientUUID}
            getTaskDataQuery={getTaskDataQuery}
            goToTaskList={goToTaskList}
          />
          <NotesConversation
            notes={taskNotesList}
            userUUID={userUUID}
            updateTaskData={updateTaskData}
            taskData={taskData}
          />
        </Stack>
      </Grid>
      <Grid item xs={4}>
        <Stack direction="column" spacing={1}>
          <TaskControls taskData={taskData} updateTaskData={updateTaskData} />
          <TaskFiles taskUUID={taskUUID} clientUUID={clientUUID} updateTaskData={updateTaskData} />
          <TaskMetaData taskUUID={taskUUID} />
        </Stack>
      </Grid>
    </Grid>
  );
}

Task.propTypes = {
  clientData: PropTypes.object,
  partnerUser: PropTypes.object,
  activeView: PropTypes.object,
};
