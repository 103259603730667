import React from "react";
import { useNavigate } from "react-router-dom";

import { Box, Chip, Divider, Grid, Typography } from "@mui/material";
import {
  Groups,
  Schema,
  Work,
  Block,
  Cottage,
  Diversity2,
  Email,
  Fingerprint,
  Hail,
  Home,
  Language,
  LocalPhone,
  LocalPolice,
  MarkEmailRead,
  MonitorHeart,
  PhoneLocked,
  ReportProblem,
  Store,
  Paid,
} from "@mui/icons-material";

const RelatedCases = ({ relatedCases }) => {
  const navigate = useNavigate();

  return relatedCases?.length > 0 ? (
    <Box sx={{ border: 1, borderColor: "divider" }}>
      <Box sx={{ borderBottom: 1, borderColor: "divider", px: 2, py: 1 }}>
        <Typography variant="h6">Related cases</Typography>
      </Box>
      {relatedCases.map((relatedCase, index) => (
        <Box
          key={index}
          sx={{
            borderBottom: relatedCases.length == index + 1 ? 0 : 1,
            borderColor: "divider",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Box
            className="link"
            title="View case"
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              py: 1,
              pl: 2,
            }}
            onClick={() => {
              navigate(
                `/case/${relatedCase.case_type == 1 ? "kyc" : "kyb"}/${relatedCase.case_uuid}`,
                true
              );
              navigate(0);
            }}
          >
            <Typography variant="body2">{relatedCase.name}</Typography>
          </Box>
          <Box sx={{ display: "flex" }}>
            <Box
              sx={{
                borderLeft:
                  relatedCase.flags.pep ||
                  relatedCase.flags.sanctions ||
                  relatedCase.flags.warnings ||
                  relatedCase.flags.adverse_media ||
                  relatedCase.flags.country ||
                  relatedCase.flags.age ||
                  relatedCase.flags.industry ||
                  relatedCase.flags.fatca ||
                  relatedCase.flags.high_risk_person ||
                  relatedCase.flags.associated_pep
                    ? 1
                    : 0,
                borderColor: "divider",
                p: 1,
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                gap: 1,
              }}
            >
              {relatedCase.flags?.pep && (
                <Chip
                  className="square-chip"
                  label="PEP"
                  sx={{ backgroundColor: "#C83E4D", color: "#ffffff", textTransform: "uppercase" }}
                />
              )}
              {relatedCase.flags?.sanctions && (
                <Chip
                  className="square-chip"
                  label="Sanctioned"
                  sx={{ backgroundColor: "#C83E4D", color: "#ffffff", textTransform: "uppercase" }}
                />
              )}
              {relatedCase.flags?.warnings && (
                <Chip
                  className="square-chip"
                  label="Warnings"
                  sx={{ backgroundColor: "#FCCA46", color: "#1C2321", textTransform: "uppercase" }}
                />
              )}
              {relatedCase.flags?.adverse_media && (
                <Chip
                  className="square-chip"
                  label="Adverse media"
                  sx={{ backgroundColor: "#FCCA46", color: "#1C2321", textTransform: "uppercase" }}
                />
              )}
              {relatedCase.flags?.country && (
                <Chip
                  className="square-chip"
                  label="Country risk"
                  sx={{ backgroundColor: "#FCCA46", color: "#1C2321", textTransform: "uppercase" }}
                />
              )}
              {relatedCase.flags?.age && (
                <Chip
                  className="square-chip"
                  label="Age risk"
                  sx={{ backgroundColor: "#FCCA46", color: "#1C2321", textTransform: "uppercase" }}
                />
              )}
              {relatedCase.flags?.industry && (
                <Chip
                  className="square-chip"
                  label="Industry risk"
                  sx={{ backgroundColor: "#FCCA46", color: "#1C2321", textTransform: "uppercase" }}
                />
              )}
              {relatedCase.flags?.fatca && (
                <Chip
                  className="square-chip"
                  label="FATCA"
                  sx={{ backgroundColor: "#2274A5", color: "#ffffff", textTransform: "uppercase" }}
                />
              )}
              {relatedCase.flags?.high_risk_person && (
                <Chip
                  className="square-chip"
                  label="High risk associate"
                  sx={{ backgroundColor: "#FCCA46", color: "#1C2321", textTransform: "uppercase" }}
                />
              )}
              {relatedCase.flags?.associated_pep && (
                <Chip
                  className="square-chip"
                  label="Associated PEP"
                  sx={{ backgroundColor: "#FCCA46", color: "#1C2321", textTransform: "uppercase" }}
                />
              )}
            </Box>

            <Box
              sx={{
                borderLeft: 1,
                borderColor: "divider",
                p: 1,
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
              }}
            >
              {relatedCase.status == 0 && (
                <Chip
                  className="square-chip"
                  label="Input required"
                  sx={{ backgroundColor: "#1C2321", color: "#ffffff", textTransform: "uppercase" }}
                />
              )}
              {relatedCase.status == 1 && (
                <Chip
                  className="square-chip"
                  label="Review"
                  sx={{ backgroundColor: "#D5E950", color: "#1C2321", textTransform: "uppercase" }}
                />
              )}
              {relatedCase.status == 2 && (
                <Box sx={{ display: "flex", flexDirection: "row", gap: 1 }}>
                  <Box
                    sx={{
                      fontSize: "13px",
                      fontWeight: 600,
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                    }}
                  >
                    <Box>Risk score: {relatedCase.risk_score}</Box>
                  </Box>
                  <Box>
                    {relatedCase.risk_classification == 1 && (
                      <Chip
                        label={relatedCase.risk_classification_label}
                        className="square-chip"
                        sx={{
                          backgroundColor: "#8BBF9F",
                          color: "#1C2321",
                          textTransform: "uppercase",
                        }}
                      />
                    )}
                    {relatedCase.risk_classification == 2 && (
                      <Chip
                        label={relatedCase.risk_classification_label}
                        className="square-chip"
                        sx={{
                          backgroundColor: "#FCCA46",
                          color: "#1C2321",
                          textTransform: "uppercase",
                        }}
                      />
                    )}
                    {relatedCase.risk_classification == 3 && (
                      <Chip
                        label={relatedCase.risk_classification_label}
                        className="square-chip"
                        sx={{
                          backgroundColor: "#C83E4D",
                          color: "#ffffff",
                          textTransform: "uppercase",
                        }}
                      />
                    )}
                  </Box>
                </Box>
              )}
              {relatedCase.status == 3 && (
                <Chip
                  className="square-chip"
                  label="Failed"
                  sx={{ backgroundColor: "#C83E4D", color: "#ffffff", textTransform: "uppercase" }}
                />
              )}

              {relatedCase.status == 4 && (
                <Chip
                  className="square-chip"
                  label="Assessment required"
                  sx={{ backgroundColor: "#D5E950", color: "#1C2321", textTransform: "uppercase" }}
                />
              )}
              {relatedCase.status == 5 && (
                <Chip
                  className="square-chip"
                  label="Waiting"
                  sx={{ backgroundColor: "#2274A5", color: "#ffffff", textTransform: "uppercase" }}
                />
              )}
              {relatedCase.status == 6 && (
                <Chip
                  className="square-chip"
                  label="Decision required"
                  sx={{ backgroundColor: "#D5E950", color: "#1C2321", textTransform: "uppercase" }}
                />
              )}
              {relatedCase.status == 7 && (
                <Chip
                  className="square-chip"
                  label="Circular Dependencies"
                  sx={{ backgroundColor: "#2274A5", color: "#ffffff", textTransform: "uppercase" }}
                />
              )}
            </Box>
          </Box>
        </Box>
      ))}
    </Box>
  ) : null;
};

export default RelatedCases;
