import { React, useEffect, useState } from "react";

import partner from "../../api/partner";
import authHeader from "../../api/authHeader";

import HandleError from "../../components/functions/HandleError";

import moment from "moment";
import DataTable from "react-data-table-component";

import Lottie from "react-lottie";
import loadingLottie from "../../components/lotties/loading-dots.json";

import { Box, Chip } from "@mui/material";

const Logs = ({ partnerData, partnerUser, caseData, entityData }) => {
  const useAuthHeader = authHeader();
  const handleError = HandleError();

  const [isLoaded, setIsLoaded] = useState(false);
  const [data, setData] = useState([]);

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: loadingLottie,
  };

  const customStyles = {
    headCells: {
      style: {
        paddingLeft: "8px", // override the cell padding for head cells
        paddingRight: "8px",
        fontWeight: "bold",
      },
    },
    cells: {
      style: {
        paddingLeft: "8px", // override the cell padding for data cells
        paddingRight: "8px",
      },
    },
  };

  const columns = [
    {
      name: "Log time",
      sortable: true,
      wrap: true,
      selector: (row) => row.timestamp,
      cell: (row) => <Box>{moment.unix(row.timestamp).format("DD/MM/YYYY HH:mm a")}</Box>,
    },
    {
      name: "Content",
      width: "50%",
      wrap: true,
      cell: (row) => row.content,
    },
    {
      name: "IP address",
      cell: (row) => (
        <>
          <Box sx={{ display: "flex", flexDirection: "column", gap: 1, py: 1 }}>
            <Box>{row.ip_address ? row.ip_address : "-"}</Box>
            <Box>{row.ip_country.name}</Box>
            {row.ip_country.high_risk && (
              <Box>
                <Chip
                  className="square-chip"
                  label="High risk"
                  size="small"
                  sx={{ backgroundColor: "#C83E4D", color: "#ffffff", textTransform: "uppercase" }}
                />
              </Box>
            )}
          </Box>
        </>
      ),
    },
    {
      name: <Box sx={{ display: "flex", justifyContent: "flex-end", width: "100%" }}>Actor</Box>,
      width: "25%",
      cell: (row) => (
        <>
          <Box sx={{ display: "flex", justifyContent: "flex-end", width: "100%" }}>{row.actor_name}</Box>
        </>
      ),
    },
  ];

  const getLogs = async () => {
    try {
      const response = await partner.post(
        `/v1/screening/case/kyc/logs`,
        JSON.stringify({
          case_uuid: caseData.value.case_uuid,
        }),
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Basic " + useAuthHeader.base64encodedData,
            service: "partners",
          },

          //withCredentials: true,
        }
      );

      if (response.data.results > 0) {
        setData(response.data.entries);
      } else {
        setData([]);
      }
      setIsLoaded(true);
    } catch (err) {
      console.log(err);
      handleError(err);
    }
  };

  useEffect(() => {
    getLogs();
  }, []);

  return (
    <>
      {!isLoaded ? (
        <Box>
          <Lottie speed={2.0} options={defaultOptions} height={200} width={200} />
        </Box>
      ) : (
        <>
          <DataTable columns={columns} data={data} customStyles={customStyles} responsive={true} pagination striped />
        </>
      )}
    </>
  );
};

export default Logs;
