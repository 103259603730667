import { React, useEffect, useState, useCallback } from "react";

import partner from "../../api/partner";
import authHeader from "../../api/authHeader";

import HandleError from "../../components/functions/HandleError";
import HandleToast from "../../components/functions/HandleToast";

import Lottie from "react-lottie";
import loadingLottie from "../../components/lotties/loading-dots.json";

import DataTable from "react-data-table-component";
import { useDropzone } from "react-dropzone";
import "moment/dist/locale/en-gb";
import moment from "moment";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

import {
  Box,
  Typography,
  Chip,
  Grid,
  Icon,
  IconButton,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Divider,
  Autocomplete,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  Collapse,
  InputAdornment,
  ToggleButtonGroup,
  ToggleButton,
  FormControlLabel,
  RadioGroup,
  Radio,
  FormLabel,
} from "@mui/material";
import { Edit, Close, Delete, Visibility, PermMedia } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import CountrySelect from "../../components/form-elements/CountrySelect";

const Fatca = ({ partnerUser, partnerData, caseData, entityData }) => {
  const useAuthHeader = authHeader();
  const handleError = HandleError();
  const displayToast = HandleToast();

  const [locale, setLocale] = useState("en-gb");

  if (moment.locale() != locale) {
    moment.locale(locale);
  }

  const [isLoaded, setIsLoaded] = useState(false);
  const [fatcaLoaded, setFatcaLoaded] = useState(false);

  const [processing, setProcessing] = useState(false);
  const [editMode, setEditMode] = useState(false);

  const [fatcaEdit, setFatcaEdit] = useState(false);
  const [codesEdit, setCodesEdit] = useState(false);

  const [entityType, setEntityType] = useState(0); // [1, 2, 3
  const [usPersonTin, setUsPersonTin] = useState("");

  const [fatcaDisplay, setFatcaDisplay] = useState();

  const [taxResidencyData, setTaxResidencyData] = useState([
    { jurisdiction: "United Kingdom", tin: "123456789", uuid: "1234" },
  ]);
  const [residencyCountry, setResidencyCountry] = useState();
  const [residencyTin, setResidencyTin] = useState("");

  const [residencyTinExemption, setResidencyTinExemption] = useState(0);
  const [residencyTinExemptionReason, setResidencyTinExemptionReason] = useState("");

  const [deleteTaxResidency, setDeleteTaxResidency] = useState("");
  const [deleteTaxResidencyCountry, setDeleteTaxResidencyCountry] = useState("");
  const [deleteTaxResidencyDialog, setDeleteTaxResidencyDialog] = useState(false);

  const [file, setFile] = useState();

  const onDrop = useCallback((acceptedFiles) => {
    acceptedFiles.forEach((file) => {
      setFile(file);
    });
  }, []);

  const { acceptedFiles, getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: {
      "image/png": [".png"],
      "image/jpeg": [".jpg", ".jpeg"],
      "application/pdf": [".pdf"],
    },
    maxFiles: 1,
  });

  const customStyles = {
    headCells: {
      style: {
        paddingLeft: "8px", // override the cell padding for head cells
        paddingRight: "8px",
        fontWeight: "bold",
      },
    },
    cells: {
      style: {
        paddingLeft: "8px", // override the cell padding for data cells
        paddingRight: "8px",
      },
    },
  };

  const columns = [
    {
      name: "Jurisdiction",
      sortable: true,
      width: "50%",
      selector: (row) => row.country?.name,
    },
    {
      name: "TIN",
      sortable: true,
      right: "true",
      selector: (row) => row.tin,
    },
  ];

  const manageColumns = [
    {
      name: "Jurisdiction",
      sortable: true,
      width: "50%",
      selector: (row) => row.country?.name,
    },
    {
      name: "TIN",
      sortable: true,
      right: "true",
      selector: (row) => row.tin,
    },
    {
      name: "Actions",
      sortable: false,
      right: "true",
      cell: (row) => (
        <Box sx={{ display: "flex", flexDirection: "row", gap: 2 }}>
          <IconButton
            onClick={() => {
              setDeleteTaxResidencyCountry(row.country?.name);
              setDeleteTaxResidency(row.uuid);
              setDeleteTaxResidencyDialog(true);
            }}
            sx={{ color: "#C83E4D", cursor: "pointer" }}
          >
            <Delete />
          </IconButton>
        </Box>
      ),
    },
  ];

  const getFatcaDeclaration = async () => {
    try {
      const response = await partner.post(
        `/v1/entity/people/person/data/fatca`,
        JSON.stringify({
          person_uuid: entityData.value.person_uuid,
        }),
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Basic " + useAuthHeader.base64encodedData,
            service: "partners",
          },

          //withCredentials: true,
        }
      );

      if (response.data.complete) {
        setEntityType(response.data.entity_type);
        setUsPersonTin(response.data.tin);
      }

      setFatcaDisplay(response.data);
      setFatcaLoaded(true);
    } catch (err) {
      console.log(err);
      handleError(err);
    }
  };

  const handleSaveFatcaDeclaration = async (e) => {
    e.preventDefault();
    setProcessing(true);

    const formData = new FormData();
    formData.append("file", file);
    formData.append("fileName", file ? file.name : "");

    formData.append("entity_type", entityType);
    formData.append("person_uuid", entityData.value.person_uuid);
    formData.append("tin", usPersonTin);

    try {
      const response = await partner.post("/v1/entity/people/person/tax/fatca", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: "Basic " + useAuthHeader.base64encodedData,
          service: "partners",
        },
        onUploadProgress: function (progressEvent) {},

        //withCredentials: true,
      });

      getFatcaDeclaration();
      getTaxNumbers();

      displayToast("FATCA declaration saved successfully", () => {
        setProcessing(false);
        setFatcaEdit(false);
        setEditMode(false);
      });
    } catch (err) {
      handleError(err);
      setProcessing(false);
    }
  };

  const getTaxNumbers = async () => {
    try {
      const response = await partner.post(
        `/v1/entity/people/person/data/tax`,
        JSON.stringify({
          person_uuid: entityData.value.person_uuid,
        }),
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Basic " + useAuthHeader.base64encodedData,
            service: "partners",
          },

          //withCredentials: true,
        }
      );

      if (response.data.results > 0) {
        setTaxResidencyData(response.data.entries);
      } else {
        setTaxResidencyData([]);
      }

      setIsLoaded(true);
    } catch (err) {
      console.log(err);
      handleError(err);
    }
  };

  const handleAddTaxNumber = async (e) => {
    e.preventDefault();
    setProcessing(true);

    try {
      const response = await partner.post(
        `/v1/entity/people/person/tax/add`,
        JSON.stringify({
          person_uuid: entityData.value.person_uuid,
          tin: residencyTin,
          country: residencyCountry ? residencyCountry.value : "",
        }),
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Basic " + useAuthHeader.base64encodedData,
            service: "partners",
          },

          //withCredentials: true,
        }
      );

      getTaxNumbers();

      displayToast("Details added successfully", () => {
        setProcessing(false);
        setResidencyTin("");
        setResidencyCountry();
      });
    } catch (err) {
      console.log(err);
      handleError(err);
      setProcessing(false);
    }
  };

  const handleDeleteTaxNumber = async (e) => {
    e.preventDefault();
    setProcessing(true);

    try {
      const response = await partner.post(
        `/v1/entity/people/person/tax/remove`,
        JSON.stringify({
          person_uuid: entityData.value.person_uuid,
          code_uuid: deleteTaxResidency,
        }),
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Basic " + useAuthHeader.base64encodedData,
            service: "partners",
          },

          //withCredentials: true,
        }
      );

      getTaxNumbers();

      setDeleteTaxResidencyDialog(false);

      displayToast("Details removed successfully", () => {
        setProcessing(false);
        setDeleteTaxResidency("");
      });
    } catch (err) {
      console.log(err);
      handleError(err);
      setProcessing(false);
    }
  };

  useEffect(() => {
    getFatcaDeclaration();
    getTaxNumbers();
  }, []);

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: loadingLottie,
  };

  return (
    <>
      {isLoaded ? (
        <Grid container spacing={2}>
          <Grid item xs={12} xl={12}>
            <Box sx={{}}>
              {!editMode ? (
                <Box
                  sx={{
                    borderRight: { xs: 0, xl: 0 },
                    borderColor: { xs: "divider", xl: "divider" },
                  }}
                >
                  <Box
                    sx={{
                      borderBottom: 1,
                      borderColor: "divider",
                      px: 2,
                      py: 1,
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <Box
                      sx={{ display: "flex", flexDirection: "column", justifyContent: "center" }}
                    >
                      <Typography variant="h6">Tax & Reporting</Typography>
                    </Box>
                  </Box>
                  <Box sx={{}}>
                    <Box>
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-between",
                          backgroundColor: "#0b0b0b",
                          color: "#ffffff",
                        }}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                          }}
                        >
                          <Typography variant="subtitle2" sx={{ fontSize: "16px", p: 2 }}>
                            FATCA information
                          </Typography>
                        </Box>
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                          }}
                        >
                          <IconButton
                            title="Edit"
                            sx={{ color: "#ffffff" }}
                            onClick={() => {
                              setCodesEdit(false);
                              setFatcaEdit(true);
                              setEditMode(true);
                            }}
                          >
                            <Edit />
                          </IconButton>
                        </Box>
                      </Box>

                      <Divider />

                      <Box sx={{}}>
                        {fatcaLoaded ? (
                          fatcaDisplay?.complete ? (
                            <Box>
                              <Box
                                sx={{
                                  display: "flex",
                                  flexDirection: "row",
                                  justifyContent: "space-between",
                                }}
                              >
                                <Box
                                  sx={{
                                    display: "flex",
                                    flexDirection: "column",
                                    justifyContent: "center",
                                  }}
                                >
                                  {fatcaDisplay.entity_type == 1 && (
                                    <Typography variant="subtitle2" sx={{ p: 2 }}>
                                      US Citizen or Tax Resident
                                    </Typography>
                                  )}

                                  {fatcaDisplay.entity_type == 2 && (
                                    <Typography variant="subtitle2" sx={{ p: 2 }}>
                                      Former US Citizen
                                    </Typography>
                                  )}

                                  {fatcaDisplay.entity_type == 3 && (
                                    <Typography variant="subtitle2" sx={{ p: 2 }}>
                                      Non US Citizen
                                    </Typography>
                                  )}
                                </Box>
                                <Box
                                  sx={{
                                    display: "flex",
                                    flexDirection: "column",
                                    justifyContent: "center",
                                    px: 2,
                                  }}
                                >
                                  {fatcaDisplay.reportable && (
                                    <Chip
                                      className="square-chip"
                                      label="Reportable"
                                      sx={{
                                        backgroundColor: "#C83E4D",
                                        color: "#ffffff",
                                        textTransform: "uppercase",
                                      }}
                                    />
                                  )}
                                </Box>
                              </Box>

                              {fatcaDisplay.entity_type == 1 && (
                                <Box
                                  sx={{
                                    borderTop: 1,
                                    borderColor: "divider",
                                    display: "flex",
                                    justifyContent: "space-between",
                                  }}
                                >
                                  <Box
                                    sx={{
                                      display: "flex",
                                      flexDirection: "column",
                                      justifyContent: "center",
                                    }}
                                  >
                                    <Typography variant="subtitle" sx={{ fontSize: "13px", p: 2 }}>
                                      TIN
                                    </Typography>
                                  </Box>
                                  <Box
                                    sx={{
                                      display: "flex",
                                      flexDirection: "column",
                                      justifyContent: "center",
                                    }}
                                  >
                                    <Typography variant="subtitle2" sx={{ fontSize: "13px", p: 2 }}>
                                      {fatcaDisplay.tin}
                                    </Typography>
                                  </Box>
                                </Box>
                              )}

                              {fatcaDisplay.entity_type == 2 && (
                                <Box
                                  sx={{
                                    borderTop: 1,
                                    borderColor: "divider",
                                    display: "flex",
                                    justifyContent: "space-between",
                                  }}
                                >
                                  <Box
                                    sx={{
                                      display: "flex",
                                      flexDirection: "column",
                                      justifyContent: "center",
                                      minWidth: "30%",
                                    }}
                                  >
                                    <Typography variant="subtitle" sx={{ fontSize: "13px", p: 2 }}>
                                      Certificate of Loss of Nationality
                                    </Typography>
                                  </Box>
                                  <Box
                                    sx={{
                                      display: "flex",
                                      flexDirection: "column",
                                      justifyContent: "center",
                                    }}
                                  >
                                    <IconButton
                                      title="View document"
                                      sx={{ color: "#000000" }}
                                      onClick={() => {}}
                                    >
                                      <Visibility />
                                    </IconButton>
                                  </Box>
                                </Box>
                              )}
                            </Box>
                          ) : (
                            <Typography sx={{ fontSize: "13px", p: 2 }}>-</Typography>
                          )
                        ) : (
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              height: "",
                            }}
                          >
                            <Lottie speed={2.0} options={defaultOptions} height={100} width={200} />
                          </Box>
                        )}
                      </Box>
                    </Box>

                    <Divider />

                    <Box>
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-between",
                          backgroundColor: "#0b0b0b",
                          color: "#ffffff",
                        }}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                          }}
                        >
                          <Typography variant="subtitle2" sx={{ fontSize: "16px", p: 2 }}>
                            Tax identifiers
                          </Typography>
                        </Box>
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                          }}
                        >
                          <IconButton
                            title="Edit"
                            sx={{ color: "#ffffff" }}
                            onClick={() => {
                              setCodesEdit(true);
                              setFatcaEdit(false);
                              setEditMode(true);
                            }}
                          >
                            <Edit />
                          </IconButton>
                        </Box>
                      </Box>
                      <Divider />

                      <DataTable
                        columns={columns}
                        data={taxResidencyData}
                        customStyles={customStyles}
                        striped
                        noTableHead={true}
                        noDataComponent={
                          <Box sx={{ width: "100%", px: 2, py: 1 }}>
                            <Typography sx={{ textAlign: "left" }} variant="subtitle2">
                              -
                            </Typography>
                          </Box>
                        }
                      />
                    </Box>
                  </Box>
                </Box>
              ) : (
                <Box
                  sx={{
                    borderRight: { xs: 0, xl: 0 },
                    borderColor: { xs: "divider", xl: "divider" },
                  }}
                >
                  {fatcaEdit && (
                    <Box sx={{}}>
                      <Box
                        sx={{
                          borderBottom: 1,
                          borderColor: "divider",
                          px: 2,
                          py: 1,
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                          }}
                        >
                          <Typography variant="h6">FATCA information</Typography>
                        </Box>
                        <Box>
                          <IconButton title="Close" onClick={() => setEditMode(false)}>
                            <Close />
                          </IconButton>
                        </Box>
                      </Box>
                      <Box sx={{ display: "flex", flexDirection: "column" }}>
                        <Box>
                          <Box sx={{ p: 2 }}>
                            <Typography variant="subtitle" sx={{ fontSize: "13px" }}>
                              FATCA is a US law that requires financial institutions to identify
                              their US customers and report information about financial accounts
                              held by US persons either directly or through certain foreign
                              entities. FATCA requires financial institutions to report on the
                              foreign assets held by their US account holders or be subject to
                              withholding on withholdable payments.
                            </Typography>
                          </Box>
                          <Divider />
                          <Box sx={{ p: 2 }}>
                            <FormControl>
                              <RadioGroup
                                aria-labelledby="fatca-type-options-label"
                                defaultValue={0}
                                value={entityType}
                                onChange={(e) => setEntityType(e.target.value)}
                                name="radio-buttons-group"
                              >
                                <FormControlLabel
                                  sx={{ mb: 1 }}
                                  value={1}
                                  control={<Radio />}
                                  label="The person is a US citizen and/or resident in the US for tax purposes"
                                />
                                <FormControlLabel
                                  sx={{ mb: 1 }}
                                  value={2}
                                  control={<Radio />}
                                  label="The person was born in the US (or a US territory) but is no longer a US citizen as they voluntarily surrendered citizenship and have a Certificate of Loss of Nationality of the United States"
                                />
                                <FormControlLabel
                                  sx={{ mb: 1 }}
                                  value={3}
                                  control={<Radio />}
                                  label="The person is not a US citizen or resident in the US for tax purposes"
                                />
                              </RadioGroup>
                            </FormControl>
                          </Box>
                        </Box>

                        <Collapse in={entityType == 1} exit={entityType != 1}>
                          <Box>
                            <Box sx={{ borderBottom: 1, borderTop: 1, borderColor: "divider" }}>
                              <Box sx={{ p: 2 }}>
                                <Typography variant="h6">US Person or tax resident</Typography>
                              </Box>
                            </Box>

                            <Box sx={{ p: 2, display: "flex", flexDirection: "column", gap: 1 }}>
                              <Typography variant="subtitle2">
                                Provide the Tax identification number (TIN)
                              </Typography>
                              <TextField
                                fullWidth
                                label="TIN"
                                variant="outlined"
                                value={usPersonTin}
                                onChange={(e) => setUsPersonTin(e.target.value)}
                              />
                            </Box>
                          </Box>
                        </Collapse>

                        <Collapse in={entityType == 2} exit={entityType != 2}>
                          <Box>
                            <Box sx={{ borderBottom: 1, borderTop: 1, borderColor: "divider" }}>
                              <Box sx={{ p: 2 }}>
                                <Typography variant="h6">Former US citizen</Typography>
                                <Typography variant="subtitle" sx={{ fontSize: "13px" }}>
                                  Please upload the Certificate of Loss of Nationality of the United
                                  States.
                                </Typography>
                              </Box>
                            </Box>

                            <Box sx={{ p: 2 }}>
                              <Box
                                className="file-drop"
                                sx={{
                                  border: 2,
                                  borderColor: "divider",
                                  borderStyle: "dashed",
                                  cursor: "pointer",
                                  p: 5,
                                  my: 2,

                                  display: "flex",
                                  flexDirection: "column",
                                  justifyContent: "center",
                                }}
                                {...getRootProps()}
                              >
                                <input {...getInputProps()} />
                                {isDragActive ? (
                                  <Typography align="center">Drop the file here ...</Typography>
                                ) : (
                                  <Typography align="center">
                                    Drop a file here, or click to select files
                                  </Typography>
                                )}
                                <Typography align="center" variant="caption">
                                  (Only pdf, jpeg or png, files will be accepted)
                                </Typography>
                              </Box>

                              {file && (
                                <Box
                                  sx={{
                                    display: "flex",
                                    flexDirection: "row",
                                    gap: 2,

                                    border: 1,
                                    borderColor: "divider",
                                    justifyContent: "space-between",
                                  }}
                                >
                                  <Box
                                    sx={{
                                      display: "flex",
                                      flexDirection: "row",
                                      width: "100%",
                                      justifyContent: "space-between",
                                    }}
                                  >
                                    <Box sx={{ display: "flex" }}>
                                      <Box
                                        sx={{
                                          display: "flex",
                                          flexDirection: "column",
                                          justifyContent: "center",
                                        }}
                                      >
                                        <Box
                                          sx={{
                                            display: "flex",
                                            flexDirection: "row",
                                            gap: 2,
                                            fontWeight: 600,
                                          }}
                                        >
                                          <Box
                                            sx={{
                                              display: "flex",
                                              flexDirection: "column",
                                              justifyContent: "center",
                                              pl: 2,
                                            }}
                                          >
                                            <PermMedia />
                                          </Box>
                                          <Box
                                            sx={{
                                              borderRight: 1,
                                              borderLeft: 1,
                                              borderColor: "divider",
                                              p: 1,
                                            }}
                                          >
                                            <Typography variant=""> "Filename"</Typography>
                                          </Box>
                                        </Box>
                                      </Box>

                                      <Box
                                        sx={{
                                          display: "flex",
                                          flexDirection: "column",
                                          justifyContent: "center",
                                          fontSize: 13,
                                          pl: 2,
                                        }}
                                      >
                                        {file.path}
                                      </Box>
                                    </Box>

                                    <Box sx={{ pr: 1 }}>
                                      <IconButton
                                        title="Remove file"
                                        onClick={() => {
                                          setFile(null);
                                        }}
                                      >
                                        <Delete />
                                      </IconButton>
                                    </Box>
                                  </Box>
                                </Box>
                              )}
                            </Box>
                          </Box>
                        </Collapse>

                        <Divider />

                        <Box
                          sx={{
                            p: 2,
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "flex-end",
                          }}
                        >
                          <LoadingButton
                            disableElevation
                            variant="contained"
                            color="success"
                            onClick={(e) => {
                              handleSaveFatcaDeclaration(e);
                            }}
                            loading={processing}
                          >
                            Save declaration
                          </LoadingButton>
                        </Box>
                      </Box>
                    </Box>
                  )}

                  {codesEdit && (
                    <Box sx={{}}>
                      <Box
                        sx={{
                          borderBottom: 1,
                          borderColor: "divider",
                          px: 2,
                          py: 1,
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                          }}
                        >
                          <Typography variant="h6">Tax registration information</Typography>
                        </Box>
                        <Box>
                          <IconButton title="Close" onClick={() => setEditMode(false)}>
                            <Close />
                          </IconButton>
                        </Box>
                      </Box>

                      <Box sx={{ p: 2 }}>
                        <Typography variant="subtitle" sx={{ fontSize: "13px" }}>
                          Please provide the tax residence information for the entity.
                        </Typography>
                      </Box>

                      <Divider />

                      <Box sx={{ p: 2, display: "flex", flexDirection: "column", gap: 2 }}>
                        <CountrySelect value={residencyCountry} setValue={setResidencyCountry} />

                        <TextField
                          fullWidth
                          label="Tax identification number (TIN)"
                          variant="outlined"
                          value={residencyTin}
                          onChange={(e) => setResidencyTin(e.target.value)}
                        />

                        <Box
                          sx={{ display: "flex", flexDirection: "row", justifyContent: "flex-end" }}
                        >
                          <LoadingButton
                            disableElevation
                            variant="contained"
                            color="success"
                            onClick={(e) => handleAddTaxNumber(e)}
                            loading={processing}
                          >
                            Save details
                          </LoadingButton>
                        </Box>
                      </Box>

                      <Divider />
                      <Box>
                        <Typography variant="subtitle2" sx={{ fontSize: "13px", p: 2 }}>
                          Residency information
                        </Typography>
                        <Divider />

                        <DataTable
                          columns={manageColumns}
                          data={taxResidencyData}
                          customStyles={customStyles}
                          striped
                          noTableHead={true}
                          noDataComponent={
                            <Box sx={{ width: "100%", px: 2, py: 1 }}>
                              <Typography sx={{ textAlign: "left" }} variant="subtitle2">
                                -
                              </Typography>
                            </Box>
                          }
                        />
                      </Box>

                      <Dialog
                        open={deleteTaxResidencyDialog}
                        onClose={() => setDeleteTaxResidencyDialog(false)}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                      >
                        <DialogTitle id="alert-dialog-title">Are you sure?</DialogTitle>
                        <DialogContent>
                          <Box>
                            <DialogContentText sx={{ mb: 1 }} id="alert-dialog-description">
                              Are you sure you want to delete the tax registration details for{" "}
                              {deleteTaxResidencyCountry}?
                            </DialogContentText>
                          </Box>
                        </DialogContent>
                        <DialogActions>
                          <LoadingButton
                            disableElevation
                            loading={processing}
                            onClick={() => setDeleteTaxResidencyDialog(false)}
                            color="primary"
                          >
                            Cancel
                          </LoadingButton>

                          <LoadingButton
                            disableElevation
                            sx={{ color: "#ffffff" }}
                            loading={processing}
                            onClick={(e) => {
                              handleDeleteTaxNumber(e);
                            }}
                            variant="contained"
                            color="danger"
                            autoFocus
                          >
                            Confirm
                          </LoadingButton>
                        </DialogActions>
                      </Dialog>
                    </Box>
                  )}
                </Box>
              )}
            </Box>
          </Grid>
        </Grid>
      ) : (
        <Box>
          <Lottie speed={2.0} options={defaultOptions} height={200} width={200} />
        </Box>
      )}
    </>
  );
};

export default Fatca;
