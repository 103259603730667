import { React, useEffect, useState } from "react";

import authHeader from "../../../api/hostedAuthHeader";

import HandleError from "../../../components/functions/HostedHandleError";
import HandleToast from "../../../components/functions/HandleToast";

import Lottie from "react-lottie";
import loadingLottie from "../../../components/lotties/loading-dots.json";

import { Close, Delete, Edit } from "@mui/icons-material";
import {
  Box,
  Divider,
  IconButton,
  Typography,
  Button,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  DialogContentText,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";

const Name = ({ partner, caseUUID, entityData, caseData, activeView, getPerson, requestData, getRequestData, getCase }) => {
  const useAuthHeader = authHeader();
  const handleError = HandleError();
  const displayToast = HandleToast();

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: loadingLottie,
  };

  const [processing, setProcessing] = useState(false);

  const [editMode, setEditMode] = useState(false);
  const [editType, setEditType] = useState(1); // 1 = name, 2 = previous name

  const [title, setTitle] = useState(entityData.value.title);
  const [firstName, setFirstName] = useState(entityData.value.first_name);
  const [middleNames, setMiddleNames] = useState(entityData.value.middle_names);
  const [lastName, setLastName] = useState(entityData.value.last_name);

  const [previousTitle, setPreviousTitle] = useState("");
  const [previousFirstName, setPreviousFirstName] = useState("");
  const [previousMiddleNames, setPreviousMiddleNames] = useState("");
  const [previousLastName, setPreviousLastName] = useState("");

  const [previousNames, setPreviousNames] = useState([]);
  const [deletePreviousNameDialog, setDeletePreviousNameDialog] = useState(false);
  const [deletePreviousNameUUID, setDeletePreviousNameUUID] = useState("");

  const titleOptions = [
    { value: "Mr", label: "Mr" },
    { value: "Mrs", label: "Mrs" },
    { value: "Miss", label: "Miss" },
    { value: "Ms", label: "Ms" },
    { value: "Dr", label: "Dr" },
    { value: "Prof", label: "Prof" },
    { value: "Rev", label: "Rev" },
    { value: "Sir", label: "Sir" },
    { value: "Madam", label: "Madam" },
    { value: "Lord", label: "Lord" },
    { value: "Lady", label: "Lady" },
    { value: "Fr", label: "Father" },
    { value: "Sr", label: "Sister" },
    { value: "Imam", label: "Imam" },
    { value: "Sheikh", label: "Sheikh" },
    { value: "Rabbi", label: "Rabbi" },
    { value: "Duke", label: "Duke" },
    { value: "Duchess", label: "Duchess" },
    { value: "Earl", label: "Earl" },
    { value: "Count", label: "Count" },
    { value: "Countess", label: "Countess" },
    { value: "Baron", label: "Baron" },
    { value: "Baroness", label: "Baroness" },
    { value: "Capt", label: "Captain" },
    { value: "Lt", label: "Lieutenant" },
    { value: "Col", label: "Colonel" },
    { value: "Gen", label: "General" },
  ];

  const getPreviousNames = async (personUUID) => {
    if (personUUID == "") {
      return;
    }
    try {
      const response = await partner.post(
        `/v1/entity/people/person/data/previous-names`,
        JSON.stringify({
          person_uuid: personUUID,
        }),
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Basic " + useAuthHeader.base64encodedData,
            service: "hosted",
          },

          //withCredentials: true,
        }
      );

      if (response.data.results > 0) {
        setPreviousNames(response.data.entries);
      } else {
        setPreviousNames([]);
      }
    } catch (err) {
      console.log(err);
      handleError(err);
    }
  };

  const handleUpdateName = async (e) => {
    e.preventDefault();
    setProcessing(true);
    try {
      const response = await partner.post(
        `/v1/entity/people/person/name/update`,
        JSON.stringify({
          person_uuid: entityData.value.person_uuid,
          title: title,
          first_name: firstName,
          middle_names: middleNames,
          last_name: lastName,
        }),
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Basic " + useAuthHeader.base64encodedData,
            service: "hosted",
          },

          // withCredentials: true,
        }
      );

      getPerson();
      getPreviousNames(entityData.value.person_uuid);
      getRequestData();
      getCase();

      displayToast("Name updated successfully", () => {
        setProcessing(false);
        setEditMode(false);
      });
    } catch (err) {
      console.log(err);
      handleError(err);
      setProcessing(false);
    }
  };

  const handleAddPreviousName = async (e) => {
    e.preventDefault();
    setProcessing(true);
    try {
      const response = await partner.post(
        `/v1/entity/people/person/name/previous-names/add`,
        JSON.stringify({
          person_uuid: entityData.value.person_uuid,
          title: previousTitle,
          first_name: previousFirstName,
          middle_names: previousMiddleNames,
          last_name: previousLastName,
        }),
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Basic " + useAuthHeader.base64encodedData,
            service: "hosted",
          },

          // withCredentials: true,
        }
      );

      getPerson();
      getPreviousNames(entityData.value.person_uuid);
      getRequestData();
      getCase();

      displayToast("Previous name or alias added successfully", () => {
        setProcessing(false);
        setPreviousTitle("");
        setPreviousFirstName("");
        setPreviousMiddleNames("");
        setPreviousLastName("");
        setEditMode(false);
        setEditType(1);
      });
    } catch (err) {
      console.log(err);
      handleError(err);
      setProcessing(false);
    }
  };

  const handleDeletePreviousName = async (e) => {
    e.preventDefault();
    setProcessing(true);
    try {
      const response = await partner.post(
        `/v1/entity/people/person/name/previous-names/remove`,
        JSON.stringify({
          person_uuid: entityData.value.person_uuid,
          name_uuid: deletePreviousNameUUID,
        }),
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Basic " + useAuthHeader.base64encodedData,
            service: "hosted",
          },

          // withCredentials: true,
        }
      );

      getPerson();
      getPreviousNames(entityData.value.person_uuid);
      setDeletePreviousNameDialog(false);

      displayToast("Previous name or alias deleted successfully", () => {
        setProcessing(false);
        setDeletePreviousNameUUID("");
      });
    } catch (err) {
      console.log(err);
      handleError(err);
      setProcessing(false);
    }
  };

  useEffect(() => {
    getPreviousNames(entityData.value.person_uuid);
  }, [entityData.value]);

  return (
    <Box>
      <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "space-between", borderBottom: 1, borderColor: "divider" }}>
        <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center", p: 2 }}>
          <Typography variant="subtitle2">Names and aliases</Typography>
        </Box>
        <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center", p: 2 }}>
          <IconButton
            title="Edit"
            onClick={() => {
              setEditType(1);
              setEditMode(!editMode);
            }}
          >
            {editMode ? <Close /> : <Edit />}
          </IconButton>
        </Box>
      </Box>

      {!editMode ? (
        <Box>
          <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "space-between", p: 2 }}>
            <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
              <Typography variant="subtitle1" sx={{ fontStyle: "italic", fontSize: "13px" }}>
                Full name
              </Typography>
            </Box>
            <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
              <Typography variant="h6" sx={{}}>
                {entityData.value.title ? entityData.value.title : ""} {entityData.value.first_name}{" "}
                {entityData.value.middle_names ? entityData.value.middle_names : ""} {entityData.value.last_name}
              </Typography>
            </Box>
          </Box>
          <Divider />

          {previousNames.length > 0 && (
            <>
              <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "space-between", p: 2 }}>
                <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
                  <Typography variant="subtitle1" sx={{ fontStyle: "italic", fontSize: "13px" }}>
                    Previous names
                  </Typography>
                </Box>
                <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
                  {previousNames.map((name, index) => (
                    <Typography key={index} variant="body2" sx={{}}>
                      {name.title ? name.title : ""} {name.first_name} {name.middle_names ? name.middle_names : ""} {name.last_name}
                    </Typography>
                  ))}
                </Box>
              </Box>

              <Divider />
            </>
          )}

          <Box sx={{ p: 2 }}>
            <Typography variant="subtitle2" sx={{ fontSize: "13px" }}>
              Please ensure your name is correct including any middle names, you can also add any previous names or aliases.
            </Typography>
          </Box>
          <Divider />

          <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "space-between", p: 2 }}>
            <Button
              variant="outlined"
              size="small"
              color="primary"
              onClick={() => {
                setEditType(2);
                setEditMode(true);
              }}
            >
              Manage previous names
            </Button>
            <Button
              variant="contained"
              size=""
              color="primary"
              onClick={() => {
                activeView.value = 1;
              }}
            >
              Continue
            </Button>
          </Box>
        </Box>
      ) : (
        <Box>
          {editType == 1 && (
            <Box>
              <Box sx={{ p: 2, display: "flex", flexDirection: "column", gap: 2 }}>
                <FormControl fullWidth>
                  <InputLabel id="name-title-label">Title</InputLabel>
                  <Select labelId="name-title" id="name-title" value={title} label="Title" onChange={(e) => setTitle(e.target.value)}>
                    {titleOptions.map((option, index) => (
                      <MenuItem key={index} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <TextField fullWidth label="First name" value={firstName} onChange={(e) => setFirstName(e.target.value)} />
                <TextField fullWidth label="Middle name(s)" value={middleNames} onChange={(e) => setMiddleNames(e.target.value)} />
                <TextField fullWidth label="Last name" value={lastName} onChange={(e) => setLastName(e.target.value)} />
              </Box>

              <Divider />

              <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "flex-end", p: 2 }}>
                <LoadingButton
                  disableElevation
                  variant="contained"
                  loading={processing}
                  color="success"
                  onClick={(e) => {
                    handleUpdateName(e);
                  }}
                >
                  Save changes
                </LoadingButton>
              </Box>
            </Box>
          )}
          {editType == 2 && (
            <Box>
              <Box sx={{ px: 2, py: 1, backgroundColor: "#0b0b0b" }}>
                <Typography variant="subtitle1" sx={{ color: "#ffffff", fontStyle: "italic" }}>
                  Managing previous names and aliases
                </Typography>
              </Box>
              <Divider />
              <Box sx={{ p: 2, display: "flex", flexDirection: "column", gap: 2 }}>
                <FormControl fullWidth>
                  <InputLabel id="name-title-label">Title</InputLabel>
                  <Select labelId="name-title" id="name-title" value={previousTitle} label="Title" onChange={(e) => setPreviousTitle(e.target.value)}>
                    {titleOptions.map((option, index) => (
                      <MenuItem key={index} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <TextField fullWidth label="First name" value={previousFirstName} onChange={(e) => setPreviousFirstName(e.target.value)} />
                <TextField fullWidth label="Middle name(s)" value={previousMiddleNames} onChange={(e) => setPreviousMiddleNames(e.target.value)} />
                <TextField fullWidth label="Last name" value={previousLastName} onChange={(e) => setPreviousLastName(e.target.value)} />
              </Box>

              <Divider />

              <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "flex-end", p: 2 }}>
                <LoadingButton
                  disableElevation
                  variant="contained"
                  loading={processing}
                  color="success"
                  onClick={(e) => {
                    handleAddPreviousName(e);
                  }}
                >
                  Add name
                </LoadingButton>
              </Box>

              {previousNames.length > 0 && (
                <>
                  <Divider />

                  <Box sx={{}}>
                    <Box sx={{ backgroundColor: "#0b0b0b", p: 2 }}>
                      <Typography variant="subtitle2" sx={{ fontSize: "13px", color: "#ffffff" }}>
                        Previous names
                      </Typography>
                    </Box>

                    {previousNames.map((name, index) => (
                      <Box key={index} sx={{ display: "flex", flexDirection: "row", justifyContent: "space-between", p: 2 }}>
                        <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
                          <Typography variant="body2" sx={{}}>
                            {name.title ? name.title : ""} {name.first_name} {name.middle_names ? name.middle_names : ""} {name.last_name}
                          </Typography>
                        </Box>
                        <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
                          <IconButton
                            sx={{ color: "#C83E4D" }}
                            title="Remove"
                            onClick={() => {
                              setDeletePreviousNameUUID(name.uuid);
                              setDeletePreviousNameDialog(true);
                            }}
                          >
                            <Delete />
                          </IconButton>
                        </Box>
                      </Box>
                    ))}
                  </Box>
                </>
              )}

              <Dialog
                open={deletePreviousNameDialog}
                onClose={() => setDeletePreviousNameDialog(false)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
              >
                <DialogTitle id="alert-dialog-title">Are you sure?</DialogTitle>
                <DialogContent>
                  <Box>
                    <DialogContentText sx={{ mb: 1 }} id="alert-dialog-description">
                      Are you sure you want to remove this previous name?
                    </DialogContentText>
                  </Box>
                </DialogContent>
                <DialogActions>
                  <LoadingButton disableElevation loading={processing} onClick={() => setDeletePreviousNameDialog(false)} color="primary">
                    Cancel
                  </LoadingButton>

                  <LoadingButton
                    disableElevation
                    sx={{ color: "#ffffff" }}
                    loading={processing}
                    onClick={(e) => {
                      handleDeletePreviousName(e);
                    }}
                    variant="contained"
                    color="danger"
                    autoFocus
                  >
                    Confirm
                  </LoadingButton>
                </DialogActions>
              </Dialog>
            </Box>
          )}
        </Box>
      )}
    </Box>
  );
};

export default Name;
