import { React, useEffect, useState } from "react";

import Lottie from "react-lottie";
import loadingLottie from "../../components/lotties/loading-dots.json";

import { CssBaseline, Box, Divider, Typography, Button, Chip, Grid, TextField } from "@mui/material";

const SessionComplete = () => {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: loadingLottie,
  };

  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    setIsLoaded(true);
  }, []);

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />

      <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
        {isLoaded ? (
          <Box>
            <Grid container>
              <Grid item xs={12} lg={5} xl={4} sx={{ mx: "auto", border: 1, borderColor: "divider" }}>
                <Box sx={{ display: "flex", flexDirection: "column" }}>
                  <Box sx={{ width: { xs: "90%", sm: "80%", lg: "70%", xl: "60%" }, p: 2, mx: "auto", mt: 2 }}>
                    <img src="/cleverli-logo-black.svg" alt="logo" style={{}} />
                  </Box>
                </Box>

                <Divider />

                <Box sx={{ p: 2, backgroundColor: "#0b0b0b", color: "#ffffff" }}>
                  <Typography variant="h6" align="center" sx={{ textTransform: "uppercase" }}>
                    Your session has ended
                  </Typography>
                </Box>
              </Grid>
            </Grid>
          </Box>
        ) : (
          <Box>
            <Lottie speed={2.0} options={defaultOptions} height={200} width={200} />
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default SessionComplete;
