import { Modal } from "../modal";
import PropTypes from "prop-types";
import { CreateNoteFrom } from "./create-note-from";
import { notesPropType } from "./propTypes";

export function NoteModal({
  note,
  onClose,
  open,
  taskData,
  noteType,
  noteUUID,
}) {
  return (
    <Modal open={open} onClose={onClose} noteUUID={noteUUID}>
      <CreateNoteFrom
        note={note}
        onClose={onClose}
        taskData={taskData}
        noteType={noteType}
        noteUUID={noteUUID}
      />
    </Modal>
  );
}

NoteModal.propTypes = {
  note: notesPropType,
  onClose: PropTypes.func,
  open: PropTypes.bool,
  taskData: PropTypes.object,
  noteType: PropTypes.oneOf(["note", "reply"]),
  noteUUID: PropTypes.string,
};
