import React from "react";
import Webcam from "react-webcam";

const WebcamDisplay = ({ videoElement, videoConstraints, setPermissionsError, setBaseInstructions, getDevice }) => {
  return (
    <Webcam
      id="video-output"
      audio={true}
      muted={true}
      ref={videoElement}
      videoConstraints={videoConstraints}
      onUserMedia={(e) => {
        var tracks = e.getTracks();
        for (var i = 0; i < tracks.length; i++) {
          getDevice(tracks[i].getSettings().deviceId);
        }

        setBaseInstructions("Press the start button to begin recording");
      }}
      onUserMediaError={() => {
        setPermissionsError(true);
      }}
    />
  );
};

export default WebcamDisplay;
