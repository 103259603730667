import {
  Box,
  Button,
  Divider,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Skeleton,
  Stack,
  Typography,
} from "@mui/material";
import { TaskItem } from "../task-item";
import { AsyncRenderWhen } from "../../../../../../components/async-render-when";
import PropTypes from "prop-types";
import { useGetPartnerUsersList } from "../../../../../../hooks/use-get-partner-users-list";
import { useGetClientTasks } from "../../../../../../hooks/use-get-client-tasks";
import { useState } from "react";
import { RenderWhen } from "../../../../../../components/render-when";
import Lottie from "react-lottie";
import emptyTaskList from "../../../../../../components/lotties/empty-task-list.json";

const stateArray = [
  { id: "-1", name: "unassigned" },
  { id: "0", name: "all" },
  { id: "1", name: "future" },
  { id: "2", name: "historic" },
  { id: "3", name: "overdue" },
];

export function TasksOverview({ clientData, activeView }) {
  const clientUUID = clientData?.v?.client_uuid;
  const [filters, setFilters] = useState({
    assigned: undefined,
    filterType: undefined,
  });
  const { clientTasksList, isLoading } = useGetClientTasks({
    clientUUID,
    filterType: filters.filterType,
    targetUserUUID: filters.assigned,
  });
  const { partnerUsersList } = useGetPartnerUsersList();

  return (
    <>
      <Grid container spacing={2} sx={{ p: 2 }}>
        <Grid item xs={2}>
          <FormControl fullWidth size="small">
            <InputLabel>Assignee</InputLabel>
            <Select
              value={filters?.assigned || ""}
              label="Assignee"
              onChange={(e) => {
                if (e.target.value === "unassigned") {
                  setFilters((perv) => ({ ...perv, assigned: undefined }));
                  return;
                }
                setFilters((perv) => ({ ...perv, assigned: e.target.value }));
              }}
            >
              <MenuItem disabled value="unassigned">
                <em>Unassigned</em>
              </MenuItem>
              {partnerUsersList?.map((user) => (
                <MenuItem key={user?.uuid} value={user?.uuid}>
                  {user?.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={2}>
          <FormControl fullWidth size="small">
            <InputLabel>State</InputLabel>
            <Select
              value={filters?.filterType ?? ""}
              label="State"
              onChange={(e) => {
                if (e.target.value === "-1") {
                  setFilters(() => ({ filterType: -1, assigned: undefined }));
                  return;
                }
                setFilters((prev) => {
                  return { ...prev, filterType: +e.target.value };
                });
              }}
            >
              {stateArray?.map((taskState) => (
                <MenuItem key={taskState.id} value={taskState.id}>
                  {taskState.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={2}>
          <Button
            variant="contained"
            color="primary"
            onClick={() => setFilters({ assigned: undefined, filterType: undefined })}
          >
            Clear
          </Button>
        </Grid>
      </Grid>
      <RenderWhen when={clientTasksList?.length > 0}>
        <Grid container justifyContent="center" alignItems="center" sx={{ px: 2 }} spacing={2}>
          <Grid item xs={4}>
            Name
          </Grid>
          <Grid item xs={3}>
            Status
          </Grid>
          <Grid item xs={2}>
            Scheduled
          </Grid>
          <Grid item xs={3}>
            Assigned
          </Grid>
        </Grid>
        <Divider sx={{ my: 2 }} />
      </RenderWhen>
      <RenderWhen when={clientTasksList?.length === 0}>
        <Stack direction="row" justifyContent="space-between" alignItems="center" px="10px">
          <Typography fontWeight={500}>Tasks list is empty</Typography>
          <Box sx={{ width: 100 }}>
            <Lottie options={{ loop: true, autoplay: true, animationData: emptyTaskList }} />
          </Box>
        </Stack>
      </RenderWhen>
      <AsyncRenderWhen
        isLoading={isLoading}
        loading={
          <>
            {Array.from(Array(5).keys()).map((i) => (
              <Skeleton sx={{ my: 2 }} variant="rectangular" width="100%" height={60} key={i} />
            ))}
          </>
        }
      >
        {clientTasksList?.map((task) => (
          <TaskItem key={task?.uuid} activeView={activeView} task={task} />
        ))}
      </AsyncRenderWhen>
    </>
  );
}

TasksOverview.propTypes = {
  clientData: PropTypes.object,
  activeView: PropTypes.object,
};
