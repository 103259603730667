import { Box, Button, Chip, Divider, Typography } from "@mui/material";
import NoResults from "../../../../../../components/functions/NoResults";
import PropTypes from "prop-types";
import { AsyncRenderWhen } from "../../../../../../components/async-render-when";

export function StepsLists({ stepsData, newTaskFormState, onOpenStepModal, onStepClick }) {
  return (
    <Box sx={{ border: 1, borderColor: "divider" }}>
      <Box sx={{ p: 2, display: "flex", justifyContent: "space-between" }}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
          }}
        >
          <Typography variant="subtitle2">Workflow steps</Typography>
        </Box>
        <AsyncRenderWhen when={newTaskFormState?.taskType === "workflow"}>
          <Button size="large" onClick={onOpenStepModal} variant="contained">
            Add Workflow Steps
          </Button>
        </AsyncRenderWhen>
      </Box>
      <Divider />
      {stepsData?.length > 0 ? (
        <Box>
          {stepsData?.map((step, index) => {
            if (!step?.active) return null;
            return (
              <Box
                key={step?.uuid || step?.id}
                sx={{
                  p: 2,
                  display: "flex",
                  justifyContent: "space-between",
                  borderTop: index > 0 ? 1 : 0,
                  borderColor: "divider",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                  }}
                >
                  <Typography
                    onClick={() => {
                      if (newTaskFormState?.taskType !== "workflow") return;
                      onStepClick(step);
                    }}
                    className="link"
                    variant="subtitle2"
                  >
                    {step?.label}
                  </Typography>
                </Box>
                <Box sx={{ display: "flex", gap: 1 }}>
                  {step?.requires_confirmation && <Chip className="square-chip" label="Requires confirmation" color="success" />}
                  {step?.requires_file && <Chip className="square-chip" label="Requires file upload" color="success" />}
                </Box>
              </Box>
            );
          })}
        </Box>
      ) : (
        <NoResults message="Add the first step of the task" />
      )}
    </Box>
  );
}

StepsLists.propTypes = {
  stepsData: PropTypes.arrayOf(
    PropTypes.shape({
      uuid: PropTypes.string,
      label: PropTypes.string,
      description: PropTypes.string,
      requires_confirmation: PropTypes.bool,
      requires_file: PropTypes.bool,
      file_description: PropTypes.string,
      order: PropTypes.number,
      active: PropTypes.bool,
    })
  ),
  onStepClick: PropTypes.func,
  newTaskFormState: PropTypes.object,
  onOpenStepModal: PropTypes.func,
};
