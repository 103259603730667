import { React, useEffect, useState } from "react";

import central from "../../../../../../../../api/central";

import authHeader from "../../../../../../../../api/authHeader";

import HandleError from "../../../../../../../../components/functions/HandleError";
import HandleToast from "../../../../../../../../components/functions/HandleToast";

import "moment/dist/locale/en-gb";
import moment from "moment";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

import { Typography, Box, Button, Divider, Card, Avatar, CardHeader, CardContent, IconButton, Alert, Icon } from "@mui/material";
import { Grid, TextField, FormControl, InputLabel, MenuItem, Select, ToggleButton, ToggleButtonGroup, Chip } from "@mui/material";
import { Add, Delete } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import NoResults from "../../../../../../../../components/functions/NoResults";

import CountrySelect from "../../../../../../../../components/form-elements/CountrySelect";

const MultipleCorporateEntities = ({ partnerUser }) => {
  const useAuthHeader = authHeader();

  const handleError = HandleError();

  const [processing, setProcessing] = useState(false);

  const [locale, setLocale] = useState("en-gb");

  if (moment.locale() != locale) {
    moment.locale(locale);
  }

  const [lookupCorporation, setLookupCorporation] = useState(true);
  const [lookupName, setLookupName] = useState("");
  const [corporationOptions, setCorporationOptions] = useState([]);
  const [selectedCorporation, setSelectedCorporation] = useState("");

  const [registrationTypeOptions, setRegistrationTypeOptions] = useState([]);
  const [registrationType, setRegistrationType] = useState("");
  const [corporationTypeLabel, setCorporationTypeLabel] = useState("");
  const [registrationTypeData, setRegistrationTypeData] = useState({});
  const [registrationName, setRegistrationName] = useState("");
  const [registrationReference, setRegistrationReference] = useState("");
  const [registrationDate, setRegistrationDate] = useState(null);
  const [registrationCountry, setRegistrationCountry] = useState();

  const handleLookupCorporations = async (e) => {
    e.preventDefault();
    setProcessing(true);
    try {
      const response = await central.post(
        `/v1/service/corporations/lookup/options`,
        JSON.stringify({
          partner_uuid: partnerUser.value.partner_uuid,
          search_terms: lookupName,
        }),
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Basic " + useAuthHeader.base64encodedData,
            service: "partners",
            partner_uuid: partnerUser.value.partner_uuid,
          },

          // withCredentials: true,
        }
      );

      if (response.data.results > 0) {
        setCorporationOptions(response.data.entries);
      } else {
        setCorporationOptions([]);
      }
      setProcessing(false);
    } catch (err) {
      console.log(err);
      handleError(err);
      setProcessing(false);
    }
  };

  const getCorporationData = async () => {
    if (selectedCorporation == "") {
      return;
    }
    try {
      const response = await central.post(
        `/v1/service/corporations/data`,
        JSON.stringify({
          partner_uuid: partnerUser.value.partner_uuid,
          uuid: selectedCorporation,
        }),
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Basic " + useAuthHeader.base64encodedData,
            service: "partners",
            partner_uuid: partnerUser.value.partner_uuid,
          },

          //withCredentials: true,
        }
      );

      setRegistrationName(response.data.name);
      setRegistrationReference(response.data.company_number);
      setRegistrationDate(moment(response.data.incorporation_date));
      setRegistrationCountry(response.data.country);
      setCorporationTypeLabel(response.data.company_type);
      console.log(response.data);
    } catch (err) {
      console.log(err);
      handleError(err);
    }
  };

  const getRegistrationTypeOptions = async () => {
    if (!registrationCountry) {
      return;
    }
    try {
      const response = await central.post(
        `/v1/service/corporations/types/options`,
        JSON.stringify({
          country: registrationCountry.value ? registrationCountry.value : registrationCountry.code ? registrationCountry.code : "",
        }),
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Basic " + useAuthHeader.base64encodedData,
            service: "partners",
            partner_uuid: partnerUser.value.partner_uuid,
          },

          //withCredentials: true,
        }
      );

      if (response.data.results > 0) {
        setRegistrationTypeOptions(response.data.entries);
      } else {
        setRegistrationTypeOptions([]);
      }
    } catch (err) {
      console.log(err);
      handleError(err);
    }
  };

  const getRegistrationTypeData = async () => {
    if (registrationType == "") {
      return;
    }
    try {
      const response = await central.post(
        `/v1/service/corporations/type-data`,
        JSON.stringify({
          type_uuid: registrationType,
        }),
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Basic " + useAuthHeader.base64encodedData,
            service: "partners",
            partner_uuid: partnerUser.value.partner_uuid,
          },

          //withCredentials: true,
        }
      );

      setRegistrationTypeData(response.data);
    } catch (err) {
      console.log(err);
      handleError(err);
    }
  };

  useEffect(() => {
    if (selectedCorporation != "") {
      getCorporationData();
    }
  }, [selectedCorporation]);

  useEffect(() => {
    if (registrationCountry) {
      getRegistrationTypeOptions();
    } else {
      setRegistrationTypeOptions([]);
      setRegistrationType("");
    }
  }, [registrationCountry]);

  useEffect(() => {
    if (registrationType != "") {
      getRegistrationTypeData();
    }
  }, [registrationType]);

  const [entities, setEntities] = useState([
    { name: "Test Company Limited", country: "GB", registration_number: "12345678", corporation_uuid: selectedCorporation },
  ]);
  const [addEntity, setAddEntity] = useState(false);

  const addEntityHandler = () => {
    if (registrationName == "" || registrationReference == "" || registrationCountry == "") {
      return;
    }

    setEntities([
      ...entities,
      { name: registrationName, country: registrationCountry, registration_number: registrationReference, corporation_uuid: "" },
    ]);
    setAddEntity(false);
  };

  const removeEntityHandler = (index) => {
    const newEntities = [...entities];
    newEntities.splice(index, 1);
    setEntities(newEntities);
  };

  return !addEntity ? (
    <Box sx={{ border: 1, borderColor: "divider" }}>
      <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "space-between", p: 2 }}>
        <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
          <Typography variant="subtitle2">Corporations</Typography>
        </Box>
        <Button onClick={() => setAddEntity(true)} variant="outlined" color="primary" startIcon={<Add />}>
          Add corporation
        </Button>
      </Box>
      <Box sx={{}}>
        {entities.length > 0 ? (
          entities.map((entity, index) => (
            <Box
              key={index}
              sx={{
                display: "flex",
                flexDirection: "row",
                gap: 2,
                justifyContent: "space-between",
                px: 2,
                py: 1,
                borderTop: 1,
                borderColor: "divider",
              }}
            >
              <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
                <Typography variant="subtitle2">
                  {entity.name} ({entity.registration_number})
                </Typography>
              </Box>
              <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                <IconButton color="danger" onClick={() => removeEntityHandler(index)}>
                  <Delete />
                </IconButton>
              </Box>
            </Box>
          ))
        ) : (
          <NoResults message={`Add your first corporation`} />
        )}
      </Box>
    </Box>
  ) : (
    <Box sx={{ display: "flex", flexDirection: "column" }}>
      <Box sx={{ display: "flex", flexDirection: "row", gap: 2, justifyContent: "space-between", p: 2 }}>
        <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
          <Typography variant="subtitle2">Add a corporate entity</Typography>
        </Box>
        <Button onClick={() => setAddEntity(false)} variant="outlined" color="primary">
          Cancel
        </Button>
      </Box>
      <Divider />

      <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
        <Box sx={{ border: 1, borderColor: "divider" }}>
          <Box sx={{ p: 2 }}>
            <Typography variant="body2">
              <strong>How would you like to input the business information?</strong>
            </Typography>
          </Box>
          <Divider />
          <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
            <Box sx={{ p: 2 }}>
              <ToggleButtonGroup
                color="primary"
                value={lookupCorporation}
                exclusive
                onChange={(e, newValue) => {
                  setLookupCorporation(newValue);
                  setRegistrationCountry(null);
                  setSelectedCorporation("");
                  setRegistrationType("");
                  setRegistrationTypeOptions([]);
                  setRegistrationTypeData({});
                  setRegistrationName("");
                  setRegistrationReference("");
                  setRegistrationDate(null);
                }}
                aria-label="Entry type"
              >
                <ToggleButton value={true}>Lookup corporation</ToggleButton>
                <ToggleButton value={false}>Manual entry</ToggleButton>
              </ToggleButtonGroup>
            </Box>

            {lookupCorporation ? (
              <Box>
                <Box sx={{ px: 2 }}>
                  <TextField
                    fullWidth
                    id="outlined-basic"
                    label="Business name"
                    variant="outlined"
                    value={lookupName}
                    onChange={(e) => setLookupName(e.target.value)}
                  />

                  <Box sx={{ display: "flex", justifyContent: "flex-end", mt: 1, mb: corporationOptions?.length > 0 ? 0 : 2 }}>
                    <LoadingButton
                      disableElevation
                      loading={processing}
                      variant="contained"
                      color="primary"
                      onClick={(e) => handleLookupCorporations(e)}
                    >
                      Lookup corporation
                    </LoadingButton>
                  </Box>
                </Box>

                {corporationOptions?.length > 0 && (
                  <Box sx={{ display: "flex", flexDirection: "column", mt: 2, borderTop: 1, borderColor: "divider" }}>
                    <Box sx={{ p: 2 }}>
                      <Typography variant="body2">
                        <strong>Select a result</strong>
                      </Typography>
                    </Box>
                    <Divider />

                    <Box sx={{ p: 2 }}>
                      <FormControl fullWidth>
                        <InputLabel id="corporation-options-label">Search results</InputLabel>

                        <Select
                          labelId="corporation-options-label"
                          id="corporation-options"
                          value={selectedCorporation}
                          label="Select corporation"
                          onChange={(e) => {
                            setSelectedCorporation(e.target.value);
                          }}
                        >
                          {corporationOptions?.map((option, index) => (
                            <MenuItem key={index} value={option.value}>
                              <Typography variant="body2">{option.label}</Typography>
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Box>
                  </Box>
                )}
              </Box>
            ) : (
              <Box sx={{ display: "flex", flexDirection: "column", gap: 2, px: 2, pb: 2 }}>
                <CountrySelect value={registrationCountry} setValue={setRegistrationCountry} />

                {registrationCountry && (
                  <>
                    {registrationTypeOptions?.length > 0 && (
                      <>
                        <FormControl fullWidth>
                          <InputLabel id="owner-label">Registration type</InputLabel>

                          <Select
                            labelId="owner"
                            id="owner"
                            value={registrationType}
                            label="Account manager"
                            onChange={(e) => setRegistrationType(e.target.value)}
                          >
                            {registrationTypeOptions?.map((option, index) => (
                              <MenuItem key={index} value={option.value}>
                                {option.label}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </>
                    )}

                    <TextField
                      fullWidth
                      id="outlined-basic"
                      label={registrationType && registrationTypeData?.registration_required ? "Registered name" : "Business name"}
                      variant="outlined"
                      value={registrationName}
                      onChange={(e) => setRegistrationName(e.target.value)}
                    />

                    {registrationType && registrationTypeData.registration_required && (
                      <TextField
                        fullWidth
                        id="outlined-basic"
                        label="Registration reference"
                        variant="outlined"
                        value={registrationReference}
                        onChange={(e) => setRegistrationReference(e.target.value)}
                      />
                    )}

                    <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale={locale}>
                      <DatePicker
                        fullWidth
                        openTo="year"
                        label={`${registrationType && registrationTypeData.registration_required ? "Registration date" : "Start date"}`}
                        value={registrationDate ? registrationDate : null}
                        onChange={(date) => {
                          setRegistrationDate(date);
                        }}
                        slotProps={{ textField: { fullWidth: true } }}
                        maxDate={moment()}
                      />
                    </LocalizationProvider>

                    {registrationType && registrationTypeData.description && (
                      <Card variant="outlined">
                        <CardHeader title="Registration type information" />
                        <CardContent sx={{ pt: 0 }}>
                          <Typography variant="body2">{registrationTypeData.description}</Typography>
                        </CardContent>
                      </Card>
                    )}
                  </>
                )}
              </Box>
            )}
          </Box>
        </Box>
      </Box>

      <Box sx={{ display: "flex", flexDirection: "column", gap: 2, p: 2 }}>
        <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
          <Button onClick={() => addEntityHandler()} disableElevation variant="contained" color="success">
            Add corporation
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default MultipleCorporateEntities;
