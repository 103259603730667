import { React, useCallback, useEffect, useState } from "react";

import partner from "../../../../api/partner";
import hostedAuthHeader from "../../../../api/hostedAuthHeader";

import HandleError from "../../../../components/functions/HostedHandleError";
import HandleToast from "../../../../components/functions/HandleToast";

import Lottie from "react-lottie";
import fileCheckLottie from "../../../../components/lotties/file-check.json";

import { useDropzone } from "react-dropzone";

import { Typography, Box, IconButton } from "@mui/material";
import { Delete, PermMedia } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";

const MultipleFileUpload = ({ questionUUID, setAnswersData, answersData, getRequestData }) => {
  const useAuthHeader = hostedAuthHeader();
  const handleError = HandleError();
  const displayToast = HandleToast();

  const successOptions = {
    loop: false,
    autoplay: true,
    animationData: fileCheckLottie,
  };

  const [fileSelection, setFileSelection] = useState(null);
  const [files, setFiles] = useState([]);
  const [processing, setProcessing] = useState(false);

  const onDrop = useCallback((acceptedFiles) => {
    setFileSelection(acceptedFiles);
    acceptedFiles.forEach((file) => {
      setFiles((prevFiles) => [
        ...prevFiles,
        {
          file: file,
        },
      ]);
    });
  }, []);

  const { acceptedFiles, getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: {
      "image/png": [".png"],
      "image/jpeg": [".jpg", ".jpeg"],
      "image/gif": [".gif"],
      "image/svg+xml": [".svg"],

      "application/pdf": [".pdf"],
      "application/msword": [".doc"],
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document": [".docx"],
      "application/vnd.ms-excel": [".xls"],
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": [".xlsx"],
      "application/vnd.ms-powerpoint": [".ppt"],
      "application/vnd.openxmlformats-officedocument.presentationml.presentation": [".pptx"],
      "application/vnd.oasis.opendocument.text": [".odt"],
      "application/vnd.oasis.opendocument.spreadsheet": [".ods"],
      "application/vnd.oasis.opendocument.presentation": [".odp"],

      "audio/mpeg": [".mp3"],
      "audio/ogg": [".ogg"],
      "audio/wav": [".wav"],
      "audio/midi": [".mid"],
      "audio/x-ms-wma": [".wma"],
      "audio/x-ms-wax": [".wax"],
      "audio/xm": [".xm"],
      "audio/x-aac": [".aac"],
      "audio/x-aiff": [".aiff"],
      "audio/x-flac": [".flac"],
      "audio/x-matroska": [".mka"],
      "audio/x-mpegurl": [".m3u"],
      "audio/x-pn-realaudio": [".ra"],
      "audio/x-pn-realaudio-plugin": [".rpm"],

      "video/mp4": [".mp4"],
      "video/mpeg": [".mpeg"],
      "video/ogg": [".ogv"],
      "video/quicktime": [".mov"],
      "video/webm": [".webm"],
      "video/x-msvideo": [".avi"],
      "video/x-flv": [".flv"],
      "video/x-matroska": [".mkv"],
      "video/x-ms-wmv": [".wmv"],
      "video/x-ms-asf": [".asf"],
      "video/x-mng": [".mng"],
      "video/x-ms-asx": [".asx"],
      "video/x-ms-wvx": [".wvx"],
      "video/x-ms-wmx": [".wmx"],
      "video/x-ms-wm": [".wm"],
      "video/x-ms-wmp": [".wmp"],
      "video/x-ms-wmv": [".wmv"],

      "text/plain": [".txt"],
      "application/zip": [".zip"],
      "application/x-rar-compressed": [".rar"],
      "application/x-7z-compressed": [".7z"],
      "application/x-tar": [".tar"],
      "application/x-gzip": [".gz"],
      "application/x-bzip2": [".bz2"],
      "application/x-xz": [".xz"],
    },
    maxFiles: 10,
  });

  const handleUploadFiles = async (e) => {
    e.preventDefault();
    setProcessing(true);

    const formData = new FormData();

    formData.append("question_uuid", questionUUID);
    formData.append("file_count", files.length);

    // loop through the files and append to the formData
    files.forEach((file) => {
      formData.append("files", file.file);
    });

    try {
      const response = await partner.post("/v1/screening/case/kyc/enhanced/answer/upload/multiple", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: "Basic " + useAuthHeader.base64encodedData,
          service: "hosted",
        },
        onUploadProgress: function (progressEvent) {},

        //withCredentials: true,
      });

      getRequestData();

      displayToast("Documents uploaded successfully", () => {
        setProcessing(false);
      });
    } catch (err) {
      handleError(err);
      setProcessing(false);
    }
  };

  return (
    <>
      {answersData[questionUUID] ? (
        <>
          <Box sx={{ backgroundColor: "#8BBF9F", p: 2 }}>
            <Typography variant="h6" sx={{ color: "#ffffff", textTransform: "uppercase", textAlign: "center" }}>
              Upload completed successfully
            </Typography>

            <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "center" }}>
              <Lottie speed={1.0} options={successOptions} height={100} width={100} />
            </Box>
          </Box>
        </>
      ) : (
        <>
          {" "}
          <Box
            className="file-drop"
            sx={{
              border: 2,
              borderColor: "divider",
              borderStyle: "dashed",
              cursor: "pointer",
              p: 5,
              my: 2,

              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
            }}
            {...getRootProps()}
          >
            <input {...getInputProps()} />
            {isDragActive ? (
              <Typography align="center">Drop the file here ...</Typography>
            ) : (
              <Typography align="center">Drop a file here, or click to select files</Typography>
            )}
          </Box>
          {files?.length > 0 && (
            <>
              <Box sx={{ borderTop: 1, borderBottom: 1, borderColor: "divider" }}>
                {files.map((file, index) => (
                  <Box
                    key={index}
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      gap: 2,
                      border: 1,
                      borderTop: index == 0 ? 0 : 1,
                      borderBottom: 0,
                      borderColor: "divider",
                      justifyContent: "space-between",
                    }}
                  >
                    <Box sx={{ display: "flex", flexDirection: "row", width: "100%", justifyContent: "space-between" }}>
                      <Box sx={{ display: "flex" }}>
                        <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
                          <Box sx={{ display: "flex", flexDirection: "row", gap: 2, fontWeight: 600 }}>
                            <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center", pl: 2 }}>
                              <PermMedia />
                            </Box>
                            <Box sx={{ borderRight: 1, borderLeft: 1, borderColor: "divider", p: 1 }}>
                              <Typography variant="">Filename</Typography>
                            </Box>
                          </Box>
                        </Box>

                        <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center", pl: 2 }}>
                          <Box>{file.file.path}</Box>
                        </Box>
                      </Box>

                      <Box sx={{ pr: 1 }}>
                        <IconButton
                          title="Remove file"
                          onClick={() => {
                            setFiles((prevFiles) => {
                              return prevFiles.filter((item, i) => i !== index);
                            });
                          }}
                        >
                          <Delete />
                        </IconButton>
                      </Box>
                    </Box>
                  </Box>
                ))}
              </Box>
              <Box sx={{ py: 2, display: "flex", flexDirection: "row", justifyContent: "flex-end" }}>
                <LoadingButton
                  disableElevation
                  variant="contained"
                  color="success"
                  loading={processing}
                  onClick={(e) => {
                    handleUploadFiles(e);
                  }}
                >
                  Upload files
                </LoadingButton>
              </Box>
            </>
          )}
        </>
      )}
    </>
  );
};

export default MultipleFileUpload;
