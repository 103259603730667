import { Avatar, Chip, Grid, Stack, Typography } from "@mui/material";
import moment from "moment";
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";
import { tasksStatus } from "../../../../constants";
import { RenderWhen } from "../../../../../../components/render-when";

const DeadLineView = ({ deadline, isOverdue, deadlineTime }) => {
  if (!deadline) return "-";
  const _deadline = deadline * 1000;
  const timeFormat = deadlineTime ? "YYYY-MM-DD HH:mm" : "YYYY-MM-DD";
  const deadlineDate = moment(_deadline).format(timeFormat);
  const isValid = moment(deadlineDate, timeFormat, true).isValid();
  const isPassed = moment(_deadline).isBefore(moment());
  if (!isValid) return "-";
  return (
    <Typography
      sx={{
        borderRadius: 1,
        width: "fit-content",
        color: isPassed || isOverdue ? "error.light" : "",
      }}
    >
      {deadlineDate}
    </Typography>
  );
};

export function TaskItem({ activeView, task }) {
  const navigate = useNavigate();
  function handleTaskClick(taskId) {
    navigate(`?taskId=${taskId}`);
    activeView.value = 13;
  }

  return (
    <Grid container justifyContent="center" alignItems="center" spacing={2} sx={{ my: 1, px: 1 }}>
      <Grid item xs={4}>
        <Typography className="link" onClick={() => handleTaskClick(task?.uuid)}>
          {task?.name}
        </Typography>
      </Grid>
      <Grid item xs={3}>
        <Chip
          className="square-chip"
          label={tasksStatus?.[task?.status]?.label}
          color={tasksStatus?.[task?.status]?.color}
        />
      </Grid>
      <Grid item xs={2}>
        <DeadLineView
          deadline={task?.deadline}
          isOverdue={task?.is_overDue}
          deadlineTime={task?.deadline_time}
        />
      </Grid>
      <Grid item xs={3}>
        <RenderWhen when={!!task?.assigned_uuid}>
          <Stack direction="row" spacing={1} alignItems="center">
            <Avatar>
              {task?.assigned_name?.first_name?.charAt(0)}
              {task?.assigned_name?.last_name?.charAt(0)}
            </Avatar>
            <span>
              {task?.assigned_name?.first_name} {task?.assigned_name?.last_name}
            </span>
          </Stack>
        </RenderWhen>
        <RenderWhen when={!task?.assigned_uuid}>
          <Avatar>-</Avatar>
        </RenderWhen>
      </Grid>
    </Grid>
  );
}

TaskItem.propTypes = {
  task: PropTypes.object,
  activeView: PropTypes.object,
};

DeadLineView.propTypes = {
  deadline: PropTypes.number,
  isOverdue: PropTypes.bool,
  deadlineTime: PropTypes.bool,
};
