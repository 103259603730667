import Cookies from "universal-cookie";
import axios from "axios";

const partnerURL = () => {
  const cookies = new Cookies();
  return "https://" + cookies.get("platformURL");
};

export default axios.create({
  baseURL: partnerURL(),
});
