import { React, useCallback } from "react";

import { useDropzone } from "react-dropzone";

import { Typography, Box } from "@mui/material";

const VideoUpload = ({ setFile }) => {
  const onDrop = useCallback((acceptedFiles) => {
    acceptedFiles.forEach((file) => {
      setFile(file);
    });
  }, []);

  const { acceptedFiles, getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: {
      "video/mp4": [".mp4"],
      "video/quicktime": [".mov"],
      "video/avi": [".avi"],
      "video/3gpp": [".3gp"],
      "video/x-msvideo": [".avi"],
      "video/x-ms-wmv": [".wmv"],
      "video/x-flv": [".flv"],
      "video/webm": [".webm"],
      "video/ogg": [".ogg"],
      "video/x-matroska": [".mkv"],
      "video/3gpp2": [".3g2"],
      "video/mpeg": [".mpeg"],
      "video/x-ms-asf": [".asf"],
    },
    maxFiles: 1,
  });

  return (
    <>
      <Box sx={{ border: 1, borderColor: "divider" }}>
        <Box sx={{ p: 2, borderBottom: 1, borderColor: "divider", backgroundColor: "#0b0b0b", color: "#ffffff" }}>
          <Typography variant="subtitle2">Upload a video of the client</Typography>
        </Box>
        <Box sx={{ p: 2, pb: 0 }}>
          <Typography variant="body2" sx={{ fontSize: "13px", m: 0 }}>
            When uploading a video of the client, ensure they are the only person visible within the video and the images are clear, unfiltered and
            unedited. The video must also clearly capture the client's face and the client must be speaking.
          </Typography>
        </Box>
        <Box sx={{ p: 2, pt: 0 }}>
          <Box
            className="file-drop"
            sx={{
              border: 2,
              borderColor: "divider",
              borderStyle: "dashed",
              cursor: "pointer",
              p: 5,
              my: 2,

              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
            }}
            {...getRootProps()}
          >
            <input {...getInputProps()} />
            {isDragActive ? (
              <Typography align="center">Drop the file here ...</Typography>
            ) : (
              <Typography align="center">Drop a file here, or click to select a file</Typography>
            )}
            <Typography align="center" variant="caption">
              (Only pdf, jpeg or png, files will be accepted)
            </Typography>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default VideoUpload;
