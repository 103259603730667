import React from "react";

import { Box, Typography, Divider, Paper, Grid, Button } from "@mui/material";
import moment from "moment";

const AccountCard = ({ accountData, getAccountData }) => {
  function formatCurrency(amount, currencyCode, locale = "en-GB") {
    return new Intl.NumberFormat(locale, {
      style: "currency",
      currency: currencyCode,
    }).format(amount);
  }

  return (
    <Grid item xs={12} md={6} lg={6} xl={4}>
      <Paper variant="elevation" elevation={3} square={false} sx={{}}>
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              gap: 0,
              width: "100%",
            }}
          >
            <Box
              sx={{
                width: "100%",
                p: 2,
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
              }}
            >
              <Typography variant="h6">{accountData.label}</Typography>
            </Box>
            <Divider />

            <Box sx={{ display: "flex", flexDirection: "row", gap: 4, color: "#808080", p: 2 }}>
              <Box sx={{ display: "flex", flexDirection: "column" }}>
                <Typography variant="button">Sort code</Typography>
                <Typography variant="button">
                  {accountData.sortcode ? accountData.sortcode : "-"}
                </Typography>
              </Box>
              <Box sx={{ display: "flex", flexDirection: "column" }}>
                <Typography variant="button">Account number</Typography>
                <Typography variant="button">
                  {accountData.account_number ? accountData.account_number : "-"}
                </Typography>
              </Box>
            </Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              p: 2,
              borderLeft: 1,

              borderColor: "divider",
            }}
          >
            <Typography variant="h3">{accountData.currency}</Typography>
          </Box>
        </Box>
        <Divider />
        {accountData.status == 0 && (
          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <Box sx={{ p: 2 }}>
              <Typography variant="h6">Application under review</Typography>
            </Box>
          </Box>
        )}

        {accountData.status > 0 && (
          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <Box sx={{ p: 2, display: "flex", flexDirection: "column", gap: 2 }}>
              <Box>
                <Typography variant="button" sx={{ color: "#808080" }}>
                  Current balance
                </Typography>
                <Typography variant="h2">
                  {formatCurrency(accountData.full_balance, accountData.currency)}
                </Typography>
              </Box>
              <Box sx={{ display: "flex", flexDirection: "column" }}>
                <Typography variant="button" sx={{ color: "#808080" }}>
                  Latest transaction
                </Typography>
                <Typography variant="button">
                  {accountData.latest_transaction > 0
                    ? moment.unix(accountData.latest_transaction).format("DD/MM/YYYY HH:mm A")
                    : "-"}
                </Typography>
              </Box>
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-end",
                alignContent: "flex-end",
                alignItems: "flex-end",
                gap: 2,
                p: 2,
              }}
            >
              <Box>
                <Button
                  variant="contained"
                  disableElevation
                  color="primary"
                  disabled={accountData.status == 0 ? true : false}
                  onClick={() => {
                    getAccountData(accountData.uuid);
                  }}
                >
                  Manage account
                </Button>
              </Box>
            </Box>
          </Box>
        )}
      </Paper>
    </Grid>
  );
};

export default AccountCard;
