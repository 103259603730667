import { React, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";

import central from "../../api/central";
import authHeader from "../../api/authHeader";

import HandleError from "../../components/functions/HostedHandleError";
import HandleToast from "../../components/functions/HandleToast";

import Cookies from "universal-cookie";
import Lottie from "react-lottie";
import loadingLottie from "../../components/lotties/loading-dots.json";

import { CssBaseline, Box, Divider, Typography, Button, Chip, Grid, TextField } from "@mui/material";
import { LoadingButton } from "@mui/lab";

const HostedAuthentication = () => {
  const cookies = new Cookies();
  const navigate = useNavigate();

  const { t } = useParams();

  const useAuthHeader = authHeader();
  const handleError = HandleError();
  const displayToast = HandleToast();

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: loadingLottie,
  };

  const [isLoaded, setIsLoaded] = useState(false);
  const [processing, setProcessing] = useState(false);

  const [codeSent, setCodeSent] = useState(false);

  const [partnerName, setPartnerName] = useState("");
  const [partnerLogo, setPartnerLogo] = useState("");

  const [code1, setCode1] = useState("");
  const [code2, setCode2] = useState("");
  const [code3, setCode3] = useState("");
  const [code4, setCode4] = useState("");
  const [code5, setCode5] = useState("");
  const [code6, setCode6] = useState("");

  const getInvite = async (inviteUUID) => {
    try {
      const response = await central.post(
        `/v1/hosted/invite`,
        JSON.stringify({
          invite_uuid: inviteUUID,
        }),
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Basic " + useAuthHeader.base64encodedData,
            service: "hosted-auth",
          },

          //withCredentials: true,
        }
      );

      setPartnerName(response.data.partner_name);
      setPartnerLogo(response.data.partner_logo);

      setIsLoaded(true);
    } catch (err) {
      console.log(err);
      handleError(err);
    }
  };

  const handleSendAuthenticationCode = async () => {
    setProcessing(true);
    try {
      const response = await central.post(
        `/v1/hosted/invite/send-code`,
        JSON.stringify({
          invite_uuid: t,
        }),
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Basic " + useAuthHeader.base64encodedData,
            service: "hosted-auth",
          },

          //withCredentials: true,
        }
      );

      displayToast("Authentication code sent", () => {
        setProcessing(false);
        setCodeSent(true);
      });
    } catch (err) {
      console.log(err);
      setProcessing(false);
      handleError(err);
    }
  };

  const handleAuthentication = async (e) => {
    e.preventDefault();
    setProcessing(true);
    try {
      const response = await central.post(
        `/v1/hosted/invite/authenticate`,
        JSON.stringify({
          invite_uuid: t,
          code: code1 + code2 + code3 + code4 + code5 + code6,
        }),
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Basic " + useAuthHeader.base64encodedData,
            service: "hosted-auth",
          },

          //withCredentials: true,
        }
      );

      cookies.set("hostedID", response.data.token, {
        path: "/",
        expires: new Date(Date.now() + 1080000000),
      });

      cookies.set("hostedSecret", response.data.secret, {
        path: "/",
        expires: new Date(Date.now() + 1080000000),
      });

      cookies.set("platformURL", response.data.platform_url, {
        path: "/",
        expires: new Date(Date.now() + 1080000000),
      });

      displayToast("Authentication successful", () => {
        setProcessing(false);
        if (response.data.invite_type == 2) {
          navigate(`/e-hosted/${response.data.token}`, { replace: true });
        } else {
          navigate(`/hosted/${response.data.token}`, { replace: true });
        }
      });
    } catch (err) {
      console.log(err);
      setCode1("");
      setCode2("");
      setCode3("");
      setCode4("");
      setCode5("");
      setCode6("");
      document.getElementById("input1").focus();
      setProcessing(false);
      handleError(err);
    }
  };

  useEffect(() => {
    getInvite(t);
  }, []);

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />

      <Box component="main" sx={{ flexGrow: 1, p: { xs: 0, sm: 1, md: 3 } }}>
        {isLoaded ? (
          <Box>
            <Grid container>
              <Grid item xs={12} lg={5} xl={4} sx={{ mx: "auto", border: 1, borderColor: "divider" }}>
                <Box sx={{ display: "flex", flexDirection: "column" }}>
                  <Box sx={{ width: "100%", p: 2, display: "flex", justifyContent: "center" }}>
                    <Box
                      component="img"
                      src={partnerLogo ? partnerLogo : "/cleverli-logo-black.svg"}
                      alt="logo"
                      sx={{ maxWidth: 400, maxHeight: 300 }}
                    />
                  </Box>
                  {partnerLogo && (
                    <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                      <Box sx={{ py: 1, px: 2 }}>
                        <Typography variant="subtitle2" sx={{ fontSize: "13px", textAlign: "end", fontStyle: "italic", mr: "5px", mb: 0 }}>
                          Powered by
                        </Typography>
                        <Box sx={{ display: "flex", justifyContent: "flex-end", mt: "-3px" }}>
                          <img src="/cleverli-logo-black.svg" alt="logo" style={{ width: "150px" }} />
                        </Box>
                      </Box>
                    </Box>
                  )}
                </Box>

                <Divider />

                <Box sx={{ p: 2, backgroundColor: "#0b0b0b", color: "#ffffff" }}>
                  <Typography variant="h4" align="center" sx={{ textTransform: "uppercase" }}>
                    Authentication
                  </Typography>
                </Box>

                <Divider />
                <Box sx={{ p: 2, textAlign: "center" }}>
                  <Typography variant="body1" gutterBottom>
                    You have been invited to verifiy your identity by {partnerName}.
                  </Typography>
                  <Typography variant="body1">
                    {codeSent
                      ? "We have sent an authentication code to your email address"
                      : "Use the button below to request an authentication code"}
                  </Typography>
                </Box>

                {!codeSent ? (
                  <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "center" }}>
                    <LoadingButton
                      disableElevation
                      loading={processing}
                      onClick={(e) => {
                        handleSendAuthenticationCode(e);
                      }}
                      variant="contained"
                      color="primary"
                      sx={{ mx: 2, mb: 2 }}
                    >
                      Send authentication code
                    </LoadingButton>
                  </Box>
                ) : (
                  <Box>
                    <Box sx={{ px: 2, pb: 1 }}>
                      <Typography variant="body1" sx={{ fontWeight: 500 }}>
                        Please enter the code below to continue
                      </Typography>
                    </Box>

                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        gap: 1,
                        textAlign: "center",
                        px: 2,
                      }}
                    >
                      <TextField
                        value={code1}
                        id="input1"
                        onChange={(e) => setCode1(e.target.value)}
                        type="number"
                        variant="outlined"
                        autoFocus
                        size="large"
                        inputProps={{
                          maxLength: 1,
                          pattern: "[0-9]*",
                          style: { textAlign: "center", fontSize: { xs: "16px", md: "24px", lg: "30px" } },
                        }}
                        sx={{ width: { xs: "50px", md: "100px" } }}
                        onKeyUp={(e) => {
                          if (e.target.value.length === 1) {
                            document.getElementById("input2").focus();
                          }
                        }}
                      />
                      <TextField
                        id="input2"
                        value={code2}
                        onChange={(e) => setCode2(e.target.value)}
                        type="number"
                        variant="outlined"
                        size="large"
                        inputProps={{
                          maxLength: 1,
                          pattern: "[0-9]*",
                          style: { textAlign: "center", fontSize: { xs: "16px", md: "24px", lg: "30px" } },
                        }}
                        sx={{ width: { xs: "50px", md: "100px" } }}
                        onKeyUp={(e) => {
                          if (e.target.value.length === 1) {
                            document.getElementById("input3").focus();
                          } else if (e.key === "Backspace") {
                            document.getElementById("input1").focus();
                          }
                        }}
                      />
                      <TextField
                        id="input3"
                        value={code3}
                        onChange={(e) => setCode3(e.target.value)}
                        type="number"
                        variant="outlined"
                        size="large"
                        inputProps={{
                          maxLength: 1,
                          pattern: "[0-9]*",
                          style: { textAlign: "center", fontSize: { xs: "16px", md: "24px", lg: "30px" } },
                        }}
                        sx={{ width: { xs: "50px", md: "100px" } }}
                        onKeyUp={(e) => {
                          if (e.target.value.length === 1) {
                            document.getElementById("input4").focus();
                          } else if (e.key === "Backspace") {
                            document.getElementById("input2").focus();
                          }
                        }}
                      />
                      <TextField
                        id="input4"
                        value={code4}
                        onChange={(e) => setCode4(e.target.value)}
                        type="number"
                        variant="outlined"
                        size="large"
                        inputProps={{
                          maxLength: 1,
                          pattern: "[0-9]*",
                          style: { textAlign: "center", fontSize: { xs: "16px", md: "24px", lg: "30px" } },
                        }}
                        sx={{ width: { xs: "50px", md: "100px" } }}
                        onKeyUp={(e) => {
                          if (e.target.value.length === 1) {
                            document.getElementById("input5").focus();
                          } else if (e.key === "Backspace") {
                            document.getElementById("input3").focus();
                          }
                        }}
                      />
                      <TextField
                        id="input5"
                        value={code5}
                        onChange={(e) => setCode5(e.target.value)}
                        type="number"
                        variant="outlined"
                        size="large"
                        inputProps={{
                          maxLength: 1,
                          pattern: "[0-9]*",
                          style: { textAlign: "center", fontSize: { xs: "16px", md: "24px", lg: "30px" } },
                        }}
                        sx={{ width: { xs: "50px", md: "100px" } }}
                        onKeyUp={(e) => {
                          if (e.target.value.length === 1) {
                            document.getElementById("input6").focus();
                          } else if (e.key === "Backspace") {
                            document.getElementById("input4").focus();
                          }
                        }}
                      />
                      <TextField
                        id="input6"
                        value={code6}
                        onChange={(e) => setCode6(e.target.value)}
                        variant="outlined"
                        size="large"
                        inputProps={{
                          maxLength: 1,
                          pattern: "[0-9]*",
                          style: { textAlign: "center", fontSize: { xs: "16px", md: "24px", lg: "30px" } },
                        }}
                        sx={{ width: { xs: "50px", md: "100px" } }}
                        onKeyUp={(e) => {
                          if (e.target.value.length === 1) {
                            handleAuthentication(e);
                          } else if (e.key === "Backspace") {
                            document.getElementById("input5").focus();
                          }
                        }}
                      />
                    </Box>
                    <Box sx={{ p: 2, display: "flex", justifyContent: "flex-end" }}>
                      <LoadingButton
                        disableElevation
                        loading={processing}
                        onClick={(e) => handleAuthentication(e)}
                        variant="contained"
                        color="success"
                        disabled={code6 == "" ? true : false}
                      >
                        Authenticate
                      </LoadingButton>
                    </Box>
                  </Box>
                )}
              </Grid>
            </Grid>
          </Box>
        ) : (
          <Box>
            <Lottie speed={2.0} options={defaultOptions} height={200} width={200} />
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default HostedAuthentication;
