import { React, useState, useEffect } from "react";

import authHeader from "../../../../api/authHeader";

import HandleError from "../../../../components/functions/HandleError";
import HandleToast from "../../../../components/functions/HandleToast";

import Lottie from "react-lottie";
import loadingLottie from "../../../../components/lotties/loading-dots.json";

import "moment/dist/locale/en-gb";
import moment from "moment";
import DataTable from "react-data-table-component";

import { Box, Select, FormControl, InputLabel, MenuItem, Grid, Chip, Collapse } from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LoadingButton } from "@mui/lab";
import { CloudDownload } from "@mui/icons-material";
import NoResults from "../../../../components/functions/NoResults";
import EmptyReport from "../../../../components/functions/EmptyReport";

const GenerateReport = ({ partner, partnerUser, partnerData }) => {
  const useAuthHeader = authHeader();

  const [locale, setLocale] = useState("en-gb");

  if (moment.locale() != locale) {
    moment.locale(locale);
  }

  const handleError = HandleError();
  const displayToast = HandleToast();

  const [isLoaded, setIsLoaded] = useState(false);
  const [processing, setProcessing] = useState(false);

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: loadingLottie,
  };

  const reportTypeOptions = [
    { value: "1", label: "High risk clients" },
    { value: "2", label: "PEP clients" },
    { value: "3", label: "Sanctioned clients" },
    { value: "4", label: "FATCA" },
    { value: "5", label: "New clients" },
    { value: "6", label: "Recent alerts" },
    //{ value: "7", label: "Overdue tasks" },
    //{ value: "8", label: "Upcoming tasks" },
    //{ value: "9", label: "Completed tasks" },
  ];

  const fileFormatOptions = [
    { value: "pdf", label: "PDF" },
    { value: "csv", label: "CSV" },
    { value: "xlsx", label: "XLSX" },
  ];

  const customStyles = {
    headCells: {
      style: {
        paddingLeft: "8px", // override the cell padding for head cells
        paddingRight: "8px",
        fontWeight: "bold",
      },
    },
    cells: {
      style: {
        paddingLeft: "8px", // override the cell padding for data cells
        paddingRight: "8px",
      },
    },
  };

  const columns = [
    {
      name: "Client name",
      sortable: true,
      width: "30%",
      selector: (row) => row.name,
      cell: (row) => (
        <Link className="link" href={`/group/${row.group_uuid}`} underline="none" sx={{ fontSize: "13px" }}>
          {row.name}
        </Link>
      ),
    },
    {
      name: "Client type",
      sortable: true,
      selector: (row) => row.client_entity_type,
      cell: (row) => (
        <Box>
          <Typography sx={{ fontSize: "13px" }}>{row.client_entity_type == 1 ? "Individual" : "Business"}</Typography>
        </Box>
      ),
    },
    {
      name: "Risk score",
      sortable: true,
      selector: (row) => row.risk_score,
      cell: (row) => (
        <Box>
          <Typography sx={{ fontSize: "13px" }}>{row.risk_score ? row.risk_score : "-"}</Typography>
        </Box>
      ),
    },
    {
      name: "Alerts",
      sortable: false,
      cell: (row) => (
        <Box sx={{ display: "flex", gap: 1 }}>
          {!row.is_pep && !row.is_sanctioned && !row.has_warnings && !row.has_adverse_media && !row.country_risk && !row.age_risk && "-"}
          {row.is_pep && (
            <Chip className="square-chip" label="PEP" sx={{ backgroundColor: "#C83E4D", color: "#ffffff", textTransform: "uppercase" }} />
          )}
          {row.is_sanctioned && (
            <Chip className="square-chip" label="Sanctioned" sx={{ backgroundColor: "#C83E4D", color: "#ffffff", textTransform: "uppercase" }} />
          )}
          {row.has_warnings && (
            <Chip className="square-chip" label="Warnings" sx={{ backgroundColor: "#FCCA46", color: "#1C2321", textTransform: "uppercase" }} />
          )}
          {row.has_adverse_media && (
            <Chip className="square-chip" label="Adverse media" sx={{ backgroundColor: "#FCCA46", color: "#1C2321", textTransform: "uppercase" }} />
          )}
          {row.country_risk && (
            <Chip className="square-chip" label="Country risk" sx={{ backgroundColor: "#FCCA46", color: "#1C2321", textTransform: "uppercase" }} />
          )}
          {row.age_risk && (
            <Chip className="square-chip" label="Age risk" sx={{ backgroundColor: "#FCCA46", color: "#1C2321", textTransform: "uppercase" }} />
          )}
        </Box>
      ),
    },
    {
      name: "Status",
      sortable: true,
      right: "true",
      selector: (row) => row.status,
      cell: (row) => (
        <Box sx={{ display: "flex", gap: 1 }}>
          {row.status == 0 && <Chip className="square-chip" label="Onboarding" color="warning" size="small" />}
          {row.status == 1 && <Chip className="square-chip" label="Active" color="success" size="small" />}
          {row.status == 2 && <Chip className="square-chip" label="Closed" color="danger" size="small" />}
        </Box>
      ),
    },
  ];

  const [selectedReportType, setSelectedReportType] = useState("");
  const [reportStart, setReportStart] = useState("");
  const [reportEnd, setReportEnd] = useState("");
  const [reportData, setReportData] = useState([]);

  const [preview, setPreview] = useState(false);

  const [reportUUID, setReportUUID] = useState("");
  const [selectedFileFormat, setSelectedFileFormat] = useState("pdf");
  const [requiresDates, setRequiresDates] = useState(false);
  const [requiresStartDate, setRequiresStartDate] = useState(false);
  const [requiresEndDate, setRequiresEndDate] = useState(false);

  const handleGenerateReport = async (e) => {
    e.preventDefault();
    setProcessing(true);

    try {
      const response = await partner.post(
        `/v1/partner/reports/generate`,
        JSON.stringify({
          report_type: parseInt(selectedReportType),
          start_date: reportStart ? reportStart.format("YYYY-MM-DD") : "",
          end_date: reportEnd ? reportEnd.format("YYYY-MM-DD") : "",
        }),
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Basic " + useAuthHeader.base64encodedData,
            service: "partners",
          },

          //withCredentials: true,
        }
      );

      if (response.data.results > 0) {
        setReportData(response.data.entries);
      } else {
        setReportData([]);
      }
      setReportUUID(response.data.report_uuid);
      setPreview(true);
      setProcessing(false);
    } catch (err) {
      handleError(err);
      setProcessing(false);
    }
  };

  const handleDownloadReport = async (e) => {
    e.preventDefault();
    setProcessing(true);

    try {
      const response = await partner.post(
        `/v1/partner/reports/download`,
        JSON.stringify({
          report_uuid: reportUUID,
          file_format: selectedFileFormat,
        }),
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Basic " + useAuthHeader.base64encodedData,
            service: "partners",
          },

          //withCredentials: true,
        }
      );

      setProcessing(false);
    } catch (err) {
      handleError(err);
      setProcessing(false);
    }
  };

  useEffect(() => {
    setIsLoaded(true);
  }, []);

  useEffect(() => {
    switch (selectedReportType) {
      case "5":
        setRequiresDates(true);
        setRequiresStartDate(true);
        setRequiresEndDate(true);
        break;
      case "8":
        setRequiresDates(true);
        setRequiresStartDate(false);
        setRequiresEndDate(true);
        break;
      case "9":
        setRequiresDates(true);
        setRequiresStartDate(true);
        setRequiresEndDate(true);
        break;
      default:
        setRequiresDates(false);
        setRequiresStartDate(false);
        setRequiresEndDate(false);

        break;
    }
  }, [selectedReportType]);

  return (
    <>
      {isLoaded ? (
        <>
          {preview ? (
            <>
              <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
                <Box sx={{ display: "flex", flexDirection: "row", gap: 2, justifyContent: "flex-end" }}>
                  <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center", minWidth: "300px" }}>
                    <FormControl fullWidth>
                      <InputLabel id="file-format-label">File format</InputLabel>
                      <Select
                        labelId="file-format-label"
                        id="file-format"
                        value={selectedFileFormat}
                        label="File format"
                        onChange={(e) => {
                          setSelectedFileFormat(e.target.value);
                        }}
                      >
                        {fileFormatOptions.map((option, index) => (
                          <MenuItem key={index} value={option.value}>
                            {option.label}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Box>

                  <LoadingButton
                    disableElevation
                    loading={processing}
                    startIcon={<CloudDownload />}
                    variant="contained"
                    color="success"
                    onClick={(e) => handleDownloadReport(e)}
                  >
                    Download report
                  </LoadingButton>
                </Box>

                <DataTable columns={columns} data={reportData} customStyles={customStyles} noDataComponent={<EmptyReport />} pagination striped />
              </Box>
            </>
          ) : (
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={6}>
                <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
                  <Box>
                    <FormControl fullWidth>
                      <InputLabel id="report-type-label">Report type</InputLabel>
                      <Select
                        labelId="report-type-label"
                        id="report-type"
                        value={selectedReportType}
                        label="Report type"
                        onChange={(e) => {
                          setSelectedReportType(e.target.value);
                        }}
                      >
                        {reportTypeOptions.map((option, index) => (
                          <MenuItem key={index} value={option.value}>
                            {option.label}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Box>

                  <Collapse in={requiresDates} exit={!requiresDates}>
                    <Grid container spacing={2}>
                      {requiresStartDate && (
                        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                          <Box>
                            <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale={locale}>
                              <DatePicker
                                fullWidth
                                openTo="year"
                                label={`Report start date`}
                                value={reportStart ? reportStart : null}
                                onChange={(date) => {
                                  setReportStart(date);
                                }}
                                slotProps={{ textField: { fullWidth: true } }}
                                maxDate={moment()}
                              />
                            </LocalizationProvider>
                          </Box>
                        </Grid>
                      )}

                      {requiresEndDate && (
                        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                          <Box>
                            <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale={locale}>
                              <DatePicker
                                fullWidth
                                openTo="year"
                                label={`Report end date`}
                                value={reportEnd ? reportEnd : null}
                                onChange={(date) => {
                                  setReportEnd(date);
                                }}
                                slotProps={{ textField: { fullWidth: true } }}
                                maxDate={moment()}
                              />
                            </LocalizationProvider>
                          </Box>
                        </Grid>
                      )}
                    </Grid>
                  </Collapse>

                  <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                    <LoadingButton disableElevation loading={processing} variant="contained" color="success" onClick={(e) => handleGenerateReport(e)}>
                      Preview report
                    </LoadingButton>
                  </Box>
                </Box>
              </Grid>
            </Grid>
          )}
        </>
      ) : (
        <Box>
          <Lottie speed={2.0} options={defaultOptions} height={200} width={200} />
        </Box>
      )}
    </>
  );
};

export default GenerateReport;
