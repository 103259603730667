import { React, useState, useEffect } from "react";
import { useParams } from "react-router-dom";

import central from "../../api/central";
import authHeader from "../../api/authHeader";

import HandleError from "../../components/functions/HostedHandleError";

import Lottie from "react-lottie";
import loadingLottie from "../../components/lotties/loading-dots.json";

import CssBaseline from "@mui/material/CssBaseline";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Button from "@mui/lab/LoadingButton";

import successLottie from "../../components/lotties/success.json";
import errorLottie from "../../components/lotties/error.json";
import { TextField } from "@mui/material";

function Copyright(props) {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {"Copyright © Cleverli.com "}
      {new Date().getFullYear()}
    </Typography>
  );
}

const VerifyTelephone = () => {
  const { t } = useParams();
  const useAuthHeader = authHeader();

  const handleError = HandleError();

  const [isLoaded, setIsLoaded] = useState(false);
  const [processing, setProcessing] = useState(false);
  const [activeView, setActiveView] = useState(0); // 0 loading | 1 success | 2 error | 3 authentication

  const [errorOutput, setErrorOutput] = useState("test output");

  const defaultOptions = {
    loop: activeView == 0 ? true : false,
    autoplay: true,
    animationData: activeView == 0 ? loadingLottie : activeView == 1 ? successLottie : errorLottie,
  };

  const [code1, setCode1] = useState("");
  const [code2, setCode2] = useState("");
  const [code3, setCode3] = useState("");
  const [code4, setCode4] = useState("");
  const [code5, setCode5] = useState("");
  const [code6, setCode6] = useState("");

  const handleVerification = async (e) => {
    e.preventDefault();
    setProcessing(true);

    try {
      const response = await central.post(
        "/verify-telephone/process",
        JSON.stringify({
          token: t,
          code: code1 + code2 + code3 + code4 + code5 + code6,
        }),
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Basic " + useAuthHeader.base64encodedData,
            service: "partners",
          },
          mode: "no-cors",
          //withCredentials: true,
        }
      );

      setProcessing(false);
      setActiveView(1);
    } catch (err) {
      console.log(err);
      setCode1("");
      setCode2("");
      setCode3("");
      setCode4("");
      setCode5("");
      setCode6("");
      document.getElementById("input1").focus();
      handleError(err);
    }
  };

  const getVerificationData = async (e) => {
    try {
      const response = await central.post(
        "/verify-telephone/data/" + t,
        JSON.stringify({
          token: t,
        }),
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Basic " + useAuthHeader.base64encodedData,
            service: "partners",
          },
          mode: "no-cors",
          //withCredentials: true,
        }
      );

      setActiveView(0);
      setIsLoaded(true);
    } catch (err) {
      console.log(err);
      setErrorOutput(err.response.data.trim());
      setActiveView(2);
      setIsLoaded(true);
    }
  };

  useEffect(() => {
    setIsLoaded(true);
    getVerificationData();
  }, []);

  return isLoaded ? (
    <>
      <CssBaseline />
      <Grid container component="main" sx={{ height: "100vh" }}>
        <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
          <Box
            sx={{
              my: 8,
              mx: 4,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              gap: 4,
            }}
          >
            <Box component="img" sx={{ maxWidth: "300px" }} alt="Logo" src="/cleverli-logo-vertical-black.svg" />

            {activeView == 0 && (
              <Box sx={{ width: "100%" }}>
                <Typography sx={{ mb: 2, textTransform: "uppercase" }} align="center" variant="h6" gutterBottom>
                  <strong>Verifying your telephone number</strong>
                </Typography>
                <Typography sx={{ mb: 2 }} align="center" variant="body2" gutterBottom>
                  Please enter the 6-digit code we sent to your telephone number
                </Typography>

                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    gap: 1,
                    textAlign: "center",
                  }}
                >
                  <TextField
                    value={code1}
                    id="input1"
                    onChange={(e) => setCode1(e.target.value)}
                    type="number"
                    variant="outlined"
                    autoFocus
                    size="large"
                    inputProps={{ maxLength: 1, pattern: "[0-9]*", style: { textAlign: "center", fontSize: "30px" } }}
                    sx={{ width: "100px" }}
                    onKeyUp={(e) => {
                      if (e.target.value.length === 1) {
                        document.getElementById("input2").focus();
                      }
                    }}
                  />
                  <TextField
                    id="input2"
                    value={code2}
                    onChange={(e) => setCode2(e.target.value)}
                    type="number"
                    variant="outlined"
                    size="large"
                    inputProps={{ maxLength: 1, pattern: "[0-9]*", style: { textAlign: "center", fontSize: "30px" } }}
                    sx={{ width: "100px" }}
                    onKeyUp={(e) => {
                      if (e.target.value.length === 1) {
                        document.getElementById("input3").focus();
                      } else if (e.key === "Backspace") {
                        document.getElementById("input1").focus();
                      }
                    }}
                  />
                  <TextField
                    id="input3"
                    value={code3}
                    onChange={(e) => setCode3(e.target.value)}
                    type="number"
                    variant="outlined"
                    size="large"
                    inputProps={{ maxLength: 1, pattern: "[0-9]*", style: { textAlign: "center", fontSize: "30px" } }}
                    sx={{ width: "100px" }}
                    onKeyUp={(e) => {
                      if (e.target.value.length === 1) {
                        document.getElementById("input4").focus();
                      } else if (e.key === "Backspace") {
                        document.getElementById("input2").focus();
                      }
                    }}
                  />
                  <TextField
                    id="input4"
                    value={code4}
                    onChange={(e) => setCode4(e.target.value)}
                    type="number"
                    variant="outlined"
                    size="large"
                    inputProps={{ maxLength: 1, pattern: "[0-9]*", style: { textAlign: "center", fontSize: "30px" } }}
                    sx={{ width: "100px" }}
                    onKeyUp={(e) => {
                      if (e.target.value.length === 1) {
                        document.getElementById("input5").focus();
                      } else if (e.key === "Backspace") {
                        document.getElementById("input3").focus();
                      }
                    }}
                  />
                  <TextField
                    id="input5"
                    value={code5}
                    onChange={(e) => setCode5(e.target.value)}
                    type="number"
                    variant="outlined"
                    size="large"
                    inputProps={{ maxLength: 1, pattern: "[0-9]*", style: { textAlign: "center", fontSize: "30px" } }}
                    sx={{ width: "100px" }}
                    onKeyUp={(e) => {
                      if (e.target.value.length === 1) {
                        document.getElementById("input6").focus();
                      } else if (e.key === "Backspace") {
                        document.getElementById("input4").focus();
                      }
                    }}
                  />
                  <TextField
                    id="input6"
                    value={code6}
                    onChange={(e) => setCode6(e.target.value)}
                    variant="outlined"
                    size="large"
                    inputProps={{ maxLength: 1, pattern: "[0-9]*", style: { textAlign: "center", fontSize: "30px" } }}
                    sx={{ width: "100px" }}
                    onKeyUp={(e) => {
                      if (e.target.value.length === 1) {
                        handleVerification(e);
                      } else if (e.key === "Backspace") {
                        document.getElementById("input5").focus();
                      }
                    }}
                  />
                </Box>
              </Box>
            )}

            {activeView == 1 && (
              <Box sx={{ width: "100%" }}>
                <Box
                  variant="div"
                  align="center"
                  alignContent={"center"}
                  sx={{ mt: 1, marginLeft: "auto", marginRight: "auto", width: "fit-content" }}
                >
                  <Lottie options={defaultOptions} height={200} width={250} isStopped={false} isPaused={false} />
                </Box>
                <Typography sx={{ mt: 3, mb: 2, textTransform: "uppercase" }} variant="h6" align="center" gutterBottom>
                  <strong>Verification successful</strong>
                </Typography>
                <Box sx={{ width: "fit-content", mt: 3, mb: 2, marginLeft: "auto", marginRight: "auto" }}>
                  <Button
                    onClick={() => {
                      setProcessing(true);
                      window.opener = null;
                      window.open("", "_self");
                      window.close();
                    }}
                    loading={processing}
                    type="button"
                    variant="contained"
                    sx={{ mt: 3, mb: 2 }}
                  >
                    Close window
                  </Button>
                </Box>
              </Box>
            )}

            {activeView == 2 && (
              <Box sx={{ width: "100%" }}>
                <Box
                  variant="div"
                  align="center"
                  alignContent={"center"}
                  sx={{ mt: 1, marginLeft: "auto", marginRight: "auto", width: "fit-content" }}
                >
                  <Lottie options={defaultOptions} height={200} width={250} isStopped={false} isPaused={false} />
                </Box>
                <Typography sx={{ textTransform: "uppercase" }} variant="h6" align="center" gutterBottom>
                  <strong>Error</strong>
                </Typography>

                <Typography sx={{ textTransform: "uppercase" }} color="danger.main" variant="h6" align="center" gutterBottom>
                  <strong>{errorOutput}</strong>
                </Typography>
                <Box sx={{ width: "fit-content", mt: 3, mb: 2, marginLeft: "auto", marginRight: "auto" }}>
                  <Button
                    onClick={() => {
                      setProcessing(true);
                      window.opener = null;
                      window.open("", "_self");
                      window.close();
                    }}
                    loading={processing}
                    type="button"
                    variant="contained"
                    sx={{ mt: 3, mb: 2 }}
                  >
                    Close window
                  </Button>
                </Box>
              </Box>
            )}

            <Box component="div" sx={{ mt: 1 }}>
              <Copyright sx={{ mt: 5 }} />
            </Box>
          </Box>
        </Grid>

        <Grid
          item
          xs={false}
          sm={4}
          md={7}
          sx={{
            backgroundColor: (t) => (t.palette.mode === "light" ? t.palette.primary.main : t.palette.primary.main),
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        />
      </Grid>
    </>
  ) : (
    <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", height: "50vh" }}>
      <Lottie speed={2.0} options={defaultOptions} height={200} width={200} />
    </Box>
  );
};

export default VerifyTelephone;
