import { Button, Divider, Drawer, IconButton, Stack, Typography } from "@mui/material";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import { useState } from "react";
import { Modal } from "../../../../../components/modal";
import PropTypes from "prop-types";
import { getTaskFile, postDeleteTaskFile } from "../../../../../api/services";
import { useGetTaskFiles } from "../../../../../hooks/use-get-task-files";
import { Delete } from "@mui/icons-material";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import { UploadTaskFileButton } from "./upload-task-file-button";
import { RenderWhen } from "../../../../../components/render-when";

import Lottie from "react-lottie";
import noFileUploadedLottie from "../../../../../components/lotties/upload-file-placeholder.json";
import HandleToast from "../../../../../components/functions/HandleToast";
import { LoadingButton } from "@mui/lab";
import { FileViewer } from "./file-viewer";

export function TaskFiles({ taskUUID, clientUUID, updateTaskData }) {
  const displayToast = HandleToast();
  const [deleteModalOpen, setDeleteModalOpen] = useState({
    open: false,
    fileUUID: null,
  });
  const [viewFileDrawer, setViewFileDrawer] = useState({
    open: false,
    fileLik: null,
    fileType: null,
  });
  const [loading, setLoading] = useState(false);
  const { taskFilesList, getTaskFilesQuery } = useGetTaskFiles(taskUUID);

  const handleDeleteFile = async () => {
    if (!deleteModalOpen?.fileUUID || !taskUUID) {
      return;
    }
    try {
      setLoading(true);
      await postDeleteTaskFile({ taskUUID, fileUUID: deleteModalOpen?.fileUUID });
      await updateTaskData();
      await getTaskFilesQuery();
      setDeleteModalOpen({
        open: false,
        fileUUID: null,
      });
      displayToast("File deleted successfully");
    } catch (error) {
      console.error(error);
      displayToast("Error deleting file", undefined, "error");
    } finally {
      setLoading(false);
    }
  };

  const handleOpenFileDrawer = async (fileUUID) => {
    if (!fileUUID) {
      return;
    }
    try {
      const res = await getTaskFile({ fileUUID });
      setViewFileDrawer({
        open: true,
        fileLik: res?.url,
        fileType: res?.filetype,
      });
    } catch (error) {
      console.error(error);
    }
  };

  const handleCloseFileDrawer = () => {
    setViewFileDrawer({
      open: false,
      fileLik: null,
      fileType: null,
    });
  };

  return (
    <>
      <Stack
        direction={"column"}
        sx={{ p: 2, border: 1, borderColor: "divider", borderRadius: 1 }}
        spacing={[0, 1]}
      >
        <Stack direction={"row"} justifyContent="space-between" alignItems="center">
          <Typography variant="body1" fontWeight={600}>
            Files
          </Typography>
          <UploadTaskFileButton
            taskUUID={taskUUID}
            clientUUID={clientUUID}
            updateTaskData={updateTaskData}
            getTaskFilesQuery={getTaskFilesQuery}
          />
        </Stack>
        <Divider />
        <RenderWhen when={Boolean(taskFilesList?.length === 0)}>
          <Stack>
            <Typography variant="body2">No files uploaded</Typography>
            <Lottie
              options={{ loop: true, autoplay: true, animationData: noFileUploadedLottie }}
              height={150}
              width={150}
            />
          </Stack>
        </RenderWhen>
        <RenderWhen when={Boolean(taskFilesList?.length !== 0)}>
          {taskFilesList?.map((file) => {
            return (
              <Stack
                key={file?.file_uuid}
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                sx={{
                  borderBottom: 1,
                  borderBottomColor: "divider",
                  borderBottomStyle: "solid",
                  padding: 1,
                  ":last-of-type": {
                    borderBottom: 0,
                  },
                }}
              >
                <Stack direction="row" spacing={2} alignItems="center">
                  <AttachFileIcon />
                  <Typography variant="caption" textOverflow={"ellipsis"} maxWidth={"20px"}>
                    {file?.filename}
                  </Typography>
                </Stack>
                <Stack direction="row" spacing={1}>
                  <IconButton
                    onClick={() => handleOpenFileDrawer(file?.file_uuid)}
                    variant="outlined"
                  >
                    <RemoveRedEyeIcon />
                  </IconButton>
                  <IconButton
                    variant="outlined"
                    color="danger"
                    onClick={() =>
                      setDeleteModalOpen({
                        open: true,
                        fileUUID: file?.file_uuid,
                      })
                    }
                  >
                    <Delete />
                  </IconButton>
                </Stack>
              </Stack>
            );
          })}
        </RenderWhen>
      </Stack>

      <Modal
        open={deleteModalOpen?.open}
        onClose={() =>
          setDeleteModalOpen({
            open: false,
            fileUUID: null,
          })
        }
      >
        <Stack direction="column" spacing={[0, 2]} sx={{ p: 2 }}>
          <Typography variant="h6">Are you sure you want to delete this file?</Typography>
          <Stack direction="row" spacing={2} justifyContent="end">
            <LoadingButton
              onClick={handleDeleteFile}
              loading={loading}
              variant="outlined"
              color="danger"
            >
              Delete
            </LoadingButton>
            <Button
              variant="outlined"
              onClick={() =>
                setDeleteModalOpen({
                  open: false,
                  fileUUID: null,
                })
              }
            >
              Cancel
            </Button>
          </Stack>
        </Stack>
      </Modal>
      <Drawer open={viewFileDrawer?.open} onClose={handleCloseFileDrawer} anchor="right">
        <FileViewer fileUrl={viewFileDrawer?.fileLik} />
      </Drawer>
    </>
  );
}

TaskFiles.propTypes = {
  taskUUID: PropTypes.string,
  clientUUID: PropTypes.string,
  updateTaskData: PropTypes.func,
};
