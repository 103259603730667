import { React, useEffect, useState } from "react";

import authHeader from "../../../../../api/authHeader";

import HandleError from "../../../../../components/functions/HandleError";

import Lottie from "react-lottie";
import loadingLottie from "../../../../../components/lotties/loading-dots.json";

import DataTable from "react-data-table-component";

import { Typography, Divider, Box, Button, Chip } from "@mui/material";

import NewSegment from "./components/segments/NewSegment";
import ManageSegment from "./components/segments/ManageSegment";
import NoResults from "../../../../../components/functions/NoResults";

const Segments = ({ partner, partnerUser, partnerData }) => {
  const useAuthHeader = authHeader();

  const handleError = HandleError();

  const [isLoaded, setIsLoaded] = useState(false);

  const [data, setData] = useState([]);

  const [newSegment, setNewSegment] = useState(false);
  const [manageSegment, setManageSegment] = useState(false);
  const [activeSegment, setActiveSegment] = useState("");

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: loadingLottie,
  };

  const customStyles = {
    headCells: {
      style: {
        paddingLeft: "8px", // override the cell padding for head cells
        paddingRight: "8px",
        fontWeight: "bold",
      },
    },
    cells: {
      style: {
        paddingLeft: "8px", // override the cell padding for data cells
        paddingRight: "8px",
      },
    },
  };

  const columns = [
    {
      name: "Segment name",
      sortable: true,
      width: "75%",
      selector: (row) => row.label,
      cell: (row) => (
        <Box
          onClick={() => {
            setActiveSegment(row.uuid);
            setManageSegment(true);
          }}
        >
          <Chip
            className="square-chip"
            label={row.label}
            sx={{ textTransform: "uppercase", backgroundColor: row.color, color: row.text_color, cursor: "pointer", minWidth: "180px" }}
          />
        </Box>
      ),
    },
    {
      name: "# Members",
      sortable: true,
      right: "true",
      selector: (row) => row.members,
    },
  ];

  const getSegments = async () => {
    try {
      const response = await partner.post(`/v1/client/segments/data/list`, JSON.stringify({}), {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Basic " + useAuthHeader.base64encodedData,
          service: "partners",
        },

        //withCredentials: true,
      });

      if (response.data.results > 0) {
        setData(response.data.entries);
      } else {
        setData([]);
      }
      setIsLoaded(true);
    } catch (err) {
      handleError(err);
    }
  };

  useEffect(() => {
    getSegments();
  }, []);

  return (
    <>
      {isLoaded ? (
        <>
          <Box sx={{ borderTop: 1, borderColor: "divider" }}>
            <Box sx={{ p: 2, display: "flex", justifyContent: "space-between" }}>
              <Typography variant="h6">{newSegment ? "Create a new segment" : manageSegment ? "Manage segment" : "Client segments"}</Typography>
              <Box sx={{ display: "flex", gap: 1 }}>
                <Button
                  onClick={() => {
                    manageSegment ? setManageSegment(false) : newSegment ? setNewSegment(false) : setNewSegment(true);
                  }}
                  variant="contained"
                  color="primary"
                >
                  {newSegment ? "Close" : manageSegment ? "Close" : "Create segment"}
                </Button>
              </Box>
            </Box>
            <Divider />
            {newSegment ? (
              <Box>
                <NewSegment
                  partner={partner}
                  partnerUser={partnerUser}
                  partnerData={partnerData}
                  setNewSegment={setNewSegment}
                  getSegments={getSegments}
                />
              </Box>
            ) : manageSegment ? (
              <Box>
                <ManageSegment
                  partner={partner}
                  partnerUser={partnerUser}
                  partnerData={partnerData}
                  activeSegment={activeSegment}
                  setActiveSegment={setActiveSegment}
                  getSegments={getSegments}
                  setManageSegment={setManageSegment}
                />
              </Box>
            ) : (
              <DataTable
                columns={columns}
                data={data}
                customStyles={customStyles}
                noDataComponent={<NoResults message={`Create your first client segment`} />}
                pagination
                striped
              />
            )}
          </Box>
        </>
      ) : (
        <Box>
          <Lottie speed={2.0} options={defaultOptions} height={200} width={200} />
        </Box>
      )}
    </>
  );
};

export default Segments;
