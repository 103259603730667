import { React, useEffect, useState } from "react";

import authHeader from "../../api/authHeader";

import HandleError from "../../components/functions/HandleError";
import NoResults from "../../components/functions/NoResults";

import Lottie from "react-lottie";
import loadingLottie from "../../components/lotties/loading-dots.json";

import DataTable from "react-data-table-component";
import moment from "moment";

import { Typography, Divider, Box, Chip, Link, FormControl, FormGroup, FormControlLabel, Switch } from "@mui/material";

const Screening = ({ partner, partnerUser, partnerData }) => {
  const useAuthHeader = authHeader();

  const handleError = HandleError();

  const [isLoaded, setIsLoaded] = useState(false);
  const [isFiltered, setIsFiltered] = useState(false);
  const [data, setData] = useState([]);

  const [showComplete, setShowComplete] = useState(true);

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: loadingLottie,
  };

  const customStyles = {
    headCells: {
      style: {
        paddingLeft: "8px", // override the cell padding for head cells
        paddingRight: "8px",
        fontWeight: "bold",
      },
    },
    cells: {
      style: {
        paddingLeft: "8px", // override the cell padding for data cells
        paddingRight: "8px",
      },
    },
  };

  const columns = [
    {
      name: "Entity name",
      sortable: true,
      width: "30%",
      selector: (row) => row.display_name,
      cell: (row) => (
        <Link
          className="link"
          href={`${row.case_type == 1 ? `/case/kyc/${row.case_uuid}` : `/case/kyb/${row.case_uuid}`}`}
          underline="none"
          sx={{ fontSize: "13px" }}
        >
          {row.display_name}
        </Link>
      ),
    },
    {
      name: "Case type",
      sortable: true,
      selector: (row) => row.case_type,
      cell: (row) => (
        <Box>
          {row.case_type == 1 ? (
            <Chip className="square-chip" label="KYC" sx={{ backgroundColor: "#55828b", color: "#ffffff", textTransform: "uppercase" }} />
          ) : (
            <Chip className="square-chip" label="KYB" sx={{ backgroundColor: "#474044", color: "#ffffff", textTransform: "uppercase" }} />
          )}
        </Box>
      ),
    },
    {
      name: "Last updated",
      sortable: true,
      selector: (row) => row.updated,
      cell: (row) => moment.unix(row.updated).fromNow(),
    },
    {
      name: "Flags",
      sortable: false,
      cell: (row) => (
        <Box sx={{ display: "flex", flexDirection: "column", gap: 1, py: 1 }}>
          {!row.flags.pep && !row.flags.sanctions && !row.flags.country && !row.flags.age && !row.flags.industry && "-"}
          {row.flags.pep && (
            <Chip className="square-chip" label="PEP" sx={{ backgroundColor: "#C83E4D", color: "#ffffff", textTransform: "uppercase" }} />
          )}
          {row.flags.sanctions && (
            <Chip className="square-chip" label="Sanctioned" sx={{ backgroundColor: "#C83E4D", color: "#ffffff", textTransform: "uppercase" }} />
          )}
          {row.flags.country && (
            <Chip className="square-chip" label="Country risk" sx={{ backgroundColor: "#FCCA46", color: "#1C2321", textTransform: "uppercase" }} />
          )}
          {row.flags.age && (
            <Chip className="square-chip" label="Age risk" sx={{ backgroundColor: "#FCCA46", color: "#1C2321", textTransform: "uppercase" }} />
          )}
          {row.flags.industry && (
            <Chip
              className="square-chip"
              label="High risk industry"
              sx={{ backgroundColor: "#FCCA46", color: "#1C2321", textTransform: "uppercase" }}
            />
          )}
        </Box>
      ),
    },
    {
      name: "Status",
      sortable: true,
      right: "true",
      selector: (row) =>
        row.status == 0 ? 3 : row.status == 1 ? 4 : row.status == 2 ? 6 : row.status == 3 ? 7 : row.status == 4 ? 1 : row.status == 5 ? 5 : 2,
      cell: (row) => (
        <Box>
          {row.status == 0 && (
            <Chip className="square-chip" label="Input required" sx={{ backgroundColor: "#1C2321", color: "#ffffff", textTransform: "uppercase" }} />
          )}
          {row.status == 1 && (
            <Chip className="square-chip" label="Review" sx={{ backgroundColor: "#D5E950", color: "#1C2321", textTransform: "uppercase" }} />
          )}
          {row.status == 2 && (
            <Chip className="square-chip" label="Complete" sx={{ backgroundColor: "#8BBF9F", color: "#1C2321", textTransform: "uppercase" }} />
          )}
          {row.status == 3 && (
            <Chip className="square-chip" label="Declined" sx={{ backgroundColor: "#C83E4D", color: "#ffffff", textTransform: "uppercase" }} />
          )}
          {row.status == 4 && (
            <Chip
              className="square-chip"
              label="Assessment required"
              sx={{ backgroundColor: "#D5E950", color: "#1C2321", textTransform: "uppercase" }}
            />
          )}
          {row.status == 5 && (
            <Chip className="square-chip" label="Waiting" sx={{ backgroundColor: "#2274A5", color: "#ffffff", textTransform: "uppercase" }} />
          )}
          {row.status == 6 && (
            <Chip
              className="square-chip"
              label="Decision required"
              sx={{ backgroundColor: "#D5E950", color: "#1C2321", textTransform: "uppercase" }}
            />
          )}
        </Box>
      ),
    },
  ];

  const getCases = async () => {
    try {
      const response = await partner.post(
        `/v1/screening/cases`,
        JSON.stringify({
          partner_uuid: partnerUser.value.partner_uuid,
          show_complete: showComplete,
        }),
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Basic " + useAuthHeader.base64encodedData,
            service: "partners",
          },

          //withCredentials: true,
        }
      );

      if (response.data.results > 0) {
        setData(response.data.entries);
      } else {
        setData([]);
      }
      setIsLoaded(true);
    } catch (err) {
      handleError(err);
    }
  };

  useEffect(() => {
    getCases();
  }, [showComplete]);

  return (
    <>
      {isLoaded ? (
        <>
          <Box sx={{ border: 1, borderColor: "divider" }}>
            <Box sx={{ p: 2, display: "flex", justifyContent: "space-between" }}>
              <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
                <Typography variant="h6">Screening cases</Typography>
              </Box>
              <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
                <FormGroup>
                  <FormControlLabel
                    control={<Switch checked={showComplete} onChange={() => setShowComplete(!showComplete)} />}
                    label={showComplete ? "Hide complete" : "Show complete"}
                  />
                </FormGroup>
              </Box>
            </Box>
            <Divider />
            <DataTable
              columns={columns}
              data={data}
              customStyles={customStyles}
              pagination
              striped
              noDataComponent={<NoResults message={`Create your first client to get started with screening`} />}
            />
          </Box>
        </>
      ) : (
        <Box>
          <Lottie speed={2.0} options={defaultOptions} height={200} width={200} />
        </Box>
      )}
    </>
  );
};

export default Screening;
