import { React, useEffect, useState } from "react";

import authHeader from "../../../../../../../api/authHeader";

import HandleError from "../../../../../../../components/functions/HandleError";
import HandleToast from "../../../../../../../components/functions/HandleToast";

import { SketchPicker } from "react-color";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

import Lottie from "react-lottie";
import loadingLottie from "../../../../../../../components/lotties/loading-dots.json";

import { Box, Divider, Grid, TextField, Typography, ToggleButtonGroup, ToggleButton, Chip } from "@mui/material";
import { LoadingButton } from "@mui/lab";

const ManageSegment = ({ partner, partnerUser, partnerData, activeSegment, setManageSegment, getSegments, setActiveSegment }) => {
  const useAuthHeader = authHeader();

  const handleError = HandleError();
  const displayToast = HandleToast();

  const [processing, setProcessing] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);

  const [segmentName, setSegmentName] = useState("");
  const [segmentDescription, setSegmentDescription] = useState("");
  const [colorType, setColorType] = useState(1); // [1: Background, 2: Text
  const [backgroundColor, setBackgroundColor] = useState({ background: "#000000" });
  const [textColor, setTextColor] = useState({ background: "#ffffff" });

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: loadingLottie,
  };

  const getSegmentData = async (segmentUUID) => {
    try {
      const response = await partner.post(
        `/v1/client/segments/data`,
        JSON.stringify({
          segment_uuid: segmentUUID,
        }),
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Basic " + useAuthHeader.base64encodedData,
            service: "partners",
          },

          //withCredentials: true,
        }
      );

      setSegmentName(response.data.label);
      setSegmentDescription(response.data.description);
      setBackgroundColor({ background: response.data.color });
      setTextColor({ background: response.data.text_color });
      setIsLoaded(true);
    } catch (err) {
      console.log(err);
      handleError(err);
    }
  };

  const handleUpdateSegment = async (e) => {
    e.preventDefault();
    setProcessing(true);

    try {
      const response = await partner.post(
        `/v1/client/segments/update`,
        JSON.stringify({
          segment_uuid: activeSegment,
          label: segmentName,
          color: backgroundColor.background ? backgroundColor.background : "",
          text_color: textColor.background ? textColor.background : "",
          description: segmentDescription,
        }),
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Basic " + useAuthHeader.base64encodedData,
            service: "partners",
          },

          //withCredentials: true,
        }
      );

      getSegments();

      displayToast("Segment updated successfully", () => {
        setSegmentName("");
        setSegmentDescription("");
        setBackgroundColor({ background: "#000000" });
        setTextColor({ background: "#ffffff" });
        setProcessing(false);
        setManageSegment(false);
      });
    } catch (err) {
      handleError(err);
      setProcessing(false);
    }
  };

  useEffect(() => {
    getSegmentData(activeSegment);
  }, []);

  return (
    <Box>
      {isLoaded ? (
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={12} lg={6} xl={4}>
            <Box sx={{ p: 2 }}>
              <Box sx={{ p: 2, border: 1, borderColor: "divider" }}>
                <Typography variant="subtitle2">What is the name of the segment?</Typography>
              </Box>

              <Box sx={{ p: 2, display: "flex", flexDirection: "column", gap: 2, border: 1, borderTop: 0, borderColor: "divider" }}>
                <TextField
                  fullWidth
                  id="outlined-basic"
                  label="Segment name"
                  variant="outlined"
                  value={segmentName}
                  onChange={(e) => setSegmentName(e.target.value)}
                />

                <Box sx={{ display: "flex", justifyContent: "space-between", border: 1, borderColor: "divider" }}>
                  <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center", p: 2 }}>
                    <Typography variant="subtitle2">Color options</Typography>
                  </Box>
                  <Box sx={{ p: 2 }}>
                    <ToggleButtonGroup
                      color="primary"
                      value={colorType}
                      exclusive
                      onChange={(e, newValue) => {
                        if (newValue !== null) setColorType(newValue);
                      }}
                      aria-label="Color options"
                    >
                      <ToggleButton value={1}>Background</ToggleButton>
                      <ToggleButton value={2}>Text</ToggleButton>
                    </ToggleButtonGroup>
                  </Box>
                </Box>

                <Box sx={{ display: "flex", gap: 2 }}>
                  <SketchPicker
                    color={colorType == 1 ? backgroundColor?.background : textColor?.background}
                    onChangeComplete={(color) => {
                      colorType == 1 ? setBackgroundColor({ background: color.hex }) : setTextColor({ background: color.hex });
                    }}
                  />
                </Box>

                <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                  <Box sx={{ display: "flex", justifyItems: "center" }}>
                    <Chip
                      className="square-chip"
                      label={`${segmentName ? segmentName : "example"}`}
                      sx={{
                        textTransform: "uppercase",
                        backgroundColor: backgroundColor?.background ? backgroundColor.background : "#000000",
                        color: textColor?.background ? textColor.background : "#ffffff",
                      }}
                    />
                  </Box>
                  <LoadingButton
                    disableElevation
                    variant="contained"
                    color="success"
                    loading={processing}
                    onClick={(e) => {
                      handleUpdateSegment(e);
                    }}
                  >
                    Save changes
                  </LoadingButton>
                </Box>
              </Box>
            </Box>
          </Grid>
        </Grid>
      ) : (
        <Box>
          <Lottie speed={2.0} options={defaultOptions} height={200} width={200} />
        </Box>
      )}
    </Box>
  );
};

export default ManageSegment;
