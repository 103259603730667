import { React, useEffect, useState } from "react";

import authHeader from "../../../../../../../api/authHeader";

import HandleError from "../../../../../../../components/functions/HandleError";
import HandleToast from "../../../../../../../components/functions/HandleToast";

import Lottie from "react-lottie";
import loadingLottie from "../../../../../../../components/lotties/loading-dots.json";

import DataTable from "react-data-table-component";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { ReactSortable } from "react-sortablejs";

import {
  Box,
  Button,
  Divider,
  Grid,
  Select,
  TextField,
  Typography,
  FormControl,
  InputLabel,
  MenuItem,
  ToggleButtonGroup,
  ToggleButton,
  Collapse,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Chip,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { Check, Delete } from "@mui/icons-material";

import NoResults from "../../../../../../../components/functions/NoResults";

const ManageTemplate = ({ partner, partnerUser, partnerData, activeTemplate, setManageTemplate, getTemplates, setPreviewForm }) => {
  const useAuthHeader = authHeader();

  const handleError = HandleError();
  const displayToast = HandleToast();

  const [isLoaded, setIsLoaded] = useState(false);
  const [processing, setProcessing] = useState(false);

  const [manageFields, setManageFields] = useState(false);

  const [templateName, setTemplateName] = useState("");
  const [templateActive, setTemplateActive] = useState(false);
  const [templateFields, setTemplateFields] = useState([]);
  const [isMandatory, setIsMandatory] = useState(false);
  const [enableHelper, setEnableHelper] = useState(false);
  const [enableOther, setEnableOther] = useState(false);
  const [helperText, setHelperText] = useState("");

  const [selectedFieldType, setSelectedFieldType] = useState({ label: "Select field type", value: "" });
  const [activeField, setActiveField] = useState({});
  const [selectFieldOptions, setSelectFieldOptions] = useState([]);
  const [manageFieldOption, setManageFieldOption] = useState(false);

  const [fieldStatus, setFieldStatus] = useState(false);
  const [selectOptionLabel, setSelectOptionLabel] = useState("");
  const [selectOptionUUID, setSelectOptionUUID] = useState("");
  const [fieldLabel, setFieldLabel] = useState("");

  const [templateDescription, setTemplateDescription] = useState("");

  const [deleteOptionDialog, setDeleteOptionDialog] = useState(false);
  const [deleteFieldDialog, setDeleteFieldDialog] = useState(false);
  const [deleteTemplateDialog, setDeleteTemplateDialog] = useState(false);

  const [manageOrder, setManageOrder] = useState(false);
  const [list, setList] = useState([]);

  const fieldOptions = [
    { label: "Textarea (multiple lines)", value: 1 },
    { label: "Single line text", value: 2 },
    { label: "Select option (single)", value: 3 },
    { label: "Select options (multiple)", value: 4 },
    { label: "Date", value: 5 },
    { label: "Email address", value: 6 },
    { label: "Telephone number", value: 7 },
    { label: "Address", value: 8 },
    { label: "Single country", value: 9 },
    { label: "Multiple countries", value: 10 },
    { label: "True/False", value: 11 },
    { label: "Yes/No", value: 12 },
    { label: "Single currency", value: 13 },
    { label: "Multiple currencies", value: 14 },
    { label: "Number (money)", value: 15 },
    { label: "Number (integer)", value: 16 },
    { label: "Number (decimal)", value: 17 },
    { label: "Number (percentage)", value: 18 },
    { label: "File upload (single)", value: 19 },
    { label: "File uploads (multiple)", value: 20 },
    { label: "Corporate entity", value: 21 },
    { label: "Individual person", value: 22 },
    { label: "Corporate entities (multiple)", value: 23 },
    { label: "Individual people (multiple)", value: 24 },
  ];

  function fieldTypeLabel(value) {
    switch (value) {
      case 1:
        return "Textarea (multiple lines)";
      case 2:
        return "Single line text";
      case 3:
        return "Select option (single)";
      case 4:
        return "Select options (multiple)";
      case 5:
        return "Date";
      case 6:
        return "Email address";
      case 7:
        return "Telephone number";
      case 8:
        return "Address";
      case 9:
        return "Single country";
      case 10:
        return "Multiple countries";
      case 11:
        return "True/False";
      case 12:
        return "Yes/No";
      case 13:
        return "Single currency";
      case 14:
        return "Multiple currencies";
      case 15:
        return "Number (money)";
      case 16:
        return "Number (integer)";
      case 17:
        return "Number (decimal)";
      case 18:
        return "Number (percentage)";
      case 19:
        return "File upload (single)";
      case 20:
        return "File uploads (multiple)";
      case 21:
        return "Corporate entity";
      case 22:
        return "Individual person";
      case 23:
        return "Corporate entities (multiple)";
      case 24:
        return "Individual people (multiple)";
      default:
        return "Unknown";
    }
  }

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: loadingLottie,
  };

  const customStyles = {
    headCells: {
      style: {
        paddingLeft: "8px", // override the cell padding for head cells
        paddingRight: "8px",
        fontWeight: "bold",
      },
    },
    cells: {
      style: {
        paddingLeft: "8px", // override the cell padding for data cells
        paddingRight: "8px",
      },
    },
  };

  const fieldColumns = [
    {
      name: "Label",
      sortable: true,
      width: "50%",
      selector: (row) => row.label,
      cell: (row) => (
        <>
          <Box
            className="link"
            onClick={() => {
              getFieldData(row.uuid, true);
            }}
          >
            {row.label}
          </Box>
        </>
      ),
    },
    {
      name: "Field type",
      sortable: true,
      selector: (row) => row.field_type,
      cell: (row) => fieldTypeLabel(row.field_type),
    },
    {
      name: "Mandatory",
      right: "true",
      selector: (row) => row.mandatory,
      cell: (row) => (row.mandatory ? <Check color="success" /> : "-"),
    },
    {
      name: "Status",
      right: "true",
      selector: (row) => row.active,
      cell: (row) =>
        row.active ? (
          <Chip className="square-chip" label="Active" color="success" sx={{ textTransform: "uppercase" }} />
        ) : (
          <Chip className="square-chip" label="Inactive" color="error" sx={{ textTransform: "uppercase" }} />
        ),
    },
  ];

  const selectFieldColumns = [
    {
      name: "Label",
      sortable: true,
      selector: (row) => row.label,
      cell: (row) => (
        <Box
          className="link"
          onClick={() => {
            getSelectOptionData(row.option_uuid);
          }}
        >
          {row.label}
        </Box>
      ),
    },
  ];

  const getTemplateData = async () => {
    try {
      const response = await partner.post(
        `/v1/screening/templates/enhanced/data`,
        JSON.stringify({
          template_uuid: activeTemplate,
        }),
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Basic " + useAuthHeader.base64encodedData,
            service: "partners",
          },

          //withCredentials: true,
        }
      );

      setTemplateFields(response.data.fields ? response.data.fields : []);

      setList(response.data.fields ? response.data.fields : []);
      setTemplateName(response.data.template_data?.name);
      setTemplateActive(response.data.template_data?.active);
      setTemplateDescription(response.data.template_data?.description);
      setIsLoaded(true);
    } catch (err) {
      handleError(err);
    }
  };

  const handleUpdateTemplate = async (e) => {
    e.preventDefault();
    setProcessing(true);
    try {
      const response = await partner.post(
        `/v1/screening/templates/enhanced/update`,
        JSON.stringify({
          partner_uuid: partnerUser.value.partner_uuid,
          template_uuid: activeTemplate,
          name: templateName,
          active: templateActive,
          description: templateDescription,
        }),
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Basic " + useAuthHeader.base64encodedData,
            service: "partners",
          },

          //withCredentials: true,
        }
      );

      getTemplates();

      displayToast("Template updated successfully", () => {
        setProcessing(false);
        setManageTemplate(false);
      });
    } catch (err) {
      handleError(err);
      setProcessing(false);
    }
  };

  const handleDeleteTemplate = async (e) => {
    e.preventDefault();
    setProcessing(true);
    try {
      const response = await partner.post(
        `/v1/screening/templates/enhanced/delete`,
        JSON.stringify({
          template_uuid: activeTemplate,
        }),
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Basic " + useAuthHeader.base64encodedData,
            service: "partners",
          },

          //withCredentials: true,
        }
      );

      getTemplates();
      setDeleteTemplateDialog(false);

      displayToast("Template deleted successfully", () => {
        setProcessing(false);
        setManageTemplate(false);
      });
    } catch (err) {
      handleError(err);
      setProcessing(false);
    }
  };

  const getFieldData = async (uuid) => {
    try {
      const response = await partner.post(
        `/v1/screening/templates/enhanced/fields/data`,
        JSON.stringify({
          field_uuid: uuid,
        }),
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Basic " + useAuthHeader.base64encodedData,
            service: "partners",
          },

          //withCredentials: true,
        }
      );

      setActiveField(response.data);
      setFieldLabel(response.data.label);
      setSelectedFieldType(response.data.field_type);
      setIsMandatory(response.data.mandatory);
      setEnableHelper(response.data.helper_text ? true : false);
      setEnableOther(response.data.enabled_other);
      setHelperText(response.data.helper_text);
      setFieldStatus(response.data.active);
      setManageFields(true);

      if (response.data.field_type > 2 && response.data.field_type < 5) {
        if (response.data.select_options != null) {
          setSelectFieldOptions(response.data.select_options);
        }
      }
    } catch (err) {
      console.log(err);
      handleError(err);
    }
  };

  const handleCreateField = async (e) => {
    e.preventDefault();
    setProcessing(true);
    try {
      const response = await partner.post(
        `/v1/screening/templates/enhanced/fields/add`,
        JSON.stringify({
          template_uuid: activeTemplate,
          field_type: selectedFieldType,
          label: fieldLabel,
          mandatory: isMandatory,
          enable_other: enableOther,
          helper_text: helperText,
        }),
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Basic " + useAuthHeader.base64encodedData,
            service: "partners",
          },

          //withCredentials: true,
        }
      );

      getTemplateData(activeTemplate);

      displayToast("Field added successfully", () => {
        setFieldLabel("");
        setIsMandatory(false);
        setEnableHelper(false);
        setEnableOther(false);
        setHelperText("");
        setSelectedFieldType("");
        setProcessing(false);
        setManageFields(false);
      });
    } catch (err) {
      console.log(err);
      handleError(err);
      setProcessing(false);
    }
  };

  const handleUpdateField = async (e) => {
    e.preventDefault();
    setProcessing(true);
    try {
      const response = await partner.post(
        `/v1/screening/templates/enhanced/fields/update`,
        JSON.stringify({
          template_uuid: activeTemplate,
          field_uuid: activeField.field_uuid,
          field_type: selectedFieldType,
          label: fieldLabel,
          mandatory: isMandatory,
          enable_other: enableOther,
          helper_text: helperText,
          active: fieldStatus,
        }),
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Basic " + useAuthHeader.base64encodedData,
            service: "partners",
          },

          //withCredentials: true,
        }
      );

      getTemplateData(activeTemplate);

      displayToast("Field updated successfully", () => {
        setFieldLabel("");
        setIsMandatory(false);
        setEnableHelper(false);
        setEnableOther(false);
        setHelperText("");
        setSelectedFieldType("");
        setProcessing(false);
        setManageFields(false);
      });
    } catch (err) {
      console.log(err);
      handleError(err);
      setProcessing(false);
    }
  };

  const handleUpdateFieldOrder = async (e) => {
    e.preventDefault();
    setProcessing(true);

    let fieldsOutput = [];

    for (let i = 0; i < list.length; i++) {
      // Square each element and push it to the new array
      fieldsOutput.push({ field_uuid: list[i].uuid, order: i + 1, label: list[i].label });
    }

    try {
      const response = await partner.post(
        `/v1/screening/templates/enhanced/fields/order`,
        JSON.stringify({
          template_uuid: activeTemplate,
          fields: fieldsOutput,
        }),
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Basic " + useAuthHeader.base64encodedData,
            service: "partners",
          },

          //withCredentials: true,
        }
      );

      getTemplateData();

      displayToast("Field order updated successfully", () => {
        setProcessing(false);
        setManageOrder(false);
      });
    } catch (err) {
      handleError(err);
      setProcessing(false);
    }
  };

  const handleDeleteField = async (e) => {
    e.preventDefault();
    setProcessing(true);
    try {
      const response = await partner.post(
        `/v1/screening/templates/enhanced/fields/delete`,
        JSON.stringify({
          template_uuid: activeTemplate,
          field_uuid: activeField.field_uuid,
        }),
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Basic " + useAuthHeader.base64encodedData,
            service: "partners",
          },

          //withCredentials: true,
        }
      );

      getTemplateData(activeTemplate);
      setDeleteFieldDialog(false);

      displayToast("Field deleted successfully", () => {
        setProcessing(false);
        setManageFields(false);
      });
    } catch (err) {
      handleError(err);
      setProcessing(false);
    }
  };

  const getSelectOptionData = async (optionUUID) => {
    try {
      const response = await partner.post(
        `/v1/screening/templates/enhanced/fields/select-option`,
        JSON.stringify({
          field_uuid: activeField.field_uuid,
          option_uuid: optionUUID,
        }),
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Basic " + useAuthHeader.base64encodedData,
            service: "partners",
          },

          //withCredentials: true,
        }
      );

      setSelectOptionLabel(response.data.label);
      setSelectOptionUUID(response.data.uuid);

      setManageFieldOption(true);
    } catch (err) {
      console.log(err);
      handleError(err);
    }
  };

  const handleAddSelectOption = async (e) => {
    e.preventDefault();
    setProcessing(true);
    try {
      const response = await partner.post(
        `/v1/screening/templates/enhanced/fields/select-option/add`,
        JSON.stringify({
          field_uuid: activeField.field_uuid,
          label: selectOptionLabel,
        }),
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Basic " + useAuthHeader.base64encodedData,
            service: "partners",
          },

          //withCredentials: true,
        }
      );

      getFieldData(activeField.field_uuid);

      displayToast("Option added successfully", () => {
        setSelectOptionLabel("");
        setProcessing(false);
        setManageFieldOption(false);
      });
    } catch (err) {
      handleError(err);
      setProcessing(false);
    }
  };

  const handleUpdateSelectOption = async (e) => {
    e.preventDefault();
    setProcessing(true);
    console.log(selectOptionUUID);
    try {
      const response = await partner.post(
        `/v1/screening/templates/enhanced/fields/select-option/update`,
        JSON.stringify({
          field_uuid: activeField.field_uuid,
          option_uuid: selectOptionUUID,
          label: selectOptionLabel,
        }),
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Basic " + useAuthHeader.base64encodedData,
            service: "partners",
          },

          //withCredentials: true,
        }
      );

      getFieldData(activeField.field_uuid);
      displayToast("Option updated successfully", () => {
        setProcessing(false);

        setSelectOptionUUID("");
        setSelectOptionLabel("");
        setManageFieldOption(false);
      });
    } catch (err) {
      console.log(err);
      handleError(err);
      setProcessing(false);
    }
  };

  const handleDeleteSelectOption = async (e) => {
    e.preventDefault();
    setProcessing(true);
    console.log(selectOptionUUID);
    console.log(activeField.field_uuid);
    try {
      const response = await partner.post(
        `/v1/screening/templates/enhanced/fields/select-option/delete`,
        JSON.stringify({
          field_uuid: activeField.field_uuid,
          option_uuid: selectOptionUUID,
        }),
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Basic " + useAuthHeader.base64encodedData,
            service: "partners",
          },

          //withCredentials: true,
        }
      );

      getFieldData(activeField.field_uuid);
      setDeleteOptionDialog(false);

      displayToast("Option deleted successfully", () => {
        setProcessing(false);

        setSelectOptionUUID("");
        setSelectOptionLabel("");
        setManageFieldOption(false);
      });
    } catch (err) {
      console.log(err);
      handleError(err);
      setProcessing(false);
    }
  };

  useEffect(() => {
    getTemplateData();
  }, []);

  return (
    <Box>
      {isLoaded ? (
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={6}>
            <Box sx={{ p: 2, display: "flex", flexDirection: "column", gap: 2 }}>
              <Box sx={{ border: 1, borderColor: "divider" }}>
                <Box sx={{ p: 2 }}>
                  <Typography variant="subtitle2">What is the name of the template?</Typography>
                </Box>

                <Divider />
                <Box sx={{ p: 2, display: "flex", flexDirection: "column", gap: 2 }}>
                  <TextField
                    fullWidth
                    id="outlined-basic"
                    label="Template name"
                    variant="outlined"
                    value={templateName}
                    onChange={(e) => setTemplateName(e.target.value)}
                  />
                </Box>
              </Box>

              <Box sx={{ border: 1, borderColor: "divider" }}>
                <Box sx={{ p: 2 }}>
                  <Typography variant="subtitle2">Provide a description and instructions for the template</Typography>
                </Box>

                <Divider />
                <ReactQuill theme="snow" value={templateDescription} onChange={setTemplateDescription} style={{ minHeight: "300px" }} />
              </Box>

              <Box sx={{ display: "flex", justifyContent: "space-between", border: 1, borderColor: "divider", p: 2 }}>
                <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
                  <Typography variant="h6">Template status</Typography>
                </Box>
                <Box sx={{ display: "flex", flexDirection: "column", gap: 2, width: "50%" }}>
                  <Box sx={{ display: "flex", justifyContent: "flex-end", gap: 1 }}>
                    <ToggleButtonGroup
                      size="small"
                      color={templateActive ? "primary" : "danger"}
                      value={templateActive}
                      exclusive
                      onChange={(e, newValue) => {
                        if (newValue !== null) setTemplateActive(newValue);
                      }}
                      aria-label="Enable or disable the template"
                    >
                      <ToggleButton value={true}>Enabled</ToggleButton>
                      <ToggleButton value={false}>Disabled</ToggleButton>
                    </ToggleButtonGroup>
                  </Box>
                </Box>
              </Box>

              {!manageFields && !manageOrder && (
                <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                  <Box>
                    {activeTemplate && (
                      <Button variant="outlined" color="danger" startIcon={<Delete />} onClick={() => setDeleteTemplateDialog(true)}>
                        Delete template
                      </Button>
                    )}
                  </Box>
                  <LoadingButton
                    disableElevation
                    variant="contained"
                    color="success"
                    loading={processing}
                    onClick={(e) => {
                      handleUpdateTemplate(e);
                    }}
                  >
                    Save changes
                  </LoadingButton>
                </Box>
              )}
            </Box>
          </Grid>

          <Grid item xs={12} sm={12} md={12} lg={12} xl={6}>
            <Box sx={{ p: 2, display: "flex", flexDirection: "column", gap: 2 }}>
              <Box sx={{ border: 1, borderColor: "divider" }}>
                <Box sx={{ p: 2, display: "flex", justifyContent: "space-between" }}>
                  <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
                    <Typography variant="subtitle2">Form fields</Typography>
                  </Box>
                  <Box sx={{ display: "flex", gap: 1 }}>
                    {templateFields?.length > 1 && !manageFields && (
                      <Button
                        variant="outlined"
                        color="primary"
                        onClick={() => {
                          setManageOrder(!manageOrder);
                        }}
                      >
                        {manageOrder ? "Close" : "Change order"}
                      </Button>
                    )}

                    {!manageOrder && (
                      <Button
                        variant="outlined"
                        color="primary"
                        onClick={() => {
                          setActiveField({});
                          setFieldLabel("");
                          setSelectedFieldType("");
                          setIsMandatory(false);
                          setEnableHelper(false);
                          setEnableOther(false);
                          setHelperText("");
                          setSelectFieldOptions([]);
                          setManageFieldOption(false);
                          setSelectOptionLabel("");
                          setManageFields(!manageFields);
                        }}
                      >
                        {manageFields ? "Close" : "Add field"}
                      </Button>
                    )}
                  </Box>
                </Box>

                <Divider />
                <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
                  {manageFields ? (
                    <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
                      <Box sx={{}}>
                        <Box sx={{ p: 2 }}>
                          <Typography variant="subtitle2">What is the field label or question?</Typography>
                        </Box>

                        <Divider />
                        <Box sx={{ p: 2, display: "flex", flexDirection: "column", gap: 2 }}>
                          <TextField
                            fullWidth
                            id="outlined-basic"
                            label="Label or question"
                            variant="outlined"
                            value={fieldLabel}
                            onChange={(e) => setFieldLabel(e.target.value)}
                          />
                        </Box>

                        <Divider />

                        <Box sx={{}}>
                          <Box sx={{ p: 2 }}>
                            <Typography variant="subtitle2">What type of data do you need to capture?</Typography>
                          </Box>

                          <Divider />
                          <Box sx={{ p: 2, display: "flex", flexDirection: "column", gap: 2 }}>
                            <FormControl fullWidth>
                              <InputLabel id="field-type-label">Field type</InputLabel>
                              <Select
                                labelId="field-type-label"
                                id="field-type"
                                value={selectedFieldType}
                                label="Field type"
                                onChange={(e) => {
                                  setSelectedFieldType(e.target.value);
                                }}
                                disabled={activeField.field_uuid ? selectedFieldType > 2 && selectedFieldType < 5 : false}
                              >
                                {fieldOptions.map((option, index) => (
                                  <MenuItem key={index} value={option.value}>
                                    {option.label}
                                  </MenuItem>
                                ))}
                              </Select>
                            </FormControl>
                          </Box>
                        </Box>

                        {activeField.field_uuid && (
                          <Collapse in={selectedFieldType > 2 && selectedFieldType < 5} exit={!(selectedFieldType > 2 && selectedFieldType < 5)}>
                            <Box sx={{ border: 1 }}>
                              <Divider />
                              <Box sx={{ p: 2, display: "flex", justifyContent: "space-between" }}>
                                <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
                                  <Typography variant="subtitle2">Select options</Typography>
                                </Box>
                                <Button
                                  variant="outlined"
                                  color="primary"
                                  onClick={() => {
                                    if (selectOptionUUID) {
                                      setSelectOptionUUID("");
                                      setSelectOptionLabel("");
                                    }
                                    setManageFieldOption(!manageFieldOption);
                                  }}
                                >
                                  {manageFieldOption ? "Close" : "Add option"}
                                </Button>
                              </Box>

                              <Divider />

                              {manageFieldOption ? (
                                <Box sx={{}}>
                                  <Box sx={{ p: 2 }}>
                                    <Typography variant="subtitle2">Provide a label for the select option</Typography>
                                  </Box>
                                  <Divider />
                                  <Box sx={{ p: 2 }}>
                                    <TextField
                                      fullWidth
                                      id="outlined-basic"
                                      label="Option label"
                                      variant="outlined"
                                      value={selectOptionLabel}
                                      onChange={(e) => setSelectOptionLabel(e.target.value)}
                                    />
                                  </Box>
                                  <Box sx={{ display: "flex", justifyContent: "space-between", p: 2, pt: 0 }}>
                                    <Box>
                                      {selectOptionUUID && (
                                        <Button variant="outlined" color="danger" startIcon={<Delete />} onClick={() => setDeleteOptionDialog(true)}>
                                          Delete option
                                        </Button>
                                      )}
                                    </Box>

                                    <LoadingButton
                                      disableElevation
                                      variant="contained"
                                      color="success"
                                      loading={processing}
                                      onClick={(e) => {
                                        {
                                          selectOptionUUID ? handleUpdateSelectOption(e) : handleAddSelectOption(e);
                                        }
                                      }}
                                    >
                                      {selectOptionUUID ? "Update option" : "Create option"}
                                    </LoadingButton>
                                  </Box>
                                </Box>
                              ) : (
                                <DataTable
                                  columns={selectFieldColumns}
                                  data={selectFieldOptions}
                                  noDataComponent={<NoResults message={`Requires atleast one select option`} />}
                                  customStyles={customStyles}
                                  striped
                                />
                              )}
                            </Box>
                          </Collapse>
                        )}

                        <Divider />

                        <Box sx={{}}>
                          <Box sx={{ p: 2 }}>
                            <Typography variant="subtitle2">Field options</Typography>
                          </Box>

                          <Divider />
                          <Box sx={{ p: 2, display: "flex", flexDirection: "column", gap: 2 }}>
                            <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                              <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
                                <Typography variant="subtitle2">Make the field mandatory</Typography>
                              </Box>
                              <Box sx={{ display: "flex", flexDirection: "column", gap: 2, width: "50%" }}>
                                <Box sx={{ display: "flex", justifyContent: "flex-end", gap: 1 }}>
                                  <ToggleButtonGroup
                                    size="small"
                                    color={isMandatory ? "primary" : "danger"}
                                    value={isMandatory}
                                    exclusive
                                    onChange={(e, newValue) => {
                                      if (newValue !== null) setIsMandatory(newValue);
                                    }}
                                    aria-label="Make the field mandatory or optional"
                                  >
                                    <ToggleButton value={true}>Mandatory</ToggleButton>
                                    <ToggleButton value={false}>Optional</ToggleButton>
                                  </ToggleButtonGroup>
                                </Box>
                              </Box>
                            </Box>

                            <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                              <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
                                <Typography variant="subtitle2">Provide guidance notes</Typography>
                              </Box>
                              <Box sx={{ display: "flex", flexDirection: "column", gap: 2, width: "50%" }}>
                                <Box sx={{ display: "flex", justifyContent: "flex-end", gap: 1 }}>
                                  <ToggleButtonGroup
                                    size="small"
                                    color={enableHelper ? "primary" : "danger"}
                                    value={enableHelper}
                                    exclusive
                                    onChange={(e, newValue) => {
                                      if (newValue !== null) setEnableHelper(newValue);
                                    }}
                                    aria-label="Enable or disable guidance notes"
                                  >
                                    <ToggleButton value={true}>Enabled</ToggleButton>
                                    <ToggleButton value={false}>Disabled</ToggleButton>
                                  </ToggleButtonGroup>
                                </Box>
                              </Box>
                            </Box>

                            {selectedFieldType > 2 && selectedFieldType < 5 && (
                              <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                                <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
                                  <Typography variant="subtitle2">Enable "other" as an option</Typography>
                                </Box>
                                <Box sx={{ display: "flex", flexDirection: "column", gap: 2, width: "50%" }}>
                                  <Box sx={{ display: "flex", justifyContent: "flex-end", gap: 1 }}>
                                    <ToggleButtonGroup
                                      size="small"
                                      color={enableOther ? "primary" : "danger"}
                                      value={enableOther}
                                      exclusive
                                      onChange={(e, newValue) => {
                                        if (newValue !== null) setEnableOther(newValue);
                                      }}
                                      aria-label="Enable or disable other as a select option"
                                    >
                                      <ToggleButton value={true}>Enabled</ToggleButton>
                                      <ToggleButton value={false}>Disabled</ToggleButton>
                                    </ToggleButtonGroup>
                                  </Box>
                                </Box>
                              </Box>
                            )}
                          </Box>
                        </Box>

                        <Collapse in={enableHelper} exit={!enableHelper}>
                          <Box sx={{}}>
                            <Divider />
                            <Box sx={{ p: 2 }}>
                              <Typography variant="subtitle2">Provide the guidance notes or instructions for the field</Typography>
                            </Box>

                            <Divider />
                            <ReactQuill theme="snow" value={helperText} onChange={setHelperText} style={{ minHeight: "300px" }} />
                          </Box>
                        </Collapse>

                        <Divider />

                        {activeField.field_uuid && (
                          <Box sx={{}}>
                            <Box sx={{ p: 2 }}>
                              <Typography variant="subtitle2">Field status</Typography>
                            </Box>

                            <Divider />
                            <Box sx={{ p: 2, display: "flex", flexDirection: "column", gap: 2 }}>
                              <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                                <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
                                  <Typography variant="subtitle2">Enable or disable the field</Typography>
                                </Box>
                                <Box sx={{ display: "flex", flexDirection: "column", gap: 2, width: "50%" }}>
                                  <Box sx={{ display: "flex", justifyContent: "flex-end", gap: 1 }}>
                                    <ToggleButtonGroup
                                      size="small"
                                      color={fieldStatus ? "primary" : "danger"}
                                      value={fieldStatus}
                                      exclusive
                                      onChange={(e, newValue) => {
                                        if (newValue !== null) setFieldStatus(newValue);
                                      }}
                                      aria-label="Enable or disable the fields status"
                                    >
                                      <ToggleButton value={true}>Active</ToggleButton>
                                      <ToggleButton value={false}>Disabled</ToggleButton>
                                    </ToggleButtonGroup>
                                  </Box>
                                </Box>
                              </Box>
                            </Box>
                            <Divider />
                          </Box>
                        )}

                        {!manageFieldOption && (
                          <Box>
                            <Box sx={{ display: "flex", justifyContent: "space-between", p: 2 }}>
                              <Box>
                                {activeField.field_uuid && (
                                  <Button variant="outlined" color="danger" startIcon={<Delete />} onClick={() => setDeleteFieldDialog(true)}>
                                    Delete field
                                  </Button>
                                )}
                              </Box>
                              <LoadingButton
                                disableElevation
                                variant="contained"
                                color="success"
                                loading={processing}
                                onClick={(e) => {
                                  activeField.field_uuid ? handleUpdateField(e) : handleCreateField(e);
                                }}
                              >
                                {activeField.field_uuid ? "Save changes" : "Create field"}
                              </LoadingButton>
                            </Box>
                          </Box>
                        )}
                      </Box>
                    </Box>
                  ) : (
                    <Box>
                      {manageOrder ? (
                        <Box>
                          <Box sx={{ p: 2 }}>
                            <ReactSortable
                              filter=".addImageButtonContainer"
                              dragClass="sortableDrag"
                              list={list}
                              setList={setList}
                              animation="200"
                              easing="ease-out"
                            >
                              {list?.map((item) => (
                                <Box key={item.order} className="draggableItem">
                                  {item.label}
                                </Box>
                              ))}
                            </ReactSortable>
                          </Box>
                          <Divider />

                          <Box sx={{ display: "flex", justifyContent: "flex-end", p: 2 }}>
                            <LoadingButton
                              disableElevation
                              variant="contained"
                              color="success"
                              loading={processing}
                              onClick={(e) => {
                                handleUpdateFieldOrder(e);
                              }}
                            >
                              Save changes
                            </LoadingButton>
                          </Box>
                        </Box>
                      ) : (
                        <Box>
                          <DataTable
                            columns={fieldColumns}
                            data={templateFields}
                            customStyles={customStyles}
                            noDataComponent={<NoResults message={`You need to add atleast one field to the form`} />}
                            pagination
                            striped
                          />

                          {templateFields?.length > 0 && (
                            <Box>
                              <Divider />

                              <Box sx={{ display: "flex", justifyContent: "flex-end", p: 2 }}>
                                <Button variant="outlined" color="primary" onClick={() => setPreviewForm(true)}>
                                  Preview Form
                                </Button>
                              </Box>
                            </Box>
                          )}
                        </Box>
                      )}
                    </Box>
                  )}
                </Box>
              </Box>
            </Box>
          </Grid>
        </Grid>
      ) : (
        <Box>
          <Lottie speed={2.0} options={defaultOptions} height={200} width={200} />
        </Box>
      )}

      <Dialog
        open={deleteOptionDialog}
        onClose={() => setDeleteOptionDialog(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Are you sure?</DialogTitle>
        <DialogContent>
          <Box>
            <DialogContentText sx={{ mb: 1 }} id="alert-dialog-description">
              Are you sure you want to delete this option?
            </DialogContentText>
          </Box>
        </DialogContent>
        <DialogActions>
          <LoadingButton disableElevation loading={processing} onClick={() => setDeleteOptionDialog(false)} color="primary">
            Cancel
          </LoadingButton>

          <LoadingButton
            disableElevation
            sx={{ color: "#ffffff" }}
            loading={processing}
            onClick={(e) => {
              handleDeleteSelectOption(e);
            }}
            variant="contained"
            color="danger"
            autoFocus
          >
            Confirm
          </LoadingButton>
        </DialogActions>
      </Dialog>

      <Dialog
        open={deleteFieldDialog}
        onClose={() => setDeleteFieldDialog(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Are you sure?</DialogTitle>
        <DialogContent>
          <Box>
            <DialogContentText sx={{ mb: 1 }} id="alert-dialog-description">
              Are you sure you want to delete this field?
            </DialogContentText>
          </Box>
        </DialogContent>
        <DialogActions>
          <LoadingButton disableElevation loading={processing} onClick={() => setDeleteFieldDialog(false)} color="primary">
            Cancel
          </LoadingButton>

          <LoadingButton
            disableElevation
            sx={{ color: "#ffffff" }}
            loading={processing}
            onClick={(e) => {
              handleDeleteField(e);
            }}
            variant="contained"
            color="danger"
            autoFocus
          >
            Confirm
          </LoadingButton>
        </DialogActions>
      </Dialog>

      <Dialog
        open={deleteTemplateDialog}
        onClose={() => setDeleteTemplateDialog(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Are you sure?</DialogTitle>
        <DialogContent>
          <Box>
            <DialogContentText sx={{ mb: 1 }} id="alert-dialog-description">
              Are you sure you want to delete this template?
            </DialogContentText>
          </Box>
        </DialogContent>
        <DialogActions>
          <LoadingButton disableElevation loading={processing} onClick={() => setDeleteTemplateDialog(false)} color="primary">
            Cancel
          </LoadingButton>

          <LoadingButton
            disableElevation
            sx={{ color: "#ffffff" }}
            loading={processing}
            onClick={(e) => {
              handleDeleteTemplate(e);
            }}
            variant="contained"
            color="danger"
            autoFocus
          >
            Confirm
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default ManageTemplate;
