import { useCallback, useEffect, useState } from "react";
import { getTaskNotesHistory } from "../../api/services";

export function useGetTaskNoteHistory(noteUUID) {
  const [state, setState] = useState({
    data: null,
    loading: true,
  });

  const getTaskNoteHistoryQuery = useCallback(async () => {
    setState({ data: null, loading: true });
    if (!noteUUID) return;
    try {
      const res = await getTaskNotesHistory({ noteUUID });
      setState({ data: res?.entries, loading: false });
    } catch (error) {
      console.error(error);
      setState({ data: null, loading: false });
    }
  }, [noteUUID]);

  useEffect(() => {
    if (!state?.data) {
      getTaskNoteHistoryQuery();
    }
  }, [getTaskNoteHistoryQuery, state?.data]);

  return {
    taskHistoryData: state?.data,
    loading: state?.loading,
    getTaskNoteHistoryQuery,
  };
}
