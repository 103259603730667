import { React, useEffect, useState } from "react";

import partner from "../../../../../api/partner";
import authHeader from "../../../../../api/authHeader";

import HandleError from "../../../../../components/functions/HandleError";
import HandleToast from "../../../../../components/functions/HandleToast";

import Lottie from "react-lottie";
import loadingLottie from "../../../../../components/lotties/loading-dots.json";

import { Typography, Box, Divider, Fade, Collapse } from "@mui/material";
import {
  Grid,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";

import { LoadingButton } from "@mui/lab";

const Capacity = ({ partner, partnerUser, partnerData }) => {
  const useAuthHeader = authHeader();

  const handleError = HandleError();
  const displayToast = HandleToast();

  const [isLoaded, setIsLoaded] = useState(false);
  const [processing, setProcessing] = useState(false);

  const [automatic, setAutomatic] = useState(false);
  const [automaticThreshold, setAutomaticThreshold] = useState("");

  const [confirmDialog, setConfirmDialog] = useState(false);

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: loadingLottie,
  };

  const getCapacitySettings = async () => {
    try {
      const response = await partner.post(
        `/v1/screening/risk-assessment/burst-capacity`,
        JSON.stringify({
          partner_uuid: partnerUser.value.partner_uuid,
        }),
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Basic " + useAuthHeader.base64encodedData,
            service: "partners",
          },

          //withCredentials: true,
        }
      );

      setAutomatic(response.data.active);
      setAutomaticThreshold(response.data.threshold);

      setIsLoaded(true);
    } catch (err) {
      handleError(err);
    }
  };

  const handleSaveSettings = async (e) => {
    e.preventDefault();
    setProcessing(true);

    try {
      const response = await partner.post(
        `/v1/screening/risk-assessment/burst-capacity/update`,
        JSON.stringify({
          partner_uuid: partnerUser.value.partner_uuid,
          active: automatic,
          threshold: parseInt(automaticThreshold),
        }),
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Basic " + useAuthHeader.base64encodedData,
            service: "partners",
          },

          //withCredentials: true,
        }
      );

      displayToast("Settings updated successfully", () => {
        setProcessing(false);
      });
    } catch (err) {
      handleError(err);
      setProcessing(false);
    }
  };

  useEffect(() => {
    getCapacitySettings();
  }, []);

  return (
    <>
      {isLoaded ? (
        <>
          <Divider />
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={12} lg={6}>
              <Box sx={{ borderBottom: 1, borderRight: { xs: 0, lg: 1 }, borderColor: { xs: "divider", lg: "divider" } }}>
                <Box sx={{ p: 2, display: "flex", justifyContent: "space-between" }}>
                  <Box sx={{ display: "flex", gap: 4 }}>
                    <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
                      <Typography variant="h6">Burst capacity</Typography>
                    </Box>
                  </Box>
                </Box>
                <Divider />
                <Box sx={{ p: 2, display: "flex", flexDirection: "column", gap: 2 }}>
                  <Box sx={{ border: 1, borderColor: "divider" }}>
                    <Box sx={{ p: 2, display: "flex", flexDirection: "column", gap: 1 }}>
                      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                        <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
                          <Typography variant="h6">Auto engage burst capacity</Typography>
                        </Box>
                        <Box sx={{ display: "flex", flexDirection: "column", gap: 2, width: "50%" }}>
                          <Box sx={{ display: "flex", justifyContent: "flex-end", gap: 1 }}>
                            <ToggleButtonGroup
                              size="small"
                              color={automatic ? "primary" : "danger"}
                              value={automatic}
                              exclusive
                              onChange={(e, newValue) => {
                                if (newValue !== null) setAutomatic(newValue);
                              }}
                              aria-label="Enable or disable automatic burst capacity"
                            >
                              <ToggleButton value={true}>Enabled</ToggleButton>
                              <ToggleButton value={false}>Disabled</ToggleButton>
                            </ToggleButtonGroup>
                          </Box>
                          <Collapse in={automatic} exit={!automatic}>
                            <Box sx={{ display: "flex", justifyContent: "flex-end", gap: 2 }}>
                              <TextField
                                label="Trigger on number of results"
                                type="number"
                                min={0}
                                value={automaticThreshold}
                                onChange={(e) => setAutomaticThreshold(e.target.value)}
                                fullWidth
                              />
                            </Box>
                          </Collapse>
                        </Box>
                      </Box>
                    </Box>
                  </Box>

                  <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                    <LoadingButton
                      disableElevation
                      variant="contained"
                      color="success"
                      loading={processing}
                      onClick={(e) => {
                        setConfirmDialog(true);
                      }}
                    >
                      Save changes
                    </LoadingButton>
                  </Box>
                </Box>
              </Box>
            </Grid>
          </Grid>

          <Dialog
            open={confirmDialog}
            onClose={() => setConfirmDialog(false)}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">Are you sure?</DialogTitle>
            <DialogContent>
              <Box>
                <DialogContentText sx={{ mb: 1 }} id="alert-dialog-description">
                  Are you sure you want to update these settings?
                </DialogContentText>
              </Box>
            </DialogContent>
            <DialogActions>
              <LoadingButton disableElevation loading={processing} onClick={() => setConfirmDialog(false)} color="primary">
                Cancel
              </LoadingButton>

              <LoadingButton
                disableElevation
                sx={{ color: "#ffffff" }}
                loading={processing}
                onClick={(e) => {
                  handleSaveSettings(e);
                }}
                variant="contained"
                color="success"
                autoFocus
              >
                Confirm
              </LoadingButton>
            </DialogActions>
          </Dialog>
        </>
      ) : (
        <Box>
          <Lottie speed={2.0} options={defaultOptions} height={200} width={200} />
        </Box>
      )}
    </>
  );
};

export default Capacity;
