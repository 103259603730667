import { React } from "react";

import { Card, CardContent, Typography, Divider, Box } from "@mui/material";

import MyAccount from "./components/settings/my-account/MyAccount";
import Branding from "./components/settings/branding/Branding";
import Templates from "./components/settings/templates/Templates";
import Screening from "./components/settings/screening/Screening";

const Settings = ({ partner, settingsView, partnerData, partnerUser }) => {
  return (
    <Card
      variant="outlined"
      sx={{
        p: 0,
        width: "100%",
        borderRadius: "0",
      }}
    >
      <Box display="flex" alignItems="center">
        <Box p={2}>
          <Typography variant="h5">
            Settings -{" "}
            {settingsView.value == 0
              ? "My account"
              : settingsView.value == 1
              ? "Branding"
              : settingsView.value == 2
              ? "Templates"
              : settingsView.value == 3
              ? "Compliance"
              : settingsView.value == 4
              ? "Client billing"
              : settingsView.value == 5 && "Data management"}
          </Typography>
        </Box>
      </Box>
      <Divider />
      <Box sx={{ p: 0 }}>
        {settingsView.value === 0 && <MyAccount partner={partner} partnerUser={partnerUser} partnerData={partnerData} />}
        {settingsView.value === 1 && <Branding partner={partner} partnerUser={partnerUser} partnerData={partnerData} />}
        {settingsView.value === 2 && <Templates partner={partner} partnerUser={partnerUser} partnerData={partnerData} />}
        {settingsView.value === 3 && <Screening partner={partner} partnerUser={partnerUser} partnerData={partnerData} />}
      </Box>
    </Card>
  );
};

export default Settings;
