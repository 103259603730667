import { Link, Menu, MenuItem, Typography } from "@mui/material";
import PropTypes from "prop-types";
import { useState } from "react";
import { RenderWhen } from "../../../../../components/render-when";

export function TaskFilesList({ step }) {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <RenderWhen when={Boolean(step?.linked_files?.length > 0)}>
        <Link className="link" onClick={handleClick}>
          Linked Files
        </Link>
        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            "aria-labelledby": "basic-button",
          }}
        >
          {step?.linked_files?.map((file, index) => (
            <MenuItem key={index} value={file?.file_uuid}>
              {file?.filename}
            </MenuItem>
          ))}
        </Menu>
      </RenderWhen>
      <RenderWhen when={Boolean(!(step?.linked_files?.length > 0))}>
        <Typography variant="body1" fontWeight={600}>
          File Required:
        </Typography>
      </RenderWhen>
    </>
  );
}

TaskFilesList.propTypes = {
  step: PropTypes.object,
};
