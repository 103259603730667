import { React, useCallback } from "react";

import { useDropzone } from "react-dropzone";

import { Typography, Box } from "@mui/material";

const SelfieUpload = ({ setFile }) => {
  const onDrop = useCallback((acceptedFiles) => {
    acceptedFiles.forEach((file) => {
      setFile(file);
    });
  }, []);

  const { acceptedFiles, getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: {
      "image/png": [".png"],
      "image/jpeg": [".jpg", ".jpeg"],
    },
    maxFiles: 1,
  });

  return (
    <>
      <Box sx={{ border: 1, borderColor: "divider" }}>
        <Box sx={{ p: 2, borderBottom: 1, borderColor: "divider", backgroundColor: "#0b0b0b", color: "#ffffff" }}>
          <Typography variant="subtitle2">Upload a selfie of the client</Typography>
        </Box>
        <Box sx={{ p: 2, pb: 0 }}>
          <Typography variant="body2" sx={{ fontSize: "13px", m: 0 }}>
            When uploading a selfie of the client, ensure they are the only person visible within the image and the image is clear, unfiltered and
            unedited.
          </Typography>
        </Box>
        <Box sx={{ p: 2, pt: 0 }}>
          <Box
            className="file-drop"
            sx={{
              border: 2,
              borderColor: "divider",
              borderStyle: "dashed",
              cursor: "pointer",
              p: 5,
              my: 2,

              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
            }}
            {...getRootProps()}
          >
            <input {...getInputProps()} />
            {isDragActive ? (
              <Typography align="center">Drop the file here ...</Typography>
            ) : (
              <Typography align="center">Drop a file here, or click to select a file</Typography>
            )}
            <Typography align="center" variant="caption">
              (Only pdf, jpeg or png, files will be accepted)
            </Typography>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default SelfieUpload;
