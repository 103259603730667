import React from "react";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

const ErrorHandling = () => {
  const MySwal = withReactContent(Swal);
  const navigate = useNavigate();

  const handleErrors = (err) => {
    if (err.response?.status === undefined) {
      MySwal.fire({
        html: "<p>Something didn't go to plan</p>",
        icon: "error",
        iconColor: "danger",
        position: "top",
        timer: 2000,
        timerProgressBar: true,
        showCancelButton: false,
        showConfirmButton: true,
        confirmButtonColor: "#1C2321",
        confirmButtonText: "Close",
        confirmButtonAriaLabel: "Close",
        focusConfirm: false,
        showDenyButton: false,
        allowOutsideClick: true,
        allowEscapeKey: false,
      }).then(() => {});

      return;
    } else if (err.response?.status === 401) {
      MySwal.fire({
        html: "<p>You are now logged out</p>",
        icon: "error",
        iconColor: "danger",
        timer: 1500,
        position: "top",
        timerProgressBar: true,
        showCancelButton: false,
        showConfirmButton: false,
        showDenyButton: false,
        allowOutsideClick: false,
        allowEscapeKey: false,
      }).then(() => {
        navigate("/", true);
      });
    } else if (err.response?.status === 400) {
      MySwal.fire({
        html: "<p>" + err.response.data + "</p>",
        icon: "error",
        iconColor: "danger",
        position: "top",
        timer: 2000,
        timerProgressBar: true,
        showCancelButton: false,
        showConfirmButton: true,
        confirmButtonColor: "#1C2321",
        confirmButtonText: "Close",
        confirmButtonAriaLabel: "Close",
        focusConfirm: false,
        showDenyButton: false,
        allowOutsideClick: true,
        allowEscapeKey: false,
      }).then(() => {});
    } else {
      MySwal.fire({
        html: "<p>Something didn't go to plan</p>",
        icon: "error",
        iconColor: "danger",
        position: "top",
        timer: 2000,
        timerProgressBar: true,
        showCancelButton: false,
        showConfirmButton: true,
        confirmButtonColor: "#1C2321",
        confirmButtonText: "Close",
        confirmButtonAriaLabel: "Close",
        focusConfirm: false,
        showDenyButton: false,
        allowOutsideClick: true,
        allowEscapeKey: false,
      }).then(() => {});
    }
  };

  return handleErrors;
};

export default ErrorHandling;
