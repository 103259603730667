import { React, useEffect, useState } from "react";

import partner from "../../api/partner";
import authHeader from "../../api/authHeader";

import HandleError from "../../components/functions/HandleError";
import HandleToast from "../../components/functions/HandleToast";

import Lottie from "react-lottie";
import loadingLottie from "../../components/lotties/loading-dots.json";

import DataTable from "react-data-table-component";
import "moment/dist/locale/en-gb";
import moment from "moment";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

import {
  Box,
  Typography,
  Chip,
  Grid,
  Icon,
  IconButton,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Divider,
  Autocomplete,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  Collapse,
  InputAdornment,
  ToggleButtonGroup,
  ToggleButton,
  FormControlLabel,
  RadioGroup,
  Radio,
  FormLabel,
} from "@mui/material";
import { Edit, Close, Delete } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import CountrySelect from "../../components/form-elements/CountrySelect";

const Fatca = ({ partnerData, partnerUser, caseData, entityData }) => {
  const useAuthHeader = authHeader();
  const handleError = HandleError();
  const displayToast = HandleToast();

  const [locale, setLocale] = useState("en-gb");

  if (moment.locale() != locale) {
    moment.locale(locale);
  }

  const [isLoaded, setIsLoaded] = useState(false);
  const [fatcaLoaded, setFatcaLoaded] = useState(false);
  const [crsLoaded, setCrsLoaded] = useState(false);

  const [processing, setProcessing] = useState(false);
  const [editMode, setEditMode] = useState(false);

  const [fatcaEdit, setFatcaEdit] = useState(false);
  const [crsEdit, setCrsEdit] = useState(false);
  const [codesEdit, setCodesEdit] = useState(false);

  const [sectionDisplay, setSectionDisplay] = useState(0);

  const [entityType, setEntityType] = useState(0);

  const [usPersonTin, setUsPersonTin] = useState("");

  const [usPersonExemptionType, setUsPersonExemptionType] = useState(0);

  const [financialInstitution, setFinancialInstitution] = useState(0);
  const [financialInstitutionType, setFinancialInstitutionType] = useState(0);

  const [giin, setGiin] = useState("");
  const [noGiinReason, setNoGiinReason] = useState(0);

  const [sponsoringEntityName, setSponsoringEntityName] = useState("");
  const [sponsoringEntityGIIN, setSponsoringEntityGIIN] = useState("");
  const [trusteeName, setTrusteeName] = useState("");
  const [trusteeGIIN, setTrusteeGIIN] = useState("");
  const [noGiinExemptionType, setNoGiinExemptionType] = useState(0);

  const [fatcaDisplay, setFatcaDisplay] = useState();

  const [taxResidencyData, setTaxResidencyData] = useState([
    { jurisdiction: "United Kingdom", tin: "123456789", uuid: "1234" },
  ]);
  const [residencyCountry, setResidencyCountry] = useState();
  const [residencyTin, setResidencyTin] = useState("");

  const [deleteTaxResidency, setDeleteTaxResidency] = useState("");
  const [deleteTaxResidencyCountry, setDeleteTaxResidencyCountry] = useState("");
  const [deleteTaxResidencyDialog, setDeleteTaxResidencyDialog] = useState(false);

  const [crsDisplay, setCrsDisplay] = useState();

  const [crsFinancialInstitution, setCrsFinancialInstitution] = useState(0);
  const [crsFinancialInstitutionType, setCrsFinancialInstitutionType] = useState(0);
  const [crsPassiveNonFinancialInstitution, setCrsPassiveNonFinancialInstitution] = useState(0);
  const [crsReportingFinancialInstitutionType, setCrsReportingFinancialInstitutionType] =
    useState(0);
  const [crsNonReportingFinancialInstitutionType, setCrsNonReportingFinancialInstitutionType] =
    useState(0);
  const [crsOtherNonReportingEntity, setCrsOtherNonReportingEntity] = useState("");

  const [controllingName, setControllingName] = useState("");
  const [controllingResidentialAddress, setControllingResidentialAddress] = useState("");
  const [controllingCountryOfBirth, setControllingCountryOfBirth] = useState();
  const [controllingPlaceOfBirth, setControllingPlaceOfBirth] = useState("");
  const [controllingDateOfBirth, setControllingDateOfBirth] = useState("");
  const [controllingTin, setControllingTin] = useState("");
  const [controllerType, setControllerType] = useState();

  const controllerTypeOptions = [
    { value: 1, label: "Controlling Person of a Legal Person – Control by Ownership" },
    { value: 2, label: "Controlling Person of a Legal Person – Control by Other Means" },
    { value: 3, label: "Controlling Person of a Legal Person – Senior Managing Official" },
    { value: 4, label: "Controlling Person of a Trust – Settlor" },
    { value: 5, label: "Controlling Person of a Trust – Trustee" },
    { value: 6, label: "Controlling Person of a Trust – Protector" },
    { value: 7, label: "Controlling Person of a Trust – Beneficiary" },
    { value: 8, label: "Controlling Person of a Trust – Other" },
    {
      value: 9,
      label: "Controlling Person of a Legal Arrangement (Non-Trust) – Settlor Equivalent",
    },
    {
      value: 10,
      label: "Controlling Person of a Legal Arrangement (Non-Trust) – Trustee Equivalent",
    },
    {
      value: 11,
      label: "Controlling Person of a Legal Arrangement (Non-Trust) – Protector Equivalent",
    },
    {
      value: 12,
      label: "Controlling Person of a Legal Arrangement (Non-Trust) – Beneficiary Equivalent",
    },
    {
      value: 13,
      label: "Controlling Person of a Legal Arrangement (Non-Trust) – Other Equivalent",
    },
  ];

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: loadingLottie,
  };

  const customStyles = {
    headCells: {
      style: {
        paddingLeft: "8px", // override the cell padding for head cells
        paddingRight: "8px",
        fontWeight: "bold",
      },
    },
    cells: {
      style: {
        paddingLeft: "8px", // override the cell padding for data cells
        paddingRight: "8px",
      },
    },
  };

  const columns = [
    {
      name: "Jurisdiction",
      sortable: true,
      width: "50%",
      selector: (row) => row.country?.name,
    },
    {
      name: "TIN",
      sortable: true,
      right: "true",
      selector: (row) => row.tax_number,
    },
  ];

  const manageColumns = [
    {
      name: "Jurisdiction",
      sortable: true,
      width: "50%",
      selector: (row) => row.country?.name,
    },
    {
      name: "TIN",
      sortable: true,
      right: "true",
      selector: (row) => row.tax_number,
    },
    {
      name: "Actions",
      sortable: false,
      right: "true",
      cell: (row) => (
        <Box sx={{ display: "flex", flexDirection: "row", gap: 2 }}>
          <IconButton
            onClick={() => {
              setDeleteTaxResidencyCountry(row.country?.name);
              setDeleteTaxResidency(row.uuid);
              setDeleteTaxResidencyDialog(true);
            }}
            sx={{ color: "#C83E4D", cursor: "pointer" }}
          >
            <Delete />
          </IconButton>
        </Box>
      ),
    },
  ];

  const getFatcaDeclaration = async () => {
    try {
      const response = await partner.post(
        `/v1/entity/corporations/corporation/data/fatca`,
        JSON.stringify({
          corporation_uuid: entityData.value.corporation_uuid,
        }),
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Basic " + useAuthHeader.base64encodedData,
            service: "partners",
          },

          //withCredentials: true,
        }
      );

      if (response.data.complete) {
        setEntityType(response.data.entity_type);
        setUsPersonTin(response.data.tin);
        setUsPersonExemptionType(response.data.us_person_exemption);
        setFinancialInstitution(response.data.financial_institution ? 1 : 0);
        setFinancialInstitutionType(response.data.financial_institution_type);
        setGiin(response.data.giin);
        setNoGiinReason(response.data.no_giin_reason);
        setNoGiinExemptionType(response.data.no_giin_exemption_type);
        setSponsoringEntityName(response.data.sponsor_name);
        setSponsoringEntityGIIN(response.data.sponsor_giin);
        setTrusteeName(response.data.trustee_name);
        setTrusteeGIIN(response.data.trustee_giin);
      }
      setFatcaDisplay(response.data);
      setFatcaLoaded(true);
    } catch (err) {
      console.log(err);
      handleError(err);
    }
  };

  const handleSaveFatcaDeclaration = async (e) => {
    e.preventDefault();
    setProcessing(true);

    try {
      const response = await partner.post(
        `/v1/entity/corporations/corporation/tax/fatca`,
        JSON.stringify({
          corporation_uuid: entityData.value.corporation_uuid,
          entity_type: parseInt(entityType),
          us_person_tin: usPersonTin,
          us_person_exemption_type: parseInt(usPersonExemptionType),
          financial_institution: financialInstitution == 1 ? true : false,
          financial_institution_type: parseInt(financialInstitutionType),
          giin: giin,
          no_giin_type: parseInt(noGiinReason),
          sponsor_name: sponsoringEntityName,
          sponsor_giin: sponsoringEntityGIIN,
          trustee_name: trusteeName,
          trustee_giin: trusteeGIIN,
          no_giin_exemption_type: parseInt(noGiinExemptionType),
        }),
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Basic " + useAuthHeader.base64encodedData,
            service: "partners",
          },

          //withCredentials: true,
        }
      );

      getFatcaDeclaration();

      displayToast("FATCA declaration saved successfully", () => {
        setProcessing(false);
        setFatcaEdit(false);
        setEditMode(false);
      });
    } catch (err) {
      console.log(err);
      handleError(err);
      setProcessing(false);
    }
  };

  const getCrsDeclaration = async () => {
    try {
      const response = await partner.post(
        `/v1/entity/corporations/corporation/data/crs`,
        JSON.stringify({
          corporation_uuid: entityData.value.corporation_uuid,
        }),
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Basic " + useAuthHeader.base64encodedData,
            service: "partners",
          },

          //withCredentials: true,
        }
      );

      console.log(response.data);

      if (response.data.complete) {
        setCrsFinancialInstitution(response.data.financial_institution ? 1 : 2);
        setCrsFinancialInstitutionType(response.data.passive_non_financial_institution ? 2 : 1);
        setCrsPassiveNonFinancialInstitution(
          response.data.passive_non_financial_institution ? 2 : 1
        );

        setCrsFinancialInstitutionType(response.data.reporting_financial_institution ? 1 : 2);

        setCrsReportingFinancialInstitutionType(response.data.reporting_financial_institution_type);
        setCrsNonReportingFinancialInstitutionType(
          response.data.non_reporting_financial_institution
        );
        setCrsOtherNonReportingEntity(response.data.other_non_reporting_entity);
        setControllingName(response.data.controlling_person_name);
        setControllingResidentialAddress(response.data.controlling_person_residential_address);
        setControllingDateOfBirth(response.data.controlling_person_date_of_birth);
        setControllingCountryOfBirth(response.data.controlling_person_country_of_birth);
        setControllingPlaceOfBirth(response.data.controlling_person_place_of_birth);
        setControllingTin(response.data.controlling_person_tin);
        setControllerType(
          response.data.controlling_person_type > 0
            ? {
                value: response.data.controlling_person_type,
                label: controllerTypeOptions.find(
                  (x) => x.value == response.data.controlling_person_type
                ).label,
              }
            : ""
        );
      }
      setCrsDisplay(response.data);
      setCrsLoaded(true);
    } catch (err) {
      console.log(err);
      handleError(err);
    }
  };

  const handleSaveCrsDeclaration = async (e) => {
    e.preventDefault();
    setProcessing(true);

    try {
      const response = await partner.post(
        `/v1/entity/corporations/corporation/tax/crs`,
        JSON.stringify({
          corporation_uuid: entityData.value.corporation_uuid,
          financial_institution: crsFinancialInstitution == 1 ? true : false,
          financial_institution_type: parseInt(crsFinancialInstitutionType),
          passive_non_financial_institution: crsPassiveNonFinancialInstitution == 2 ? true : false,
          reporting_financial_institution_type: parseInt(crsReportingFinancialInstitutionType),
          non_reporting_financial_institution_type: parseInt(
            crsNonReportingFinancialInstitutionType
          ),
          other_non_reporting_entity: crsOtherNonReportingEntity,

          controlling_person_name: controllingName,
          controlling_person_residential_address: controllingResidentialAddress,
          controlling_person_date_of_birth: controllingDateOfBirth
            ? moment(controllingDateOfBirth).format("YYYY-MM-DD")
            : "",
          controlling_person_country_of_birth: controllingCountryOfBirth
            ? controllingCountryOfBirth.value
            : "",
          controlling_person_place_of_birth: controllingPlaceOfBirth,
          controlling_person_tin: controllingTin,
          controlling_person_type: parseInt(controllerType),
        }),
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Basic " + useAuthHeader.base64encodedData,
            service: "partners",
          },

          //withCredentials: true,
        }
      );

      getCrsDeclaration();

      displayToast("CRS declaration saved successfully", () => {
        setProcessing(false);
        setCrsEdit(false);
        setEditMode(false);
      });
    } catch (err) {
      console.log(err);
      handleError(err);
      setProcessing(false);
    }
  };

  const getTaxNumbers = async () => {
    try {
      const response = await partner.post(
        `/v1/entity/corporations/corporation/data/tax`,
        JSON.stringify({
          corporation_uuid: entityData.value.corporation_uuid,
        }),
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Basic " + useAuthHeader.base64encodedData,
            service: "partners",
          },

          //withCredentials: true,
        }
      );

      if (response.data.results > 0) {
        setTaxResidencyData(response.data.entries);
      } else {
        setTaxResidencyData([]);
      }

      setIsLoaded(true);
    } catch (err) {
      console.log(err);
      handleError(err);
    }
  };

  const handleAddTaxNumber = async (e) => {
    e.preventDefault();
    setProcessing(true);

    try {
      const response = await partner.post(
        `/v1/entity/corporations/corporation/tax/add`,
        JSON.stringify({
          corporation_uuid: entityData.value.corporation_uuid,
          tax_number: residencyTin,
          country: residencyCountry ? residencyCountry.value : "",
          code_type: 1,
        }),
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Basic " + useAuthHeader.base64encodedData,
            service: "partners",
          },

          //withCredentials: true,
        }
      );

      getTaxNumbers();

      displayToast("Details added successfully", () => {
        setProcessing(false);
        setResidencyTin("");
        setResidencyCountry();
      });
    } catch (err) {
      console.log(err);
      handleError(err);
      setProcessing(false);
    }
  };

  const handleDeleteTaxNumber = async (e) => {
    e.preventDefault();
    setProcessing(true);

    try {
      const response = await partner.post(
        `/v1/entity/corporations/corporation/tax/remove`,
        JSON.stringify({
          corporation_uuid: entityData.value.corporation_uuid,
          uuid: deleteTaxResidency,
        }),
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Basic " + useAuthHeader.base64encodedData,
            service: "partners",
          },

          //withCredentials: true,
        }
      );

      getTaxNumbers();

      setDeleteTaxResidencyDialog(false);

      displayToast("Details removed successfully", () => {
        setProcessing(false);
        setDeleteTaxResidency("");
      });
    } catch (err) {
      console.log(err);
      handleError(err);
      setProcessing(false);
    }
  };

  useEffect(() => {
    getFatcaDeclaration();
    getCrsDeclaration();
    getTaxNumbers();
  }, []);

  return isLoaded ? (
    <Grid container spacing={2}>
      <Grid item xs={12} xl={12}>
        <Box sx={{}}>
          {!editMode ? (
            <Box
              sx={{ borderRight: { xs: 0, xl: 0 }, borderColor: { xs: "divider", xl: "divider" } }}
            >
              <Box
                sx={{
                  borderBottom: 1,
                  borderColor: "divider",
                  px: 2,
                  py: 1,
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
                  <Typography variant="h6">Tax & Reporting</Typography>
                </Box>
              </Box>
              <Box sx={{}}>
                <Box>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                      backgroundColor: "#0b0b0b",
                      color: "#ffffff",
                    }}
                  >
                    <Box
                      sx={{ display: "flex", flexDirection: "column", justifyContent: "center" }}
                    >
                      <Typography variant="subtitle2" sx={{ fontSize: "16px", p: 2 }}>
                        FATCA information
                      </Typography>
                    </Box>
                    <Box
                      sx={{ display: "flex", flexDirection: "column", justifyContent: "center" }}
                    >
                      <IconButton
                        title="Edit"
                        sx={{ color: "#ffffff" }}
                        onClick={() => {
                          setCrsEdit(false);
                          setCodesEdit(false);
                          setFatcaEdit(true);
                          setEditMode(true);
                        }}
                      >
                        <Edit />
                      </IconButton>
                    </Box>
                  </Box>

                  <Divider />

                  <Box sx={{}}>
                    {fatcaLoaded ? (
                      fatcaDisplay?.complete ? (
                        <Box>
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "row",
                              justifyContent: "space-between",
                            }}
                          >
                            <Box
                              sx={{
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "center",
                              }}
                            >
                              {fatcaDisplay.entity_type == 1 && (
                                <Typography variant="subtitle2" sx={{ p: 2 }}>
                                  Specified US Person
                                </Typography>
                              )}

                              {fatcaDisplay.entity_type == 2 && (
                                <Typography variant="subtitle2" sx={{ p: 2 }}>
                                  Non specified US Person
                                </Typography>
                              )}

                              {fatcaDisplay.entity_type == 3 && (
                                <Typography variant="subtitle2" sx={{ p: 2 }}>
                                  Non US Person
                                </Typography>
                              )}
                            </Box>
                            <Box
                              sx={{
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "center",
                                px: 2,
                              }}
                            >
                              {fatcaDisplay.reportable && (
                                <Chip
                                  className="square-chip"
                                  label="Reportable"
                                  sx={{
                                    backgroundColor: "#C83E4D",
                                    color: "#ffffff",
                                    textTransform: "uppercase",
                                  }}
                                />
                              )}
                            </Box>
                          </Box>

                          {fatcaDisplay.entity_type == 1 && (
                            <Box
                              sx={{
                                borderTop: 1,
                                borderColor: "divider",
                                display: "flex",
                                justifyContent: "space-between",
                              }}
                            >
                              <Box
                                sx={{
                                  display: "flex",
                                  flexDirection: "column",
                                  justifyContent: "center",
                                }}
                              >
                                <Typography variant="subtitle" sx={{ fontSize: "13px", p: 2 }}>
                                  TIN
                                </Typography>
                              </Box>
                              <Box
                                sx={{
                                  display: "flex",
                                  flexDirection: "column",
                                  justifyContent: "center",
                                }}
                              >
                                <Typography variant="subtitle2" sx={{ fontSize: "13px", p: 2 }}>
                                  {fatcaDisplay.tin}
                                </Typography>
                              </Box>
                            </Box>
                          )}

                          {fatcaDisplay.entity_type == 2 && (
                            <Box
                              sx={{
                                borderTop: 1,
                                borderColor: "divider",
                                display: "flex",
                                justifyContent: "space-between",
                              }}
                            >
                              <Box
                                sx={{
                                  display: "flex",
                                  flexDirection: "column",
                                  justifyContent: "center",
                                  minWidth: "30%",
                                }}
                              >
                                <Typography variant="subtitle" sx={{ fontSize: "13px", p: 2 }}>
                                  Exemption
                                </Typography>
                              </Box>
                              <Box
                                sx={{
                                  display: "flex",
                                  flexDirection: "column",
                                  justifyContent: "center",
                                }}
                              >
                                <Typography variant="subtitle2" sx={{ fontSize: "13px", p: 2 }}>
                                  {fatcaDisplay.us_person_exemption_label}
                                </Typography>
                              </Box>
                            </Box>
                          )}

                          {fatcaDisplay.entity_type == 3 && fatcaDisplay.financial_institution && (
                            <Box>
                              <Box
                                sx={{
                                  borderTop: 1,
                                  borderColor: "divider",
                                  display: "flex",
                                  justifyContent: "space-between",
                                }}
                              >
                                <Box
                                  sx={{
                                    display: "flex",
                                    flexDirection: "column",
                                    justifyContent: "center",
                                    minWidth: "30%",
                                  }}
                                >
                                  <Typography variant="subtitle" sx={{ fontSize: "13px", p: 2 }}>
                                    Financial institution type
                                  </Typography>
                                </Box>
                                <Box
                                  sx={{
                                    display: "flex",
                                    flexDirection: "column",
                                    justifyContent: "center",
                                  }}
                                >
                                  <Typography variant="subtitle2" sx={{ fontSize: "13px", p: 2 }}>
                                    {fatcaDisplay.financial_institution_type_label}
                                  </Typography>
                                </Box>
                              </Box>

                              {fatcaDisplay.giin ? (
                                <Box
                                  sx={{
                                    borderTop: 1,
                                    borderColor: "divider",
                                    display: "flex",
                                    justifyContent: "space-between",
                                  }}
                                >
                                  <Box
                                    sx={{
                                      display: "flex",
                                      flexDirection: "column",
                                      justifyContent: "center",
                                      minWidth: "30%",
                                    }}
                                  >
                                    <Typography variant="subtitle" sx={{ fontSize: "13px", p: 2 }}>
                                      GIIN
                                    </Typography>
                                  </Box>
                                  <Box
                                    sx={{
                                      display: "flex",
                                      flexDirection: "column",
                                      justifyContent: "center",
                                    }}
                                  >
                                    <Typography variant="subtitle2" sx={{ fontSize: "13px", p: 2 }}>
                                      {fatcaDisplay.giin}
                                    </Typography>
                                  </Box>
                                </Box>
                              ) : (
                                <Box>
                                  <Box
                                    sx={{
                                      borderTop: 1,
                                      borderColor: "divider",
                                      display: "flex",
                                      justifyContent: "space-between",
                                    }}
                                  >
                                    <Box
                                      sx={{
                                        display: "flex",
                                        flexDirection: "column",
                                        justifyContent: "center",
                                        minWidth: "30%",
                                      }}
                                    >
                                      <Typography
                                        variant="subtitle"
                                        sx={{ fontSize: "13px", p: 2 }}
                                      >
                                        GIIN Exemption
                                      </Typography>
                                    </Box>
                                    <Box
                                      sx={{
                                        display: "flex",
                                        flexDirection: "column",
                                        justifyContent: "center",
                                      }}
                                    >
                                      <Typography
                                        variant="subtitle2"
                                        sx={{ fontSize: "13px", p: 2 }}
                                      >
                                        {fatcaDisplay.no_giin_reason_label}
                                      </Typography>
                                    </Box>
                                  </Box>

                                  {fatcaDisplay.no_giin_reason == 1 && (
                                    <Box>
                                      <Box
                                        sx={{
                                          borderTop: 1,
                                          borderColor: "divider",
                                          display: "flex",
                                          justifyContent: "space-between",
                                        }}
                                      >
                                        <Box
                                          sx={{
                                            display: "flex",
                                            flexDirection: "column",
                                            justifyContent: "center",
                                            minWidth: "30%",
                                          }}
                                        >
                                          <Typography
                                            variant="subtitle"
                                            sx={{ fontSize: "13px", p: 2 }}
                                          >
                                            GIIN Sponsor
                                          </Typography>
                                        </Box>
                                        <Box
                                          sx={{
                                            display: "flex",
                                            flexDirection: "column",
                                            justifyContent: "center",
                                          }}
                                        >
                                          <Typography
                                            variant="subtitle2"
                                            sx={{ fontSize: "13px", p: 2 }}
                                          >
                                            {fatcaDisplay.sponsor_name}
                                          </Typography>
                                        </Box>
                                      </Box>
                                      <Box
                                        sx={{
                                          borderTop: 1,
                                          borderColor: "divider",
                                          display: "flex",
                                          justifyContent: "space-between",
                                        }}
                                      >
                                        <Box
                                          sx={{
                                            display: "flex",
                                            flexDirection: "column",
                                            justifyContent: "center",
                                            minWidth: "30%",
                                          }}
                                        >
                                          <Typography
                                            variant="subtitle"
                                            sx={{ fontSize: "13px", p: 2 }}
                                          >
                                            Sponsors GIIN
                                          </Typography>
                                        </Box>
                                        <Box
                                          sx={{
                                            display: "flex",
                                            flexDirection: "column",
                                            justifyContent: "center",
                                          }}
                                        >
                                          <Typography
                                            variant="subtitle2"
                                            sx={{ fontSize: "13px", p: 2 }}
                                          >
                                            {fatcaDisplay.sponsor_giin}
                                          </Typography>
                                        </Box>
                                      </Box>
                                    </Box>
                                  )}

                                  {fatcaDisplay.no_giin_reason == 2 && (
                                    <Box>
                                      <Box
                                        sx={{
                                          borderTop: 1,
                                          borderColor: "divider",
                                          display: "flex",
                                          justifyContent: "space-between",
                                        }}
                                      >
                                        <Box
                                          sx={{
                                            display: "flex",
                                            flexDirection: "column",
                                            justifyContent: "center",
                                            minWidth: "30%",
                                          }}
                                        >
                                          <Typography
                                            variant="subtitle"
                                            sx={{ fontSize: "13px", p: 2 }}
                                          >
                                            GIIN Sponsor
                                          </Typography>
                                        </Box>
                                        <Box
                                          sx={{
                                            display: "flex",
                                            flexDirection: "column",
                                            justifyContent: "center",
                                          }}
                                        >
                                          <Typography
                                            variant="subtitle2"
                                            sx={{ fontSize: "13px", p: 2 }}
                                          >
                                            {fatcaDisplay.trustee_name}
                                          </Typography>
                                        </Box>
                                      </Box>
                                      <Box
                                        sx={{
                                          borderTop: 1,
                                          borderColor: "divider",
                                          display: "flex",
                                          justifyContent: "space-between",
                                        }}
                                      >
                                        <Box
                                          sx={{
                                            display: "flex",
                                            flexDirection: "column",
                                            justifyContent: "center",
                                            minWidth: "30%",
                                          }}
                                        >
                                          <Typography
                                            variant="subtitle"
                                            sx={{ fontSize: "13px", p: 2 }}
                                          >
                                            Sponsors GIIN
                                          </Typography>
                                        </Box>
                                        <Box
                                          sx={{
                                            display: "flex",
                                            flexDirection: "column",
                                            justifyContent: "center",
                                          }}
                                        >
                                          <Typography
                                            variant="subtitle2"
                                            sx={{ fontSize: "13px", p: 2 }}
                                          >
                                            {fatcaDisplay.trustee_giin}
                                          </Typography>
                                        </Box>
                                      </Box>
                                    </Box>
                                  )}

                                  {fatcaDisplay.no_giin_reason == 3 && (
                                    <Box
                                      sx={{
                                        borderTop: 1,
                                        borderColor: "divider",
                                        display: "flex",
                                        justifyContent: "space-between",
                                      }}
                                    >
                                      <Box
                                        sx={{
                                          display: "flex",
                                          flexDirection: "column",
                                          justifyContent: "center",
                                          minWidth: "30%",
                                        }}
                                      >
                                        <Typography
                                          variant="subtitle"
                                          sx={{ fontSize: "13px", p: 2 }}
                                        >
                                          Exemption
                                        </Typography>
                                      </Box>
                                      <Box
                                        sx={{
                                          display: "flex",
                                          flexDirection: "column",
                                          justifyContent: "center",
                                        }}
                                      >
                                        <Typography
                                          variant="subtitle2"
                                          sx={{ fontSize: "13px", p: 2 }}
                                        >
                                          {fatcaDisplay.no_giin_exemption_type_label}
                                        </Typography>
                                      </Box>
                                    </Box>
                                  )}
                                </Box>
                              )}
                            </Box>
                          )}
                        </Box>
                      ) : (
                        <Typography sx={{ fontSize: "13px", p: 2 }}>-</Typography>
                      )
                    ) : (
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          height: "",
                        }}
                      >
                        <Lottie speed={2.0} options={defaultOptions} height={100} width={200} />
                      </Box>
                    )}
                  </Box>
                </Box>

                <Divider />

                <Box>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                      backgroundColor: "#0b0b0b",
                      color: "#ffffff",
                    }}
                  >
                    <Box
                      sx={{ display: "flex", flexDirection: "column", justifyContent: "center" }}
                    >
                      <Typography variant="subtitle2" sx={{ fontSize: "16px", p: 2 }}>
                        CRS information
                      </Typography>
                    </Box>
                    <Box
                      sx={{ display: "flex", flexDirection: "column", justifyContent: "center" }}
                    >
                      <IconButton
                        title="Edit"
                        sx={{ color: "#ffffff" }}
                        onClick={() => {
                          setCrsEdit(true);
                          setCodesEdit(false);
                          setFatcaEdit(false);
                          setEditMode(true);
                        }}
                      >
                        <Edit />
                      </IconButton>
                    </Box>
                  </Box>
                  <Divider />

                  <Box sx={{}}>
                    {crsLoaded ? (
                      <Box>
                        {crsDisplay?.complete ? (
                          <Box>
                            <Box
                              sx={{
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "space-between",
                              }}
                            >
                              <Box
                                sx={{
                                  display: "flex",
                                  flexDirection: "column",
                                  justifyContent: "center",
                                }}
                              >
                                {crsDisplay.financial_institution ? (
                                  <Typography variant="subtitle2" sx={{ p: 2 }}>
                                    The entity is a Financial Institution
                                  </Typography>
                                ) : (
                                  <Typography variant="subtitle2" sx={{ p: 2 }}>
                                    The entity is not a Financial Institution
                                  </Typography>
                                )}
                              </Box>
                              <Box
                                sx={{
                                  display: "flex",
                                  flexDirection: "column",
                                  justifyContent: "center",
                                  px: 2,
                                }}
                              ></Box>
                            </Box>

                            {crsDisplay.financial_institution && (
                              <Box>
                                <Box
                                  sx={{
                                    borderTop: 1,
                                    borderColor: "divider",
                                    display: "flex",
                                    justifyContent: "space-between",
                                  }}
                                >
                                  <Box
                                    sx={{
                                      display: "flex",
                                      flexDirection: "column",
                                      justifyContent: "center",
                                    }}
                                  >
                                    <Typography variant="subtitle" sx={{ fontSize: "13px", p: 2 }}>
                                      Reporting status
                                    </Typography>
                                  </Box>
                                  <Box
                                    sx={{
                                      display: "flex",
                                      flexDirection: "column",
                                      justifyContent: "center",
                                    }}
                                  >
                                    <Typography variant="subtitle2" sx={{ fontSize: "13px", p: 2 }}>
                                      {crsDisplay.reporting_financial_institution
                                        ? "Reporting"
                                        : "Non-reporting"}
                                    </Typography>
                                  </Box>
                                </Box>
                                <Box
                                  sx={{
                                    borderTop: 1,
                                    borderColor: "divider",
                                    display: "flex",
                                    justifyContent: "space-between",
                                  }}
                                >
                                  <Box
                                    sx={{
                                      display: "flex",
                                      flexDirection: "column",
                                      justifyContent: "center",
                                    }}
                                  >
                                    <Typography variant="subtitle" sx={{ fontSize: "13px", p: 2 }}>
                                      Institution type
                                    </Typography>
                                  </Box>
                                  <Box
                                    sx={{
                                      display: "flex",
                                      flexDirection: "column",
                                      justifyContent: "center",
                                    }}
                                  >
                                    <Typography variant="subtitle2" sx={{ fontSize: "13px", p: 2 }}>
                                      {crsDisplay.reporting_financial_institution
                                        ? crsDisplay.reporting_financial_institution_type_label
                                        : crsDisplay.non_reporting_financial_institution_type_label}
                                    </Typography>
                                    {!crsDisplay.reporting_financial_institution &&
                                      crsDisplay.non_reporting_financial_institution == 9 && (
                                        <Typography
                                          variant="subtitle2"
                                          sx={{ fontSize: "13px", p: 2, pt: 0 }}
                                        >
                                          {
                                            crsDisplay.non_reporting_financial_institution_type_other
                                          }
                                        </Typography>
                                      )}
                                  </Box>
                                </Box>
                              </Box>
                            )}
                          </Box>
                        ) : (
                          <Typography sx={{ fontSize: "13px", p: 2 }}>-</Typography>
                        )}
                      </Box>
                    ) : (
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          height: "",
                        }}
                      >
                        <Lottie speed={2.0} options={defaultOptions} height={100} width={200} />
                      </Box>
                    )}
                  </Box>
                </Box>

                <Divider />

                <Box>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                      backgroundColor: "#0b0b0b",
                      color: "#ffffff",
                    }}
                  >
                    <Box
                      sx={{ display: "flex", flexDirection: "column", justifyContent: "center" }}
                    >
                      <Typography variant="subtitle2" sx={{ fontSize: "16px", p: 2 }}>
                        Tax identifiers
                      </Typography>
                    </Box>
                    <Box
                      sx={{ display: "flex", flexDirection: "column", justifyContent: "center" }}
                    >
                      <IconButton
                        title="Edit"
                        sx={{ color: "#ffffff" }}
                        onClick={() => {
                          setCrsEdit(false);
                          setCodesEdit(true);
                          setFatcaEdit(false);
                          setEditMode(true);
                        }}
                      >
                        <Edit />
                      </IconButton>
                    </Box>
                  </Box>
                  <Divider />

                  <DataTable
                    columns={columns}
                    data={taxResidencyData}
                    customStyles={customStyles}
                    striped
                    noTableHead={true}
                    noDataComponent={
                      <Box sx={{ width: "100%", px: 2, py: 1 }}>
                        <Typography sx={{ textAlign: "left" }} variant="subtitle2">
                          -
                        </Typography>
                      </Box>
                    }
                  />
                </Box>
              </Box>
            </Box>
          ) : (
            <Box
              sx={{ borderRight: { xs: 0, xl: 0 }, borderColor: { xs: "divider", xl: "divider" } }}
            >
              {fatcaEdit && (
                <Box sx={{}}>
                  <Box
                    sx={{
                      borderBottom: 1,
                      borderColor: "divider",
                      px: 2,
                      py: 1,
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <Box
                      sx={{ display: "flex", flexDirection: "column", justifyContent: "center" }}
                    >
                      <Typography variant="h6">FATCA information</Typography>
                    </Box>
                    <Box>
                      <IconButton title="Close" onClick={() => setEditMode(false)}>
                        <Close />
                      </IconButton>
                    </Box>
                  </Box>
                  <Box sx={{ display: "flex", flexDirection: "column" }}>
                    <Box>
                      <Box sx={{ p: 2 }}>
                        <Typography variant="subtitle" sx={{ fontSize: "13px" }}>
                          FATCA is a US law that requires financial institutions to identify their
                          US customers and report information about financial accounts held by US
                          persons either directly or through certain foreign entities. FATCA
                          requires financial institutions to report on the foreign assets held by
                          their US account holders or be subject to withholding on withholdable
                          payments.
                        </Typography>
                      </Box>
                      <Divider />
                      <Box sx={{ p: 2 }}>
                        <FormControl>
                          <RadioGroup
                            aria-labelledby="fatca-type-options-label"
                            defaultValue={0}
                            value={entityType}
                            onChange={(e) => setEntityType(e.target.value)}
                            name="radio-buttons-group"
                          >
                            <FormControlLabel
                              sx={{ mb: 1 }}
                              value={1}
                              control={<Radio />}
                              label="The entity is a Specified US Person"
                            />
                            <FormControlLabel
                              sx={{ mb: 1 }}
                              value={2}
                              control={<Radio />}
                              label="The entity is a US Person, that is not a Specified US Person"
                            />
                            <FormControlLabel
                              sx={{ mb: 1 }}
                              value={3}
                              control={<Radio />}
                              label="The entity is not considered a US Person"
                            />
                          </RadioGroup>
                        </FormControl>
                      </Box>
                    </Box>

                    <Collapse in={entityType == 1} exit={entityType != 1}>
                      <Box>
                        <Box sx={{ borderBottom: 1, borderTop: 1, borderColor: "divider" }}>
                          <Box sx={{ p: 2 }}>
                            <Typography variant="h6">Specified US Person</Typography>
                            <Typography variant="subtitle" sx={{ fontSize: "13px" }}>
                              A Specified US Person under the FATCA (Foreign Account Tax Compliance
                              Act) rules typically includes any US corporation or entity that is not
                              specifically excluded by the legislation. This can be characterized as
                              a corporation that is created or organised in the United States or
                              under the laws of the United States or any state therein, excluding
                              entities such as foreign governments, international organizations,
                              foreign central banks of issue, and others specifically exempted by
                              the FATCA regulations. These entities are subject to certain reporting
                              requirements for their financial accounts and offshore assets to
                              ensure compliance with US tax laws and regulations.
                            </Typography>
                          </Box>
                        </Box>

                        <Box sx={{ p: 2, display: "flex", flexDirection: "column", gap: 1 }}>
                          <Typography variant="subtitle2">
                            Provide the Tax identification number (TIN)
                          </Typography>
                          <TextField
                            fullWidth
                            label="TIN"
                            variant="outlined"
                            value={usPersonTin}
                            onChange={(e) => setUsPersonTin(e.target.value)}
                          />
                        </Box>
                      </Box>
                    </Collapse>

                    <Collapse in={entityType == 2} exit={entityType != 2}>
                      <Box>
                        <Box sx={{ borderBottom: 1, borderTop: 1, borderColor: "divider" }}>
                          <Box sx={{ p: 2 }}>
                            <Typography variant="h6">US Person (Exemption)</Typography>
                            <Typography variant="subtitle" sx={{ fontSize: "13px" }}>
                              Please select the option that describes how the entity is exempt from
                              being a Specified US Person.
                            </Typography>
                          </Box>
                        </Box>

                        <Box sx={{ p: 2 }}>
                          <FormControl>
                            <RadioGroup
                              aria-labelledby="fatca-exemptions-options-label"
                              defaultValue={0}
                              value={usPersonExemptionType}
                              onChange={(e) => setUsPersonExemptionType(e.target.value)}
                              name="radio-buttons-group"
                            >
                              <FormControlLabel
                                sx={{ mb: 1 }}
                                value={1}
                                control={<Radio />}
                                label="An organisation exempt from tax under section 501(a), or any individual retirement plan as defined in section 7701(a)(37)"
                              />
                              <FormControlLabel
                                sx={{ mb: 1 }}
                                value={2}
                                control={<Radio />}
                                label="The United States or any of its agencies or instrumentalities"
                              />
                              <FormControlLabel
                                sx={{ mb: 1 }}
                                value={3}
                                control={<Radio />}
                                label="A state, the District of Columbia, a U.S. commonwealth or possession, or any of their political subdivisions, agencies, or instrumentalities"
                              />
                              <FormControlLabel
                                sx={{ mb: 1 }}
                                value={4}
                                control={<Radio />}
                                label="A corporation the stock of which is regularly traded on one or more established securities markets, as described in Regulations 1.1472-1(c)(1)(i)"
                              />
                              <FormControlLabel
                                sx={{ mb: 1 }}
                                value={5}
                                control={<Radio />}
                                label="A state, the District of Columbia, a US territory, a political subdivision of any of the foregoing, or a wholly owned agency or instrumentality of any one or more of the foregoing"
                              />
                              <FormControlLabel
                                sx={{ mb: 1 }}
                                value={6}
                                control={<Radio />}
                                label="A corporation that is a member of the same expanded affiliated group as a corporation described in Regulations 1.1472-1(c)(1)(i)"
                              />
                              <FormControlLabel
                                sx={{ mb: 1 }}
                                value={7}
                                control={<Radio />}
                                label="A dealer in securities, commodities, or derivative financial instruments (including notional principal contracts, futures, forwards, and options) that is registered as such under the laws of the United States or any State"
                              />
                              <FormControlLabel
                                sx={{ mb: 1 }}
                                value={8}
                                control={<Radio />}
                                label="A real estate investment trust"
                              />
                              <FormControlLabel
                                sx={{ mb: 1 }}
                                value={9}
                                control={<Radio />}
                                label="A regulated investment company as defined in section 851 or an entity registered at all times during the tax year under the Investment Company Act of 1940"
                              />
                              <FormControlLabel
                                sx={{ mb: 1 }}
                                value={10}
                                control={<Radio />}
                                label="A common trust fund as defined in section 584(a)"
                              />
                              <FormControlLabel
                                sx={{ mb: 1 }}
                                value={11}
                                control={<Radio />}
                                label="A bank as defined in section 581"
                              />
                              <FormControlLabel
                                sx={{ mb: 1 }}
                                value={12}
                                control={<Radio />}
                                label="A broker"
                              />
                              <FormControlLabel
                                sx={{ mb: 1 }}
                                value={13}
                                control={<Radio />}
                                label="A trust exempt from tax under section 664 or described in section 4947"
                              />
                              <FormControlLabel
                                sx={{ mb: 1 }}
                                value={14}
                                control={<Radio />}
                                label="A tax-exempt trust under a section 403(b) plan or section 457(g) plan"
                              />
                            </RadioGroup>
                          </FormControl>
                        </Box>
                      </Box>
                    </Collapse>

                    <Collapse in={entityType == 3} exit={entityType != 3}>
                      <Box>
                        <Box sx={{ borderBottom: 1, borderTop: 1, borderColor: "divider" }}>
                          <Box sx={{ p: 2 }}>
                            <Typography variant="h6">Non US Person</Typography>
                            <Typography variant="subtitle" sx={{ fontSize: "13px" }}>
                              Please complete the questions below.
                            </Typography>
                          </Box>
                        </Box>

                        <Box sx={{ p: 2 }}>
                          <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
                            <Typography variant="subtitle2">
                              Is the entity is a Registered Financial Institution?
                            </Typography>
                            <FormControl>
                              <RadioGroup
                                aria-labelledby="fatca-three-point-one-label"
                                defaultValue={0}
                                value={financialInstitution}
                                onChange={(e) => setFinancialInstitution(e.target.value)}
                                name="radio-buttons-group"
                              >
                                <FormControlLabel
                                  sx={{ mb: 1 }}
                                  value={1}
                                  control={<Radio />}
                                  label="Yes"
                                />
                                <FormControlLabel
                                  sx={{ mb: 1 }}
                                  value={0}
                                  control={<Radio />}
                                  label="No"
                                />
                              </RadioGroup>
                            </FormControl>
                          </Box>

                          <Collapse in={financialInstitution == 1} exit={financialInstitution == 0}>
                            <Typography variant="subtitle2">
                              Select an option that describes the entity and provide the Global
                              intermediary identification number (GIIN)
                            </Typography>

                            <FormControl>
                              <RadioGroup
                                aria-labelledby="fatca-three-point-one-label"
                                defaultValue={0}
                                value={financialInstitutionType}
                                onChange={(e) => setFinancialInstitutionType(e.target.value)}
                                name="radio-buttons-group"
                              >
                                <FormControlLabel
                                  sx={{ mb: 1 }}
                                  value={1}
                                  control={<Radio />}
                                  label="Isle of Man or IGA Partner Jurisdiction Financial Institution"
                                />
                                <FormControlLabel
                                  sx={{ mb: 1 }}
                                  value={2}
                                  control={<Radio />}
                                  label="Registered Deemed Compliant Foreign Financial Institution"
                                />
                                <FormControlLabel
                                  sx={{ mb: 1 }}
                                  value={3}
                                  control={<Radio />}
                                  label="Participating Foreign Financial Institution"
                                />
                              </RadioGroup>
                            </FormControl>

                            <Box sx={{ display: "flex", flexDirection: "column", gap: 1, mt: 2 }}>
                              <Typography variant="subtitle2">
                                Provide the Global intermediary identification number (GIIN)
                              </Typography>
                              <TextField
                                fullWidth
                                label="GIIN"
                                variant="outlined"
                                value={giin}
                                onChange={(e) => setGiin(e.target.value)}
                              />
                            </Box>

                            <Collapse
                              in={financialInstitution == 1 && giin == ""}
                              exit={financialInstitution == 2 && giin != ""}
                            >
                              <Box sx={{ display: "flex", flexDirection: "column", gap: 1, mt: 2 }}>
                                <Typography variant="subtitle2">
                                  If the entity is a Financial Institution but unable to provide a
                                  GIIN, please select one of the reasons below
                                </Typography>

                                <FormControl>
                                  <RadioGroup
                                    aria-labelledby="fatca-three-point-one-label"
                                    defaultValue={0}
                                    value={noGiinReason}
                                    onChange={(e) => setNoGiinReason(e.target.value)}
                                    name="radio-buttons-group"
                                  >
                                    <FormControlLabel
                                      sx={{ mb: 1 }}
                                      value={1}
                                      control={<Radio />}
                                      label="The entity is a Sponsored Financial Institution (including a Sponsored Investment Entity and Sponsored Closely Held Investment Vehicle) and has not yet obtained a GIIN but is sponsored by another entity that has registered as a Sponsoring Entity"
                                    />
                                    <FormControlLabel
                                      sx={{ mb: 1 }}
                                      value={2}
                                      control={<Radio />}
                                      label="The entity is a Trustee Documented Trust"
                                    />
                                    <FormControlLabel
                                      sx={{ mb: 1 }}
                                      value={3}
                                      control={<Radio />}
                                      label="The entity is a Certified Deemed Compliant, or other Non-Reporting Foreign Financial Institution (including a Foreign Financial Institution deemed compliant under Annex II of an IGA, except for a Trustee Documented Trust or Sponsored Financial Institution)."
                                    />
                                    <FormControlLabel
                                      sx={{ mb: 1 }}
                                      value={4}
                                      control={<Radio />}
                                      label="The entity is an Owner Documented Financial Institution"
                                    />
                                    <FormControlLabel
                                      sx={{ mb: 1 }}
                                      value={5}
                                      control={<Radio />}
                                      label="The entity is a Non-Participating Foreign Financial Institution"
                                    />
                                  </RadioGroup>
                                </FormControl>

                                <Collapse in={noGiinReason == 1} exit={noGiinReason != 1}>
                                  <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
                                    <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
                                      <Typography variant="subtitle2">
                                        Provide the sponsoring entities name
                                      </Typography>
                                      <TextField
                                        fullWidth
                                        label="Sponsoring entities name"
                                        variant="outlined"
                                        value={sponsoringEntityName}
                                        onChange={(e) => setSponsoringEntityName(e.target.value)}
                                      />
                                    </Box>
                                    <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
                                      <Typography variant="subtitle2">
                                        Provide the sponsoring entities Global intermediary
                                        identification number (GIIN)
                                      </Typography>
                                      <TextField
                                        fullWidth
                                        label="Sponsoring entities GIIN"
                                        variant="outlined"
                                        value={sponsoringEntityGIIN}
                                        onChange={(e) => setSponsoringEntityGIIN(e.target.value)}
                                      />
                                    </Box>
                                  </Box>
                                </Collapse>

                                <Collapse in={noGiinReason == 2} exit={noGiinReason != 2}>
                                  <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
                                    <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
                                      <Typography variant="subtitle2">
                                        Provide the trustees name
                                      </Typography>
                                      <TextField
                                        fullWidth
                                        label="Trustees name"
                                        variant="outlined"
                                        value={trusteeName}
                                        onChange={(e) => setTrusteeName(e.target.value)}
                                      />
                                    </Box>
                                    <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
                                      <Typography variant="subtitle2">
                                        Provide the trustees Global intermediary identification
                                        number (GIIN)
                                      </Typography>
                                      <TextField
                                        fullWidth
                                        label="Trustees GIIN"
                                        variant="outlined"
                                        value={trusteeGIIN}
                                        onChange={(e) => setTrusteeGIIN(e.target.value)}
                                      />
                                    </Box>
                                  </Box>
                                </Collapse>

                                <Collapse in={noGiinReason == 3} exit={noGiinReason != 3}>
                                  <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
                                    <Typography variant="subtitle2">
                                      Select the reason for exemption
                                    </Typography>
                                    <FormControl>
                                      <RadioGroup
                                        aria-labelledby="fatca-three-point-two-exemption-label"
                                        defaultValue={0}
                                        value={noGiinExemptionType}
                                        onChange={(e) => setNoGiinExemptionType(e.target.value)}
                                        name="radio-buttons-group"
                                      >
                                        <FormControlLabel
                                          sx={{ mb: 1 }}
                                          value={1}
                                          control={<Radio />}
                                          label="The entity is a Certified Deemed Compliant Foreign Financial Institution"
                                        />
                                        <FormControlLabel
                                          sx={{ mb: 1 }}
                                          value={2}
                                          control={<Radio />}
                                          label="The entity is a Non-Participating Foreign Financial Institution"
                                        />
                                      </RadioGroup>
                                    </FormControl>
                                  </Box>
                                </Collapse>
                              </Box>
                            </Collapse>
                          </Collapse>
                        </Box>
                      </Box>
                    </Collapse>

                    <Divider />

                    <Box
                      sx={{
                        p: 2,
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "flex-end",
                      }}
                    >
                      <LoadingButton
                        disableElevation
                        variant="contained"
                        color="success"
                        onClick={(e) => {
                          handleSaveFatcaDeclaration(e);
                        }}
                        loading={processing}
                      >
                        Save declaration
                      </LoadingButton>
                    </Box>
                  </Box>
                </Box>
              )}

              {codesEdit && (
                <Box sx={{}}>
                  <Box
                    sx={{
                      borderBottom: 1,
                      borderColor: "divider",
                      px: 2,
                      py: 1,
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <Box
                      sx={{ display: "flex", flexDirection: "column", justifyContent: "center" }}
                    >
                      <Typography variant="h6">Tax registration information</Typography>
                    </Box>
                    <Box>
                      <IconButton title="Close" onClick={() => setEditMode(false)}>
                        <Close />
                      </IconButton>
                    </Box>
                  </Box>

                  <Box sx={{ p: 2 }}>
                    <Typography variant="subtitle" sx={{ fontSize: "13px" }}>
                      Please provide the tax residence information for the entity.
                    </Typography>
                  </Box>

                  <Divider />

                  <Box sx={{ p: 2, display: "flex", flexDirection: "column", gap: 2 }}>
                    <CountrySelect value={residencyCountry} setValue={setResidencyCountry} />

                    <TextField
                      fullWidth
                      label="Tax identification number (TIN)"
                      variant="outlined"
                      value={residencyTin}
                      onChange={(e) => setResidencyTin(e.target.value)}
                    />

                    <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "flex-end" }}>
                      <LoadingButton
                        disableElevation
                        variant="contained"
                        color="success"
                        onClick={(e) => handleAddTaxNumber(e)}
                        loading={processing}
                      >
                        Save details
                      </LoadingButton>
                    </Box>
                  </Box>

                  <Divider />
                  <Box>
                    <Typography variant="subtitle2" sx={{ fontSize: "13px", p: 2 }}>
                      Residency information
                    </Typography>
                    <Divider />

                    <DataTable
                      columns={manageColumns}
                      data={taxResidencyData}
                      customStyles={customStyles}
                      striped
                      noTableHead={true}
                      noDataComponent={
                        <Box sx={{ width: "100%", px: 2, py: 1 }}>
                          <Typography sx={{ textAlign: "left" }} variant="subtitle2">
                            -
                          </Typography>
                        </Box>
                      }
                    />
                  </Box>

                  <Dialog
                    open={deleteTaxResidencyDialog}
                    onClose={() => setDeleteTaxResidencyDialog(false)}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                  >
                    <DialogTitle id="alert-dialog-title">Are you sure?</DialogTitle>
                    <DialogContent>
                      <Box>
                        <DialogContentText sx={{ mb: 1 }} id="alert-dialog-description">
                          Are you sure you want to delete the tax registration details for{" "}
                          {deleteTaxResidencyCountry}?
                        </DialogContentText>
                      </Box>
                    </DialogContent>
                    <DialogActions>
                      <LoadingButton
                        disableElevation
                        loading={processing}
                        onClick={() => setDeleteTaxResidencyDialog(false)}
                        color="primary"
                      >
                        Cancel
                      </LoadingButton>

                      <LoadingButton
                        disableElevation
                        sx={{ color: "#ffffff" }}
                        loading={processing}
                        onClick={(e) => {
                          handleDeleteTaxNumber(e);
                        }}
                        variant="contained"
                        color="danger"
                        autoFocus
                      >
                        Confirm
                      </LoadingButton>
                    </DialogActions>
                  </Dialog>
                </Box>
              )}

              {crsEdit && (
                <Box sx={{}}>
                  <Box
                    sx={{
                      borderBottom: 1,
                      borderColor: "divider",
                      px: 2,
                      py: 1,
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <Box
                      sx={{ display: "flex", flexDirection: "column", justifyContent: "center" }}
                    >
                      <Typography variant="h6">CRS classification</Typography>
                    </Box>
                    <Box>
                      <IconButton title="Close" onClick={() => setEditMode(false)}>
                        <Close />
                      </IconButton>
                    </Box>
                  </Box>

                  <Box sx={{ p: 2 }}>
                    <Typography variant="subtitle" sx={{ fontSize: "13px" }}>
                      The Common Reporting Standard (CRS) is a global standard for the automatic
                      exchange of financial account information between tax authorities to help
                      prevent tax evasion and ensure that everyone pays the correct amount of tax.
                      The CRS is based on the model of the Foreign Account Tax Compliance Act
                      (FATCA) developed by the Organisation for Economic Co-operation and
                      Development (OECD).
                    </Typography>
                  </Box>

                  <Box sx={{ p: 2, pt: 0 }}>
                    <FormControl>
                      <RadioGroup
                        aria-labelledby="fatca-type-options-label"
                        defaultValue={0}
                        value={crsFinancialInstitution}
                        onChange={(e) => setCrsFinancialInstitution(e.target.value)}
                        name="radio-buttons-group"
                      >
                        <FormControlLabel
                          sx={{ mb: 1 }}
                          value={1}
                          control={<Radio />}
                          label="The entity is a financial institution"
                        />
                        <FormControlLabel
                          sx={{ mb: 1 }}
                          value={2}
                          control={<Radio />}
                          label="The entity is not a financial institution"
                        />
                      </RadioGroup>
                    </FormControl>
                  </Box>

                  <Collapse in={crsFinancialInstitution == 1} exit={crsFinancialInstitution != 1}>
                    <Box sx={{ p: 2, pt: 0 }}>
                      <Typography variant="subtitle2">
                        Is the entity a reporting or non-reporting type of financial institution
                      </Typography>

                      <FormControl>
                        <RadioGroup
                          aria-labelledby="fatca-type-options-label"
                          defaultValue={0}
                          value={crsFinancialInstitutionType}
                          onChange={(e) => setCrsFinancialInstitutionType(e.target.value)}
                          name="radio-buttons-group"
                        >
                          <FormControlLabel
                            sx={{ mb: 1 }}
                            value={1}
                            control={<Radio />}
                            label="Reporting financial institution"
                          />
                          <FormControlLabel
                            sx={{ mb: 1 }}
                            value={2}
                            control={<Radio />}
                            label="Non-reporting financial institution"
                          />
                        </RadioGroup>
                      </FormControl>
                    </Box>

                    <Collapse
                      in={crsFinancialInstitutionType == 1}
                      exit={crsFinancialInstitutionType != 1}
                    >
                      <Box sx={{ p: 2, pt: 0, display: "flex", flexDirection: "column", gap: 1 }}>
                        <Typography variant="subtitle2">
                          Select the type of reporting financial institution
                        </Typography>
                        <FormControl>
                          <RadioGroup
                            aria-labelledby="fatca-type-options-label"
                            defaultValue={0}
                            value={crsReportingFinancialInstitutionType}
                            onChange={(e) =>
                              setCrsReportingFinancialInstitutionType(e.target.value)
                            }
                            name="radio-buttons-group"
                          >
                            <FormControlLabel
                              sx={{ mb: 1 }}
                              value={1}
                              control={<Radio />}
                              label="Depository institution"
                            />
                            <FormControlLabel
                              sx={{ mb: 1 }}
                              value={2}
                              control={<Radio />}
                              label="Custodial institution"
                            />
                            <FormControlLabel
                              sx={{ mb: 1 }}
                              value={3}
                              control={<Radio />}
                              label="Investment entity"
                            />
                            <FormControlLabel
                              sx={{ mb: 1 }}
                              value={4}
                              control={<Radio />}
                              label="Managed investment entity"
                            />
                            <FormControlLabel
                              sx={{ mb: 1 }}
                              value={5}
                              control={<Radio />}
                              label="Specified insurance company"
                            />
                          </RadioGroup>
                        </FormControl>
                      </Box>

                      <Collapse
                        in={crsReportingFinancialInstitutionType == 4}
                        exit={crsReportingFinancialInstitutionType != 4}
                      >
                        <Box sx={{ p: 2, borderTop: 1, borderBottom: 1, borderColor: "divider" }}>
                          <Typography variant="h6">Controlling person</Typography>
                        </Box>

                        <Box sx={{ p: 2, display: "flex", flexDirection: "column", gap: 1 }}>
                          <Typography>Name</Typography>
                          <Typography>Residential address</Typography>
                          <Typography>Mainling address (optional)</Typography>
                          <Typography>Date of birth</Typography>
                          <Typography>Country of birth</Typography>
                          <Typography>Town or city of birth</Typography>

                          <Typography>Tax residences with TIN</Typography>

                          <Typography>Type of controlling person (options)</Typography>
                        </Box>
                      </Collapse>
                    </Collapse>

                    <Collapse
                      in={crsFinancialInstitutionType == 2}
                      exit={crsFinancialInstitutionType != 2}
                    >
                      <Box sx={{ p: 2, pt: 0, display: "flex", flexDirection: "column", gap: 1 }}>
                        <Typography variant="subtitle2">
                          Select the type of non-reporting financial institution
                        </Typography>
                        <FormControl>
                          <RadioGroup
                            aria-labelledby="fatca-type-options-label"
                            defaultValue={0}
                            value={crsNonReportingFinancialInstitutionType}
                            onChange={(e) =>
                              setCrsNonReportingFinancialInstitutionType(e.target.value)
                            }
                            name="radio-buttons-group"
                          >
                            <FormControlLabel
                              sx={{ mb: 1 }}
                              value={1}
                              control={<Radio />}
                              label="Government entity"
                            />
                            <FormControlLabel
                              sx={{ mb: 1 }}
                              value={2}
                              control={<Radio />}
                              label="International organisation"
                            />
                            <FormControlLabel
                              sx={{ mb: 1 }}
                              value={3}
                              control={<Radio />}
                              label="Central bank"
                            />
                            <FormControlLabel
                              sx={{ mb: 1 }}
                              value={4}
                              control={<Radio />}
                              label="Broad participation retirement fund"
                            />
                            <FormControlLabel
                              sx={{ mb: 1 }}
                              value={5}
                              control={<Radio />}
                              label="Narrow participation retirement fund"
                            />
                            <FormControlLabel
                              sx={{ mb: 1 }}
                              value={10}
                              control={<Radio />}
                              label="Pension fund of a governmental entity, international organisation or central bank"
                            />
                            <FormControlLabel
                              sx={{ mb: 1 }}
                              value={6}
                              control={<Radio />}
                              label="Exempt collective investment vehicle"
                            />
                            <FormControlLabel
                              sx={{ mb: 1 }}
                              value={7}
                              control={<Radio />}
                              label="Trustee documented trust"
                            />
                            <FormControlLabel
                              sx={{ mb: 1 }}
                              value={8}
                              control={<Radio />}
                              label="Qualified credit card issuer"
                            />
                            <FormControlLabel
                              sx={{ mb: 1 }}
                              value={9}
                              control={<Radio />}
                              label="Other entity defined under domestic law as low risk of being used to evade tax"
                            />
                          </RadioGroup>
                        </FormControl>

                        <Collapse
                          in={crsNonReportingFinancialInstitutionType == 9}
                          exit={crsNonReportingFinancialInstitutionType != 9}
                        >
                          <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
                            <Typography variant="subtitle2">
                              Provide the type of financial institution
                            </Typography>
                            <TextField
                              fullWidth
                              label="Institution type"
                              variant="outlined"
                              value={crsOtherNonReportingEntity}
                              onChange={(e) => setCrsOtherNonReportingEntity(e.target.value)}
                            />
                          </Box>
                        </Collapse>
                      </Box>
                    </Collapse>
                  </Collapse>

                  <Collapse in={crsFinancialInstitution == 2} exit={crsFinancialInstitution != 2}>
                    <Box sx={{ p: 2, pt: 0 }}>
                      <Typography variant="subtitle2">
                        Is the entity an active or passive non-financial institution
                      </Typography>

                      <FormControl>
                        <RadioGroup
                          aria-labelledby="fatca-type-options-label"
                          defaultValue={0}
                          value={crsPassiveNonFinancialInstitution}
                          onChange={(e) => setCrsPassiveNonFinancialInstitution(e.target.value)}
                          name="radio-buttons-group"
                        >
                          <FormControlLabel
                            sx={{ mb: 1 }}
                            value={1}
                            control={<Radio />}
                            label="Active non-financial institution"
                          />
                          <FormControlLabel
                            sx={{ mb: 1 }}
                            value={2}
                            control={<Radio />}
                            label="Passive non-financial institution"
                          />
                        </RadioGroup>
                      </FormControl>
                    </Box>

                    <Collapse
                      in={crsPassiveNonFinancialInstitution == 2}
                      exit={crsPassiveNonFinancialInstitution != 2}
                    >
                      <Box sx={{ p: 2, borderTop: 1, borderBottom: 1, borderColor: "divider" }}>
                        <Typography variant="h6">Controlling person</Typography>
                      </Box>

                      <Box sx={{ p: 2, display: "flex", flexDirection: "column", gap: 2 }}>
                        <TextField
                          fullWidth
                          label="Name"
                          variant="outlined"
                          value={controllingName}
                          onChange={(e) => setControllingName(e.target.value)}
                        />

                        <TextField
                          fullWidth
                          label="Residential address"
                          variant="outlined"
                          value={controllingResidentialAddress}
                          onChange={(e) => setControllingResidentialAddress(e.target.value)}
                        />

                        <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale={locale}>
                          <DatePicker
                            fullWidth
                            openTo="year"
                            label={`Date of birth`}
                            value={controllingDateOfBirth ? controllingDateOfBirth : null}
                            onChange={(date) => {
                              setControllingDateOfBirth(date);
                            }}
                            slotProps={{ textField: { fullWidth: true } }}
                            maxDate={moment()}
                          />
                        </LocalizationProvider>

                        <CountrySelect
                          value={controllingCountryOfBirth}
                          setValue={setControllingCountryOfBirth}
                        />

                        <TextField
                          fullWidth
                          label="Town or city of birth"
                          variant="outlined"
                          value={controllingPlaceOfBirth}
                          onChange={(e) => setControllingPlaceOfBirth(e.target.value)}
                        />

                        <TextField
                          fullWidth
                          label="Tax identification number (TIN)"
                          variant="outlined"
                          value={controllingTin}
                          onChange={(e) => setControllingTin(e.target.value)}
                        />

                        <FormControl fullWidth>
                          <InputLabel id="controlling-person-type-label">
                            Type of controlling person
                          </InputLabel>

                          <Select
                            labelId="controlling-person-type-label"
                            id="controlling-person-type-select"
                            value={controllerType}
                            label="Type of controlling person"
                            onChange={(e) => setControllerType(e.target.value)}
                          >
                            {controllerTypeOptions?.map((option, index) => (
                              <MenuItem key={index} value={option.value}>
                                {option.label}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </Box>
                    </Collapse>
                  </Collapse>

                  <Divider />
                  <Box sx={{ p: 2, display: "flex", justifyContent: "flex-end" }}>
                    <LoadingButton
                      disableElevation
                      variant="contained"
                      color="success"
                      onClick={(e) => handleSaveCrsDeclaration(e)}
                      loading={processing}
                    >
                      Save details
                    </LoadingButton>
                  </Box>
                </Box>
              )}
            </Box>
          )}
        </Box>
      </Grid>
    </Grid>
  ) : (
    <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", height: "50vh" }}>
      <Lottie speed={2.0} options={defaultOptions} height={200} width={200} />
    </Box>
  );
};

export default Fatca;
