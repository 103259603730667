import { React, useEffect, useState } from "react";

import authHeader from "../../../../../api/authHeader";

import HandleError from "../../../../../components/functions/HandleError";
import HandleToast from "../../../../../components/functions/HandleToast";

import Lottie from "react-lottie";
import loadingLottie from "../../../../../components/lotties/loading-dots.json";

import DataTable from "react-data-table-component";

import { Typography, Divider, Box, Button, Chip, Link, Fade } from "@mui/material";
import { Close } from "@mui/icons-material";

import NewTemplate from "./components/edd/NewTemplate";
import ManageTemplate from "./components/edd/ManageTemplate";
import PreviewForm from "./components/edd/PreviewForm";

const EnhancedDueDiligence = ({ partner, partnerUser, partnerData }) => {
  const useAuthHeader = authHeader();

  const handleError = HandleError();
  const displayToast = HandleToast();

  const [isLoaded, setIsLoaded] = useState(false);
  const [processing, setProcessing] = useState(false);

  const [data, setData] = useState([]);

  const [newTemplate, setNewTemplate] = useState(false);
  const [manageTemplate, setManageTemplate] = useState(false);
  const [activeTemplate, setActiveTemplate] = useState("");
  const [previewForm, setPreviewForm] = useState(false);

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: loadingLottie,
  };

  const customStyles = {
    headCells: {
      style: {
        paddingLeft: "8px", // override the cell padding for head cells
        paddingRight: "8px",
        fontWeight: "bold",
      },
    },
    cells: {
      style: {
        paddingLeft: "8px", // override the cell padding for data cells
        paddingRight: "8px",
      },
    },
  };

  const columns = [
    {
      name: "Template name",
      sortable: true,
      width: "75%",
      selector: (row) => row.name,
      cell: (row) => (
        <Box
          className="link"
          onClick={() => {
            setActiveTemplate(row.uuid);
            setManageTemplate(true);
          }}
          sx={{ fontSize: "13px" }}
        >
          {row.name}
        </Box>
      ),
    },
    {
      name: "Status",
      right: "true",
      selector: (row) => row.active,
      cell: (row) => (
        <Box>
          {row.active ? (
            <Chip className="square-chip" label="Active" color="success" size="small" />
          ) : (
            <Chip className="square-chip" label="Inactive" color="error" size="small" />
          )}
        </Box>
      ),
    },
  ];

  const getTemplates = async () => {
    try {
      const response = await partner.post(`/v1/screening/templates/enhanced/list`, JSON.stringify({}), {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Basic " + useAuthHeader.base64encodedData,
          service: "partners",
        },

        //withCredentials: true,
      });

      if (response.data.results > 0) {
        setData(response.data.entries);
      } else {
        setData([]);
      }
      setIsLoaded(true);
    } catch (err) {
      handleError(err);
    }
  };

  useEffect(() => {
    getTemplates();
  }, []);

  return (
    <>
      {isLoaded ? (
        <>
          {previewForm ? (
            <Box>
              <Box sx={{ borderTop: 1, borderColor: "divider" }}>
                <Box sx={{ p: 2, display: "flex", justifyContent: "space-between" }}>
                  <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
                    <Typography variant="h6">Template preview</Typography>
                  </Box>

                  <Box sx={{ display: "flex", gap: 1 }}>
                    <Button
                      onClick={() => {
                        setPreviewForm(false);
                      }}
                      variant="contained"
                      color="primary"
                    >
                      Close
                    </Button>
                  </Box>
                </Box>

                <PreviewForm partner={partner} partnerUser={partnerUser} activeTemplate={activeTemplate} />
              </Box>
            </Box>
          ) : (
            <Box sx={{ borderTop: 1, borderColor: "divider" }}>
              <Box sx={{ p: 2, display: "flex", justifyContent: "space-between" }}>
                <Typography variant="h6">{newTemplate ? "Create a new template" : manageTemplate ? "Manage template" : "EDD Templates"}</Typography>
                <Box sx={{ display: "flex", gap: 1 }}>
                  <Button
                    onClick={() => {
                      manageTemplate ? setManageTemplate(false) : newTemplate ? setNewTemplate(false) : setNewTemplate(true);
                    }}
                    variant="contained"
                    color="primary"
                  >
                    {newTemplate ? "Close" : manageTemplate ? "Close" : "Create template"}
                  </Button>
                </Box>
              </Box>
              <Divider />
              {newTemplate ? (
                <Box>
                  <NewTemplate
                    partner={partner}
                    partnerUser={partnerUser}
                    partnerData={partnerData}
                    setNewTemplate={setNewTemplate}
                    getTemplates={getTemplates}
                    setActiveTemplate={setActiveTemplate}
                    setManageTemplate={setManageTemplate}
                  />
                </Box>
              ) : manageTemplate ? (
                <Box>
                  <ManageTemplate
                    partner={partner}
                    partnerUser={partnerUser}
                    partnerData={partnerData}
                    activeTemplate={activeTemplate}
                    setActiveTemplate={setActiveTemplate}
                    getTemplates={getTemplates}
                    setManageTemplate={setManageTemplate}
                    setPreviewForm={setPreviewForm}
                  />
                </Box>
              ) : (
                <DataTable columns={columns} data={data} customStyles={customStyles} pagination striped />
              )}
            </Box>
          )}
        </>
      ) : (
        <Box>
          <Lottie speed={2.0} options={defaultOptions} height={200} width={200} />
        </Box>
      )}
    </>
  );
};

export default EnhancedDueDiligence;
