import Cookies from "universal-cookie";
import { Buffer } from "buffer";

const hostedAuthHeader = () => {
  const cookies = new Cookies();

  const base64encodedData = Buffer.from(`${cookies.get("hostedID")}:${cookies.get("hostedSecret")}`).toString("base64");
  return { base64encodedData };
};

export default hostedAuthHeader;
