import { React, useEffect, useState } from "react";

import authHeader from "../../api/authHeader";

import HandleError from "../../components/functions/HandleError";
import NoResults from "../../components/functions/NoResults";

import Lottie from "react-lottie";
import loadingLottie from "../../components/lotties/loading-dots.json";

import DataTable from "react-data-table-component";

import { Typography, Divider, Box, Button, Chip, Link, Fade } from "@mui/material";
import { Close } from "@mui/icons-material";
import NewGroup from "./components/groups/NewGroup";

const Groups = ({ partner, partnerUser, partnerData }) => {
  const useAuthHeader = authHeader();

  const handleError = HandleError();

  const [isLoaded, setIsLoaded] = useState(false);
  const [isFiltered, setIsFiltered] = useState(false);
  const [data, setData] = useState([]);

  const [newGroup, setNewGroup] = useState(false);

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: loadingLottie,
  };

  const customStyles = {
    headCells: {
      style: {
        paddingLeft: "8px", // override the cell padding for head cells
        paddingRight: "8px",
        fontWeight: "bold",
      },
    },
    cells: {
      style: {
        paddingLeft: "8px", // override the cell padding for data cells
        paddingRight: "8px",
      },
    },
  };

  const columns = [
    {
      name: "Group name",
      sortable: true,
      width: "30%",
      selector: (row) => row.name,
      cell: (row) => (
        <Link className="link" href={`/group/${row.group_uuid}`} underline="none" sx={{ fontSize: "13px" }}>
          {row.name}
        </Link>
      ),
    },
    {
      name: "# enities",
      sortable: true,
      selector: (row) => row.members,
      cell: (row) => (
        <Box>
          <Typography sx={{ fontSize: "13px" }}>{row.members}</Typography>
        </Box>
      ),
    },
    {
      name: "Flags",
      sortable: false,
      right: "true",
      cell: (row) => (
        <Box sx={{ display: "flex", flexDirection: "column", gap: 1, py: 1 }}>
          {!row.flags.pep &&
            !row.flags.sanctions &&
            !row.flags.warnings &&
            !row.flags.adverse_media &&
            !row.flags.country &&
            !row.flags.age &&
            !row.flags.industry &&
            !row.flags.fatca &&
            !row.flags.associated_pep &&
            "-"}
          {row.flags.pep && (
            <Chip className="square-chip" label="PEP" sx={{ backgroundColor: "#C83E4D", color: "#ffffff", textTransform: "uppercase" }} />
          )}
          {row.flags.sanctions && (
            <Chip className="square-chip" label="Sanctioned" sx={{ backgroundColor: "#C83E4D", color: "#ffffff", textTransform: "uppercase" }} />
          )}
          {row.flags.warnings && (
            <Chip className="square-chip" label="Warnings" sx={{ backgroundColor: "#FCCA46", color: "#1C2321", textTransform: "uppercase" }} />
          )}
          {row.flags.adverse_media && (
            <Chip className="square-chip" label="Adverse media" sx={{ backgroundColor: "#FCCA46", color: "#1C2321", textTransform: "uppercase" }} />
          )}
          {row.flags.country && (
            <Chip className="square-chip" label="Country risk" sx={{ backgroundColor: "#FCCA46", color: "#1C2321", textTransform: "uppercase" }} />
          )}
          {row.flags.age && (
            <Chip className="square-chip" label="Age risk" sx={{ backgroundColor: "#FCCA46", color: "#1C2321", textTransform: "uppercase" }} />
          )}
          {row.flags.industry && (
            <Chip
              className="square-chip"
              label="High risk industry"
              sx={{ backgroundColor: "#FCCA46", color: "#1C2321", textTransform: "uppercase" }}
            />
          )}
          {row.flags.fatca && (
            <Chip className="square-chip" label="FATCA" sx={{ backgroundColor: "#2274A5", color: "#1C2321", textTransform: "uppercase" }} />
          )}
          {row.flags.associated_pep && (
            <Chip className="square-chip" label="Associated PEP" sx={{ backgroundColor: "#FCCA46", color: "#1C2321", textTransform: "uppercase" }} />
          )}
          {row.flags.high_risk_person && (
            <Chip
              className="square-chip"
              label="High risk person"
              sx={{ backgroundColor: "#FCCA46", color: "#1C2321", textTransform: "uppercase" }}
            />
          )}
        </Box>
      ),
    },
  ];

  const getGroups = async () => {
    try {
      const response = await partner.post(
        `/v1/client/groups/data/list`,
        JSON.stringify({
          partner_uuid: partnerUser.value.partner_uuid,
        }),
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Basic " + useAuthHeader.base64encodedData,
            service: "partners",
          },

          //withCredentials: true,
        }
      );

      if (response.data.results > 0) {
        setData(response.data.entries);
      } else {
        setData([]);
      }
      setIsLoaded(true);
    } catch (err) {
      console.log(err);
      handleError(err);
    }
  };
  useEffect(() => {
    getGroups();
  }, []);

  return (
    <>
      {isLoaded ? (
        <>
          <Box sx={{ border: 1, borderColor: "divider" }}>
            <Box sx={{ p: 2, display: "flex", justifyContent: "space-between" }}>
              <Typography variant="h6">{newGroup ? "Create a new group" : "Client groups"}</Typography>
              <Box sx={{ display: "flex", gap: 1 }}>
                <Fade in={isFiltered} exit={!isFiltered} timeout={750}>
                  <Box>
                    <Button
                      onClick={() => {
                        getGroups();
                        setIsFiltered(false);
                      }}
                      startIcon={<Close />}
                      variant="outlined"
                      color="primary"
                    >
                      Remove filter
                    </Button>
                  </Box>
                </Fade>

                <Button
                  onClick={() => {
                    setNewGroup(!newGroup);
                  }}
                  variant="contained"
                  color="primary"
                >
                  {newGroup ? "Close" : "Create group"}
                </Button>
              </Box>
            </Box>
            <Divider />

            {newGroup ? (
              <Box>
                <NewGroup partner={partner} partnerUser={partnerUser} getGroups={getGroups} />
              </Box>
            ) : (
              <DataTable
                columns={columns}
                data={data}
                customStyles={customStyles}
                pagination
                striped
                noDataComponent={<NoResults message={`Waiting for your first client group`} />}
              />
            )}
          </Box>
        </>
      ) : (
        <Box>
          <Lottie speed={2.0} options={defaultOptions} height={200} width={200} />
        </Box>
      )}
    </>
  );
};

export default Groups;
