import { Stack, Typography } from "@mui/material";
import moment from "moment";
import { notesPropType, replyPropType } from "./propTypes";
import PropTypes from "prop-types";

export function NoteHeader({ note }) {
  return (
    <Stack
      direction="row"
      alignItems="center"
      justifyContent="end"
      sx={{
        bgcolor: "#faf8f7",
        p: 1,
        borderColor: "divider",
        borderBottomWidth: 1,
        borderBottomStyle: "solid",
      }}
    >
      <Stack direction="column" spacing={1}>
        <Typography
          sx={{ textAlign: "right" }}
          variant="body1"
          fontWeight={500}
        >
          {note?.owner_name}
        </Typography>
        <Stack
          direction="row"
          spacing={2}
          fontWeight={300}
          justifyContent="end"
        >
          <Typography variant="subtitle2">
            {moment.unix(note.created).format("Do MMM YYYY - hh:mm a")}
          </Typography>
          {note?.version > 1 && (
            <Typography variant="subtitle2">Edited</Typography>
          )}
        </Stack>
      </Stack>
    </Stack>
  );
}

NoteHeader.propTypes = {
  note: PropTypes.oneOfType([notesPropType, replyPropType]),
};
