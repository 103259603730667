import parse from "html-react-parser";

export function htmlParser(htmlString) {
  if (!htmlString) {
    return null;
  }
  if (typeof htmlString !== "string") {
    return null;
  }
  try {
    return parse(htmlString);
  } catch (error) {
    console.error(error);
    return null;
  }
}
