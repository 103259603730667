import { FormControl, InputLabel, MenuItem, Select, Stack } from "@mui/material";
import { useGetPartnerUsersList } from "../../../../../hooks/use-get-partner-users-list";
import { tasksStatus } from "../../../constants";
import {
  postChangeTaskAssignee,
  postChangeTaskDueDate,
  postChangeTaskStatus,
  postUnassignTask,
} from "../../../../../api/services";
import PropTypes from "prop-types";
import { useEffect, useMemo, useState } from "react";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import moment from "moment";
import HandleToast from "../../../../../components/functions/HandleToast";

export function TaskControls({ taskData, updateTaskData }) {
  const [locale, setLocale] = useState("en-gb");
  const [tasksState, setTasksState] = useState({
    assigned_to_uuid: "none",
    status: "0",
    dueDate: undefined,
  });
  const taskUUID = useMemo(() => taskData?.task_uuid, [taskData]);
  const displayToast = HandleToast();
  const { partnerUsersList } = useGetPartnerUsersList();

  const handleChangeOwnership = async (targetUUID) => {
    try {
      if (!taskUUID || !targetUUID) return;
      if (targetUUID === "none") {
        await postUnassignTask({ taskUUID });
      } else {
        await postChangeTaskAssignee({ taskUUID, targetUUID });
      }
      await updateTaskData();
      displayToast("Task ownership updated successfully");
    } catch (error) {
      console.error(error);
      displayToast("Error updating task ownership", undefined, "error");
    }
  };

  const handleChangeStatus = async (status) => {
    const newStatus = Number(status);
    try {
      if (!taskUUID || typeof newStatus !== "number") return;
      await postChangeTaskStatus({ taskUUID, newStatus });
      await updateTaskData();
      displayToast("Task status updated successfully");
    } catch (error) {
      console.error(error);
      displayToast("Error updating task status", undefined, "error");
    }
  };

  const handelChangeDueDate = async (date) => {
    try {
      if (!taskUUID || !date) return;
      await postChangeTaskDueDate({
        taskUUID,
        dueDate: moment(date).format("YYYY-MM-DD"),
      });
      await updateTaskData();
      displayToast("Task due date updated successfully");
    } catch (error) {
      console.error(error);
      displayToast("Error updating task due date", undefined, "error");
    }
  };

  useEffect(() => {
    const dueDate = taskData?.due_date ? moment(taskData?.due_date) : null;
    setTasksState({
      assigned_to_uuid: taskData?.assigned_to_uuid || "none",
      status: taskData?.status || "0",
      dueDate: dueDate,
    });
  }, [taskData]);

  return (
    <Stack spacing={2} sx={{ p: 2, border: 1, borderColor: "divider", borderRadius: 1 }}>
      <FormControl fullWidth size="small">
        <InputLabel>Status</InputLabel>
        <Select
          value={tasksState?.status || "0"}
          label="Status"
          onChange={(e) => {
            const value = e.target.value;
            if (value === "0") return;
            handleChangeStatus(value);
          }}
        >
          {Object.keys(tasksStatus)?.map((statusKey) => (
            <MenuItem
              key={statusKey}
              value={statusKey}
              disabled={statusKey === "0"}
              sx={{
                bgcolor: statusKey === "0" ? "action.disabledBackground" : "",
              }}
            >
              {tasksStatus?.[statusKey]?.label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <FormControl fullWidth size="small">
        <InputLabel>Assignee</InputLabel>
        <Select
          value={tasksState?.assigned_to_uuid || "none"}
          label="Assignee"
          onChange={(event) => handleChangeOwnership(event.target.value)}
        >
          <MenuItem value="none">Unassigned</MenuItem>
          {partnerUsersList?.map((user) => (
            <MenuItem key={user?.uuid} value={user?.uuid}>
              {user?.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale={locale}>
        <DatePicker
          onChange={(date) => {
            handelChangeDueDate(date);
          }}
          fullWidth
          label={`Due Date`}
          value={tasksState?.dueDate ? tasksState?.dueDate : null}
          slotProps={{ textField: { fullWidth: true } }}
        />
      </LocalizationProvider>
    </Stack>
  );
}

TaskControls.propTypes = {
  taskData: PropTypes.object,
  updateTaskData: PropTypes.func,
};
