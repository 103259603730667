import { React, useEffect, useState } from "react";

import partner from "../../api/partner";
import central from "../../api/central";
import authHeader from "../../api/authHeader";

import HandleError from "../../components/functions/HandleError";
import HandleToast from "../../components/functions/HandleToast";

import Lottie from "react-lottie";
import loadingLottie from "../../components/lotties/loading-dots.json";

import "moment/dist/locale/en-gb";
import moment from "moment";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

import {
  Box,
  Typography,
  Chip,
  Grid,
  Icon,
  IconButton,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Button,
  Collapse,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  DialogContentText,
  ToggleButtonGroup,
  ToggleButton,
} from "@mui/material";
import { Close, Delete, Edit, InsertDriveFile } from "@mui/icons-material";

import CountrySelect from "../../components/form-elements/CountrySelect";
import { LoadingButton } from "@mui/lab";

const BusinessDetails = ({ partnerData, partnerUser, caseData, entityData, getCorporation }) => {
  const useAuthHeader = authHeader();
  const handleError = HandleError();
  const displayToast = HandleToast();

  const [locale, setLocale] = useState("en-gb");

  if (moment.locale() != locale) {
    moment.locale(locale);
  }

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: loadingLottie,
  };

  const [isLoaded, setIsLoaded] = useState(false);
  const [processing, setProcessing] = useState(false);
  const [editMode, setEditMode] = useState(false);

  const [registrationName, setRegistrationName] = useState(
    entityData.value.registered_name ? entityData.value.registered_name : ""
  );
  const [tradingName, setTradingName] = useState(
    entityData.value.trading_name ? entityData.value.trading_name : ""
  );

  const [registrationCountry, setRegistrationCountry] = useState({ value: "", name: "" });

  const [registrationType, setRegistrationType] = useState(
    entityData.value.registration_type_uuid ? entityData.value.registration_type_uuid : ""
  );
  const [registrationTypeOptions, setRegistrationTypeOptions] = useState([]);
  const [registrationTypeData, setRegistrationTypeData] = useState({});

  const [registrationReference, setRegistrationReference] = useState(
    entityData.value.registration_reference ? entityData.value.registration_reference : ""
  );
  const [registrationDate, setRegistrationDate] = useState(null);

  const [endOfYearDay, setEndOfYearDay] = useState(
    entityData.value.year_end_day ? entityData.value.year_end_day : 0
  );
  const [endOfYearMonth, setEndOfYearMonth] = useState(
    entityData.value.year_end_month ? entityData.value.year_end_month : 0
  );

  const [publicRegistryUrl, setPublicRegistryUrl] = useState(
    entityData.value.registry_url ? entityData.value.registry_url : ""
  );

  const [addPreviousName, setAddPreviousName] = useState(false);
  const [previousName, setPreviousName] = useState("");
  const [previousNameType, setPreviousNameType] = useState(1);

  const [deletePreviousNameDialog, setDeletePreviousNameDialog] = useState(false);
  const [deletePreviousNameUUID, setDeletePreviousNameUUID] = useState("");

  const [vatNumber, setVatNumber] = useState(
    entityData.value.vat_number ? entityData.value.vat_number : ""
  );

  const getRegistrationTypeOptions = async () => {
    if (registrationCountry?.value == "") {
      return;
    }

    try {
      const response = await central.post(
        `/v1/service/corporations/types/options`,
        JSON.stringify({
          country: registrationCountry?.value ? registrationCountry.value : "",
        }),
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Basic " + useAuthHeader.base64encodedData,
            service: "partners",
            partner_uuid: partnerUser.value.partner_uuid,
          },

          //withCredentials: true,
        }
      );

      if (response.data.results > 0) {
        setRegistrationTypeOptions(response.data.entries);
      } else {
        setRegistrationTypeOptions([]);
      }
      setIsLoaded(true);
    } catch (err) {
      console.log(err);
      handleError(err);
    }
  };

  const handleUpdateRegistration = async (e) => {
    e.preventDefault();
    setProcessing(true);

    try {
      const response = await partner.post(
        `/v1/entity/corporations/corporation/registration`,
        JSON.stringify({
          corporation_uuid: entityData.value.corporation_uuid,
          registered_name: registrationName,
          trading_name: tradingName,
          country: registrationCountry.value,
          registration_type: registrationType,
          registration_reference: registrationReference,
          registration_date: registrationDate ? moment(registrationDate).format("YYYY-MM-DD") : "",
          year_end_day: parseInt(endOfYearDay),
          year_end_month: parseInt(endOfYearMonth),
          vat_number: vatNumber,
          registry_url: publicRegistryUrl,
        }),
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Basic " + useAuthHeader.base64encodedData,
            service: "partners",
          },

          //withCredentials: true,
        }
      );

      getCorporation(entityData.value.corporation_uuid);

      displayToast("Registration details updated successfully", () => {
        setProcessing(false);
        setEditMode(false);
      });
    } catch (err) {
      console.log(err);
      handleError(err);
      setProcessing(false);
    }
  };

  const handleAddPreviousName = async (e) => {
    e.preventDefault();
    setProcessing(true);
    try {
      const response = await partner.post(
        `/v1/entity/corporations/corporation/previous-name/add`,
        JSON.stringify({
          corporation_uuid: entityData.value.corporation_uuid,
          previous_name: previousName,
          name_type: parseInt(previousNameType),
        }),
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Basic " + useAuthHeader.base64encodedData,
            service: "partners",
          },

          //withCredentials: true,
        }
      );

      getCorporation(entityData.value.corporation_uuid);

      displayToast("Previous name added successfully", () => {
        setPreviousName("");
        setPreviousNameType(1);
        setAddPreviousName(false);
        setProcessing(false);
      });
    } catch (err) {
      console.log(err);
      handleError(err);
      setProcessing(false);
    }
  };

  const handleRemovePreviousName = async (e) => {
    e.preventDefault();
    setProcessing(true);

    try {
      const response = await partner.post(
        `/v1/entity/corporations/corporation/previous-name/remove`,
        JSON.stringify({
          corporation_uuid: entityData.value.corporation_uuid,
          uuid: deletePreviousNameUUID,
        }),
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Basic " + useAuthHeader.base64encodedData,
            service: "partners",
          },

          //withCredentials: true,
        }
      );

      getCorporation(entityData.value.corporation_uuid);
      setDeletePreviousNameDialog(false);

      displayToast("Previous name removed successfully", () => {
        setProcessing(false);
      });
    } catch (err) {
      console.log(err);
      handleError(err);
      setProcessing(false);
    }
  };

  useEffect(() => {
    if (entityData.value.registration_country?.code == "") {
      setIsLoaded(true);
    } else {
      setRegistrationCountry({
        value: entityData.value.registration_country.code,
        name: entityData.value.registration_country.name,
      });
    }

    if (entityData.value.registration_date != "") {
      setRegistrationDate(moment(entityData.value.registration_date));
    }

    setIsLoaded(true);
  }, []);

  useEffect(() => {
    if (registrationCountry?.code != "") {
      getRegistrationTypeOptions();

      if (registrationType == "") {
        setRegistrationType(
          entityData.value.registration_type_uuid ? entityData.value.registration_type_uuid : ""
        );
      }
    }
  }, [registrationCountry]);

  return isLoaded ? (
    <Grid container spacing={2}>
      <Grid item xs={12} xl={12}>
        <Box sx={{}}>
          {!editMode ? (
            <Box
              sx={{
                borderRight: { xs: 0, xl: 0 },
                borderColor: { xs: "divider", xl: "divider" },
                borderBottom: 0,
              }}
            >
              <Box
                sx={{
                  borderBottom: 1,
                  borderColor: "divider",
                  px: 2,
                  py: 1,
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
                  <Typography variant="h6">Company Profile</Typography>
                </Box>
                <Box>
                  <IconButton title="Edit" onClick={() => setEditMode(true)}>
                    <Edit />
                  </IconButton>
                </Box>
              </Box>

              <Box
                sx={{
                  px: 2,
                  py: 1,
                  borderBottom: 1,
                  borderColor: "divider",
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
                  <Typography variant="body2">Registered name</Typography>
                </Box>
                <Box>
                  <Typography variant="subtitle2">
                    {entityData.value.registered_name ? entityData.value.registered_name : "-"}
                  </Typography>
                </Box>
              </Box>

              <Box
                sx={{
                  px: 2,
                  py: 1,
                  borderBottom: 1,
                  borderColor: "divider",
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
                  <Typography variant="body2">Trading name</Typography>
                </Box>
                <Box>
                  <Typography variant="subtitle2">
                    {entityData.value.trading_name ? entityData.value.trading_name : "-"}
                  </Typography>
                </Box>
              </Box>

              <Box
                sx={{
                  px: 2,
                  py: 1,
                  borderBottom: 1,
                  borderColor: "divider",
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
                  <Typography variant="body2">
                    Previous name{entityData.value.previous_names?.length > 1 ? "s" : ""}
                  </Typography>
                </Box>
                <Box>
                  {entityData.value.previous_names?.length > 0 ? (
                    entityData.value.previous_names.map((pnData, index) => (
                      <Typography key={index} variant="subtitle2">
                        {pnData.previous_name}
                      </Typography>
                    ))
                  ) : (
                    <Typography variant="subtitle2">-</Typography>
                  )}
                </Box>
              </Box>

              <Box
                sx={{
                  px: 2,
                  py: 1,
                  borderBottom: 1,
                  borderColor: "divider",
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
                  <Typography variant="body2">Entity type</Typography>
                </Box>
                <Box>
                  <Typography variant="subtitle2">
                    {entityData.value.registration_type ? entityData.value.registration_type : "-"}
                  </Typography>
                </Box>
              </Box>

              <Box
                sx={{
                  px: 2,
                  py: 1,
                  borderBottom: 1,
                  borderColor: "divider",
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
                  <Typography variant="body2">Company reference</Typography>
                </Box>
                <Box>
                  <Typography variant="subtitle2">
                    {entityData.value.registration_reference
                      ? entityData.value.registration_reference
                      : "-"}
                  </Typography>
                </Box>
              </Box>

              <Box
                sx={{
                  px: 2,
                  py: 1,
                  borderBottom: 1,
                  borderColor: "divider",
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
                  <Typography variant="body2">Registry URL</Typography>
                </Box>
                <Box>
                  <Typography variant="subtitle2">
                    {entityData.value.registry_url ? entityData.value.registry_url : "-"}
                  </Typography>
                </Box>
              </Box>

              <Box
                sx={{
                  px: 2,
                  py: 1,
                  borderBottom: 1,
                  borderColor: "divider",
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
                  <Typography variant="body2">Registration date</Typography>
                </Box>
                <Box>
                  <Typography variant="subtitle2">
                    {entityData.value.registration_date
                      ? moment(entityData.value.registration_date).format("Do MMMM YYYY")
                      : "-"}
                  </Typography>
                </Box>
              </Box>

              <Box
                sx={{
                  px: 2,
                  py: 1,
                  borderBottom: 1,
                  borderColor: "divider",
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
                  <Typography variant="body2">Registration country</Typography>
                </Box>
                <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
                  <Typography variant="subtitle2" align="right">
                    {entityData.value.registration_country?.code
                      ? entityData.value.registration_country.name
                      : "-"}
                  </Typography>

                  {entityData.value.registration_country?.high_risk && (
                    <Chip
                      className="square-chip"
                      label="High risk"
                      size="small"
                      sx={{
                        backgroundColor: "#C83E4D",
                        color: "#ffffff",
                        textTransform: "uppercase",
                      }}
                    />
                  )}
                  {entityData.value.registration_country?.conflict && (
                    <Chip
                      className="square-chip"
                      label="Conflict"
                      size="small"
                      sx={{
                        backgroundColor: "#FF4A1C",
                        color: "#1C2321",
                        textTransform: "uppercase",
                      }}
                    />
                  )}
                  {entityData.value.registration_country?.borders_conflict && (
                    <Chip
                      className="square-chip"
                      label="Borders conflict"
                      size="small"
                      sx={{
                        backgroundColor: "#977390",
                        color: "#1C2321",
                        textTransform: "uppercase",
                      }}
                    />
                  )}
                  {entityData.value.registration_country?.is_secrecy && (
                    <Chip
                      className="square-chip"
                      label="Secrecy"
                      size="small"
                      sx={{
                        backgroundColor: "#999999",
                        color: "#1C2321",
                        textTransform: "uppercase",
                      }}
                    />
                  )}
                </Box>
              </Box>

              <Box
                sx={{
                  px: 2,
                  py: 1,
                  borderBottom: 1,
                  borderColor: "divider",
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
                  <Typography variant="body2">Financial year end</Typography>
                </Box>
                <Box>
                  <Typography variant="subtitle2">
                    {entityData.value.year_end_day > 0
                      ? moment(
                          new Date(
                            2023,
                            entityData.value.year_end_month - 1,
                            entityData.value.year_end_day
                          )
                        ).format("Do MMMM")
                      : "-"}
                  </Typography>
                </Box>
              </Box>

              <Box
                sx={{
                  px: 2,
                  py: 1,
                  borderBottom: 0,
                  borderColor: "divider",
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
                  <Typography variant="body2">VAT number</Typography>
                </Box>
                <Box sx={{ display: "flex", gap: 2 }}>
                  {entityData.value.vat_validated && (
                    <Chip className="square-chip" label="Validated" size="small" color="success" />
                  )}
                  <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
                    <Typography variant="subtitle2">
                      {entityData.value.vat_number ? entityData.value.vat_number : "-"}
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Box>
          ) : (
            <Box>
              <Box
                sx={{
                  borderRight: { xs: 0, xl: 0 },
                  borderColor: { xs: "divider", xl: "divider" },
                }}
              >
                <Box
                  sx={{
                    borderBottom: 1,
                    borderColor: "divider",
                    px: 2,
                    py: 1,
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
                    <Typography variant="h6">Update registration details</Typography>
                  </Box>
                  <Box>
                    <IconButton title="Close" onClick={() => setEditMode(false)}>
                      <Close />
                    </IconButton>
                  </Box>
                </Box>
                <Box sx={{ p: 2, display: "flex", flexDirection: "column", gap: 2 }}>
                  <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
                    <Box sx={{}}>
                      <Typography variant="subtitle2">Name information</Typography>
                    </Box>
                  </Box>

                  <TextField
                    fullWidth
                    id="outlined-basic"
                    label={
                      registrationType && registrationTypeData?.registration_required
                        ? "Registered name"
                        : "Business name"
                    }
                    variant="outlined"
                    value={registrationName}
                    onChange={(e) => setRegistrationName(e.target.value)}
                  />

                  <TextField
                    fullWidth
                    id="outlined-basic"
                    label="Trading name (optional)"
                    variant="outlined"
                    value={tradingName}
                    onChange={(e) => setTradingName(e.target.value)}
                  />

                  <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                    <Button variant="outlined" onClick={() => setAddPreviousName(!addPreviousName)}>
                      {addPreviousName ? "Close" : "Add previous name"}
                    </Button>
                  </Box>
                  <Collapse in={addPreviousName} exit={!addPreviousName}>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        gap: 1,
                        border: 1,
                        borderColor: "divider",
                        p: 2,
                      }}
                    >
                      <Typography variant="subtitle2">Add a previous name</Typography>
                      <TextField
                        fullWidth
                        id="outlined-basic"
                        label="Previous name"
                        variant="outlined"
                        value={previousName}
                        onChange={(e) => setPreviousName(e.target.value)}
                      />

                      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                        <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
                          <Typography variant="subtitle2">Previous name type</Typography>
                          <ToggleButtonGroup
                            color="primary"
                            value={previousNameType}
                            exclusive
                            onChange={(e, newValue) => {
                              if (newValue !== null) {
                                setPreviousNameType(newValue);
                              }
                            }}
                            aria-label="Previous name type"
                          >
                            <ToggleButton value={1}>Registered name</ToggleButton>
                            <ToggleButton value={2}>Trading name</ToggleButton>
                          </ToggleButtonGroup>
                        </Box>
                        <Box
                          sx={{ display: "flex", flexDirection: "column", justifyContent: "end" }}
                        >
                          <LoadingButton
                            disableElevation
                            variant="contained"
                            color="success"
                            loading={processing}
                            onClick={(e) => handleAddPreviousName(e)}
                          >
                            Save name
                          </LoadingButton>
                        </Box>
                      </Box>
                    </Box>
                  </Collapse>

                  {entityData.value.previous_names?.length > 0 && (
                    <Box sx={{ border: 1, borderColor: "divider" }}>
                      <Box
                        sx={{
                          borderBottom: 1,
                          borderColor: "divider",
                          px: 2,
                          py: 1,
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                          }}
                        >
                          <Typography variant="body2">
                            Previous name{entityData.value.previous_names?.length > 1 ? "s" : ""}
                          </Typography>
                        </Box>
                      </Box>

                      {entityData.value.previous_names?.length > 0 &&
                        entityData.value.previous_names.map((pnData, index) => (
                          <Box
                            key={index}
                            sx={{
                              px: 2,
                              py: 1,
                              borderBottom:
                                entityData.value.previous_names.length == index + 1 ? 0 : 1,
                              borderColor: "divider",
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            <Box
                              sx={{
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "center",
                              }}
                            >
                              <Typography variant="subtitle2">{pnData.previous_name}</Typography>
                            </Box>
                            <Box>
                              <IconButton
                                sx={{ color: "#C83E4D" }}
                                title="Remove"
                                onClick={() => {
                                  setDeletePreviousNameUUID(pnData.uuid);
                                  setDeletePreviousNameDialog(true);
                                }}
                              >
                                <Delete />
                              </IconButton>
                            </Box>
                          </Box>
                        ))}
                    </Box>
                  )}

                  <Dialog
                    open={deletePreviousNameDialog}
                    onClose={() => setDeletePreviousNameDialog(false)}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                  >
                    <DialogTitle id="alert-dialog-title">Are you sure?</DialogTitle>
                    <DialogContent>
                      <Box>
                        <DialogContentText sx={{ mb: 1 }} id="alert-dialog-description">
                          Are you sure you want to remove this previous name?
                        </DialogContentText>
                      </Box>
                    </DialogContent>
                    <DialogActions>
                      <LoadingButton
                        disableElevation
                        loading={processing}
                        onClick={() => setDeletePreviousNameDialog(false)}
                        color="primary"
                      >
                        Cancel
                      </LoadingButton>

                      <LoadingButton
                        disableElevation
                        sx={{ color: "#ffffff" }}
                        loading={processing}
                        onClick={(e) => {
                          handleRemovePreviousName(e);
                        }}
                        variant="contained"
                        color="danger"
                        autoFocus
                      >
                        Confirm
                      </LoadingButton>
                    </DialogActions>
                  </Dialog>

                  <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
                    <Box sx={{}}>
                      <Typography variant="subtitle2">Corporate registration</Typography>
                    </Box>
                  </Box>

                  <CountrySelect value={registrationCountry} setValue={setRegistrationCountry} />

                  <FormControl fullWidth>
                    <InputLabel id="registration-type-label">Registration type</InputLabel>

                    <Select
                      labelId="registration-type-label"
                      id="registration-type-select"
                      value={registrationType}
                      label="Registration type"
                      onChange={(e) => setRegistrationType(e.target.value)}
                    >
                      {registrationTypeOptions?.map((option, index) => (
                        <MenuItem key={index} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>

                  <TextField
                    fullWidth
                    id="outlined-basic"
                    label="Registration reference"
                    variant="outlined"
                    value={registrationReference}
                    onChange={(e) => setRegistrationReference(e.target.value)}
                  />

                  <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
                    <Box sx={{}}>
                      <Typography variant="subtitle2">
                        {registrationType && registrationTypeData.registration_required
                          ? "Registration date"
                          : "Start date"}
                      </Typography>
                    </Box>

                    <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale={locale}>
                      <DatePicker
                        fullWidth
                        openTo="year"
                        label={`${
                          registrationType && registrationTypeData.registration_required
                            ? "Registration date"
                            : "Start date"
                        }`}
                        value={registrationDate ? registrationDate : null}
                        onChange={(date) => {
                          setRegistrationDate(date);
                        }}
                        slotProps={{ textField: { fullWidth: true } }}
                        maxDate={moment()}
                      />
                    </LocalizationProvider>
                  </Box>

                  <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
                    <Box sx={{}}>
                      <Typography variant="subtitle2">Financial year end</Typography>
                    </Box>

                    <Grid container spacing={2}>
                      <Grid item xs={12} md={6}>
                        <FormControl fullWidth>
                          <InputLabel id="eoy-day-label">Day</InputLabel>

                          <Select
                            labelId="eoy-day-label"
                            id="eoy-day-select"
                            value={endOfYearDay}
                            label="Day"
                            onChange={(e) => setEndOfYearDay(e.target.value)}
                          >
                            <MenuItem value={0} disabled>
                              Select day
                            </MenuItem>
                            <MenuItem value={1}>1st</MenuItem>
                            <MenuItem value={2}>2nd</MenuItem>
                            <MenuItem value={3}>3rd</MenuItem>
                            <MenuItem value={4}>4th</MenuItem>
                            <MenuItem value={5}>5th</MenuItem>
                            <MenuItem value={6}>6th</MenuItem>
                            <MenuItem value={7}>7th</MenuItem>
                            <MenuItem value={8}>8th</MenuItem>
                            <MenuItem value={9}>9th</MenuItem>
                            <MenuItem value={10}>10th</MenuItem>
                            <MenuItem value={11}>11th</MenuItem>
                            <MenuItem value={12}>12th</MenuItem>
                            <MenuItem value={13}>13th</MenuItem>
                            <MenuItem value={14}>14th</MenuItem>
                            <MenuItem value={15}>15th</MenuItem>
                            <MenuItem value={16}>16th</MenuItem>
                            <MenuItem value={17}>17th</MenuItem>
                            <MenuItem value={18}>18th</MenuItem>
                            <MenuItem value={19}>19th</MenuItem>
                            <MenuItem value={20}>20th</MenuItem>
                            <MenuItem value={21}>21st</MenuItem>
                            <MenuItem value={22}>22nd</MenuItem>
                            <MenuItem value={23}>23rd</MenuItem>
                            <MenuItem value={24}>24th</MenuItem>
                            <MenuItem value={25}>25th</MenuItem>
                            <MenuItem value={26}>26th</MenuItem>
                            <MenuItem value={27}>27th</MenuItem>
                            <MenuItem value={28}>28th</MenuItem>
                            <MenuItem value={29}>29th</MenuItem>
                            <MenuItem value={30}>30th</MenuItem>
                            <MenuItem value={31}>31st</MenuItem>
                          </Select>
                        </FormControl>
                      </Grid>

                      <Grid item xs={12} md={6}>
                        <FormControl fullWidth>
                          <InputLabel id="eoy-month-label">Month</InputLabel>

                          <Select
                            labelId="eoy-month-label"
                            id="eoy-month-select"
                            value={endOfYearMonth}
                            label="Month"
                            onChange={(e) => setEndOfYearMonth(e.target.value)}
                          >
                            <MenuItem value={0} disabled>
                              Select month
                            </MenuItem>
                            <MenuItem value={1}>January</MenuItem>
                            <MenuItem value={2}>February</MenuItem>
                            <MenuItem value={3}>March</MenuItem>
                            <MenuItem value={4}>April</MenuItem>
                            <MenuItem value={5}>May</MenuItem>
                            <MenuItem value={6}>June</MenuItem>
                            <MenuItem value={7}>July</MenuItem>
                            <MenuItem value={8}>August</MenuItem>
                            <MenuItem value={9}>September</MenuItem>
                            <MenuItem value={10}>October</MenuItem>
                            <MenuItem value={11}>November</MenuItem>
                            <MenuItem value={12}>December</MenuItem>
                          </Select>
                        </FormControl>
                      </Grid>
                    </Grid>
                  </Box>

                  <Box>
                    <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
                      <Box sx={{}}>
                        <Typography variant="subtitle2">VAT number</Typography>
                      </Box>

                      <TextField
                        fullWidth
                        id="outlined-basic"
                        label="VAT number (optional)"
                        variant="outlined"
                        value={vatNumber}
                        onChange={(e) => setVatNumber(e.target.value)}
                      />
                    </Box>
                  </Box>

                  <Box>
                    <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
                      <Box sx={{}}>
                        <Typography variant="subtitle2">Public registry URL</Typography>
                      </Box>

                      <TextField
                        fullWidth
                        id="outlined-basic"
                        label="Public registry URL (optional)"
                        variant="outlined"
                        value={publicRegistryUrl}
                        onChange={(e) => setPublicRegistryUrl(e.target.value)}
                      />
                    </Box>
                  </Box>

                  {!addPreviousName && (
                    <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "flex-end" }}>
                      <LoadingButton
                        disableElevation
                        variant="contained"
                        color="success"
                        loading={processing}
                        onClick={(e) => handleUpdateRegistration(e)}
                      >
                        Save changes
                      </LoadingButton>
                    </Box>
                  )}
                </Box>
              </Box>
            </Box>
          )}
        </Box>
      </Grid>
    </Grid>
  ) : (
    <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", height: "50vh" }}>
      <Lottie speed={2.0} options={defaultOptions} height={200} width={200} />
    </Box>
  );
};

export default BusinessDetails;
