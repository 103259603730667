import moment from "moment";

export function dateTime(date) {
  if (!date) return null;

  const _date = date * 1000;
  const timeFormat = "YYYY-MM-DD HH:mm";
  const formattedDate = moment(_date).format(timeFormat);
  const isValid = moment(formattedDate, timeFormat, true).isValid();
  if (!isValid) return null;

  return formattedDate;
}
