import { React, useEffect, useState } from "react";

import partner from "../../api/partner";
import authHeader from "../../api/authHeader";

import Lottie from "react-lottie";
import loadingLottie from "../../components/lotties/loading-dots.json";

import DataTable from "react-data-table-component";

import HandleError from "../../components/functions/HandleError";
import HandleToast from "../../components/functions/HandleToast";

import { Box, Typography, Divider, Chip, Button } from "@mui/material";
import { Close } from "@mui/icons-material";

import NoResults from "../../components/functions/NoResults";
import NewAsset from "./components/assets/NewAsset";

const Assets = ({ partnerData, partnerUser, clientData, entityData }) => {
  const useAuthHeader = authHeader();
  const handleError = HandleError();
  const displayToast = HandleToast();

  const [isLoaded, setIsLoaded] = useState(false);
  const [processing, setProcessing] = useState(false);

  const [data, setData] = useState([]);

  const [newAsset, setNewAsset] = useState(false);
  const [manageAsset, setManageAsset] = useState(false);
  const [activeAsset, setActiveAsset] = useState("");

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: loadingLottie,
  };

  const customStyles = {
    headCells: {
      style: {
        paddingLeft: "8px", // override the cell padding for head cells
        paddingRight: "8px",
        fontWeight: "bold",
      },
    },
    cells: {
      style: {
        paddingLeft: "8px", // override the cell padding for data cells
        paddingRight: "8px",
      },
    },
  };

  const columns = [
    {
      name: "Asset name",
      sortable: true,
      width: "20%",
      selector: (row) => row.name,
      cell: (row) => (
        <Box
          className="link"
          onClick={() => {
            setActiveTemplate(row.uuid);
            setManageTemplate(true);
          }}
          sx={{ fontSize: "13px" }}
        >
          {row.name}
        </Box>
      ),
    },
    {
      name: "Asset type",
      sortable: true,
      selector: (row) => row.name,
      cell: (row) => (
        <Box
          className="link"
          onClick={() => {
            console.log(row.uuid);
            setActiveTemplate(row.uuid);
            setManageTemplate(true);
          }}
          sx={{ fontSize: "13px" }}
        >
          {row.name}
        </Box>
      ),
    },
    {
      name: "Value",
      sortable: false,
      right: "true",
      selector: (row) => row.active,
      cell: (row) => (
        <Box>
          {row.active ? (
            <Chip className="square-chip" label="Active" color="success" size="small" />
          ) : (
            <Chip className="square-chip" label="Inactive" color="error" size="small" />
          )}
        </Box>
      ),
    },
  ];

  useEffect(() => {
    setIsLoaded(true);
  }, []);

  return isLoaded ? (
    <Box sx={{}}>
      <Box sx={{ border: 0, borderColor: "divider" }}>
        <Box sx={{ p: 2, display: "flex", justifyContent: "space-between" }}>
          <Typography variant="h6">{newAsset ? "Create a new asset" : manageAsset ? "Manage asset" : "Asset register"}</Typography>
          <Box sx={{ display: "flex", gap: 1 }}>
            <Button
              onClick={() => {
                manageAsset ? setManageAsset(false) : newAsset ? setNewAsset(false) : setNewAsset(true);
              }}
              variant="contained"
              color="primary"
            >
              {newAsset ? "Close" : manageAsset ? "Close" : "Create asset"}
            </Button>
          </Box>
        </Box>
        <Divider />
        {newAsset ? (
          <NewAsset
            partnerData={partnerData}
            partnerUser={partnerUser}
            clientData={clientData}
            entityData={entityData}
            setManageAsset={setManageAsset}
            setActiveAsset={setActiveAsset}
            getAssets={() => {}}
            setNewAsset={setNewAsset}
          />
        ) : manageAsset ? (
          <Box>manage asset form</Box>
        ) : (
          <DataTable
            columns={columns}
            data={data}
            customStyles={customStyles}
            noDataComponent={<NoResults message={`Create your first asset to get started`} />}
            pagination
            striped
          />
        )}
      </Box>
    </Box>
  ) : (
    <Box>
      <Lottie speed={2.0} options={defaultOptions} height={200} width={200} />
    </Box>
  );
};

export default Assets;
