import { React, useState, useEffect } from "react";

import authHeader from "../../../../api/authHeader";

import HandleError from "../../../../components/functions/HandleError";
import HandleToast from "../../../../components/functions/HandleToast";
import NoResults from "../../../../components/functions/NoResults";

import Lottie from "react-lottie";
import loadingLottie from "../../../../components/lotties/loading-dots.json";

import "moment/dist/locale/en-gb";
import moment from "moment";
import DataTable from "react-data-table-component";

import { Box, Select, FormControl, InputLabel, MenuItem, Grid, Chip, IconButton, Typography } from "@mui/material";
import { Visibility, CloudDownload, Close } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import EmptyReport from "../../../../components/functions/EmptyReport";

const ReportHistory = ({ partner, partnerUser, partnerData }) => {
  const useAuthHeader = authHeader();

  const handleError = HandleError();
  const displayToast = HandleToast();

  const [isLoaded, setIsLoaded] = useState(false);
  const [processing, setProcessing] = useState(false);

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: loadingLottie,
  };

  const customStyles = {
    headCells: {
      style: {
        paddingLeft: "8px", // override the cell padding for head cells
        paddingRight: "8px",
        fontWeight: "bold",
      },
    },
    cells: {
      style: {
        paddingLeft: "8px", // override the cell padding for data cells
        paddingRight: "8px",
      },
    },
  };

  const columns = [
    {
      name: "Report generated",
      sortable: true,
      selector: (row) => row.timestamp,
      cell: (row) => (
        <Box>
          <Typography sx={{ fontSize: "13px" }}>{moment.unix(row.timestamp).format("DD/MM/YYYY HH:mm")}</Typography>
        </Box>
      ),
    },
    {
      name: "Report type",
      sortable: true,
      selector: (row) => row.report_type,
      cell: (row) => (
        <Box>
          <Typography sx={{ fontSize: "13px" }}>{reportTypeLabels(row.report_type)}</Typography>
        </Box>
      ),
    },
    {
      name: "Start date",
      sortable: false,
      selector: (row) => row.start_timestamp,
      cell: (row) => (
        <Box>
          <Typography sx={{ fontSize: "13px" }}>{row.start_timestamp ? moment.unix(row.start_timestamp).format("DD/MM/YYYY") : "-"}</Typography>
        </Box>
      ),
    },
    {
      name: "End date",
      sortable: false,
      selector: (row) => row.template_type,
      cell: (row) => (
        <Box>
          <Typography sx={{ fontSize: "13px" }}>{row.end_timestamp ? moment.unix(row.end_timestamp).format("DD/MM/YYYY") : "-"}</Typography>
        </Box>
      ),
    },
    {
      name: "Results",
      sortable: false,
      selector: (row) => row.results,
    },
    {
      name: "Options",
      sortable: true,
      right: "true",
      selector: (row) => row.active,
      cell: (row) => (
        <Box sx={{ display: "flex", gap: 1 }}>
          <IconButton
            title="Preview"
            variant="contained"
            color="primary"
            size="small"
            onClick={() => {
              handlePreviewReport(row.uuid);
            }}
          >
            <Visibility />
          </IconButton>
        </Box>
      ),
    },
  ];

  const previewColumns = [
    {
      name: "Client name",
      sortable: true,
      width: "30%",
      selector: (row) => row.name,
      cell: (row) => (
        <Link className="link" href={`/group/${row.group_uuid}`} underline="none" sx={{ fontSize: "13px" }}>
          {row.name}
        </Link>
      ),
    },
    {
      name: "Client type",
      sortable: true,
      selector: (row) => row.client_entity_type,
      cell: (row) => (
        <Box>
          <Typography sx={{ fontSize: "13px" }}>{row.client_entity_type == 1 ? "Individual" : "Business"}</Typography>
        </Box>
      ),
    },
    {
      name: "Risk score",
      sortable: true,
      selector: (row) => row.risk_score,
      cell: (row) => (
        <Box>
          <Typography sx={{ fontSize: "13px" }}>{row.risk_score ? row.risk_score : "-"}</Typography>
        </Box>
      ),
    },
    {
      name: "Alerts",
      sortable: false,
      cell: (row) => (
        <Box sx={{ display: "flex", gap: 1 }}>
          {!row.is_pep && !row.is_sanctioned && !row.has_warnings && !row.has_adverse_media && !row.country_risk && !row.age_risk && "-"}
          {row.is_pep && (
            <Chip className="square-chip" label="PEP" sx={{ backgroundColor: "#C83E4D", color: "#ffffff", textTransform: "uppercase" }} />
          )}
          {row.is_sanctioned && (
            <Chip className="square-chip" label="Sanctioned" sx={{ backgroundColor: "#C83E4D", color: "#ffffff", textTransform: "uppercase" }} />
          )}
          {row.has_warnings && (
            <Chip className="square-chip" label="Warnings" sx={{ backgroundColor: "#FCCA46", color: "#1C2321", textTransform: "uppercase" }} />
          )}
          {row.has_adverse_media && (
            <Chip className="square-chip" label="Adverse media" sx={{ backgroundColor: "#FCCA46", color: "#1C2321", textTransform: "uppercase" }} />
          )}
          {row.country_risk && (
            <Chip className="square-chip" label="Country risk" sx={{ backgroundColor: "#FCCA46", color: "#1C2321", textTransform: "uppercase" }} />
          )}
          {row.age_risk && (
            <Chip className="square-chip" label="Age risk" sx={{ backgroundColor: "#FCCA46", color: "#1C2321", textTransform: "uppercase" }} />
          )}
        </Box>
      ),
    },
    {
      name: "Status",
      sortable: true,
      right: "true",
      selector: (row) => row.status,
      cell: (row) => (
        <Box sx={{ display: "flex", gap: 1 }}>
          {row.status == 0 && <Chip className="square-chip" label="Onboarding" color="warning" size="small" />}
          {row.status == 1 && <Chip className="square-chip" label="Active" color="success" size="small" />}
          {row.status == 2 && <Chip className="square-chip" label="Closed" color="danger" size="small" />}
        </Box>
      ),
    },
  ];

  const fileFormatOptions = [
    { value: "pdf", label: "PDF" },
    { value: "csv", label: "CSV" },
    { value: "xlsx", label: "XLSX" },
  ];

  const [data, setData] = useState();
  const [preview, setPreview] = useState(false);
  const [reportData, setReportData] = useState([]);
  const [reportUUID, setReportUUID] = useState("");
  const [selectedFileFormat, setSelectedFileFormat] = useState("pdf");

  const reportTypeLabels = (reportType) => {
    switch (reportType) {
      case 1:
        return "High risk clients";
      case 2:
        return "PEP clients";
      case 3:
        return "Sanctioned clients";
      case 4:
        return "FATCA";
      case 5:
        return "New clients";
      case 6:
        return "Recent alerts";
      case 7:
        return "Overdue tasks";
      case 8:
        return "Upcoming tasks";
      case 9:
        return "Completed tasks";
      default:
        return "Unknown";
    }
  };

  const handlePreviewReport = async (uuid) => {
    setProcessing(true);

    try {
      const response = await partner.post(
        `/v1/partner/reports/preview`,
        JSON.stringify({
          report_uuid: uuid,
        }),
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Basic " + useAuthHeader.base64encodedData,
            service: "partners",
          },

          //withCredentials: true,
        }
      );

      if (response.data.results > 0) {
        setReportData(response.data.entries);
      } else {
        setReportData([]);
      }
      setReportUUID(response.data.report_uuid);
      setPreview(true);
      setProcessing(false);
    } catch (err) {
      handleError(err);
      setProcessing(false);
    }
  };

  const handleDownloadReport = async (e) => {
    e.preventDefault();
    setProcessing(true);

    try {
      const response = await partner.post(
        `/v1/partner/reports/download`,
        JSON.stringify({
          report_uuid: reportUUID,
          file_format: selectedFileFormat,
        }),
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Basic " + useAuthHeader.base64encodedData,
            service: "partners",
          },

          //withCredentials: true,
        }
      );

      setProcessing(false);
    } catch (err) {
      handleError(err);
      setProcessing(false);
    }
  };

  const getReports = async () => {
    try {
      const response = await partner.post(
        `/v1/partner/reports/list`,
        JSON.stringify({
          partner_uuid: partnerUser.value.partner_uuid,

          partner_user_uuid: partnerUser.value.uuid,
        }),
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Basic " + useAuthHeader.base64encodedData,
            service: "partners",
          },

          //withCredentials: true,
        }
      );

      if (response.data.results > 0) {
        setData(response.data.entries);
      } else {
        setData([]);
      }

      setIsLoaded(true);
    } catch (err) {
      handleError(err);
      setProcessing(false);
    }
  };

  useEffect(() => {
    getReports();
  }, []);

  return (
    <>
      {isLoaded ? (
        <>
          {preview ? (
            <>
              <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
                <Box sx={{ display: "flex", flexDirection: "row", gap: 2, justifyContent: "flex-end" }}>
                  <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center", minWidth: "300px" }}>
                    <FormControl fullWidth>
                      <InputLabel id="file-format-label">File format</InputLabel>
                      <Select
                        labelId="file-format-label"
                        id="file-format"
                        value={selectedFileFormat}
                        label="File format"
                        onChange={(e) => {
                          setSelectedFileFormat(e.target.value);
                        }}
                      >
                        {fileFormatOptions.map((option, index) => (
                          <MenuItem key={index} value={option.value}>
                            {option.label}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Box>

                  <LoadingButton
                    disableElevation
                    loading={processing}
                    startIcon={<CloudDownload />}
                    variant="contained"
                    color="success"
                    onClick={(e) => handleDownloadReport(e)}
                  >
                    Download report
                  </LoadingButton>
                </Box>

                <DataTable
                  columns={previewColumns}
                  data={reportData}
                  customStyles={customStyles}
                  noDataComponent={<EmptyReport />}
                  pagination
                  striped
                />
              </Box>
            </>
          ) : (
            <Box sx={{ border: 1, borderColor: "divider" }}>
              <DataTable
                columns={columns}
                data={data}
                customStyles={customStyles}
                pagination
                striped
                noDataComponent={<NoResults message={`Generate your first report`} />}
              />
            </Box>
          )}
        </>
      ) : (
        <Box>
          <Lottie speed={2.0} options={defaultOptions} height={200} width={200} />
        </Box>
      )}
    </>
  );
};

export default ReportHistory;
