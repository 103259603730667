import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

const HandleToast = () => {
  const MySwal = withReactContent(Swal);

  const displayToast = (
    message,
    postProcess,
    icon = "success", // 'success' | 'error' | 'warning' | 'info' | 'question'
    iconColor = "success`"
  ) => {
    MySwal.fire({
      html: "<p>" + message + "</p>",
      icon: icon,
      iconColor: iconColor,
      position: "top-end",
      toast: true,
      timer: 1500,
      timerProgressBar: true,
      showCancelButton: false,
      showConfirmButton: false,
      showDenyButton: false,
      allowEscapeKey: false,
    })
      .then(() => {
        if (typeof postProcess === "function") {
          postProcess();
        }
      })
      .catch(console.error);
  };

  return displayToast;
};

export default HandleToast;
