import Cookies from "universal-cookie";
import { Buffer } from "buffer";

const authHeader = () => {
  const cookies = new Cookies();

  const base64encodedData = Buffer.from(`${cookies.get("sessionID")}:${cookies.get("secretToken")}`).toString("base64");
  return { base64encodedData };
};

export default authHeader;
