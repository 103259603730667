import { useEffect, useState } from "react";
import { getClientTasksTemplatesOptions } from "../../api/services";

export function useGetClientTaskTemplates() {
  const [state, setState] = useState({
    isLoading: true,
    data: [],
  });
  useEffect(() => {
    getClientTasksTemplatesOptions()
      .then((data) => {
        setState({
          isLoading: false,
          data: data?.entries,
        });
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  return {
    isLoading: state.isLoading,
    clientTaskTemplates: state.data,
  };
}
