import { Stack } from "@mui/material";
import parse from "html-react-parser";
import PropTypes from "prop-types";
import { notesPropType, replyPropType } from "./propTypes";

export function NoteContent({ note }) {
  return (
    <Stack
      className="quill-rest"
      sx={{
        p: 1,
        borderColor: "divider",
        borderBottomWidth: 1,
        borderBottomStyle: "solid",
      }}
    >
      {parse(note.content)}
    </Stack>
  );
}

NoteContent.propTypes = {
  note: PropTypes.oneOfType([notesPropType, replyPropType]),
};
