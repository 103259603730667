import { React, useState } from "react";

import Cookies from "universal-cookie";
import axios from "axios";
import { signal } from "@preact/signals-react";
import Lottie from "react-lottie";
import loadingLottie from "../../components/lotties/loading-dots.json";

import { CssBaseline, Box } from "@mui/material";

import DashboardNav from "./DashboardNav";
import Overview from "./Overview";
import Groups from "./Groups";
import Clients from "./Clients";
import Screening from "./Screening";
import Reports from "./Reports";
import Users from "./Users";
import Billing from "./Billing";
import Settings from "./Settings";
import Support from "./Support";
import Documents from "./Documents";
import Prospects from "./Prospects";

const activeView = signal(0);
const settingsView = signal(0);
const partnerUser = signal({});
const partnerData = signal({});

const Dashboard = () => {
  const cookies = new Cookies();

  const [navOpen, setNavOpen] = useState(true);

  const [userLoaded, setUserLoaded] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);

  const apiBaseURL = "https://" + cookies.get("platformURL");

  const partner = axios.create({ baseURL: apiBaseURL });

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: loadingLottie,
  };

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />

      <DashboardNav
        partner={partner}
        navOpen={navOpen}
        setNavOpen={setNavOpen}
        activeView={activeView}
        partnerUser={partnerUser}
        partnerData={partnerData}
        userLoaded={userLoaded}
        setUserLoaded={setUserLoaded}
        settingsView={settingsView}
        setIsLoaded={setIsLoaded}
      />

      <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
        {isLoaded ? (
          <>
            {activeView.value === 0 && (
              <Overview
                partner={partner}
                partnerUser={partnerUser}
                partnerData={partnerData}
              />
            )}
            {activeView.value === 2 && (
              <Groups
                partner={partner}
                partnerUser={partnerUser}
                partnerData={partnerData}
              />
            )}
            {activeView.value === 3 && (
              <Clients
                partner={partner}
                partnerUser={partnerUser}
                partnerData={partnerData}
              />
            )}
            {activeView.value === 4 && (
              <Screening
                partner={partner}
                partnerUser={partnerUser}
                partnerData={partnerData}
              />
            )}
            {activeView.value === 5 && (
              <Reports
                partner={partner}
                partnerUser={partnerUser}
                partnerData={partnerData}
              />
            )}
            {activeView.value === 6 && (
              <Users
                partner={partner}
                partnerUser={partnerUser}
                partnerData={partnerData}
              />
            )}
            {activeView.value === 7 && (
              <Billing
                partner={partner}
                partnerUser={partnerUser}
                partnerData={partnerData}
              />
            )}
            {activeView.value === 8 && (
              <Settings
                partner={partner}
                settingsView={settingsView}
                partnerData={partnerData}
                partnerUser={partnerUser}
              />
            )}
            {activeView.value === 9 && (
              <Support
                partner={partner}
                partnerUser={partnerUser}
                partnerData={partnerData}
              />
            )}
            {activeView.value === 11 && (
              <Documents
                partner={partner}
                partnerUser={partnerUser}
                partnerData={partnerData}
              />
            )}
            {activeView.value === 12 && (
              <Prospects
                partner={partner}
                partnerUser={partnerUser}
                partnerData={partnerData}
              />
            )}
          </>
        ) : (
          <Box>
            <Lottie
              speed={2.0}
              options={defaultOptions}
              height={200}
              width={200}
            />
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default Dashboard;
