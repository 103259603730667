import { React, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { styled } from "@mui/material/styles";

import authHeader from "../../api/authHeader";

import ErrorHandling from "../../components/functions/HandleError";

import FeatherIcon from "feather-icons-react";
import Lottie from "react-lottie";
import loadingLottie from "../../components/lotties/loading-dots.json";

import {
  Apps,
  AccountTree,
  Business,
  Policy,
  Assessment,
  Group,
  Receipt,
  Settings,
  Logout,
  ContactSupport,
  WorkspacePremium,
  FileCopy,
  RingVolume,
} from "@mui/icons-material";

import MuiDrawer from "@mui/material/Drawer";

import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import { ChevronRight, ChevronLeft } from "@mui/icons-material";
import { Box, Collapse, Fade } from "@mui/material";
import axios from "axios";

const drawerWidth = 275;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== "open" })(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

const DrawerHeader = styled("div")(({ theme }) => ({
  width: "100%",
  display: "flex",
  flexDirection: "row",
  justifyContent: "flex-start",
  backgroundColor: "#000000",
  color: "#ffffff",
  //padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const DashboardNav = ({
  partner,
  navOpen,
  setNavOpen,
  activeView,
  partnerUser,
  partnerData,
  userLoaded,
  setUserLoaded,
  settingsView,
  setIsLoaded,
}) => {
  const useAuthHeader = authHeader();
  const navigate = useNavigate();
  const handleError = ErrorHandling();

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: loadingLottie,
  };

  /*const Sections = [
    {
      title: "General",
      options: [
        {
          title: "Overview",
          icon: Apps,
          isActive: activeView.value == 0,
          clickFunction: () => {
            activeView.value = 0;
          },
          subOptions: [],
        },
      ],
    },
    {
      title: "Clients",
      options: [
        {
          title: "Prospects",
          icon: RingVolume,
          isActive: activeView.value == 12,
          clickFunction: () => {
            activeView.value = 12;
          },
          subOptions: [],
        },
        {
          title: "Groups",
          icon: AccountTree,
          isActive: activeView.value == 2,
          clickFunction: () => {
            activeView.value = 2;
          },
          subOptions: [],
        },
        {
          title: "Clients",
          icon: Business,
          isActive: activeView.value == 3,
          clickFunction: () => {
            activeView.value = 3;
          },
          subOptions: [],
        },
        {
          title: "Screening",
          icon: Policy,
          isActive: activeView.value == 4,
          clickFunction: () => {
            activeView.value = 4;
          },
          subOptions: [],
        },
      ],
    },
    {
      title: "Processes",
      options: [
        {
          title: "Reports",
          icon: Assessment,
          isActive: activeView.value == 5,
          clickFunction: () => {
            activeView.value = 5;
          },
          subOptions: [],
        },
        {
          title: "Document processing",
          icon: FileCopy,
          isActive: activeView.value == 11,
          clickFunction: () => {
            activeView.value = 11;
          },
        },
      ],
    },
    {
      title: "Account",
      options: [
        {
          title: "Users",
          icon: Group,
          isActive: activeView.value == 6,
          clickFunction: () => {
            activeView.value = 6;
          },
          subOptions: [],
        },
        {
          title: "Billing",
          icon: Receipt,
          isActive: activeView.value == 7,
          clickFunction: () => {
            activeView.value = 7;
          },
          subOptions: [],
        },
        {
          title: "Settings",
          icon: Settings,
          isActive: activeView.value == 8,
          clickFunction: () => {
            activeView.value = 8;
          },
          subOptions: [
            {
              title: "My account",
              icon: <FeatherIcon icon="user" />,
              isActive: settingsView.value == 0,
              clickFunction: () => {
                settingsView.value = 0;
              },
            },
            {
              title: "Branding",
              icon: <FeatherIcon icon="briefcase" />,
              isActive: settingsView.value == 1,
              clickFunction: () => {
                settingsView.value = 1;
              },
            },
            {
              title: "Templates",
              icon: <FeatherIcon icon="copy" />,
              isActive: settingsView.value == 2,
              clickFunction: () => {
                settingsView.value = 2;
              },
            },
            {
              title: "Compliance",
              icon: <FeatherIcon icon="shield" />,
              isActive: settingsView.value == 3,
              clickFunction: () => {
                settingsView.value = 3;
              },
            },
          ],
        },
      ],
    },

    {
      title: "Support",
      options: [
        {
          title: "Support",
          icon: ContactSupport,
          isActive: activeView.value == 9,
          clickFunction: () => {
            activeView.value = 9;
          },
          subOptions: [],
        },
      ],
    },

    {
      title: "Access",
      options: [
        {
          title: "Logout",
          icon: Logout,
          isLogout: true,
          spacing: true,
          clickFunction: () => {
            handleLogout();
          },
          subOptions: [],
        },
      ],
    },
  ]; */

  const Sections = [
    {
      title: "General",
      options: [
        {
          title: "Overview",
          icon: Apps,
          isActive: activeView.value == 0,
          clickFunction: () => {
            activeView.value = 0;
          },
          subOptions: [],
        },
      ],
    },
    {
      title: "Clients",
      options: [
        {
          title: "Clients",
          icon: Business,
          isActive: activeView.value == 3,
          clickFunction: () => {
            activeView.value = 3;
          },
          subOptions: [],
        },
        {
          title: "Screening",
          icon: Policy,
          isActive: activeView.value == 4,
          clickFunction: () => {
            activeView.value = 4;
          },
          subOptions: [],
        },
      ],
    },
    {
      title: "Processes",
      options: [
        {
          title: "Reports",
          icon: Assessment,
          isActive: activeView.value == 5,
          clickFunction: () => {
            activeView.value = 5;
          },
          subOptions: [],
        },
        /*{
          title: "Document processing",
          icon: FileCopy,
          isActive: activeView.value == 11,
          clickFunction: () => {
            activeView.value = 11;
          },
        }, */
      ],
    },
    {
      title: "Account",
      options: [
        {
          title: "Users",
          icon: Group,
          isActive: activeView.value == 6,
          clickFunction: () => {
            activeView.value = 6;
          },
          subOptions: [],
        },

        {
          title: "Settings",
          icon: Settings,
          isActive: activeView.value == 8,
          clickFunction: () => {
            activeView.value = 8;
          },
          subOptions: [
            {
              title: "My account",
              icon: <FeatherIcon icon="user" />,
              isActive: settingsView.value == 0,
              clickFunction: () => {
                settingsView.value = 0;
              },
            },
            {
              title: "Branding",
              icon: <FeatherIcon icon="briefcase" />,
              isActive: settingsView.value == 1,
              clickFunction: () => {
                settingsView.value = 1;
              },
            },
            {
              title: "Templates",
              icon: <FeatherIcon icon="copy" />,
              isActive: settingsView.value == 2,
              clickFunction: () => {
                settingsView.value = 2;
              },
            },
            {
              title: "Compliance",
              icon: <FeatherIcon icon="shield" />,
              isActive: settingsView.value == 3,
              clickFunction: () => {
                settingsView.value = 3;
              },
            },
          ],
        },
      ],
    },

    {
      title: "Support",
      options: [
        {
          title: "Support",
          icon: ContactSupport,
          isActive: activeView.value == 9,
          clickFunction: () => {
            activeView.value = 9;
          },
          subOptions: [],
        },
      ],
    },

    {
      title: "Access",
      options: [
        {
          title: "Logout",
          icon: Logout,
          isLogout: true,
          spacing: true,
          clickFunction: () => {
            handleLogout();
          },
          subOptions: [],
        },
      ],
    },
  ];

  const getPartnerUserData = async () => {
    try {
      const response = await partner.post("/v1/users/self/data", JSON.stringify({}), {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Basic " + useAuthHeader.base64encodedData,
          service: "partners",
        },
        mode: "no-cors",
        //withCredentials: true,
      });

      partnerUser.value = response.data;

      setUserLoaded(true);
      // setIsLoaded(true);
    } catch (err) {
      console.log(err);
      handleError(err);
    }
  };

  const getPartnerData = async () => {
    try {
      const response = await partner.get("/v1/partner/data", {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Basic " + useAuthHeader.base64encodedData,
          service: "partners",
        },
        mode: "no-cors",
        //withCredentials: true,
      });

      partnerData.value = response.data;
      setIsLoaded(true);
    } catch (err) {
      console.log(err);
      handleError(err);
    }
  };

  const handleLogout = async () => {
    try {
      const response = await partner.get("/v1/user/auth/logout", {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Basic " + useAuthHeader.base64encodedData,
          service: "partners",
        },
        mode: "no-cors",
        //withCredentials: true,
      });

      cookies.set("sessionID", "", {
        path: "/",
        expires: new Date(),
      });

      cookies.set("secretToken", "", {
        path: "/",
        expires: new Date(),
      });

      navigate("/", true);
    } catch (err) {
      navigate("/", true);
    }
  };

  useEffect(() => {
    !userLoaded && getPartnerUserData();
    getPartnerData();
  }, []);

  return (
    <Drawer variant="permanent" open={navOpen}>
      <DrawerHeader>
        <IconButton sx={{ color: "#ffffff", fill: "#ffffff" }} onClick={() => setNavOpen(!navOpen)}>
          {navOpen ? <ChevronLeft color="white.main" /> : <ChevronRight color="white.main" />}
        </IconButton>
        <Fade in={true} timeout={2000}>
          <Box sx={{ mb: 0, marginLeft: 3 }} width={150} component="img" alt="Logo" src="/cleverli-logo.svg" />
        </Fade>
      </DrawerHeader>
      <Divider />
      {userLoaded ? (
        Sections.map((section, index) => (
          <Box sx={{ display: "block" }} key={index}>
            <List key={index + `section`}>
              {section.options.map((item, index) => (
                <ListItem onClick={() => item.clickFunction()} key={index} disablePadding sx={{ display: "block" }}>
                  <ListItemButton
                    className={`transition-all ${item.isActive && "selected"} ${item.isLogout && "logout"}`}
                    sx={{
                      minHeight: 48,
                      justifyContent: navOpen ? "initial" : "center",
                      px: 2.5,
                    }}
                  >
                    <ListItemIcon
                      className="transition-all"
                      sx={{
                        minWidth: 0,
                        mr: navOpen ? 3 : "auto",
                        justifyContent: "center",
                      }}
                    >
                      {<item.icon />}
                    </ListItemIcon>
                    <ListItemText primary={item.title} sx={{ opacity: navOpen ? 1 : 0 }} />
                  </ListItemButton>
                  {item.subOptions?.length > 0 && (
                    <Collapse in={item.isActive} exit={!item.isActive} orientation="vertical" timeout={1000}>
                      <List>
                        {item.subOptions.map((subItem, index) => (
                          <ListItem onClick={() => subItem.clickFunction()} key={index} disablePadding sx={{ display: "block" }}>
                            <ListItemButton
                              sx={{
                                minHeight: 48,
                                justifyContent: navOpen ? "initial" : "center",
                                px: 2.5,
                              }}
                              className={`transition-all ${subItem.isActive && "sub-selected"} ${subItem.isLogout && "logout"} ${
                                subItem.spacing && "spacing"
                              }`}
                            >
                              <ListItemIcon
                                className="transition-all"
                                sx={{
                                  minWidth: 0,
                                  mr: navOpen ? 3 : "auto",
                                  justifyContent: "center",
                                }}
                              >
                                {subItem.icon}
                              </ListItemIcon>
                              <ListItemText primary={subItem.title} sx={{ opacity: navOpen ? 1 : 0 }} />
                            </ListItemButton>
                          </ListItem>
                        ))}
                      </List>
                    </Collapse>
                  )}
                </ListItem>
              ))}
            </List>

            {section.options?.length > 0 && <Divider />}
          </Box>
        ))
      ) : (
        <Lottie speed={1.75} options={defaultOptions} height={200} width={200} />
      )}
      <Divider />
    </Drawer>
  );
};

export default DashboardNav;
