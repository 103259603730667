import { useCallback, useEffect, useState } from "react";
import { getTaskAssignmentHistory } from "../../api/services";

export function useGetTaskAssignmentHistory(taskUUID) {
  const [state, setState] = useState({
    data: null,
    isLoading: true,
  });

  const getTaskAssignmentHistoryQuery = useCallback(async () => {
    try {
      const taskAssignmentHistory = await getTaskAssignmentHistory({ taskUUID });
      setState({
        data: taskAssignmentHistory?.entries || [],
        isLoading: false,
      });
    } catch (error) {
      console.error(error);
    }
  }, [taskUUID]);

  useEffect(() => {
    if (state?.data || !taskUUID) return;
    getTaskAssignmentHistoryQuery();
  }, [state?.data, getTaskAssignmentHistoryQuery, taskUUID]);

  return {
    taskAssignmentHistoryList: state.data,
    isLoading: state.isLoading,
    getTaskAssignmentHistoryQuery,
  };
}
