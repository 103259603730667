import { React, useState, useEffect } from "react";

import partner from "../../../../../api/partner";
import authHeader from "../../../../../api/authHeader";

import HandleError from "../../../../../components/functions/HandleError";
import HandleToast from "../../../../../components/functions/HandleToast";

import {
  Box,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Typography,
  Collapse,
} from "@mui/material";

import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import CurrencyTextField from "@puhl/mui-currency-textfield";
import getSymbolFromCurrency from "currency-symbol-map";
import { LoadingButton } from "@mui/lab";

const AccountUsage = ({
  clientData,
  entityData,
  applicationUUID,
  setApplicationUUID,
  applicationData,
  getAccounts,
}) => {
  const useAuthHeader = authHeader();
  const handleError = HandleError();
  const displayToast = HandleToast();

  const [processing, setProcessing] = useState(false);

  const [accountLabel, setAccountLabel] = useState(
    applicationData.label ? applicationData.label : ""
  );
  const [description, setDescription] = useState(
    applicationData.description ? applicationData.description : ""
  );
  const [accountCurrency, setAccountCurrency] = useState(
    applicationData.currency ? applicationData.currency : ""
  );

  const [currencySymbol, setCurrencySymbol] = useState("£");
  const [monthlyDeposits, setMonthlyDeposits] = useState(
    applicationData.incoming_value ? applicationData.incoming_value : 0
  );
  const [depositVolumes, setDepositVolumes] = useState(
    applicationData.incoming_volume ? applicationData.incoming_volume : ""
  );
  const [monthlyWithdrawals, setMonthlyWithdrawals] = useState(
    applicationData.outgoing_value ? applicationData.outgoing_value : 0
  );
  const [withdrawalVolumes, setWithdrawalVolumes] = useState(
    applicationData.outgoing_volume ? applicationData.outgoing_volume : ""
  );

  const handleInitApplication = async (e) => {
    e.preventDefault();
    setProcessing(true);

    try {
      const response = await partner.post(
        `/v1/client/banking/apply/init`,
        JSON.stringify({
          client_uuid: clientData.value.client_uuid,
          entity_uuid: clientData.value.entity_uuid,
          entity_type: clientData.value.entity_type,
          application_uuid: applicationUUID,
          account_label: accountLabel,
          description: description,
          currency: accountCurrency,
          incoming_value: parseFloat(monthlyDeposits),
          incoming_volume: parseFloat(depositVolumes),
          outgoing_value: parseFloat(monthlyWithdrawals),
          outgoing_volume: parseFloat(withdrawalVolumes),
        }),
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Basic " + useAuthHeader.base64encodedData,
            service: "partners",
          },

          // withCredentials: true,
        }
      );

      setApplicationUUID(response.data.uuid);
      getAccounts();

      displayToast("Details saved", () => {
        setProcessing(false);
      });
    } catch (err) {
      console.log(err);
      handleError(err);
      setProcessing(false);
    }
  };

  useEffect(() => {
    if (accountCurrency) {
      setCurrencySymbol(getSymbolFromCurrency(accountCurrency));
    }
  }, [accountCurrency]);

  const numberFormat = (value) =>
    new Intl.NumberFormat("en-gb", {
      style: "currency",
      currency: accountCurrency,
    }).format(value);

  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
      <Box sx={{ display: "flex", gap: 2 }}>
        <Box sx={{ width: "70%" }}>
          <TextField
            fullWidth
            id="outlined-basic"
            label="Account label"
            variant="outlined"
            value={accountLabel}
            onChange={(e) => setAccountLabel(e.target.value)}
          />
        </Box>

        <Box sx={{ width: "30%" }}>
          <FormControl fullWidth>
            <InputLabel id="account-currency-label">Account currency</InputLabel>

            <Select
              labelId="account-currency-label"
              id="account-currency-select"
              value={accountCurrency}
              label="Account currency"
              onChange={(e) => setAccountCurrency(e.target.value)}
            >
              <MenuItem value="GBP">GBP - Great British Pound</MenuItem>

              <MenuItem value="EUR" disabled>
                EUR - Euro
              </MenuItem>
              <MenuItem value="USD" disabled>
                USD - United States Dollar
              </MenuItem>
              <MenuItem value="CHF" disabled>
                CHF - Swiss Franc
              </MenuItem>
            </Select>
          </FormControl>
        </Box>
      </Box>

      <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
        <Typography variant="h6">What will the account be used for?</Typography>
        <Box sx={{ border: 1, borderColor: "divider" }}>
          <ReactQuill theme="snow" value={description} onChange={setDescription} />
        </Box>
      </Box>

      <Collapse in={accountCurrency != ""} exit={accountCurrency == ""}>
        <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
          <Typography variant="h6">Estimated transaction volumes</Typography>

          <Box sx={{ display: "flex", gap: 2 }}>
            <CurrencyTextField
              fullWidth
              label="Estimated monthly deposits"
              variant="outlined"
              value={monthlyDeposits}
              currencySymbol={currencySymbol}
              outputFormat="number"
              minimumValue="0"
              decimalCharacter="."
              digitGroupSeparator=","
              onChange={(event, value) => setMonthlyDeposits(value)}
            />

            <TextField
              fullWidth
              label="Estimated number of deposit transactions"
              variant="outlined"
              value={depositVolumes}
              onChange={(e) => setDepositVolumes(e.target.value)}
            />
          </Box>

          <Box sx={{ display: "flex", gap: 2 }}>
            <CurrencyTextField
              fullWidth
              label="Estimated monthly payments out"
              variant="outlined"
              value={monthlyWithdrawals}
              currencySymbol={currencySymbol}
              outputFormat="number"
              minimumValue="0"
              decimalCharacter="."
              digitGroupSeparator=","
              onChange={(event, value) => setMonthlyWithdrawals(value)}
            />

            <TextField
              fullWidth
              label="Estimated number of payments out"
              variant="outlined"
              value={withdrawalVolumes}
              onChange={(e) => setWithdrawalVolumes(e.target.value)}
            />
          </Box>

          <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
            <LoadingButton
              disableElevation
              color="success"
              variant="contained"
              loading={processing}
              onClick={(e) => handleInitApplication(e)}
            >
              Save details
            </LoadingButton>
          </Box>
        </Box>
      </Collapse>
    </Box>
  );
};

export default AccountUsage;
