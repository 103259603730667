import { LoadingButton } from "@mui/lab";
import { Box, Button, Divider, Stack, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import ReactQuill from "react-quill";
import PropTypes from "prop-types";

import { notesPropType } from "./propTypes";
import {
  addReplyToNote,
  postDeleteNote,
  postNewTaskNote,
  postUpdateReply,
  postUpdateTaskNote,
} from "../../api/services";
import { Delete } from "@mui/icons-material";

export function CreateNoteFrom({
  note,
  onClose,
  taskData,
  noteType,
  noteUUID,
}) {
  const taskUUID = taskData?.task_uuid;
  const [notesData, setNotesData] = useState({
    noteContent: "",
  });

  useEffect(() => {
    if (note) {
      setNotesData({
        noteContent: note?.content,
      });
    }
  }, [note]);

  const onSubmitNote = async () => {
    if (notesData.noteContent === "" || !taskUUID) return;
    try {
      if (note) {
        if (notesData.noteContent === note.content) return;
        if (!note?.uuid) return;
        // update note
        await postUpdateTaskNote({
          taskUUID: taskUUID,
          noteUUID: note?.uuid,
          note: notesData.noteContent,
        });
      } else {
        await postNewTaskNote({
          taskUUID: taskUUID,
          note: notesData.noteContent,
        });
      }
      onClose();
    } catch (error) {
      console.log(error);
    }
  };
  const onSubmitReply = async () => {
    if (notesData.noteContent === "" || !taskUUID || !noteUUID) return;
    try {
      if (note) {
        if (notesData.noteContent === note.content) return;
        if (!note?.uuid) return;
        // update note
        await postUpdateReply({
          taskUUID: taskUUID,
          replyUUID: note?.uuid,
          note: notesData.noteContent,
        });
      } else {
        await addReplyToNote({
          taskUUID: taskUUID,
          noteUUID: noteUUID,
          note: notesData.noteContent,
        });
      }
      onClose();
    } catch (error) {
      console.log(error);
    }
  };

  const handleDeleteNote = async () => {
    if (!note?.uuid) return;
    try {
      await postDeleteNote({
        noteUUID: note?.uuid,
      });
      onClose();
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        border: 1,
        borderColor: "divider",
      }}
    >
      <Box sx={{ p: 2 }}>
        <Typography variant="h6">
          {note
            ? `Update ${noteType === "note" ? "Note" : "Reply"}`
            : `Create a New ${noteType === "note" ? "Note" : "Reply"}`}
        </Typography>
      </Box>

      <Divider />

      <Box>
        <ReactQuill
          theme="snow"
          value={notesData.noteContent}
          onChange={(value) => {
            setNotesData({
              ...notesData,
              noteContent: value,
            });
          }}
          style={{ minHeight: "300px" }}
        />
      </Box>

      <Divider />

      <Stack direction="row" justifyContent="end" spacing={2} sx={{ p: 2 }}>
        {note && noteType === "note" && (
          <Button
            variant="outlined"
            color="danger"
            startIcon={<Delete />}
            onClick={handleDeleteNote}
          >
            Delete {noteType === "note" ? "Note" : "Reply"}
          </Button>
        )}
        <LoadingButton
          disableElevation
          variant="contained"
          color="success"
          // loading={processing}
          onClick={noteType === "note" ? onSubmitNote : onSubmitReply}
        >
          {note
            ? `Update ${noteType === "note" ? "Note" : "Reply"}`
            : `Create ${noteType === "note" ? "Note" : "Reply"}`}
        </LoadingButton>
      </Stack>
    </Box>
  );
}

CreateNoteFrom.propTypes = {
  note: notesPropType,
  onClose: PropTypes.func,
  taskData: PropTypes.object,
  noteType: PropTypes.oneOf(["note", "reply"]),
  noteUUID: PropTypes.string,
};
