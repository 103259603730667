import { useMemo, useState } from "react";

import { Tab, Tabs } from "@mui/material";
import { TasksOverview } from "./components/tasks/overview-tab/tasks-overview";
import { CreateTaskTab } from "./components/tasks/create-task-tab";

import PropTypes from "prop-types";

const Tasks = ({ activeView, clientData }) => {
  const [currentTab, setCurrentTab] = useState("1");

  const handleChange = (_, newValue) => {
    setCurrentTab(newValue);
  };

  const TaskTabs = useMemo(
    () => ({
      1: <TasksOverview clientData={clientData} activeView={activeView} />,
      2: "timeline",
      3: "kanban",
      4: (
        <CreateTaskTab
          clientData={clientData}
          onChangeTab={(tab) => handleChange(undefined, tab)}
        />
      ),
    }),
    [clientData, activeView]
  );

  return (
    <>
      <Tabs value={currentTab} onChange={handleChange} aria-label="lab API tabs example">
        <Tab label="OverView" value="1" />
        <Tab disabled label="Timeline" value="2" />
        <Tab disabled label="Kanban" value="3" />
        <Tab label="Create Task" value="4" />
      </Tabs>
      {TaskTabs[currentTab]}
    </>
  );
};

Tasks.propTypes = {
  activeView: PropTypes.object,
  clientData: PropTypes.object,
};

export default Tasks;
