import { React, useEffect, useState } from "react";

import authHeader from "../../../../../../../api/authHeader";

import HandleError from "../../../../../../../components/functions/HandleError";
import HandleToast from "../../../../../../../components/functions/HandleToast";

import Lottie from "react-lottie";
import loadingLottie from "../../../../../../../components/lotties/loading-dots.json";

import parse from "html-react-parser";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import "moment/dist/locale/en-gb";
import moment from "moment";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

import {
  Box,
  Chip,
  Divider,
  Typography,
  TextField,
  ToggleButtonGroup,
  ToggleButton,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Select,
  Collapse,
} from "@mui/material";
import { AddBoxOutlined, Help, Info, Percent } from "@mui/icons-material";

import { MuiTelInput } from "mui-tel-input";

import Money from "./form-elements/Money";
import CountrySelect from "../../../../../../../components/form-elements/CountrySelect";
import CurrencySelect from "../../../../../../../components/form-elements/CurrencySelect";
import SingleFileUpload from "./form-elements/SingleFileUpload";
import MultipleFileUpload from "./form-elements/MultipleFileUpload";
import Address from "./form-elements/Address";
import Individual from "./form-elements/Individual";
import CorporateEntity from "./form-elements/CorporateEntity";
import MultipleIndividuals from "./form-elements/MultipleIndividuals";
import MultipleCorporateEntities from "./form-elements/MultipleCorporateEntities";

const PreviewForm = ({ partner, partnerUser, activeTemplate }) => {
  const useAuthHeader = authHeader();

  const handleError = HandleError();
  const displayToast = HandleToast();

  const [locale, setLocale] = useState("en-gb");

  if (moment.locale() != locale) {
    moment.locale(locale);
  }

  const [isLoaded, setIsLoaded] = useState(false);
  const [templateData, setTemplateData] = useState(null);

  const [textInput, setTextInput] = useState("");
  const [textLine, setTextLine] = useState("");

  const [currencyValue, setCurrencyValue] = useState(null);
  const [amountValue, setAmountValue] = useState("");

  const [dateInput, setDateInput] = useState(null);

  const [trueFalse, setTrueFalse] = useState(null);
  const [yesNo, setYesNo] = useState(null);

  const [countrySelect, setCountrySelect] = useState();
  const [currencySelect, setCurrencySelect] = useState(null);

  const [diallingCode, setDiallingCode] = useState("");
  const [phoneCountry, setPhoneCountry] = useState("");
  const [telephoneNumber, setTelephoneNumber] = useState("");
  const [phoneValue, setPhoneValue] = useState("");

  const [file, setFile] = useState(null);
  const [files, setFiles] = useState(null);

  const [singleSelect, setSingleSelect] = useState(null);
  const [multiSelect, setMultiSelect] = useState([]);

  const [multiCurrency, setMultiCurrency] = useState([]);
  const [multiCountry, setMultiCountry] = useState([]);

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: loadingLottie,
  };

  const getTemplateData = async () => {
    try {
      const response = await partner.post(
        `/v1/screening/templates/enhanced/data`,
        JSON.stringify({
          template_uuid: activeTemplate,
        }),
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Basic " + useAuthHeader.base64encodedData,
            service: "partners",
          },

          //withCredentials: true,
        }
      );

      setTemplateData(response.data);
      console.log(response.data);
      setIsLoaded(true);
    } catch (err) {
      console.log(err);
      handleError(err);
    }
  };

  useEffect(() => {
    getTemplateData();
  }, []);

  useEffect(() => {
    console.log(multiSelect);
  }, [multiSelect]);

  return isLoaded ? (
    <Box>
      <Box sx={{ p: 2, display: "flex", justifyContent: "space-between", borderTop: 1, borderColor: "divider" }}>
        <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
          <Typography variant="subtitle2">{templateData?.template_data?.name}</Typography>
        </Box>
        <Box sx={{ display: "flex", gap: 1 }}>
          {templateData?.template_data?.active ? (
            <Chip className="square-chip" label="Active" color="success" />
          ) : (
            <Chip className="square-chip" label="Inactive" color="error" />
          )}
        </Box>
      </Box>
      <Divider />
      <Box>
        {templateData?.fields.length > 0 &&
          templateData?.fields.map((field, index) => (
            <Box
              key={index}
              sx={{
                p: 2,
                display: "flex",
                justifyContent: "space-between",
                gap: 2,
                borderBottom: index == templateData?.fields.length - 1 ? 0 : 1,
                borderColor: "divider",
              }}
            >
              {console.log(field)}
              <Box sx={{ px: 2, display: "flex", flexDirection: "column", gap: 2, width: "50%" }}>
                <Box sx={{}}>
                  <Typography variant="body" sx={{ fontWeight: 500 }}>
                    {field.label}
                  </Typography>
                </Box>

                <Box sx={{}}>
                  {field.field_type == 1 && (
                    <Box sx={{ border: 1, borderColor: "divider" }}>
                      <ReactQuill theme="snow" value={textInput} onChange={setTextInput} style={{ minHeight: "300px" }} />
                    </Box>
                  )}

                  {field.field_type == 2 && (
                    <Box>
                      <TextField
                        fullWidth
                        label={field.label}
                        variant="outlined"
                        name={field.uuid}
                        value={textLine}
                        onChange={(e) => setTextLine(e.target.value)}
                      />
                    </Box>
                  )}

                  {field.field_type == 3 && (
                    <Box>
                      <FormControl fullWidth>
                        <InputLabel id={`${field.uuid}-title`}>Select an option</InputLabel>
                        <Select
                          labelId={`${field.uuid}-title`}
                          value={singleSelect}
                          label="Select an option"
                          onChange={(e) => setSingleSelect(e.target.value)}
                        >
                          {field.select_options?.map((option, index) => (
                            <MenuItem key={index} value={option.value}>
                              {option.label}
                            </MenuItem>
                          ))}
                          {field.enable_other && <MenuItem value={-1}>Other</MenuItem>}
                        </Select>
                      </FormControl>

                      {field.enable_other && (
                        <Collapse sx={{ mt: 2 }} in={singleSelect == -1} exit={singleSelect != -1}>
                          <Box>
                            <TextField
                              fullWidth
                              label="Other"
                              variant="outlined"
                              name={`${field.uuid}-other`}
                              value={textLine}
                              onChange={(e) => setTextLine(e.target.value)}
                            />
                          </Box>
                        </Collapse>
                      )}
                    </Box>
                  )}

                  {field.field_type == 4 && (
                    <Box>
                      <FormControl fullWidth>
                        <InputLabel id={`${field.uuid}-title`}>Select multiple options</InputLabel>
                        <Select
                          labelId={`${field.uuid}-title`}
                          value={multiSelect}
                          label="Select multiple options"
                          multiple
                          onChange={(e) => setMultiSelect(e.target.value)}
                        >
                          {field.select_options?.map((option, index) => (
                            <MenuItem key={index} value={option.value}>
                              {option.label}
                            </MenuItem>
                          ))}
                          {field.enable_other && <MenuItem value={-1}>Other</MenuItem>}
                        </Select>
                      </FormControl>

                      {field.enable_other && (
                        <Collapse sx={{ mt: 2 }} in={multiSelect.includes(-1)} exit={!multiSelect.includes(-1)}>
                          <Box>
                            <TextField
                              fullWidth
                              label="Other"
                              variant="outlined"
                              name={`${field.uuid}-other`}
                              value={textLine}
                              onChange={(e) => setTextLine(e.target.value)}
                            />
                          </Box>
                        </Collapse>
                      )}
                    </Box>
                  )}

                  {field.field_type == 5 && (
                    <Box>
                      <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale={locale}>
                        <DatePicker
                          fullWidth
                          openTo="year"
                          label={`Date`}
                          value={dateInput ? dateInput : null}
                          onChange={(date) => {
                            setDateInput(date);
                          }}
                          slotProps={{ textField: { fullWidth: true } }}
                        />
                      </LocalizationProvider>
                    </Box>
                  )}

                  {field.field_type == 6 && (
                    <Box>
                      <TextField
                        fullWidth
                        type="email"
                        label="Email address"
                        variant="outlined"
                        name={field.uuid}
                        value={textLine}
                        onChange={(e) => setTextLine(e.target.value)}
                      />
                    </Box>
                  )}

                  {field.field_type == 7 && (
                    <Box>
                      <MuiTelInput
                        fullWidth
                        defaultCountry="IM"
                        value={phoneValue}
                        onChange={(phone, meta) => {
                          setTelephoneNumber(meta.nationalNumber);
                          setPhoneCountry(meta.countryCode);
                          setDiallingCode(meta.countryCallingCode);
                          setPhoneValue(phone);
                        }}
                      />
                    </Box>
                  )}

                  {field.field_type == 8 && (
                    <Box>
                      <Address partnerUser={partnerUser} />
                    </Box>
                  )}

                  {field.field_type == 9 && (
                    <Box>
                      <CountrySelect value={countrySelect} setValue={setCountrySelect} />
                    </Box>
                  )}

                  {field.field_type == 10 && (
                    <Box>
                      <CountrySelect value={multiCountry} setValue={setMultiCountry} isMultiple={true} />
                    </Box>
                  )}

                  {field.field_type == 11 && (
                    <Box>
                      <ToggleButtonGroup
                        color="primary"
                        value={trueFalse}
                        exclusive
                        onChange={(e, newValue) => {
                          if (newValue !== null) {
                            setTrueFalse(newValue);
                          }
                        }}
                        aria-label="True or false?"
                      >
                        <ToggleButton value={true}>True</ToggleButton>
                        <ToggleButton value={false}>False</ToggleButton>
                      </ToggleButtonGroup>
                    </Box>
                  )}

                  {field.field_type == 12 && (
                    <Box>
                      <ToggleButtonGroup
                        color="primary"
                        value={yesNo}
                        exclusive
                        onChange={(e, newValue) => {
                          if (newValue !== null) {
                            setYesNo(newValue);
                          }
                        }}
                        aria-label="True or false?"
                      >
                        <ToggleButton value={true}>Yes</ToggleButton>
                        <ToggleButton value={false}>No</ToggleButton>
                      </ToggleButtonGroup>
                    </Box>
                  )}

                  {field.field_type == 13 && (
                    <Box>
                      <CurrencySelect value={currencySelect} setValue={setCurrencySelect} />
                    </Box>
                  )}

                  {field.field_type == 14 && (
                    <Box>
                      <CurrencySelect value={multiCurrency} setValue={setMultiCurrency} isMultiple={true} />
                    </Box>
                  )}

                  {field.field_type == 15 && (
                    <Box>
                      <Money
                        currencyValue={currencyValue}
                        setCurrencyValue={setCurrencyValue}
                        amountValue={amountValue}
                        setAmountValue={setAmountValue}
                      />
                    </Box>
                  )}

                  {field.field_type == 16 && (
                    <Box>
                      <TextField
                        fullWidth
                        type="number"
                        InputProps={{ step: "1" }}
                        label={field.label}
                        variant="outlined"
                        name={field.uuid}
                        value={textLine}
                        onChange={(e) => setTextLine(e.target.value)}
                      />
                    </Box>
                  )}

                  {field.field_type == 17 && (
                    <Box>
                      <TextField
                        fullWidth
                        type="number"
                        InputProps={{ step: "0.00000001" }}
                        label={field.label}
                        variant="outlined"
                        name={field.uuid}
                        value={textLine}
                        onChange={(e) => setTextLine(e.target.value)}
                      />
                    </Box>
                  )}

                  {field.field_type == 18 && (
                    <Box>
                      <TextField
                        fullWidth
                        type="number"
                        InputProps={{ step: "0.0001", endAdornment: <Percent /> }}
                        label={field.label}
                        variant="outlined"
                        name={field.uuid}
                        value={textLine}
                        onChange={(e) => setTextLine(e.target.value)}
                      />
                    </Box>
                  )}

                  {field.field_type == 19 && (
                    <Box>
                      <SingleFileUpload setFile={setFile} />
                    </Box>
                  )}

                  {field.field_type == 20 && (
                    <Box>
                      <Box>
                        <MultipleFileUpload setFile={setFiles} />
                      </Box>
                    </Box>
                  )}

                  {field.field_type == 21 && (
                    <Box>
                      <CorporateEntity partnerUser={partnerUser} />
                    </Box>
                  )}

                  {field.field_type == 22 && (
                    <Box>
                      <Individual />
                    </Box>
                  )}

                  {field.field_type == 23 && (
                    <Box>
                      <MultipleCorporateEntities partnerUser={partnerUser} />
                    </Box>
                  )}

                  {field.field_type == 24 && (
                    <Box>
                      <MultipleIndividuals />
                    </Box>
                  )}
                </Box>
              </Box>

              {field.helper_text && (
                <Box sx={{ width: { xs: "50%", lg: "50%", xl: "40%" }, border: 1, borderColor: "divider" }}>
                  <Box sx={{ p: 2, display: "flex", gap: 2 }}>
                    <Info color="info" sx={{}} />
                    <Typography variant="subtitle2">Guidance notes</Typography>
                  </Box>
                  <Divider />
                  <Box sx={{ p: 2, fontSize: "13px" }}>{parse(field.helper_text)}</Box>
                </Box>
              )}
            </Box>
          ))}
      </Box>
    </Box>
  ) : (
    <Box>
      <Lottie speed={2.0} options={defaultOptions} height={200} width={200} />
    </Box>
  );
};

export default PreviewForm;
