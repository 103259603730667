import { React, useEffect, useState } from "react";

import authHeader from "../../../api/hostedAuthHeader";

import HandleError from "../../../components/functions/HostedHandleError";
import HandleToast from "../../../components/functions/HandleToast";

import Lottie from "react-lottie";
import loadingLottie from "../../../components/lotties/loading-dots.json";

import { Close, Delete, Edit } from "@mui/icons-material";
import {
  Box,
  Divider,
  IconButton,
  Typography,
  Button,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  DialogContentText,
  Collapse,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";

import CountrySelect from "../../../components/form-elements/CountrySelect";

const Citizenship = ({ partner, caseUUID, entityData, caseData, activeView, getPerson, requestData, getRequestData, getCase }) => {
  const useAuthHeader = authHeader();
  const handleError = HandleError();
  const displayToast = HandleToast();

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: loadingLottie,
  };

  const [isLoaded, setIsLoaded] = useState(false);
  const [processing, setProcessing] = useState(false);

  const [editMode, setEditMode] = useState(entityData.value.birth_country?.code ? false : true);
  const [editType, setEditType] = useState(1);

  const [countryOfBirth, setCountryOfBirth] = useState(
    entityData.value.birth_country?.code ? { value: entityData.value.birth_country.code, name: entityData.value.birth_country.name } : null
  );
  const [birthTin, setBirthTin] = useState("");

  const [residencyCountry, setResidencyCountry] = useState(null);
  const [residencyTin, setResidencyTin] = useState("");
  const [residencies, setResidencies] = useState([]);

  const [deleteResidencyDialog, setDeleteResidencyDialog] = useState(false);
  const [deleteResidencyUUID, setDeleteResidencyUUID] = useState("");

  const handleUpdateCountryOfBirth = async (e) => {
    e.preventDefault();
    setProcessing(true);
    try {
      const response = await partner.post(
        `/v1/entity/people/person/birth/country`,
        JSON.stringify({
          person_uuid: entityData.value.person_uuid,
          country: countryOfBirth ? countryOfBirth.value : "",
          tin: birthTin,
        }),
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Basic " + useAuthHeader.base64encodedData,
            service: "hosted",
          },

          // withCredentials: true,
        }
      );

      getPerson();
      getRequestData();
      getResidencies(entityData.value.person_uuid);
      getCase();

      displayToast("Country of birth updated successfully", () => {
        setProcessing(false);
        setEditMode(false);
      });
    } catch (err) {
      console.log(err);
      handleError(err);
      setProcessing(false);
    }
  };

  const getResidencies = async (personUUID) => {
    try {
      const response = await partner.post(
        `/v1/entity/people/person/data/tax`,
        JSON.stringify({
          person_uuid: personUUID,
        }),
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Basic " + useAuthHeader.base64encodedData,
            service: "hosted",
          },

          //withCredentials: true,
        }
      );

      if (response.data.results > 0) {
        setResidencies(response.data.entries);
      } else {
        setResidencies([]);
      }
      setIsLoaded(true);
    } catch (err) {
      console.log(err);
      handleError(err);
    }
  };

  const handleAddResidency = async (e) => {
    e.preventDefault();
    setProcessing(true);

    try {
      const response = await partner.post(
        `/v1/entity/people/person/tax/add`,
        JSON.stringify({
          person_uuid: entityData.value.person_uuid,
          tin: residencyTin,
          country: residencyCountry ? residencyCountry.value : "",
        }),
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Basic " + useAuthHeader.base64encodedData,
            service: "hosted",
          },

          //withCredentials: true,
        }
      );

      getResidencies(entityData.value.person_uuid);
      getRequestData();
      getCase();

      displayToast("Residency added successfully", () => {
        setProcessing(false);
        setResidencyTin("");
        setResidencyCountry(null);
      });
    } catch (err) {
      console.log(err);
      handleError(err);
      setProcessing(false);
    }
  };

  const handleDeleteResidency = async (e) => {
    e.preventDefault();
    setProcessing(true);

    try {
      const response = await partner.post(
        `/v1/entity/people/person/tax/remove`,
        JSON.stringify({
          person_uuid: entityData.value.person_uuid,
          code_uuid: deleteResidencyUUID ? deleteResidencyUUID : "",
        }),
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Basic " + useAuthHeader.base64encodedData,
            service: "hosted",
          },

          //withCredentials: true,
        }
      );

      getResidencies(entityData.value.person_uuid);
      getRequestData();
      getCase();
      setDeleteResidencyDialog(false);

      displayToast("Residency deleted successfully", () => {
        setProcessing(false);
        setDeleteResidencyUUID("");
      });
    } catch (err) {
      console.log(err);
      setDeleteResidencyDialog(false);
      handleError(err);
      setProcessing(false);
    }
  };

  useEffect(() => {
    getResidencies(entityData.value.person_uuid);
  }, []);

  return isLoaded ? (
    <Box>
      <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "space-between", borderBottom: 1, borderColor: "divider" }}>
        <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center", p: 2 }}>
          <Typography variant="subtitle2">Birth country and residencies</Typography>
        </Box>
        <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center", p: 2 }}>
          <IconButton
            title="Edit"
            onClick={() => {
              setEditType(1);
              setEditMode(!editMode);
            }}
          >
            {editMode ? <Close /> : <Edit />}
          </IconButton>
        </Box>
      </Box>

      {!editMode ? (
        <Box>
          <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "space-between", p: 2 }}>
            <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
              <Typography variant="subtitle1" sx={{ fontStyle: "italic", fontSize: "13px" }}>
                Country of birth
              </Typography>
            </Box>
            <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
              <Typography variant="h6" sx={{}}>
                {entityData.value.birth_country?.code ? entityData.value.birth_country.name : "-"}
              </Typography>
            </Box>
          </Box>
          <Divider />

          <Box sx={{ p: 2 }}>
            <Typography variant="subtitle2" sx={{ fontSize: "13px" }}>
              Please ensure all of your tax residencies are listed.
            </Typography>
          </Box>

          {residencies.length > 0 && (
            <>
              <Divider />

              <Box sx={{}}>
                {residencies.map((countryData, index) => (
                  <Box key={index} sx={{ display: "flex", flexDirection: "row", justifyContent: "space-between", p: 2 }}>
                    <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
                      <Typography variant="body2" sx={{}}>
                        {countryData.country.name}
                      </Typography>
                    </Box>
                    <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
                      <Typography variant="subtitle2" sx={{ fontSize: "13px", textAlign: "end" }}>
                        TIN
                      </Typography>
                      <Typography variant="body2" sx={{}}>
                        {countryData.tin}
                      </Typography>
                    </Box>
                  </Box>
                ))}
              </Box>
            </>
          )}

          <Divider />

          <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "space-between", p: 2 }}>
            <Button
              variant="outlined"
              size="small"
              color="primary"
              onClick={() => {
                setEditType(2);
                setEditMode(true);
              }}
            >
              Manage residencies
            </Button>
            <Button
              variant="contained"
              size=""
              color="primary"
              onClick={() => {
                requestData.requirements.address
                  ? (activeView.value = 5)
                  : requestData.requirements.identification
                  ? (activeView.value = 6)
                  : requestData.requirements.proof_of_life
                  ? (activeView.value = 7)
                  : requestData.requirements.pep_declaration
                  ? (activeView.value = 8)
                  : (activeView.value = 9);
              }}
            >
              Continue
            </Button>
          </Box>
        </Box>
      ) : (
        <Box>
          {editType == 1 && (
            <Box>
              <Box sx={{ p: 2, display: "flex", flexDirection: "column", gap: 2 }}>
                <Typography variant="subtitle2" sx={{ fontSize: "13px" }}>
                  Where were you born?
                </Typography>

                <CountrySelect value={countryOfBirth} setValue={setCountryOfBirth} />

                <Collapse in={countryOfBirth?.value == "US"} exit={countryOfBirth?.value != "US"}>
                  <TextField fullWidth label="Tax identification number (TIN)" value={birthTin} onChange={(e) => setBirthTin(e.target.value)} />
                </Collapse>
              </Box>

              <Divider />

              <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "flex-end", p: 2 }}>
                <LoadingButton
                  disableElevation
                  variant="contained"
                  loading={processing}
                  color="success"
                  onClick={(e) => {
                    handleUpdateCountryOfBirth(e);
                  }}
                >
                  Save changes
                </LoadingButton>
              </Box>
            </Box>
          )}
          {editType == 2 && (
            <Box>
              <Box sx={{ px: 2, py: 1, backgroundColor: "#0b0b0b" }}>
                <Typography variant="subtitle1" sx={{ color: "#ffffff", fontStyle: "italic" }}>
                  Managing tax residencies
                </Typography>
              </Box>
              <Divider />
              <Box sx={{ p: 2, display: "flex", flexDirection: "column", gap: 2 }}>
                <CountrySelect value={residencyCountry} setValue={setResidencyCountry} />

                <TextField fullWidth label="Tax identification number (TIN)" value={residencyTin} onChange={(e) => setResidencyTin(e.target.value)} />
              </Box>

              <Divider />

              <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "flex-end", p: 2 }}>
                <LoadingButton
                  disableElevation
                  variant="contained"
                  loading={processing}
                  color="success"
                  onClick={(e) => {
                    handleAddResidency(e);
                  }}
                >
                  Add country
                </LoadingButton>
              </Box>

              {residencies.length > 0 && (
                <>
                  <Divider />

                  <Box sx={{}}>
                    <Box sx={{ backgroundColor: "#0b0b0b", p: 2 }}>
                      <Typography variant="subtitle2" sx={{ fontSize: "13px", color: "#ffffff" }}>
                        Residencies
                      </Typography>
                    </Box>

                    {residencies.map((countryData, index) => (
                      <Box key={index} sx={{ display: "flex", flexDirection: "row", justifyContent: "space-between", p: 2 }}>
                        {console.log(countryData)}
                        <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
                          <Typography variant="body2" sx={{}}>
                            {countryData.country.name}
                          </Typography>
                        </Box>
                        <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
                          <IconButton
                            sx={{ color: "#C83E4D" }}
                            title="Remove"
                            onClick={() => {
                              setDeleteResidencyUUID(countryData.uuid);
                              setDeleteResidencyDialog(true);
                            }}
                          >
                            <Delete />
                          </IconButton>
                        </Box>
                      </Box>
                    ))}
                  </Box>
                </>
              )}

              <Dialog
                open={deleteResidencyDialog}
                onClose={() => setDeleteResidencyDialog(false)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
              >
                <DialogTitle id="alert-dialog-title">Are you sure?</DialogTitle>
                <DialogContent>
                  <Box>
                    <DialogContentText sx={{ mb: 1 }} id="alert-dialog-description">
                      Are you sure you want to remove this residency?
                    </DialogContentText>
                  </Box>
                </DialogContent>
                <DialogActions>
                  <LoadingButton disableElevation loading={processing} onClick={() => setDeleteResidencyDialog(false)} color="primary">
                    Cancel
                  </LoadingButton>

                  <LoadingButton
                    disableElevation
                    sx={{ color: "#ffffff" }}
                    loading={processing}
                    onClick={(e) => {
                      handleDeleteResidency(e);
                    }}
                    variant="contained"
                    color="danger"
                    autoFocus
                  >
                    Confirm
                  </LoadingButton>
                </DialogActions>
              </Dialog>
            </Box>
          )}
        </Box>
      )}
    </Box>
  ) : (
    <Box>
      <Lottie speed={2.0} options={defaultOptions} height={200} width={200} />
    </Box>
  );
};

export default Citizenship;
